import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetterOfWords, formatText } from '../../helpers/utils';
import DonutChartV2 from '../Charts/DonutV2';
const ProductFrequency = ({ expanded }) => {
  const { t } = useTranslation();

  const { globalStats } = useSelector(state => state.dashboard) || [];
  const data = globalStats?.product_frequency
    ? globalStats?.product_frequency?.filter(item => item?.key && item?.key != 'null null')
    : [];
  const getKeyName = key => {
    switch (key) {
      case '1 MONTH':
        return 'Monthly';
      case '1 YEAR':
        return 'Annually';
      case '3 MONTH':
        return 'Quarterly ';
      case '6 MONTH':
        return 'Bi-annually';
      default:
        return key;
    }
  };

  const dataSet = data.map(item => ({
    key: item,
    value: item.count,
    name: capitalizeFirstLetterOfWords(formatText(item.key === 'null null' ? 'Free product' : getKeyName(item.key))),
  }));

  return (
    <div
      className={`border radius-4 pxy-4 flex-column flex-1 h-full ${expanded ? 'frequency-box' : ''}`}
      style={{ height: 350, width: 415 }}>
      <label className="font-12 lighter-text regular-text">{t('ENTRANTS')}</label>
      <label className="font-20 medium-text mb-5">{t('DONATION_FREQUENCY')}</label>
      <DonutChartV2 dataSet={dataSet} Tootltiptile={'Entrants'} />
    </div>
  );
};

export default ProductFrequency;
