import React from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import { ReactSelectDropdownWrapper } from './react-select-dropdown.styled';

const Option = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`option-wrapper flex-column items-start justify-start w-full px-2 py-1 radius-1 cursor ${
        isSelected ? 'selected' : ''
      }`}
      {...innerProps}>
      <div className="regular-text font-16">{data?.name}</div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between w-50">
          <div className="flex items-center justify-start w-50">
            <MailIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {data?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? data?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-50 ml-2">
            <MobileIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {data?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? data?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <label
          className={`regular-text text-right ml-10 flex-1 one-line font-14 lighter-text ${
            data?.primary_location?.formatted_address ? '' : 'grey-text'
          }`}>
          {data?.primary_location?.formatted_address ? data?.primary_location?.formatted_address : 'Not provided'}
        </label>
      </div>
    </div>
  );
};

const singleValueComponent = props => {
  const {
    selectProps: { value, menuIsOpen },
  } = props;
  return (
    <div
      className={` flex-column items-start justify-start flex-1 w-full px-2 py-1 overflow-hidden ${
        menuIsOpen && 'hide-content'
      }`}>
      <div className="regular-text font-16">{value?.name}</div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between" style={{ width: '40%' }}>
          <div className="flex items-center justify-start w-50">
            <MailIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                value?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {value?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? value?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-50 ml-2">
            <MobileIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line${
                value?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {value?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? value?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <label
          className={`regular-text text-right ml-10 flex-1 one-line font-14 lighter-text ${
            value?.primary_location?.formatted_address ? '' : 'grey-text'
          }`}>
          {value?.primary_location?.formatted_address ? value?.primary_location?.formatted_address : 'Not provided'}
        </label>
      </div>
    </div>
  );
};

const ReactSelectDropdownComponent = ({
  options = [],
  placeholder = 'Select marketing company',
  isSearchable = false,
  onChange = () => {},
  closeMenuOnSelect = true,
  selectedValue = '',
  style = {},
}) => {
  return (
    <ReactSelectDropdownWrapper className="w-full">
      <Select
        options={options.map(o => ({ ...o, value: o.id }))}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={e => {
          onChange(e);
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        value={selectedValue}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#8927EF' : '#ECEEF3',
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: '40px',
            cursor: 'pointer',
            boxShadow: 'none',

            ':hover': {
              borderColor: '#8927EF',
            },
            ':active': {
              borderColor: '#ECEEF3',
            },
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: 'Poppins Regular',
            color: '#A0A0A0',
            fontSize: '16px',
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            fontFamily: 'Poppins Regular',
            color: '#16192C',
            fontSize: '16px',
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none',
          }),
          indicatorsContainer: (baseStyles, state) => ({
            ...baseStyles,
            color: '#16192C',
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: '16px',
            padding: '8px',
            offset: `2px, 8px
            rgba(5, 49, 73, 0.1)`,
            zIndex: 11,
          }),
        }}
        components={{
          Option,
          Control: props => (
            <components.Control
              {...props}
              className={` control-wrapper flex items-center justify-start radius-4 border cursor"`}
            />
          ),
          SingleValue: props => singleValueComponent(props),
          Input: props => (
            <components.Input
              {...props}
              className={`${!props.selectProps.menuIsOpen && props.hasValue && 'hide-content'}`}
            />
          ),
        }}
      />
    </ReactSelectDropdownWrapper>
  );
};

export default ReactSelectDropdownComponent;
