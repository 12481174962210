import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EmptyImage } from '../../assets/images/subtract.svg';
import { ReactComponent as TrophyIcon } from '../../assets/images/trophy.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/users.svg';
import { Tooltip } from '../../components/tooltip/tooltip';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText } from '../../helpers/utils';
import { PrizeDrawItemWrapper } from '../../styles/components/prize-draw-list/prize-draw-list.styled';
import Menu from '../common/menu';

const PrizeDrawItem = ({ item }) => {
  const { hasPermission } = useContext(OrganisationContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToPrizeDrawDetail = item => {
    let route = '';
    if (hasPermission('ENTRANTS_VIEW')) {
      route = `/prize-draws/all/${item.id}/entrants`;
    } else if (hasPermission('PRIZEDRAWS_ORGANIZATIONS_VIEW')) {
      route = `/prize-draws/all/${item.id}/organizations`;
    } else {
      route = `/prize-draws/all/${item.id}/forms`;
    }
    navigate(route);
  };

  return (
    <PrizeDrawItemWrapper className="w-full cursor" onClick={() => goToPrizeDrawDetail(item)}>
      <div className="border-right flex items-center px-6 overflow-hidden">
        {item?.image?.url ? (
          <img src={item?.image?.url} alt="prize-draw-icon" className="radius-4" height={40} width={40} />
        ) : (
          <div className="flex items-center justify-center radius-4 empty-img-content">
            <EmptyImage height={32} width={32} />
          </div>
        )}
        <label className="medium-text one-line ml-4">{item.name}</label>
      </div>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <label className="regular-text grey-text two-lines word-break-all">{item.description}</label>
      </div>
      <div className="border-right flex items-center px-6">
        <div className="flex items-center fit-content relative cursor">
          <Tooltip
            className="flex items-center"
            content={<span className="regular-text">{t('ENTRANTS')}</span>}
            placement="bottom-end">
            <UsersIcon className="mr-2" />
            <span className="normal-text">{item.entrants?.total}</span>
          </Tooltip>
        </div>
      </div>
      <div className="border-right flex items-center px-6">
        <div className="flex items-center fit-content relative cursor">
          <Tooltip
            className="flex items-center"
            content={<span className="regular-text">{t('WINNERS')}</span>}
            placement="bottom-end">
            <TrophyIcon className="mr-2" width={16} height={16} />
            <span className="normal-text">{item.entrants?.winners}</span>
          </Tooltip>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className={`prize-draw-lifecycle flex items-center justify-center ${formatText(item.lifecycle)}`}>
          <div className="dot mr-1" />
          <span className="medium-text font-12">{capitalize(formatText(item.lifecycle))}</span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Menu menuList={[{ name: t('DETAILS'), onClick: () => goToPrizeDrawDetail(item) }]} />
      </div>
    </PrizeDrawItemWrapper>
  );
};

export default PrizeDrawItem;
