import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GroupDetailsRigthWrapper } from '../../../styles/components/group-details/group-detail.styled';
import Accounts from './accounts';

const GroupDetailsRight = () => {
  return (
    <GroupDetailsRigthWrapper className="flex border flex-1 radius-4">
      <Routes>
        <Route element={<Accounts />} path="/accounts" />
      </Routes>
    </GroupDetailsRigthWrapper>
  );
};

export default GroupDetailsRight;
