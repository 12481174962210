import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const IntegrationEnableDisableAddedDeleted = ({ log = {} }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState({});
  const { event_data, performed_by, event_type } = log;
  const performedBy = getPerformedByFromLog(log);
  const getitleText = (
    <>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{data?.titleText}</span>
    </>
  );
  const setUpdatedData = () => {
    let updatedData = data;
    switch (event_type) {
      case 'INTEGRATION_CREATED': {
        updatedData = { headerText: 'Integration added', titleText: 'added an integration' };
        break;
      }
      case 'INTEGRATION_ENABLED': {
        updatedData = { headerText: 'Integration enabled', titleText: 'enabled an integration' };
        break;
      }
      case 'INTEGRATION_DISABLED': {
        updatedData = { headerText: 'Integration disabled', titleText: 'disabled an integration' };
        break;
      }
      case 'INTEGRATION_DELETED': {
        updatedData = { headerText: 'Integration deleted', titleText: 'deleted an integration' };
        break;
      }
    }
    setData(updatedData);
  };
  useEffect(() => {
    setUpdatedData();
  }, []);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={data?.headerText}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
      />
      {showDetails && (
        <LogContentBox
          title={'Integration'}
          contentText={<p className="regular-text lighter-text font-12">{event_data?.integration?.name}</p>}
        />
      )}
    </div>
  );
};

export default IntegrationEnableDisableAddedDeleted;
