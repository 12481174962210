import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as EmptyImage } from '../../assets/images/subtract.svg';
import { ReactComponent as TrophyIcon } from '../../assets/images/trophy.svg';
import { ReactComponent as UserIcon } from '../../assets/images/users.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription } from '../../helpers/utils';
import { getPrizeDrawList } from '../../store/features/prizeDrawSlice';
import { addToast } from '../../store/features/toastSlice';
import { HomeDetailLeftWrapper } from '../../styles/components/home-details/home-details.styled';
import Menu from '../common/menu';
import Status from '../common/status';

const HomeDetailsLeft = () => {
  const dispatch = useDispatch();
  const { hasPermission } = useContext(OrganisationContext);

  const pageRef = useRef(null);
  const { globalStats } = useSelector(state => state.dashboard);
  const { prizeDrawList } = useSelector(state => state.prizeDraw);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const fetchPrizedrawList = (page, merge) => {
    pageRef.current = page;
    dispatch(getPrizeDrawList({ params: { page }, merge })).catch(err => {
      dispatch(
        addToast({ error: true, text: getErrorDescription(err, 'Error while fetching prizedraw list'), id: uuid() }),
      );
    });
  };
  useEffect(() => {
    fetchPrizedrawList(0, false);
  }, []);

  const fetchMoreData = () => {
    fetchPrizedrawList(pageRef.current + 1, true);
  };

  const goToPrizeDrawDetail = item => {
    if (hasPermission('PRIZEDRAWS_VIEW')) {
      navigate(`/prize-draws/all/${item.id}/entrants`);
    }
  };

  return (
    <HomeDetailLeftWrapper className="border flex-column">
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text">{t('PRIZE_DRAWS')}</label>
        <div className="flex items-center col-gap-4">
          <div className="flex items-center col-gap-2">
            <UserIcon height={16} width={16} />
            <label className="normal-text">{globalStats?.total_entrants || 0}</label>
          </div>
          <div className="flex items-center col-gap-2">
            <TrophyIcon height={16} width={16} />
            <label className="normal-text">{globalStats?.total_winners || 0}</label>
          </div>
        </div>
      </div>
      <div className="pxy-4 flex-column w-full flex-1 overflow-scroll" id="scrollableDiv">
        {(prizeDrawList?.content || []).length > 0 ? (
          <InfiniteScroll
            className="flex-column flex-1 row-gap-4"
            dataLength={(prizeDrawList?.content || [])?.length}
            hasMore={!prizeDrawList?.last}
            loader={
              <div className="item flex items-center justify-center">
                <img alt="loading" height="32px" src={Loading} />
              </div>
            }
            next={fetchMoreData}
            scrollableTarget="scrollableDiv">
            {(prizeDrawList?.content || []).map(prizeDraw => (
              <div
                className="py-4 pl-4 pr-2 radius-4 prizedraw-item-wrapper col-gap-2 cursor"
                onClick={() => goToPrizeDrawDetail(prizeDraw)}>
                <div className="flex items-center">
                  {prizeDraw?.image?.url ? (
                    <img
                      src={prizeDraw?.image?.url}
                      alt="prize-draw-icon"
                      className="radius-4"
                      height={40}
                      width={40}
                    />
                  ) : (
                    <div className="flex items-center justify-center radius-4 empty-img-content">
                      <EmptyImage height={32} width={32} />
                    </div>
                  )}
                </div>
                <div className="flex-column w-full overflow-hidden">
                  <label className="semibold-text font-16 one-line">{prizeDraw?.name}</label>
                  <label className="normal-text font-12 lighter-text one-line">{prizeDraw?.code}</label>
                </div>
                <div className="flex justify-between items-start col-gap-1">
                  {prizeDraw?.lifecycle && <Status withDot status={prizeDraw?.lifecycle} />}
                  <Menu
                    menuList={[
                      {
                        name: t('DETAILS'),
                        onClick: () => goToPrizeDrawDetail(prizeDraw),
                        permission: 'PRIZEDRAWS_VIEW',
                      },
                    ]}
                    menuClassName="h-full flex items-center justify-center"
                  />
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div className="flex items-center justify-center flex-1">
            <label className="medium-text grey-text">{t('NO_PRIZEDRAW_TO_SHOW')}</label>
          </div>
        )}
      </div>
    </HomeDetailLeftWrapper>
  );
};

export default HomeDetailsLeft;
