import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrizeDrawEntrantEntryReferralWrapper } from '../../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import PrizeDrawDetailEntrantHeader from '../prize-draw-entrant-header';
import EntryForm from './entryForm';
import Fundraising from './fundraising';
import Referrals from './referrals';
const EntryReferrals = () => {
  const { t } = useTranslation();
  return (
    <PrizeDrawEntrantEntryReferralWrapper className="flex-column w-full h-full">
      <PrizeDrawDetailEntrantHeader title={t('ENTRY_REFERRALS')} />
      <div className="flex-column flex-1 mb-6 overflow-scroll">
        <EntryForm />
        <Fundraising />
        <Referrals />
      </div>
    </PrizeDrawEntrantEntryReferralWrapper>
  );
};

export default EntryReferrals;
