import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AutomationDetailsRightWrapper } from '../../../styles/components/automationDetails/automation-details.styled';
import Configuration from './configuration';
import Logs from './logs';

function AutomationDetailsRight() {
  return (
    <AutomationDetailsRightWrapper className="flex border flex-1 radius-4">
      <Routes>
        <Route element={<Configuration />} path="configuration" />
        <Route element={<Logs />} path="logs" />
      </Routes>
    </AutomationDetailsRightWrapper>
  );
}

export default AutomationDetailsRight;
