import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const UserProfileUpdate = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(false);
  const { event_data } = log;
  const performedBy = getPerformedByFromLog(log);
  const { previous, user } = event_data;
  const { t } = useTranslation();
  const changedValue =
    previous?.user?.forename !== user?.forename || previous?.user?.surname !== user?.surname
      ? 'name'
      : previous?.user?.profile?.email !== user?.profile?.email
      ? 'email'
      : previous?.user?.profile?.phone !== user?.profile?.phone
      ? 'phone'
      : previous?.user?.mfa_type !== user?.mfa_type
      ? 'mfa_type'
      : '';

  const getTextTitle = (
    <span>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{t('UPDATED')}</span>
      <span className="regular-text lighter-text font-12 ml-1">
        {changedValue === 'mfa_type' ? 'MFA configuration' : changedValue}
      </span>
    </span>
  );
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'User profile updated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={getTextTitle}
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
        showDownIcon={showDetails}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'From'}
            contentText={
              <p className="flex regular-text lighter-text font-12">
                {changedValue === 'name'
                  ? `${previous?.user.forename} ${previous?.user?.surname}`
                  : changedValue === 'email' || changedValue === 'phone'
                  ? previous?.user?.profile[changedValue]
                  : previous?.user[changedValue]}
              </p>
            }
          />
          <LogContentBox
            title={'To'}
            contentText={
              <p className="flex regular-text lighter-text font-12">
                {changedValue === 'name' ? `${user.forename} ${user?.surname}` : user[changedValue]}
              </p>
            }
          />
        </>
      )}
    </div>
  );
};

export default UserProfileUpdate;
