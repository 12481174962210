import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DropDown from '../../components/McDropdown';
import PhoneInput from '../../components/common/phone-input';
import { onlyNumbers } from '../../helpers/utils';

const UserInfo = ({ userInfo = {}, handleChange = () => {}, error = {}, requiredFields = {} }) => {
  const { t } = useTranslation();
  const { organizationUserRoles } = useSelector(state => state.groups);
  return (
    <div className="flex-column items-center justify-center w-full row-gap-6">
      <div className="flex-column w-full row-gap-2">
        <label className="regular-text lighter-text font-12">{t('NAME')}</label>
        <input
          autoComplete="turnoff"
          className={`input ${error.name && 'error-border'}`}
          onChange={e => handleChange('name', e.target.value)}
          placeholder={t('ENTER_FULL_NAME')}
          value={userInfo?.name}
        />
      </div>
      <div className="flex-column w-full row-gap-2">
        <label className="regular-text lighter-text font-12">{t('PHONE_OPTIONAL')}</label>
        <PhoneInput
          selectedCountry={userInfo?.phone?.phoneCountry}
          setSelectedCountry={country => handleChange('phoneCountry', country)}
          phone={userInfo?.phone?.phone}
          setPhone={phone => handleChange('phone', phone)}
          className={error?.phone ? 'phone-input-error' : ''}
        />
      </div>
      <div className="flex-column w-full row-gap-2">
        <label className="regular-text lighter-text font-12">{t('USER_ROLE')}</label>
        <DropDown
          className={`provider-selector ${error.role && 'error-border'}`}
          options={organizationUserRoles}
          selected={(organizationUserRoles || []).find(role => role.id === userInfo?.role?.id)}
          setSelected={option => handleChange('role', option)}
          placeholder={`Select user role`}
          size="large"
        />
      </div>
      <div className="flex-column w-full row-gap-2">
        <label className="regular-text lighter-text font-12">{t('EMAIL')}</label>
        <input
          autoComplete="turnoff"
          className={`input ${error.email && 'error-border'}`}
          onChange={e => handleChange('email', e.target.value)}
          placeholder={'Enter email address'}
          value={userInfo.email}
        />
      </div>
      {requiredFields?.employeeid && (
        <div className="w-full flex-column row-gap-2">
          <label className="regular-text lighter-text font-12">
            {t(requiredFields?.employeeid ? 'EMPLOYEE_NUMBER' : 'EMPLOYEE_NUMBER_OPTINAL')}
          </label>
          <input
            autoComplete="turnoff"
            className={`input ${error?.employeeId && 'error-border'}`}
            onChange={e => {
              if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                handleChange('employeeId', e.target.value);
              }
            }}
            placeholder={t('TYPE_EMPLOYEE_NUMBER')}
            value={userInfo?.employeeId}
          />
        </div>
      )}
      {requiredFields?.badgenumber && (
        <div className="w-full flex-column row-gap-2">
          <label className="regular-text lighter-text font-12">
            {t(requiredFields?.badgenumber ? 'BADGE_NUMBER' : 'BADGE_NUMBER_OPTINAL')}
          </label>
          <input
            autoComplete="turnoff"
            className={`input ${error?.badgeNumber && 'error-border'}`}
            onChange={e => {
              if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                handleChange('badgeNumber', e.target.value);
              }
            }}
            placeholder={t('TYPE_BADGE_NUMBER')}
            value={userInfo?.badgeNumber}
          />
        </div>
      )}
    </div>
  );
};

export default UserInfo;
