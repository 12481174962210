import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import DropDown from '../components/McDropdown';
import PhoneInput from '../components/common/phone-input';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, emailRegEx, formatText, onlyNumbers } from '../helpers/utils';
import { addToast } from '../store/features/toastSlice';
import { addUsers, checkRequiredField, getUserRoles } from '../store/features/userManagementSlice';
import CommonPopup from './common-popup';
import { InviteUserWrapper } from './modal.styled';

const defaultRequiredFields = { name: true, email: true, role: true };
const InviteUser = () => {
  const { t } = useTranslation();
  const { modal, setModal } = useContext(OrganisationContext);
  const [user, setUser] = useState({});
  const [error, setError] = useState({ name: false, email: false, role: false });
  const [requiredFields, setRequiredFields] = useState({ ...defaultRequiredFields });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { userRoles } = useSelector(state => state.usersManagement);
  const { selectedPage } = modal.content;
  useEffect(() => {
    dispatch(getUserRoles());
  }, []);

  const checkErrors = () => {
    return {
      ...error,
      name: !user?.name?.trim(),
      email: !user?.email?.trim(),
      role: !user?.role?.id,
      employeeId: requiredFields?.employeeid && !user?.employeeId?.trim() ? true : false,
      badgeNumber: requiredFields?.badgenumber && !user?.badgeNumber?.trim() ? true : false,
      phone: requiredFields?.phone && !user?.phone?.trim() ? true : false,
    };
  };

  const checkUserErrors = () => {
    const updatedError = checkErrors();
    if (Object.values(updatedError).some(err => err === true)) {
      setError({ ...error, ...updatedError });
      dispatch(addToast({ error: true, text: 'Please fill required field' }));
      return true;
    } else if (!(user?.name && user?.name?.length > 2 && user.name.match(/[a-zA-Z]+/g).length > 1)) {
      setError({ ...error, name: true });
      dispatch(addToast({ error: true, text: 'Please enter user full name' }));
      return true;
    } else if (!emailRegEx.test(user.email)) {
      setError({ ...error, email: true });
      dispatch(addToast({ error: true, text: 'Please enter valid email' }));
      return true;
    } else if (user?.phone?.trim() && user?.phone.length !== 12) {
      setError({ ...error, phone: true });
      dispatch(addToast({ error: true, text: 'Please enter valid phone number' }));
      return true;
    } else if (requiredFields?.badgenumber && user?.badgeNumber?.trim().length !== 8) {
      setError({ ...error, badgeNumber: true });
      dispatch(addToast({ error: true, text: 'Badge number should be 8 character' }));
      return true;
    } else if (requiredFields?.employeeid && user?.employeeId?.trim().length !== 8) {
      setError({ ...error, employeeId: true });
      dispatch(addToast({ error: true, text: 'employee number should be 8 character' }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkUserErrors()) {
      return;
    }

    setLoading(true);
    const request = {
      forename: user.name.split(' ')[0],
      surname: user.name.split(' ')[1],
      role: { id: user.role.id },
      email: user.email,
      title: '',
      timezone: '',
      language: '',
      employee_number: requiredFields?.employeeid ? user?.employeeId : '',
      badge_number: requiredFields?.badgenumber ? user?.badgeNumber : '',
      phone: user?.phone || '',
      is_owner: false,
      country_code: user?.phoneCountry ? user?.phoneCountry : '+1',
    };

    dispatch(addUsers({ user: { ...request }, isInviteUserAPI: true, page: selectedPage }))
      .then(data => {
        setModal(initModal);
        dispatch(
          addToast({
            text: `User invited successfully`,
            id: uuid(),
          }),
        );
      })
      .catch(({ response }) => {
        setLoading(false);
        dispatch(
          addToast({
            error: true,
            text: `${
              response.data.error_description
                ? response.data.error_description
                : 'Something went wrong while invite user'
            }`,
            id: uuid(),
          }),
        );
      });
  };

  const handleRoleChange = role => {
    dispatch(checkRequiredField({ role: { ...role } })).then(data => {
      let updatedRequiredField = { ...defaultRequiredFields };
      (data || []).forEach(item => {
        updatedRequiredField = {
          ...updatedRequiredField,
          [formatText(item.field, '')]: item?.is_required_in_invite,
        };
      });
      setError({ ...error, role: false, employeeId: false, phone: false, badgeNumber: false });
      setRequiredFields(updatedRequiredField);
      setUser({ ...user, role: role });
    });
  };

  return (
    <CommonPopup
      popupTitle={'Invite new user'}
      confirmButtonProps={{ label: t('SEND_INVITE') }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}>
      <InviteUserWrapper className="flex-column items-center w-full">
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{capitalize(formatText(t('NAME')))}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.name && 'error-border'}`}
            onChange={e => {
              setUser({ ...user, name: e.target.value });
              setError({ ...error, name: false });
            }}
            placeholder={t('TYPE_USER_NAME')}
            value={user?.name}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{capitalize(formatText(t('EMAIL')))}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.email && !emailRegEx.test(user.email) && 'error-border'}`}
            onChange={e => {
              setUser({ ...user, email: e.target.value });
              setError({ ...error, email: false });
            }}
            placeholder={t('TYPE_USER_EMAIL')}
            value={user?.email}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{t('USER_ROLE')}</label>
          <DropDown
            className={`provider-selector ${error.role && 'error-border'}`}
            options={userRoles}
            selected={userRoles.find(role => role?.id === user?.role?.id)}
            setSelected={option => handleRoleChange(option)}
            placeholder={`Select user role`}
            size="large"
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">
            {t(requiredFields?.phone ? 'PHONE' : 'PHONE_OPTIONAL')}
          </label>
          <PhoneInput
            selectedCountry={user.phoneCountry}
            setSelectedCountry={country => setUser({ ...user, phoneCountry: country, phone: '' })}
            phone={user.phone}
            setPhone={phone => {
              setUser({ ...user, phone: phone });
              setError({ ...error, phone: false });
            }}
            className={error?.phone ? 'phone-input-error' : ''}
          />
        </div>
        {requiredFields?.employeeid && (
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">
              {t(requiredFields?.employeeid ? 'EMPLOYEE_NUMBER' : 'EMPLOYEE_NUMBER_OPTINAL')}
            </label>
            <input
              autoComplete="turnoff"
              className={`input ${error?.employeeId && 'error-border'}`}
              onChange={e => {
                if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                  setUser({ ...user, employeeId: e.target.value });
                  setError({ ...error, employeeId: false });
                }
              }}
              placeholder={t('TYPE_EMPLOYEE_NUMBER')}
              value={user?.employeeId}
            />
          </div>
        )}
        {requiredFields?.badgenumber && (
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">
              {t(requiredFields?.badgenumber ? 'BADGE_NUMBER' : 'BADGE_NUMBER_OPTINAL')}
            </label>
            <input
              autoComplete="turnoff"
              className={`input ${error?.badgeNumber && 'error-border'}`}
              onChange={e => {
                if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                  setUser({ ...user, badgeNumber: e.target.value });
                  setError({ ...error, badgeNumber: false });
                }
              }}
              placeholder={t('TYPE_BADGE_NUMBER')}
              value={user?.badgeNumber}
            />
          </div>
        )}
      </InviteUserWrapper>
    </CommonPopup>
  );
};

export default InviteUser;
