import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
// import { ReactComponent as ExportIcon } from '../../assets/images/download-icon-grey.svg';
import { capitalize, formatText } from '../../helpers/utils';
import HeatMapV2 from '../Charts/HeatMapV2';
import Switch from '../common/switch';

const PaymentRetention = ({ paymentSuccessToggle, setPaymentSuccessToggle }) => {
  const { entrantPaymentStats = [] } = useSelector(state => state.dashboard);
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <div className="divider-border flex mt-4 pxy-4 flex-column radius-4 overflow-scroll">
      <div className="flex justify-between radius-4 mb-6">
        <div className="flex-column">
          <label className="regular-text font-12 lighter-text">{t('DONATIONS')}</label>
          <label className="medium-text font-20">{t('PAYMENT_RETENTION')}</label>
        </div>
        <div className="flex gap-4 items-center">
          <div className="flex-row gap-2 items-center">
            <label className="regular-text font-12 lighter-text items-center">
              {paymentSuccessToggle ? t('SUCCESSFUL') : capitalize(formatText(t('UNSUCCESSFULLY')))}
            </label>
            <Switch
              onClick={() => setPaymentSuccessToggle(!paymentSuccessToggle)}
              disabled={false}
              enabled={paymentSuccessToggle}
              bgColor={paymentSuccessToggle ? theme.colors.ZenBlue : theme.colors.ZenNegative}
            />
          </div>
          {/* <ExportIcon />   */}
        </div>
      </div>
      <HeatMapV2 data={entrantPaymentStats || []} paymentSuccessToggle={paymentSuccessToggle} />
    </div>
  );
};

export default PaymentRetention;
