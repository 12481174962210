import React, { useContext } from 'react';
import styled from 'styled-components';
import { OrganisationContext } from '../../../context/organisationContext';

const Switch = ({ enabled, onClick, permission = '', disabled = false, bgColor, className = '' }) => {
  const { checkPermission } = useContext(OrganisationContext);

  const onSwitchChange = () => {
    checkPermission(() => {
      onClick && onClick();
    }, permission);
  };

  return (
    <SwitchWrapper className={className} bgColor={bgColor}>
      <div
        className={`flex items-center cursor switch ml-1 ${enabled && 'switch-selected'} ${
          disabled ? 'not-allowed' : 'cursor'
        }`}
        onClick={e => {
          e.stopPropagation();
          onSwitchChange();
        }}>
        <div className="selector" />
      </div>
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled('div')`
  .switch {
    width: 36px;
    height: 20px;
    background: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.ZenLightGray)};
    border-radius: 12px;

    &:hover {
      background: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.ZenGray2)};
    }

    .selector {
      min-width: 16px;
      min-height: 16px;
      background: #ffffff;
      border-radius: 50%;
      margin: 0 2px;
      cursor: pointer;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.zenPurple)};

    &:hover {
      background: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.zenPurple)};
    }

    .selector {
      transform: translate(16px);
    }
  }
`;

export default Switch;
