import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as UsersIcon } from '../../../assets/images/user-management.svg';
import Box from '../../../components/common/Box';
import { OrganisationContext } from '../../../context/organisationContext';
import { formatText } from '../../../helpers/utils';
import { SecurityWrapper } from '../../../styles/pages/settings.styled';

const Security = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { checkMultiplePermission } = useContext(OrganisationContext);
  const lstUserManagment = [
    {
      id: uuid(),
      title: t('USERS'),
      subtitle: t('MANAGE_CURRENT_AND_INVITE_USERS'),
      path: `/${location.pathname}/users`,
      disabled: false,
      permissions: ['USERS_MANAGE', 'USERS_VIEW'],
    },
  ];
  return (
    <SecurityWrapper className="flex-column items-start justify-start w-full px-10 py-6">
      <label className="semibold-text font-20 zen-default-text">{t('SETTINGS')}</label>
      <div className="flex items-center justify-start w-full h-auto content-box radius-4 pxy-6  mt-6">
        <div className="flex-column w-50 items-start justify-center h-full">
          <div className="flex w-full items-start justify-start">
            <UsersIcon height={24} width={24} />
            <div className="flex-column flex-1 items-start justify-start ml-5">
              <label className="medium-text font-16 ">{t('USER_MANAGEMENT')}</label>
              <label className="regular-text font-14 subtitle-text grey-text">{t('MANAGE_USER_ROLES_AND_TEAMS')}</label>
            </div>
          </div>
        </div>
        <div className="flex-column items-start justify-start flex-1">
          {lstUserManagment.map((data, index) => (
            <div
              key={data.id}
              className={`w-full ${index === 0 ? '' : 'mt-4'} ${data.disabled ? 'disabled' : ''}`}
              onClick={() =>
                checkMultiplePermission(
                  () => navigate(`${location.pathname}/${formatText(data.title, '')}`),
                  data.permissions,
                )
              }>
              <Box title={data.title} subTitle={data.subtitle} />
            </div>
          ))}
        </div>
      </div>
    </SecurityWrapper>
  );
};

export default Security;
