import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as CancellationIncon } from '../../../../../assets/images/cancellation.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/info.svg';
import { Tooltip } from '../../../../../components/tooltip/tooltip';
import { paymentDeclineCode } from '../../../../../constant/paymentDeclineCode';
import { OrganisationContext } from '../../../../../context/organisationContext';
import { capitalize, formatText, getCurrencySymbol, getPaymentFrequencyText } from '../../../../../helpers/utils';
import {
  prizeDrawEntrantDetails as getPrizeDrawEntrantDetails,
  prizeDrawEntrantOverviewProducts,
} from '../../../../../store/features/prizeDrawSlice';
import Menu from '../../../../common/menu';
import PaymentStatus from '../../../../common/status/payment-status';

const Products = () => {
  const { setModal, hasPermission } = useContext(OrganisationContext);
  const { prizeDrawEntrantOverviewProductsDetails, prizeDrawEntrantDetails } = useSelector(state => state.prizeDraw);
  const { is_anonymized } = prizeDrawEntrantDetails || {};
  const { t } = useTranslation();
  const { entrant_id } = useParams();
  const dispatch = useDispatch();
  const hasEntrantPermission = hasPermission('ENTRANTS_MANAGE');
  const showMoreInfo = status => {
    switch (status) {
      case 'CANCELED':
      case 'CANCEL_PENDING':
      case 'PAST_DUE':
      case 'UNPAID':
        return true;
    }
  };

  const entrantStatus =
    prizeDrawEntrantOverviewProductsDetails &&
    (prizeDrawEntrantOverviewProductsDetails || []).length &&
    prizeDrawEntrantOverviewProductsDetails[0]?.status;

  const showCancelPopup = () => {
    setModal({
      type: 'prizedraw-entrant-action',
      content: {
        entrant: prizeDrawEntrantDetails,
        title: 'Are you sure you want to cancel this entrant’s subscription?',
        subtitle: 'This will stop further charges and will change the entrant to canceled status',
        isCancel: true,
        confirmButtonProps: { label: 'Yes, cancel', className: 'negative' },
        cancelButtonProps: { label: 'Go back!', className: 'primary-white' },
        onSuccess: () => {
          dispatch(getPrizeDrawEntrantDetails(entrant_id));
          dispatch(prizeDrawEntrantOverviewProducts(entrant_id));
        },
      },
    });
  };

  const showReactivePopup = () => {
    setModal({
      type: 'prizedraw-entrant-reactive',
      content: {
        entrant: prizeDrawEntrantDetails,
        title: 'Are you sure you want reactivate this entrant?',
        subtitle: 'The entrant will change to active status and will start further charges.',
        confirmButtonProps: { label: 'Activate', className: 'primary' },
        cancelButtonProps: { label: 'Cancel', className: 'primary-white' },
        onSuccess: () => {
          dispatch(getPrizeDrawEntrantDetails(entrant_id));
          dispatch(prizeDrawEntrantOverviewProducts(entrant_id));
        },
      },
    });
  };

  const menuList = [
    {
      name: t('CANCEL'),
      onClick: () => showCancelPopup(),
      showCondition:
        entrantStatus !== 'CANCEL_PENDING' && entrantStatus !== 'CANCELED' && hasEntrantPermission && !is_anonymized,
    },
    {
      name: 'Reactivate',
      onClick: () => showReactivePopup(),
      showCondition:
        (entrantStatus === 'CANCEL_PENDING' || entrantStatus === 'CANCELED') && hasEntrantPermission && !is_anonymized,
    },
  ];

  const statusDescription = (status, cancellation, last_failed_transaction) => {
    switch (status) {
      case 'CANCELED':
      case 'CANCEL_PENDING':
        return (
          <div className="flex mt-3 divider-top pt-3 justify-between">
            <div>
              <div className="flex-row items-center gap-1">
                <CancellationIncon />
                <label className="regular-text error-text font-12">{cancellation?.reason?.description}</label>
              </div>
              <div className="flex-row gap-2 items-center">
                <label className="regular-text lighter-text font-12">
                  <Tooltip
                    content={
                      <div className="flex-column">
                        <label className="font-12 regular-text">{cancellation?.reason?.name}</label>
                        <label className="font-12 regular-text lighter-text">{cancellation?.reason?.description}</label>
                      </div>
                    }
                    placement="bottom-start">
                    {t('CONTACTED_BY')} {formatText(cancellation?.cancelled_by)}
                  </Tooltip>
                </label>
                <label className="dot-3-lighter-text"></label>
                <label className="regular-text lighter-text font-12">
                  Contacted client? {cancellation?.contacted_client ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <label className="regular-text font-12 lighter-text">{t('CANCELLATION_REASON')}</label>
          </div>
        );
      case 'UNPAID':
        return cancellation?.reason ? (
          <div className="flex mt-3 divider-top pt-3 justify-between">
            <div>
              <div className="flex-row items-center gap-1">
                <InfoIcon />
                <label className="regular-text font-14">{cancellation?.reason?.description}</label>
              </div>
              <div className="flex-row gap-2 items-center">
                {last_failed_transaction && (
                  <label className="regular-text lighter-text font-12">
                    <Tooltip
                      content={
                        <div className="flex-column">
                          <label className="font-12 regular-text">{cancellation?.reason?.name}</label>
                          <label className="font-12 regular-text lighter-text">
                            {cancellation?.reason?.description}
                          </label>
                        </div>
                      }
                      placement="bottom-start">
                      Failed charge on {moment(last_failed_transaction * 1000).format('MMM DD, YYYY')}
                    </Tooltip>
                  </label>
                )}
              </div>
            </div>
            <label className="regular-text font-12 lighter-text">{t('MOST_RECENT_DECLINE_CODE')}</label>
          </div>
        ) : (
          <></>
        );

      case 'PAST_DUE':
        return (
          <div className="flex mt-3 divider-top pt-3 justify-between">
            <div>
              <div className="flex-row items-center gap-1">
                <CancellationIncon />
                <label className="regular-text error-text font-12">{cancellation?.reason?.description}</label>
              </div>
              <div className="flex-row gap-2 items-center">
                {last_failed_transaction && (
                  <label className="regular-text lighter-text font-12">
                    <Tooltip
                      content={
                        <div className="flex-column">
                          <label className="font-12 regular-text">{cancellation?.reason?.name}</label>
                          <label className="font-12 regular-text lighter-text">
                            {cancellation?.reason?.description}
                          </label>
                        </div>
                      }
                      placement="bottom-start">
                      Failed charge on {moment(last_failed_transaction * 1000).format('MMM DD, YYYY')}
                    </Tooltip>
                  </label>
                )}
              </div>
            </div>
            <label className="regular-text font-12 lighter-text">{t('MOST_RECENT_DECLINE_CODE')}</label>
          </div>
        );
    }
  };

  useEffect(() => {
    dispatch(prizeDrawEntrantOverviewProducts(entrant_id));
  }, []);

  return prizeDrawEntrantOverviewProductsDetails?.length > 0 ? (
    <div className="px-6 pt-6 flex flex-column">
      <label className="medium-text font-16 lighter-text divider-bottom w-full flex pb-2">{t('PRODUCT')}</label>
      {prizeDrawEntrantOverviewProductsDetails?.map((item, index) => {
        const {
          cancellation,
          currency,
          current_period,
          last_failed_transaction,
          payment_amount,
          product,
          recurring,
          status,
        } = item;

        return (
          <div className="border radius-4 py-3 px-4 mt-4">
            <div
              className={`flex-row justify-between items-center ${
                (status === 'PAST_DUE' || status === 'UNPAID') && last_failed_transaction ? 'border-bottom' : ''
              }`}>
              <div className="flex-column gap-1">
                <div className="flex-row gap-2 items-center ">
                  <label className="regular-text font-14 ">{product?.name}</label>
                  <PaymentStatus withDot status={status} statusText={status === 'PAST_DUE' ? 'Past Due' : ''} />
                </div>
                <div className="flex-row gap-2 items-center mt-1">
                  <label className="regular-text lighter-text font-12">
                    {getCurrencySymbol(currency)}
                    {payment_amount} {getPaymentFrequencyText(recurring?.interval)}
                  </label>
                  <div className="flex-row gap-2 items-center">
                    <label className="dot-3-lighter-text"></label>
                    <label className="regular-text lighter-text font-12">
                      {status === 'CANCELED'
                        ? `${t('CANCELED_ON')} ${moment(cancellation?.requested_on * 1000).format('MMM DD, YYYY')}`
                        : `${t('NEXT_PAYMENT_ON')} ${moment(current_period?.end_date * 1000).format('MMM DD, YYYY')}`}
                    </label>
                  </div>

                  <label className="dot-3-lighter-text"></label>
                  <label className="regular-text lighter-text font-12">{product?.external_reference}</label>
                </div>
              </div>
              {index === 0 && menuList.filter(m => m.showCondition).length > 0 && (
                <Menu menuClassName="flex items-center h-full" menuList={menuList.filter(m => m.showCondition)} />
              )}
            </div>
            {(status === 'PAST_DUE' || status === 'UNPAID' || status === 'CANCELED') && last_failed_transaction && (
              <>
                <div className="flex-row justify-between pt-3">
                  <div className="flex justify-between w-full">
                    <Tooltip
                      content={
                        <div className="flex-column items-start justify-start pxy-1">
                          <div className="flex items-center justify-start w-auto">
                            {last_failed_transaction?.decline_code && (
                              <label className="medium-text font-12 flex-1 one-line">
                                {capitalize(formatText(last_failed_transaction?.decline_code, ' '))}
                              </label>
                            )}
                          </div>
                          {paymentDeclineCode?.[last_failed_transaction?.decline_code] && (
                            <div className="flex items-center justify-start w-full mt-1">
                              <label className="regular-text lighter-text font-12 break-word wrap">
                                {paymentDeclineCode?.[last_failed_transaction?.decline_code]}
                              </label>
                            </div>
                          )}
                        </div>
                      }
                      placement={'bottom-start'}>
                      <div className="flex-column cursor">
                        <div className="flex-row gap-2 items-center justify-between pb-1 w-full">
                          <div className="flex gap-2 items-center">
                            <InfoIcon />
                            {last_failed_transaction?.decline_code && (
                              <label className="regular-text">
                                {capitalize(formatText(last_failed_transaction?.decline_code, ' '))}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="flex-row gap-2 items-center ">
                          {last_failed_transaction?.date && (
                            <label className="regular-text lighter-text font-12">
                              Failed charge on{' '}
                              {last_failed_transaction?.date &&
                                moment.unix(last_failed_transaction?.date).format('MMM DD, YY')}
                            </label>
                          )}
                        </div>
                      </div>
                    </Tooltip>
                    <label className="regular-text lighter-text font-12 ">Most recent decline code</label>
                  </div>
                </div>
              </>
            )}
            {showMoreInfo(status) && statusDescription(status, cancellation, last_failed_transaction)}
          </div>
        );
      })}
    </div>
  ) : null;
};

export default Products;
