import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const NoteCreated = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Note added'}
        subtitle={log?.tenant?.id || 'Entrant'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">added a note to entrant</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {log?.event_data?.entrant?.urn}
            </span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <LogContentBox
          title={'Note'}
          contentText={
            <p className="regular-text lighter-text font-12 word-break-all">{log?.event_data?.note?.note}</p>
          }
        />
      )}
    </div>
  );
};

export default NoteCreated;
