import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as AddIcon } from '../../../../assets/images/cross.svg';
import { ConfigurationWrapper } from '../../../../styles/components/automationDetails/automation-details.styled';
import Loader from '../../../common/loader';
import AutomationDetailsHeader from '../automation-details-header';
import AddEditAutomationLogAction from './add-edit-automation-action';
import AutomationLogAction from './automation-action';
import AutomationTrigger from './automation-trigger';

const Configuration = () => {
  const { automationDetails, automationConfigLoading, automationSaveDetails } = useSelector(state => state.automations);
  const { t } = useTranslation();
  const [showAutomationAction, setShowOpenAutomationAction] = useState(false);
  const [editAutomationActionData, setEditAutomationActionData] = useState({});
  const [editAutomationActionType, setEditAutomationActionType] = useState('');

  const handleAction = () => {
    setShowOpenAutomationAction(true);
  };

  useEffect(() => {
    setShowOpenAutomationAction(false);
  }, [automationSaveDetails]);

  const onEditAutomationAction = (action, type) => {
    setEditAutomationActionData(action);
    setEditAutomationActionType(type);
  };

  return (
    <ConfigurationWrapper className="flex-column items-start justify-start w-full h-full">
      <AutomationDetailsHeader title={'Configuration'} />
      <div className="flex-column flex-1 items-start justify-start w-full px-6 pb-6 overflow-scroll relative">
        {automationConfigLoading ? (
          <Loader />
        ) : (
          <>
            <AutomationTrigger automationTrigger={automationDetails?.event_type} />
            {(automationDetails?.actions || [])?.map((action, index) =>
              editAutomationActionData?.id === action.id ? (
                <AddEditAutomationLogAction
                  length={index + 1}
                  editAutomationActionData={editAutomationActionData}
                  setEditAutomationActionData={data => setEditAutomationActionData(data)}
                  editAutomationActionType={editAutomationActionType}
                />
              ) : (
                <AutomationLogAction
                  automationAction={action}
                  index={index}
                  setEditAutomationActionData={data => setEditAutomationActionData(data)}
                  onEditAutomationAction={onEditAutomationAction}
                />
              ),
            )}
            {showAutomationAction || (automationDetails?.event_type && automationDetails?.actions?.length === 0) ? (
              <AddEditAutomationLogAction
                length={automationDetails?.actions?.length + 1}
                editAutomationActionData={editAutomationActionData}
                setEditAutomationActionData={data => setEditAutomationActionData(data)}
              />
            ) : null}
            {automationDetails?.event_type && (
              <div
                className="flex items-center justify-center w-full footer-btn mt-6 pxy-2 radius-3 relative cursor"
                onClick={handleAction}>
                <AddIcon />
                <label className="normal-text ml-2">{t('ADD_NEW_ACTION')}</label>
              </div>
            )}
          </>
        )}
      </div>
    </ConfigurationWrapper>
  );
};

export default Configuration;
