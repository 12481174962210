import React from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from '../../components/McDropdown';
import AddressInput from '../../components/common/address-input';
import { stateOptions } from '../../constant/optionData';

const stateOptionsWithNameAbbreviation = stateOptions.map(state => ({ ...state, name: state.abbreviation }));

const Address = ({
  address = {},
  handleChange = () => {},
  error = {},
  customLabels = { line1: 'ADDRESS_LINE_1', line2: 'ADDRESS_LINE_2' },
  wrapperClassName = '',
  labelClassName = '',
}) => {
  const { t } = useTranslation();

  return (
    <div className={`flex-column items-center justify-center w-full row-gap-6 ${wrapperClassName}`}>
      <div className="flex-column w-full row-gap-2 line-1">
        <label className={`regular-text lighter-text font-12 ${labelClassName}`}>{t(customLabels.line1)}</label>
        <AddressInput
          address={{ ...address, label: address.line1 }}
          setSelectedAddress={add => handleChange('address', add)}
          error={error.line1 ? true : false}
          wrapperClassName="address-input"
        />
      </div>
      <div className="flex-column w-full row-gap-2 line-2">
        <label className={`regular-text lighter-text font-12 ${labelClassName}`}>{t(customLabels.line2)}</label>
        <input
          autoComplete="turnoff"
          className={`input ${error.line2 && 'error-border'}`}
          onChange={e => handleChange('line2', e.target.value)}
          placeholder={t('ENTER_ADDRESS')}
          value={address?.line2 || ''}
        />
      </div>
      <div className="flex-column w-full row-gap-2 city">
        <label className={`regular-text lighter-text font-12 ${labelClassName}`}>{t('CITY')}</label>
        <input
          autoComplete="turnoff"
          className={`input ${error?.city && 'error-border'}`}
          onChange={e => handleChange('city', e.target.value)}
          placeholder={t('ENTER_CITY')}
          value={address?.city || ''}
        />
      </div>
      <div className="flex-row items-center justify-center w-full col-gap-6 row-gap-2">
        <div className="flex-column row-gap-2 w-full state">
          <label className={`regular-text lighter-text font-12 ${labelClassName}`}>{t('STATE')}</label>
          <DropDown
            options={stateOptionsWithNameAbbreviation}
            selected={stateOptionsWithNameAbbreviation.find(state => state.abbreviation === address?.state)}
            setSelected={option => handleChange('state', option.abbreviation)}
            placeholder={t('SELECT_STATE')}
            className={`w-full state-dropdown ${error?.state && 'error-border'}`}
          />
        </div>
        <div className="flex-column row-gap-2 w-full zip-code">
          <label className={`regular-text lighter-text font-12 ${labelClassName}`}>{t('ZIP_CODE')}</label>
          <input
            autoComplete="turnoff"
            className={`input w-full ${error?.postcode && 'error-border'}`}
            onChange={e => handleChange('postcode', e.target.value)}
            placeholder={t('ENTER_ZIPCODE')}
            value={address?.postcode || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
