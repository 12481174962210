import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { cancelAutomationLogs } from '../store/features/automationsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AutomationLogCancelWrapper } from './modal.styled';

const AutomationLogCancel = () => {
  const [loading, setLoading] = useState(false);
  const { modal, setModal } = useContext(OrganisationContext);
  const { automationLog } = modal.content;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDone = () => {
    setLoading(true);
    dispatch(cancelAutomationLogs({ automationLogId: automationLog?.id }))
      .then(response => {
        setModal(initModal);
        setLoading(false);
      })
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: 'Something went wrong',
            id: uuid(),
          }),
        );
        setLoading(false);
      });
  };

  return (
    <CommonPopup
      popupTitle="Are you sure you want to cancel this automation?"
      onCancel={() => setModal(initModal)}
      onConfirm={handleDone}
      disabled={loading}
      confirmButtonProps={{ label: 'Cancel', className: 'negative' }}
      cancelButtonProps={{ label: 'Keep' }}>
      <AutomationLogCancelWrapper className="w-full">
        <label className="regular-text font-14 grey-text flex justify-center">
          You will not be able to un-do this action
        </label>
        <div className="bg-backgroundClr radius-4 mt-6 pxy-4 flex-column">
          <div className="font-16 semibold-text">{automationLog?.automation?.name}</div>
          <div className="pt-4">
            <div className="font-14 lighter-text">{t('ID')}</div>
            <label className="flex regular-text mt-1 font-14">{automationLog && automationLog?.id}</label>
          </div>
          <div className="pt-4">
            <div className="font-14 lighter-text">{t('DATE')}</div>
            <label className="flex regular-text mt-1 font-14">
              {moment(automationLog && automationLog?.created_on * 1000).format('MM/DD/YY - hh:mm a')}
            </label>
          </div>
        </div>
      </AutomationLogCancelWrapper>
    </CommonPopup>
  );
};

export default AutomationLogCancel;
