import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy-icons.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as MailIcon } from '../../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/images/mobile.svg';
import { ReactComponent as Request } from '../../../../assets/images/request.svg';
import { ReactComponent as Stripe } from '../../../../assets/images/stripe.svg';
import { ReactComponent as EmptyImage } from '../../../../assets/images/subtract.svg';
import Status from '../../../../components/common/status';
import { OrganisationContext } from '../../../../context/organisationContext';
import { capitalize, emailRegEx, formatText, handleCopyClick, isDataChanged } from '../../../../helpers/utils';
import Address from '../../../../popup/add-account/address';
import {
  createContactAddress,
  createEmail,
  createPhone,
  prizeDrawEntrantDetails as getPrizeDrawEntrantDetails,
  prizeDrawEntrantDetails as setPrizeDrawEntrantDetail,
  updateContact,
  updateContactAddress,
  updateEmail,
  updatePhone,
} from '../../../../store/features/prizeDrawSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { PrizeDrawDetailEntrantLeftWrapper } from '../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import DropDown from '../../../McDropdown';
import Loader from '../../../common/loader';
import Menu from '../../../common/menu';
import PhoneInputUs from '../../../common/phone-input-us';
import UpdateAction from '../../../common/update-action';

const PrizeDrawEntrantDetailsLeft = () => {
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  const { hasPermission, setModal } = useContext(OrganisationContext);
  const hasEntrantPermission = hasPermission('ENTRANTS_MANAGE');

  const { entrant_id } = useParams();
  const { prizeDrawEntrantDetails, prizeDrawEntrantOverviewProductsDetails } = useSelector(state => state.prizeDraw);
  const {
    contact,
    created_on,
    out_of_draw_date,
    urn,
    win_status,
    draw_status,
    prizedraw,
    is_anonymized,
    external_reference,
  } = prizeDrawEntrantDetails || {};
  const entrantStatus =
    prizeDrawEntrantOverviewProductsDetails &&
    (prizeDrawEntrantOverviewProductsDetails || []).length &&
    prizeDrawEntrantOverviewProductsDetails[0]?.status;

  const { image, name: prizeDrawName, code, lifecycle, description } = prizedraw || {};
  const { emails, phones, name, forename, surname, title } = contact || {};
  const contactEmail = emails ? emails?.find(e => e.is_primary) || emails?.[0] : null;
  const contactPhone = phones ? phones?.find(p => p.is_primary) || phones?.[0] : null;
  const contactHome = phones
    ? phones?.find(p => p.is_primary && p.contact_type === 'LANDLINE') ||
      phones?.find(p => p.contact_type === 'LANDLINE')
    : null;
  const [updatePrizedrawDetail, setUpdatePrizedrawDetail] = useState({ type: '', value: '' });

  const options = [
    { name: 'None' },
    { name: 'Mr' },
    { name: 'Mrs' },
    { name: 'Ms' },
    { name: 'Miss' },
    { name: 'Dr' },
    { name: 'Other' },
  ];

  const menuList = [
    {
      name: t('ANONYMIZE'),
      onClick: () => showAnonymisePopup(),
      showCondition: entrantStatus === 'CANCELED' && !is_anonymized && hasEntrantPermission,
    },
  ];

  const dispatch = useDispatch();
  const onCancelUpdate = () => {
    setUpdatePrizedrawDetail({ type: '', value: '' });
  };

  const getSelectedTitle = () => {
    const selectedTitle = { name: updatePrizedrawDetail?.value.title || 'Mr' };
    return selectedTitle;
  };

  const getAddressByType = type => {
    const addresses = contact?.addresses || [];
    const addressesByType = [...addresses].reverse().filter(a => a[type]) || [];
    return addressesByType.find(a => a.is_primary) || addressesByType[0] || addresses[0] || {};
  };

  const entrantBillingAddress = getAddressByType('is_billing');
  const entrantShippingAddress = getAddressByType('is_postal');

  const handleCopy = async url => {
    const resp = await handleCopyClick(url);
    if (resp) {
      dispatch(
        addToast({
          text: 'External reference copied to your clipboard',
        }),
      );
    }
  };
  const emptyImageContent = () => {
    return (
      <div className="img-container border flex items-center justify-center radius-4 empty-img-content cursor mr-4">
        <EmptyImage className="empty-img" />
      </div>
    );
  };

  const homeStatus = () => {
    if (contactPhone && contactHome) {
      return true;
    }
    return false;
  };

  const imageContent = url => {
    return (
      <div className="flex items-center justify-center radius-4 img-container relative mr-4">
        <img
          className="flex items-center justify-center radius-2"
          src={url}
          style={{ objectFit: 'cover', height: '64px', width: '64px' }}
        />
      </div>
    );
  };

  const renderAddressByType = (type, customClassName = '') => {
    const address = type === 'is_postal' ? entrantShippingAddress : entrantBillingAddress;
    const addressType = type === 'is_postal' ? 'Shipping' : 'Billing';
    if (!address) {
      return null;
    }
    return (
      <label className={`regular-text line-21 ${customClassName}`}>
        {address.formatted_address}
        <span className={`font-10 medium-text line-16 address-status ${addressType}`}>{addressType}</span>
      </label>
    );
  };

  const onUpdate = async () => {
    const names = updatePrizedrawDetail.value.name.trim().split(' ');

    if (names.length < 1) {
      dispatch(addToast({ error: true, text: 'Please enter a name', id: uuid() }));
      return;
    }

    const surname = names.pop();
    const forename = names.join(' ');
    dispatch(
      updateContact({
        entrant_id,
        id: contact?.id,
        request: {
          surname,
          forename,
          title: updatePrizedrawDetail?.value?.title,
        },
      }),
    )
      .then(() => {
        setUpdatePrizedrawDetail({ type: '', value: '' });
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Error while updating details', id: uuid() }));
      });
  };

  const isValidPhoneNumber = phoneNumber => {
    const numericPhoneNumber = phoneNumber.replaceAll('-', '').replaceAll(' ', '');
    return numericPhoneNumber.length === 10;
  };

  const validateContactDetails = (email, mobile, homeNumber) => {
    const errors = {};

    if (!email) {
      errors.email = 'Required Field Missing';
    } else if (!emailRegEx.test(email)) {
      errors.email = 'Please enter valid email';
    }

    if (!mobile && !homeNumber) {
      errors.mobile = 'Required Field Missing';
      errors.homeNumber = 'Required Field Missing';
    }

    if (mobile && !isValidPhoneNumber(mobile)) {
      errors.mobile = 'Please enter valid phone number';
    } else if (homeNumber && !isValidPhoneNumber(homeNumber)) {
      errors.homeNumber = 'Please enter valid phone number';
    }

    return errors;
  };

  const handlePhoneAndEmailUpdate = async (updateFunction, updateId, createFunction, request) => {
    if (updateId) {
      return dispatch(updateFunction({ contact_id: contact?.id, contact_value_id: updateId, request }));
    } else {
      return dispatch(createFunction({ request, contact_id: contact?.id }));
    }
  };

  const onContactUpdate = async () => {
    setError({});

    const { email, mobile, mobileId, emailId, countryCodeMobile, countryCodeHome, homeNumber, homeId } =
      updatePrizedrawDetail.value;

    const errors = validateContactDetails(email, mobile, homeNumber);
    setError(errors);

    if (Object.keys(errors).length > 0) {
      setError(errors);
      dispatch(addToast({ error: true, text: errors.email || errors.mobile || errors.homeNumber }));
      return;
    }

    try {
      const mobileRequest = {
        contact_type: 'MOBILE',
        contact_value: mobile?.toLowerCase().replaceAll('-', '').replaceAll(' ', ''),
        is_primary: true,
        phone_type: 'WORK',
        country_code: countryCodeMobile ? countryCodeMobile : '+1',
      };

      const landLineRequest = {
        contact_type: 'LANDLINE',
        contact_value: homeNumber?.toLowerCase().replaceAll('-', '').replaceAll(' ', ''),
        is_primary: true,
        phone_type: 'WORK',
        country_code: countryCodeHome ? countryCodeHome : '+1',
      };

      const emailRequest = { email_type: 'WORK', value: email, is_primary: true };

      const mobileUpdatePromise = isDataChanged('INPUT', mobile || '', contactPhone?.value || '')
        ? mobileRequest.contact_value || (!mobileRequest.contact_value && mobileId)
          ? handlePhoneAndEmailUpdate(updatePhone, mobileId, createPhone, mobileRequest)
          : null
        : null;

      const homeNumberUpdate = isDataChanged('INPUT', homeNumber || '', contactHome?.value || '')
        ? landLineRequest.contact_value || (!landLineRequest.contact_value && homeId)
          ? handlePhoneAndEmailUpdate(updatePhone, homeId, createPhone, landLineRequest)
          : null
        : null;

      const emailUpdatePromise = isDataChanged('INPUT', email || '', contactEmail?.value || '')
        ? handlePhoneAndEmailUpdate(updateEmail, emailId, createEmail, emailRequest)
        : null;

      await Promise.all([mobileUpdatePromise, emailUpdatePromise, homeNumberUpdate]);

      onCancelUpdate();
      dispatch(getPrizeDrawEntrantDetails(entrant_id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddressChange = (field, value, type) => {
    const prizeDrawDetailsValues = updatePrizedrawDetail.value;
    const prizeDrawDetailsErrors = updatePrizedrawDetail.errors;
    let updatedData = prizeDrawDetailsValues;
    let updatedError = prizeDrawDetailsErrors;

    if (field === 'address') {
      updatedData = { ...prizeDrawDetailsValues, [type]: { ...prizeDrawDetailsValues?.[type], ...value } };
      updatedError = { ...prizeDrawDetailsErrors, [type]: {} };
    } else if (field === 'postcode') {
      if (!isNaN(Number(value))) {
        updatedData = { ...prizeDrawDetailsValues, [type]: { ...prizeDrawDetailsValues?.[type], [field]: value } };
        updatedError = { ...prizeDrawDetailsErrors, [type]: { ...prizeDrawDetailsErrors?.[type], [field]: false } };
      }
    } else {
      updatedData = { ...prizeDrawDetailsValues, [type]: { ...prizeDrawDetailsValues?.[type], [field]: value } };
      updatedError = { ...prizeDrawDetailsErrors, [type]: { ...prizeDrawDetailsErrors?.[type], [field]: false } };
    }
    setUpdatePrizedrawDetail(prevState => ({
      ...prevState,
      value: updatedData,
      errors: updatedError,
    }));
  };

  const checkAddressInfoErrors = address => {
    if (!address?.line1?.trim() || !address?.state?.trim() || !address?.city?.trim() || !address?.postcode?.trim()) {
      return {
        line1: !address?.line1?.trim(),
        state: !address?.state?.trim(),
        city: !address?.city?.trim(),
        postcode: !address?.postcode?.trim(),
      };
    } else {
      return {};
    }
  };

  const onUpdateAddress = async () => {
    const { shippingAddress, billingAddress } = updatePrizedrawDetail?.value || {};
    const shippingAddressErrors = checkAddressInfoErrors(shippingAddress);
    const billingAddressErrors = checkAddressInfoErrors(billingAddress);
    if (Object.values(shippingAddressErrors).some(err => err) || Object.values(billingAddressErrors).some(err => err)) {
      setUpdatePrizedrawDetail(prevState => ({
        ...prevState,
        errors: { shippingAddress: shippingAddressErrors, billingAddress: billingAddressErrors },
      }));
      dispatch(addToast({ error: true, text: 'Please fill address line 1, state, city and zip code' }));
      return;
    }
    const {
      line1: shipLine1,
      line2: shipLine2,
      city: shipCity,
      state: shipState,
      postcode: shipPostCode,
      id: shipId,
      is_postal,
      is_billing: shipBilling,
    } = shippingAddress;
    const {
      line1: billLine1,
      line2: billLine2,
      city: billCity,
      state: billState,
      postcode: billPostCode,
      id: billId,
      is_billing,
      is_postal: billingPostal,
    } = billingAddress;
    const shippingFormattedAddress = [shipLine1, shipLine2, shipCity, shipState, shipPostCode]
      .filter(Boolean)
      .join(', ');
    const billingFormattedAddress = [billLine1, billLine2, billCity, billState, billPostCode]
      .filter(Boolean)
      .join(', ');
    try {
      if (isDataChanged('ADDRESS', shippingAddress, entrantShippingAddress)) {
        const shippingAddressData =
          shipId && is_postal && !shipBilling
            ? dispatch(
                updateContactAddress({
                  contact_id: contact?.id,
                  contact_address_id: shippingAddress.id,
                  request: {
                    ...shippingAddress,
                    formatted_address: shippingFormattedAddress,
                  },
                }),
              )
            : dispatch(
                createContactAddress({
                  contact_id: contact?.id,
                  request: {
                    ...shippingAddress,
                    formatted_address: shippingFormattedAddress,
                    is_postal: true,
                    is_primary: true,
                    is_billing: false,
                  },
                }),
              );

        await shippingAddressData;
      }
      if (isDataChanged('ADDRESS', billingAddress, entrantBillingAddress)) {
        const billingAddressData =
          billId && is_billing && !billingPostal
            ? dispatch(
                updateContactAddress({
                  contact_id: contact?.id,
                  contact_address_id: billingAddress.id,
                  request: {
                    ...billingAddress,
                    formatted_address: billingFormattedAddress,
                  },
                }),
              )
            : dispatch(
                createContactAddress({
                  contact_id: contact?.id,
                  request: {
                    ...billingAddress,
                    formatted_address: billingFormattedAddress,
                    is_billing: true,
                    is_primary: true,
                    is_postal: false,
                  },
                }),
              );

        await billingAddressData;
      }
      onCancelUpdate();
      dispatch(setPrizeDrawEntrantDetail(entrant_id));
    } catch (error) {
      dispatch(addToast({ error: true, text: 'Addresses cannot be updated' }));
    }
  };

  const showAnonymisePopup = () => {
    setModal({
      type: 'anonymize',
      content: {
        title: 'Are you sure you want to anonymize this entrant?',
        subtitle:
          'WARNING this will result in all entrant’s personal data to be permanently deleted and changed to out of draw status. This action is not reversible - please be certain.',
        description: urn,
        entrant_id: entrant_id,
      },
    });
  };

  const nameField =
    updatePrizedrawDetail.type === 'name' ? (
      <div className="update-detail-contaner relative flex gap-4">
        <div className="flex flex-column gap-1">
          <label className="regular-text font-12 grey-text">{t('TITLE')}</label>
          <DropDown
            options={options}
            placeholder={'Title'}
            className="title-input"
            selected={getSelectedTitle()}
            setSelected={value =>
              setUpdatePrizedrawDetail({
                ...updatePrizedrawDetail,
                value: {
                  ...updatePrizedrawDetail.value,
                  title: value?.name,
                },
              })
            }
          />
        </div>
        <div className="flex flex-column gap-1">
          <label className="regular-text font-12 grey-text">{t('NAME')}</label>
          <input
            className="input w-full edit-input"
            value={updatePrizedrawDetail.value?.name}
            onChange={({ target }) =>
              setUpdatePrizedrawDetail({
                ...updatePrizedrawDetail,
                value: { ...updatePrizedrawDetail.value, name: target.value },
              })
            }
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className="update-action-container absolute bottom-right">
          <UpdateAction
            onCancel={onCancelUpdate}
            onUpdate={() => {
              if (
                isDataChanged('INPUT', updatePrizedrawDetail?.value?.title || '', title || '') ||
                isDataChanged('INPUT', updatePrizedrawDetail?.value?.name || '', forename + ' ' + surname || '')
              ) {
                onUpdate();
              } else {
                onCancelUpdate();
              }
            }}
          />
        </div>
      </div>
    ) : (
      <div>
        <label className="regular-text font-12 grey-text">{t('NAME')}</label>
        <div
          className={`flex items-center py-1 mt-1 ${
            !is_anonymized && hasEntrantPermission && 'cursor content-hover-edit'
          } w-full`}
          onClick={() => {
            if (!is_anonymized && hasEntrantPermission) {
              setUpdatePrizedrawDetail({
                type: 'name',
                value: { name: forename + ' ' + surname, title: title },
              });
            }
          }}>
          <span className="semibold-text font-16 flex-1 one-line hover-margin mr-10 w-full">
            {is_anonymized ? (
              <div className="font-16 semibold-text grey-secondary-text">{t('ANONYMIZE')}</div>
            ) : hasPermission('PII_VIEW') ? (
              <span>
                {title === 'Other' && title === 'None' ? title + ' ' : ''}
                {forename} {surname}
              </span>
            ) : (
              <div className="font-16 semibold-text grey-secondary-text">{t('ENTRANTS')}</div>
            )}
          </span>
          {!is_anonymized && hasEntrantPermission && <EditIcon width={16} height={16} className="copy-icon" />}
        </div>
      </div>
    );

  const handleValueChange = (value, field) => {
    setUpdatePrizedrawDetail(prevDetail => ({
      ...prevDetail,
      value: {
        ...prevDetail.value,
        [field]: value,
      },
    }));
  };

  const contactField =
    updatePrizedrawDetail.type === 'contact' ? (
      <div className="relative">
        <div className="flex-column gap-4 relative pt-6">
          <div className="flex-column gap-1">
            <label className="normal-text font-12 grey-text">{t('EMAIL')}</label>
            <input
              className={`input w-full ${error.email && 'required-error'}`}
              value={updatePrizedrawDetail?.value?.email}
              placeholder="Email"
              onChange={e => {
                handleValueChange(e.target.value, 'email');
                setError(prevError => ({
                  ...prevError,
                  email: false,
                }));
              }}
            />
          </div>
          <div className="flex-column gap-1">
            <label className="normal-text font-12 grey-text">{t('MOBILE')}</label>
            <PhoneInputUs
              className={`${error.mobile && 'error-phone'}`}
              phone={updatePrizedrawDetail?.value?.mobile}
              setPhone={value => {
                handleValueChange(value, 'mobile');
                setError(prevError => ({
                  ...prevError,
                  mobile: false,
                  homeNumber: false,
                }));
              }}
            />
          </div>
          <div className="flex-column gap-1">
            <label className="normal-text font-12 grey-text">{t('HOME')}</label>
            <PhoneInputUs
              className={`${error.homeNumber && 'error-phone'}`}
              phone={updatePrizedrawDetail?.value?.homeNumber}
              setPhone={value => {
                handleValueChange(value, 'homeNumber');
                setError(prevError => ({
                  ...prevError,
                  mobile: false,
                  homeNumber: false,
                }));
              }}
            />
          </div>
          <div className="update-action-container absolute bottom-right">
            <UpdateAction
              onCancel={onCancelUpdate}
              onUpdate={() => {
                if (
                  isDataChanged('INPUT', updatePrizedrawDetail?.value?.email || '', contactEmail?.value || '') ||
                  isDataChanged('INPUT', updatePrizedrawDetail?.value?.mobile || '', contactPhone?.value || '') ||
                  isDataChanged('INPUT', updatePrizedrawDetail?.value?.homeNumber || '', contactHome?.value || '')
                ) {
                  onContactUpdate();
                } else {
                  onCancelUpdate();
                }
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="flex-column pt-6 gap-1">
        <label className="regular-text font-12 grey-text">Contact</label>
        <div
          className={`${hasEntrantPermission && 'content-hover-edit cursor'} justify-between py-1`}
          onClick={() => {
            if (hasEntrantPermission) {
              setUpdatePrizedrawDetail({
                type: 'contact',
                value: {
                  mobile: contactPhone?.value,
                  mobileId: contactPhone?.id,
                  countryCodeMobile: '+1',
                  email: contactEmail?.value,
                  emailId: contactEmail?.id,
                  homeNumber: contactHome?.value,
                  homeId: contactHome?.id,
                  countryCodeHome: '+1',
                },
              });
            }
          }}>
          <label className="regular-text font-14 flex flex-column gap-2">
            <div className="flex flex-row items-center gap-2">
              <MailIcon />
              <span className={`flex-1 one-line ${!contactEmail?.value && 'lighter-text'}`}>
                {contactEmail?.value || 'no data'}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              {contactPhone && (
                <div className="flex-row gap-2 items-center">
                  <MobileIcon />
                  <span className={`${!contactPhone?.value && 'lighter-text'}`}>
                    {contactPhone?.value ? `${contactPhone?.country_code}${contactPhone?.value}` : 'no data'}
                  </span>
                  {homeStatus() && (
                    <Status
                      statusTextClassName="font-10"
                      status={contactPhone?.contact_type === 'LANDLINE' ? 'HOME' : contactPhone?.contact_type}
                    />
                  )}
                </div>
              )}
              {contactHome && (
                <div className="flex-row gap-2 items-center">
                  <MobileIcon />
                  <span className={`${!contactHome?.value && 'lighter-text'}`}>
                    {contactHome?.value ? `${contactHome?.country_code}${contactHome?.value}` : 'no data'}
                  </span>
                  {homeStatus() && (
                    <Status
                      statusTextClassName="font-10"
                      status={contactHome?.contact_type === 'LANDLINE' ? 'HOME' : contactPhone?.contact_type}
                    />
                  )}
                </div>
              )}
            </div>
          </label>
          {hasEntrantPermission && <EditIcon width={16} height={16} className="copy-icon" />}
        </div>
      </div>
    );

  const addressFields =
    updatePrizedrawDetail.type === 'address' ? (
      <div className="relative">
        <label className="flex semibold-text font-14 mb-2 mt-6">Shipping</label>
        <Address
          address={{ ...updatePrizedrawDetail?.value?.shippingAddress }}
          handleChange={(key, value) => handleAddressChange(key, value, 'shippingAddress')}
          customLabels={{ line1: 'ADDRESS', line2: 'ADDRESS_2' }}
          wrapperClassName="row-gap-4 address-fields"
          error={{ ...updatePrizedrawDetail?.errors?.shippingAddress }}
          labelClassName="grey-text"
        />
        <label className="flex semibold-text font-14 mb-2 mt-6">Billing</label>
        <Address
          address={{ ...updatePrizedrawDetail?.value?.billingAddress }}
          handleChange={(key, value) => handleAddressChange(key, value, 'billingAddress')}
          customLabels={{ line1: 'ADDRESS', line2: 'ADDRESS_2' }}
          wrapperClassName="address-fields"
          error={{ ...updatePrizedrawDetail?.errors?.billingAddress }}
          labelClassName="grey-text"
        />
        <div className="update-action-container absolute bottom-right">
          <UpdateAction
            onCancel={onCancelUpdate}
            onUpdate={() => {
              if (
                isDataChanged(
                  'ADDRESS',
                  updatePrizedrawDetail?.value?.billingAddress || {},
                  entrantBillingAddress || {},
                ) ||
                isDataChanged(
                  'ADDRESS',
                  updatePrizedrawDetail?.value?.shippingAddress || {},
                  entrantShippingAddress || {},
                )
              ) {
                onUpdateAddress();
              } else {
                onCancelUpdate();
              }
            }}
          />
        </div>
      </div>
    ) : (
      <div
        className="pt-6 flex-column gap-1"
        onClick={() => {
          if (hasEntrantPermission) {
            setUpdatePrizedrawDetail({
              type: 'address',
              value: {
                billingAddress: { ...entrantBillingAddress },
                shippingAddress: { ...entrantShippingAddress },
              },
            });
          }
        }}>
        <label className="regular-text font-12 grey-text flex-column">{t('ADDRESS')}</label>
        <div
          className={`${
            hasEntrantPermission && 'content-hover-edit cursor'
          } flex justify-between py-1 w-full gap-4 pr-10`}>
          <div className="flex-column flex-1">
            {renderAddressByType('is_postal', 'mb-4')}
            {renderAddressByType('is_billing')}
          </div>
          {hasEntrantPermission && <EditIcon width={16} height={16} className="copy-icon" />}
        </div>
      </div>
    );

  useEffect(() => {
    if (prizeDrawEntrantDetails) {
      setLoader(false);
    }
  }, [prizeDrawEntrantDetails]);

  return (
    <div className="flex-column gap-6 h-full overflow-scroll">
      <PrizeDrawDetailEntrantLeftWrapper className="border flex-column">
        <div className="flex border-bottom px-6 py-4">
          <label className="flex-1 font-20 medium-text">{t('ENTRANT_DETAILS')}</label>
          {menuList.filter(item => item.showCondition).length > 0 && (
            <Menu menuList={menuList.filter(item => item.showCondition)} />
          )}
        </div>
        {loader ? (
          <div className="pxy-6 flex-1 h-full">
            <Loader />
          </div>
        ) : (
          <div className="pxy-6 flex-column flex-1 h-full">
            <div className="flex-column gap-1">{nameField}</div>
            <div className="flex-column pt-6 gap-1">
              <label className="regular-text font-12 grey-text">Created on</label>
              <label className="regular-text font-14 flex items-center gap-2">
                <Request /> {moment(created_on * 1000).format('MMM DD, YYYY')}
              </label>
            </div>
            {hasPermission('PII_VIEW') && !is_anonymized && <div>{addressFields}</div>}
            {hasPermission('PII_VIEW') && !is_anonymized && contactField}
            <div className="flex pt-6">
              <div className="flex-1 flex-column gap-1">
                <label className="regular-text font-12 grey-text">{t('ID')}</label>
                <label className="regular-text font-14">{urn}</label>
              </div>
              <div className="flex-1 flex-column gap-1">
                <label className="regular-text font-12 grey-text">{t('WIN_STATUS')}</label>
                {win_status && <Status withDot status={win_status} />}
              </div>
            </div>
            <div className="flex pt-6">
              <div className="flex-1 flex-column gap-1">
                <label className="regular-text font-12 grey-text">{t('DRAW_STATUS')}</label>
                {draw_status && (
                  <Status withDot status={draw_status} statusText={capitalize(formatText(draw_status, ' '))} />
                )}
              </div>
              <div className="flex-1 flex-column gap-1">
                <label className="regular-text font-12 grey-text flex flex-column">{t('OUT_OF_DRAW')}</label>
                <label className={`regular-text font-14 ${!out_of_draw_date && 'lighter-text'}`}>
                  {out_of_draw_date ? moment(out_of_draw_date * 1000).format('MMM DD, YYYY') : 'no data'}
                </label>
              </div>
            </div>
            {external_reference && !is_anonymized && (
              <div className="pt-6 gap-1 flex-column">
                <label className="regular-text font-12 grey-text">{t('EXTERNAL_REFERNCE')}</label>
                <label
                  className="regular-text flex content-hover-edit items-center py-1 cursor"
                  onClick={() => handleCopy(external_reference)}>
                  <div className="flex gap-1 mr-4 cursor">
                    <Stripe />
                    <div className="one-line width font-14">{external_reference}</div>
                  </div>
                  <CopyIcon className="copy-icon" />
                </label>
              </div>
            )}
          </div>
        )}
      </PrizeDrawDetailEntrantLeftWrapper>
      <PrizeDrawDetailEntrantLeftWrapper className="border flex-column">
        <div className="flex items-center border-bottom px-6 py-4">
          <label className="flex-1 font-20 medium-text">{t('PRIZE_DRAW_DETAILS')}</label>
          <Menu />
        </div>
        {loader ? (
          <div className="pxy-6 flex-column flex-1">
            <Loader />
          </div>
        ) : (
          <div className="pxy-6 flex-column flex-1">
            <div className="flex items-center">
              {image ? imageContent(image?.url) : emptyImageContent()}
              <label className="flex-column flex-1" style={{ maxWidth: 'calc(100% - 80px)' }}>
                <label className="regular-text grey-text font-12">{t('NAME')}</label>
                <span className="semibold-text font-16 flex-1 one-line w-full">{prizeDrawName}</span>
              </label>
            </div>
            <div className="flex-column pt-6 gap-1">
              <label className="regular-text grey-text font-12">{t('DESCRIPTION')}</label>
              <label className="font-14 regular-text">{description}</label>
            </div>
            <div className="flex pt-6">
              <div className="flex-1 flex-column gap-1">
                <label className="regular-text grey-text font-12">{t('PRIZE_DRAW_CODE')}</label>
                <label className="font-14 regular-text">{code}</label>
              </div>
              <div className="flex-1 flex-column gap-1">
                <span className="regular-text grey-text font-12">{t('STATUS')}</span>
                {lifecycle && <Status withDot status={lifecycle} />}
              </div>
            </div>
          </div>
        )}
      </PrizeDrawDetailEntrantLeftWrapper>
    </div>
  );
};

export default PrizeDrawEntrantDetailsLeft;
