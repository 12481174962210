import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDown from '../components/McDropdown';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { createIntegration, getConnectors } from '../store/features/integrationsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AddIntegrationWrapper } from './modal.styled.js';

const AddIntegration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { connectors } = useSelector(state => state.integrations);
  const { setModal } = useContext(OrganisationContext);

  const [integration, setIntegration] = useState({});
  const [error, setError] = useState({ name: false, provider: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getConnectors());
  }, []);

  const checkIntegrationErrors = () => {
    if (!integration?.name?.trim() || !integration?.description?.trim() || !integration?.provider?.id) {
      setError({
        name: !integration?.name?.trim(),
        provider: !integration?.provider?.id,
        description: !integration?.description?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill integration name, description and provider' }));
      return true;
    } else if (!integration.connector?.id) {
      dispatch(addToast({ error: true, text: 'Please select integration connector' }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkIntegrationErrors()) {
      return;
    }

    setLoading(true);
    const request = {
      name: integration.name,
      description: integration.description,
      connector: { id: integration.connector.id },
      is_enabled: false,
      is_default: false,
    };
    dispatch(createIntegration({ request }))
      .then(({ data }) => {
        setLoading(false);
        setModal(initModal);
        navigate(`/platforms/integrations/${data?.id}`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSelectSubProvider = subProvider => {
    setIntegration({
      ...integration,
      connector: subProvider,
    });
  };

  return (
    <CommonPopup
      popupTitle="Add integration"
      confirmButtonProps={{ label: 'Add' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}
      disabled={loading}>
      <AddIntegrationWrapper className="flex-column items-center w-full">
        <div className="w-full flex-column mt-6">
          <label className="regular-text grey-text mb-1">{t('NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.name && 'error-border'}`}
            onChange={e => {
              setIntegration({ ...integration, name: e.target.value });
              setError({ ...error, name: false });
            }}
            placeholder="Enter integration name"
            value={integration.name || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text grey-text mb-1">{t('DESCRIPTION')}</label>
          <textarea
            className={`textarea ${error.description && 'error-border'}`}
            onChange={e => {
              setIntegration({ ...integration, description: e.target.value });
              setError({ ...error, description: false });
            }}
            placeholder="Enter description here"
            rows={4}
            value={integration.description || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text grey-text mb-1">{t('PROVIDERS')}</label>
          <DropDown
            className={`provider-selector ${error.provider && 'error-border'}`}
            setSelected={option => {
              setIntegration({ ...integration, provider: option, connector: '' });
              setError({ ...error, provider: false });
            }}
            options={connectors}
            placeholder="Select"
            selected={connectors?.find(relation => relation.id === integration.provider?.id)}
            withIcon={false}
          />
        </div>
        {integration.provider && (
          <div className="sub-provider mt-6">
            {integration.provider?.connectors?.map(connector => (
              <div
                key={connector?.id}
                className={`flex items-center px-4 py-2 sub-provider-item cursor ${
                  integration.connector?.id === connector.id ? 'selected-sub-provider' : ''
                }`}
                onClick={() => onSelectSubProvider(connector)}>
                <img className="mr-2" alt="icon" src={`${connector.icon?.active}`} width={24} height={24} />
                <span className="normal-text">{connector.name}</span>
              </div>
            ))}
          </div>
        )}
      </AddIntegrationWrapper>
    </CommonPopup>
  );
};

export default AddIntegration;
