import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../components/FormElements/dropdown';
import InputElement from '../../../components/FormElements/input';
import Switch from '../../../components/common/switch';
import { dateOfBirthComponentTypes } from '../../../constant/optionData';
import { uppercase } from '../../../helpers/utils';
const maskRegEx = /^[aAnN]*$/;

export const GeneralConfig = ({ component, setSelectedComponent = () => {} }) => {
  const { t } = useTranslation();
  const {
    name,
    label,
    sub_label,
    default_value,
    mask,
    range,
    is_required,
    character_limit = {},
    component_type,
    component_layout,
  } = component;

  const onMaskUpdate = value => {
    if (maskRegEx.test(value)) {
      setSelectedComponent({ ...component, mask: uppercase(value) });
    }
  };

  return (
    <div className="flex-column my-8">
      <label className="flex-1 medium-text font-16">{t('GENERAL_UC')}</label>
      <InputElement
        name="Name"
        onChange={value => setSelectedComponent({ ...component, name: value })}
        value={name}
        label="Enter"
        className="w-full mt-6"
        font={{ size: 12 }}
        type={'TEXTAREA'}
        showRequiredError={true}
      />
      {(component_type !== 'DOB' || (component_type === 'DOB' && component_layout !== 'DAYMONTHYEAR')) && (
        <Fragment>
          {component_type !== 'PHONE' && (
            <InputElement
              name="Label"
              onChange={value => setSelectedComponent({ ...component, label: value })}
              value={label}
              label="Enter"
              className="w-full mt-6"
              font={{ size: 12 }}
            />
          )}
          <InputElement
            name="Sub-label"
            onChange={value => setSelectedComponent({ ...component, sub_label: value })}
            value={sub_label}
            label="Enter"
            className="w-full mt-6"
            font={{ size: 12 }}
          />
        </Fragment>
      )}
      {(component_type === 'ALPHA' || component_type === 'NUMERIC' || component_type === 'ALPHANUMERIC') && (
        <Fragment>
          <InputElement
            name="Default value"
            onChange={value => setSelectedComponent({ ...component, default_value: value })}
            value={default_value}
            label="Enter"
            className="w-full mt-6"
            font={{ size: 12 }}
          />
          <InputElement
            name="Mask"
            onChange={value => onMaskUpdate(value)}
            value={mask}
            label="Enter"
            className="w-full mt-6"
            font={{ size: 12 }}
          />
        </Fragment>
      )}
      {component_type === 'NUMERIC' && (
        <div className="flex col-gap-4 mt-6">
          <InputElement
            name="Min range"
            onChange={value => setSelectedComponent({ ...component, range: { ...range, min: value } })}
            value={range.min}
            label="Enter"
            className="w-full"
            font={{ size: 12 }}
            type="NUMERIC"
          />
          <InputElement
            name="Max range"
            onChange={value => setSelectedComponent({ ...component, range: { ...range, max: value } })}
            value={range.min}
            label="Enter"
            className="w-full"
            font={{ size: 12 }}
            type="NUMERIC"
          />
        </div>
      )}
      {component_type === 'DOB' && (
        <div className="flex-column mt-6">
          <label className="flex regular-text lighter-text font-12 flex-1 mb-1">{t('AGE_RANGE')}</label>
          <div className="flex col-gap-6">
            <InputElement
              onChange={value => setSelectedComponent({ ...component, range: { ...range, min: value } })}
              value={range.min}
              label="Min"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
            <InputElement
              onChange={value => setSelectedComponent({ ...component, range: { ...range, max: value } })}
              value={range.max}
              label="Max"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
          </div>
          <Dropdown
            className="mt-6"
            name="Type"
            placeholder="Select"
            options={dateOfBirthComponentTypes}
            font={{ size: 12 }}
            selectedValue={dateOfBirthComponentTypes.find(type => type.value === component_layout)}
            onChange={option => setSelectedComponent({ ...component, component_layout: option.value })}
          />
        </div>
      )}
      {(component_type === 'ALPHA' || component_type === 'ALPHANUMERIC' || component_type === 'NUMERIC') && (
        <div className="flex-column mt-6">
          <label className="flex regular-text lighter-text font-12 flex-1 mb-1">{t('CHARACTER_LIMIT')}</label>
          <div className="flex col-gap-6">
            <InputElement
              onChange={value =>
                setSelectedComponent({ ...component, character_limit: { ...character_limit, min: value } })
              }
              value={character_limit.min}
              label="Min"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
            <InputElement
              onChange={value =>
                setSelectedComponent({ ...component, character_limit: { ...character_limit, max: value } })
              }
              value={character_limit.max}
              label="Max"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
          </div>
        </div>
      )}
      <div className="flex mt-6">
        <label className="regular-text flex-1">{t('REQUIRED')}</label>
        <Switch
          enabled={is_required}
          onClick={() => setSelectedComponent({ ...component, is_required: !is_required })}
        />
      </div>
    </div>
  );
};
