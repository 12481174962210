import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import Dropdown from '../../../components/FormElements/dropdown';
import InputElement from '../../../components/FormElements/input';
import { formComponentTypes } from '../../../constant/optionData';
import { AdvancedConfig } from './advanced-config';
import { AlignmentConfig } from './alignment-config';
import { ChooserOptionsConfig } from './chooser-option-config';
import { GeneralConfig } from './general-config';
import { HeaderGeneralConfig } from './header-general-config';
import { ImageGeneralConfig } from './image-general-config';
import { MultichoiceGeneralConfig } from './multichoice-general-config';
import { PaymentGeneralConfig } from './payment-general-config';
import { ProductGeneralConfig } from './product-general-config';

export const ComponentConfigs = ({ component, setSelectedComponent }) => {
  const { component_type, row, column } = component;
  const { t } = useTranslation();
  return (
    <div className="flex-column flex-1 overflow-hidden">
      <div className="flex items-center">
        <div className="flex items-center pxy-1 mr-2 cursor" onClick={() => setSelectedComponent(null, false)}>
          <ArrowLeftIcon height={16} width={16} />
        </div>
        <label className="flex-1 medium-text font-16">{t('COMPONENT_DETAILS')}</label>
      </div>
      <div className="flex-column overflow-scroll px-1">
        <div className="flex-column my-8">
          <label className="flex-1 medium-text font-16">{t('OPTIONS_UC')}</label>
          <Dropdown
            className="mt-6"
            name="Component type"
            placeholder="Select type"
            options={formComponentTypes}
            font={{ size: 12 }}
            selectedValue={formComponentTypes.find(c => c.value === component_type)}
            onChange={option => setSelectedComponent({ ...component, component_type: option.value })}
          />
          <div className="mt-6 flex col-gap-4">
            <InputElement
              name="Row"
              onChange={value => setSelectedComponent({ ...component, row: value })}
              value={row}
              label="Row"
              className="w-full"
              font={{ size: 12 }}
              type="NUMERIC"
            />
            <InputElement
              name="Column"
              onChange={value => setSelectedComponent({ ...component, column: value })}
              value={column}
              label="Column"
              className="w-full"
              font={{ size: 12 }}
            />
          </div>
          {component_type === 'HEADER' && (
            <AlignmentConfig component={component} setSelectedComponent={setSelectedComponent} />
          )}
        </div>
        {component_type && (
          <Fragment>
            <div className="hr-line" />
            {component_type === 'HEADER' ? (
              <HeaderGeneralConfig component={component} setSelectedComponent={setSelectedComponent} />
            ) : component_type === 'PRODUCT' ? (
              <ProductGeneralConfig component={component} setSelectedComponent={setSelectedComponent} />
            ) : component_type === 'PAYMENT' ? (
              <PaymentGeneralConfig component={component} setSelectedComponent={setSelectedComponent} />
            ) : component_type === 'IMAGE' ? (
              <ImageGeneralConfig component={component} setSelectedComponent={setSelectedComponent} />
            ) : component_type === 'MULTICHOICE' ? (
              <MultichoiceGeneralConfig component={component} setSelectedComponent={setSelectedComponent} />
            ) : (
              <GeneralConfig component={component} setSelectedComponent={setSelectedComponent} />
            )}
            {component_type === 'CHOOSER' && (
              <Fragment>
                <div className="hr-line" />
                <ChooserOptionsConfig component={component} setSelectedComponent={setSelectedComponent} />
              </Fragment>
            )}
            <div className="hr-line" />
            <AdvancedConfig component={component} setSelectedComponent={setSelectedComponent} />
          </Fragment>
        )}
      </div>
    </div>
  );
};
