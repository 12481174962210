import ReactECharts from 'echarts-for-react';
import React from 'react';

const DonutChartV2 = ({ dataSet, Tootltiptile }) => {
  const getOption = () => {
    const customColors = [
      '#8927EF',
      '#E8D1FF',
      '#EF7ABD',
      '#7BB2FD',
      '#F8B133',
      '#5470c6',
      '#af6df4',
      '#f85e86',
      '#D0D5DD',
    ];

    const customTooltipFormatter = params => {
      const name = params?.data?.name;
      const value = params?.data?.value;
      const titlesub = Tootltiptile;

      const backgroundColor = '#8927EF30';
      const color = '#8927EF';

      return `
          <div style="min-width:120px; text-align:center; border-radius: 12px">
            <p class="medium-text py-1" style="background-color: ${backgroundColor}; color: ${color}; border-radius: 12px 12px 0 0;">
              ${name}
            </p>
            <div class="px-2 flex-column">
              <label class="flex justify-center items-center flex-1 semibold-text font-12">${value}</label>
              <label class="flex justify-center items-center flex-1 medium-text font-12">${titlesub}</label>
            </div>
          </div>
      `;
    };

    return {
      tooltip: {
        trigger: 'item',
        formatter: customTooltipFormatter,
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
        borderRadius: 12,
        borderWidth: 0,
        padding: 0,
      },
      legend: {
        top: '10%',
        right: 'right',
        icon: 'circle',
        textStyle: {
          color: '#16192C',
          width: 120,
          fontSize: '12px',
          fontFamily: 'Poppins',
          overflow: 'truncate',
        },
      },

      series: [
        {
          type: 'pie',
          // roseType: 'radius',
          center: ['30%', '40%'],
          labelLine: {
            show: false,
          },
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 8,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: true,
            position: 'center',
            fontSize: '20px',
            borderWidth: 1,
            fontWeight: '500',
            fontFamily: 'Poppins',
            formatter: params => {
              const percentValue = Math.round(params.percent);
              return `${percentValue}%`;
            },
            borderColor: '#E2E7EF',
            backgroundColor: '#fff',
            width: 70,
            height: 70,
            padding: [15, 15],
            borderRadius: 70,
          },

          data: dataSet.map((item, index) => ({
            ...item,
            itemStyle: {
              color: customColors[index],
            },
          })),
        },
      ],
    };
  };

  return (
    <div className="chart-content-box h-full">
      <ReactECharts
        className="echart-custom-tooltip"
        option={getOption()}
        notMerge={true}
        lazyUpdate={true}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

export default DonutChartV2;
