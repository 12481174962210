import moment from 'moment/moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as MailIcon } from '../assets/images/mail.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText } from '../helpers/utils';
import { retryAutomationLog } from '../store/features/automationsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AutomationLogActionWrapper } from './modal.styled';

const AutomationLogAction = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { automationLog, isCancel, title, isRetry, automationAction, actionTitle } = modal.content;
  const handleConfirm = () => {
    setLoading(true);
    try {
      if (isRetry) {
        dispatch(retryAutomationLog({ automationLogId: automationAction?.id })).then(resposne => {
          setModal(initModal);
          setLoading(false);
        });
      } else if (isCancel) {
        setModal(initModal);
      }
    } catch (error) {
      dispatch(addToast({ error: true, text: 'Something Went Wrong' }));
      setLoading(false);
    }
  };

  const cancelContent = (
    <>
      <label className="bold-text font-18">{automationLog?.automation?.name}</label>
      <div className="flex-column items-start justify-start w-full mt-4">
        <label className="regular-text grey-text font-12">{t('ID')}:</label>
        <label className="regular-text font-14 mt-1">{automationLog?.id}</label>
      </div>
      <div className="flex-column items-start justify-start w-full mt-4">
        <label className="regular-text grey-text font-12">{t('DATE')}:</label>
        <label className="regular-text font-14 mt-1">
          {moment(automationLog?.created_on * 1000).format('DD/MM/YY - hh:mm a')}
        </label>
      </div>
    </>
  );

  const retryContent = (
    <>
      <label className="semibold-text font-16">{actionTitle}</label>
      <div className="flex item-start justify-start px-4 py-3 radius-2 automation-action-container mt-4">
        <MailIcon className="flex items-center justify-center" height={24} width={24} />
        <div className="flex-column items-start justify-start flex-1 ml-2">
          <label className="regular-text font-14">{automationAction?.action?.action_type?.name}</label>
          <label className="regular-text mt-1 grey-text font-12">
            {automationAction?.action?.action_type?.description}
          </label>
        </div>
      </div>
      <div className="flex items-center justify-start w-full mt-4">
        <div
          className="flex items-center justify-center border"
          style={{ background: '#ffff', borderRadius: '6px', padding: '3px' }}>
          <img
            src={`${automationAction?.action?.integration?.connector?.icon?.active}`}
            style={{ width: 18, height: 18 }}
          />
        </div>
        <label className="ml-2 regular-text font-14">{automationAction?.action?.integration?.name}</label>
      </div>
      {automationAction?.action?.params.length > 0 && (
        <div className="flex-column items-start justify-start w-full mt-4">
          {automationAction?.action?.params?.map((param, index) => (
            <div className={`flex items-center justify-start w-full ${index === 0 ? '' : 'mt-2'}`} key={param.id}>
              <label className="regular-text grey-text font-14">
                {capitalize(formatText(param.param_type, ' '))}: &nbsp;
              </label>
              <label className="regular-text font-14"> {param.param_value || param.param_value}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );

  const getContent = () => {
    if (isCancel) {
      return cancelContent;
    } else if (isRetry) {
      return retryContent;
    }
  };
  return (
    <CommonPopup
      confirmButtonProps={{ label: 'Retry' }}
      onCancel={() => setModal(initModal)}
      onConfirm={handleConfirm}
      popupTitle={title}
      disabled={loading}>
      <AutomationLogActionWrapper>
        <div className="mt-6 pxy-4 flex-column w-full automation-log-details-container">{getContent()}</div>
      </AutomationLogActionWrapper>
    </CommonPopup>
  );
};

export default AutomationLogAction;
