import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LogoutPurpleIcon } from '../../assets/images/FiLogOut.svg';
import { ReactComponent as ArrowLeftWhiteIcon } from '../../assets/images/arrow-left-white.svg';
import { ReactComponent as ArrowRightWhiteIcon } from '../../assets/images/arrow-right-white.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';
import { ReactComponent as DominoMask } from '../../assets/images/domino-mask.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/sidebar/logout.svg';
import { ReactComponent as UserActionIcon } from '../../assets/images/sidebar/user-action-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/images/sidebar/user.svg';
import EmptyImage from '../../assets/images/subtract.svg';
import zentNameLogo from '../../assets/images/zen-logo.svg';
import Logo from '../../assets/images/zenterprize-logo.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { getUserInitials } from '../../helpers/utils';
import { onAccessAccountUsingOrignalToken } from '../../store/features/groupsSlice';
import { getDetailsProfileUser } from '../../store/features/profileSlice';
import { addToast } from '../../store/features/toastSlice';
import { setUser, setUserLogout } from '../../store/features/userSlice';
import { SidebarWrapper } from '../../styles/components/sidebar/sidebar.styled';
import Button from '../Elements/button/button';
import MenuItem from './menu-item';

const Sidebar = ({ menuItems, onItemClick, selectedPath, expanded, setExpanded }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { userDetails, user } = useSelector(state => state.user);
  const { userDataProfile, userImage } = useSelector(state => state.profile);
  const { hasPermission } = useContext(OrganisationContext);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMasqueradeDetails, setShowMasqueradeDetails] = useState(false);
  const { t } = useTranslation();

  const logout = () => {
    localStorage.removeItem('user');
    dispatch(setUserLogout());
    navigate('/login', { replace: true });
  };

  const goToProfile = () => {
    navigate('/profile');
    setShowUserMenu(false);
  };

  const goToHome = () => {
    navigate('/home/logs');
  };

  const [userMenuItems, setUserMenuItems] = useState([
    {
      id: 0,
      text: 'My Profile',
      Icon: UserIcon,
      IconGreen: UserIcon,
      path: '/profile',
      action: goToProfile,
    },
    {
      id: 1,
      text: 'Log Out',
      Icon: LogoutIcon,
      IconGreen: LogoutIcon,
      action: logout,
    },
  ]);

  useEffect(() => {
    setUserMenuItems(userMenuItems.map(item => ({ ...item, selected: selectedPath.includes(item.path) })));
  }, [selectedPath]);

  useEffect(() => {
    if (user?.isAccessAccount) {
      setExpanded(true);
    }
  }, [user?.isAccessAccount]);

  const onExitMasquerade = () => {
    const api_tokens = user.original_api_tokens;
    const organizations = user.original_organizations;
    const updatedUser = {
      ...user,
      api_tokens: { ...api_tokens },
      organizations: organizations,
      accountDetails: null,
      isAccessAccount: false,
      original_api_tokens: null,
      selectedAccount: null,
    };
    setShowMasqueradeDetails(false);
    setItemInLocalStorage('user', updatedUser);
    dispatch(setUser(updatedUser));
    if (location.pathname === '/dashboard') {
      window.location.reload();
    } else {
      navigate('/dashboard');
    }
  };

  const onAccessAccountClick = selectedOrg => {
    dispatch(onAccessAccountUsingOrignalToken({ organizationId: selectedOrg.id }))
      .then(data => {
        const updatedUser = {
          ...user,
          api_tokens: { ...data },
          organizations: [{ ...selectedOrg }],
          selectedAccount: { ...selectedOrg },
        };
        setItemInLocalStorage('user', updatedUser);
        dispatch(setUser(updatedUser));
        dispatch(addToast({ error: false, text: 'Account has been accessed' }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Account cannot be accessed' }));
      });
  };

  const getMasqueradeOrganizationList = () => {
    const { selectedAccount, original_organizations, masquarage_organizations } = user;
    const userOrganization = original_organizations[0];
    let updatedOrgList = masquarage_organizations.filter(data => data.id !== userOrganization.id);

    const output = updatedOrgList.map(data => (
      <div
        key={data?.id}
        className={`masquerade-organization-item flex items-center py-1 px-2 radius-2 cursor w-full ${
          data.id === selectedAccount.id ? 'selected-organization' : ''
        }`}
        onClick={() => onAccessAccountClick(data)}>
        <label className="regular-text one-line">{data.name}</label>
      </div>
    ));
    return output;
  };
  useEffect(() => {
    dispatch(getDetailsProfileUser({ id: id ? id : user?.user?.id })).catch(() => {});
  }, []);

  return (
    <SidebarWrapper
      className={`${expanded ? 'expanded' : 'collapsed'}`}
      style={{
        transition: 'all 400ms linear',
      }}>
      <div className="flex items-center pxy-6">
        {!expanded ? (
          <img src={Logo} alt="zen-logo" width={28} height={30} className="mr-2" onClick={() => goToHome()} />
        ) : (
          <img src={zentNameLogo} alt="zen-logo" height={40} width={116} onClick={() => goToHome()} />
        )}
      </div>
      {!user.isAccessAccount && (
        <div
          className={`absolute flex items-center justify-center cursor expand-icon`}
          style={{
            transition: 'all 400ms linear',
          }}
          onClick={() => setExpanded(!expanded)}>
          {expanded ? <ArrowLeftWhiteIcon /> : <ArrowRightWhiteIcon />}
        </div>
      )}
      <div className="sidebar-top" tabIndex="0">
        <div className="flex items-center justify-between cursor user-info no-select relative" tabIndex={0}>
          <div className="w-full menu-items">
            {user.isAccessAccount && expanded && (
              <div className="flex items-center w-full px-6 py-3 relative access-account-sidebar">
                <div
                  className="flex items-center w-full"
                  onClick={() => setShowMasqueradeDetails(!showMasqueradeDetails)}>
                  <div className="flex-column flex-1">
                    <label className="regular-text grey-text font-12">{t('ACCOUNT_UC')}</label>
                    <label className="medium-text zen-purple-text">{user.selectedAccount?.name}</label>
                  </div>
                  <div className="px-2 py-1">
                    <ArrowRight className={showUserMenu ? 'rotate-270' : 'rotate-90'} />
                  </div>
                </div>
                {showMasqueradeDetails && (
                  <div
                    className="absolute card masquerade-details-box overflow-hidden"
                    onBlur={() => setShowMasqueradeDetails(false)}
                    tabIndex={0}>
                    <div className="flex-column masquerade-items-wrapper overflow-scroll pl-2 pr-4 my-3 row-gap-1">
                      {getMasqueradeOrganizationList()}
                    </div>
                    <hr className="horizontal-line" />
                    <div className="pxy-4">
                      <Button
                        className={'w-full default-secondary'}
                        label="Exit masquerade"
                        size="medium"
                        borderRadius="12px"
                        icon={<LogoutPurpleIcon height={16} width={16} />}
                        onClick={() => onExitMasquerade()}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {menuItems.map((item, i) => (
              <MenuItem key={i} item={item} onClick={onItemClick} expanded={expanded} />
            ))}
          </div>
        </div>
      </div>
      <div
        className="sidebar-bottom"
        onBlur={e => {
          e.preventDefault();
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowUserMenu(false);
          }
        }}
        onClick={() => setShowUserMenu(!showUserMenu)}
        tabIndex="0">
        <div className="flex items-center py-3 px-6 user-name cursor">
          {user.isAccessAccount ? (
            <div className="flex pxy-1 radius-2 mr-2 masquerade-user-icon">
              <DominoMask />
            </div>
          ) : (
            <div className="userProfile-box mr-2">
              {userImage?.url || userDataProfile?.avatar?.url ? (
                <img
                  src={
                    userImage?.url
                      ? userImage?.url
                      : userDataProfile?.avatar?.url && user?.user?.email === userDataProfile?.email
                      ? userDataProfile?.avatar?.url
                      : EmptyImage
                  }
                  alt="profile"
                  className="profile-icon mr-2"
                />
              ) : (
                <div
                  className="user-icon flex items-center justify-center empty-img nots-user-image radius-2"
                  style={{ width: '32px', height: '32px' }}>
                  <span className="font-14 bold-text grey-text">
                    {getUserInitials(userDataProfile ? userDataProfile : userDetails || {})}
                  </span>
                </div>
              )}
            </div>
          )}
          {expanded && (
            <>
              {userDataProfile?.name && userDataProfile?.id === user?.user?.id ? (
                <label className="semibold-text white-text flex-1"> {`${userDataProfile?.name}`}</label>
              ) : (
                <label className="semibold-text white-text flex-1">
                  {' '}
                  {`${userDetails?.forename} ${userDetails?.surname}`}
                </label>
              )}
              <UserActionIcon />
            </>
          )}
        </div>
        <div className={`user-menu ${showUserMenu ? 'show-menu' : 'hidden-menu'}`}>
          {userMenuItems.map((item, i) => (
            <div
              key={i}
              className={`user-action ${item.selected && 'user-action-selected'} ${
                !item.permission || (hasPermission(item.permission) ? 'visible' : 'hidden')
              }`}
              onClick={() => {
                item.action();
              }}>
              <div className={`flex items-center py-4 px-6 cursor ${expanded ? 'px-6' : 'px-2 justify-center'}`}>
                <item.Icon className="mr-3 user-action-icon" />
                {expanded && <label className="regular-text font-12">{item.text}</label>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
