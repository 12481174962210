import React, { useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as Selected } from '../../../assets/images/check.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import Button from '../../Elements/button/button';

const Option = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`option-wrapper flex items-center w-full pxy-2 radius-2 cursor ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <span className="flex-1 regular-text option-text">{data.label}</span>
      {isSelected && <Selected />}
    </div>
  );
};

const MenuList = props => {
  const { selectProps, selectOption } = props;
  const { showAddButtonInOption, onAddButton = () => {} } = selectProps;

  const onAddButtonClick = () => {
    selectOption({ id: 'ADD_BUTTON' });
    onAddButton();
  };

  return (
    <components.MenuList {...props}>
      {showAddButtonInOption && (
        <div className="flex-column mx-2">
          <Button
            size="medium"
            label="Add list"
            icon={<PlusIcon />}
            className="w-full default-secondary my-2"
            onClick={onAddButtonClick}
            borderRadius="12px"
          />
          <hr className="flex my-2" />
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

const Dropdown = ({
  options = [],
  placeholder = '',
  isSearchable = false,
  onChange = () => {},
  selectedValue = null,
  isMulti = false,
  name = '',
  is_required = false,
  font = null,
  sub_label = null,
  fontFamily,
  is_autofill,
  showRequiredError,
  updateOnLoad = false,
  loadOptions = () => {},
  onInputChange = () => {},
  inputValue = null,
  className = '',
  showAddButtonInOption = false,
  onAddButton = () => {},
  isDisabled = false,
  autoFillValue = null,
}) => {
  const dropdownSelectedValue = is_autofill ? autoFillValue : selectedValue;
  const theme = useTheme();

  useEffect(() => {
    if (updateOnLoad) {
      onChange(null);
    }
  }, []);

  return (
    <DropdownWrapper className={`w-full ${className}`}>
      {name && (
        <div className="mb-1">
          <span
            className="regular-text lighter-text"
            style={{ fontSize: `${font.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
            {name}
          </span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      {isSearchable ? (
        <AsyncSelect
          loadOptions={loadOptions}
          placeholder={placeholder}
          onChange={option => {
            onInputChange('', { action: 'input-change' });
            onChange(option);
          }}
          isDisabled={is_autofill || isDisabled}
          onInputChange={onInputChange}
          inputValue={inputValue}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          value={dropdownSelectedValue}
          showAddButtonInOption={showAddButtonInOption}
          onAddButton={onAddButton}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: 'unset',
              borderColor:
                showRequiredError && !dropdownSelectedValue
                  ? theme.colors.ZenNegative
                  : state.isFocused
                  ? theme.colors.zenPurple
                  : theme.colors.border,
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              minHeight: '40px',
              cursor: 'pointer',
              boxShadow: 'none',

              ':hover': {
                borderColor:
                  showRequiredError && !dropdownSelectedValue
                    ? theme.colors.ZenNegative
                    : state.isFocused
                    ? theme.colors.zenPurple
                    : theme.colors.ZenPurpleSecondary,
              },
            }),
            placeholder: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
              color: theme.colors.RegularText,
              opacity: '0.2',
              fontSize: '16px',
            }),
            input: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
              color: theme.colors.RegularText,
              fontSize: '16px',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            indicatorsContainer: baseStyles => ({
              ...baseStyles,
              color: theme.colors.RegularText,
            }),
            valueContainer: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
              padding: '4px 16px',
            }),
            menu: baseStyles => ({
              ...baseStyles,
              borderRadius: '16px',
              padding: '8px',
              zIndex: 11,
            }),
            menuList: baseStyles => ({
              ...baseStyles,
              padding: '0px',
            }),
            multiValue: baseStyles => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.colors.ZenLightGray,
              padding: '4px',
              borderRadius: '6px',
              margin: '2px 8px 2px 0px',
            }),
            multiValueLabel: baseStyles => ({
              ...baseStyles,
              fontSize: '14px',
              padding: '0',
            }),
            multiValueRemove: baseStyles => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.colors.white,
              padding: '4px',
              borderRadius: '6px',
              marginLeft: '8px',

              ':hover': {
                borderColor: theme.colors.white,
              },
            }),
            clearIndicator: () => ({
              display: 'none',
            }),
            noOptionsMessage: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
            }),
          }}
          components={{
            Option,
          }}
        />
      ) : (
        <Select
          options={options}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onChange={option => {
            onChange(option);
          }}
          isMulti={isMulti}
          isDisabled={is_autofill || isDisabled}
          closeMenuOnSelect={!isMulti}
          hideSelectedOptions={false}
          showAddButtonInOption={showAddButtonInOption}
          onAddButton={onAddButton}
          value={dropdownSelectedValue}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: 'unset',
              borderColor:
                showRequiredError && !dropdownSelectedValue
                  ? theme.colors.ZenNegative
                  : state.isFocused
                  ? theme.colors.zenPurple
                  : theme.colors.border,
              borderRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              minHeight: '40px',
              cursor: 'pointer',
              boxShadow: 'none',

              ':hover': {
                borderColor:
                  showRequiredError && !dropdownSelectedValue
                    ? theme.colors.ZenNegative
                    : state.isFocused
                    ? theme.colors.zenPurple
                    : theme.colors.ZenPurpleSecondary,
              },
            }),
            placeholder: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
              color: theme.colors.RegularText,
              opacity: '0.2',
              fontSize: '16px',
            }),
            input: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
              color: theme.colors.RegularText,
              fontSize: '16px',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            indicatorsContainer: baseStyles => ({
              ...baseStyles,
              color: theme.colors.RegularText,
            }),
            valueContainer: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
              padding: '4px 16px',
            }),
            menu: baseStyles => ({
              ...baseStyles,
              borderRadius: '16px',
              padding: '8px',
              zIndex: 11,
            }),
            menuList: baseStyles => ({
              ...baseStyles,
              padding: '0px',
            }),
            multiValue: baseStyles => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.colors.ZenLightGray,
              padding: '4px',
              borderRadius: '6px',
              margin: '2px 8px 2px 0px',
            }),
            multiValueLabel: baseStyles => ({
              ...baseStyles,
              fontSize: '14px',
              padding: '0',
            }),
            multiValueRemove: baseStyles => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.colors.white,
              padding: '4px',
              borderRadius: '6px',
              marginLeft: '8px',

              ':hover': {
                borderColor: theme.colors.white,
              },
            }),
            clearIndicator: () => ({
              display: 'none',
            }),
            noOptionsMessage: baseStyles => ({
              ...baseStyles,
              fontFamily: 'Poppins Regular',
            }),
          }}
          components={{
            Option,
            MenuList,
          }}
        />
      )}
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled.div`
  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
      .option-text {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .hide-content {
    display: none;
  }
`;

export default Dropdown;
