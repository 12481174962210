import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutomationLogsList from '../../components/automation-logs-list';
import Loader from '../../components/common/loader';
import PageHeader from '../../components/page-header';
import useDebounce from '../../helpers/useDebounceHook';
import { getAutomationLogList } from '../../store/features/automationsSlice';
import { AutomationLogsWrapper } from '../../styles/pages/automation-logs.styled.js';
const AutomationLogs = () => {
  const dispatch = useDispatch();
  const { automationLogList } = useSelector(state => state.automations);
  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(searchText, 500);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSelectedPage(0);
    let params = { page: selectedPage, size: 20 };
    if (debouncedSearch) {
      params = { ...params, automation_log_id: debouncedSearch };
    }
    dispatch(getAutomationLogList({ params: { ...params } }))
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [debouncedSearch]);

  useEffect(() => {
    setLoading(true);
    let params = { page: selectedPage, size: 20 };
    if (debouncedSearch) {
      params = { ...params, automation_log_id: debouncedSearch };
    }
    dispatch(getAutomationLogList({ params: { ...params } }))
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [selectedPage]);
  return (
    <AutomationLogsWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Platforms', onClick: () => {} },
          { text: 'Automations Log', onClick: () => {} },
        ]}
        showSearch
        showAddNewBtn={false}
        addBtnPermission={'AUTOMATION_LOGS_VIEW'}
        searchText={searchText}
        onSearchChange={setSearchText}
        showPagination
        pagination={{
          selectedPage: selectedPage,
          totalPages: automationLogList.total_pages || 0,
          setSelectedPage: setSelectedPage,
        }}
      />
      {loading ? <Loader height={64} width={64} /> : <AutomationLogsList debouncedSearch={debouncedSearch} />}
    </AutomationLogsWrapper>
  );
};

export default AutomationLogs;
