import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as UsersIcon } from '../assets/images/multiple-users.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { removeGroup } from '../store/features/groupsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';

const GroupActions = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const { group, title, onSuccess = () => {} } = modal.content;

  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    dispatch(removeGroup({ organizationCategoryId: group.id }))
      .then(() => {
        setLoading(false);
        setModal(initModal);
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
        onSuccess();
        dispatch(
          addToast({
            error: true,
            text: 'Error while delete group',
            id: uuid(),
          }),
        );
      });
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{ label: 'Delete', className: 'negative' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <GroupActionsWrapper className="flex-column w-full mt-6 pxy-4">
        <div className="flex flex-1">
          <label className="medium-text font-18 flex-1">{group.name}</label>
          <div className="flex items-center justify-center">
            <UsersIcon />
            <label className="regular-text ml-1">{group.organizations_number}</label>
          </div>
        </div>
        <div className="mb-2">
          <label className="regular-text lighter-text w-full word-break-all">{group?.description}</label>
        </div>
        <div className="flex wrap">
          {group?.roles?.length > 0 &&
            (group?.roles || []).map(role => (
              <div
                className={`flex items-center justify-center w-auto role-wrapper radius-2 mr-2 mt-2 role-item`}
                key={role?.id}>
                <label className={`medium-text font-12`}>{role?.name}</label>
              </div>
            ))}
        </div>
      </GroupActionsWrapper>
    </CommonPopup>
  );
};

const GroupActionsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 16px;

  .role-wrapper {
    background: ${({ theme }) => theme.colors.ZenBlueSecondary};
    padding: 2px 8px;
    width: fit-content;

    label {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
  }
`;

export default GroupActions;
