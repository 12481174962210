import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as AmericanExpress } from '../../../../../assets/images/card/american-express.svg';
import { ReactComponent as BankAccount } from '../../../../../assets/images/card/bank-account.svg';
import { ReactComponent as Discover } from '../../../../../assets/images/card/discover.svg';
import { ReactComponent as Master } from '../../../../../assets/images/card/master.svg';
import { ReactComponent as Visa } from '../../../../../assets/images/card/visa.svg';
import { capitalize, formatText } from '../../../../../helpers/utils';
import {
  prizeDrawEntrantOverviewPaymentMethods,
  setPrizeDrawEntrantOverviewPaymentMethodsDetails,
} from '../../../../../store/features/prizeDrawSlice';

const PaymentMethod = () => {
  const { prizeDrawEntrantOverviewPaymentMethodsDetails } = useSelector(state => state.prizeDraw);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { entrant_id } = useParams();

  useEffect(() => {
    dispatch(prizeDrawEntrantOverviewPaymentMethods(entrant_id));

    return () => {
      dispatch(setPrizeDrawEntrantOverviewPaymentMethodsDetails([]));
    };
  }, []);

  const getCardImage = brand => {
    switch (brand) {
      case 'visa':
        return <Visa />;
      case 'amex':
        return <AmericanExpress />;
      case 'discover':
        return <Discover />;
      case 'mastercard':
        return <Master />;
      default:
        return <BankAccount />;
    }
  };

  return (
    prizeDrawEntrantOverviewPaymentMethodsDetails?.length > 0 && (
      <div className="px-6 pt-6 flex flex-column">
        <label className="medium-text font-16 lighter-text divider-bottom w-full flex-1 flex pb-2">
          {t('PAYMENT_METHOD')}
        </label>
        {prizeDrawEntrantOverviewPaymentMethodsDetails.map(
          ({ brand, card, type, bank, last_successful_payment, id }) => (
            <div className="border radius-4 py-3 px-4 mt-4" key={id}>
              <div className="flex gap-2">
                <div>{getCardImage(brand)}</div>
                <div className="flex-column">
                  <label className="regular-text font-14">
                    {type === 'CARD' ? `${capitalize(formatText(card?.funding))} Card` : 'US Bank Account'}
                  </label>
                  <div className="flex flex-row gap-2 items-center">
                    <label className="regular-text lighter-text font-12">
                      {capitalize(type === 'CARD' ? brand : bank?.name)}
                    </label>
                    {last_successful_payment && (
                      <div className="flex flex-row gap-2 items-center">
                        <label className="dot-3-lighter-text"></label>
                        <label className="regular-text lighter-text font-12">
                          Last successful payment on {moment(last_successful_payment * 1000).format('MMM DD, YYYY')}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    )
  );
};

export default PaymentMethod;
