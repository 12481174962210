import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { camelCase, capitalize, formatText, getPerformedByFromLog } from '../../../../helpers/utils';
import Status from '../../../common/status';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizedrawLifecycleUpdate = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const {
    event_data: { prizedraw, previous },
  } = log;
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={prizedraw?.lifecycle === 'INACTIVE' ? 'Prize Draw deactivated' : 'Prize Draw activated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('ACTIVATED_PRIZE_DRAW')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogTitle
            text={
              <span className="flex items-center fw-w">
                <span className="regular-text zen-purple-text cursor font-12"> {prizedraw?.code}</span>
                <span className="regular-text lighter-text font-12 cursor ml-1">{t('STATUS_UPDATED_FROM')}</span>
                <Status
                  withDot={true}
                  status={previous?.prizedraw?.lifecycle}
                  statusText={capitalize(camelCase(formatText(previous?.prizedraw?.lifecycle, ' ')))}
                  statusWrapperClassName={'ml-1'}
                />
                <span className="regular-text lighter-text font-12 ml-1">to</span>
                <Status
                  withDot={true}
                  status={prizedraw?.lifecycle}
                  statusText={capitalize(camelCase(formatText(prizedraw?.lifecycle)))}
                  statusWrapperClassName={'ml-1'}
                />
              </span>
            }
            showDownIcon={false}
          />
          <LogContentBox
            title={'Prize Draw'}
            contentText={<p className="regular-text lighter-text font-12">{prizedraw?.name}</p>}
          />
        </>
      )}
    </div>
  );
};

export default PrizedrawLifecycleUpdate;
