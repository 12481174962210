import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HidePassword from '../../assets/images/hide-password.svg';
import Logo from '../../assets/images/logo.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as LoginErrorIcon } from '../../assets/images/vector.svg';
import TextLogo from '../../assets/images/zenterprize.svg';
import Button from '../../components/Elements/button/button';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { setUser } from '../../store/features/userSlice';
import { LoginWrapper } from '../../styles/pages/login.styled';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async event => {
    if (active) {
      Axios.post(`${process.env.REACT_APP_API_URL}/authentication`, {
        identity: email,
        password: password,
      })
        .then(({ data }) => {
          dispatch(setUser(data));
          setItemInLocalStorage('user', data);
          navigate('/authentication');
        })
        .catch(() => {
          setShowError(true);
        });
    }
  };

  const changePassword = e => {
    setPassword(e.target.value);
    setShowError(false);
  };

  const changeEmail = email => {
    setEmail(email);
    setShowError(false);
  };

  useEffect(() => {
    setActive(email && password && email.length > 2 && password.length > 2);
  }, [email, password]);

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onLogin();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [email, password, active]);

  return (
    <LoginWrapper>
      <div className="login-page">
        <div className="login">
          <div className="login-form card px-16 py-14">
            <img className="logo" src={Logo} alt="logo" />
            <img className="text-logo" src={TextLogo} alt="text-logo" />
            <div className="flex-column form w-full">
              <label className="input-label mb-2">{t('EMAIL_ADDRESS')}</label>
              <div className="input-container">
                <input
                  placeholder={t('EMAIL_ADDRESS')}
                  onChange={e => changeEmail(e.target.value)}
                  className={`${showError && 'error-border'}`}
                />
              </div>
              <label className="input-label mb-2">{t('PASSWORD')}</label>
              <div className="input-container">
                <input
                  className={`password-input ${showError && 'error-border'}`}
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('ENTER_PASSWORD')}
                  onChange={e => changePassword(e)}
                />
                <img
                  className="show-password"
                  src={showPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {showError && (
                <div className="error-message mt-6">
                  <LoginErrorIcon className="error-icon mr-2" />
                  <label className="regular-text color-white">{t('INCORRECT_EMAIL_PASSWORD')}</label>
                </div>
              )}
              <Button
                label={t('LOGIN')}
                className={`mt-10 mb-6 ${active && !showError ? 'primary' : 'secondary'}`}
                size="large"
                borderRadius="16px"
                onClick={onLogin}
                width="350px"
              />
              <a href="#/password" className="color-purple medium-text font-12 mb-2">
                {t('FORGOT_PASSWORD')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default Login;
