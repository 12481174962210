import React from 'react';
import styled, { useTheme } from 'styled-components';
import { capitalize, formatText } from '../../../helpers/utils';

const PaymentStatus = ({
  withDot = false,
  status = '',
  withDottedBorder = false,
  dotColor = null,
  statusText = null,
  statusTextClassName = '',
  statusWrapperClassName = '',
}) => {
  const theme = useTheme();
  const { colors: themeColors } = theme || {};

  const getStatusStyle = status => {
    switch (status) {
      case 'ACTIVE':
        return {
          color: theme.colors.ZenPositiveText,
          background: theme.colors.ZenPositiveSecondary,
          dotColor: theme.colors.ZenPositive,
        };
      case 'PAST_DUE':
        return {
          color: themeColors?.ZenOrangeText,
          background: themeColors?.ZenOrangeSecondary,
          dotColor: themeColors?.ZenOrange,
        };
      case 'UNPAID':
        return {
          color: themeColors?.RegularText,
          background: themeColors?.ZenGray2Secondary,
          dotColor: themeColors?.RegularText,
        };
      case 'CANCELED':
      case 'CANCEL_PENDING':
        return {
          background: themeColors.ZenRedSecondary,
          color: themeColors.ZenRed,
          dotColor: themeColors.ZenRed,
        };
      case 'PAUSED':
        return {
          background: themeColors.LightBlueBackground,
          color: themeColors.LightBlue,
          dotColor: themeColors.LightBlue,
        };
      case 'INCOMPLETE':
        return {
          background: themeColors?.ZenDefaultBackground2,
          color: themeColors?.ZenDefault,
          dotColor: themeColors?.ZenDefault,
        };
      case 'INCOMPLETE_EXPIRED':
        return {
          background: themeColors?.ZenPinkBackground,
          color: themeColors?.ZenPink,
          dotColor: themeColors?.ZenPink,
        };
      default:
        return { color: themeColors.ZenDefault, background: themeColors.ZenGray2 };
    }
  };

  return (
    <StatusWrapper
      className={`flex items-center justify-center ${withDottedBorder && 'dotted-border'} ${statusWrapperClassName}`}
      withDottedBorder={withDottedBorder}
      {...getStatusStyle(status)}>
      {withDot && <div className="dot mr-1" />}
      <span className={`medium-text font-12 status-text ${statusTextClassName}`}>
        {statusText ? statusText : capitalize(formatText(status, ' '))}
      </span>
    </StatusWrapper>
  );
};

const StatusWrapper = styled('div')`
  padding: 3px 8px;
  width: fit-content;
  border-radius: 18px;
  background: ${({ background }) => background};

  &.dotted-border {
    border: 1px dashed ${({ background, borderColor }) => borderColor || background};
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${({ color, dotColor }) => dotColor || color};
  }

  .status-text {
    color: ${({ color }) => color};
  }
`;

export default PaymentStatus;
