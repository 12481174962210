import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';
import { GenderWrapper } from '../../styles/components/dashboard/dashboard.styled';

const AverageStats = ({ expanded }) => {
  const { t } = useTranslation();
  const { globalStats } = useSelector(state => state.dashboard) || [];
  return (
    <GenderWrapper className="flex-colummn h-full">
      <div className="flex col-gap-4 mb-4">
        <div className={`border radius-4 ${expanded ? 'dashboard-years-box section' : 'section'}  flex-row w-full `}>
          <div className="liner-gradient"></div>
          <div
            className={`flex-column flex justify-center items-end ml-10 pr-4 ${expanded ? 'dashboard-years-box' : ''}`}>
            <label className="font-12 regular-text lighter-text one-line">{t('AVERAGE_AGE')}</label>
            <label className="font-20 medium-text one-line">
              {globalStats?.avg_age ? `${globalStats?.avg_age?.toFixed(0)} years` : ''}
            </label>
          </div>
        </div>
        <div className={`border radius-4 ${expanded ? 'dashboard-years-box section' : 'section'} flex-row w-full`}>
          <div className="liner-gradient"></div>
          <div
            className={`flex-column flex justify-center items-end ml-10 pr-4 ${expanded ? 'dashboard-years-box' : ''}`}>
            <label className="font-12 regular-text lighter-text one-line">{t('MEDIAN_AGE')}</label>
            <label className="font-20 medium-text one-line">
              {globalStats?.median_age ? `${globalStats?.median_age?.toFixed(0)} years` : ''}
            </label>
          </div>
        </div>
      </div>
      <div className="flex col-gap-4">
        <div className={`border radius-4 ${expanded ? 'dashboard-years-box section' : 'section'} flex-row w-full`}>
          <div className="liner-gradient"></div>
          <div
            className={`flex-column flex justify-center items-end ml-10 pr-4 ${expanded ? 'dashboard-years-box' : ''}`}>
            <label className="font-12 regular-text lighter-text one-line">{t('PAYMENT_COUNT')}</label>
            <label className="font-20 medium-text one-line">
              {getFormattedNumberStyle(globalStats?.payment_count || 0, 'decimal', 0, 0) || ''}
            </label>
          </div>
        </div>
        <div className={`border radius-4 ${expanded ? 'dashboard-years-box section' : 'section'} flex-row w-full`}>
          <div className="liner-gradient"></div>
          <div
            className={`flex-column flex justify-center items-end ml-10 pr-4 ${expanded ? 'dashboard-years-box' : ''}`}>
            <label className="font-12 regular-text lighter-text one-line">{t('AVERAGE_DONATION')}</label>
            <label className="font-20 medium-text one-line">
              {globalStats?.avg_payment ? getFormattedNumber(globalStats?.avg_payment, 'USD', 2, 2) : ''}
            </label>
          </div>
        </div>
      </div>
    </GenderWrapper>
  );
};

export default AverageStats;
