import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/purple-check.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/trash-red.svg';
import useDebounce from '../../../../helpers/useDebounceHook';
import { uppercase } from '../../../../helpers/utils';
import { getEventTypes, updateEventType } from '../../../../store/features/automationsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { AutomationTriggerWrapper } from '../../../../styles/components/automationDetails/automation-details.styled';
import InputSearch from '../../../common/input-search';
import UpdateAction from '../../../common/update-action';

const AutomationTrigger = ({ automationTrigger = {} }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isEditAutomationTrigger, setIsEditAutomationTrigger] = useState(false);
  const [listData, setListData] = useState([]);
  const [filteredListData, setFilteredListData] = useState([]);
  const [expand, setExpand] = useState(true);
  const [selected, setSelected] = useState({});
  const [searchText, setSearchText] = useState('');

  const debouncedSearch = useDebounce(searchText, 500);

  const onSearchChange = value => {
    setSearchText(value);
  };

  const getEventTypeList = () => {
    dispatch(getEventTypes())
      .then(({ data }) => {
        setListData(data);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Something went wrong' }));
      });
  };
  const resetEditTriggerData = () => {
    setSelected({});
    setIsEditAutomationTrigger(false);
  };
  const onSelectTrigger = selectedTrigger => {
    if (isEditAutomationTrigger && selectedTrigger?.id === automationTrigger.id) {
      resetEditTriggerData();
      return;
    }
    setSelected(selectedTrigger);
    dispatch(updateEventType({ id: id, request: { event_type: selectedTrigger?.event_type } }))
      .then(response => {
        setExpand(false);
        dispatch(getAutomationAction());
      })
      .catch(() => {
        setExpand(false);
      });
  };

  useEffect(() => {
    const filteredData = listData
      .map(item => ({
        ...item,
        event_types: item?.event_types.filter(event =>
          event.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
        ),
      }))
      .filter(item => item.event_types.length > 0);
    setFilteredListData(filteredData);
  }, [listData, debouncedSearch]);

  useEffect(() => {
    if (!automationTrigger || isEditAutomationTrigger) {
      getEventTypeList();
    }
  }, [!automationTrigger, isEditAutomationTrigger]);

  const expandUI = (
    <div className="flex justify-start sub-header-border radius-2 pl-6 pb-6 flex-column relative">
      <InputSearch placeholder={'Search'} value={searchText} onChange={onSearchChange} className="input-search" />
      {filteredListData?.length > 0 &&
        filteredListData?.map(item => (
          <div className="mt-8">
            <label className="medium-text grey-text font-14">{uppercase(item.name)}</label>
            <div className="grid-trigger">
              {item?.event_types.map(event => (
                <div
                  className={`border radius-3 mt-4 pl-4 py-3 pr-5 flex flex-row gap-2 cursor ${
                    selected?.id === event.id ? 'selected' : ''
                  }`}
                  onClick={() => onSelectTrigger(event)}>
                  <img src={event?.icon?.active} alt="icon" height={24} width={24} />
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex-column">
                      <label className="medium-text font-14">{event.name}</label>
                      <label className="regular-text font-12 grey-text"> {event.description}</label>
                    </div>
                    {selected?.id === event.id && (
                      <div className="flex items-center">
                        <CheckIcon width={16} height={16} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      {isEditAutomationTrigger && (
        <UpdateAction className="update-trigger" onCancel={resetEditTriggerData} onUpdate={resetEditTriggerData} />
      )}
    </div>
  );

  const collapsedUI = (
    <div className="flex justify-start sub-header-border radius-2 pl-6 pb-4 flex-column">
      <div className={`border radius-3 pl-4 py-3 pr-5 flex flex-row gap-2 cursor`}>
        <img src={selected?.icon?.active} alt="" className="radius-4" height={24} width={24} />
        <div className="flex-column">
          <label className="medium-text font-14">{selected?.name}</label>
          <label className="font-12 grey-text"> {selected?.description}</label>
        </div>
      </div>
    </div>
  );

  const handleEditTrigger = () => {
    setSelected(automationTrigger);
    setIsEditAutomationTrigger(true);
  };

  return (
    <AutomationTriggerWrapper className="automation-trigger-wrapper flex-column border items-start justify-start w-full radius-3">
      <div className="flex-column items-start justify-start w-full pr-6 pt-4 pb-6">
        <div className="flex items-center header-border-left w-full">
          <div className="flex items-center pl-5 flex-1">
            <div className="dot-2-green" />
            <label className="medium-text font-16 ml-2">{t('TRIGGER')}</label>
          </div>
          {automationTrigger && (
            <div className="flex items-center justify-center cursor edit-delete-btn-wrapper">
              {isEditAutomationTrigger ? (
                <DeleteIcon onClick={resetEditTriggerData} />
              ) : (
                <EditIcon onClick={handleEditTrigger} />
              )}
            </div>
          )}
        </div>
        {automationTrigger?.event_type && !isEditAutomationTrigger && (
          <div className="flex items-center justify-start ml-6 sub-header-border border radius-2 mt-4 px-4 py-3">
            <div className="flex items-start justify-start">
              <div className="flex items-center justify-center icon-wrapper">
                <img src={`${automationTrigger?.icon?.active}`} style={{ width: 24, height: 24 }} />
              </div>
              <div className="flex-column items-start justify-start flex-1 ml-2">
                <label className="medium-text font-14">{automationTrigger?.name}</label>
                <label className="normal-text font-12 grey-text">{automationTrigger?.description}</label>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* selected trigger only visible when trigger is selected or edit is true */}
      {(!automationTrigger || isEditAutomationTrigger) && (expand ? expandUI : selected && collapsedUI)}
    </AutomationTriggerWrapper>
  );
};

export default AutomationTrigger;
