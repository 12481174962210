import styled from 'styled-components';

export const ReactSelectDropdownWrapper = styled.div`
  .control-wrapper {
    min-height: 40px;
    border-radius: 16px;
    box-shadow: none;
    &:hover {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  .hide-content {
    display: none;
  }

  .placeholder-text {
    color: ;
  }
`;
