import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Elements/button/button';
import Loader from '../../components/common/loader';
import { downloadEntrantDetailsCSVFile } from '../../store/features/prizeDrawSlice';
import { addToast } from '../../store/features/toastSlice';

const EntrantExportDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const downloadCsvFile = async data => {
    try {
      const response = await fetch(data?.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', `Entrant_${id}.csv`);
      a.click();
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const downloadEntrantExport = async () => {
    try {
      setLoading(true);
      const response = await dispatch(downloadEntrantDetailsCSVFile({ entrant_id: id }));
      await downloadCsvFile(response);
      dispatch(
        addToast({
          error: false,
          text: 'Entrant data dowloaded successfully',
        }),
      );
      setMessage('Entrant data downloaded');
      setLoading(false);
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: error?.response?.data?.error ? error?.response?.data?.error : 'Something went wrong',
        }),
      );
      setMessage('Error while downloading entrant data');
      setLoading(false);
    }
  };
  useEffect(() => {
    try {
      downloadEntrantExport();
    } catch (error) {
      console.log('error');
    }
  }, [id]);
  return (
    <div className="flex wrap items-center justify-center w-full my-auto h-100-vh">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-column items-center justify-center h-full w-full row-gap-4">
          <label className="w-full font-24 medium-text text-center">{message}</label>
          <div className="flex items-center justify-center w-25">
            <Button
              label="Go To Dashboard"
              size="medium"
              className="primary flex-1"
              onClick={() => navigate('/dashboard')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EntrantExportDetails;
