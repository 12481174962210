import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/common/loader';
import PageHeader from '../../components/page-header';
import PrizeDrawList from '../../components/prize-draw-list';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getPrizeDrawDetail, getPrizeDrawList } from '../../store/features/prizeDrawSlice';
import { AllPrizeDrawWrapper } from '../../styles/pages/all-prize-draws.styled';

const AllPrizeDraws = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    object: { name: 'STATUS', type: 'STATUS', param: 'lifecycle' },
    value: { id: 'ACTIVE', name: 'Active' },
  });
  const [search, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const { setModal } = useContext(OrganisationContext);
  const { prizeDrawList } = useSelector(state => state.prizeDraw);

  const getFilterValue = filter => {
    if (!filter.value?.id) {
      return {};
    }
    return { [filter.object.param]: filter.value?.id || '' };
  };

  const fetchPrizeDrawList = currentPage => {
    setLoading(true);
    dispatch(
      getPrizeDrawList({ params: { ...getFilterValue(filter), search: debouncedSearch, page: currentPage, size: 11 } }),
    )
      .then(response => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchPrizeDrawList(0);
  }, [debouncedSearch]);

  useEffect(() => {
    fetchPrizeDrawList(selectedPage);
  }, [filter.value?.id, selectedPage]);

  const fetchPrizeDrawDetails = data => {
    dispatch(getPrizeDrawDetail({ id: data?.id }));
  };

  const handleActivatePrizedraw = data => {
    const { lifecycle } = data || {};
    setModal({
      type: 'activate-prizedraw',
      content: {
        prizeDrawDetail: data,
        isDeActivated: lifecycle === 'ACTIVE' ? true : false,
        isActivate: lifecycle === 'INACTIVE' ? true : false,
        confirmTitle:
          lifecycle === 'INACTIVE'
            ? 'Would you like to activate this prize draw?'
            : 'Would you like to deactivate this prize draw?',
        handleSuccess: () => fetchPrizeDrawDetails(data),
      },
    });
  };

  const handleAddPrizeDraw = () => {
    setModal({
      type: 'add-prize-draw',
      content: {
        handleOnSuccess: data => {
          navigate(`/prize-draws/all/${data.id}/entrants`);
          setTimeout(() => handleActivatePrizedraw(data), 500);
        },
      },
    });
  };

  return (
    <AllPrizeDrawWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Prize Draws', onClick: () => {} },
          { text: 'All Prize Draws', onClick: () => {} },
        ]}
        showFilter
        filterObject={['STATUS']}
        showSearch
        showAddNewBtn
        filter={filter}
        setFilter={setFilter}
        searchText={search}
        onSearchChange={setSearchText}
        addBtnPermission={'PRIZEDRAWS_MANAGE'}
        onAddClick={() => handleAddPrizeDraw()}
        pagination={{
          selectedPage: selectedPage,
          totalPages: prizeDrawList?.total_pages || 0,
          setSelectedPage: setSelectedPage,
        }}
        showPagination={true}
      />
      {loading ? (
        <Loader height={64} width={64} />
      ) : (
        <PrizeDrawList filter={filter} debouncedSearch={debouncedSearch} />
      )}
    </AllPrizeDrawWrapper>
  );
};

export default AllPrizeDraws;
