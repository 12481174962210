import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantExportTemplateUpdated = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState({});
  const { event_data, performed_by } = log;
  const { prizedraw, export_template, previous } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  const getTitleText = () => {
    return (
      <span>
        <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">{data.title}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
      </span>
    );
  };

  const getDifference = (arr1 = [], arr2 = [], key = 'id') => {
    return (arr1 || []).filter(({ [key]: id1 }) => !(arr2 || []).some(({ [key]: id2 }) => id2 === id1));
  };

  const setDetails = () => {
    let updateData = {};
    if (export_template?.name !== previous?.export_template?.name) {
      updateData = {
        title: 'updated entrant export template name for Prize Draw',
        from: previous?.export_template?.name,
        to: export_template?.name,
      };
    } else if (export_template?.description !== previous?.export_template?.description) {
      updateData = {
        title: 'updated entrant export template description for Prize Draw',
        from: previous?.export_template?.description,
        to: export_template?.description,
      };
    } else if (
      (export_template?.attributes || []).length > (previous?.export_template?.attributes || []).length
        ? getDifference(export_template?.attributes || [], previous?.export_template?.attributes || []).length > 0
        : (export_template?.attributes || [])?.length < (previous?.export_template?.attributes || [])?.length
        ? getDifference(previous?.export_template?.attributes || [], export_template?.attributes || []).length > 0
        : getDifference(export_template?.attributes || [], previous?.export_template?.attributes || []).length > 0
    ) {
      updateData = {
        title: 'updated entrant export template data for Prize Draw',
        from: (previous?.export_template?.attributes || []).map(data => data?.column_name || '').join(', '),
        to: (export_template?.attributes || []).map(data => data?.column_name || '').join(', '),
      };
    }
    setData(updateData);
  };

  useEffect(() => {
    setDetails();
  }, []);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Entrant export template updated'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle text={getTitleText()} showDetails={showDetails} setShowDetails={data => setShowDetails(data)} />
      {showDetails && (
        <>
          <LogContentBox
            title={'From'}
            contentText={<p className="flex regular-text lighter-text font-12">{data?.from}</p>}
          />
          <LogContentBox title={'To'} contentText={<p className="regular-text lighter-text font-12">{data?.to}</p>} />
          {/* <LogContentBox
            title={'Prize Draw'}
            contentText={<p className="regular-text lighter-text font-12">{prizedraw?.name || ''}</p>}
          /> */}
        </>
      )}
    </div>
  );
};

export default EntrantExportTemplateUpdated;
