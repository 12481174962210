import React from 'react';
import { useSelector } from 'react-redux';
import { IntegrationsListWrapper } from '../../styles/components/integrations-list/integrations-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import IntegrationsListItem from './integrations-list-item';

const IntegrationsList = ({ debouncedSearch }) => {
  const { integrationsList } = useSelector(state => state.integrations);

  return (
    <IntegrationsListWrapper className="flex-column flex-1">
      {integrationsList?.length > 0 ? (
        integrationsList?.map(integration => <IntegrationsListItem key={integration?.id} integration={integration} />)
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </IntegrationsListWrapper>
  );
};

export default IntegrationsList;
