import React from 'react';

const LogHeader = ({ icon = <></>, title = '', subtitle = '' }) => {
  return (
    <div className="flex items-center col-gap-2">
      {icon}
      <label className="regular-text medium-text font-12">{title}</label>
      <label className="regular-text lighter-text font-12">{subtitle}</label>
    </div>
  );
};

export default LogHeader;
