import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantExport = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data, performed_by } = log;
  const { prizedraw, entrant_export_request } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Entrant data exported'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">exported entrant data from Prize Draw</span>
            <span className="regular-text zen-purple-text font-12 ml-1">{prizedraw?.code}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'Selected data'}
            contentText={
              <p className="flex regular-text lighter-text font-12">
                {(entrant_export_request?.columns || []).map(val => val?.name || '').join(', ')}
              </p>
            }
          />
          <LogContentBox
            title={'Prize Draw'}
            contentText={<p className="regular-text lighter-text font-12">{prizedraw?.name}</p>}
          />
        </>
      )}
    </div>
  );
};

export default EntrantExport;
