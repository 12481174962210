import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'styled-components';
import { getAddressDetails, getAddressList } from '../../../store/features/groupsSlice';

const controlStyle = (baseStyles, state, theme, error) => ({
  ...baseStyles,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  cursor: 'pointer',
  minHeight: '48px',
  boxShadow: 'none',
  borderColor: error ? 'red' : state.isFocused ? theme.colors.zenPurple : theme.colors.ZenGray2,
  paddingLeft: 16,
  paddingRight: 16,
  ':hover': {
    borderColor: theme.colors.zenPurple,
  },
  ':active': {
    borderColor: theme.colors.ZenGray2,
  },
});

const placeholderStyle = (baseStyles, state, theme) => ({
  ...baseStyles,
  fontFamily: 'Poppins Regular',
  fontWeight: 400,
  color: theme.colors.zenGray,
  fontSize: '16px',
  opacity: 0.3,
});

const inputStyle = (baseStyles, state, theme) => ({
  ...baseStyles,
  fontFamily: 'Poppins Regular',
  color: theme.colors.RegularText,
  fontSize: '16px',
});

const singleValueStyle = (baseStyles, state, theme) => ({
  ...baseStyles,
  fontFamily: 'Poppins Regular',
  color: theme.colors.RegularText,
  fontSize: '16px',
});

const menuStyle = (baseStyles, state, theme) => ({
  ...baseStyles,
  borderRadius: '16px',
  fontFamily: 'Poppins Regular',
  color: theme.colors.RegularText,
  padding: '14px',
  fontSize: '16px',
});

const optionStyle = (baseStyles, state, theme) => ({
  ...baseStyles,
  fontSize: '14px',
  fontWeight: '400',
  fontFamily: 'Poppins Regular',
  borderRadius: '8px',
  paddingRight: '14px',
  paddingLeft: '14px',
  color: state.isSelected ? theme.colors.zenPurple : theme.colors.RegularText,
  backgroundColor: state.isSelected ? theme.colors.backgroundColor : '#ffff',
  cursor: 'pointer',

  ':hover': {
    backgroundColor: theme.colors.backgroundColor,
    color: theme.colors.zenPurple,
  },
});

const valueContainerStyle = (baseStyles, state, theme) => ({
  ...baseStyles,
  padding: 0,
});

const AddressInput = ({ setSelectedAddress = () => {}, address = {}, error, wrapperClassName = '' }) => {
  const dispatch = useDispatch();
  const [editedAddress, setEditedAddress] = useState(address);
  const [addressText, setAddressText] = useState(address.line1);
  const [addressList, setAddressList] = useState([]);
  const [isTyping, setTyping] = useState(true);
  const theme = useTheme();

  const getAddressData = async inputValue => {
    try {
      const response = await dispatch(getAddressList({ params: { search: inputValue } })).catch(err => {});
      const formatedAddressList = response.map(data => {
        return { ...data, value: data.id, label: data.address };
      });
      return formatedAddressList;
    } catch (error) {}
  };

  const handleInputChange = (newValue, a) => {
    if (a.action === 'input-change') {
      setAddressText(newValue);
      setTyping(true);
      return newValue;
    } else {
      return editedAddress?.line1;
    }
  };

  const loadOptions = async inputValue => {
    try {
      if (inputValue) {
        const options = await getAddressData(inputValue);
        setAddressList(options);
        return options;
      }
    } catch (error) {}
  };

  const handleChangeOption = async option => {
    dispatch(getAddressDetails({ id: option.id })).then(response => {
      setEditedAddress(response);
      setSelectedAddress(response);
      setTyping(false);
    });
  };
  useEffect(() => {
    loadOptions(editedAddress.line1);
    setAddressText(editedAddress.line1);
  }, [editedAddress]);
  return (
    <AsyncSelect
      key={editedAddress?.line1 || ''}
      className={wrapperClassName}
      classNamePrefix={'async-select'}
      defaultOptions={addressList || []}
      noOptionsMessage={() => 'No address found'}
      inputValue={addressText || ''}
      onInputChange={(e, action) => handleInputChange(e, action)}
      loadOptions={loadOptions}
      maxMenuHeight={150}
      placeholder={'Enter Address'}
      onChange={option => handleChangeOption(option)}
      value={
        addressText && editedAddress.formatted_address
          ? { ...editedAddress, label: editedAddress.line1, value: editedAddress.id }
          : null
      }
      styles={{
        control: (baseStyles, state) => controlStyle(baseStyles, state, theme, error),
        indicatorsContainer: () => ({ display: 'none' }),
        placeholder: (baseStyles, state) => placeholderStyle(baseStyles, state, theme),
        input: (baseStyles, state) => inputStyle(baseStyles, state, theme),
        singleValue: (baseStyles, state) => singleValueStyle(baseStyles, state, theme),
        menu: (baseStyles, state) => menuStyle(baseStyles, state, theme),
        option: (baseStyles, state) => optionStyle(baseStyles, state, theme),
        valueContainer: (baseStyles, state) => valueContainerStyle(baseStyles, state, theme),
      }}
    />
  );
};

export default AddressInput;
