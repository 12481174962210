import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as MailIcon } from '../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../assets/images/mobile.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { removePrizedrawOrganization } from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { MarketingCompanyActionsWrapper } from './modal.styled';

const MarketingCompanyActions = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const { agency, isDelete, title, prizedrawid, marketingCompany } = modal.content;

  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (isDelete) {
        setModal(initModal);
        dispatch(
          removePrizedrawOrganization({
            prizedrawid: prizedrawid,
            orgID: marketingCompany.id,
            isMarketingOrganization: true,
            parentOrgID: agency.id,
          }),
        )
          .then(() => {
            setLoading(false);
            setModal(initModal);
          })
          .catch(() => {
            setLoading(false);
            dispatch(
              addToast({
                error: true,
                text: 'Error while delete agency',
                id: uuid(),
              }),
            );
          });
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to delete marketing company`,
          id: uuid(),
        }),
      );
    }
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{ label: isDelete ? 'Delete' : 'Confirm', className: isDelete ? 'negative' : 'primary' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <MarketingCompanyActionsWrapper className="flex-column items-center w-full">
        <div className="mt-6 pxy-4 flex-column w-full marketing-compnay-details-container">
          <label className="bold-text font-18">{marketingCompany.name}</label>
          {marketingCompany?.primary_location?.formatted_address && (
            <label className="regular-text font-14 mt-1 w-full one-line">
              {marketingCompany?.primary_location?.formatted_address}
            </label>
          )}
          <div className="flex-column items-start justify-start w-full mt-5">
            <div className="flex items-center justify-start w-full">
              <div className="flex items-center justify-center icon-wrapper mr-2">
                <MailIcon className="flex items-center justify-center " height={16} width={16} />
              </div>
              <label
                className={`regular-text font-14 ${
                  !marketingCompany?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                    ? 'grey-text'
                    : ''
                }`}>
                {marketingCompany?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                  ? marketingCompany?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                  : 'Not provided'}
              </label>
            </div>
            <div className="flex items-center justify-start w-full mt-2">
              <div className="flex items-center justify-center icon-wrapper mr-2">
                <MobileIcon className="flex items-center justify-center " height={16} width={16} />
              </div>
              <label
                className={`regular-text font-14 ${
                  !marketingCompany?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                    ? 'grey-text'
                    : ''
                }`}>
                {marketingCompany?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                  ? marketingCompany?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                  : 'Not provided'}
              </label>
            </div>
          </div>
        </div>
      </MarketingCompanyActionsWrapper>
    </CommonPopup>
  );
};

export default MarketingCompanyActions;
