import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from '../../../../assets/images/user.svg';
import { camelCase, capitalize, formatText } from '../../../../helpers/utils';
import Status from '../../../common/status';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantDrawStatusUpdate = ({ log = {} }) => {
  const { event_data } = log;
  const {
    previous: {
      entrant: { draw: previousDraw },
    },
    entrant: { draw },
  } = event_data;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<UserIcon />}
        title={'Draw status updated'}
        subtitle={log?.tenant?.id ? log?.tenant?.id : 'Entrant'}
      />
      <LogTitle
        showDownIcon={false}
        text={
          <span className="flex items-center fw-w">
            <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {event_data?.entrant?.urn}
            </span>
            <span className="regular-text lighter-text font-12 ml-1">draw status updated from</span>
            <Status
              withDot={true}
              status={previousDraw?.status}
              statusText={capitalize(camelCase(formatText(previousDraw?.status, ' ')))}
              statusWrapperClassName={'ml-1'}
            />
            <span className="regular-text lighter-text font-12 ml-1">to</span>
            <Status
              withDot={true}
              status={draw.status}
              statusText={capitalize(camelCase(formatText(draw.status)))}
              statusWrapperClassName={'ml-1'}
            />
          </span>
        }
      />
    </div>
  );
};

export default EntrantDrawStatusUpdate;
