import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutomationDetailsComponent from '../../components/automation-details';
import PageHeader from '../../components/page-header';
import { getAutomationDetails, getUsersList } from '../../store/features/automationsSlice';
import { AutomationDetailsWrapper } from '../../styles/pages/automation-details.styled';

const AutomationDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { automationDetails } = useSelector(state => state.automations);
  const fetchUserList = (search, page) => {
    dispatch(getUsersList({ params: { size: 15, search: search, page: 0 } }));
  };

  useEffect(() => {
    dispatch(getAutomationDetails({ automationID: id }));
    fetchUserList('', 0);
  }, []);

  return (
    <AutomationDetailsWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Platforms', onClick: () => {} },
          { text: 'Automations', path: '/platforms/automations' },
          { text: automationDetails?.name, onClick: () => {} },
        ]}
      />
      <AutomationDetailsComponent fetchUser={search => fetchUserList(search, 0)} />
    </AutomationDetailsWrapper>
  );
};

export default AutomationDetails;
