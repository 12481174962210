export const TRANSLATIONS_EN_GB = {
  EMAIL_ADDRESS: 'Email address',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  ENTER_PASSWORD: 'Enter password',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot my password',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email or password',
  EMAIL_TO_RECEIVE_CODE: 'Enter your email to receive the security code',
  ENTER_EMAIL_ADDRESS: 'Enter Email address',
  WE_SENT_YOU_EMAIL: 'We sent you an email',
  SEND: 'Send',
  SECURITY_REASON_DETAIL_TEXT:
    'For added security, we need to further verify your account. Please, enter security code from email here',
  INCORRECT_OTP_CODE_ERROR: 'Incorrect code - please check your code is correct and try again',
  VERIFY: 'Verify',
  SUBMIT: 'Submit',
  RESEND_SECURE_CODE: 'Resend secure code',
  RESET_YOUR_PASSWORD: 'Reset your password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  ENTER_YOUR_PASSWORD: 'Enter your password',
  CONFIRM_YOUR_PASSWORD: 'Confirm your password',
  SET_NEW_PASSWORD: 'Set new password',
  SEND_CODE_BY_SMS: 'Send code by SMS',
  SEND_CODE_BY_EMAIL: 'Send code by email',
  SAME_EMAIL_ADDRESS_DETAIL: 'Please ensure you enter the same email address registered with your account',
  PHONE_NUMBER_TO_RECEIVE_CODE: 'Enter your phone number to receive the security code',
  SAME_PHONE_NUMBER_DETAIL: 'Please ensure you enter the same phone number registered with your account',
  PHONE_NUMBER: 'Phone number',
  WE_SENT_YOU_SMS: 'We sent you an SMS',
  CHECK_SMS_CODE: 'Check your SMS and enter your security code below',
  CHECK_EMAIL_CODE: 'Check your email and enter your security code below',
  MINIMUM_PASSWORD_REQUIREMENT:
    'Minimum password 16 characters, at least one upper case, one number, and one special character',
  ADD_NEW: 'Add new',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  EDIT: 'Edit',
  DELETE: 'DELETE',
  PRIZE_DRAW_DETAILS: 'Prize Draw details',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  PRIZE_DRAW_CODE: 'Prize draw code',
  STATUS: 'Status',
  FORM: 'Form',
  ENTRANT: 'Entrant',
  ENTRANTS: 'Entrants',
  EXPORT: 'Export',
  RESULTS: 'results',
  MENU: 'Menu',
  ENTRANT_NAME: 'Entrant name',
  PAYMENT_STATUS: 'Payment status',
  DRAW_STATUS: 'Draw status',
  ENTRY_DATE: 'Entry date',
  PRODUCT_AMOUNT: 'Product Amount',
  PAYMENT_FREQUENCY: 'Payment frequency',
  WIN_STATUS: 'Win status',
  DETAILS: 'Details',
  FORMS: 'Forms',
  DRAW_CONFIGURATION: 'Draw configuration',
  TIME_ZONE: 'Time Zone',
  TIME_ZONE_PRIZE_DRAW_OPERATE: 'Time zone prize draw will operate',
  START_DATE_TIME: 'Start Date & Time',
  PRIZE_DRAW_START_DATE_TIME: 'Prize draw start date and time',
  ENTRANCE_PARAMETERS: 'Entrance Parameters',
  DATE_ENTRANTS_MUST_ENTER_BY: 'Date entrants must enter by',
  WINNER_DATE_GENERATION: 'Winner Date Generation',
  DATE_WINNERS_WILL_GENERATE: 'Date winners will be generated',
  MAKE_RECURRING: 'Make Recurring',
  GENERATE_WINNERS_CONTINUOUSLY: 'Generate winners continuously',
  RECURRENCE_INTERVAL: 'Recurrence Interval',
  PRIZE_DRAW_RECURRENCE: 'Prize draw recurrence',
  RECURRENCE_PATTERN: 'Recurrence Pattern',
  RECURRING_DRAW_SETTINGS: 'Recurring draw settings',
  TIME_OF_DRAW: 'Time of Draw',
  TIME_OF_PRIZE_DRAW: 'The time of the prize draws',
  END_OF_ENTRY_PERIOD: 'End of Entry Period',
  FINAL_ENTRIES_DATE: 'Final entries date',
  SETTINGS: 'Settings',
  SECURITY: 'Security',
  CONFIGURE_YOUR_SECURITY: 'Configure your security settings',
  USERS: 'Users',
  MANAGE_CURRENT_AND_INVITE_USERS: 'Manage current and invite new users',
  TEAMS: 'Teams',
  MANAGE_AND_INVITE_NEW_TEAMS: 'Manage current and create new teams',
  USER_MANAGEMENT: 'User Management',
  MANAGE_USER_ROLES_AND_TEAMS: 'Manage users, roles and teams',
  NO_DATA: 'No data',
  NO_RESULT: 'No results found',
  RECENT_ACTIVITY: 'Recent Activity',
  NETWORK: 'Network',
  CONFIGURATION: 'Configuration',
  ORGANIZATIONS: 'Organizations',
  CONFIGURATION: 'Configuration',
  ENTRY_RESTRICTIONS: 'Entry Restrictions',
  MIN_AGE_TO_ENTER: 'Minimum age to enter',
  F2F_ALLOWED_AGE_RANGE: 'F2F allowed age range',
  ALLOW_PRORATED_CHARGES: 'Allow prorated charges',
  REFERRAL_RULES: 'Referral Rules',
  BONUS_ENTRIES_PER_REFERRAL: 'Bonus entries per referral',
  MAXIMUM_PERMITTED_ENTRIES: 'Maximum permitted entries',
  WINNING_RULES: 'Winning Rules',
  PRIZES: 'Prizes',
  STATES_EXCLUDED: 'States excluded',
  INVITE_USER: 'Invite User',
  NO_DATA: 'No data',
  TYPE_USER_EMAIL: 'Type user’s email',
  TYPE_USER_NAME: 'Full user’s name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  PHONE_OPTIONAL: 'Phone (optional)',
  USER_ROLE: 'User’s role',
  TYPE_USER_PHONENUMBER: '"Type user’s phone number"',
  EMPLOYEE_NUMBER: 'Employee number',
  EMPLOYEE_NUMBER_OPTINAL: 'Badge number (optional)',
  TYPE_EMPLOYEE_NUMBER: "Type employee's number",
  BADGE_NUMBER: 'Badge number',
  BADGE_NUMBER_OPTINAL: 'Badge number (optional)',
  TYPE_BADGE_NUMBER: 'Type badge number',
  CANCEL: 'Cancel',
  SEND_INVITE: 'Send invite',
  NO_RESULT_FOUND: 'There aren’t any results for this query.',
  ADD_MARKET_COMPANY: 'Add marketing company',
  REMOVE: 'Remove',
  GROUPS: 'Group',
  GROUP_DETAILS: 'Group details',
  ACCOUNTS: 'Accounts',
  PLATFORMS: 'Platforms',
  AUTOMATIONS: 'Automations',
  AUTOMATION_DETAILS: 'Automation details',
  OWNER: 'Owner',
  ADD_NEW_ACTION: 'Add new action',
  ADD: 'Add',
  ENTER_GROUP_NAME: 'Enter group name',
  ENTER_DESCRIPTION: 'Enter description',
  ADD: 'Add',
  CREATE_ACCOUNT: 'Create account',
  ACCOUNT_INFO: 'Account info',
  ADDRESS: 'Address',
  USER_INFO: 'User info',
  ABBREVIATION: 'Abbreviation',
  NEXT: 'Next',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  ENTER_ADDRESS: 'Enter address',
  CITY: 'City',
  ENTER_CITY: 'Enter city',
  STATE: 'State',
  ZIP_CODE: 'Zip code',
  SELECT_STATE: 'Select State',
  ENTER_ZIPCODE: 'Enter zip code',
  ENTER_FULL_NAME: 'Enter full name',
  FORM_DETAILS: 'Form details',
  PRIZE_DRAW: 'Prize Draw',
  LABEL: 'Label',
  CLIENT: 'Client',
  CLIENT_DRAW_CODE: 'Client draw code',
  VERSION_DETAILS: 'Version details',
  FORM_NAME: 'Form Name',
  VERSION: 'Version',
  CREATED: 'Created',
  PUBLISHED: 'Published',
  RETIRED: 'Retired',
  FORM_LOG: 'Form log',
  DUPLICATE: 'Duplicate',
  REPUBLISH: 'Republish',
  RETIRE: 'Retire',
  PUBLISH: 'Publish',
  VERSION_POPUP_TITLE: 'Would you like to {{type}} this version?',
  PUBLISH_VERSION_POPUP_TITLE: 'Are you sure you want to {{type}} this version?',
  CLIENTS: 'Clients',
  FEES: 'Fees',
  ACTIVATE_YOUR_ACCOUNT: 'Activate your account',
  ACTIVATE: 'Activate',
  ENTRANT_DETAILS: 'Entrant details',
  OVERVIEW: 'Overview',
  PAYMENTS: 'Payments',
  PAYMENT_AMOUNT: 'Payment amount',
  PAYMENT_COUNT: 'Payment count',
  COUNT_BY_FREQUENCY: 'Count by frequency',
  WINNINGS: 'Winnings',
  ENTERED_ON: 'Entered on',
  WON: 'Won',
  PAYMENT_METHOD: 'Payment Method',
  NOTES: 'Notes',
  PRODUCT: 'Product',
  CONTACTED_BY: 'Contacted by',
  CANCELED_ON: 'Canceled on',
  NEXT_PAYMENT_ON: 'Next payment on',
  ACCOUNT_DETAILS: 'Account details',
  PRIMARY_USER: 'Primary user',
  CONTACT: 'Contact',
  ENTRY_REFERRALS: 'Entry & Referrals',
  ENTRY_FORM: 'Entry form',
  REFERRALS: 'Referrals',
  REFERRAL_SOURCE: 'Referral source',
  ENTRANT: 'Entrant',
  REFEREES: 'Referees',
  LOGS: 'Logs',
  ACTIVITY_LOGS: 'Activity Log',
  TITLE: 'Title',
  ADDRESS_2: 'Address 2',
  MOBILE: 'Mobile',
  HOME: 'Home',
  ANONYMIZE: 'Anonymize',
  SUCCESSFUL: 'Successful',
  DATE: 'Date',
  ID: 'Id',
  AUTOMATION_LOG: 'Automation Log',
  EVENT: 'Event',
  UPDATE_USER: 'Update user',
  MY_PROFILE: 'My profile',
  USER_PROFILE: 'User profile',
  USER_BADGE: 'User badge ',
  COMING_SOON: 'Coming soon',
  MFA_CONFIGURATION: 'MFA configuration ',
  SAVE: 'Save ',
  VERIFY_CODE: 'Verify the code sent to your phone ',
  VERIFY_CODE_EMAIL: 'Verify the code sent to your email ',
  SEND_CODE: 'Send Code ',
  USER: 'user ',
  PRODUCT_FREQUENCY: 'Product Frequency',
  BADGE_NUMBER: 'Badge Number',
  PLATFORMFEE: 'Platform fee',
  STARTDATE: 'Start on',
  ENDDATE: 'End on',
  PLATFORM_FEE: 'Platform fee',
  START_ON: 'Start on',
  END_ON: 'End on',
  CREATED_DATE: 'Created date',
  PASSWORD_MUST: 'Password must:',
  SELECT_INTEGRATION: 'SELECT INTEGRATION',
  CONDITION: 'Condition',
  REMOVE_ALL: 'Remove all',
  NO_CONFIG_PARAMS_FOUND: 'No config params found',
  SCHEDULE: 'SCHEDULE',
  TRIGGER: 'Trigger:',
  RETRY: 'Retry',
  AUTOMATION_NAME: 'Automation name',
  DATE: 'Date',
  STATUS: 'Status',
  PRODUCT_DETAILS: 'Product details',
  PRODUCT_NAME: 'Product name',
  AMOUNT: 'Amount',
  FREQUENCY: 'Frequency',
  CONTINUAL_BILLING: 'Continual billing',
  COHORT_BILLINGS: 'Cohort billing',
  ALL: 'All',
  BILLING_TYPE: 'Billing type',
  START: 'Start',
  END: 'End',
  FEE: 'Fee',
  FEE_LOG: 'Fee log',
  CLEAR: 'Clear',
  COUNT: 'Count',
  INTERVAL: 'Interval',
  MIN: 'Min',
  MAX: 'Max',
  AVERAGE_AGE: 'Avg. age',
  MEDIAN_AGE: 'Median age',
  PAYMENT_COUNT: 'Payment count',
  AVERAGE_PAY: 'Avg. pay',
  AVERAGE_DONATION: 'Avg. donation',
  STATISTICS: 'Statistics',
  DRAWS: 'Draws',
  GENDER: 'Gender',
  GEOMAP: 'GeoMap',
  DASHBOARD: 'Dashboard',
  ENTRANT_PAYMENT_RETENTION: 'Entrant payment retention',
  FILTER: 'Filter',
  IFRAME: 'Iframe:',
  SHARE: 'Share:',
  LAUNCH_FORM: 'Launch form',
  ENTER_PAYMENT_DETAILS: 'Enter payment details',
  PAYMENT_SUCCEEDED: 'Payment succeeded!',
  USER_ROLES: 'User Roles',
  TAGS: 'Tags',
  PRIZE_DRAWS: 'Prize Draws',
  SELECT_CLIENT: 'Select client',
  OUT_OF_DRAW: 'Out of draw',
  EXTERNAL_REFERNCE: 'External reference',
  DOWNLOAD: 'Download',
  GROSS_PAYMENT: 'Gross payment',
  GROSS_REFUND: 'Gross refund',
  NET_PAYMENT: 'Net payment',
  GROSS_PAYMENT_COUNT: 'Gross payment count',
  GROSS_REFUND_COUNT: 'Gross refund count',
  NET_PAYMENT_COUNT: 'Net payment count',
  CANCELLATION_REASON: 'Cancellation reason',
  MOST_RECENT_DECLINE_CODE: 'Most recent decline code',
  USER: 'User',
  ADDED_AN_ACCOUNT: 'added an account',
  ACCESSED_ACCOUNT: 'accessed account',
  UPDATED_ENTRANT: 'updated entrant',
  BILLING_ADDRESS: 'billing address',
  UPDATED: 'updated',
  UPDATED_PRIZE_DRAW: 'updated Prize Draw',
  EMAIL_LC: 'email',
  UPDATED_THEIR_EMAIL: 'updated their email',
  ANONYMIZED_ENTRANT: 'anonymized entrant',
  SYSTEM_ANONYMIZED_ENTRANT: 'System anonymized entrant',
  CANCELED_ENTRANT: 'Canceled entrant',
  PRODUCT_SUBSCRIPTION: 'product subscription',
  ENTRY_LC: 'entry',
  DEBIT_CARD: 'Debit Card',
  US_BANK_ACCOUNT: 'US Bank Account',
  ADDED_A_GROUP: 'added a group',
  DELETED_GROUP: 'deleted group',
  NAME_LC: 'name',
  WAS_CHARGED: 'was charged',
  UNSUCCESSFULLY: 'unsuccessfully',
  SUCCESSFULLY: 'successfully',
  ADDED_PRIZE_DRAW: 'added Prize Draw',
  PRORATED_CHARGES: 'prorated charges',
  ADDED_A: 'added a',
  FORM_TO_PRIZE_DRAW: 'form to Prize Draw',
  RETIRED_FORM_VERSION: 'retired form version',
  IN_PRIZE_DRAW: 'in Prize Draw',
  STATUS_UPDATED_FROM: 'status updated from',
  ACTIVATED_PRIZE_DRAW: 'activated Prize Draw',
  PRIZE: 'prize',
  SHIPPING_ADDRESS: 'shipping address',
  DOWNLOADED_ENTRANT: 'downloaded entrant',
  ENTRY_FORM_PDF: 'entry form PDF',
  ACTIVATED: 'activated',
  USER_LC: 'user',
  WAS_ANONYMIZED: 'was anonymized',
  INVITED: 'invited',
  REQUEST_FOR_OTP: 'requested for otp',
  SELECT_ORGANIZATION: 'Select organization',
  PRIZE_AMOUNT: 'Prize amount:',
  NUMBER_OF_ENTRANTS: 'Number of entrants:',
  WINNERS: 'Winners',
  ACCOUNT_UC: 'ACCOUNT',
  ACTIVE: 'Active',
  DATE_RANGE: 'DATE RANGE',
  FROM: 'From',
  TO: 'To',
  FEE_AMOUNT: 'Fee amount',
  INTEGRATION: 'Integration',
  ADVANCED_UC: 'ADVANCED',
  CONFIDENTIAL: 'Confidential',
  REQUIRES_CONFIRMATION: 'Requires Confirmation',
  VISIBLE: 'Visible',
  OPTIONS_UC: 'OPTIONS',
  GENERAL_UC: 'GENERAL',
  AGE_RANGE: 'Age range',
  CHARACTER_LIMIT: 'Character limit',
  REQUIRED: 'Required',
  STYLE: 'Style',
  Font: 'Font',
  SIZE: 'Size',
  IMAGE: 'Image',
  COMPONENT_DETAILS: 'Component details',
  PRODUCTS_UC: 'PRODUCTS',
  CONFIRMATION_PAGE_UC: 'CONFIRMATION PAGE',
  CONFIRMATION_PAGE: 'Confirmation page',
  DISPLAY_WATERMARK: 'Display watermark',
  FONT_UC: 'FONT',
  DEFAULT_FONT: 'Default font',
  FONT_STYLE: 'Font style',
  WIDTH: 'Width',
  PROGRESS_BAR_UC: 'PROGRESS BAR',
  COMPONENTS_UC: 'COMPONENTS',
  PREVIEW_FORM: 'Preview form',
  FORM_SETTINGS: 'Form Settings',
  WIDGET_CONFIGURATION: 'Widget configuration',
  MARKETING_COMPANY: 'Marketing company',
  DATE_SETTINGS: 'Date settings',
  DATE_RANGE: 'Date range',
  DATE_FORMAT: 'Date format',
  ENTRANT_EXPORT: 'Entrant export',
  EXPORT_FILTER: 'Export filter',
  TEMPLATE: 'Template',
  CREATE_TEMPLATE: 'Create template',
  PROVIDERS: 'Providers',
  ENTRANTS_NAME: 'Entrant’s name',
  CONTACT_TYPE: 'Contact type',
  CONTACTED_CLIENT: 'Contacted client',
  CANCELLATION_DATE: 'Cancellation date',
  CURRENT: 'Current',
  NEW: 'New',
  NO_PRIZEDRAW_TO_SHOW: 'No prizedraws to show',
  ADD_NEW_FILTER: 'Add New Filter',
  PLEASE_ENTER_PREVIOUS_FILTER: 'Please enter previous filter details',
  ENTRIES: 'Entries',
  OUT_OF_DRAW_DATE: 'Out Of Draw Date',
  LAST_SUCCESSFUL_PAYMENT_DATE: 'Last Successful Payment Date',
  NEXT_PAYMENT_DATE: 'Next Payment Date',
  PAYMENT_TYPE: 'Payment Type',
  DECLINE_CODE: 'Decline Code',
  WIN_COUNT: 'Win Count',
  FUNDRAISER: 'Fundraiser',
  FUNDRAISER_BADGE: 'Fundraiser Badge',
  AGENCY: 'Agency',
  PRIZEDRAW: 'Prizedraw',
  PRIZE_DRAW: 'Prize Draw',
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  AMERICAN_EXPRESS: 'American Express',
  CANCELED: 'Canceled',
  PAST_DUE: 'Past Due',
  UNPAID: 'Unpaid',
  PAUSED: 'Paused',
  INCOMPLETE: 'Incomplete',
  INCOMPLETE_EXPIRED: 'Incomplete Expired',
  CANCEL_PENDING: 'Cancel Pending',
  IN_DRAW: 'In Draw',
  INACTIVE: 'Inactive',
  DEFAULT: 'Default',
  CUSTOM: 'Custom',
  EXPIRED: 'Expired',
  OTHER: 'Other',
  CONSENT_TEXT:
    'You agree that your phone number will be used for security purposes, such as helping you get back into your account if you ever forget your password. Terms and Privacy are found at zenterprize.us/privacy-policy',
  CONSENT_LABEL: 'Sign up for SMS',
  CONSENT_ERROR: 'Agreement to SMS is required',
  PRICING: 'Pricing',
  DONATION_FREQUENCY: 'Donation frequency',
  DONATION_AMOUNT: 'Donation amount',
  HEATMAP: 'Heatmap',
  DONATIONS: 'Donations',
  PAYMENT_RETENTION: 'Payment retention',
  END_DATE_SHOULD_NOT_BE_LESS_THEN_START_DATE: 'End date should not be less than start date',
  OFFLINE_OR_POOR_WIFI:
    'Seems like you are offline or on a poor wifi connection, please connect to a stronger wifi connection and try again',
};
