import { combineReducers, configureStore } from '@reduxjs/toolkit';
import automationsSlice from './features/automationsSlice';
import billingSlice from './features/billingSlice';
import dashboardSlice from './features/dashboardSlice';
import formsSlice from './features/formsSlice';
import groupsSlice from './features/groupsSlice';
import integrationsSlice from './features/integrationsSlice';
import prizeDrawSlice from './features/prizeDrawSlice';
import profileSlice from './features/profileSlice';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';
import userManagementSlice from './features/userManagementSlice';
import userSlice from './features/userSlice';

const combinedReducer = combineReducers({
  reload: reloadSlice,
  toast: toastSlice,
  user: userSlice,
  prizeDraw: prizeDrawSlice,
  integrations: integrationsSlice,
  usersManagement: userManagementSlice,
  groups: groupsSlice,
  automations: automationsSlice,
  forms: formsSlice,
  billing: billingSlice,
  dashboard: dashboardSlice,
  profile: profileSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/setUserLogout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_STAGE === 'development',
});
