import update from 'immutability-helper';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import { ReactComponent as AddPageIcon } from '../../assets/images/add-page.svg';
import { ReactComponent as DragHandleIcon } from '../../assets/images/drag-handle.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as LockIcon } from '../../assets/images/lock.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import Button from '../../components/Elements/button/button';
import Address from '../../components/FormElements/address';
import Checkbox from '../../components/FormElements/checkbox';
import DateOfBirthDayMonthYear from '../../components/FormElements/date-of-birth/DayMonthYear';
import DateSelectorElement from '../../components/FormElements/date-selector';
import Dropdown from '../../components/FormElements/dropdown';
import FormPhoneInput from '../../components/FormElements/form-phone-input';
import HeaderElement from '../../components/FormElements/header';
import ImageElement from '../../components/FormElements/image-element';
import InputElement from '../../components/FormElements/input';
import Payment from '../../components/FormElements/payment';
import Signature from '../../components/FormElements/signature';
import UpdateAction from '../../components/common/update-action/index';
import { DndContainer } from '../../components/dnd';
import { DndCard } from '../../components/dnd/card';
import { Tooltip } from '../../components/tooltip/tooltip';
import { widgetNewComponentDetails } from '../../constant/InitialData';
import FormBuilderContext from '../../context/FormBuilderContext';
import { getAlignment, getFontFamily } from '../../helpers/utils';
import { MiddlePanelWrapper } from './edit-form-version.styled';

const PagePanel = () => {
  const {
    panelConfig,
    setPanelConfig,
    updatePanelPosition,
    selectedPage,
    setSelectedPage,
    onDeletePagePanel,
    onUpdatePanelName,
  } = useContext(FormBuilderContext);
  const [showAddPage, setShowAddPage] = useState({ step: null, position: null, pageName: null });
  const [showEditPage, setShowEditPage] = useState();

  const onAddNewPage = () => {
    const pageIndex = panelConfig.findIndex(({ key }) => key === showAddPage.step);
    const pageListCopy = [...panelConfig];
    if (showAddPage.position === 'PREVIOUS') {
      pageListCopy.splice(pageIndex, 0, {
        name: showAddPage.pageName || '',
        key: uuid(),
        isNew: true,
        panel_color: '#FF11EE',
        submit_onpayment: false,
        is_confirmation_panel: false,
      });
    } else if (showAddPage.position === 'NEXT') {
      pageListCopy.splice(pageIndex + 1, 0, {
        name: showAddPage.pageName || '',
        key: uuid(),
        isNew: true,
        panel_color: '#FF11EE',
        submit_onpayment: false,
        is_confirmation_panel: false,
      });
    } else if (showAddPage.step === 'FIRST') {
      pageListCopy.push({
        name: showAddPage.pageName || '',
        key: uuid(),
        isNew: true,
        panel_color: '#FF11EE',
        submit_onpayment: false,
        is_confirmation_panel: false,
      });
    }
    setPanelConfig([...pageListCopy]);
    setShowAddPage({ step: null, position: null, pageName: null });
  };

  const newPageToolTipContent = () => {
    return (
      <div className="add-page-wrapper">
        <div>
          <label className="flex regular-text grey-text font-12 mb-1">New page</label>
          <input
            className="input w-full add-page-input"
            placeholder="Page name"
            value={selectedPage.pageName}
            onChange={({ target: { value } }) => setShowAddPage({ ...showAddPage, pageName: value })}
          />
        </div>
        <div className="flex col-gap-6 mt-6">
          <Button
            size="medium"
            label="Cancel"
            className="primary-white flex-1"
            onClick={() => setShowAddPage({ step: null, position: null, pageName: null })}
          />
          <Button size="medium" label="Add" className="primary flex-1" onClick={() => onAddNewPage()} />
        </div>
      </div>
    );
  };

  const updatePosition = useCallback(
    (dragIndex, hoverIndex) => {
      const newConfig = update(panelConfig, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, panelConfig[dragIndex]],
        ],
      });
      updatePanelPosition(newConfig);
    },
    [panelConfig],
  );

  const onDeletePanel = key => {
    onDeletePagePanel(panelConfig.find(panel => panel.key === key));
  };

  const updatePanelName = () => {
    const { name, key, previousName } = showEditPage;
    onUpdatePanelName(name || previousName, key);
    setShowEditPage(null);
  };

  return (
    <div className="w-full flex overflow-scroll steps-list">
      <DndContainer>
        {panelConfig.length === 0 && (
          <div className="flex step-container">
            <div className="flex items-center px-3 add-step">
              <Tooltip
                shouldOpen={false}
                isAlwaysOpen={showAddPage.step === 'FIRST'}
                content={newPageToolTipContent()}
                placement={'bottom-start'}>
                <AddPageIcon className="cursor" onClick={() => setShowAddPage({ step: 'FIRST' })} />
              </Tooltip>
            </div>
          </div>
        )}
        {panelConfig.map(({ name, key, is_confirmation_panel }, index) => (
          <DndCard
            index={index}
            id={key}
            setCards={updatePosition}
            key={key}
            canDragAndDrop={!is_confirmation_panel}
            shouldNotDropOnIndex={is_confirmation_panel ? index : null}>
            <div className="flex step-container">
              <div
                className={`flex items-center px-3 add-step ${
                  showAddPage.step !== key && showEditPage?.key !== key && 'display-none'
                }`}>
                <Tooltip
                  shouldOpen={false}
                  isAlwaysOpen={showAddPage.step === key && showAddPage.position === 'PREVIOUS'}
                  content={newPageToolTipContent()}
                  placement={'bottom-start'}>
                  <AddPageIcon className="cursor" onClick={() => setShowAddPage({ step: key, position: 'PREVIOUS' })} />
                </Tooltip>
              </div>
              <div
                onClick={() => setSelectedPage({ name, key })}
                className={`flex items-center px-2 step-wrapper cursor ${
                  index === 0 ? 'first-page' : index === panelConfig.length - 1 ? 'last-page' : 'middle-page'
                } ${selectedPage.key === key && 'selected-step'}`}>
                {showEditPage?.key === key ? (
                  <div className="page-edit-action-tooltip">
                    <Tooltip
                      isAlwaysOpen={true}
                      content={
                        <div className="page-edit-action">
                          <UpdateAction
                            onCancel={e => {
                              e.stopPropagation();
                              setShowEditPage(null);
                            }}
                            onUpdate={e => {
                              e.stopPropagation();
                              updatePanelName();
                            }}
                          />
                        </div>
                      }
                      placement="bottom"
                      className="w-full">
                      <input
                        className="input page-edit-input"
                        placeholder="Page name"
                        value={showEditPage.name}
                        autoFocus
                        onChange={({ target: { value } }) => setShowEditPage({ ...showEditPage, name: value })}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <>
                    <label
                      className={`flex regular-text font-12 pl-2 flex-1 ${
                        selectedPage.key === key && 'medium-text zen-purple-text'
                      }`}>
                      {name}
                      {!is_confirmation_panel && (
                        <div
                          className="display-none delete-step ml-1"
                          onClick={e => {
                            e.stopPropagation();
                            setShowEditPage({ name, key, previousName: name });
                          }}>
                          <EditIcon />
                        </div>
                      )}
                    </label>
                    {is_confirmation_panel ? (
                      <div className="display-none delete-step">
                        <LockIcon height={16} width={16} />
                      </div>
                    ) : (
                      <div className="display-none delete-step">
                        <DeleteIcon
                          height={16}
                          width={16}
                          onClick={e => {
                            e.stopPropagation();
                            onDeletePanel(key);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {!is_confirmation_panel && (
                <div
                  className={`flex items-center px-3 add-step ${
                    showAddPage.step !== key && showEditPage?.key !== key && 'display-none'
                  }`}>
                  <Tooltip
                    shouldOpen={false}
                    isAlwaysOpen={showAddPage.step === key && showAddPage.position === 'NEXT'}
                    content={newPageToolTipContent()}
                    placement={'bottom-start'}>
                    <AddPageIcon className="cursor" onClick={() => setShowAddPage({ step: key, position: 'NEXT' })} />
                  </Tooltip>
                </div>
              )}
            </div>
          </DndCard>
        ))}
      </DndContainer>
    </div>
  );
};

const WidgetPanel = () => {
  const {
    formAttributes,
    panelWidgets,
    selectedWidget,
    setSelectedWidget = () => {},
    onDeleteWidget,
    panelConfig,
    onAddWidget,
  } = useContext(FormBuilderContext);

  const { display_watermark, alignment, watermark_location, image } = formAttributes?.watermark || {};
  const { display_watermark: confirmation_panel_watermark } = formAttributes?.confirmation_panel || {};

  const [values, setValues] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);
  const showRequiredError = false;
  const showRequireConfirmationError = false;

  const onAddressUpdate = (address, widget) => {
    const addressValue = widget.components
      .filter(c => c.lexicon?.includes('contact'))
      .reduce((acc, { lexicon, id }) => {
        acc[id] = address[lexicon.split('.')[1]] || '';
        return acc;
      }, {});
    setSelectedAddress(address);
    return addressValue;
  };

  const autoFillLookUp = lexicon => {
    return '';
  };

  const getFormAttributeValue = field => {
    return null;
  };

  const getProductOptions = () => {
    return [];
  };

  const selectedProduct = {};

  const setPaymentDetails = () => {};
  const onSubmitForm = () => {};

  const groupByRow = components => {
    return components.reduce((acc, curr) => ({ ...acc, [curr.row]: (acc[curr.row] || []).concat(curr) }), {});
  };

  const getPanelWidgetList = panel => {
    return panelWidgets.find(p => (panel.key ? panel.key === p.id : panel.id === p.id))?.widgets || [];
  };

  const onAddNewWidget = (panelId, newWidgetIndex) => {
    onAddWidget(
      {
        name: 'New widget',
        key: uuid(),
        isNew: true,
        components: [widgetNewComponentDetails],
        widget_type: 'BASIC',
      },
      newWidgetIndex,
      panelId,
      true,
    );
  };

  const formWidgets = (widget, index, panelId) => {
    return (
      <Fragment>
        {((selectedWidget?.key && selectedWidget.key === widget.key) ||
          (selectedWidget?.id && selectedWidget?.id === widget.id)) && (
          <div className="flex items-center justify-center relative add-widget my-1">
            <div className="flex items-center justify-center plus divider-border mx-1">
              <PlusIcon className="cursor" onClick={() => onAddNewWidget(panelId, index)} />
            </div>
            <div className="w-full absolute line divider-top" />
          </div>
        )}
        <Draggable key={widget.key || widget.id} draggableId={widget.key || widget.id} index={index}>
          {provided => (
            <div
              className={`w-full flex-column widget-hover my-1 flex ${
                ((selectedWidget?.key && selectedWidget.key === widget.key) ||
                  (selectedWidget?.id && selectedWidget?.id === widget.id)) &&
                'selected-widget'
              }`}
              onClick={() => setSelectedWidget({ ...widget, panelId })}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}>
              <div className="flex my-2 widget-container">
                <div className="flex items-center justify-center justify-center widget-hover-icon">
                  <DragHandleIcon />
                </div>
                <div className="flex-column flex-1">
                  {Object.values(groupByRow(widget.components)).map((c, index) => (
                    <div className="flex flex-1 w-full col-gap-10">
                      {c.map(
                        component =>
                          component.is_visible && (
                            <>
                              <div
                                className={`w-full ${index > 0 && 'mt-4'}`}
                                style={{ flex: `1 1 calc(100%/${c.length})` }}>
                                {component.component_type === 'IMAGE' && (
                                  <ImageElement
                                    key={component.id}
                                    src={component?.image?.image?.url}
                                    alt={component.name}
                                    media={component?.image?.media}
                                    size={getFormAttributeValue('width')}
                                    width={component?.image?.width}
                                    height={component?.image?.height}
                                  />
                                )}
                                {component.component_type === 'HEADER' && (
                                  <HeaderElement
                                    key={component.id}
                                    name={component.name}
                                    font={component.font}
                                    fontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
                                    alignment={getAlignment(component.alignment)}
                                  />
                                )}
                                {component.component_type === 'CHOOSER' && (
                                  <Dropdown
                                    {...component}
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    placeholder={component.label}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    is_autofill={component.is_autofill}
                                    sub_label={component.sub_label}
                                    autoFillValue={autoFillLookUp(component.lexicon)}
                                    options={component.list?.choices?.map(c => ({ ...c, label: c.name, value: c.id }))}
                                    font={formAttributes?.font}
                                    selectedValue={values[component.id]}
                                    onChange={option => {}}
                                  />
                                )}
                                {component.component_type === 'PHONE' && (
                                  <FormPhoneInput
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    font={formAttributes?.font}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    is_autofill={component.is_autofill}
                                    autoFillValue={autoFillLookUp(component.lexicon)}
                                    sub_label={component.sub_label}
                                    phoneValue={values[component.id]}
                                    onChange={value => {}}
                                  />
                                )}
                                {(component.component_type === 'ALPHA' ||
                                  component.component_type === 'NUMERIC' ||
                                  component.component_type === 'ALPHANUMERIC') && (
                                  <div className="flex col-gap-10">
                                    <InputElement
                                      className="w-full"
                                      key={component.id}
                                      is_required={component.is_required}
                                      showRequiredError={showRequiredError && component.is_required}
                                      showRequireConfirmationError={
                                        component.requires_confirmation &&
                                        showRequireConfirmationError &&
                                        values[component.id] !== values[`${component.id}-confirm`]
                                      }
                                      name={component.name}
                                      label={component.label}
                                      font={formAttributes?.font}
                                      sub_label={component.sub_label}
                                      fontFamily={getFontFamily(
                                        formAttributes?.font?.style,
                                        formAttributes?.font?.name,
                                      )}
                                      is_autofill={component.is_autofill}
                                      autoFillValue={autoFillLookUp(component.lexicon)}
                                      type={component.component_type}
                                      value={values[component.id]}
                                      onChange={value => {}}
                                    />
                                    {component.requires_confirmation && (
                                      <InputElement
                                        className="w-full"
                                        is_required={component.is_required}
                                        showRequiredError={showRequiredError && component.is_required}
                                        showRequireConfirmationError={
                                          showRequireConfirmationError &&
                                          values[component.id] !== values[`${component.id}-confirm`]
                                        }
                                        name={`Confirm ${component.name}`}
                                        label={component.label}
                                        font={formAttributes?.font}
                                        sub_label={component.sub_label}
                                        fontFamily={getFontFamily(
                                          formAttributes?.font?.style,
                                          formAttributes?.font?.name,
                                        )}
                                        is_autofill={component.is_autofill}
                                        type={component.component_type}
                                        value={values[`${component.id}-confirm`]}
                                        onChange={value => {}}
                                      />
                                    )}
                                  </div>
                                )}
                                {component.component_type === 'DOB' &&
                                  component.component_layout === 'DAYMONTHYEAR' && (
                                    <DateOfBirthDayMonthYear
                                      key={component.id}
                                      is_required={component.is_required}
                                      showRequiredError={showRequiredError && component.is_required}
                                      name={component.name}
                                      placeholder={component.label}
                                      font={formAttributes?.font}
                                      sub_label={component.sub_label}
                                      fontFamily={getFontFamily(
                                        formAttributes?.font?.style,
                                        formAttributes?.font?.name,
                                      )}
                                      is_autofill={component.is_autofill}
                                      autoFillValue={autoFillLookUp(component.lexicon)}
                                      selectedDate={values[component.id]}
                                      setSelectedDate={value => {}}
                                    />
                                  )}
                                {component.component_type === 'DOB' &&
                                  (component.component_layout === 'DATEPICKER' || !component.component_layout) && (
                                    <DateSelectorElement
                                      key={component.id}
                                      is_required={component.is_required}
                                      showRequiredError={showRequiredError && component.is_required}
                                      name={component.name}
                                      placeholder={component.label}
                                      font={formAttributes?.font}
                                      sub_label={component.sub_label}
                                      fontFamily={getFontFamily(
                                        formAttributes?.font?.style,
                                        formAttributes?.font?.name,
                                      )}
                                      is_autofill={component.is_autofill}
                                      autoFillValue={autoFillLookUp(component.lexicon)}
                                      selectedDate={values[component.id]}
                                      setSelectedDate={value => {}}
                                    />
                                  )}
                                {component.component_type === 'DATE' && (
                                  <DateSelectorElement
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    placeholder={component.label}
                                    font={formAttributes?.font}
                                    sub_label={component.sub_label}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    is_autofill={component.is_autofill}
                                    autoFillValue={autoFillLookUp(component.lexicon)}
                                    selectedDate={values[component.id]}
                                    setSelectedDate={value => {}}
                                  />
                                )}
                                {component.component_type === 'MULTICHOICE' && (
                                  <Checkbox
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    font={formAttributes?.font}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    is_autofill={component.is_autofill}
                                    autoFillValue={autoFillLookUp(component.lexicon)}
                                    checked={values[component.id]}
                                    onChange={value => {}}
                                  />
                                )}
                                {component.component_type === 'SIGNATURE' && (
                                  <Signature
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    font={formAttributes?.font}
                                    sub_label={component.sub_label}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    signatureValue={values[component.id]}
                                    onChange={value => {}}
                                    pageWidth={formAttributes.panel_width}
                                  />
                                )}
                                {component.component_type === 'ADDRESS' && (
                                  <Address
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    font={formAttributes?.font}
                                    sub_label={component.sub_label}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    is_autofill={component.is_autofill}
                                    autoFillValue={autoFillLookUp(component.lexicon)}
                                    placeholder={component.label}
                                    selectedValue={values[component.id]}
                                    onAddressUpdate={address => {}}
                                  />
                                )}
                                {component.component_type === 'PRODUCT' && (
                                  <Dropdown
                                    options={getProductOptions(component.products)}
                                    key={component.id}
                                    is_required={component.is_required}
                                    showRequiredError={showRequiredError && component.is_required}
                                    name={component.name}
                                    placeholder={component.label}
                                    font={formAttributes?.font}
                                    sub_label={component.sub_label}
                                    fontFamily={getFontFamily(formAttributes?.font?.style, formAttributes?.font?.name)}
                                    is_autofill={component.is_autofill}
                                    autoFillValue={autoFillLookUp(component.lexicon)}
                                    selectedValue={values[component.id]}
                                    onChange={option => {}}
                                    updateOnLoad={true}
                                    {...component}
                                  />
                                )}
                                {component.component_type === 'PAYMENT' && (
                                  <Payment
                                    key={component.id}
                                    name={component.name}
                                    placeholder={component.label}
                                    integration={component.integration}
                                    selectedProduct={selectedProduct}
                                    selectedAddress={selectedAddress}
                                    backgroundColor={getFormAttributeValue('background_color')}
                                    setPaymentDetails={setPaymentDetails}
                                    onSubmitForm={onSubmitForm}
                                  />
                                )}
                              </div>
                            </>
                          ),
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-center mt-4 widget-hover-icon">
                  <div
                    className="pxy-1 flex items-center justify-center radius-1 delete-widget cursor"
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteWidget(widget, panelId);
                    }}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Draggable>
        {((selectedWidget?.key && selectedWidget.key === widget.key) ||
          (selectedWidget?.id && selectedWidget?.id === widget.id)) && (
          <div className="flex items-center justify-center relative add-widget my-1">
            <div className="flex items-center justify-center plus divider-border mx-1">
              <PlusIcon className="cursor" onClick={() => onAddNewWidget(panelId, index + 1)} />
            </div>
            <div className="w-full absolute line divider-top" />
          </div>
        )}
      </Fragment>
    );
  };

  const renderWatermark = (panel, panelIndex, panelConfig) => {
    const panelConfigWithoutConfirmationPanel = panelConfig.filter(p => !p.is_confirmation_panel);
    if (panel.is_confirmation_panel && !confirmation_panel_watermark) {
      return;
    }
    if (watermark_location === 'FIRSTPAGE' && panelIndex !== 0 && !panel.is_confirmation_panel) {
      return;
    }
    if (
      watermark_location === 'LASTPAGE' &&
      panelConfigWithoutConfirmationPanel.length - 1 !== panelIndex &&
      !panel.is_confirmation_panel
    ) {
      return;
    }
    if (!image?.image?.url) {
      return;
    }
    return (
      <div
        className={`flex mt-4 ${
          alignment === 'LEFT'
            ? 'items-start justify-start'
            : alignment === 'RIGHT'
            ? 'items-start justify-end'
            : 'items-end justify-center'
        }`}>
        <img height={64} width={192} src={image?.image?.url} alt="watermark" className="watermark" />
      </div>
    );
  };

  return (
    <div className="flex-column w-auto h-auto flex-1 form-content-wrapper overflow-scroll">
      {panelConfig.map((panel, index) => (
        <Fragment>
          <Droppable droppableId={`middle-panel:${panel.key || panel.id}`}>
            {provided => (
              <div
                id={`middle-panel:${panel.key || panel.id}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`flex-column form-content radius-6 flex-1 mb-10 ${
                  getPanelWidgetList(panel)?.length > 0 ? 'fit-content' : 'droppable-min-height'
                } `}
                style={{
                  backgroundColor: formAttributes.color.page,
                  width: `${formAttributes.panel_width}px`,
                }}>
                {getPanelWidgetList(panel)?.map((widget, index) => (
                  <>{formWidgets(widget, index, panel.key || panel.id)}</>
                ))}
                {provided.placeholder}
                {display_watermark && renderWatermark(panel, index, panelConfig)}
              </div>
            )}
          </Droppable>
        </Fragment>
      ))}
    </div>
  );
};

const MiddlePanel = () => {
  const { formAttributes } = useContext(FormBuilderContext);

  return (
    <MiddlePanelWrapper
      className="flex-column flex-1 overflow-scroll"
      backgroundColor={formAttributes.background.color}>
      <PagePanel />
      <WidgetPanel />
    </MiddlePanelWrapper>
  );
};

export default MiddlePanel;
