import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const UserActivated = ({ log = {} }) => {
  const performedBy = getPerformedByFromLog(log);
  const {
    event_data: { user },
  } = log;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'User activated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('ACTIVATED')}</span>
            <span className="medium-text lighter-text font-12 ml-1">{user?.role?.name}</span>
            <span className="regular-text lighter-text zen-purple-text font-12 ml-1">
              {user?.badge_number || user?.employee_number || user?.id}
            </span>
          </span>
        }
        showDownIcon={false}
      />
    </div>
  );
};

export default UserActivated;
