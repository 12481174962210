import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/download-icon.svg';
import { ReactComponent as DownloadImage } from '../../../../../assets/images/download-image.svg';
import { OrganisationContext } from '../../../../../context/organisationContext';
import {
  PrizeDrawEntrantDownloadForm,
  prizeDrawEntrantEntryForm,
  setPrizeDrawEntrantEntryForm,
} from '../../../../../store/features/prizeDrawSlice';
import { addToast } from '../../../../../store/features/toastSlice';
import Loader from '../../../../common/loader';
import Menu from '../../../../common/menu';
import Status from '../../../../common/status';

const EntryForm = () => {
  const theme = useTheme();
  const { prizeDrawEntrantEntryFormDetails, prizeDrawEntrantDetails } = useSelector(state => state.prizeDraw);
  const { form, submission_pdf } = prizeDrawEntrantEntryFormDetails || {};
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = useTranslation();
  const { id, entrant_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useContext(OrganisationContext);
  const hasDownloadPermission = hasPermission('PII_VIEW');

  const handleDownload = async () => {
    if (hasDownloadPermission) {
      setIsDownloading(true);
      try {
        dispatch(PrizeDrawEntrantDownloadForm(entrant_id))
          .then(async data => {
            try {
              const response = await fetch(data?.url);
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);

              const link = document.createElement('a');
              link.href = url;
              link.download = `Entrant-EntryForm-${entrant_id}.pdf`;
              link.click();

              URL.revokeObjectURL(url);
              setIsDownloading(false);
              dispatch(addToast({ error: false, text: 'File has been downloaded' }));
            } catch (error) {
              dispatch(addToast({ error: true, text: 'File has not been downloaded' }));
              setIsDownloading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch(addToast({ error: true, text: "You don't have permission to download the file" }));
    }
  };

  useEffect(() => {
    dispatch(prizeDrawEntrantEntryForm(entrant_id)).catch(() => {
      dispatch(setPrizeDrawEntrantEntryForm({}));
    });
  }, []);

  const onFormDetails = () => {
    // if (form?.id && form?.version?.id && hasPermission('FORMS_VIEW')) {
    //   navigate(`/prize-draws/all/${id}/form-details/${form.id}/form-version/${form?.version?.id}`);
    // }
    if (form?.id && form?.version?.id && hasPermission('FORMS_VIEW')) {
      navigate(`/prize-draws/all/${id}/form-details/${form.id}/share-options`);
    }
  };

  return prizeDrawEntrantEntryFormDetails?.form?.id ? (
    <div className="px-6 pt-6 flex flex-column">
      <label className="medium-text font-16 lighter-text divider-bottom w-full flex pb-2">{t('ENTRY_FORM')}</label>
      <div className="border radius-4 py-3 px-4 mt-4 flex-row justify-between items-center">
        <div className="flex-column">
          <div className="flex-row gap-2 mb-1 items-center">
            <label className="regular-text font-14 fw-400">{form?.name}</label>
            <label className="semibold-text font-14">V{form?.version?.version_no}</label>
            <Status
              dotColor={form?.form_type?.is_custom ? theme.colors.ZenBlue : theme.colors.ZenOrange}
              withDot
              withDottedBorder
              status={'FORM_TYPE_LABEL'}
              statusText={form?.form_type?.name}
            />
          </div>
          <div className="flex-row gap-2 items-center">
            <label className="regular-text lighter-text font-12">
              Total entries {prizeDrawEntrantDetails?.entries}x
            </label>
            <label className="dot-3-lighter-text"></label>
            <label className="regular-text lighter-text font-12">Client is {form?.client?.name}</label>
            <label className="dot-3-lighter-text"></label>
            <label className="regular-text lighter-text font-12">
              Client draw code {form?.client?.client_draw_code}
            </label>
          </div>
        </div>
        {hasPermission('FORMS_VIEW') && (
          <Menu menuList={[{ name: 'Details', onClick: () => onFormDetails(), permission: 'FORMS_VIEW' }]} />
        )}
      </div>
      {submission_pdf && (
        <div className="flex border radius-4 mt-4 pxy-4 download-container justify-between items-center">
          <div className="flex-row gap-2 items-center">
            <DownloadImage />
            <label className="flex-1 regular-text grey-text font-12 mr-13">
              Downloading the completed entry form can serve as compelling evidence in potential disputes. For security
              purposes, it is advised to promptly delete the file after its use to ensure confidentiality and data
              protection.
            </label>
          </div>
          <div
            className={`flex zen-purple-text download-button gap-2 items-center radius-3 ${
              hasDownloadPermission ? 'cursor' : 'cursor-not-allowed'
            }`}
            onClick={handleDownload}>
            {!isDownloading ? <DownloadIcon /> : <Loader height={16} width={16} />}
            <label className="regular-text font-14 zen-purple-text flex items-center">{t('DOWNLOAD')}</label>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Fragment />
  );
};
export default EntryForm;
