import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import {
  monthOptions,
  recurrenceIntervalOptions,
  timeMeridiemOptions,
  weekDayOptions,
  weekOfMonthOptions,
} from '../../constant/optionData';
import { OrganisationContext } from '../../context/organisationContext';
import {
  getPrizeDrawConfig,
  getTimezones,
  setPrizeDrawConfig,
  updatePrizeDrawConfig,
} from '../../store/features/prizeDrawSlice';
import { PrizeDrawConfigWrapper } from '../../styles/components/prize-draw-detail/prize-draw-configuration.styled';
import DropDown from '../McDropdown';
import Switch from '../common/switch';
import UpdateAction from '../common/update-action';
import DateSelectorElement from '../widget/date-selector-element/date-selector-element';
import PrizeDrawDetailHeader from './prize-draw-details-header';

const PrizeDrawConfiguration = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { prizeDrawConfig } = useSelector(state => state.prizeDraw);

  const [isRecurresive, setIsRecurresive] = useState(prizeDrawConfig?.is_recurring);
  const [updateConfig, setUpdateConfig] = useState({ type: '', value: '' });
  const [timezoneData, setTimezoneData] = useState([]);
  const { hasPermission } = useContext(OrganisationContext);

  useEffect(() => {
    dispatch(getPrizeDrawConfig({ prizedrawId: id }))
      .then(data => {
        setIsRecurresive(data.is_recurring);
      })
      .catch(error => {
        setIsRecurresive(false);
      });
    dispatch(getTimezones())
      .then(data => {
        const newData = data.map(d => ({ ...d, name: `${d.name} (${d.abbreviation}) UTC ${d.current_offset}` }));
        setTimezoneData(newData);
      })
      .catch(error => {
        setTimezoneData([]);
      });
  }, [id]);

  const getDateInFormat = (date = null, time = null, timeOption = null) => {
    const timeFormat = time?.split(':');
    const hours =
      timeOption === 'PM'
        ? timeFormat?.[0] === '12'
          ? timeFormat?.[0]
          : parseInt(timeFormat?.[0], 10) + 12
        : timeFormat?.[0] === '12'
        ? '00'
        : timeFormat?.[0];
    if (date) {
      return moment.unix(date).set({ hour: hours, minute: timeFormat?.[1] }).unix();
    } else {
      return moment().set({ hour: hours, minute: timeFormat?.[1] }).unix();
    }
  };

  const getTimeOfDrawFormat = (time = null, timeOption = null) => {
    const timeFormat = time?.split(':');
    let hours =
      timeOption === 'PM'
        ? timeFormat?.[0] === '12'
          ? timeFormat?.[0]
          : parseInt(timeFormat?.[0], 10) + 12
        : timeFormat?.[0] === '12'
        ? '00'
        : timeFormat?.[0];
    hours = parseInt(hours, 10);
    const minutes = parseInt(timeFormat?.[1], 10);
    return [hours > 24 ? 12 : hours, minutes > 59 ? 59 : minutes];
  };

  const onUpdateConfig = (field, value) => {
    const previousConfig = { ...prizeDrawConfig };
    const recurring_config_request = field === 'is_recurring' && value ? null : { ...previousConfig.recurring_config };
    const request = {
      ...previousConfig,
      is_recurring: isRecurresive,
      recurring_config: isRecurresive ? recurring_config_request : null,
      [field]: value,
    };
    dispatch(setPrizeDrawConfig(request));
    setUpdateConfig({});
    dispatch(updatePrizeDrawConfig({ prizedrawId: id, request })).catch(() => {});
  };

  const onUpdateRecurranceConfig = (field, value) => {
    const previousConfig = { ...prizeDrawConfig };
    const request = {
      ...previousConfig,
      is_recurring: isRecurresive,
      recurring_config: isRecurresive
        ? {
            ...previousConfig.recurring_config,
            [field]: value,
            intervals: value === 'DAILY' ? 1 : previousConfig.recurring_config?.intervals || null,
            day_of_week: value === 'DAILY' ? null : previousConfig.recurring_config?.day_of_week || null,
            week_of_month: value === 'DAILY' ? null : previousConfig.recurring_config?.week_of_month || null,
            month: value === 'DAILY' ? null : previousConfig.recurring_config?.month || null,
            day_of_month: value === 'DAILY' ? null : previousConfig.recurring_config?.day_of_month || null,
          }
        : null,
    };
    dispatch(setPrizeDrawConfig(request));
    if (field === 'interval_type' && value !== 'DAILY') {
      setUpdateConfig({
        type: 'RECURRENCE_PATTERN',
        optionSelected: !previousConfig?.recurring_config?.day_of_month ? 1 : 2,
        ...previousConfig?.recurring_config,
      });
    } else {
      setUpdateConfig({});
    }
    if (field !== 'interval_type' || (field === 'interval_type' && value === 'DAILY')) {
      dispatch(updatePrizeDrawConfig({ prizedrawId: id, request })).catch(() => {
        // dispatch(setPrizeDrawConfig(previousConfig));
      });
    }
  };

  const onUpdateRecurrencePattern = () => {
    const previousConfig = { ...prizeDrawConfig };
    let request = { ...prizeDrawConfig, is_recurring: true };
    if (prizeDrawConfig?.recurring_config?.interval_type === 'WEEKLY') {
      request = {
        ...request,
        recurring_config: {
          ...request.recurring_config,
          intervals: updateConfig?.intervals,
          day_of_week: updateConfig?.day_of_week,
          week_of_month: null,
          month: null,
          day_of_month: null,
        },
      };
    } else if (prizeDrawConfig?.recurring_config?.interval_type === 'MONTHLY') {
      request = {
        ...request,
        recurring_config: {
          ...request.recurring_config,
          intervals: updateConfig?.intervals,
          day_of_week: updateConfig?.day_of_week,
          week_of_month: updateConfig?.week_of_month,
          month: null,
          day_of_month: null,
        },
      };
    } else {
      if (updateConfig?.optionSelected === 1) {
        request = {
          ...request,
          recurring_config: {
            ...request.recurring_config,
            intervals: updateConfig?.intervals,
            day_of_week: updateConfig?.day_of_week,
            week_of_month: updateConfig?.week_of_month,
            month: updateConfig?.month,
            day_of_month: null,
          },
        };
      } else {
        request = {
          ...request,
          recurring_config: {
            ...request.recurring_config,
            intervals: updateConfig?.intervals,
            day_of_week: null,
            week_of_month: null,
            month: updateConfig?.month,
            day_of_month: updateConfig?.day_of_month,
          },
        };
      }
    }
    dispatch(setPrizeDrawConfig(request));
    setUpdateConfig({});
    dispatch(updatePrizeDrawConfig({ prizedrawId: id, request })).catch(() => {
      // dispatch(setPrizeDrawConfig(previousConfig));
    });
  };

  const timeInput = () => {
    return (
      <div className="flex col-gap-1 date-time-input">
        <InputMask
          className="input regular-text font-14 time-input"
          mask={'99:99'}
          maskChar={null}
          onChange={e => setUpdateConfig({ ...updateConfig, time: e.target.value })}
          placeholder={'00:00'}
          value={updateConfig?.time || null}
        />
        <DropDown
          options={timeMeridiemOptions}
          selected={updateConfig?.timeOption || null}
          setSelected={o => {
            setUpdateConfig({ ...updateConfig, timeOption: o });
          }}
          placeholder="Select"
          className="w-full"
        />
      </div>
    );
  };

  const dateInput = () => {
    return (
      <div className="flex col-gap-1 date-time-input selector-dropdown">
        <DateSelectorElement
          placeholder="MM/DD/YYYY"
          selectedDate={updateConfig?.date || null}
          setSelectedDate={d => setUpdateConfig({ ...updateConfig, date: d })}
          format={'MM/DD/YYYY'}
          showDropdownArrow={false}
        />
        <div>{timeInput()}</div>
      </div>
    );
  };

  const weeklyInput = () => {
    return (
      <div className="flex col-gap-1 selector-dropdown weekly-input">
        <span className="regular-text mr-1">Every</span>
        <input
          className="input font-14 mr-1"
          value={updateConfig?.intervals || null}
          placeholder="1"
          onChange={e => setUpdateConfig({ ...updateConfig, intervals: e.target.value })}
        />
        <span className="regular-text mr-1">{`week(s) on`}</span>
        <DropDown
          options={weekDayOptions}
          selected={weekDayOptions.find(o => o.value === updateConfig?.day_of_week)}
          setSelected={o => {
            setUpdateConfig({ ...updateConfig, day_of_week: o.value });
          }}
          placeholder="Select"
        />
      </div>
    );
  };

  const monthlyInput = () => {
    return (
      <div className="flex items-center col-gap-1 selector-dropdown weekly-input">
        <span className="regular-text mr-1">The</span>
        <DropDown
          options={weekOfMonthOptions}
          selected={weekOfMonthOptions.find(o => o.value === updateConfig?.week_of_month)}
          setSelected={o => {
            setUpdateConfig({ ...updateConfig, week_of_month: o.value });
          }}
          placeholder="Select"
          className="mr-1 sm-selector"
        />
        <DropDown
          options={weekDayOptions}
          selected={weekDayOptions.find(o => o.value === updateConfig?.day_of_week)}
          setSelected={o => {
            setUpdateConfig({ ...updateConfig, day_of_week: o.value });
          }}
          placeholder="Select"
          className="mr-1"
        />
        <span className="regular-text mr-1">{`of every`}</span>
        <input
          className="input font-14 mr-1"
          value={updateConfig?.intervals || null}
          placeholder="1"
          onChange={e => setUpdateConfig({ ...updateConfig, intervals: e.target.value })}
        />
        <span className="regular-text mx-1">{`months`}</span>
      </div>
    );
  };

  const yearlyInput = () => {
    return (
      <div>
        <div className="flex">
          {/* radio */}
          <input
            value={1}
            checked={updateConfig?.optionSelected === 1}
            onChange={e => setUpdateConfig({ ...updateConfig, optionSelected: 1 })}
            type="radio"
            className={`radio-input ${updateConfig?.optionSelected === 1 && 'selected-radio'}`}
          />
          <div className="flex fw-w ml-2 items-center col-gap-1 selector-dropdown weekly-input">
            <span className="regular-text mr-1">On the</span>
            <DropDown
              options={weekOfMonthOptions}
              selected={weekOfMonthOptions.find(o => o.value === updateConfig?.week_of_month)}
              setSelected={o => {
                setUpdateConfig({ ...updateConfig, week_of_month: o.value });
              }}
              placeholder="Select"
              className="mr-1 sm-selector"
            />
            <DropDown
              options={weekDayOptions}
              selected={weekDayOptions.find(o => o.value === updateConfig?.day_of_week)}
              setSelected={o => {
                setUpdateConfig({ ...updateConfig, day_of_week: o.value });
              }}
              placeholder="Select"
              className="mr-1"
            />
            <span className="regular-text mr-1">{`of`}</span>
            <DropDown
              options={monthOptions}
              selected={monthOptions.find(o => o.value === updateConfig?.month)}
              setSelected={o => {
                setUpdateConfig({ ...updateConfig, month: o.value });
              }}
              placeholder="Select"
              className="mr-1"
            />
            <span className="regular-text mr-1">{`for every`}</span>
            <input
              className="input font-14 mr-1"
              value={updateConfig?.intervals || null}
              placeholder="1"
              onChange={e => setUpdateConfig({ ...updateConfig, intervals: e.target.value })}
            />
            <span className="regular-text">{`year(s)`}</span>
          </div>
        </div>
        <div className="flex mt-4">
          {/* radio */}
          <input
            value={2}
            checked={updateConfig?.optionSelected === 2}
            onChange={() => setUpdateConfig({ ...updateConfig, optionSelected: 2 })}
            type="radio"
            className={`radio-input ${updateConfig?.optionSelected === 2 && 'selected-radio'}`}
          />
          <div className="flex fw-w ml-2 items-center col-gap-1 selector-dropdown weekly-input">
            <span className="regular-text mr-1">On</span>
            <DropDown
              options={monthOptions}
              selected={monthOptions.find(o => o.value === updateConfig?.month)}
              setSelected={o => {
                setUpdateConfig({ ...updateConfig, month: o.value });
              }}
              placeholder="Select"
              className="mr-1 sm-selector"
            />
            <input
              className="input font-14 mr-1"
              value={updateConfig?.day_of_month || null}
              placeholder="1"
              onChange={e => setUpdateConfig({ ...updateConfig, day_of_month: e.target.value })}
            />
            <span className="regular-text mr-1">{`for every`}</span>
            <input
              className="input font-14 mr-1"
              value={updateConfig?.intervals || null}
              placeholder="1"
              onChange={e => setUpdateConfig({ ...updateConfig, intervals: e.target.value })}
            />
            <span className="regular-text">{`year(s)`}</span>
          </div>
        </div>
      </div>
    );
  };

  const endOfEntryInput = () => {
    return (
      <div className="flex col-gap-1 selector-dropdown weekly-input">
        <input
          className="input font-14 mr-1"
          value={updateConfig?.end_of_entry_days || null}
          placeholder="1"
          onChange={e => setUpdateConfig({ ...updateConfig, end_of_entry_days: e.target.value })}
        />
        <span className="regular-text mr-1">{`day(s) before the draw`}</span>
      </div>
    );
  };

  const intervalDataFormat = config => {
    const { interval_type, intervals, day_of_week, week_of_month, day_of_month, month } = config || {};
    if (!interval_type) {
      return null;
    }
    if (interval_type === 'WEEKLY') {
      return `Every ${intervals || ''} week on ${weekDayOptions.find(a => a.value === day_of_week)?.name || ''}`;
    }
    if (interval_type === 'MONTHLY') {
      return `The ${weekOfMonthOptions.find(a => a.value === week_of_month)?.name || ''} ${
        weekDayOptions.find(a => a.value === day_of_week)?.name || ''
      } of every ${intervals} month`;
    }
    if (interval_type === 'YEARLY') {
      if (day_of_month) {
        return `On ${
          monthOptions.find(a => a.value === month)?.name || ''
        } ${day_of_month} for every ${intervals} years`;
      } else {
        return `On the ${weekOfMonthOptions.find(a => a.value === week_of_month)?.name || ''} ${
          weekDayOptions.find(a => a.value === day_of_week)?.name || ''
        } of ${monthOptions.find(a => a.value === month)?.name || ''} for every ${intervals} years`;
      }
    }
  };

  const getTimevalueInFormat = momentTime => {
    let hours = momentTime.get('hours');
    const minutes = momentTime.get('minutes');
    const timeOption = hours >= 12 ? { name: 'PM', value: 'PM' } : { name: 'AM', value: 'AM' };
    hours = hours > 12 ? hours - 12 : hours;
    hours = hours > 10 ? hours : `0${hours}`;
    return { time: `${hours}:${minutes}`, timeOption };
  };

  const getTimeOfDrawInFormat = timeOfDraw => {
    const [hours, minutes] = timeOfDraw || [];
    if (hours && minutes) {
      const momentTime = moment().set('hours', hours).set('minutes', minutes);
      return getTimevalueInFormat(momentTime);
    }
    return { time: '', timeOption: null };
  };

  return (
    <PrizeDrawConfigWrapper className="flex-column items-start justify-start w-full h-full">
      <PrizeDrawDetailHeader title={t('DRAW_CONFIGURATION')} />
      <div className="w-full flex-1 overflow-scroll px-6">
        <div className="flex justify-between py-6 border-bottom">
          <div className="flex-column nowrap mr-4">
            <span className="medium-text lighter-text">{t('TIME_ZONE')}</span>
            <span className="regular-text font-12 grey-text">{t('TIME_ZONE_PRIZE_DRAW_OPERATE')}</span>
          </div>
          <div className="flex-1 ml-10 flex justify-end">
            {updateConfig.type === 'TIME_ZONE' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
              <div className="update-detail-contaner timezone-dropdown relative flex-1">
                <DropDown
                  options={timezoneData}
                  selected={timezoneData.find(t => t.id === updateConfig.timezone?.id)}
                  setSelected={o => {
                    setUpdateConfig({ ...updateConfig, timezone: o });
                  }}
                  placeholder="Select timezone"
                  className="w-full draw-config-dropdown"
                />
                <UpdateAction
                  onCancel={() => setUpdateConfig({})}
                  onUpdate={() => onUpdateConfig('timezone', updateConfig.timezone || null)}
                />
              </div>
            ) : (
              <div
                className={`flex items-center py-1 px-2 cursor ${
                  hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                } w-fit-content justify-end`}
                onClick={() =>
                  setUpdateConfig({
                    type: 'TIME_ZONE',
                    timezone: prizeDrawConfig?.timezone,
                  })
                }>
                {prizeDrawConfig?.timezone?.id ? (
                  <span className="normal-text font-14 flex-1">
                    {timezoneData.find(t => t.id === prizeDrawConfig?.timezone?.id)?.name}
                  </span>
                ) : (
                  <span className="normal-text font-14 flex-1 grey-text">{'no data'}</span>
                )}
                {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                  <EditIcon width={16} height={16} className="edit-icon" />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between py-6 border-bottom">
          <div className="flex-column nowrap mr-4">
            <span className="medium-text lighter-text">{t('START_DATE_TIME')}</span>
            <span className="regular-text font-12 grey-text">{t('PRIZE_DRAW_START_DATE_TIME')}</span>
          </div>
          <div>
            {updateConfig.type === 'START_DATE_TIME' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
              <div className="update-detail-contaner relative">
                {dateInput()}
                <UpdateAction
                  onCancel={() => setUpdateConfig({})}
                  onUpdate={() =>
                    onUpdateConfig(
                      'start',
                      getDateInFormat(updateConfig?.date, updateConfig?.time, updateConfig?.timeOption?.value),
                    )
                  }
                />
              </div>
            ) : (
              <div
                className={`flex items-center py-1 px-2 cursor ${
                  hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                }`}
                onClick={() =>
                  setUpdateConfig({
                    type: 'START_DATE_TIME',
                    date: prizeDrawConfig?.start > 1 ? prizeDrawConfig?.start : '',
                    ...(prizeDrawConfig?.start > 1 ? getTimevalueInFormat(moment.unix(prizeDrawConfig?.start)) : {}),
                  })
                }>
                {prizeDrawConfig?.start > 1 ? (
                  <span className="normal-text font-14 flex-1">
                    {moment.unix(prizeDrawConfig.start).format('dddd MM/DD/YYYY hh:mm A')}
                  </span>
                ) : (
                  <span className="normal-text font-14 flex-1 grey-text">{'no data'}</span>
                )}
                {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                  <EditIcon width={16} height={16} className="edit-icon" />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between py-6 border-bottom">
          <div className="flex-column nowrap mr-4">
            <span className="medium-text lighter-text">{t('ENTRANCE_PARAMETERS')}</span>
            <span className="regular-text font-12 grey-text">{t('DATE_ENTRANTS_MUST_ENTER_BY')}</span>
          </div>
          <div>
            {updateConfig.type === 'ENTRANCE_PARAMETERS' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
              <div className="update-detail-contaner relative">
                {dateInput()}
                <UpdateAction
                  onCancel={() => setUpdateConfig({})}
                  onUpdate={() =>
                    onUpdateConfig(
                      'last_entrance_date',
                      getDateInFormat(updateConfig?.date, updateConfig?.time, updateConfig?.timeOption?.value),
                    )
                  }
                />
              </div>
            ) : (
              <div
                className={`flex items-center py-1 px-2 cursor ${
                  hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                }`}
                onClick={() =>
                  setUpdateConfig({
                    type: 'ENTRANCE_PARAMETERS',
                    date: prizeDrawConfig?.last_entrance_date > 1 ? prizeDrawConfig?.last_entrance_date : '',
                    ...(prizeDrawConfig?.last_entrance_date > 1
                      ? getTimevalueInFormat(moment.unix(prizeDrawConfig?.last_entrance_date))
                      : {}),
                  })
                }>
                {prizeDrawConfig?.last_entrance_date > 1 ? (
                  <span className="normal-text font-14 flex-1">
                    {moment.unix(prizeDrawConfig.last_entrance_date).format('dddd MM/DD/YYYY hh:mm A')}
                  </span>
                ) : (
                  <span className="normal-text font-14 flex-1 grey-text">{'no data'}</span>
                )}
                {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                  <EditIcon width={16} height={16} className="edit-icon" />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between py-6 border-bottom">
          <div className="flex-column nowrap mr-4">
            <span className="medium-text lighter-text">{t('WINNER_DATE_GENERATION')}</span>
            <span className="regular-text font-12 grey-text">{t('DATE_WINNERS_WILL_GENERATE')}</span>
          </div>
          <div>
            {updateConfig.type === 'WINNER_DATE_GENERATION' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
              <div className="update-detail-contaner relative">
                {dateInput()}
                <UpdateAction
                  onCancel={() => setUpdateConfig({})}
                  onUpdate={() =>
                    onUpdateConfig(
                      'draw_date',
                      getDateInFormat(updateConfig?.date, updateConfig?.time, updateConfig?.timeOption?.value),
                    )
                  }
                />
              </div>
            ) : (
              <div
                className={`flex items-center py-1 px-2 cursor ${
                  hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                }`}
                onClick={() =>
                  setUpdateConfig({
                    type: 'WINNER_DATE_GENERATION',
                    date: prizeDrawConfig?.draw_date > 1 ? prizeDrawConfig?.draw_date : '',
                    ...(prizeDrawConfig?.draw_date > 1
                      ? getTimevalueInFormat(moment.unix(prizeDrawConfig?.draw_date))
                      : {}),
                  })
                }>
                {prizeDrawConfig?.draw_date > 1 ? (
                  <span className="normal-text font-14 flex-1">
                    {moment.unix(prizeDrawConfig.draw_date).format('dddd MM/DD/YYYY hh:mm A')}
                  </span>
                ) : (
                  <span className="normal-text font-14 flex-1 grey-text">{'no data'}</span>
                )}
                {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                  <EditIcon width={16} height={16} className="edit-icon" />
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between py-6 border-bottom">
          <div className="flex-column flex-1">
            <span className="medium-text lighter-text">{t('MAKE_RECURRING')}</span>
            <span className="regular-text font-12 grey-text">{t('GENERATE_WINNERS_CONTINUOUSLY')}</span>
          </div>
          <Switch
            enabled={isRecurresive}
            onClick={() => {
              setIsRecurresive(!isRecurresive);
              onUpdateConfig('is_recurring', !isRecurresive);
            }}
            permission="PRIZEDRAWS_DRAWCONFIG_MANAGE"
            disabled={false}
          />
        </div>
        {isRecurresive && (
          <>
            <div className="flex justify-between py-6 border-bottom">
              <div className="flex-column nowrap mr-4">
                <span className="medium-text lighter-text">{t('RECURRENCE_INTERVAL')}</span>
                <span className="regular-text font-12 grey-text">{t('PRIZE_DRAW_RECURRENCE')}</span>
              </div>
              <div>
                {updateConfig.type === 'RECURRENCE_INTERVAL' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
                  <div className="update-detail-contaner selector-dropdown relative">
                    <DropDown
                      options={recurrenceIntervalOptions}
                      selected={recurrenceIntervalOptions.find(o => o.value === updateConfig?.interval_type)}
                      setSelected={o => {
                        setUpdateConfig({ ...updateConfig, interval_type: o.value });
                      }}
                      placeholder="Select interval"
                      className="w-full"
                    />
                    <UpdateAction
                      onCancel={() => setUpdateConfig({})}
                      onUpdate={() => onUpdateRecurranceConfig('interval_type', updateConfig?.interval_type || null)}
                    />
                  </div>
                ) : (
                  <div
                    className={`flex items-center py-1 px-2 cursor ${
                      hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                    }`}
                    onClick={() =>
                      setUpdateConfig({
                        type: 'RECURRENCE_INTERVAL',
                        interval_type: prizeDrawConfig?.recurring_config?.interval_type,
                      })
                    }>
                    {prizeDrawConfig?.recurring_config?.interval_type ? (
                      <span className="normal-text font-14 flex-1">
                        {recurrenceIntervalOptions.find(
                          a => a.value === prizeDrawConfig?.recurring_config?.interval_type,
                        )?.name || ''}
                      </span>
                    ) : (
                      <span className="normal-text font-14 flex-1 grey-text">{'no data'}</span>
                    )}
                    {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                      <EditIcon width={16} height={16} className="edit-icon" />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between py-6 border-bottom">
              <div className="flex-column nowrap mr-4">
                <span className="medium-text lighter-text">{t('RECURRENCE_PATTERN')}</span>
                <span className="regular-text font-12 grey-text">{t('RECURRING_DRAW_SETTINGS')}</span>
              </div>

              <div>
                {updateConfig.type === 'RECURRENCE_PATTERN' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
                  <div className="update-detail-contaner relative">
                    {prizeDrawConfig?.recurring_config?.interval_type === 'WEEKLY'
                      ? weeklyInput()
                      : prizeDrawConfig?.recurring_config?.interval_type === 'MONTHLY'
                      ? monthlyInput()
                      : yearlyInput()}
                    <UpdateAction onCancel={() => setUpdateConfig({})} onUpdate={() => onUpdateRecurrencePattern()} />
                  </div>
                ) : (
                  <div
                    className={`flex items-center py-1 px-2 cursor ${
                      prizeDrawConfig?.recurring_config?.interval_type !== 'DAILY' &&
                      hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') &&
                      'hover-edit'
                    }`}
                    onClick={() =>
                      prizeDrawConfig?.recurring_config?.interval_type !== 'DAILY' &&
                      setUpdateConfig({
                        type: 'RECURRENCE_PATTERN',
                        optionSelected: !prizeDrawConfig?.recurring_config?.day_of_month ? 1 : 2,
                        ...prizeDrawConfig?.recurring_config,
                      })
                    }>
                    {intervalDataFormat(prizeDrawConfig?.recurring_config) ? (
                      <span className="normal-text font-14 flex-1">
                        {intervalDataFormat(prizeDrawConfig?.recurring_config)}
                      </span>
                    ) : (
                      <span className="normal-text font-14 flex-1 grey-text">{t('NO_DATA')}</span>
                    )}
                    {prizeDrawConfig?.recurring_config?.interval_type !== 'DAILY' &&
                      hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                        <EditIcon width={16} height={16} className="edit-icon" />
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between py-6 border-bottom">
              <div className="flex-column nowrap mr-4">
                <span className="medium-text lighter-text">{t('TIME_OF_DRAW')}</span>
                <span className="regular-text font-12 grey-text">{t('TIME_OF_PRIZE_DRAW')}</span>
              </div>
              <div>
                {updateConfig.type === 'TIME_OF_DRAW' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
                  <div className="update-detail-contaner date-time-input relative">
                    {timeInput()}
                    <UpdateAction
                      onCancel={() => setUpdateConfig({})}
                      onUpdate={() =>
                        onUpdateRecurranceConfig(
                          'time_of_draw',
                          getTimeOfDrawFormat(updateConfig?.time, updateConfig?.timeOption?.value),
                        )
                      }
                    />
                  </div>
                ) : (
                  <div
                    className={`flex items-center py-1 px-2 cursor ${
                      hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                    }`}
                    onClick={() =>
                      setUpdateConfig({
                        type: 'TIME_OF_DRAW',
                        ...(prizeDrawConfig?.recurring_config?.time_of_draw
                          ? getTimeOfDrawInFormat(prizeDrawConfig?.recurring_config?.time_of_draw)
                          : {}),
                      })
                    }>
                    {prizeDrawConfig?.recurring_config?.time_of_draw ? (
                      <span className="normal-text font-14 flex-1">
                        {moment()
                          .set('hour', prizeDrawConfig?.recurring_config?.time_of_draw?.[0])
                          .set('minutes', prizeDrawConfig?.recurring_config?.time_of_draw?.[1])
                          .format('hh:mm A') || ''}
                      </span>
                    ) : (
                      <span className="normal-text font-14 flex-1 grey-text">{t('NO_DATA')}</span>
                    )}
                    {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                      <EditIcon width={16} height={16} className="edit-icon" />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between py-6">
              <div className="flex-column nowrap mr-4">
                <span className="medium-text lighter-text">{t('END_OF_ENTRY_PERIOD')}</span>
                <span className="regular-text font-12 grey-text">{t('FINAL_ENTRIES_DATE')}</span>
              </div>
              <div>
                {updateConfig.type === 'END_OF_ENTRY_PERIOD' && hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? (
                  <div className="update-detail-contaner date-time-input relative">
                    {endOfEntryInput()}
                    <UpdateAction
                      onCancel={() => setUpdateConfig({})}
                      onUpdate={() =>
                        onUpdateRecurranceConfig('end_of_entry_days', updateConfig?.end_of_entry_days || null)
                      }
                    />
                  </div>
                ) : (
                  <div
                    className={`flex items-center py-1 px-2 cursor ${
                      hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') ? 'hover-edit ' : ''
                    }`}
                    onClick={() =>
                      setUpdateConfig({
                        type: 'END_OF_ENTRY_PERIOD',
                        end_of_entry_days: prizeDrawConfig?.recurring_config?.end_of_entry_days || '',
                      })
                    }>
                    {prizeDrawConfig?.recurring_config?.end_of_entry_days ? (
                      <span className="normal-text font-14 flex-1">
                        {`${prizeDrawConfig?.recurring_config?.end_of_entry_days} day${
                          prizeDrawConfig?.recurring_config?.end_of_entry_days > 1 ? 's' : ''
                        } before the draw` || ''}
                      </span>
                    ) : (
                      <span className="normal-text font-14 flex-1 grey-text">{t('NO_DATA')}</span>
                    )}
                    {hasPermission('PRIZEDRAWS_DRAWCONFIG_MANAGE') && (
                      <EditIcon width={16} height={16} className="edit-icon" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </PrizeDrawConfigWrapper>
  );
};

export default PrizeDrawConfiguration;
