import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const GroupDeleted = ({ log = {} }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(true);
  const {
    event_data: { organization_category },
    performed_by,
  } = log;
  const performedBy = getPerformedByFromLog(log);
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Group deleted'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('DELETED_GROUP')}</span>
          </span>
        }
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
      />
      {showDetails && (
        <LogContentBox
          title={'Group'}
          contentText={<p className="regular-text lighter-text font-12">{organization_category?.name || ''}</p>}
        />
      )}
    </div>
  );
};

export default GroupDeleted;
