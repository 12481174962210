import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as UpIcon } from '../../../assets/images/sorting.svg';
import Loader from '../../../components/common/loader';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalize, formatText, getCurrencySymbol, getPaymentFrequencyText, getStatus } from '../../../helpers/utils';
import { FeesDetailsWrapper } from '../../../styles/components/fees/fees.styled';
import Menu from '../../common/menu';
import NoDataComponent from '../../common/no-data-component';
import NoResultComponent from '../../common/no-result-component';
import Status from '../../common/status';

const renderSortableHeader = (label, sortKey, sortedBy, orderBy, handleSorting, showBorderRight = true) => {
  const handleClick = () => {
    handleSorting(sortKey);
  };

  return (
    <div className={`flex w-full items-center px-2 py-1 overflow-hidden my-2 ${showBorderRight && 'border-right'}`}>
      <label className="flex cursor medium-text font-12 lighter-text gap-6 flex-1 justify-center" onClick={handleClick}>
        {label}
        {sortedBy === sortKey && orderBy && (
          <UpIcon
            className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
            height={16}
            width={16}
          />
        )}
      </label>
    </div>
  );
};

const feesDetails = ({ setOrderBy, setSortedBy, sortedBy, orderBy, loading = false, search = '' }) => {
  const { feesList } = useSelector(state => state.billing);

  const { t } = useTranslation();
  const pageRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkPermission } = useContext(OrganisationContext);

  const sortedEnum = {
    productName: 'PRODUCT_NAME',
    productAmount: 'PRODUCT_AMOUNT',
    feeAmount: 'FEE_AMOUNT',
    client: 'CLIENT',
    feeStatus: 'FEE_STATUS',
    reccurringPeriod: 'RECURRING_PERIOD',
    productId: 'PRODUCT_ID',
  };

  const handleSorting = fieldName => {
    if (!sortedBy) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    } else if (sortedBy === fieldName) {
      if (!orderBy) {
        setOrderBy('DESC');
      } else if (orderBy === 'DESC') {
        setOrderBy('ASC');
      } else if (orderBy === 'ASC') {
        setOrderBy('');
        setSortedBy('');
      }
    } else if (sortedBy !== fieldName) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    }
  };

  const handleFeesDetails = data => {
    navigate(`/billings/fees/${data.id}`);
  };

  if (loading) {
    return <Loader height={64} width={64} />;
  }

  return (
    <FeesDetailsWrapper className="flex-column flex-1">
      {feesList?.content?.length > 0 ? (
        <>
          <div className="w-full content-header content-grid">
            {renderSortableHeader('Id', sortedEnum.productId, sortedBy, orderBy, handleSorting)}
            {renderSortableHeader('Product name', sortedEnum.productName, sortedBy, orderBy, handleSorting)}
            {renderSortableHeader('Amount', sortedEnum.productAmount, sortedBy, orderBy, handleSorting)}
            {renderSortableHeader('Frequency', sortedEnum.reccurringPeriod, sortedBy, orderBy, handleSorting)}
            {renderSortableHeader('Client', sortedEnum.client, sortedBy, orderBy, handleSorting)}
            {renderSortableHeader('Fee', sortedEnum.feeAmount, sortedBy, orderBy, handleSorting)}
            {renderSortableHeader('Status', sortedEnum.feeStatus, sortedBy, orderBy, handleSorting, false)}
            <div></div>
          </div>
          <div className="overflow-scroll flex-1" id="scrollableDiv">
            {feesList?.content?.map((item, index) => (
              <div
                className="w-full content-body border-bottom cursor content-grid"
                key={`${item.id}:${index}`}
                onClick={() => checkPermission(() => handleFeesDetails(item), '')}>
                <div className="flex-column justify-center items-start w-full px-6 border-right">
                  <label className="regular-text font-14">{item?.product?.id}</label>
                </div>
                <div className="flex-column justify-center items-start w-full px-6 border-right">
                  <label className="regular-text font-14 w-full one-line">{item?.product?.name}</label>
                </div>
                <div className="flex-column justify-center items-start w-full px-6 border-right">
                  {item?.pricing?.price && (
                    <label className="regular-text font-14">
                      {getCurrencySymbol(item?.pricing?.currency_code)}
                      {item?.pricing?.price}
                    </label>
                  )}
                </div>

                <div className="flex-column justify-center items-start w-full px-6 border-right">
                  <label className="regular-text font-14">
                    {getPaymentFrequencyText(item?.pricing?.recurring_period)}
                  </label>
                </div>
                <div className="flex-column justify-center items-start w-full px-6 border-right">
                  <label className="regular-text font-14 w-full one-line">{item?.organization?.name}</label>
                </div>
                <div className="flex-column justify-center items-start w-full px-6 border-right">
                  {item?.fee?.fee_amount && (
                    <label className="regular-text font-14">
                      {getCurrencySymbol(item?.pricing?.currency_code)}
                      {item?.fee?.fee_amount}
                    </label>
                  )}
                </div>
                <div className="flex-row justify-center items-center w-full px-6">
                  <label className="regular-text font-14">
                    <Status
                      withDot
                      withDottedBorder
                      status={getStatus(item?.fee?.dates?.start, item?.fee?.dates?.end)}
                      statusText={capitalize(formatText(getStatus(item?.fee?.dates?.start, item?.fee?.dates?.end)))}
                    />
                  </label>
                </div>
                <div className="flex items-center w-full py-1">
                  <Menu menuList={[{ name: t('DETAILS'), onClick: () => handleFeesDetails(item) }]} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : search ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </FeesDetailsWrapper>
  );
};

export default feesDetails;
