import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import IntegrationsDetail from '../../components/integrations-detail';
import PageHeader from '../../components/page-header';
import { getIntegrationDetail } from '../../store/features/integrationsSlice';
import { IntegrationsDetailContainerWrapper } from '../../styles/pages/integrations-detail-container.styled';

const IntegrationsDetailContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { integrationsDetail } = useSelector(state => state.integrations);

  useEffect(() => {
    dispatch(getIntegrationDetail({ id: id }));
  }, []);

  return (
    <IntegrationsDetailContainerWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Platforms', onClick: () => {} },
          { text: 'Integrations', path: '/platforms/integrations' },
          { text: integrationsDetail?.connector?.name, onClick: () => {} },
        ]}
      />
      <IntegrationsDetail />
    </IntegrationsDetailContainerWrapper>
  );
};

export default IntegrationsDetailContainer;
