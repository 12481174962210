import styled from 'styled-components';

export const BillingFeesDetailsWrapper = styled.div``;

export const BillingFeesDetailsLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  max-height: 100%;
  transition: width 400ms linear;

  .prizedraw-info-header {
    min-height: 44px;
    max-height: 44px;
  }

  .empty-img-content {
    max-height: 40px;
    max-width: 40px;
    height: 40px;
    width: 40px;
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
  }
`;

export const BillingFeesDetailsRightWrapper = styled.div`
  .tabs-wrapper {
    max-height: 32px;
    min-height: 32px;
  }

  .billing-type-wrapper {
    max-width: 180px;
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% + 1px) !important;
    }
  }

  .first-tab-container {
    background: ${({ theme }) => theme.colors.divider};
    min-width: 160px;
    border-top-right-radius: 12px;
    border: 1.5px solid ${({ theme }) => theme.colors?.ZenLightGray};
    :hover {
      background: #ffff;
      border: 1.5px solid ${({ theme }) => theme.colors.ZenLightGray};
      label {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }
  .tooltip-content {
    padding: 16px;
    max-width: 180px;
  }
  .tab-container {
    min-width: 160px;
    background: ${({ theme }) => theme.colors.divider};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1.5px solid ${({ theme }) => theme.colors?.ZenLightGray};
    margin-left: -10px;
    :hover {
      background: #ffff;
      border: 1.5px solid ${({ theme }) => theme.colors.ZenLightGray};
      label {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .active-tab {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1.5px solid ${({ theme }) => theme.colors.ZenLightGray};
    z-index: 5;
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .bdr-right {
    border-right: 1px solid ${({ theme }) => theme.colors.Gray4};
  }

  .grid-header-container {
    display: grid;
    grid-template-columns:
      minmax(160px, 1fr) minmax(130px, 0.7fr) minmax(130px, 0.7fr) minmax(130px, 0.7fr)
      minmax(150px, 0.7fr);
    background: ${({ theme }) => theme.colors.backgroundColor};
    max-height: 40px;
    min-height: 40px;
  }

  .item-header-container {
    display: grid;
    grid-template-columns:
      minmax(160px, 1fr) minmax(130px, 0.7fr) minmax(130px, 0.7fr) minmax(130px, 0.7fr)
      minmax(150px, 0.7fr);
    max-height: 48px;
    min-height: 48px;
  }

  .item-bdr-right {
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
  }
  .bdr-bottom {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }
  .continual {
    background: ${({ theme }) => theme.colors.ZenBlue2};
    height: 28px;
    border-radius: 18px;
    label {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
    svg {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
  }

  .cohort {
    background: ${({ theme }) => theme.colors.ZenPurpleSecondary2};
    height: 28px;
    border-radius: 18px;
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
    svg {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .empty-image-wrapper {
    background: ${({ theme }) => theme.colors.zenPurple};
    height: 40px;
    width: 40px;
  }
`;
