import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../../../assets/images/burger.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { PrizeDrawDetailHeaderWrapper } from '../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Menu from '../../../common/menu';

const PrizeDrawDetailEntrantHeader = ({ title, titleContent = null, actionContent = null }) => {
  const { id, entrant_id } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = useContext(OrganisationContext);
  const { t } = useTranslation();

  const onMenuClick = navigateTo => {
    navigate(`/prize-draws/all/${id}/entrant-details/${entrant_id}/${navigateTo}`);
  };

  const menuItems = [{ name: t('OVERVIEW'), onClick: () => onMenuClick('overview') }];

  const menuItemsWithPermission = [
    { key: 'ENTRANT_NOTES_VIEW', name: t('NOTES'), onClick: () => onMenuClick('notes') },
    { key: 'ENTRANT_ENTRY_VIEW', name: t('ENTRY_REFERRALS'), onClick: () => onMenuClick('entry-referral') },
    { key: 'ENTRANT_LOG_VIEW', name: t('ACTIVITY_LOGS'), onClick: () => onMenuClick('logs') },
  ];

  const filteredMenuItems = menuItemsWithPermission.filter(permission => hasPermission(permission.key));

  const menuList = [...menuItems, ...filteredMenuItems];

  return (
    <PrizeDrawDetailHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 border-bottom">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text font-20 one-line">{title}</label>
        {titleContent}
      </div>
      {actionContent}
      <Menu Icon={HamburgerIcon} menuList={menuList} tooltipContent={'Menu'} />
    </PrizeDrawDetailHeaderWrapper>
  );
};

export default PrizeDrawDetailEntrantHeader;
