import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as UsersIcon } from '../../assets/images/multiple-users.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { formatText } from '../../helpers/utils';
import { GroupsListItemWrapper } from '../../styles/components/groups-list/groups-list.styled';
import Menu from '../common/menu';
import { Tooltip } from '../tooltip/tooltip';

const GroupListItem = ({ group, getGroupList = () => {} }) => {
  const { setModal } = useContext(OrganisationContext);

  const [showRolesCount, setShowRolesCount] = useState(group?.roles?.length || 0);
  const groupListItemRef = useRef();
  // const { width } = useWindowSize();
  const navigate = useNavigate();

  // useEffect(() => {
  //   setShowRolesCount(group?.roles?.length);
  // }, []);

  const goToOrganizationCategoryetail = item => {
    navigate(`/groups/${item.id}/accounts`);
  };

  // TODO: Need to figure out how to hide remaining list items based on width
  // useEffect(() => {
  //   const roleWrapper = document.getElementById('role-item-wrapper');
  //   let lstRoleItems = [];
  //   lstRoleItems = groupListItemRef.current.getElementsByClassName('role-item') || [];
  //   if (lstRoleItems.length > 0) {
  //     let count = 0;
  //     let currentWidth = 0;
  //     let row = 1;
  //     const roleItemSpacing = 4; //margin right 4px
  //     const totalWidth = roleWrapper?.clientWidth - 64;
  //     Object.values(lstRoleItems).forEach((data, index) => {
  //       currentWidth = currentWidth + data.clientWidth + roleItemSpacing;
  //       if (currentWidth < totalWidth) {
  //         count = count + 1;
  //       } else {
  //         if (row === 1) {
  //           row = row + 1;
  //           count = count + 1;
  //           currentWidth = data.clientWidth + roleItemSpacing;
  //         }
  //       }
  //     });
  //     setShowRolesCount(count);
  //   }
  // }, [width]);

  const onDeleteGroup = () => {
    setModal({
      type: 'group-action',
      content: {
        group: group,
        onSuccess: () => getGroupList(),
        title: 'Are you sure you want to delete this group?',
      },
    });
  };

  return (
    <GroupsListItemWrapper
      ref={groupListItemRef}
      className="w-full cursor"
      onClick={() => goToOrganizationCategoryetail(group)}>
      <div className="border-right flex-column items-center px-4 one-line justify-center w-full">
        <label className="regular-text font-16 one-line w-full">{group?.name}</label>
      </div>
      <div className="border-right flex items-center px-4 justify-start ">
        <label className="normal-text lighter-text two-lines w-full word-break-all">{group?.description}</label>
      </div>
      <div
        id="role-item-wrapper"
        className={`border-right flex items-center px-4 py-1 justify-start w-full wrap items-start`}>
        {group?.roles?.length > 0 &&
          (group?.roles || []).map(role => (
            <div
              className={`flex items-center justify-center w-auto role-wrapper radius-2 mr-2 mb-2 role-item`}
              key={role?.id}>
              <label className={`medium-text font-12`}>{role?.name}</label>
            </div>
          ))}
        {/* {group?.roles.length - showRolesCount > 0 && (
          <div className="flex items-center justify-center w-auto role-wrapper radius-2 mr-2 mb-2 ">
            <label className="medium-text font-12">{`+ ${group?.roles.length - showRolesCount}`}</label>
          </div>
        )} */}
      </div>
      <div className="flex items-center justify-between w-full px-4">
        <Tooltip content={<label className="regular-text font-12">Accounts</label>} placement={'bottom-start'}>
          <div className={`flex items-center status-container py-1 px-2 ${formatText(group?.status)}`}>
            <UsersIcon />
            <label className="regular-text font-14 ml-1">{group?.organizations_number}</label>
          </div>
        </Tooltip>
        <Menu
          menuList={[
            { name: 'Details', onClick: () => goToOrganizationCategoryetail(group) },
            ...[
              group?.organizations_number <= 0
                ? {
                    name: 'Delete',
                    onClick: () => onDeleteGroup(),
                  }
                : null,
            ].filter(Boolean),
          ]}
        />
      </div>
    </GroupsListItemWrapper>
  );
};

export default GroupListItem;
