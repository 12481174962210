import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import DateSelector from './date-selector';

const DateSelectorElement = ({
  selectedDate,
  setSelectedDate,
  placeholder,
  format = 'MM/DD/YYYY',
  className = '',
  name = '',
  is_required = false,
  font = null,
  fontFamily,
  showRequiredError,
  sub_label = null,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const changeSelectedDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowCalendar(false);
    }
  };

  return (
    <DateSelectorElementWrapper>
      <div className="mb-1">
        <span
          className="regular-text lighter-text"
          style={{ fontSize: `${font.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
          {name}
        </span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <div
        className={`date-selector-element flex flex-1 items-center justify-between px-4 cursor py-2 relative ${
          showCalendar && 'show-calendar'
        } ${showRequiredError && !selectedDate && 'date-required-error'} ${className}`}
        onBlur={onBlur}
        onClick={() => setShowCalendar(!showCalendar)}
        tabIndex={0}>
        {selectedDate ? (
          <label className="regular-text font-14">{moment.unix(selectedDate).format(format)}</label>
        ) : (
          <label className="regular-text font-14 o03">{placeholder}</label>
        )}
        {showCalendar && (
          <DateSelector
            setEndDate={() => {}}
            showCurrentMonth={false}
            setStartDate={changeSelectedDate}
            startDate={moment.unix(selectedDate || moment().unix())}
          />
        )}
      </div>
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
    </DateSelectorElementWrapper>
  );
};

export const DateSelectorElementWrapper = styled.div`
  .date-selector-element {
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};
    box-sizing: border-box;
    border-radius: 16px;

    &:hover {
      border-color: ${({ theme }) => theme.colors.ZenPurpleSecondary};
    }

    .date-selector {
      top: 45px;
      z-index: 5;
      right: -40px;
    }
  }

  .show-calendar {
    border-color: ${({ theme }) => theme.colors.zenPurple};

    &:hover {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .date-required-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    &:hover {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }
`;

export default DateSelectorElement;
