import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import PageHeader from '../../components/page-header';
import { filterChildRenderComponent, multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { getGlobalStatistic, getPaymentStatistic } from '../../store/features/dashboardSlice';
import { GraphWrapper } from '../../styles/components/dashboard/dashboard.styled';
import AverageStats from './AverageStats';
import Entrants from './Entrants';
import Gender from './Gender';
import GeoMap from './GeoMap';
import PaymentRetention from './PaymentRetention';
import ProductFrequency from './ProductFrequency';

const Dashboard = ({ expanded }) => {
  const { t } = useTranslation();
  const Category = getItemFromLocalStorage('user').organizations[0]?.category.name;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState([{ id: uuid() }]);
  const [paymentSuccessToggle, setPaymentSuccessToggle] = useState(true);

  useEffect(() => {
    const statParam = paymentSuccessToggle ? 'stat=RETENTION' : 'stat=ATTRITION';
    dispatch(getPaymentStatistic({ params: statParam })).catch(() => {});
    dispatch(getGlobalStatistic()).catch(() => {});
  }, []);

  const createEntrantFilterUrl = filters => {
    let url = '';
    filters?.forEach(item => {
      if (item?.childRenderComponent === filterChildRenderComponent.dateRange) {
        if (item.value) {
          const fromDate = item.value.fromDate || '';
          const toDate = item.value.toDate || '';
          url += url.includes('?') ? '&' : '?';
          url += `${item.minParam}=${fromDate}&${item.maxParam}=${toDate}`;
        }
      } else if (item?.childRenderComponent === filterChildRenderComponent.minMax) {
        if (item.value) {
          const minValue = item.value.minValue || '';
          const maxValue = item.value.maxValue || '';
          url += url.includes('?') ? '&' : '?';
          url += `${item.minParam}=${minValue}&${item.maxParam}=${maxValue}`;
        }
      } else if (item?.childRenderComponent === filterChildRenderComponent.inputDropDown) {
        if (item.value) {
          const inputValue = item.value.inputValue || '';
          const valueId = item.value.id || '';
          url += url.includes('?') ? '&' : '?';
          url += `${item.countParam}=${inputValue}&${item.param}=${valueId}`;
        }
      } else if (item?.childRenderComponent === filterChildRenderComponent.infiniteScrollMultiDropdown) {
        if (item.value2) {
          url += url.includes('?') ? '&' : '?';
          url += `${item.param}=${item.value.id}&${item.subParam ? item.subParam : item.param}=${item.value2.id}`;
        } else if (item.value) {
          url += url.includes('?') ? '&' : '?';
          url += `${item.param}=${item.value.id}`;
        }
      } else {
        if (item.value) {
          url += url.includes('?') ? '&' : '?';
          url += `${item.param}=${
            item.param === 'prizedraw_client_code' ? item.value.prizedraw_client_code : item.value.id
          }`;
        }
      }
    });
    return url;
  };

  useEffect(() => {
    const statParam = paymentSuccessToggle ? 'stat=RETENTION' : 'stat=ATTRITION';
    const url = createEntrantFilterUrl(filters);
    dispatch(
      getPaymentStatistic({
        url: url,
        params: statParam,
      }),
    ).catch(() => {});
    dispatch(
      getGlobalStatistic({
        url: url,
      }),
    ).catch(() => {});
  }, [filters]);

  useEffect(() => {
    const statParam = paymentSuccessToggle ? 'stat=RETENTION' : 'stat=ATTRITION';
    const url = createEntrantFilterUrl(filters);
    dispatch(
      getPaymentStatistic({
        url: url,
        params: statParam,
      }),
    ).catch(() => {});
  }, [paymentSuccessToggle]);

  const filterType = () => {
    switch (Category) {
      case 'Client':
        return 'Client';
      case 'Marketing Company':
        return 'MarketingCompany';
      case 'Agency':
      case 'Agencies':
        return 'Agency';
      case 'Saas Operators':
        return 'SaasOperators';
    }
  };

  return (
    <GraphWrapper className="flex flex-1 flex-column mx-10 overflow-hidden">
      <div className="flex justify-between">
        <div className="font-20 pt-6 pb-8 semibold-text zen-default-text">{t('DASHBOARD')}</div>
        <div style={{ marginRight: '100px' }}>
          <PageHeader
            showMultipleFilter
            multiFilter={filters}
            setMultiFilter={data => setFilters(data)}
            multiFilterFor={multipleFilterTypeEnum[filterType()]}
          />
        </div>
      </div>

      <div className="flex-column overflow-scroll">
        <div className="flex-column w-full">
          <div className="flex-row gap-4 ">
            <div className="flex flex-1">
              <Entrants url={createEntrantFilterUrl(filters)} />
            </div>
            <div className="flex-column gap-4">
              <div className="flex flex-1 gap-4">
                <ProductFrequency expanded={expanded} />
                <Gender expanded={expanded} />
              </div>
              <div className="flex flex-1 gap-4">
                <AverageStats expanded={expanded} />
                <GeoMap expanded={expanded} />
              </div>
            </div>
          </div>
          <PaymentRetention
            setPaymentSuccessToggle={setPaymentSuccessToggle}
            paymentSuccessToggle={paymentSuccessToggle}
          />
        </div>
      </div>
    </GraphWrapper>
  );
};
export default Dashboard;
