import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as ConditonIcon } from '../../../../assets/images/condition.svg';
import { ReactComponent as Delete } from '../../../../assets/images/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as MailIcon } from '../../../../assets/images/mail.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/menu/arrow-down-black.svg';
import { ReactComponent as RightArrow } from '../../../../assets/images/sorting-right.svg';
import { ReactComponent as SwapVerticalIcon } from '../../../../assets/images/swap-vertical-circle.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/images/time.svg';
import { capitalize, formatText, operatorOptions } from '../../../../helpers/utils';
import { deleteAutomationAction } from '../../../../store/features/automationsSlice';

const AutomationLogAction = ({
  automationAction,
  index,
  setEditAutomationActionData = () => {},
  onEditAutomationAction = () => {},
}) => {
  const [expand, setExpand] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();

  const DeleteAction = () => {
    dispatch(deleteAutomationAction({ id: id, automationId: automationAction?.id }));
  };

  const handleEditAutomationData = () => {
    setEditAutomationActionData(automationAction);
  };

  const expandUI = (
    <div className="automation-action-wrapper flex-column border items-start justify-start w-full radius-3 mt-6 relative">
      {/* Header */}
      <div className="flex-column items-start justify-start w-full pr-6 pt-4 pb-6 border-bottom">
        <div className="flex flex-column header-border-left w-full">
          <div className="flex justify-start cursor">
            <div className="flex items-center pl-5 flex-1">
              <div className="dot" />
              <div className="flex flex-1">
                <label className="medium-text font-16 ml-2 w-full">{`Action ${index + 1}`}</label>
              </div>
            </div>
            <div className="flex items-center justify-start col-gap-2 ml-6">
              <Delete onClick={DeleteAction} className="hover-delete cursor" />
              <ArrowDownIcon
                className={`flex items-center justify-center cursor ${expand ? 'rotate-180' : ''}`}
                onClick={() => setExpand(!expand)}
              />
            </div>
          </div>
          <div className="flex items-start justify-start self-start ml-6 sub-header-border border radius-2 mt-4 px-4 py-3">
            <div className="flex items-center justify-center icon-wrapper">
              <MailIcon className="flex items-center justify-center" height={24} width={24} />
            </div>
            <div className="flex-column items-start justify-start flex-1 ml-2 action-header-wrapper">
              <div className="flex items-center">
                <label className="medium-text font-14">{automationAction?.action_type?.name}</label>
                <div className="flex items-center justify-center cursor edit-delete-btn-wrapper ml-1">
                  <EditIcon onClick={() => onEditAutomationAction(automationAction, 'ACTION')} />
                </div>
              </div>
              <label className="normal-text font-12 grey-text">{automationAction?.action_type?.description}</label>
            </div>
          </div>
        </div>

        {/* content */}
        <div className="flex-column items-start justify-start w-full pxy-6">
          <div className="flex items-center justify-start w-full edit-integration-wrapper">
            <div className="flex items-center justify-center border icon-wrapper">
              <img
                src={`${automationAction?.integration?.connector?.icon?.active}`}
                style={{ width: 18, height: 18 }}
              />
            </div>
            <div className="flex-column items-start justify-start flex-1 ml-2">
              <div className="flex items-center">
                <label className="ml-2 medium-text font-14">{automationAction?.integration?.name}</label>
                <div className="flex items-center justify-center cursor edit-integration-icon ml-1">
                  <EditIcon onClick={() => onEditAutomationAction(automationAction, 'INTEGRATION')} />
                </div>
              </div>
              <label className="ml-2 normal-text fw-400 grey-text font-12">
                {automationAction?.integration?.description}
              </label>
            </div>
          </div>
          <div className="flex edit-integration-param-wrapper">
            <div className="flex-column">
              {automationAction?.params.length > 0 && (
                <Fragment>
                  <div className="flex-column items-start justify-start w-full mt-4">
                    {automationAction?.params
                      ?.filter(p => !p.mapping_source)
                      .map((param, index) => (
                        <div
                          className={`flex items-center justify-start w-full ${index === 0 ? '' : 'mt-2'}`}
                          key={param.id}>
                          <label className="regular-text lighter-text font-14">
                            {capitalize(formatText(param.param_type, ' '))}: &nbsp;
                          </label>
                          <label className="regular-text font-14"> {param.display_value || param.param_value}</label>
                        </div>
                      ))}
                  </div>
                  {automationAction?.params?.filter(p => p.mapping_source)?.length > 0 && (
                    <div className="flex-column bg-backgroundClr mapping-data-wrapper mt-4 radius-2 px-3 py-2">
                      {automationAction?.params
                        ?.filter(p => p.mapping_source)
                        ?.map((param, index) => {
                          const { display_value, id } = param || {};
                          const splitedName = display_value?.split(':') || [];
                          return (
                            <div key={id} className="data-wrapper items-center col-gap-2 row-gap-1">
                              {index === 0 ? <SwapVerticalIcon width={16} height={16} /> : <div />}
                              <div className="flex items-center col-gap-2">
                                <label className="regular-text font-14">
                                  {capitalize(formatText(splitedName?.[0], ' '))}
                                </label>
                                <RightArrow />
                                <label className="regular-text font-14">
                                  {capitalize(formatText(splitedName?.[1], ' '))}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </Fragment>
              )}
              {automationAction?.schedule?.offset &&
                automationAction?.schedule?.unit &&
                automationAction?.schedule?.field && (
                  <div className="flex item-center justify-start mt-4 schedule-conditon-wrapper py-2 px-4 radius-2">
                    <ClockIcon className="flex items-center justify-center" height={18} width={18} />
                    <label className="normal-text ml-1">{`${Math.abs(automationAction?.schedule?.offset)}
                      ${formatText(automationAction?.schedule?.unit, ' ')}
                      ${automationAction?.schedule?.offset < 0 ? 'before' : 'after'}
                      ${automationAction?.schedule?.field.toLowerCase().replaceAll('.', ' ')}`}</label>
                  </div>
                )}
              <div className="flex-column items-start justify-start mt-2">
                {(automationAction?.conditions || []).map((condition, index) => {
                  return (
                    <div className="flex item-center justify-start mt-6 schedule-conditon-wrapper py-2 px-4 radius-2 my-1">
                      <ConditonIcon className="flex items-center justify-center mr-2" height={18} width={18} />
                      <label className="flex normal-text font-14">
                        {index == 0 ? 'When' : 'And'} {condition?.display_field || condition?.field}{' '}
                        {operatorOptions.find(opr => opr.value === condition?.operator).name}
                      </label>
                      <label className="flex normal-text fw-500 font-14 ml-1">
                        {condition?.display_value || condition?.value}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex cursor edit-integration-icon ml-1 mt-2">
              <EditIcon onClick={() => onEditAutomationAction(automationAction, 'ACTION_DATA')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const collapsedUI = (
    <div className="automation-action-wrapper flex-column border items-center justify-start w-full radius-3 mt-6 relative">
      {/* Header */}
      <div className="flex-column items-start justify-start w-full pr-6 pt-4 pb-6 action-header-wrapper">
        <div className="flex items-center header-border-left w-full">
          <div className="flex items-center pl-5 flex-1">
            <div className="dot" />
            <div className="flex items-center flex-1">
              <label className="medium-text font-16 ml-2 w-full">{`Action ${index + 1}`}</label>
            </div>
          </div>
          <div className="flex items-center justify-start col-gap-2 ml-6">
            <div className="flex items-center justify-center cursor edit-delete-btn-wrapper">
              <EditIcon onClick={() => handleEditAutomationData()} />
            </div>
            <Delete onClick={DeleteAction} className="hover-delete cursor" />
            <ArrowDownIcon
              className={`flex items-center justify-center cursor ${expand ? 'rotate-180' : ''}`}
              onClick={() => setExpand(!expand)}
            />
          </div>
        </div>
        <div className="flex items-center justify-start ml-6 sub-header-border border radius-2 mt-4 px-4 py-3">
          <div className="flex items-start justify-start w-50">
            <MailIcon className="flex items-center justify-center mt-1" height={24} width={24} />
            <div className="flex-column items-start justify-start flex-1 ml-2">
              <label className="medium-text fw-500 font-14">{automationAction?.action_type?.name}</label>
              <label className="normal-text fw-500 font-12 grey-text">
                {automationAction?.action_type?.description}
              </label>
            </div>
          </div>
          <div className="flex items-start justify-between w-50">
            <div className="flex items-center justify-start flex-1">
              <div className="flex items-center justify-center border icon-wrapper">
                <img
                  src={`${automationAction?.integration?.connector?.icon?.active}`}
                  style={{ width: 18, height: 18 }}
                />
              </div>
              <div className="flex-column items-start justify-start flex-1 ml-2">
                <label className="ml-2 medium-text fw-500 font-14">{automationAction?.integration?.name}</label>
                <label className="ml-2 normal-text fw-400 grey-text font-12">
                  {automationAction?.integration?.description}
                </label>
              </div>
            </div>
            <div className="flex self-center items-center justify-start">
              <ClockIcon className="flex items-center justify-center" />
              <div className="flex items-center justify-center ml-4">
                <ConditonIcon className="flex items-center justify-center" color={'#053149'} />
                <label className="normal-text ml-2">{(automationAction?.conditions || [])?.length}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return expand ? expandUI : collapsedUI;
};

export default AutomationLogAction;
