import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizedrawUpdate = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();
  const { prizedraw, previous } = event_data;
  const {
    prizedraw: { name: prevName, code: prevCode, description: prevDescription, image: prevImage },
  } = previous;
  const changedValue =
    prevName !== prizedraw?.name
      ? 'name'
      : prevCode !== prizedraw?.code
      ? 'code'
      : prevDescription !== prizedraw?.description
      ? 'description'
      : previous?.prizedraw?.image?.id !== prizedraw?.image?.id
      ? 'image'
      : '';

  const getTextTitle =
    changedValue === 'image' ? (
      <span>
        <span className="regular-text lighter-text font-12">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">
          {prizedraw?.image?.id ? 'updated Prize Draw logo' : 'remove Prize Draw logo'}
        </span>
      </span>
    ) : (
      <span>
        <span className="regular-text lighter-text font-12">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">{t('UPDATED_PRIZE_DRAW')}</span>
        <span className="regular-text zen-purple-text cursor font-12 ml-1">{event_data?.prizedraw?.code}</span>
        <span className="regular-text lighter-text font-12 ml-1">{changedValue}</span>
      </span>
    );

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Prizedraw updated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={getTextTitle}
        showDetails={changedValue !== 'image' ? showDetails : false}
        setShowDetails={data => setShowDetails(data)}
        showDownIcon={changedValue !== 'image' ? true : false}
      />
      {showDetails && changedValue !== 'image' && (
        <>
          <LogContentBox
            title={'From'}
            contentText={<p className="flex regular-text lighter-text font-12">{previous?.prizedraw[changedValue]}</p>}
          />
          <LogContentBox
            title={'To'}
            contentText={<p className="flex regular-text lighter-text font-12">{prizedraw[changedValue]}</p>}
          />
        </>
      )}
    </div>
  );
};

export default PrizedrawUpdate;
