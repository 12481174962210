import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as MailIcon } from '../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../assets/images/mobile.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { getContactValue } from '../helpers/utils';
import { deleteClient } from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { RemovePrizedrawClient } from './modal.styled';

const RemoveClient = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const { title, subTitle, clientData, prizedrawid, prizeDrawClientId, onSuccess = () => {} } = modal.content;
  const [loading, setLoading] = useState(false);

  const onDone = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(
      deleteClient({
        prizeDrawId: prizedrawid,
        orgID: prizeDrawClientId,
        isClient: true,
      }),
    )
      .then(({ data }) => {
        onSuccess();
        setModal(initModal);
        setLoading(false);
      })
      .catch(() => {
        dispatch(
          addToast({
            error: true,
            text: 'Error while deleting client',
            id: uuid(),
          }),
        );
        setLoading(false);
      });
  };
  return (
    <CommonPopup
      disabled={loading}
      popupTitle={title}
      popupSubTitle={subTitle}
      confirmButtonProps={{ label: 'Remove', className: 'negative' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}>
      <RemovePrizedrawClient className="w-full radius-3 pxy-4 mt-6">
        <div className="semibold-text font-18">{clientData?.name}</div>
        <div className="font-14 regular-text pt-2">{clientData?.primary_location?.formatted_address}</div>
        <div className="flex items-center justify-start w-full pt-4">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MailIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label
            className={`regular-text one-line font-14 ml-2 ${
              getContactValue(clientData?.contacts, 'EMAIL') === 'Not provided' ? 'grey-text' : ''
            }`}>
            {getContactValue(clientData?.contacts, 'EMAIL')}
          </label>
        </div>
        <div className="flex items-center justify-start w-full mt-1">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MobileIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label
            className={`regular-text one-line font-14 ml-2 ${
              getContactValue(clientData?.contacts, 'MOBILE') === 'Not provided' ? 'grey-text' : ''
            }`}>
            {getContactValue(clientData?.contacts, 'MOBILE')}
          </label>
        </div>
      </RemovePrizedrawClient>
    </CommonPopup>
  );
};

export default RemoveClient;
