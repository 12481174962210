import React from 'react';
import { AutomationDetailsComponentWrapper } from '../../styles/components/automationDetails/automation-details.styled';
import AutomationDetailsLeft from './automation-details-left';
import AutomationDetailsRight from './automation-details-right';

const AutomationDetailsComponent = ({ fetchUser = () => {} }) => {
  return (
    <AutomationDetailsComponentWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6">
      <AutomationDetailsLeft fetchUser={fetchUser} />
      <AutomationDetailsRight />
    </AutomationDetailsComponentWrapper>
  );
};

export default AutomationDetailsComponent;
