import styled from 'styled-components';

export const EditFormVersionWrapper = styled.div`
  width: 100dvw;
  height: 100dvh;
`;

export const LeftPanelWrapper = styled.div`
  min-width: 256px;
  width: 256px;
  .widget-container {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .hr-line {
    margin: 16px -16px;
    width: calc(100% + 32px);
  }

  .widget-icon {
    object-fit: contain;
  }
`;

export const HeaderPanelWrapper = styled.div`
  .logo-container {
    width: 256px;
  }

  .device-selector-wrapper {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .selected-device {
    border-color: ${({ theme }) => theme.colors.zenPurple};
  }
`;

export const RightPanelWrapper = styled.div`
  min-width: 384px;
  width: 384px;

  .hr-line {
    border-top: 1px solid ${({ theme }) => theme.colors.divider};
  }

  .delete-widget {
    background: ${({ theme }) => theme.colors.ZenNegativeSecondary_O3};
  }

  .close-widget-config {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .widget-input {
    min-height: 40px;
  }

  .widget-component-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    min-height: 40px;
  }

  .add-new-component-btn {
    label {
      margin: 0;
    }
  }

  .selected-alignment {
    color: ${({ theme }) => theme.colors.zenPurple};
  }

  .add-option-btn {
    label {
      margin: 0;
    }
  }

  .edit-hover {
    .choice-actions {
      display: none;
    }

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};

      .choice-actions {
        display: flex;
      }
    }
  }

  .selected-choice {
    border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    .choice-input {
      border: none;
      height: 24px;
    }

    .update-action {
      top: 100%;
    }
  }

  .upload-image {
    height: 80px;
    width: 80px;
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .image-wrapper {
    height: 80px;
    width: 80px;
    .delete-btn-wrapper {
      height: 80px;
      width: 80px;
      background: ${({ theme }) => theme.colors.ZenGraySecondary_05};
      display: none;
    }
    &:hover {
      .delete-btn-wrapper {
        display: flex;
      }
    }
  }

  .general-settings-input {
    height: 40px;
    max-width: 156px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  .progress-bar-settings-container {
    background: ${({ theme }) => theme.colors.backgroundColor};

    .progress-bar-tab {
      height: 32px;
    }

    .selected-progress-bar-tab {
      background: ${({ theme }) => theme.colors.zenPurple};
      color: #ffffff;
      border-radius: 10px;
    }
  }
`;

export const PaymentConfigWrapper = styled.div`
  .show-prize {
    .prize-container {
      height: fit-content;
      visibility: visible;
      padding: 4px 8px;
      margin-top: 4px;
    }
  }
  .hide-prize {
    .prize-container {
      height: 0;
      visibility: collapse;
    }
  }

  .prize-container {
    background-color: ${({ theme }) => theme.colors.backgroundColor};

    .update-action {
      top: 100%;
    }
  }

  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      .edit-icon {
        display: flex;
      }
    }
  }

  .prize-input {
    height: 24px;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export const ColorPickerWrapper = styled.div`
  .color {
    height: 24px;
    width: 24px;
    border-radius: 8px;
    background-color: ${({ selectedColor }) => selectedColor};
  }

  .picker-container {
    min-width: 112px;
  }
`;

export const MiddlePanelWrapper = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  .droppable-min-height {
    min-height: 200px;
  }
  .steps-list {
    background: ${({ theme }) => theme.colors.divider};
    min-height: 32px;
  }

  .step-container {
    .step-wrapper {
      height: 32px;
      min-width: 160px;
    }

    .page-edit-input {
      height: 24px;
      font-size: 12px;
      border-radius: 8px;
      padding: 0 12px;
    }

    .tooltip-content {
      padding: 24px;
      max-width: 272px;
      pointer-events: all;
      z-index: 1;

      .add-page-wrapper {
        .add-page-input {
          height: 32px;
          font-size: 14px;
          border-radius: 12px;
        }
      }
    }

    .page-edit-action-tooltip {
      .tooltip-content {
        padding: 0;
      }
      .page-edit-action {
        min-width: 124px;
      }
    }

    .middle-page,
    .first-page {
      border-top-right-radius: 12px;
      border-right: 1.5px solid ${({ theme }) => theme.colors.ZenLightGray};
    }

    .middle-page,
    .last-page {
      border-top-left-radius: 12px;
    }

    .last-page {
      border-right: unset;
    }

    .selected-step {
      background: ${({ theme }) => theme.colors.ZenLightGray};
    }

    &:hover {
      .add-step,
      .delete-step {
        display: flex;
      }

      .first-page {
        border-top-left-radius: 12px;
      }

      .last-page {
        border-top-right-radius: 12px;
      }
    }
  }

  .form-content-wrapper {
    margin: 24px auto;
    .form-content {
      padding: 40px;
    }
  }

  .add-widget {
    .plus {
      z-index: 1;
      border-radius: 50%;
    }
  }

  .widget-hover {
    .widget-hover-icon {
      display: none;
    }
    &:hover {
      margin: 4px -32px;
      width: calc(100% + 66px);
      border-radius: 16px;
      border: 1px dashed ${({ theme }) => theme.colors.zenPurple};
      background: ${({ theme }) => theme.colors.backgroundColor_05};

      .widget-hover-icon {
        margin: 0 8px;
        display: flex;

        .delete-widget {
          background: ${({ theme }) => theme.colors.ZenNegativeSecondary_O3};
        }
      }
    }
  }

  .selected-widget {
    margin: 4px -32px;
    width: calc(100% + 66px);
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    background: ${({ theme }) => theme.colors.backgroundColor_05};

    .widget-hover-icon {
      margin: 0 8px;
      display: flex;

      .delete-widget {
        background: ${({ theme }) => theme.colors.ZenNegativeSecondary_O3};
      }
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }
  }
`;
