import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as LogoIcon } from '../../assets/images/zenterprize-logo.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { updatedIntegrationStateInList, updateIntegrationState } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { IntegrationsListItemWrapper } from '../../styles/components/integrations-list/integrations-list.styled';
import Menu from '../common/menu';
import Switch from '../common/switch';

const IntegrationsListItem = ({ integration }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const onIntegration = integration => {
    navigate(`/platforms/integrations/${integration.id}`);
  };

  const onDeleteIntegration = () => {
    setModal({
      type: 'integration-action',
      content: {
        integration: integration,
        top: true,
        isDelete: true,
      },
    });
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integration;
    if (is_enabled) {
      setModal({ type: 'integration-action', content: { integration: integration, top: true, isDisable: true } });
    } else {
      const request = {
        enabled: !is_enabled,
      };
      dispatch(updatedIntegrationStateInList(integration));
      dispatch(updateIntegrationState({ id: integration?.id, request, updateList: false })).catch(error => {
        const errorData = error?.response?.data;
        dispatch(updatedIntegrationStateInList(integration));
        dispatch(
          addToast({
            error: true,
            text:
              errorData?.error_code === 'BAD_REQUEST'
                ? 'Please add all required fields in integration'
                : 'Error while updating status',
            id: uuid(),
          }),
        );
      });
    }
  };

  return (
    <IntegrationsListItemWrapper className="w-full cursor" onClick={() => onIntegration(integration)}>
      <div className="border-right flex items-center px-4 overflow-hidden">
        <label className="medium-text one-line">{integration?.name}</label>
      </div>
      <div className="border-right flex items-center px-4 overflow-hidden">
        <label className="regular-text grey-text two-lines">{integration?.description}</label>
      </div>
      <div className="border-right flex items-center justify-between px-4">
        <div className="flex items-center">
          <img alt="icon" className="icons" src={integration?.connector?.icon?.active} height={24} width={24} />
          <span className="regular-text font-14 ml-2">{integration?.connector?.name}</span>
        </div>
        <div>{integration?.is_default && <LogoIcon width={24} height={24} />}</div>
      </div>
      <div className="flex items-center justify-between px-4">
        <span
          className={`regular-text flex items-center font-16 last-width ${
            integration?.is_enabled ? 'enable-color' : 'disable-color'
          }`}>
          {integration?.is_enabled ? t('ENABLED') : t('DISABLED')}
        </span>
        <Switch onClick={() => onChangeStatus()} enabled={integration?.is_enabled} disabled={false} />
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuList={[
            { name: t('DETAILS'), onClick: () => onIntegration(integration), permission: 'INTEGRATIONS_MANAGE' },
            { name: t('DELETE'), onClick: () => onDeleteIntegration(), permission: 'INTEGRATIONS_MANAGE' },
          ]}
        />
      </div>
    </IntegrationsListItemWrapper>
  );
};

export default IntegrationsListItem;
