import { createSlice } from '@reduxjs/toolkit';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import api from '../../store/services';
import { addToast } from './toastSlice';

export const prizeDrawSlice = createSlice({
  name: 'prizeDraw',
  initialState: {
    prizeDrawList: [],
  },
  reducers: {
    setPrizeDrawList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.prizeDrawList?.content, ...payload?.data?.content] };
        state.prizeDrawList = mergeData;
      } else {
        state.prizeDrawList = payload.data;
      }
    },
    setPrizeDrawDetail: (state, { payload }) => {
      state.prizeDrawDetail = payload;
    },
    setPrizeDrawForm: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.prizeDrawForm?.content, ...payload?.data?.content] };
        state.prizeDrawForm = mergeData;
      } else {
        state.prizeDrawForm = payload.data;
      }
    },

    setPrizeDrawEntrant: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.prizeDrawEntrant?.content, ...payload?.data?.content],
        };
        state.prizeDrawEntrant = mergeData;
      } else {
        state.prizeDrawEntrant = payload.data;
      }
    },

    setUsersData: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.users?.content, ...payload?.data?.content] };
        state.users = mergeData;
      } else {
        state.users = payload.data;
      }
    },

    setOrganizationsData: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.organizations?.content, ...payload?.data?.content] };
        state.organizations = mergeData;
      } else {
        state.organizations = payload.data;
      }
    },

    setPrizeDrawConfig: (state, { payload }) => {
      state.prizeDrawConfig = payload;
    },

    setPrizeDrawSettings: (state, { payload }) => {
      state.prizeDrawSettings = payload;
    },

    setPrizeDrawOrganizations: (state, { payload }) => {
      state.prizeDrawOrganizations = payload.data;
    },

    setPrizeDrawOrganizationCategories: (state, { payload }) => {
      state.prizeDrawOrganizationCategories = payload;
    },

    setPrizeDrawOrganizatitonNetwork: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.prizeDrawOrganizationNetworks?.content, ...payload?.data?.content],
        };
        state.prizeDrawOrganizationNetworks = mergeData;
      } else {
        state.prizeDrawOrganizationNetworks = payload.data;
      }
    },

    removePrizeDrawOrganization: (state, { payload }) => {
      if (payload.isMarketingOrganization) {
        const updatedList = state.prizeDrawOrganizations.map(data => {
          if (data.id === payload.parentOrgID) {
            const updatedChildOrgsList = data.child_organizations.filter(item => item.id !== payload.orgID);
            return { ...data, child_organizations: [...updatedChildOrgsList] };
          } else {
            return data;
          }
        });
        state.prizeDrawOrganizations = [...updatedList];
      } else {
        state.prizeDrawOrganizations = state.prizeDrawOrganizations.filter(data => data.id !== payload.orgID);
      }
    },

    removePrizeDrawClients: (state, { payload }) => {
      if (payload.isClient) {
        const updatedList = state.prizeDrawClients.map(data => {
          if (data.id === payload.parentOrgID) {
            const updatedChildOrgsList = data.child_organizations.filter(item => item.id !== payload.orgID);
            return { ...data, child_organizations: [...updatedChildOrgsList] };
          } else {
            return data;
          }
        });
        state.prizeDrawClients = [...updatedList];
      } else {
        state.prizeDrawClients = state.prizeDrawClients.filter(data => data.id !== payload.orgID);
      }
    },

    setFormList: (state, { payload }) => {
      state.formList = payload;
    },

    setFormType: (state, { payload }) => {
      state.formType = payload;
    },
    setClientDrawCode: (state, { payload }) => {
      state.clientDrawCode = payload;
    },
    setPrizeDrawClients: (state, { payload }) => {
      state.prizeDrawClients = payload;
    },
    setPrizeDrawFormTemplate: (state, { payload }) => {
      state.formTemplate = payload;
    },
    setPrizeDrawEntrantDetails: (state, { payload }) => {
      state.prizeDrawEntrantDetails = payload;
    },
    setPrizeDrawEntrantOverviewDetails: (state, { payload }) => {
      state.prizeDrawEntrantOverDetails = payload;
    },
    setPrizeDrawEntrantNotesDetails: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.prizeDrawEntrantNotesDetails?.content, ...payload?.data?.content],
        };
        state.prizeDrawEntrantNotesDetails = mergeData;
      } else {
        state.prizeDrawEntrantNotesDetails = payload.data;
      }
    },
    setUpdatedEntrantNotes: (state, { payload }) => {
      try {
        const updatedList = state.prizeDrawEntrantNotesDetails?.content.map(data => {
          if (data.id === payload.id) {
            return { ...payload };
          } else {
            return { ...data };
          }
        });
        state.prizeDrawEntrantNotesDetails = { ...state.prizeDrawEntrantNotesDetails, content: [...updatedList] };
      } catch (error) {
        console.log('Error', error);
      }
    },
    setPrizeDrawEntrantOverviewWinningDetails: (state, { payload }) => {
      state.prizeDrawEntrantOverviewWinningDetails = payload;
    },
    setPrizeDrawEntrantCancelReason: (state, { payload }) => {
      state.prizeDrawEntrantCancelReasons = payload;
    },
    setPrizeDrawEntrantOverviewWinningProducts: (state, { payload }) => {
      state.prizeDrawEntrantOverviewProductsDetails = payload;
    },
    setPrizeDrawEntrantOverviewPaymentMethodsDetails: (state, { payload }) => {
      state.prizeDrawEntrantOverviewPaymentMethodsDetails = payload;
    },
    setPrizeDrawEntrantEntryForm: (state, { payload }) => {
      state.prizeDrawEntrantEntryFormDetails = payload;
    },
    setPrizeDrawEntrantEntryFundraising: (state, { payload }) => {
      state.prizeDrawEntrantEntryFundraisingDetails = payload;
    },
    setEventLogs: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.eventLogs?.content, ...payload?.data?.content],
        };
        state.eventLogs = mergeData;
      } else {
        state.eventLogs = payload.data;
      }
    },
    setPrizeDrawEntrantEntryReferrals: (state, { payload }) => {
      state.prizeDrawEntrantEntryReferralsDetails = payload;
    },
    setEventGroups: (state, { payload }) => {
      state.eventGroups = payload || [];
    },
    setSubEventGroups: (state, { payload }) => {
      const { data, id } = payload;
      state.subEventGroups = { ...state.subEventGroups, [id]: data };
    },
    setExportColumnsList: (state, { payload }) => {
      state.exportColumnList = payload;
    },
    setTemplateList: (state, { payload }) => {
      state.templateList = payload;
    },
    updateTemplateList: (state, { payload }) => {
      const updatedList = state.templateList.map(data => (data?.id === payload?.id ? payload : data));
      state.templateList = updatedList;
    },
    removeTemplate: (state, { payload }) => {
      const updatedList = state.templateList.filter(data => data?.id !== payload);
      state.templateList = updatedList;
    },
    addTemplateList: (state, { payload }) => {
      state.templateList = [...state.templateList, { ...payload }];
    },
    setPrizedrawChecklist: (state, { payload }) => {
      state.prizedrawChecklist = payload;
    },
    setPrizeDrawOrganizatitonNetworkAgency: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.prizeDrawOrganizationNetworksAgency?.content, ...payload?.data?.content],
        };
        state.prizeDrawOrganizationNetworksAgency = mergeData;
      } else {
        state.prizeDrawOrganizationNetworksAgency = payload.data;
      }
    },
    setPrizeDrawOrganizatitonNetworkClient: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.prizeDrawOrganizationNetworksClient?.content, ...payload?.data?.content],
        };
        state.prizeDrawOrganizationNetworksClient = mergeData;
      } else {
        state.prizeDrawOrganizationNetworksClient = payload.data;
      }
    },
    setPrizeDrawOrganizatitonNetworkForAgency: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...state?.prizeDrawOrganizationNetworksForSubChild,
          [payload?.organization_id]: {
            ...state?.prizeDrawOrganizationNetworksForSubChild[payload?.organization_id],
            content: [
              ...state?.prizeDrawOrganizationNetworksForSubChild[payload?.organization_id]?.content,
              ...payload?.data?.content,
            ],
          },
        };
        state.prizeDrawOrganizationNetworksForSubChild = mergeData;
      } else {
        state.prizeDrawOrganizationNetworksForSubChild = {
          ...state?.prizeDrawOrganizationNetworksForSubChild,
          [payload?.organization_id]: { ...payload?.data },
        };
      }
    },
    setMarketingCompanyUsersData: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...state?.marketingCompanyUsers,
          [payload?.organization_id]: {
            ...state?.marketingCompanyUsers[payload?.organization_id],
            content: [...state?.marketingCompanyUsers[payload?.organization_id]?.content, ...payload?.data?.content],
          },
        };
        state.marketingCompanyUsers = mergeData;
      } else {
        state.marketingCompanyUsers = {
          ...state?.marketingCompanyUsers,
          [payload?.organization_id]: { ...payload?.data },
        };
      }
    },
    setHomeEntrant: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.homeEntrant?.content, ...payload?.data?.content],
        };
        state.homeEntrant = mergeData;
      } else {
        state.homeEntrant = payload.data;
      }
    },
  },
});

export const getPrizeDrawList = payload => async dispatch => {
  const { data } = await api.get(`/prizedraws`, { params: payload?.params });
  dispatch(setPrizeDrawList({ data: data, merge: payload?.merge }));
  return Promise.resolve(data);
};

export const getPrizeDrawDetail = payload => async dispatch => {
  const { data } = await api.get(`/prizedraws/${payload.id}`);
  dispatch(setPrizeDrawDetail(data));
  return Promise.resolve(data);
};

export const getPrizeDrawForm = payload => async dispatch => {
  const { prizedraw, page, search } = payload?.params;
  const url = `/prizedraws/forms?page=${page}${prizedraw ? `&prizedraw=${prizedraw}` : ''}${
    search ? `&search=${search}` : ''
  }${payload?.url ? payload.url : ''}`;
  const { data } = await api.get(url);
  dispatch(setPrizeDrawForm({ data: data, merge: payload.merge }));
  return Promise.resolve(data);
};

export const getPrizeDrawEntrant = payload => async dispatch => {
  try {
    const { prizedraw_id, search, sort_by, order_by, page, size } = payload?.params;
    const url = `/entrants?prizedraw_id=${prizedraw_id}&page=${page}&size=${size}&search=${search}&sort_by=${sort_by}&order_by=${
      order_by ? order_by : ''
    }${payload?.url && `&${payload?.url}`}`;
    const { data } = await api.get(url);
    dispatch(setPrizeDrawEntrant({ data, merge: payload.merge }));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getUsers = payload => async dispatch => {
  try {
    const { data } = await api.get(`/users`, { params: payload.params });
    dispatch(setUsersData({ data: data, merge: payload.merge }));
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getPrizeDrawOrganization = payload => async dispatch => {
  try {
    const { data } = await api.get(`/prizedraws/${payload.prizedrawid}/organizations`, { params: payload.params });
    dispatch(setPrizeDrawOrganizations({ data: data }));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getOrganizations = payload => async dispatch => {
  try {
    const { data } = await api.get(`/organizations`, { params: payload.params });
    dispatch(setOrganizationsData({ data: data, merge: payload.merge }));
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getMultiOrganization = payload => async dispatch => {
  const organisationId = JSON.parse(localStorage.getItem('user'));
  try {
    const { data } = await api.get(`/organizations/${organisationId.organizations[0].id}/network`, {
      params: payload.params,
    });
    dispatch(setOrganizationsData({ data: data, merge: payload.merge }));
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getPrizeDrawConfig = payload => async dispatch => {
  try {
    const { data } = await api.get(`/prizedraws/${payload.prizedrawId}/draw_config`);
    dispatch(setPrizeDrawConfig(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(setPrizeDrawConfig({}));
    return Promise.reject(error);
  }
};

export const getPrizeDrawSettings = payload => async dispatch => {
  try {
    const { data } = await api.get(`/prizedraws/${payload.prizedrawId}/configuration`);
    dispatch(setPrizeDrawSettings(data));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(setPrizeDrawSettings({}));
    return Promise.reject(error);
  }
};

export const updatePrizeDrawConfig = payload => async dispatch => {
  try {
    const { data } = await api.put(`/prizedraws/${payload.prizedrawId}/draw_config`, payload.request);
    dispatch(setPrizeDrawConfig(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrizeDrawSettings = payload => async dispatch => {
  try {
    const { data } = await api.put(`/prizedraws/${payload.prizedrawId}/configuration`, payload.request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrizesSettings = payload => async dispatch => {
  try {
    const { data } = await api.put(`/prizedraws/${payload.prizedrawId}/prizes`, payload.request);
    dispatch(setPrizeDrawSettings(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateExcludedStatesSettings = payload => async dispatch => {
  try {
    const { data } = await api.put(`/prizedraws/${payload.prizedrawId}/excluded_states`, payload.request);
    dispatch(setPrizeDrawSettings(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationCategories = payload => async dispatch => {
  try {
    const org = getItemFromLocalStorage('user')?.organizations[0];
    const { data } = await api.get(`/prizedraws/organizations/${org.id}/organization_categories`);
    dispatch(setPrizeDrawOrganizationCategories(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationNetworks = payload => async dispatch => {
  try {
    const org = getItemFromLocalStorage('user').organizations[0];
    const { data } = await api.get(
      `/organizations/${payload?.organization_id ? payload?.organization_id : org?.id}/network`,
      { params: payload.param },
    );
    dispatch(setPrizeDrawOrganizatitonNetwork({ data: data, merge: payload.merge }));
    if (payload.callFormTemplate) {
      await dispatch(getFormTemplate());
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationNetworksAgency = payload => async dispatch => {
  try {
    const org = getItemFromLocalStorage('user').organizations[0];
    const { data } = await api.get(
      `/organizations/${payload?.organization_id ? payload?.organization_id : org?.id}/network`,
      { params: payload.param },
    );
    dispatch(setPrizeDrawOrganizatitonNetworkAgency({ data: data, merge: payload.merge }));
    if (payload.callFormTemplate) {
      dispatch(getFormTemplate());
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationNetworksClient = payload => async dispatch => {
  try {
    const org = getItemFromLocalStorage('user').organizations[0];
    const { data } = await api.get(
      `/organizations/${payload?.organization_id ? payload?.organization_id : org?.id}/network`,
      { params: payload.param },
    );
    dispatch(setPrizeDrawOrganizatitonNetworkClient({ data: data, merge: payload.merge }));
    if (payload.callFormTemplate) {
      dispatch(getFormTemplate());
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationNetworksforSubOptions = payload => async dispatch => {
  try {
    const org = getItemFromLocalStorage('user').organizations[0];
    const { data } = await api.get(
      `/organizations/${payload?.organization_id ? payload?.organization_id : org?.id}/network`,
      { params: payload.param },
    );
    dispatch(
      setPrizeDrawOrganizatitonNetworkForAgency({
        data: data,
        merge: payload.merge,
        organization_id: payload?.organization_id,
      }),
    );
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMarketingCompanyUsers = payload => async dispatch => {
  try {
    const { data } = await api.get(`/users`, { params: payload.params });
    dispatch(
      setMarketingCompanyUsersData({
        data: data,
        merge: payload.merge,
        organization_id: payload?.params?.organization_id,
      }),
    );
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getFormTemplate = payload => async dispatch => {
  const { data } = await api.get(`/form_templates`);
  dispatch(setPrizeDrawFormTemplate(data));
};

export const AddOrganization = payload => async dispatch => {
  try {
    const { params, prizedrawid } = payload;
    const { data } = await api.post(`/prizedraws/${prizedrawid}/organizations`, { ...params });
    dispatch(getPrizeDrawOrganization({ prizedrawid: prizedrawid }))
      .then(response => {
        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removePrizedrawOrganization = payload => async dispatch => {
  try {
    const { orgID, prizedrawid, isMarketingOrganization, parentOrgID } = payload;
    const { data } = await api.delete(`/prizedraws/${prizedrawid}/organizations/${orgID}`);
    dispatch(removePrizeDrawOrganization({ orgID: orgID, isMarketingOrganization, parentOrgID: parentOrgID }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteClient = payload => async dispatch => {
  try {
    const { prizeDrawId, orgID, isClient } = payload;
    const { data } = await api.delete(`/prizedraws/${prizeDrawId}/clients/${orgID}`);
    dispatch(removePrizeDrawClients({ orgID: orgID, isClient, parentOrgID: prizeDrawId }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getFormList = payload => async dispatch => {
  const { search, page } = payload.params;
  const url = `/prizedraws/forms?search=${search ? search : ''}&page=${page ? page : 0}&size=13${
    payload?.url && `${payload?.url}`
  }`;
  const { data } = await api.get(url);
  dispatch(setFormList(data));
  Promise.resolve(data);
};

export const getFormType = payload => async dispatch => {
  const { data } = await api.get(`/form_types`, { params: payload.param });
  dispatch(setFormType(data));
};

export const getClientDrawCode = payload => async dispatch => {
  const { data } = await api.get(`/prizedraw_clients`, { params: payload.params });
  const updatedData = data.map(item => {
    return { ...item, name: item.prizedraw_client_code };
  });
  dispatch(setClientDrawCode(updatedData));
};

export const getPrizeDrawClient = payload => async dispatch => {
  const { params, prizeDrawId } = payload;
  const { data } = await api.get(`/prizedraws/${prizeDrawId}/clients`, { params: params });
  const updatedData = data?.map(item => {
    return { ...item, name: item?.prizedraw_client_code };
  });
  dispatch(setPrizeDrawClients(updatedData));
};

export const prizeDrawForm = payload => async dispatch => {
  try {
    const { data } = await api.post(`/prizedraws/forms`, { ...payload });
    Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addClients = payload => async dispatch => {
  try {
    const { prizedrawid, params, formTemplates } = payload;
    const { data } = await api.post(`/prizedraws/${prizedrawid}/clients`, { ...params });
    dispatch(getPrizeDrawClient({ prizeDrawId: prizedrawid }));
    if (formTemplates.length > 0) {
      (formTemplates || [])?.map(formTemplate =>
        dispatch(
          prizeDrawForm({
            prizedraw: { id: prizedrawid },
            prizedraw_client: { id: data.id },
            template: { id: formTemplate?.id },
          }),
        ),
      );
    }
    Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createForm = payload => async dispatch => {
  try {
    const { request, pageNumber } = payload;
    const { data } = await api.post(`/prizedraws/forms`, { ...request });
    dispatch(getFormList({ params: { page: pageNumber }, url: '' }))
      .then(response => {
        return Promise.resolve(data);
      })
      .catch(err => {
        return Promise.resolve(err);
      });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPrizeDraw = payload => async () => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/prizedraws`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrizeDraw = payload => async () => {
  try {
    const { request, prizeDrawId } = payload;
    const { data } = await api.put(`/prizedraws/${prizeDrawId}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const prizeDrawEntrantDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}`);
    dispatch(setPrizeDrawEntrantDetails(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantOverviewPayment = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/payments`);
    dispatch(setPrizeDrawEntrantOverviewDetails(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantNotes = payload => async dispatch => {
  try {
    const { entrant_id, params, merge } = payload;
    const { data } = await api.get(`/entrants/${entrant_id}/notes`, { params: params });
    dispatch(setPrizeDrawEntrantNotesDetails({ data: data, merge: merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(err);
  }
};

export const addPrizeDrawEntrantNotes = payload => async dispatch => {
  try {
    const { entrant_id, request } = payload;
    const { data } = await api.post(`/entrants/${entrant_id}/notes`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(err);
  }
};

export const editPrizeDrawEntrantNotes = payload => async dispatch => {
  try {
    const { entrant_id, request, entrant_note_id } = payload;
    const { data } = await api.put(`/entrants/${entrant_id}/notes/${entrant_note_id}`, request);
    dispatch(setUpdatedEntrantNotes(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const prizeDrawEntrantOverviewWinning = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/winnings`);
    dispatch(setPrizeDrawEntrantOverviewWinningDetails(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantOverviewPaymentMethods = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/payment_methods`);
    dispatch(setPrizeDrawEntrantOverviewPaymentMethodsDetails(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPrizeDrawEntrantCancelReasons = payload => async dispatch => {
  try {
    const { data } = await api.get(`/commerce/cancel_reasons`);
    dispatch(setPrizeDrawEntrantCancelReason(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(err);
  }
};

export const createCancelEntrant = payload => async dispatch => {
  try {
    const { request, entrant_id } = payload;
    const { data } = await api.post(`/entrants/${entrant_id}/cancel`, { ...request });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantOverviewProducts = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/product`);
    dispatch(setPrizeDrawEntrantOverviewWinningProducts(data));
    return Promise.resolve(data);
  } catch (err) {
    dispatch(setPrizeDrawEntrantOverviewWinningProducts([]));
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantEntryForm = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/form`);
    dispatch(setPrizeDrawEntrantEntryForm(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantEntryFundraising = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/marketing`);
    dispatch(setPrizeDrawEntrantEntryFundraising(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const PrizeDrawEntrantDownloadForm = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/submission_pdf`);
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getEventLogs = payload => async dispatch => {
  try {
    const { merge } = payload;
    let url = `/event_logs${payload?.url}`;
    const { data } = await api.get(url);
    dispatch(setEventLogs({ data: data, merge: merge }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const prizeDrawEntrantEntryreferrals = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/${payload}/referrals`);
    dispatch(setPrizeDrawEntrantEntryReferrals(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateContact = payload => async dispatch => {
  const { id, request, entrant_id } = payload;
  try {
    const { data } = await api.put(`/contacts/${id}`, {
      ...request,
    });
    dispatch(prizeDrawEntrantDetails(entrant_id));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getEventGroups = payload => async dispatch => {
  try {
    const { params } = payload;
    const { data } = await api.get(`/event_groups`, { params: params });
    dispatch(setEventGroups(data));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePhone = payload => async dispatch => {
  const { contact_id, contact_value_id, request } = payload;
  try {
    const { data } = await api.put(`/contacts/${contact_id}/phones/${contact_value_id}`, {
      ...request,
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSubEventGroups = payload => async dispatch => {
  try {
    const { params, id } = payload;
    const { data } = await api.get(`/event_groups/${id}`, { params: params });
    dispatch(setSubEventGroups({ data, id }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateEmail = payload => async dispatch => {
  const { contact_id, contact_value_id, request } = payload;
  try {
    const { data } = await api.put(`/contacts/${contact_id}/emails/${contact_value_id}`, {
      ...request,
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createPhone = payload => async dispatch => {
  const { contact_id, request } = payload;
  try {
    const { data } = await api.post(`/contacts/${contact_id}/phones`, {
      ...request,
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createEmail = payload => async dispatch => {
  const { contact_id, request } = payload;
  try {
    const { data } = await api.post(`/contacts/${contact_id}/emails`, {
      ...request,
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateContactAddress = payload => async dispatch => {
  try {
    const { contact_id, contact_address_id, request } = payload;
    const { data } = await api.put(`/contacts/${contact_id}/addresses/${contact_address_id}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContactAddress = payload => async dispatch => {
  try {
    const { contact_id, request } = payload;
    const { data } = await api.post(`/contacts/${contact_id}/addresses`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExportColumnsList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/export_columns`);
    dispatch(setExportColumnsList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const anonymiseEntrant = payload => async dispatch => {
  try {
    const { data } = await api.post(`/entrants/${payload}/anonymize`);
    dispatch(prizeDrawEntrantDetails(payload));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTemplateList = () => async dispatch => {
  try {
    const { data } = await api.get(`/templates`);
    dispatch(setTemplateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTemplate = payload => async dispatch => {
  try {
    const { request, templateId } = payload;
    const { data } = await api.put(`/templates/${templateId}`, request);
    dispatch(updateTemplateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTemplateApi = payload => async dispatch => {
  try {
    const { templateId } = payload;
    const { data } = await api.delete(`/templates/${templateId}`);
    dispatch(removeTemplate(templateId));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createTemplateApi = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/templates`, request);
    dispatch(addTemplateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportEntrantApi = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/entrants/export`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPrizedrawChecklist = payload => async dispatch => {
  try {
    const { prizedrawId } = payload;
    const { data } = await api.get(`/prizedraws/${prizedrawId}/checklists`);
    dispatch(setPrizedrawChecklist(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrizeDrawLifeCycle = payload => async () => {
  try {
    const { request, prizeDrawId } = payload;
    const { data } = await api.put(`/prizedraws/${prizeDrawId}/lifecycle`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTimezones = () => async dispatch => {
  try {
    const { data } = await api.get(`/timezones`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEntrant = payload => async dispatch => {
  try {
    const { search, sort_by, order_by, page, size } = payload?.params;
    const url = `/entrants?page=${page}&size=${size}&search=${search}&sort_by=${sort_by}&order_by=${
      order_by ? order_by : ''
    }${payload?.url && `&${payload?.url}`}`;
    const { data } = await api.get(url);
    dispatch(setHomeEntrant({ data, merge: payload.merge }));
    return Promise.resolve(data);
  } catch (error) {
    dispatch(
      addToast({
        error: true,
        text: 'Something went wrong',
      }),
    );
  }
};

export const getProductDetails = payload => async dispatch => {
  try {
    const { product_id, params } = payload;
    const { data } = await api.get(`/products/${product_id}`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormProductDetails = payload => async dispatch => {
  try {
    const { form_id } = payload;
    const { data } = await api.get(`/forms/${form_id}/products`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createEntrantSubscription = payload => async dispatch => {
  try {
    const { entrant_id, request } = payload;
    const { data } = await api.post(`/entrants/${entrant_id}/subscriptions`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadEntrantDetailsCSVFile = payload => async () => {
  try {
    const { entrant_id } = payload;
    const { data } = await api.get(`/entrants/export/${entrant_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {
  setPrizeDrawList,
  setPrizeDrawDetail,
  setPrizeDrawForm,
  setPrizeDrawEntrant,
  mergePrizeDrawForm,
  setUsersData,
  setOrganizationsData,
  setPrizeDrawConfig,
  setPrizeDrawOrganizations,
  setPrizeDrawSettings,
  setPrizeDrawOrganizationCategories,
  setPrizeDrawOrganizatitonNetwork,
  removePrizeDrawOrganization,
  setFormList,
  setFormType,
  setClientDrawCode,
  setPrizeDrawClients,
  removePrizeDrawClients,
  setPrizeDrawFormTemplate,
  setPrizeDrawEntrantDetails,
  setPrizeDrawEntrantOverviewDetails,
  setPrizeDrawEntrantNotesDetails,
  setUpdatedEntrantNotes,
  setPrizeDrawEntrantOverviewWinningDetails,
  setPrizeDrawEntrantOverviewPaymentMethodsDetails,
  setPrizeDrawEntrantCancelReason,
  setPrizeDrawEntrantOverviewWinningProducts,
  setPrizeDrawEntrantEntryForm,
  setPrizeDrawEntrantEntryFundraising,
  setEventLogs,
  setPrizeDrawEntrantEntryReferrals,
  setEventGroups,
  setSubEventGroups,
  setExportColumnsList,
  setTemplateList,
  updateTemplateList,
  removeTemplate,
  addTemplateList,
  setPrizedrawChecklist,
  setPrizeDrawOrganizatitonNetworkAgency,
  setPrizeDrawOrganizatitonNetworkClient,
  setPrizeDrawOrganizatitonNetworkForAgency,
  setMarketingCompanyUsersData,
  setHomeEntrant,
} = prizeDrawSlice.actions;
export default prizeDrawSlice.reducer;
