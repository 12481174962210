import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';
const EntrantExportTemplateDeleted = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const performedBy = getPerformedByFromLog(log);
  const { event_data, performed_by } = log;
  const { export_template } = event_data;
  const { t } = useTranslation();

  const getTitleText = () => {
    return (
      <span>
        <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">deleted entrant export template</span>
      </span>
    );
  };
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Entrant export template deleted'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle text={getTitleText()} showDetails={showDetails} setShowDetails={data => setShowDetails(data)} />
      {showDetails && (
        <>
          <LogContentBox
            title={'Template'}
            contentText={<p className="flex regular-text lighter-text font-12">{export_template?.name}</p>}
          />
        </>
      )}
    </div>
  );
};

export default EntrantExportTemplateDeleted;
