import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const AccountAccessed = ({ log = {} }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(true);
  const { event_data, performed_by, event_type } = log;
  const { organization } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const getitleText =
    event_type === 'ORGANIZATION_CREATED' ? (
      <>
        <span className="regular-text lighter-text font-12">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">{t('ADDED_AN_ACCOUNT')}</span>
      </>
    ) : (
      <>
        <span className="regular-text lighter-text font-12">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">{t('ACCESSED_ACCOUNT')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{organization?.code}</span>
      </>
    );

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={event_type === 'ORGANIZATION_CREATED' ? 'Account added' : 'Account accessed'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'Account'}
            contentText={
              <div className="flex items-center w-full">
                <p className="regular-text lighter-text font-12">{organization?.name}</p>
                <p className="medium-text lighter-text font-12 ml-8">{organization?.code}</p>
              </div>
            }
          />
          <LogContentBox
            title={'Group'}
            contentText={<p className="regular-text lighter-text font-12">{organization?.category?.name}</p>}
          />
        </>
      )}
    </div>
  );
};

export default AccountAccessed;
