import styled from 'styled-components';

export const IntegrationsListWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  overflow: scroll;
`;

export const IntegrationsListItemWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 230px) minmax(250px, 1fr) minmax(220px, 250px) minmax(200px, 230px)
    minmax(40px, 40px);
  min-height: 76px;
  max-height: 76px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  padding: 8px 16px;

  &:hover {
    background: #f5f9fc80;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      grid-template-columns: minmax(160px, 190px) minmax(210px, 1fr) minmax(180px, 210px) minmax(160px, 190px) minmax(30px, 30px);
    }

    ${up(breakpoints.xl)} {
      grid-template-columns: minmax(240px, 270px) minmax(290px, 1fr) minmax(260px, 290px) minmax(240px, 270px) minmax(50px, 50px);
    }
  `}
`;
