import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as EmptyImage } from '../../assets/images/subtract.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { FormListItemWrapper } from '../../styles/components/form-list/form-list.styled';
import Menu from '../common/menu';
import Status from '../common/status';

const FormListItem = ({ form }) => {
  const { hasPermission } = useContext(OrganisationContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToFormDetails = () => {
    if (hasPermission('FORMS_VIEW')) {
      navigate(`/prize-draws/forms/${form.id}/form-log`);
    }
  };

  const openForm = () => {
    window.open(`https://forms.${process.env.REACT_APP_BASE_DOMAIN}/#/forms/${form.id}`, '_blank');
  };

  return (
    <FormListItemWrapper className="w-full cursor py-2 px-4" onClick={() => goToFormDetails()}>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <label className="medium-text one-line">{form?.name}</label>
      </div>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <label className="regular-text grey-text two-lines">{form?.description}</label>
      </div>
      <div className="border-right flex-row items-center px-6 overflow-hidden">
        {form?.prizedraw && (
          <>
            {form?.prizedraw?.image?.url ? (
              <img
                src={form?.prizedraw?.image?.url}
                alt="prize-draw-icon"
                className="mr-4 radius-3"
                height={40}
                width={40}
              />
            ) : (
              <div className="flex items-center justify-center radius-3 empty-img-content mr-4">
                <EmptyImage height={32} width={32} />
              </div>
            )}
            <div className="flex-column overflow-hidden">
              <label className="regular-text one-line">{form?.prizedraw?.name}</label>
              <label className="medium-text lighter-text font-12 one-line">
                {form?.prizedraw_client?.organization?.name}
              </label>
            </div>
          </>
        )}
      </div>
      <div className="border-right flex items-center px-6">
        <div className="flex items-center justify-center flex-1 mr-2">
          {form?.form_type?.name && (
            <div className="flex items-center justify-start wrap">
              <Status
                dotColor={form?.form_type?.is_custom ? theme.colors.ZenBlue : theme.colors.ZenOrange}
                withDot
                withDottedBorder
                status={'FORM_TYPE_LABEL'}
                statusText={form?.form_type?.name}
              />
            </div>
          )}
        </div>
      </div>
      <div className="medium-text flex items-center justify-between px-6 font-12">
        <Status withDot status={form.status} statusText={form.status === 'ACTIVE' ? 'Active' : 'Retired'} />
        <Menu
          menuList={[
            {
              name: t('DETAILS'),
              onClick: () => goToFormDetails(),
              permission: 'FORMS_VIEW',
            },
          ].filter(menuItem => (menuItem.permission ? hasPermission(menuItem.permission) : true))}
          ExtraOptions={
            form.status === 'ACTIVE' ? (
              <div
                className={`flex items-center regular-text justify-between ${
                  hasPermission('FORMS_VIEW') ? 'border-top mt-2' : ''
                } `}
                onClick={() => openForm()}>
                <div className="mt-2 px-2 pt-2 pb-2 option-hover w-full">{t('LAUNCH_FORM')}</div>
              </div>
            ) : null
          }
        />
      </div>
    </FormListItemWrapper>
  );
};

export default FormListItem;
