import styled from 'styled-components';

export const FeesDetailsWrapper = styled.div`
  .content-grid {
    display: grid;
    grid-template-columns:
      minmax(230px, 230px) minmax(220px, 1fr) minmax(120px, 120px) minmax(115px, 115px) minmax(150px, 1fr)
      minmax(140px, 140px) minmax(150px, 150px) 60px;
  }
  .content-header {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .content-body {
    min-height: 45px;
    padding: 8px 0;

    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }
`;
