import React from 'react';
import { ReactComponent as HamburgerIcon } from '../../../assets/images/burger.svg';
import { AccountDetailsHeaderWrapper } from '../../../styles/components/group-details/group-detail.styled';
import Menu from '../../common/menu';

const AccountDetailsHeader = ({ title = '', titleContent = <></>, actionContent = <></> }) => {
  return (
    <AccountDetailsHeaderWrapper className="items-center justify-between border-bottom pl-6 py-4 pr-4 w-full">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text font-20 mr-4">{title}</label>
        {titleContent}
      </div>
      {actionContent}
      <Menu Icon={HamburgerIcon} menuList={[]} tooltipContent={'Menu'} />
    </AccountDetailsHeaderWrapper>
  );
};

export default AccountDetailsHeader;
