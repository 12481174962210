import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { monthOptions, weekDayOptions } from '../../../../constant/optionData';
import { capitalize, formatText, getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const DrawConfigUpdate = ({ log = {} }) => {
  const {
    event_data: {
      previous: { prizedraw: prevPrizedraw },
      prizedraw,
    },
  } = log || {};

  const performedBy = getPerformedByFromLog(log);

  const { draw_config: prevDrawConfig } = prevPrizedraw || {};
  const { draw_config } = prizedraw || {};
  const [updatedData, setUpdatedData] = useState({});
  const [showDetails, setShowDetails] = useState(true);
  const { t } = useTranslation();

  const intervalDataFormat = data => {
    const { interval_type, intervals, day_of_week, week_of_month, day_of_month, month } = data || {};
    if (!interval_type) {
      return null;
    }
    if (interval_type === 'WEEKLY') {
      return `Every ${intervals || ''} week on ${weekDayOptions.find(a => a.value === day_of_week)?.name || ''}`;
    }
    if (interval_type === 'MONTHLY') {
      return `The ${week_of_month || ''} ${
        weekDayOptions.find(a => a.value === day_of_week)?.name || ''
      } of every ${intervals} month`;
    }
    if (interval_type === 'YEARLY') {
      if (day_of_month) {
        return `On ${
          monthOptions.find(a => a.value === month)?.name || ''
        } ${day_of_month} for every ${intervals} years`;
      } else {
        return `On the ${week_of_month || ''} ${weekDayOptions.find(a => a.value === day_of_week)?.name || ''} of ${
          monthOptions.find(a => a.value === month)?.name || ''
        } for every ${intervals} years`;
      }
    }
  };
  const setDetails = () => {
    let details = {};
    if (draw_config?.timezone !== prevDrawConfig?.timezone) {
      details = { updatedText: 'time zone', fromText: prevDrawConfig?.timezone, toText: draw_config?.timezone };
    } else if (draw_config?.start !== prevDrawConfig?.start) {
      details = {
        updatedText: 'start date & time',
        fromText: moment.unix(prevDrawConfig?.start).format('dddd MM/DD/YYYY h:mm A'),
        toText: moment.unix(draw_config?.start).format('dddd MM/DD/YYYY h:mm A'),
      };
    } else if (draw_config?.last_entrance_date !== prevDrawConfig?.last_entrance_date) {
      details = {
        updatedText: 'entrance parameters',
        fromText: moment.unix(prevDrawConfig?.last_entrance_date).format('dddd MM/DD/YYYY h:mm A'),
        toText: moment.unix(draw_config?.last_entrance_date).format('dddd MM/DD/YYYY h:mm A'),
      };
    } else if (draw_config?.draw_date !== prevDrawConfig?.draw_date) {
      details = {
        updatedText: 'winner date generation',
        fromText: moment.unix(prevDrawConfig?.draw_date).format('dddd MM/DD/YYYY h:mm A'),
        toText: moment.unix(draw_config?.draw_date).format('dddd MM/DD/YYYY h:mm A'),
      };
    } else if (draw_config?.recurring_config?.interval_type !== prevDrawConfig?.recurring_config?.interval_type) {
      details = {
        updatedText: 'recurrence interval',
        fromText: capitalize(formatText(prevDrawConfig?.recurring_config?.interval_type, '')),
        toText: capitalize(formatText(draw_config?.recurring_config?.interval_type, '')),
      };
    } else if (
      draw_config?.recurring_config?.intervals !== prevDrawConfig?.recurring_config?.intervals ||
      draw_config?.recurring_config?.day_of_week !== prevDrawConfig?.recurring_config?.day_of_week ||
      draw_config?.recurring_config?.week_of_month !== prevDrawConfig?.recurring_config?.week_of_month ||
      draw_config?.recurring_config?.month !== prevDrawConfig?.recurring_config?.month ||
      draw_config?.recurring_config?.day_of_month !== prevDrawConfig?.recurring_config?.day_of_month
    ) {
      details = {
        updatedText: 'recurrence pattern',
        fromText: intervalDataFormat(prevDrawConfig?.recurring_config),
        toText: intervalDataFormat(draw_config?.recurring_config),
      };
    } else if (
      draw_config?.recurring_config?.end_of_entry_days !== prevDrawConfig?.recurring_config?.end_of_entry_days
    ) {
      details = {
        updatedText: 'end of entry period',
        fromText:
          `${prevDrawConfig?.recurring_config?.end_of_entry_days} day${
            prevDrawConfig?.recurring_config?.end_of_entry_days > 1 ? 's' : ''
          } before the draw` || '',
        toText:
          `${draw_config?.recurring_config?.end_of_entry_days} day${
            draw_config?.recurring_config?.end_of_entry_days > 1 ? 's' : ''
          } before the draw` || '',
      };
    } else if (draw_config?.recurring_config?.time_of_draw !== prevDrawConfig?.recurring_config?.time_of_draw) {
      details = {
        updatedText: 'time of draw',
        fromText:
          moment()
            .set('hour', prevDrawConfig?.recurring_config?.time_of_draw?.[0])
            .set('minutes', prevDrawConfig?.recurring_config?.time_of_draw?.[1])
            .format('hh:mm A') || '',
        toText:
          moment()
            .set('hour', draw_config?.recurring_config?.time_of_draw?.[0])
            .set('minutes', draw_config?.recurring_config?.time_of_draw?.[1])
            .format('hh:mm A') || '',
      };
    }
    setUpdatedData(details);
  };
  const getTitleText = () => {
    if (draw_config?.is_recurring !== prevDrawConfig?.is_recurring) {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">{`${
            draw_config?.is_recurring === true ? 'enabled' : 'disabled'
          } Prize Draw`}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('MAKE_RECURRING')}</span>
        </span>
      );
    } else {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('UPDATED_PRIZE_DRAW')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          <span className="regular-text lighter-text font-12 ml-1">{updatedData?.updatedText || ''}</span>
        </span>
      );
    }
  };

  useEffect(() => {
    setDetails();
  }, []);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Draw configuration updated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={getTitleText()}
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
        showDownIcon={draw_config?.is_recurring !== prevDrawConfig?.is_recurring ? false : true}
      />
      {showDetails && draw_config?.is_recurring === prevDrawConfig?.is_recurring && (
        <>
          <LogContentBox
            title={'From'}
            contentText={<p className="regular-text lighter-text font-12">{updatedData?.fromText || ''}</p>}
          />
          <LogContentBox
            title={'To'}
            contentText={<p className="regular-text lighter-text font-12">{updatedData?.toText || ''}</p>}
          />
        </>
      )}
    </div>
  );
};

export default DrawConfigUpdate;
