import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import HomeDetails from '../../components/home-details';
import PageHeader from '../../components/page-header';
import { getGlobalStatistic } from '../../store/features/dashboardSlice';
import { HomeWrapper } from '../../styles/pages/home.styled';

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getGlobalStatistic()).catch(() => {});
  }, []);

  return (
    <HomeWrapper className="flex-column flex-1">
      <PageHeader title={t('HOME')} />
      <HomeDetails />
    </HomeWrapper>
  );
};

export default Home;
