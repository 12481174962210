import React from 'react';
import { ReactComponent as ConditonIcon } from '../../../../assets/images/condition.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/images/time.svg';
import { capitalize, formatText } from '../../../../helpers/utils';

const AutomationSaveDetails = ({ integrationDetails }) => {
  return (
    <div className="flex-column items-start justify-start w-full pxy-6">
      <div className="flex items-center justify-start w-full">
        <div className="flex items-center justify-center border icon-wrapper">
          <img src={`${integrationDetails?.integration?.connector?.icon?.active}`} style={{ width: 18, height: 18 }} />
        </div>
        <div className="flex-column items-start justify-start flex-1 ml-2">
          <label className="ml-2 medium-text font-14">{integrationDetails?.integration?.name}</label>
          <label className="ml-2 normal-text fw-400 grey-text font-12">
            {integrationDetails?.integration?.description}
          </label>
        </div>
      </div>
      {integrationDetails?.params?.length > 0 && (
        <div className="flex-column items-start justify-start w-full mt-4">
          {integrationDetails?.params?.map((param, index) => (
            <div className={`flex items-center justify-start w-full ${index === 0 ? '' : 'mt-2'}`} key={param.id}>
              <label className="regular-text lighter-text font-14">
                {capitalize(formatText(param.param_type, ' '))}: &nbsp;
              </label>
              <label className="regular-text font-14"> {param.display_value || param.param_value}</label>
            </div>
          ))}
        </div>
      )}
      {integrationDetails?.schedule?.offset &&
        integrationDetails?.schedule?.unit &&
        integrationDetails?.schedule?.field && (
          <div className="flex item-center justify-start mt-4 schedule-conditon-wrapper py-2 px-4 radius-2">
            <ClockIcon className="flex items-center justify-center" height={18} width={18} />
            <label className="normal-text ml-1">{`${Math.abs(integrationDetails?.schedule?.offset)}
                ${formatText(integrationDetails?.schedule?.unit, ' ')}
                ${integrationDetails?.schedule?.offset < 0 ? 'before' : 'after'}
                ${integrationDetails?.schedule?.field.toLowerCase().replaceAll('.', ' ')}`}</label>
          </div>
        )}
      <div className="flex-column items-start justify-start mt-2">
        {(integrationDetails?.conditions || []).map((condition, index) => {
          return (
            <div className="flex item-center justify-start mt-6 schedule-conditon-wrapper py-2 px-4 radius-2 my-1">
              <ConditonIcon className="flex items-center justify-center mr-2" height={18} width={18} />
              <label className="flex normal-text font-14">
                {index == 0 ? 'When' : 'And'} {condition?.field} =
              </label>
              <label className="flex normal-text fw-500 font-14 ml-1">{condition?.value}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AutomationSaveDetails;
