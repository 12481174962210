import styled from 'styled-components';

export const AutomationDetailsComponentWrapper = styled.div`
  overflow: hidden;
`;

export const AutomationDetailsLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .hover-edit {
    .edit-icon {
      display: none;
    }

    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      margin-top: 0px;
      margin-bottom: -4px;

      .edit-icon {
        display: flex;
      }

      .hover-margin {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .hover-margin-bottom {
        margin-bottom: 4px;
      }
    }
  }
  .update-action {
    margin-top: 4px;
  }
  .name-input {
    height: 40px;
  }

  .description-input {
    font-size: 14px;
  }

  .user-dropdown {
    max-height: 40px;
    border-radius: 16px;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.divider};

    &:hover {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
  }
  .selected {
    background: ${({ theme }) => theme.colors.backgroundColor};
    .option-text {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .user-icon {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.backgroundColor};
    padding: 8px;
    border-radius: 50%;
    span {
      color: ${({ theme }) => theme.colors.zenGray};
    }
  }
  .display-none {
    display: none;
  }
  .menu-wrapper {
    border-radius: 16px;
    padding: 4px 8px;
  }
  .option-wrapper {
    cursor: pointer;
    min-height: 40px;
    max-height: 40px;
    border-radius: 8px;
    pointer: cursor;
    &:hover {
      background: ${({ theme }) => theme.colors.backgroundColor};

      .option-text {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }
`;

export const AutomationDetailsRightWrapper = styled.div``;

export const AutomationDetailsHeaderWrapper = styled.div``;

export const ConfigurationWrapper = styled.div`
  .vertical-line {
    height: 24px;
    border-color: ${({ theme }) => theme.colors.ZenGray2};
  }

  .footer-btn {
    z-index: 1;
    background: ${({ theme }) => theme.colors.ZenPurpleSecondary2};
    border: 1px solid ${({ theme }) => theme.colors.ZenPurpleSecondary2};
    height: 40px;
    padding: 10px auto;
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
    &::before {
      content: '';
      top: -24px;
      content: '';
      left: 20px;
      height: 24px;
      background: ${({ theme }) => theme.colors.divider};
      positon: relative;
      position: absolute;
      width: 1px;
      z-index: 1;
    }
  }
  .automation-trigger-wrapper {
    .header-border-left {
      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 100px;
        background: ${({ theme }) => theme.colors.ZenBlue};
      }
    }
    .sub-header-border {
      width: calc(100% - 24px);
    }
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.ZenGray2};
    }
  }
  .automation-action-wrapper {
    z-index: 2;
    .action-header-wrapper {
      .edit-delete-btn-wrapper {
        display: none;
      }
      :hover {
        .edit-delete-btn-wrapper {
          display: flex;
        }
      }
    }
    &::before {
      content: '';
      top: -24px;
      content: '';
      left: 20px;
      height: 24px;
      background: ${({ theme }) => theme.colors.divider};
      positon: relative;
      position: absolute;
      width: 1px;
      z-index: 1;
    }

    .header-border-left {
      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 100px;
        background: ${({ theme }) => theme.colors.ZenOrange};
      }
    }

    .action-container {
      width: calc(100% - 24px);
    }

    .icon-wrapper {
      background: ${({ theme }) => theme.colors.navBackground};
      border-radius: 6px;
      padding: 3px;
    }

    .sub-header-border {
      width: calc(100% - 24px);
    }

    .schedule-conditon-wrapper {
      background: ${({ theme }) => theme.colors.backgroundColor};
    }

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.ZenGray2};
    }

    .edit-integration-wrapper,
    .edit-integration-param-wrapper {
      .edit-integration-icon {
        display: none;
      }
      :hover {
        box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
        border-radius: 8px;

        margin: -4px -8px;
        padding: 4px 8px;

        .edit-integration-icon {
          display: flex;
        }
      }
    }
  }

  .mapping-data-wrapper {
    .data-wrapper {
      display: grid;
      grid-template-columns: 16px 1fr;
    }
  }
`;

export const LogsWrapper = styled.div`
  .header-container,
  .item-container {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(200px, 200px) minmax(150px, 150px) 40px;
  }

  .header-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    height: 40px;
  }

  .item-container {
    height: 40px;
  }

  .automation-log-hover {
    &:hover {
      background-color: ${({ theme }) => theme.colors.hoverColor};
    }
  }
`;

export const AutomationTriggerWrapper = styled.div`
  .grid-trigger {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }

  .input-search {
    .input {
      width: 310px;
    }
  }
  .edit-delete-btn-wrapper {
    display: none;
  }
  :hover {
    .edit-delete-btn-wrapper {
      display: flex;
    }
  }

  .selected {
    border-color: ${({ theme }) => theme.colors.zenPurple};
  }

  .update-trigger {
    bottom: -24px;
  }
`;

export const AutomationLogActionWrapper = styled.div`
  .selected {
    border-color: ${({ theme }) => theme.colors.zenPurple};
  }
  .edit-delete-btn-wrapper {
    display: none;
  }
  :hover {
    .edit-delete-btn-wrapper {
      display: flex;
    }
  }
  .grid-trigger {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }
  .input-search {
    .input {
      width: 310px;
    }
  }
  .update-action {
    bottom: -48px;
  }
`;

export const AutomationIntegrationDetails = styled.div`
  z-index: 100;
  .name-input {
    height: 32px;
    width: 80px;
    border-radius: 12px;
  }

  .provider-selector {
    z-index: 3 !important;
    .organisations-border {
      height: 32px;
      border-radius: 12px;
    }
    .mc-options {
      top: 32px;
      z-index: 3 !important;
    }
  }

  .condition-selector {
    width: 220px;
    .organisations-border {
      height: 32px;
      border-radius: 12px;
    }
    .mc-options {
      top: 32px;
      z-index: 3;
    }
  }

  .operator-selector {
    width: 80px;
    .organisations-border {
      height: 32px;
      border-radius: 12px;
    }
    .mc-options {
      top: 32px;
      z-index: 3;
    }
  }

  .update-action-container {
    position: relative;
    top: 4px;
  }

  .name-input-form {
    height: 40px;
    border-radius: 12px;
  }
`;
