import React from 'react';
import { useSelector } from 'react-redux';
import { PrizeDrawListWrapper } from '../../styles/components/prize-draw-list/prize-draw-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import PrizeDrawItem from './prize-draw-item';

const PrizeDrawList = ({ debouncedSearch }) => {
  const { prizeDrawList } = useSelector(state => state.prizeDraw);

  return (
    <PrizeDrawListWrapper className="flex-column flex-1">
      {prizeDrawList.content?.length > 0 ? (
        prizeDrawList.content?.map(item => <PrizeDrawItem item={item} key={item.id} />)
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </PrizeDrawListWrapper>
  );
};

export default PrizeDrawList;
