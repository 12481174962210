import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { setItemInLocalStorage } from '../../../helpers/localstorage';
import { getContactValue } from '../../../helpers/utils';
import { PrizeDrawClientsItemWrapper } from '../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Menu from '../../common/menu';

const PrizeDrawClientsItem = ({ prizeDrawClient, refreshData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { organization, prizedraw_client_code, id: prizeDrawClientId } = prizeDrawClient;
  const { hasPermission, setModal, checkPermission } = useContext(OrganisationContext);

  const handleRemoveClient = () => {
    setModal({
      type: 'remove-prizedraw-clients',
      content: {
        prizedrawid: id,
        title: 'Are you sure you want to remove this client?',
        subTitle: 'Removing a client will result in all connecting entry forms to be retired.',
        clientData: organization,
        prizeDrawClientId: prizeDrawClientId,
        onSuccess: () => refreshData(),
      },
    });
  };

  const handleFeesClick = () => {
    setItemInLocalStorage('fees-client-data', JSON.stringify(organization));
    navigate(`/billings/fees`);
  };

  return (
    <PrizeDrawClientsItemWrapper
      className={`flex-column items-start flex-start w-full cursor`}
      onClick={() =>
        checkPermission(() => navigate(`account-details/${organization?.id}/prize-draw-access`), 'ORGANIZATIONS_VIEW')
      }>
      <div className="list-container-3-col w-full border-bottom">
        <div className="border-right flex-column items-start px-6 overflow-hidden justify-center w-full">
          <label className="regular-text font-14 one-line">{organization?.name}</label>
          <div className="flex flex-row pt-2">
            <div className="flex items-center justify-center mr-2 font-14 lighter-text">{prizedraw_client_code}</div>
            <div className="flex items-center justify-center status-container">
              <label className="regular-text one-line lighter-text font-10">
                {organization?.organization_category?.name}
              </label>
            </div>
          </div>
        </div>
        <div className="border-right flex-column items-start px-6 justify-center w-full">
          <div className="flex items-center justify-start w-full">
            <div className="flex items-center justify-center icon-wrapper radius-2">
              <MailIcon className="flex items-center justify-center " height={16} width={16} />
            </div>
            <label
              className={`regular-text one-line font-14 ml-2 ${
                getContactValue(organization?.contacts, 'EMAIL') === 'Not provided' ? 'grey-text' : ''
              }`}>
              {getContactValue(organization?.contacts, 'EMAIL')}
            </label>
          </div>
          <div className="flex items-center justify-start w-full mt-1">
            <div className="flex items-center justify-center icon-wrapper radius-2">
              <MobileIcon className="flex items-center justify-center " height={16} width={16} />
            </div>
            <label
              className={`regular-text one-line font-14 ml-2 ${
                getContactValue(organization?.contacts, 'MOBILE') === 'Not provided' ? 'grey-text' : ''
              }`}>
              {getContactValue(organization?.contacts, 'MOBILE')}
            </label>
          </div>
        </div>
        <div className="flex items-center px-6 justify-between w-full">
          <label className="regular-text font-14 flex-1 text-overflow-2 two-lines">
            {organization?.primary_location?.formatted_address}
          </label>
          <Menu
            menuClassName="w-auto h-full flex items-center"
            menuList={[
              {
                name: t('DETAILS'),
                onClick: () => navigate(`account-details/${organization?.id}/prize-draw-access`),
                permission: 'ORGANIZATIONS_VIEW',
              },
              { name: t('FEES'), onClick: () => handleFeesClick() },
              hasPermission('PRIZEDRAW_CLIENTS_MANAGE') && {
                name: t('REMOVE'),
                onClick: () => handleRemoveClient(),
              },
            ].filter(Boolean)}
          />
        </div>
      </div>
    </PrizeDrawClientsItemWrapper>
  );
};

export default PrizeDrawClientsItem;
