import styled from 'styled-components';

export const HomeDetailWrapper = styled.div`
  overflow: hidden;
`;

export const HomeDetailLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .prizedraw-item-wrapper {
    display: grid;
    grid-template-columns: minmax(45px, 45px) 1fr minmax(110px, 110px);
    border: 1px solid ${({ theme }) => theme.colors.divider};
  }

  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      height: calc(100% + 1px) !important;
    }
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      min-width: 320px;
      max-width: 320px;
      transition: width 400ms linear;

    }

    ${up(breakpoints.xl)} {
      min-width: 400px;
      max-width: 400px;
      transition: width 400ms linear;
    }
  `}

  .img-data-wrapper {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
  }
  .img-container {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
  }
  .empty-img-content {
    height: 40px;
    width: 40px;
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
  }
`;

export const HomeDetailRightWrapper = styled.div`
  border-radius: 16px;
`;

export const HomeEntrantsWrapper = styled.div`
  .header-container {
    height: 64px;
  }

  .prize-draw-entrant-filter {
    left: -115px;
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% + 1px) !important;
    }
  }

  .filter {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
  }

  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.zenPurple};
      fill: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .content-header {
    display: grid;
    grid-template-columns:
      minmax(120px, 1fr) minmax(120px, 1fr) minmax(135px, 135px) minmax(100px, 110px) minmax(100px, 1fr)
      minmax(95px, 95px) minmax(135px, 135px);
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .content-body {
    display: grid;
    grid-template-columns:
      minmax(120px, 1fr) minmax(120px, 1fr) minmax(135px, 135px) minmax(100px, 110px) minmax(100px, 1fr)
      minmax(95px, 95px) minmax(135px, 135px);

    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }

  .tooltip-content {
    padding: 6px 8px;
    border-radius: 8px;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      .content-body,
      .content-header {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(115px, 115px) minmax(115px, 115px) minmax(115px, 115px);
      }
      .input-search {
        .input {
          width: 180px;
        }
      }
    }

    ${up(breakpoints.xl)} {
      .content-body,
      .content-header {
        grid-template-columns: 1fr 1fr minmax(150px, 1fr) minmax(150px, 1fr) 1fr 1fr minmax(180px, 180px);
      }
    }
  `}

  .count-box {
    background: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.LighterText};
    height: 24px;
    border-radius: 100px;
  }
`;
