import React from 'react';
import DashboardComponent from '../../components/Dashboard/index';

const Dashboard = ({ expanded }) => {
  return (
    <div
      className="flex flex-1"
      style={{ maxHeight: '100dvh', maxWidth: '100dvw', width: expanded ? '100vh' : 'auto' }}>
      <DashboardComponent expanded={expanded} />
    </div>
  );
};

export default Dashboard;
