import { Circle } from 'rc-progress';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as WhiteCheckedIcon } from '../assets/images/checkbox-base-round.svg';
import { ReactComponent as DropdownIcon } from '../assets/images/dropdown.svg';
import { ReactComponent as CheckIcon } from '../assets/images/green-check.svg';
import { ReactComponent as CloseIcon } from '../assets/images/red-close.svg';
import { ReactComponent as EmptyImage } from '../assets/images/subtract.svg';
import { ReactComponent as LoaderIcon } from '../assets/images/task-marker.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { camelCase, capitalize, formatText } from '../helpers/utils';
import { getPrizedrawChecklist, updatePrizeDrawLifeCycle } from '../store/features/prizeDrawSlice';
import CommonPopup from './common-popup';
import { ActivatePrizedrawWrapper } from './modal.styled';

const ActivatePrizedraw = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setModal, modal } = useContext(OrganisationContext);
  const { isDeActivated, isActivate, confirmTitle, handleSuccess, prizeDrawDetail } = modal?.content || {};
  const { prizedrawChecklist } = useSelector(state => state.prizeDraw);
  const [showCheckListDetails, setShowCheckListDetails] = useState('');
  const [currentState, setCurrenState] = useState(isActivate ? 'TASK' : 'CONFIRMATION');
  const theme = useTheme();

  const fetchPrizedrawChecklist = () => {
    setLoading(true);
    dispatch(getPrizedrawChecklist({ prizedrawId: prizeDrawDetail?.id }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isActivate) fetchPrizedrawChecklist();
  }, []);
  const handleSetDetails = key => {
    setShowCheckListDetails(key === showCheckListDetails ? '' : key);
  };
  const getProgressIcon = data => {
    const completeTask = (Object.values(data) || []).filter(item => item === true).length;
    const totalTask = (Object.values(data) || []).length;
    const ProgressPercentage = (completeTask * 100) / totalTask;
    if (totalTask === completeTask) {
      return <WhiteCheckedIcon className="flex items-center justify-center" height={16} width={16} />;
    } else if (completeTask === 0) {
      return <LoaderIcon className="flex items-center justify-center" height={16} width={16} />;
    } else {
      return (
        <div className="flex items-center justify-center" height={16} width={16}>
          <Circle
            percent={ProgressPercentage}
            strokeWidth={15}
            trailWidth={15}
            strokeColor={'#29CC97'}
            trailColor={'#F5F9FC'}
            className="circular-progress-bar"
          />
        </div>
      );
    }
  };
  const handleCompleteTask = selectedTask => {
    switch (selectedTask) {
      case 'basic_information':
      case 'prizedraw_logo': {
        setModal(initModal);
        break;
      }
      case 'general_config': {
        navigate(`/prize-draws/all/${prizeDrawDetail?.id}/draw-settings`);
        setModal(initModal);
        break;
      }
      case 'draw_config': {
        navigate(`/prize-draws/all/${prizeDrawDetail?.id}/draw-configuration`);
        setModal(initModal);
        break;
      }
      case 'client_added': {
        navigate(`/prize-draws/all/${prizeDrawDetail?.id}/clients`);
        setModal(initModal);
        break;
      }
      case 'entry_form_published': {
        navigate(`/prize-draws/all/${prizeDrawDetail?.id}/forms`);
        setModal(initModal);
        break;
      }
    }
  };
  const checklistItem = (parentKey, value) => {
    if (parentKey === 'total_percentage') {
      return;
    }
    return (
      <div className="flex-column px-6 py-3  row-gap-4 border radius-4">
        <div className="flex">
          <label className="medium-text flex-1">
            {parentKey ? capitalize(camelCase(formatText(parentKey, ' '))) : ''}
          </label>
          <div className="flex items-center col-gap-3">
            {getProgressIcon(value)}
            <label className="normal-text lighter-text font-12">{`${
              (Object.values(value) || []).filter(item => item === true).length
            }/${(Object.values(value) || []).length} Tasks`}</label>
            <DropdownIcon
              height={14}
              width={14}
              className={`flex cursor ${showCheckListDetails === parentKey ? 'rotate-180' : ''}`}
              color={theme.colors.LighterText}
              onClick={() => handleSetDetails(parentKey)}
            />
          </div>
        </div>
        {showCheckListDetails === parentKey && (
          <div className="flex-column w-full row-gap-2">
            {Object.entries(value).map(([key, values]) => (
              <div className="flex items-center col-gap-2">
                {values ? <CheckIcon /> : <CloseIcon />}
                <label className="normal-text">{capitalize(camelCase(formatText(key, ' ')))}</label>
                <label
                  className={`link_text medium-text cursor ${values ? 'disabled_link_text' : ''}`}
                  onClick={() => handleCompleteTask(key)}>
                  Here
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const getButtonDisability = () => {
    let isDisabled = false;
    if (prizedrawChecklist) {
      Object.entries(prizedrawChecklist).map(([key, values]) => {
        const val = values || {};
        if (key === 'total_percentage' || isDisabled) {
          return;
        }
        if (val) {
          Object.values(val).forEach(value => (isDisabled = !value));
        }
      });
    }
    return isDisabled || loading;
  };
  const taskUI = (
    <div className="flex-column row-gap-6 w-full">
      <div className="flex-column row-gap-1 w-full">
        <label className="normal-text font-12">{`${prizedrawChecklist?.total_percentage || 0}% complete`}</label>
        <div className="relative w-full flex items-center progress-bar-wrapper radius-4">
          <div
            className="progress-bar h-full radius-4"
            style={{ width: `${prizedrawChecklist?.total_percentage || 0}%` }}></div>
        </div>
      </div>
      <div className="flex-column row-gap-4 w-full">
        {prizedrawChecklist && Object.entries(prizedrawChecklist).map(([key, values]) => checklistItem(key, values))}
      </div>
    </div>
  );
  const confirmationUI = (
    <div className="flex-column row-gap-4 w-full pxy-4 radius-4 details-wrapper">
      <div className="flex items-start col-gap-2">
        {prizeDrawDetail?.image?.url ? (
          <img src={prizeDrawDetail?.image?.url} alt="prize-draw-icon" className="radius-4" height={40} width={40} />
        ) : (
          <div className="flex items-center justify-center radius-4 empty-img-content">
            <EmptyImage height={32} width={32} />
          </div>
        )}
        <div className="flex-column flex-1">
          <label className="semibold-text font-16 one-line">{prizeDrawDetail?.name}</label>
          <label className="regular-text lighter-text font-12 one-line">{prizeDrawDetail?.code}</label>
        </div>
      </div>
      <div className="flex-column row-gap-1 flex-1">
        <label className="regular-text grey-text ">{prizeDrawDetail?.description}</label>
      </div>
    </div>
  );
  const getContentPage = () => {
    switch (currentState) {
      case 'TASK':
        return taskUI;
      case 'CONFIRMATION':
        return confirmationUI;
    }
  };
  const handleActivateAndDeActivateButton = () => {
    setLoading(true);
    dispatch(
      updatePrizeDrawLifeCycle({
        prizeDrawId: prizeDrawDetail?.id,
        request: { lifecycle: isDeActivated ? 'INACTIVE' : 'ACTIVE' },
      }),
    )
      .then(() => {
        setLoading(false);
        if (handleSuccess) {
          handleSuccess();
        }
        setModal(initModal);
      })
      .catch(() => setLoading(false));
  };
  const handleSubmit = () => {
    switch (currentState) {
      case 'TASK': {
        setCurrenState('CONFIRMATION');
        break;
      }
      case 'CONFIRMATION': {
        handleActivateAndDeActivateButton();
        break;
      }
    }
  };
  return (
    <CommonPopup
      popupTitle={currentState === 'CONFIRMATION' ? confirmTitle : 'Tasks to complete'}
      confirmButtonProps={{
        label: currentState === 'CONFIRMATION' ? (isDeActivated ? 'Deactivate' : 'Activate') : 'Continue',
        className: currentState === 'CONFIRMATION' && isDeActivated ? 'negative' : 'primary',
      }}
      onCancel={() => setModal(initModal)}
      onConfirm={() => (isActivate ? handleSubmit() : handleActivateAndDeActivateButton())}
      disabled={isDeActivated ? loading : currentState === 'TASK' && isActivate ? getButtonDisability() : loading}
      onlyConfirmButtonDisable={currentState === 'TASK' && isActivate ? true : false}>
      <ActivatePrizedrawWrapper className="mt-6 flex-column items-center w-full">
        {getContentPage()}
      </ActivatePrizedrawWrapper>
    </CommonPopup>
  );
};

export default ActivatePrizedraw;
