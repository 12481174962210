import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {
    setEntrantPaymentStats: (state, { payload }) => {
      state.entrantPaymentStats = payload;
    },
    setGlobalStats: (state, { payload }) => {
      state.globalStats = payload;
    },
    setEnteredStats: (state, { payload }) => {
      state.enteredStats = payload;
    },
  },
});

export const getPaymentStatistic = payload => async dispatch => {
  try {
    const { data } = await api.get(
      `/entrants/statistics/payments${payload?.url ? `${payload?.url}&${payload?.params}` : `?${payload.params}`}`,
    );
    dispatch(setEntrantPaymentStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGlobalStatistic = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/statistics/global${payload?.url ? payload?.url : ''}`);
    dispatch(setGlobalStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEnteredStatistic = payload => async dispatch => {
  try {
    const { data } = await api.get(`/entrants/statistics/entered${payload?.url ? payload?.url : ''}`);
    dispatch(setEnteredStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setEntrantPaymentStats, setGlobalStats, setEnteredStats } = dashboardSlice.actions;
export default dashboardSlice.reducer;
