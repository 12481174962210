import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { getUserInitials, isDataChanged } from '../../../helpers/utils';
import { updateAutomationDetails } from '../../../store/features/automationsSlice';
import { AutomationDetailsLeftWrapper } from '../../../styles/components/automationDetails/automation-details.styled';
import Menu from '../../common/menu';
import Switch from '../../common/switch';
import UpdateAction from '../../common/update-action';

const Option = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`option-wrapper flex items-center justify-start w-full px-2 py-1 ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      {getUserInitials(data || {}) && (
        <div className="user-icon flex items-center justify-center mr-2">
          <span className="bold-text grey-text-2">{getUserInitials(data || {})}</span>
        </div>
      )}
      <div className="regular-text fw-400 font-16 option-text one-line">{data?.name}</div>
    </div>
  );
};

const singleValueComponent = props => {
  const {
    selectProps: { value, menuIsOpen },
  } = props;
  return (
    <div className={`flex items-center justify-start flex-1 w-full overflow-hidden ${menuIsOpen && 'display-none'}`}>
      <div className="user-icon flex items-center justify-center mr-2">
        <span className="bold-text grey-text-2">{getUserInitials(value || {})}</span>
      </div>
      <div className="regular-text fw-400 font-16 one-line">{value?.name}</div>
    </div>
  );
};

const AutomationDetailsLeft = ({ fetchUser = () => {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { setModal } = useContext(OrganisationContext);

  const { automationDetails, userList } = useSelector(state => state.automations);
  const [editField, setEditField] = useState({});
  const [error, setError] = useState({});

  const onCancelUpdateData = () => {
    setEditField({});
    setError({});
  };

  const onChangeEnable = () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automationDetails,
        isEnable: !automationDetails.is_enabled,
        title: automationDetails.is_enabled
          ? 'Are you sure you want to disable this automation?'
          : 'Would you like to enable this automation?',
      },
    });
  };

  const onUpdateData = (field, value) => {
    const { name, description, owner } = automationDetails;
    const request = { name: name, description: description, owner: { id: owner.id }, [field]: value };
    dispatch(updateAutomationDetails({ automationID: id, request: request }))
      .then(data => {
        onCancelUpdateData();
      })
      .catch(err => {});
  };

  const onDelete = () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automationDetails,
        title: 'Are you sure you want to delete this automation?',
        isDelete: true,
        onSuccess: () => navigate('/platforms/automations'),
      },
    });
  };

  const nameField =
    editField.type === 'NAME' ? (
      <div className="relative">
        <input
          autoComplete="turnoff"
          className={'input w-full name-input'}
          onChange={e => {
            setEditField({ ...editField, value: e.target.value });
            setError({ ...error, description: false });
          }}
          value={editField?.value}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              editField.value || '',
              automationDetails?.name || '',
              () => onUpdateData('name', editField.value),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className="flex items-center justify-start mt-1 w-full cursor hover-edit"
        onClick={() => setEditField({ type: 'NAME', value: automationDetails?.name })}>
        <label className="semibold-text font-16 one-line w-full hover-margin mr-10 break-word flex-1">
          {automationDetails?.name}
        </label>
        <EditIcon width={16} height={16} className="edit-icon mr-2" />
      </div>
    );

  const descriptionField =
    editField.type === 'DESCRIPTION' ? (
      <div className="relative">
        <textarea
          className={`textarea w-full description-input`}
          onChange={e => {
            setEditField({ ...editField, value: e.target.value });
            setError({ ...error, description: false });
          }}
          placeholder="Enter description here"
          rows={4}
          value={editField.value || ''}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              editField.value || '',
              automationDetails?.description || '',
              () => onUpdateData('description', editField.value),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className="flex items-start justify-start mt-1 w-full cursor hover-edit"
        onClick={() => setEditField({ type: 'DESCRIPTION', value: automationDetails?.description })}>
        <p className="regular-text w-full break-word mr-10 hover-margin flex-1">{automationDetails?.description}</p>
        <EditIcon width={16} height={16} className="flex edit-icon mr-2 mt-1" />
      </div>
    );

  const roleField =
    editField.type === 'OWNER' ? (
      <div className="relative">
        <Select
          options={userList?.content?.map(user => ({ ...user, value: user.id, label: user.name })) || []}
          placeholder={'Select owner'}
          onChange={e => {
            setEditField({ ...editField, value: e });
          }}
          isSearchable
          onInputChange={e => fetchUser(e)}
          value={editField?.value}
          menuPlacement={'top'}
          maxMenuHeight={150}
          components={{
            Option,
            IndicatorSeparator: props => {
              <components.IndicatorSeparator {...props} className="display-none" />;
            },
            Control: props => (
              <components.Control
                {...props}
                className={`user-dropdown flex items-center justify-start radius-4 border cursor"`}
              />
            ),
            Placeholder: props => <components.Placeholder {...props} className="regular-text grey-text" />,
            SingleValue: props => singleValueComponent(props),
            Input: props => (
              <components.Input
                {...props}
                autoFocus={true}
                className={`${!props.selectProps.menuIsOpen && props.hasValue && 'display-none'}`}
              />
            ),
            Menu: props => <components.Menu {...props} className="menu-wrapper absolute radius-4 pxy-2" />,
          }}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'DROPDOWN',
              editField.value || {},
              automationDetails?.owner || {},
              () => onUpdateData('owner', { id: editField.value.id }),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className="flex items-center justify-start wrap mt-1 w-full cursor hover-edit"
        onClick={() => setEditField({ type: 'OWNER', value: automationDetails?.owner })}>
        <p className=" regular-text flex-1 break-work mr-10 hover-margin">{automationDetails?.owner?.name}</p>
        <EditIcon width={16} height={16} className="edit-icon" />
      </div>
    );

  return (
    <AutomationDetailsLeftWrapper className="flex-column border">
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text fw-500">{t('AUTOMATION_DETAILS')}</label>
        <div className="flex items-center justify-start">
          <Menu menuList={[{ name: t('DELETE'), onClick: () => onDelete(), permission: 'AUTOMATIONS_MANAGE' }]} />
        </div>
      </div>
      <div className="py-6 pl-6 pr-4 flex-column flex-1 overflow-scroll">
        <div className="flex items-center">
          <div className="flex-column flex-1 w-full">
            <span className="regular-text grey-text font-12">{t('NAME')}</span>
            {nameField}
          </div>
        </div>
        <div className="flex-column mt-6">
          <span className="regular-text grey-text font-12">{t('DESCRIPTION')}</span>
          {descriptionField}
        </div>
        <div className="flex mt-6">
          <div className="flex-1 flex-column">
            <span className="regular-text grey-text font-12">{t('OWNER')}</span>
            {roleField}
          </div>
        </div>
        <div className="flex mt-6">
          <div className="flex-1 flex-column">
            <span className="regular-text grey-text font-12 mb-1">{t('STATUS')}</span>
            <div className="flex items-center justify-between w-full ">
              <p className=" regular-text w-full ">{automationDetails?.is_enabled ? t('ENABLED') : t('DISABLED')}</p>
              <Switch
                className="mr-2"
                onClick={() => onChangeEnable()}
                enabled={automationDetails?.is_enabled}
                disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </AutomationDetailsLeftWrapper>
  );
};

export default AutomationDetailsLeft;
