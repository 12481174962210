import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as CheckIcon } from '../assets/images/check-mark.svg';
import { ReactComponent as User } from '../assets/images/user.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { anonymiseEntrant, prizeDrawEntrantDetails } from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AnonymizeActionWrapper } from './modal.styled';

const Anonymize = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { title, subtitle, description, entrant_id } = modal.content || {};
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (count === 0) {
      setDisable(false);
      return;
    }
    const timer = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [count]);
  const onConfirmAction = () => {
    dispatch(anonymiseEntrant(entrant_id))
      .then(() => {
        dispatch(addToast({ error: false, text: 'Entrant has been anonymized' }));
        setModal(initModal);
        dispatch(prizeDrawEntrantDetails(entrant_id));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Entrant has not been anonymized' }));
      });
  };

  const counter = (
    <div className="semibold-text flex font-16 counter">
      {count !== 0 ? <div className="mr-1">{count}</div> : <CheckIcon />}
    </div>
  );
  return (
    <CommonPopup
      popupTitle={title}
      cancelButtonProps={{ label: 'No, go back!', className: 'primary-white' }}
      confirmButtonProps={{ label: 'Anonymize', className: 'negative', icon: counter }}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}
      disabled={disable}>
      <AnonymizeActionWrapper className="flex flex-column">
        <label className="error-text font-14 regular-text mt-2 text-center">{subtitle}</label>
        <div className="container mt-8 pxy-4 radius-3">
          <div className="flex gap-1 items-center">
            <User />
            <label className="semibold-text font-18">{t('ENTRANTS_NAME')}</label>
          </div>
          <div className="flex-column gap-1">
            <label className="regular-text ml-5 grey-text font-14">{description}</label>
          </div>
        </div>
      </AnonymizeActionWrapper>
    </CommonPopup>
  );
};

export default Anonymize;
