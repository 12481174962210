import React from 'react';
import { Chart } from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { stateOptions } from '../../constant/optionData';
function createCustomHTMLContent(formattedPercentage, count) {
  return `
    <p class="flex-column">
      <label class="flex regular-text zen-purple-text">Percentage: <span class="ml-1 bold-text zen-purple-text">${formattedPercentage}%</span></label>
      <label class="regular-text zen-purple-text">Entrant: <span class="bold-text zen-purple-text">${count}</span></label>
    </p>
    `;
}

const GeoMap = () => {
  const { globalStats } = useSelector(state => state.dashboard) || [];
  const data = globalStats?.state ? globalStats?.state?.filter(item => item?.key && item?.key !== 'UNKNOWN') : [];
  const { t } = useTranslation();
  const geoData = [['State', 'Entrants', { type: 'string', role: 'tooltip', p: { html: true } }]];

  data.forEach(({ key, percentage, count }) => {
    const formattedPercentage = percentage ? parseFloat(percentage).toFixed(2) : 0;
    const stateName = stateOptions.find(state => state.abbreviation === key)?.name || key;
    if (stateName) {
      geoData.push([stateName, count, createCustomHTMLContent(formattedPercentage, count)]);
    }
  });

  const options = {
    region: 'US',
    displayMode: 'regions',
    resolution: 'provinces',
    colorAxis: { colors: ['#F2E8FD', '#8927EF'] },
    datalessRegionColor: '#fff',
    defaultColor: '#8927EF',
    tooltip: {
      isHtml: true,
      textStyle: { color: '#8927EF', fontSize: 14, fontName: 'Poppins', textAlign: 'center', overflow: 'truncate' },
      trigger: 'hover',
    },
  };
  return (
    <div className={`border radius-4 pxy-4 flex-column flex-1 h-full  w-full`}>
      <label className="font-12 lighter-text regular-text">{t('ENTRANTS')}</label>
      <label className="font-20 medium-text">{t('HEATMAP')}</label>
      {data?.length > 0 && (
        <Chart
          chartEvents={[
            {
              eventName: 'select',
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 0) return;
                const region = data[selection[0].row + 1];
              },
            },
          ]}
          data={geoData}
          options={options}
          chartType="GeoChart"
          width={'100%'}
        />
      )}
    </div>
  );
};

export default GeoMap;
