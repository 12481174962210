import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DropDown from '../components/McDropdown';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText } from '../helpers/utils';
import { createGroupCategory, getGroupTag } from '../store/features/groupsSlice';
import { addToast } from '../store/features/toastSlice';
import { getUserRoles } from '../store/features/userManagementSlice';
import CommonPopup from './common-popup';
import { CreateGroupWrapper } from './modal.styled';

const CreateGroups = () => {
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const { userRoles } = useSelector(state => state.usersManagement);
  const { groupTags } = useSelector(state => state.groups);
  const [group, setGroup] = useState();
  const [error, setError] = useState({ name: false, description: false, role: false });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getGroupTag());
  }, []);

  const handleRoleChange = role => {
    setGroup({ ...group, role: role });
  };

  const getTooltipContent = option => {
    return (
      <div className="flex-column items-start justify-start">
        <label className="medium-text font-12 fw-500">{option?.name}</label>
        <label className="regular-text font-12 lighter-text">{option?.description}</label>
      </div>
    );
  };

  const checkGroupErrors = () => {
    if (!group?.name?.trim() || !group?.description?.trim() || !group?.role?.id || !group?.tag?.id) {
      setError({
        name: !group?.name?.trim(),
        role: !group?.role?.id,
        description: !group?.description?.trim(),
        tag: !group?.tag?.id,
      });
      dispatch(addToast({ error: true, text: 'Please fill group name, description, user role and tag' }));
      return true;
    }
    return false;
  };

  const onDone = () => {
    if (loading) {
      return;
    }
    if (checkGroupErrors()) {
      return;
    }
    dispatch(
      createGroupCategory({
        request: {
          name: group.name,
          description: group.description,
          operator_only: false,
          roles: [{ id: group?.role?.id }],
          tag: { id: group?.tag?.id },
        },
      }),
    )
      .then(data => {
        dispatch(addToast({ error: false, text: 'Group added successfully' }));
        navigate(`/groups/${data.id}/accounts`);
        setModal(initModal);
      })
      .catch(err => {
        dispatch(addToast({ error: true, text: 'Something went wrong' }));
      });
  };

  return (
    <CommonPopup
      popupTitle={'New account group'}
      confirmButtonProps={{ label: t('ADD') }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}>
      <CreateGroupWrapper className="flex-column items-center w-full">
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{capitalize(formatText(t('NAME')))}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error.name && 'error-border'}`}
            onChange={e => {
              setGroup({ ...group, name: e.target.value });
              setError({ ...error, name: false });
            }}
            placeholder={t('ENTER_GROUP_NAME')}
            value={group?.name}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{capitalize(formatText(t('DESCRIPTION')))}</label>
          <textarea
            className={`textarea ${error?.description && 'error-border'}`}
            onChange={e => {
              setGroup({ ...group, description: e.target.value });
              setError({ ...error, description: false });
            }}
            placeholder={t('ENTER_DESCRIPTION')}
            rows={4}
            value={group?.description || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{t('USER_ROLE')}</label>
          <DropDown
            className={`provider-selector ${error.role && 'error-border'}`}
            options={userRoles}
            showTooltipOption={userRoles}
            selected={userRoles.find(role => role?.id === group?.role?.id)}
            setSelected={option => {
              handleRoleChange(option);
              setError({ ...error, role: false });
            }}
            placeholder={`Select user role`}
            size="large"
            getTooltipContent={option => getTooltipContent(option)}
            tooltipPlacement={'bottom'}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">Tag</label>
          <DropDown
            className={`provider-selector-2 ${error.tag && 'error-border'}`}
            options={groupTags}
            selected={groupTags.find(role => role?.id === group?.tag?.id)}
            setSelected={option => {
              setGroup({ ...group, tag: option });
              setError({ ...error, tag: false });
            }}
            placeholder={`Select tag`}
            size="large"
          />
        </div>
      </CreateGroupWrapper>
    </CommonPopup>
  );
};

export default CreateGroups;
