import React, { useContext } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext';
import { PrizeDrawDetailRightWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import PrizeDrawClient from './prize-draw-client';
import PrizeDrawConfiguration from './prize-draw-configuration';
import PrizeDrawEntrants from './prize-draw-entrants';
import PrizeDrawForm from './prize-draw-form';
import PrizeDrawOrganization from './prize-draw-organization';
import PrizeDrawSettings from './prize-draw-settings';
import PrizeDrawLogs from './prize-draw-logs';

const prizedrawDetailsRoutes = [
  {
    id: 1,
    path: '/entrants',
    Component: PrizeDrawEntrants,
    permission: ['ENTRANTS_VIEW'],
  },
  {
    id: 2,
    path: '/forms',
    Component: PrizeDrawForm,
    permission: ['FORMS_VIEW_LIST'],
  },
  {
    id: 3,
    path: '/draw-configuration',
    Component: PrizeDrawConfiguration,
    permission: ['PRIZEDRAWS_DRAWCONFIG_VIEW'],
  },
  {
    id: 4,
    path: '/organizations',
    Component: PrizeDrawOrganization,
    permission: ['PRIZEDRAWS_ORGANIZATIONS_VIEW'],
  },
  {
    id: 5,
    path: '/clients',
    Component: PrizeDrawClient,
    permission: ['PRIZEDRAW_CLIENTS_VIEW'],
  },
  {
    id: 6,
    path: '/draw-settings',
    Component: PrizeDrawSettings,
    permission: ['PRIZEDRAWS_CONFIG_VIEW'],
  },
  {
    id: 7,
    path: '/logs',
    Component: PrizeDrawLogs,
    permission: ['PRIZEDRAW_LOG_VIEW'],
  },
];

const PrizeDrawDetailRight = () => {
  const { hasMultiplePermission } = useContext(OrganisationContext);
  const { id } = useParams();

  const getRoute = path => {
    const currentPath = `/prize-draws/all/${id}`;
    const route = prizedrawDetailsRoutes.find(path => hasMultiplePermission(path.permission));
    if (route) {
      return `${currentPath}${route.path}`;
    }
    return `${currentPath}/`;
  };

  return (
    <PrizeDrawDetailRightWrapper className="flex border flex-1">
      <Routes>
        {prizedrawDetailsRoutes
          .filter(path => hasMultiplePermission(path.permission))
          .map(path => (
            <Route
              key={path.id}
              element={<path.Component {...path.params} path={path.path} {...path.actions} />}
              path={path.path}
            />
          ))}
        <Route element={<Navigate replace to={getRoute()} />} path="*" />
      </Routes>
    </PrizeDrawDetailRightWrapper>
  );
};

export default PrizeDrawDetailRight;
