import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    position: relative;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const AddIntegrationWrapper = styled.div`
  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }

  .sub-provider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
  }

  .sub-provider-item {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 100px;
  }

  .selected-sub-provider {
    border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    background: #ffffff;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
`;

export const DeleteIntegrationWrapper = styled.div`
  .integration-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }

  .integration-type-container {
    .integration-icon {
      padding: 1px;
      background: white;
      border-radius: 4px;
    }
  }

  .automation-detail {
    max-height: min(calc(65vh - 350px), 350px);
    overflow: scroll;
  }

  .automation-detail-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;

    .integration-item-top {
      .integration-item-left {
        .hide-name {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            display: block;
          }
        }
      }

      .integration-item-right {
        position: relative;
        outline: none;

        .owner-logo {
          border-radius: 50%;
        }

        .owner-background-0 {
          background: ${({ theme }) => theme.colors.additionalRed};
        }
        .owner-background-1 {
          background: ${({ theme }) => theme.colors.additionalGreen};
        }
        .owner-background-2 {
          background: ${({ theme }) => theme.colors.additionalYellow};
        }
      }
    }
  }
`;

export const InviteUserWrapper = styled.div`
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }

  .phone-input-error {
    .input {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 53px;
    }
  }
`;

export const UserActionsWrapper = styled.div`
  .user-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
`;

export const AgencyActionsWrapper = styled.div`
  .agency-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
`;

export const MarketingCompanyActionsWrapper = styled.div`
  .marketing-compnay-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
`;

export const AutomationActionWrapper = styled.div`
  .automation-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;

    .user-icon {
      height: 32px;
      width: 32px;
      background: ${({ theme }) => theme.colors.ZenPinkSecondary};
      padding: 8px;
      border-radius: 50%;
      span {
        color: ${({ theme }) => theme.colors.RegularText};
      }
    }
  }
`;

export const CreateGroupWrapper = styled.div`
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 53px;
    }
  }

  .provider-selector-2 {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: -178px;
    }
  }
`;
export const AddFormWrapper = styled.div`
  .display-none {
    display: none;
  }
  .prize-draw-img-wrapper {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
  .empty-img-content {
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
    max-width: 32px;
    max-height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }

  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }

  .label-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: unset;
      bottom: 52px;
    }
  }

  .code-txt {
    opacity: 0.3;
  }

  .prizedraw-option {
    min-height: 40px;
    max-height: 40px;

    :hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .label-txt {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
      .code-txt {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .selected-option {
    background: ${({ theme }) => theme.colors.backgroundColor};
    .label-txt {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .client-option {
    min-height: 40px;
    max-height: 40px;

    :hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .label-txt {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }
  .client-code-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px dashed ${({ theme }) => theme.colors.ZenBlueSecondary};
    border-radius: 18px;
    padding: 0px 8px;
  }
`;

export const AddAccountWrapper = styled.div`
  .state-dropdown {
    .selected-name {
      font-size: 16px;
    }
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 53px;
    }
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 53px;
    }
  }
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }

  .phone-input-error {
    .input {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }
`;

export const FormVersionActionsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};

  .form-image {
    height: 40px;
  }
`;

export const PublishFormVersionWrapper = styled.div`
  .form-detail-wrapper {
    background: ${({ theme }) => theme.colors.backgroundColor};

    .form-image {
      height: 40px;
    }
  }

  .hr-line {
    margin: 16px -48px;
    background-color: ${({ theme }) => theme.colors.divider};
    width: calc(100% + 96px);
  }
`;

export const AddAutomationWrapper = styled.div`
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }

  .user-icon {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.Gray3};
    padding: 8px;
    border-radius: 50%;
    span {
      color: ${({ theme }) => theme.colors.zenGray};
    }
  }

  .owner-option {
    height: 40px;

    &:hover {
      background: ${({ theme }) => theme.colors.Gray3};
    }
  }
`;

export const AddPrizedrawWrapper = styled.div`
  .img-data-wrapper {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
    .delete-btn-wrapper {
      height: 24px;
      width: 24px;
      background: #ffff;
      display: none;
    }
    &:hover {
      .delete-btn-wrapper {
        display: flex;
      }
    }
  }
  .img-container {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
  }
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
  .empty-img-content {
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;

    .add-img-btn {
      display: none;
      height: 24px;
      width: 24px;
      background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }

    &:hover {
      background: #ffff;
      opacity: 1;

      .empty-img {
        display: none;
      }

      .add-img-btn {
        display: flex;
      }
    }
  }

  .numeric-id {
    right: 16px;
    opacity: 0.3;
  }
`;

export const AddProductFeesWrapper = styled.div`
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }

  .disabled {
    pointer-events: none;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
  .fromdate-container {
    .date-selector {
      top: 48px;
      left: 0px;
      width: 350px;
      height: 310px;
      padding: 24px 48px;
    }
  }

  .integration-img-wrapper {
    height: 32px;
    width: 32px;
  }
  .integration-img {
    height: 32px;
    width: 32px;
  }

  .prizedraw-option {
    min-height: 40px;
    max-height: 40px;

    :hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .label-txt {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
      .code-txt {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .prizedraw-product-option {
    .sub-option-text {
      opacity: 0.3;
    }
    :hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .product-option-text {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
      .sub-option-text {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .selected-product-option {
    .product-option-text {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
    .sub-option-text {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }
`;

export const AddFeesWrapper = styled.div`
  .provider-selector {
    .organisations-border {
      height: 48px;

      .selected-name {
        font-size: 16px;
      }
    }
    .mc-options {
      top: 48px;
    }
  }
  .disabled {
    pointer-events: none !important;
  }
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
  .fromdate-container {
    min-height: 48px;
    label {
      font-size: 16px;
    }
    .date-selector {
      top: 48px;
      left: 0px;
      width: 350px;
      height: 310px;
      padding: 24px 48px;
    }
  }
  .warning-text {
    color: ${({ theme }) => theme.colors.ZenNegative};
    text-align: center;
  }

  .confirm-box {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
  .VARIABLE {
    background: ${({ theme }) => theme.colors.ZenBlue2};
    height: 28px;
    border-radius: 18px;
    label {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
    svg {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
  }
  .FIXED {
    background: ${({ theme }) => theme.colors.ZenPurpleSecondary2};
    height: 28px;
    border-radius: 18px;
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
    svg {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }
`;

export const RemovePrizedrawClient = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};
`;

export const RemoveBillingFeeWrapper = styled.div`
  background: ${({ theme }) => theme.colors.backgroundColor};

  .continual {
    width: 170px;
    background: ${({ theme }) => theme.colors.ZenBlue2};
    height: 28px;
    border-radius: 18px;
    label {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
    svg {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
  }

  .cohort {
    width: 170px;
    background: ${({ theme }) => theme.colors.ZenPurpleSecondary2};
    height: 28px;
    border-radius: 18px;
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
    svg {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }
`;

export const PrizedrawEntrantActionWrapper = styled.div`
  .entrant-details {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
  .horizontal-line {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    width: calc(100% + 96px);
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
  .fromdate-container {
    min-height: 48px;
    label {
      font-size: 16px;
    }
    .date-selector {
      top: unset;
      bottom: 50px;
      left: 0px;
      width: 100%;
      min-height: 390px;
    }
  }
`;

export const AnonymizeActionWrapper = styled.div`
  .container {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export const EntrantExportWrapper = styled.div`
  .entrant-wrapper {
    max-width: 1080px;
    min-width: 1080px;
    min-height: 800px;
    max-height: 800px;

    .count-box {
      background: ${({ theme }) => theme.colors.backgroundColor};
      height: 24px;
      width: 24px;
    }
    .filter {
      background: ${({ theme }) => theme.colors.backgroundColor};
      border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
      border-radius: 12px;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
      }
    }

    .open-filter-icon {
      background: ${({ theme }) => theme.colors.backgroundColor};
      path {
        stroke: ${({ theme }) => theme.colors.zenPurple};
        fill: ${({ theme }) => theme.colors.zenPurple};
      }
    }
    .prize-draw-entrant-filter {
      left: 40px !important;
      top: 140px !important;
    }

    .filter-box-wrapper {
      background: ${({ theme }) => theme.colors.backgroundColor};
    }

    .provider-selector {
      .organisations-border {
        height: 40px;
        background: #ffff;
      }
      .mc-options {
        top: 48px;
      }
    }

    .left-panel-wrapper {
      width: calc(52% - 20px);
    }
    .right-panel-wrapper {
      width: calc(48% - 20px);
    }

    .filter-box-input {
      min-width: 40%;
      max-width: 40%;
      background: #ffff;
      height: 40px;
    }

    .template-item-wrapper {
      border: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .selected-template {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }

    .column-item-wrapper {
      border: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .sub-item-wrapper {
      border-top: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .edit-template-wrapper {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }

    .update-btns {
      top: 85px;
    }

    .edit-input {
      height: 32px;
      font-size: 14px;
    }

    .date-format-dropdown {
      height: 40px;
      background: #ffff;
    }
    .selected-date-format-dropdown {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }
    .date-format-option-wrapper {
      top: 45px;
      max-height: 250px;
      z-index: 5;
      .date-format-label {
        text-transform: uppercase;
      }
      .sub-option-wrapper {
        row-gap: 2px;
      }
      .date-format-option {
        height: 32px;
      }
      .selected-option {
        background: ${({ theme }) => theme.colors.backgroundColor};
        label {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
      .date-format-option:hover {
        background: ${({ theme }) => theme.colors.backgroundColor};
        label {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
    }
    .date-format-input {
      max-width: 170px;
    }
    .active-box {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }
    .date-range-wrapper {
      left: 0px !important;
      top: 40px;
      .date-selector {
        height: 270px;
      }
      .no-select {
        margin-bottom: 8px;
      }
      .month-selector {
        margin-bottom: 12px;
      }
      .date-container-wrapper {
        min-height: 320px;
        max-height: 320px;
      }
    }
  }

  .template-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
`;

export const AutomationLogCancelWrapper = styled.div``;

export const AutomationLogActionWrapper = styled.div`
  width: 100%;
  .automation-log-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
  .automation-action-container {
    background: ${({ theme }) => theme.colors.white};
  }
`;

export const ActivatePrizedrawWrapper = styled.div`
  .progress-bar-wrapper {
    height: 4px;
    background: ${({ theme }) => theme.colors.backgroundColor};
    .progress-bar {
      left: 0px;
      top: 0px;
      background: linear-gradient(
        180deg,
        ${({ theme }) => theme?.colors?.ZenPositive} 0%,
        ${({ theme }) => theme?.colors?.ZenPositiveDark} 100%
      );
    }
  }
  .link_text {
    color: ${({ theme }) => theme.colors.zenPurple};
    text-decoration-line: underline;
  }
  .disabled_link_text {
    opacity: 0.5;
    pointer-events: none;
  }
  .circular-progress-bar {
    height: 16px;
    width: 16px;
  }
  .details-wrapper {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
  .container__progressbars {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    min-width: 270px;
    width: 100%;
    min-height: 100%;
  }
  .checked-wrapper {
    background: ${({ theme }) => theme.colors.ZenPositive};
    height: 16px;
    width: 16px;
    border-radius: 50%;

    .check-icon {
      z-index: 10px;
      color: #ffff;
      height: 10px;
      width: 10px;
    }
  }
  .empty-img-content {
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
  }
`;

export const PrizedrawEntrantReActiveWrapper = styled.div`
  .entrant-details {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
  .horizontal-line {
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
    width: calc(100% + 96px);
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }
  .fromdate-container {
    min-height: 48px;
    label {
      font-size: 16px;
    }
    .date-selector {
      top: unset;
      bottom: 50px;
      left: 0px;
      width: 100%;
      min-height: 390px;
    }
  }
  .product-list-option {
    :hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
      label {
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .payment-method-selector {
    .mc-options {
      top: unset;
      bottom: 100%;
      margin-bottom: 4px;
    }
  }
`;
