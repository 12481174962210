import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as AvatarImage } from '../../../../../assets/images/avatar-image.svg';
import { ReactComponent as MailIcon } from '../../../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../../../assets/images/mobile.svg';
import { OrganisationContext } from '../../../../../context/organisationContext';
import { prizeDrawEntrantEntryFundraising } from '../../../../../store/features/prizeDrawSlice';
import Menu from '../../../../common/menu';

const Fundraising = () => {
  const { prizeDrawEntrantEntryFundraisingDetails, prizeDrawEntrantEntryFormDetails } = useSelector(
    state => state.prizeDraw,
  );

  const { form } = prizeDrawEntrantEntryFormDetails || {};
  const { fundraiser, marketing_company } = prizeDrawEntrantEntryFundraisingDetails || {};

  const { id, entrant_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useContext(OrganisationContext);

  const imageContent = (
    <img src={fundraiser?.avatar_url} style={{ objectFit: 'cover', height: '24px', width: '24px' }} />
  );

  useEffect(() => {
    dispatch(prizeDrawEntrantEntryFundraising(entrant_id));
  }, []);

  const onDetails = () => {
    if (marketing_company?.id) {
      navigate(`/prize-draws/all/${id}/organizations/account-details/${marketing_company?.id}/prize-draw-access`);
    }
  };

  return (
    (fundraiser?.name || marketing_company?.name) && (
      <div className="px-6 pt-6 flex flex-column">
        <label className="medium-text font-16 lighter-text divider-bottom w-full flex pb-2">
          {form?.form_type?.name} fundraising
        </label>
        <div className="border radius-4 mt-4 px-4">
          {marketing_company?.name && (
            <div className="py-3 justify-between items-center fundraising-grid">
              <div className="flex-column border-right py-3 pr-3">
                <label className="regular-text font-14 fw-400 one-line mb-1">{marketing_company?.name}</label>
                <label className="medium-text font-10 zen-purple-text px-2 bg-gray radius-4 fw-500 text-center width-120">
                  Marketing company
                </label>
              </div>
              <div className="flex-column pl-6 gap-2 border-right py-2">
                <div className="flex-row gap-2 items-center">
                  <MailIcon />
                  <label className="regular-text font-14 fw-400">
                    {marketing_company?.email ? (
                      marketing_company?.email
                    ) : (
                      <span className="lighter-text">no data</span>
                    )}
                  </label>
                </div>
                <div className="flex-row gap-2 items-center">
                  <MobileIcon />{' '}
                  <label className="regular-text font-14 fw-400">
                    {marketing_company?.phone ? (
                      marketing_company?.phone
                    ) : (
                      <span className="lighter-text">no data</span>
                    )}
                  </label>
                </div>
              </div>
              <div className="flex-row pl-6 justify-between">
                <label className="regular-text font-14 two-line">{marketing_company?.formatted_address}</label>
                <Menu menuList={[{ name: 'Details', onClick: () => onDetails() }]} />
              </div>
            </div>
          )}
          {fundraiser?.name && (
            <div className="divider-top py-3 flex-column gap-1">
              <div className="flex items-center gap-2">
                {fundraiser?.avatar_url ? imageContent : <AvatarImage />}
                <label className="regular-text font-14 fw-400">
                  {hasPermission('PII_VIEW') ? fundraiser?.name : 'Fundraiser'}
                </label>
              </div>
              <div className="flex-row gap-2 items-center">
                {fundraiser?.badge_number && (
                  <label className="regular-text lighter-text font-12">
                    Badge number assigned {fundraiser?.badge_number}
                  </label>
                )}
                {fundraiser?.badge_number && <label className="dot-3-lighter-text"></label>}
                {fundraiser?.call_verification && (
                  <label className="regular-text lighter-text font-12">
                    Call verification number {fundraiser?.call_verification}
                  </label>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Fundraising;
