import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as Delete } from '../../../../assets/images/delete-icon.svg';
import { getItemFromLocalStorage } from '../../../../helpers/localstorage';
import { operatorOptions } from '../../../../helpers/utils';
import { getAutomationEventType } from '../../../../store/features/automationsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import api from '../../../../store/services/index';
import Button from '../../../Elements/button/button';
import DropDown from '../../../McDropdown';

const conditionDropdownLexicons = [
  'prizedraw.id',
  'entrant_form.form_type.id',
  'client.id',
  'form.id',
  'entrant.contact.marketing_preferences.SMS.consent',
  'entrant.contact.marketing_preferences.EMAIL.consent',
  'entrant.contact.marketing_preferences.PHONE.consent',
  'entrant.contact.marketing_preferences.POST.consent',
];

const initialRows = [{ name: 'When', dropdownValue: '', inputValue: '' }];

const getDefaultCondition = conditionData => {
  if (!conditionData?.length) {
    return initialRows;
  }
  return conditionData.map((condition, index) => ({
    name: index === 0 ? 'When' : 'And',
    operatorValue: null,
    dropdownValue: { id: condition.field, lexicon: condition.field, name: condition.display_field },
    inputValue: conditionDropdownLexicons.includes(condition?.field)
      ? { id: condition.value, name: condition.display_value }
      : condition.value,
    operatorValue: condition.operator,
  }));
};

const AutomationCondition = ({ conditionData, sendDataToCondition, handleShowConditon = () => {} }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localUser = getItemFromLocalStorage('user');
  const orgId = localUser.organizations?.[0]?.id;
  const { eventTypeData } = useSelector(state => state.automations);

  const [tableRows, setTableRows] = useState(getDefaultCondition(conditionData));
  const [optionData, setOptionData] = useState({});

  const addNewRow = () => {
    const lastRow = tableRows[tableRows.length - 1];
    if (lastRow.dropdownValue && lastRow.inputValue) {
      setTableRows(prevRows => [...prevRows, { name: 'And', dropdownValue: eventTypeData[0], inputValue: '' }]);
    } else {
      dispatch(addToast({ error: true, text: 'Please fill the previous condition details' }));
    }
  };

  const handleDropdownChange = (index, value) => {
    const updatedRows = [...tableRows];
    updatedRows[index].dropdownValue = value;
    setTableRows(updatedRows);
  };

  const handleInputChange = (index, value) => {
    const updatedRows = [...tableRows];
    updatedRows[index].inputValue = value;
    setTableRows(updatedRows);
  };

  const handleOperatorChange = (index, value) => {
    const updatedRows = [...tableRows];
    updatedRows[index].operatorValue = value;
    setTableRows(updatedRows);
  };

  const clearAllData = () => {
    setTableRows(prevRows => [{ ...prevRows[0], dropdownValue: '', inputValue: '', operatorValue: null }]);
    handleDropdownChange && handleShowConditon(false);
  };

  const clearData = index => {
    const updatedRows = [...tableRows];
    updatedRows[index].dropdownValue = '';
    updatedRows[index].inputValue = '';
    updatedRows[index].operatorValue = '';
    updatedRows.splice(index, 1);
    setTableRows(updatedRows);
    if (updatedRows.length === 0) {
      handleDropdownChange && handleShowConditon(false);
    }
  };

  useEffect(() => {
    dispatch(getAutomationEventType(id));
  }, []);

  useEffect(() => {
    sendDataToCondition(tableRows);
  }, [tableRows]);

  const getPrizedrawOptions = async page => {
    const { data } = await api.get(`/prizedraws`, { params: { page } });
    return data;
  };

  const getFormTypeOptions = async page => {
    const { data } = await api.get(`/form_types`, { params: { page } });
    return data;
  };

  const getClientOptions = async (page, lexicon) => {
    const { data } = await api.get(`/organizations/${orgId}/network`, { params: { page, category_tag: 'CLIENT' } });
    return data;
  };

  const getFormOptions = async page => {
    const { data } = await api.get(`/prizedraws/forms`, { params: { page } });
    return data;
  };

  const getNewOption = async (lexicon, page) => {
    switch (lexicon) {
      case 'prizedraw.id':
        return getPrizedrawOptions(page);
      case 'entrant_form.form_type.id':
      case 'session.form_type':
        return getFormTypeOptions(page);
      case 'client.id':
        return getClientOptions(page);
      case 'form.id':
        return getFormOptions(page);
      case 'entrant.contact.marketing_preferences.SMS.consent':
      case 'entrant.contact.marketing_preferences.EMAIL.consent':
      case 'entrant.contact.marketing_preferences.PHONE.consent':
      case 'entrant.contact.marketing_preferences.POST.consent':
        return [
          { id: 'true', name: true, value: true },
          { id: 'false', name: false, value: false },
        ];
      default:
        break;
    }
  };

  const fetchMoreData = async (lexicon, page) => {
    const data = await getNewOption(lexicon, page);
    setOptionData({
      ...optionData,
      [lexicon]: { ...data, content: [...optionData[lexicon].content, ...data.content] },
    });
  };

  const onChangeFirstCondition = async (option, index) => {
    setTableRows(
      [...tableRows].map((c, idx) =>
        idx === index ? { ...c, dropdownValue: option, inputValue: null, operatorValue: '' } : { ...c },
      ),
    );
    if (!optionData[option.lexicon]) {
      const data = await getNewOption(option.lexicon, 0);
      setOptionData({ ...optionData, [option.lexicon]: data });
    }
  };

  const getOptionForConditonalSelect = data => {
    const lexiconOptionData = data.dropdownValue?.lexicon ? optionData[data.dropdownValue?.lexicon] || [] : [];
    const options = lexiconOptionData?.content || lexiconOptionData;

    return {
      options: options,
      isPaged: lexiconOptionData?.total_pages,
      hasMore: !lexiconOptionData?.last,
      pageNumber: (lexiconOptionData?.number || 0) + 1,
      fetchData: pageNumber => fetchMoreData(data.dropdownValue?.lexicon, pageNumber),
    };
  };

  const fetchOptions = async data => {
    let optionData = {};
    await Promise.all(
      data.map(async row => {
        const lexicon = row.dropdownValue?.lexicon;
        const data = await getNewOption(lexicon, 0);
        optionData = { ...optionData, [lexicon]: data };
      }),
    ).catch(err => console.log(err));
    setOptionData(optionData);
  };

  useEffect(() => {
    fetchOptions(tableRows);
  }, []);

  return (
    <div className="flex flex-column pt-8">
      <div className="flex-row justify-between">
        <label className="regular-text font-14 grey-text">{t('CONDITION')}</label>
        <div className="font-12 regular-text red-text cursor" onClick={clearAllData}>
          {t('REMOVE_ALL')}
        </div>
      </div>
      <div>
        <div className="flex gap-4 flex-column py-4 ">
          {tableRows.map((row, index) => (
            <div key={index} className="flex gap-4">
              <label className="regular-text flex font-16 items-center" style={{ width: '60px' }}>
                {index + 1}. {row.name}
              </label>
              <DropDown
                className="condition-selector"
                options={eventTypeData?.event_type?.lexicons}
                setSelected={e => onChangeFirstCondition(e, index)}
                selected={row.dropdownValue}
                placeholder="Select"
              />
              <DropDown
                className="operator-selector"
                options={operatorOptions}
                setSelected={e => handleOperatorChange(index, e.value)}
                selected={operatorOptions.find(data => data.value === row.operatorValue) || null}
                placeholder="Select"
              />
              {conditionDropdownLexicons.includes(row.dropdownValue?.lexicon) ? (
                <DropDown
                  className="condition-selector"
                  {...getOptionForConditonalSelect(row)}
                  setSelected={e => handleInputChange(index, e)}
                  selected={row.inputValue}
                  placeholder="Select"
                />
              ) : (
                <input
                  type="text"
                  className="input name-input"
                  value={row.inputValue}
                  onChange={e => handleInputChange(index, e.target.value)}
                  placeholder="Select"
                />
              )}
              <Delete height={32} className="cursor" onClick={() => clearData(index)} />
            </div>
          ))}
        </div>

        <Button
          onClick={addNewRow}
          className={'default-secondary px-4'}
          borderRadius="8px"
          label={'+ Add Condition'}></Button>
      </div>
    </div>
  );
};

export default AutomationCondition;
