import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { monthOptions, stateOptions, weekDayOptions } from '../../../../constant/optionData';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizeDrawConfigUpdate = ({ log = {} }) => {
  const {
    event_data: {
      previous: { prizedraw: prevPrizedraw },
      prizedraw,
    },
  } = log || {};

  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();
  const { config: prevConfig } = prevPrizedraw || {};
  const { config } = prizedraw || {};
  const [updatedData, setUpdatedData] = useState({});
  const [showDetails, setShowDetails] = useState(true);

  const intervalDataFormat = data => {
    const { interval_type, intervals, day_of_week, week_of_month, day_of_month, month } = data || {};
    if (!interval_type) {
      return null;
    }
    if (interval_type === 'WEEKLY') {
      return `Every ${intervals || ''} week on ${weekDayOptions.find(a => a.value === day_of_week)?.name || ''}`;
    }
    if (interval_type === 'MONTHLY') {
      return `The ${week_of_month || ''} ${
        weekDayOptions.find(a => a.value === day_of_week)?.name || ''
      } of every ${intervals} month`;
    }
    if (interval_type === 'YEARLY') {
      if (day_of_month) {
        return `On ${
          monthOptions.find(a => a.value === month)?.name || ''
        } ${day_of_month} for every ${intervals} years`;
      } else {
        return `On the ${week_of_month || ''} ${weekDayOptions.find(a => a.value === day_of_week)?.name || ''} of ${
          monthOptions.find(a => a.value === month)?.name || ''
        } for every ${intervals} years`;
      }
    }
  };
  const getDifference = (arr1 = [], arr2) => {
    return arr1.filter(data1 => !(arr2 || []).some(data2 => data2 === data1));
  };

  const statesWithComma = (states = []) => {
    return states.map(state => stateOptions.find(s => s.abbreviation === state)?.name || state).join(', ');
  };

  const setDetails = () => {
    let details = {};
    if (config?.min_age !== prevConfig?.min_age) {
      details = {
        updatedText: 'maximum age to enter',
        fromText: `${prevConfig?.min_age} years old`,
        toText: `${config?.min_age} years old`,
      };
    } else if (config?.f2f_min_age !== prevConfig?.f2f_min_age || config?.f2f_max_age !== prevConfig?.f2f_max_age) {
      details = {
        updatedText: 'F2F allowed age range',
        fromText: `${prevConfig?.f2f_min_age} to ${prevConfig?.f2f_max_age} years old`,
        toText: `${config?.f2f_min_age} to ${config?.f2f_max_age} years old`,
      };
    } else if (config?.max_entries !== prevConfig?.max_entries) {
      details = {
        updatedText: 'maximum permitted entries',
        fromText: `${prevConfig?.max_entries} maximum entries`,
        toText: `${config?.max_entries} maximum entries`,
      };
    } else if (config?.referral_bonus_entries !== prevConfig?.referral_bonus_entries) {
      details = {
        updatedText: 'bonus entries per referral',
        fromText: `${prevConfig?.referral_bonus_entries} entry per referral`,
        toText: `${prevConfig?.referral_bonus_entries} entry per referral`,
      };
    } else if (
      config?.excluded_states.length > prevConfig?.excluded_states.length ||
      config?.excluded_states.length < prevConfig?.excluded_states.length ||
      config?.excluded_states.length === prevConfig?.excluded_states.length ||
      config?.excluded_states.length !== prevConfig?.excluded_states.length
    ) {
      details = {
        updatedText: 'states excluded',
        fromText: `${statesWithComma(prevConfig?.excluded_states)}`,
        fromLabel: 'From',
        toText: `${statesWithComma(config?.excluded_states)}`,
        toLabel: 'To',
      };
    }
    setUpdatedData(details);
  };
  const getUpdatedText = () => {
    if ((config?.excluded_states || [])?.length > (prevConfig?.excluded_states || [])?.length) {
      return 'added Prize Draw';
    } else if ((config?.excluded_states || [])?.length < (prevConfig?.excluded_states || [])?.length) {
      return 'deleted Prize Draw';
    } else {
      return 'updated Prize Draw';
    }
  };
  const getTitleText = () => {
    if (config?.allow_prorated_charges !== prevConfig?.allow_prorated_charges) {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">{`${
            config?.allow_prorated_charges === true ? 'enabled Prize Draw' : 'disabled Prize Draw'
          } Prize Draw`}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('PRORATED_CHARGES')}</span>
        </span>
      );
    } else {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">{getUpdatedText()}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          <span className="regular-text lighter-text font-12 ml-1">{updatedData?.updatedText || ''}</span>
        </span>
      );
    }
  };

  useEffect(() => {
    setDetails();
  }, []);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Configuration updated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={getTitleText()}
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
        showDownIcon={
          config?.is_recurring !== prevConfig?.is_recurring ||
          config?.allow_prorated_charges !== prevConfig?.allow_prorated_charges
            ? false
            : true
        }
      />
      {showDetails &&
        !(
          config?.is_recurring !== prevConfig?.is_recurring ||
          config?.allow_prorated_charges !== prevConfig?.allow_prorated_charges
        ) && (
          <>
            <LogContentBox
              title={updatedData?.fromLabel ? updatedData?.fromLabel : 'From'}
              contentText={<p className="regular-text lighter-text font-12">{updatedData?.fromText || ''}</p>}
            />
            {updatedData?.toText && (
              <LogContentBox
                title={'To'}
                contentText={<p className="regular-text lighter-text font-12">{updatedData?.toText || ''}</p>}
              />
            )}
          </>
        )}
    </div>
  );
};

export default PrizeDrawConfigUpdate;
