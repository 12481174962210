import styled from 'styled-components';

export const BillingOverviewListWrapper = styled.div`
  .content-grid {
    display: grid;
    grid-template-columns: minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr) minmax(180px, 1fr);
  }
  .content-header {
    background: ${({ theme }) => theme.colors.backgroundColor};
    max-height: 40px;
  }

  .content-body {
    min-height: 56px;
    max-height: 56px;
    padding: 8px 0;

    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }
`;
