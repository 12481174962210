import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GroupDetailWrapper } from '../../styles/components/group-details/group-detail.styled';
import AccountDetails from './account-details';
import GroupDetailsLeft from './group-details-left';
import GroupDetailsRight from './group-details-right';

const GroupDetails = () => {
  return (
    <GroupDetailWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6 overflow-hidden">
      <Routes>
        <Route path="/accounts/account-details/:account_id/*" element={<AccountDetails />} />
        <Route
          path="/*"
          element={
            <>
              <GroupDetailsLeft />
              <GroupDetailsRight />
            </>
          }
        />
      </Routes>
    </GroupDetailWrapper>
  );
};

export default GroupDetails;
