import parse from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';
import CheckedIcon from '../../../assets/images/check-done.svg';

const Checkbox = ({
  name,
  is_required,
  onChange = () => {},
  checked = false,
  font = null,
  fontFamily,
  showRequiredError,
}) => {
  return (
    <CheckboxWrapper className="flex items-start mb-4">
      <input
        className={`cursor checkbox ${checked && 'checked'} ${showRequiredError && !checked && 'required-error'}`}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <div className="ml-2">
        <span
          className="regular-text"
          style={{ fontSize: `${font.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
          {parse(name)}
        </span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled('div')`
  .checkbox {
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    outline: none;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
    border-radius: 4px;
  }

  .checked {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border-color: ${({ theme }) => theme.colors.zenPurple};
    position: relative;
  }

  .checked::before {
    content: url(${CheckedIcon});
    transform: scale(0.7);
    position: absolute;
    right: -1px;
    top: -1px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export default Checkbox;
