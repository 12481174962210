import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AutomationLogsListWrapper } from '../../styles/components/automation-logs-list/automation-logs-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import AutomationLogItem from './automation-log-item';

const AutomationLogsList = ({ debouncedSearch = '' }) => {
  const { automationLogList } = useSelector(state => state.automations);
  const { t } = useTranslation();

  return (
    <AutomationLogsListWrapper className="flex-column items-start justify-start w-full flex-1">
      <div className="flex items-center justify-center w-full automatiom-log-header-wrapper">
        <div className="divider-right flex items-center justify-center w-full px-6 overflow-hidden">
          <label className="medium-text one-line font-12 lighter-text">{t('ID')}</label>
        </div>
        <div className="divider-right flex items-center justify-center w-full px-6 overflow-hidden">
          <label className="medium-text font-12 lighter-text one-line">{t('AUTOMATION_NAME')}</label>
        </div>
        <div className="divider-right flex items-center justify-center w-full px-6 overflow-hidden">
          <label className="medium-text font-12 lighter-text one-line">{t('DATE')}</label>
        </div>
        <div className="flex items-center justify-center w-full px-6 overflow-hidden">
          <label className="medium-text font-12 lighter-text one-line">{t('STATUS')}</label>
        </div>
      </div>
      <div className="flex-column items-start justify-start w-full flex-1 overflow-scroll">
        {automationLogList?.content?.length ? (
          (automationLogList?.content || []).map((automationLog, index) => (
            <AutomationLogItem key={index + 1} automationLog={automationLog} />
          ))
        ) : debouncedSearch ? (
          <NoResultComponent />
        ) : (
          <NoDataComponent />
        )}
      </div>
    </AutomationLogsListWrapper>
  );
};

export default AutomationLogsList;
