import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import Button from '../../components/Elements/button/button';
import Checkbox from '../../components/checkbox';
import UpdateAction from '../../components/common/update-action';
import MultipleFilter from '../../components/multiple-filter';
import { initModal } from '../../constant/InitialData';
import { filterChildRenderComponent, multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { replaceTextSymbol } from '../../helpers/utils';
import {
  createTemplateApi,
  deleteTemplateApi,
  exportEntrantApi,
  getExportColumnsList,
  getTemplateList,
  updateTemplate,
} from '../../store/features/prizeDrawSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';
import { EntrantExportWrapper } from '../modal.styled';
import ColumnItem from './column-item';
import FilterBox from './filter-box';
import TemplateItem from './template-item';

const EntrantExport = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const { filters: entrantFilter } = modal?.content;
  const dispatch = useDispatch();
  const { exportColumnList, templateList, prizeDrawDetail } = useSelector(state => state.prizeDraw);
  const [selectAllColumn, setSelectedAllColumn] = useState(false);
  const [filters, setFilters] = useState([...entrantFilter]);
  const [openFilter, setOpenFilter] = useState(false);
  const [columns, setColumns] = useState(exportColumnList);
  const [templates, setTemplates] = useState(templateList);
  const [loading, setLoading] = useState(false);
  const [editTemplate, setEditTemplate] = useState({});
  const [deleteTemplate, setDeleteTemplate] = useState({});
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [createTemplate, setCreateTemplate] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showSaveChangesBtn, setShowSaveChangesBtn] = useState(false);
  const [selectedRangeData, setSelectedDateRange] = useState({
    name: 'Today',
    label: 'Today',
    value: 'TODAY',
    startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().set('hour', 24).set('minute', 0).set('second', 0).unix(),
  });
  const [selectedDateFormat, setSelectedDateFormat] = useState({
    groupName: 'Regional (default)',
    id: 'MM/DD/YYYY',
    name: moment().format('MM/DD/YYYY'),
    value: 'MM/DD/YYYY',
    param: 'MM/dd/YYYY',
  });
  const { t } = useTranslation();
  const org = getItemFromLocalStorage('user').organizations[0];

  const { user } = useSelector(state => state.user);
  const { user: userDetail } = user || {};

  useEffect(() => {
    dispatch(getExportColumnsList());
    dispatch(getTemplateList());
  }, []);
  useEffect(() => {
    setColumns(exportColumnList);
    setTemplates(templateList);
  }, [exportColumnList, templateList]);
  const handleSelectedAllColumns = checked => {
    const updatedColumns = (columns || []).map(column => {
      const updatedSubColumns = (column?.columns || []).map(data => ({ ...data, selected: checked }));
      return { ...column, selected: checked, columns: [...updatedSubColumns] };
    });
    setColumns(updatedColumns);
    setSelectedAllColumn(checked);
  };
  const getDifference = (arr1 = [], arr2, key = 'id') => {
    return arr1.filter(({ [key]: id1 }) => !(arr2 || []).some(({ [key]: id2 }) => id2 === id1));
  };
  const removeDuplicateItem = (arr = [], key = 'id') => {
    return arr.filter((obj, index) => {
      return index === arr.findIndex(o => obj[key] === o[key]);
    });
  };
  const handleCheckShowChangesButtonVisibility = list => {
    let selectedAttribute = [];
    list.forEach(data => {
      const selectedSubColumns = (data?.columns || []).filter(item => item.selected);
      selectedAttribute = [...selectedAttribute, ...selectedSubColumns];
    });
    const uniqueselectedAttribute = removeDuplicateItem(selectedAttribute, 'id');
    let unique1 = getDifference(uniqueselectedAttribute, selectedTemplate?.attributes, 'id');
    let unique2 = getDifference(selectedTemplate?.attributes, uniqueselectedAttribute, 'id');
    setShowSaveChangesBtn([...unique1, ...unique2].length > 0 ? true : false);
  };
  const handleCheckColumn = (colId, checked, subColumnId) => {
    let updatedColumns = [];
    if (subColumnId) {
      updatedColumns = (columns || [])?.map(col => {
        if (col?.id === colId) {
          const updatedSubColumns = (col?.columns || [])?.map(subColumn =>
            subColumnId === subColumn?.id ? { ...subColumn, selected: checked } : { ...subColumn },
          );
          return {
            ...col,
            selected:
              updatedSubColumns.filter(data => data?.selected).length === col?.columns?.length
                ? true
                : updatedSubColumns.filter(data => data?.selected).length !== col?.columns?.length
                ? false
                : col?.selected || false,
            columns: [...updatedSubColumns],
          };
        } else {
          return { ...col };
        }
      });
    } else {
      updatedColumns = (columns || [])?.map(col => {
        if (col?.id === colId) {
          const updatedSubColumns = (col?.columns || [])?.map(subColumn => ({ ...subColumn, selected: checked }));
          return {
            ...col,
            selected: checked,
            columns: [...updatedSubColumns],
          };
        } else {
          return { ...col };
        }
      });
    }
    let isAllSelected = true;
    (updatedColumns || []).forEach(col => {
      if (!isAllSelected) {
        return;
      } else {
        isAllSelected = col?.selected;
        if (isAllSelected) {
          (col?.columns || []).forEach(item => (isAllSelected = item.selected));
        }
      }
    });
    if (selectedTemplate) {
      handleCheckShowChangesButtonVisibility(updatedColumns);
    }
    setSelectedAllColumn(isAllSelected);
    setColumns(updatedColumns);
  };
  const handleTemplateChanges = (field, value) => {
    setEditTemplate({ ...editTemplate, [field]: value });
  };
  const getTemplateColumns = template => {
    const updatedColumns = exportColumnList.map(col => {
      const updatedSubColumns = (col?.columns || []).map(item => {
        if ((template?.attributes || []).find(data => data.id === item?.id)) {
          return { ...item, selected: true };
        } else {
          return { ...item };
        }
      });
      return {
        ...col,
        columns: [...updatedSubColumns],
        selected:
          (col.columns || []).length > 0 &&
          (col.columns || []).length === updatedSubColumns.filter(item => item.selected).length
            ? true
            : false,
      };
    });
    return updatedColumns;
  };
  const handleSelectedTemplate = async template => {
    if (template.id === selectedTemplate?.id) {
      setSelectedTemplate({});
      setColumns(exportColumnList);
      return;
    }
    const updatedColumns = await getTemplateColumns(template);
    setShowSaveChangesBtn(false);
    setSelectedTemplate(template);
    setEditTemplate({});
    setColumns(updatedColumns || []);
  };

  const isDataChanged = () => {
    const selectedTemp = templates.find(temp => temp.id === editTemplate.id);
    if (selectedTemp?.name !== editTemplate?.name || selectedTemp?.description !== editTemplate?.description) {
      return true;
    } else {
      return false;
    }
  };
  const handleUpdateTemplate = () => {
    if (!isDataChanged()) {
      setEditTemplate({});
      return;
    }
    if (!editTemplate?.name?.trim() && !editTemplate?.description?.trim()) {
      dispatch(addToast({ error: true, text: 'Name and Description is required' }));
      return;
    }
    if (!editTemplate?.name?.trim()) {
      dispatch(addToast({ error: true, text: 'Name is required' }));
      return;
    }
    if (!editTemplate?.description?.trim()) {
      dispatch(addToast({ error: true, text: 'Description is required' }));
      return;
    }
    setLoading(true);
    dispatch(
      updateTemplate({
        request: { ...editTemplate, external_id: editTemplate?.id },
        templateId: editTemplate?.id,
      }),
    )
      .then(() => {
        setLoading(false);
        setEditTemplate({});
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleSelectedTemplateUpdate = () => {
    let updatedAttribute = selectedTemplate?.attributes || [];
    if (showSaveChangesBtn) {
      updatedAttribute = [];
      (columns || []).forEach(col => {
        (col?.columns || [])?.forEach(item => {
          if (item?.selected) {
            delete item.selected;
            updatedAttribute.push(item);
          }
        });
      });
      updatedAttribute = removeDuplicateItem(updatedAttribute, 'id');
    }
    dispatch(
      updateTemplate({
        request: { ...selectedTemplate, attributes: [...updatedAttribute], external_id: selectedTemplate?.id },
        templateId: selectedTemplate?.id,
      }),
    )
      .then(data => {
        const updatedColumns = getTemplateColumns(data);
        setSelectedTemplate({ ...data });
        setColumns(updatedColumns);
        setShowSaveChangesBtn(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleCreateTemplate = () => {
    if (!createTemplate?.name?.trim() && !createTemplate?.description?.trim()) {
      dispatch(addToast({ error: true, text: 'Name and Description is required' }));
      return;
    }
    if (!createTemplate?.name?.trim()) {
      dispatch(addToast({ error: true, text: 'Name is required' }));
      return;
    }
    if (!createTemplate?.description?.trim()) {
      dispatch(addToast({ error: true, text: 'Description is required' }));
      return;
    }

    setLoading(true);
    const updatedAttribute = [];
    (columns || []).forEach(col => {
      (col?.columns || [])?.forEach(item => {
        if (item?.selected) {
          delete item.selected;
          updatedAttribute.push(item);
        }
      });
    });
    const uniqueAttribute = removeDuplicateItem(updatedAttribute, 'id');
    dispatch(createTemplateApi({ request: { ...createTemplate, attributes: [...uniqueAttribute], filters: [] } }))
      .then(() => {
        setLoading(false);
        setShowCreateTemplate(false);
        setCreateTemplate({});
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleRemoveTemplate = () => {
    setLoading(true);
    dispatch(deleteTemplateApi({ templateId: deleteTemplate?.id }))
      .then(() => {
        setLoading(false);
        setDeleteTemplate({});
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenFilter(false);
    }
  };
  const getSelectedFilters = () => {
    let output = [];
    (filters || []).forEach(filter => {
      if (filter.value) {
        if (filter?.childRenderComponent === filterChildRenderComponent?.dateRange) {
          const fromFilter = { param: filter?.minParam, param_value: filter?.value?.fromDate || '' };
          const toFilter = { param: filter?.maxParam, param_value: filter?.value?.toDate || '' };
          output = [...output, fromFilter, toFilter];
        } else if (filter?.childRenderComponent === filterChildRenderComponent?.minMax) {
          const minFilter = { param: filter?.minParam, param_value: filter?.value?.minValue || '' };
          const maxFilter = { param: filter?.maxParam, param_value: filter?.value?.maxValue || '' };
          output = [...output, minFilter, maxFilter];
        } else {
          const selectedItem = { param: filter?.param, param_value: filter?.value?.id || '' };
          output = [...output, selectedItem];
        }
      }
    });
    return output;
  };
  const downloadCsvFile = data => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `Entrant_${replaceTextSymbol(prizeDrawDetail?.code)}.csv`);
    a.click();
  };

  const handleExportData = () => {
    let selectedColumns = [];
    columns.forEach(col => {
      (col?.columns || [])?.forEach(subColumn => {
        if (subColumn.selected) {
          selectedColumns.push({ export_column_id: subColumn?.id });
        }
      });
    });
    if (!selectedColumns.length) {
      dispatch(addToast({ error: true, text: 'Please select columns' }));
      setLoading(false);
      return;
    }
    const uniqueSelectedColumns = removeDuplicateItem(selectedColumns, 'export_column_id');
    const selectedFilters = getSelectedFilters(filters);
    let dateRange = { from: selectedRangeData?.startDate, to: selectedRangeData?.endDate };
    if (selectedRangeData?.value === 'ALL' || selectedRangeData?.value === 'LAST_7_DAYS') {
      dateRange = { ...dateRange, to: moment().unix() };
    }
    const dateFormat = selectedDateFormat?.param;
    setLoading(true);
    dispatch(
      exportEntrantApi({
        request: {
          columns: [...uniqueSelectedColumns],
          filters: [{ param: 'prizedraw_id', param_value: prizeDrawDetail?.id }, ...selectedFilters],
          date_range: { ...dateRange },
          date_format: dateFormat,
          send_to: userDetail.email,
          timezone_id: 'US/Pacific',
        },
      }),
    )
      .then(() => {
        setModal(initModal);
        dispatch(
          addToast({
            error: false,
            text: 'You will receive the file in an email linked with your account',
            id: 'file-export-success',
          }),
        );
        setLoading(false);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'File has not been exported', id: 'file-export-failure' }));
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  const getFilterEnum = () => {
    const {
      category: { tag },
    } = org;
    switch (tag?.tag) {
      case 'AGENCY':
        return multipleFilterTypeEnum.entrants;
      case 'SAAS_OPERATOR':
        return multipleFilterTypeEnum?.entarntSAASOperator;
      default:
        return multipleFilterTypeEnum.entrants;
    }
  };
  const entrantExportUI = (
    <div className=" entrant-wrapper px-12 pb-12 pt-8 flex-column items-center w-full radius-6">
      <label className="regular-text font-24 bold-text">{t('ENTRANT_EXPORT')}</label>
      <div className="flex mt-8 col-gap-10 w-full overflow-hidden">
        <div className="flex-column left-panel-wrapper">
          <div className="flex items-center col-gap-2">
            <p className="semibold-text font-18">{t('EXPORT_FILTER')}</p>
            <div className=" " onBlur={onBlur} tabIndex={0}>
              <div
                className="filter pxy-2 mr-6 flex items-center justify-center cursor"
                onClick={() => setOpenFilter(!openFilter)}>
                <FilterIcon className={`${(openFilter || filters.some(f => f.value)) && 'open-filter-icon'}`} />
              </div>
              {openFilter && (
                <MultipleFilter
                  className="prize-draw-entrant-filter"
                  filters={filters}
                  setFilters={setFilters}
                  filterFor={getFilterEnum()}
                />
              )}
            </div>
          </div>
          <FilterBox
            className={'mt-2'}
            selectedRangeData={selectedRangeData}
            setSelectedDateRange={dateRange =>
              setSelectedDateRange({
                ...dateRange,
                startDate: dateRange?.value === 'ALL' ? 0 : dateRange?.startDate,
              })
            }
            selectedDateFormat={selectedDateFormat}
            setSelectedDateFormat={dateFormat => setSelectedDateFormat(dateFormat)}
          />
          <div className="flex-column w-full mt-6 flex-1 overflow-scroll">
            <div className="flex col-gap-2">
              <label className="regular-text semibold-text font-18 flex-1">{t('TEMPLATE')}</label>
              {/* <InputSearch
                placeholder={'Search'}
                value={''}
                onChange={() => {}}
                className="input-search"
                inputType="number"
              /> */}
            </div>
            <div className="flex col-gap-2 mt-2">
              <Checkbox
                checked={showCreateTemplate}
                onChange={checked => {
                  if (!checked) {
                    setCreateTemplate({});
                  } else {
                    setColumns(exportColumnList);
                    setSelectedTemplate({});
                    setEditTemplate({});
                  }
                  setShowCreateTemplate(checked);
                }}
              />
              <label className="flex flex-1 regular-text">{t('CREATE_TEMPLATE')}</label>
            </div>
            <div className="flex-column row-gap-2 w-full flex-1 overflow-scroll mt-4">
              {showCreateTemplate && (
                <div className="flex-column relative row-gap-2 pxy-2 edit-template-wrapper radius-4">
                  <input
                    className="input w-full edit-input"
                    value={createTemplate?.name}
                    onChange={({ target }) => setCreateTemplate({ ...createTemplate, name: target.value })}
                    placeholder="Name"
                    autoFocus
                  />
                  <input
                    className="input w-full edit-input"
                    value={createTemplate?.description}
                    onChange={({ target }) => setCreateTemplate({ ...createTemplate, description: target.value })}
                    placeholder="Description"
                    autoFocus
                  />
                  <UpdateAction
                    className="update-btns"
                    onCancel={() => {
                      setShowCreateTemplate(false);
                      setCreateTemplate({});
                    }}
                    onUpdate={() => handleCreateTemplate()}
                  />
                </div>
              )}
              {(templates || []).map(template => (
                <TemplateItem
                  template={template}
                  editTemplate={editTemplate}
                  handleTemplateChanges={(field, value) => handleTemplateChanges(field, value)}
                  setEditTemplate={data => {
                    setSelectedTemplate({});
                    setShowSaveChangesBtn(false);
                    setColumns(exportColumnList);
                    setEditTemplate(data);
                  }}
                  handleUpdateTemplate={() => handleUpdateTemplate()}
                  handleDeleteTemplate={template => setDeleteTemplate(template)}
                  handleSelectedTemplate={template => handleSelectedTemplate(template)}
                  selectedTemplate={selectedTemplate}
                  showSaveChangesBtn={showSaveChangesBtn}
                  handleSelectedTemplateUpdate={() => handleSelectedTemplateUpdate()}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex-column right-panel-wrapper row-gap-4">
          <div className="flex col-gap-2">
            <p className="semibold-text font-18 flex-1">Select</p>
            <div className="flex items-center justify-center col-gap-2">
              <Checkbox onChange={checked => handleSelectedAllColumns(checked)} checked={selectAllColumn} />
              <p className="regular-text font-14">Select all</p>
            </div>
          </div>
          <div className="flex-column w-full flex-1 overflow-scroll row-gap-2">
            {(columns || []).map(column => (
              <ColumnItem
                column={column}
                handleCheckColumn={(columnId, checked, subColumnId) =>
                  handleCheckColumn(columnId, checked, subColumnId)
                }
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-10 col-gap-6 w-50">
        <Button
          label="Cancel"
          size="large"
          borderRadius="16px"
          className="primary-white specified-width"
          onClick={() => setModal(initModal)}
          width="100%"
          disabled={loading}
        />
        <Button
          label="Export"
          size="large"
          borderRadius="16px"
          className="primary"
          width="100%"
          onClick={() => handleExportData()}
          disabled={loading}
        />
      </div>
    </div>
  );
  const deleteTemplateUI = (
    <CommonPopup
      popupTitle={'Are you sure you want to delete this template?'}
      confirmButtonProps={{
        label: 'Delete',
        className: 'negative',
      }}
      disabled={loading}
      onCancel={() => setDeleteTemplate({})}
      onConfirm={() => handleRemoveTemplate()}>
      <div className="flex-column template-details-container items-center mt-6 pxy-4 w-full radius-4">
        <div className="flex justify-between items-center w-full">
          <label className="medium-text">{deleteTemplate?.name}</label>
        </div>
        <div className="flex items-center justify-start w-full">
          <label className="medium-text lighter-text">{deleteTemplate?.description}</label>
        </div>
      </div>
    </CommonPopup>
  );

  return <EntrantExportWrapper>{deleteTemplate?.id ? deleteTemplateUI : entrantExportUI}</EntrantExportWrapper>;
};

export default EntrantExport;
