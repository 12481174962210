import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrizeDrawEntrantDetailsRightWarpper } from '../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import EntryReferrals from './prize-draw-entrant-details-entry-referrals';
import PrizeDrawEntrantLogs from './prize-draw-entrant-logs';
import PrizedrawEntrantsNotes from './prize-draw-entrant-notes';
import Overview from './prize-draw-entrant-overview';

const PrizeDrawEntrantDetailsRight = () => {
  return (
    <div className="flex-column flex-1 overflow-scroll details-entra-ref-main">
      <PrizeDrawEntrantDetailsRightWarpper className="flex border overflow-hidden radius-4">
        <Routes>
          <Route element={<Overview />} path="/overview" />
          <Route element={<PrizedrawEntrantsNotes />} path="/notes" />
          <Route element={<EntryReferrals />} path="/entry-referral" />
          <Route element={<PrizeDrawEntrantLogs />} path="/logs" />
        </Routes>
      </PrizeDrawEntrantDetailsRightWarpper>
    </div>
  );
};

export default PrizeDrawEntrantDetailsRight;
