import styled from 'styled-components';

export const AutomationListWrapper = styled.div`
  overflow: scroll;
`;

export const AutomationListItemWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 230px) minmax(250px, 1fr) minmax(230px, 260px) minmax(80px, 80px)
    minmax(40px, 40px);
  min-height: 76px;
  max-height: 76px;
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.hoverColor};
  }

  .owner-container {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
  }
`;
