import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizedrawClientRemoveAdded = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const {
    event_type,
    event_data: { prizedraw, client },
  } = log;

  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={event_type === 'PRIZEDRAW_CLIENT_REMOVE' ? 'Client removed' : 'Client added'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">
              {event_type === 'PRIZEDRAW_CLIENT_REMOVE'
                ? 'removed client organization from Prize Draw'
                : 'added client organization to Prize Draw'}
            </span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'Client draw code'}
            contentText={<p className="regular-text lighter-text font-12">{client.client_draw_code}</p>}
          />
          <LogContentBox
            title={'Client'}
            contentText={
              <div className="flex items-center w-full">
                <p className="regular-text lighter-text font-12">{client?.name}</p>
                <p className="medium-text lighter-text font-12 ml-8">{client?.code}</p>
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

export default PrizedrawClientRemoveAdded;
