import styled from 'styled-components';

export const GroupDetailWrapper = styled.div`
  overflow: hidden;
`;

export const GroupDetailLeftWrapper = styled.div`
  height: fit-content;
  min-width: 340px;
  max-width: 340px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .status-container {
    width: fit-content;
    border-radius: 18px;
    padding: 2px 8px;
    background: ${({ theme }) => theme.colors.ZenBlueSecondary};

    label {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
  }
  .display-none {
    display: none;
  }

  .description-input {
    font-size: 14px;
  }

  .empty-tag {
    min-height: 24px;
  }

  .hover-edit {
    .edit-icon {
      display: none;
    }

    .role-edit-icon {
      display: none;
      position: absolute;
      right: 10px;
    }

    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      margin-top: 0px;
      margin-bottom: -4px;

      .edit-icon {
        display: flex;
      }
      .role-edit-icon {
        display: flex;
      }

      .hover-margin {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .hover-margin-bottom {
        margin-bottom: 4px;
      }
    }
  }

  .update-action {
    margin-top: 4px;
  }

  .user-dropdown {
    border-radius: 16px;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.divider};
    &:hover {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
  }
  .selected {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .menu-wrapper {
    border-radius: 16px;
    padding: 4px 8px;
  }
  .option-wrapper {
    cursor: pointer;
    height: 32px;
    border-radius: 8px;
    pointer: cursor;
    &:hover {
      background: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  .error-container {
    top: -20px;
    background: #ffffff;
    box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
    border-radius: 8px;
    width: fit-content;
    left: 0;

    .border-left-content {
      display: flex;
      margin-right: 4px;
      border-radius: 8px 0 0 8px;
      width: 8px;
      background: ${({ theme }) => theme.colors.ZenNegative};
    }
  }

  .tooltip-content {
    padding: 3px;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      min-width: 320px;
      max-width: 320px;
      transition: width 400ms linear;

    }

    ${up(breakpoints.xl)} {
      min-width: 400px;
      max-width: 400px;
      transition: width 400ms linear;
    }
  `}

  .provider-selector {
    .mc-options {
      bottom: 45px !important;
      top: unset;
    }
  }
`;

export const GroupHeaderWrapper = styled.div``;

export const GroupDetailsRigthWrapper = styled.div``;

export const GroupAccountsWrapper = styled.div`
  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% + 1px) !important;
    }
  }
`;

export const GroupAccountItemWrapper = styled.div`
  min-height: 72px;
  display: grid;
  grid-template-columns: minmax(165px, 200px) minmax(225px, 300px) minmax(170px, 1fr) minmax(160px, 210px);

  .category-container {
    background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    border-radius: 18px;
    padding: 0px 8px;
    label {
      color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .tooltip-content {
    padding: 3px 6px;
    border-radius: 12px;
  }

  .abbrivation-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 18px;
    border: 1px dashed ${({ theme }) => theme.colors.ZenBlueSecondary};
    margin-right: 6px;
  }

  .tooltip-content {
    margin-top: 8px;
  }

  .abbrivation-tooltip-container {
    max-width: 177px;
    margin: 13px 10px;
  }
`;

export const AccountsDetailLeftWrapper = styled.div`
  height: fit-content;
  min-width: 340px;
  max-width: 340px;
  border-radius: 16px;
  max-height: 100%;

  .display-none {
    display: none;
  }

  .user-icon {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.backgroundColor};
    padding: 8px;
    border-radius: 50%;
    span {
      color: ${({ theme }) => theme.colors.zenGray};
    }
  }

  .user-dropdown {
    max-height: 40px;
    border-radius: 16px;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.divider};

    &:hover {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
    &:active {
      border-color: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .menu-wrapper {
    border-radius: 16px;
    padding: 4px 8px;
  }

  .height {
    height: 40px;
  }
  .primary-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .billing {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    color: ${({ theme }) => theme.colors.ZenPositive};
    height: 16px;
  }

  .shipping {
    background: ${({ theme }) => theme.colors.ZenOrangeSecondary};
    color: ${({ theme }) => theme.colors.ZenOrange};
    height: 16px;
  }

  .address-fields {
    row-gap: 8px;

    .line-1,
    .line-2,
    .city,
    .state,
    .zip-code {
      row-gap: 4px;
    }

    .input,
    .mc-select {
      height: 40px;
    }

    .address-input {
      .async-select__control {
        height: 40px;
        min-height: 40px;
      }
    }

    .selected-name {
      font-size: 16px;
    }

    .error-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative};

      .organisations-border {
        border-color: ${({ theme }) => theme.colors.ZenNegative};
      }
    }
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      min-width: 320px;
      max-width: 320px;
      transition: width 400ms linear;

    }

    ${up(breakpoints.xl)} {
      min-width: 400px;
      max-width: 400px;
      transition: width 400ms linear;
    }
  `}

  .content-hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      padding-left: 8px;
      .edit-icon {
        display: flex;
      }
    }
  }

  .update-detail-container {
    .input {
      height: 40px;
    }
  }
  .update-action-container {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .account-status {
    height: 16px;
  }

  .user-icon {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.backgroundColor};
    padding: 8px;
    border-radius: 50%;
    span {
      color: ${({ theme }) => theme.colors.zenGray};
    }
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      position: absolute;
      top: 48px !important;
    }
  }

  .update-action-container-user {
    position: relative;
    top: 4px;
  }
`;

export const AccountDetailsRightWrapper = styled.div`
  .result-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    height: 24px;
  }

  .input-search {
    .input {
      width: 180px;
    }
  }
`;

export const AccountDetailsHeaderWrapper = styled.div``;

export const PrizeDrawAccessWarpper = styled.div``;

export const PrizeDrawAccessItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(170px, 220px) minmax(170px, 1fr) minmax(160px, 180px);
  min-height: 56px;
  max-height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};

  .empty-img-content {
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.hoverColor};
  }

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.colors.divider};
  }
`;
