import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { getItemFromLocalStorage } from '../../../../helpers/localstorage';
import useDebounce from '../../../../helpers/useDebounceHook';
import { getAccountList } from '../../../../store/features/groupsSlice';
import { GroupAccountsWrapper } from '../../../../styles/components/group-details/group-detail.styled';
import Button from '../../../Elements/button/button';
import InputSearch from '../../../common/input-search';
import Loader from '../../../common/loader';
import NoDataComponent from '../../../common/no-data-component';
import NoResultComponent from '../../../common/no-result-component';
import GroupHeader from '../group-header';
import AccountItem from './account-item';

const Accounts = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const { accountList, groupDetails } = useSelector(state => state.groups);
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const userOrganization = getItemFromLocalStorage('user').organizations[0];
  const { id } = useParams();
  const debouncedSearch = useDebounce(searchText, 500);
  const { hasPermission, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);

  const getAccountListData = (page, merge) => {
    pageRef.current = page;
    if (!merge && page === 0) {
      setLoading(true);
    }
    dispatch(
      getAccountList({
        params: { organization_category_id: id, page: page, search: debouncedSearch, size: 20 },
        organizationID: userOrganization.id,
        merge: merge,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getAccountListData(0, false);
  }, [id, debouncedSearch]);

  const fetchMoreData = () => {
    getAccountListData(pageRef.current + 1, true);
  };

  const handleAddAccount = () => {
    setModal({
      type: 'add-account',
      content: {
        onSuccess: () => getAccountListData(0, false),
      },
    });
  };

  return (
    <GroupAccountsWrapper className="flex-column items-start justify-start w-full h-full">
      <GroupHeader
        title={t('ACCOUNTS')}
        actionContent={
          <div className="items-center justify-between w-full mr-2">
            <div className="flex items-center justify-end flex-1">
              <Button
                className={`default-secondary mr-6 ${!hasPermission('ORGANIZATIONS_MANAGE') && 'disabled'}`}
                label={t('ADD_NEW')}
                borderRadius={'12px'}
                size={'medium'}
                width={'105px'}
                icon={<PlusIcon height={16} width={16} />}
                onClick={() => handleAddAccount()}
              />
              <InputSearch
                placeholder={'Search'}
                value={searchText}
                onChange={setSearchText}
                className="input-search"
              />
            </div>
          </div>
        }
      />
      <div className="flex-column flex-1 overflow-auto w-full" id="scrollableDiv">
        {loading ? (
          <Loader height={32} width={32} />
        ) : accountList?.content?.length > 0 ? (
          <InfiniteScroll
            className="flex-column flex-1"
            dataLength={(accountList?.content || [])?.length}
            hasMore={!accountList?.last}
            height={72}
            loader={<Loader height={32} width={32} />}
            next={fetchMoreData}
            scrollableTarget="scrollableDiv">
            {accountList?.content?.map(account => (
              <AccountItem account={account} groupDetails={groupDetails} getAccountListData={getAccountListData} />
            ))}
          </InfiniteScroll>
        ) : debouncedSearch ? (
          <NoResultComponent />
        ) : (
          <NoDataComponent />
        )}
      </div>
    </GroupAccountsWrapper>
  );
};

export default Accounts;
