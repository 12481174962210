import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../../../../assets/images/copy-icons.svg';
import { ReactComponent as User } from '../../../../../assets/images/user-icon.svg';
import { handleCopyClick } from '../../../../../helpers/utils';
import { prizeDrawEntrantEntryreferrals } from '../../../../../store/features/prizeDrawSlice';
import { addToast } from '../../../../../store/features/toastSlice';
import Menu from '../../../../common/menu';
const Referrals = () => {
  const { prizeDrawEntrantEntryReferralsDetails } = useSelector(state => state.prizeDraw);
  const { referees, referral_source, referral_url, total_bonus_entries } = prizeDrawEntrantEntryReferralsDetails || {};
  const { t } = useTranslation();
  const { id, entrant_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(prizeDrawEntrantEntryreferrals(entrant_id));
  }, []);

  const showRefferal = () => {
    return referral_url || referral_source || referees?.length > 0;
  };

  const onDetails = entrantIdToNavigate => {
    if (entrantIdToNavigate) {
      navigate(`/prize-draws/all/${id}/entrant-details/${entrantIdToNavigate}/overview`);
    }
  };

  const handleReferralCopy = async referral_url => {
    await handleCopyClick(referral_url);
    dispatch(addToast({ text: 'Copied Successfully' }));
  };

  return (
    <div className="px-6 pt-6 flex flex-column">
      {showRefferal() && (
        <label className="medium-text font-16 lighter-text divider-bottom w-full flex pb-2">{t('REFERRALS')}</label>
      )}
      {referral_url && (
        <div className="border radius-4 py-3 px-4 mt-4 flex-column  gap-2">
          <div className="flex-row w-full gap-4 justify-between items-center">
            <div className="border radius-3 px-4 py-2 one-line w-full">{referral_url}</div>
            <div
              className="flex zen-purple-text gap-2 bg-purple pxy-2 radius-3 items-center cursor"
              onClick={() => handleReferralCopy(referral_url)}>
              <CopyIcon />
              Copy
            </div>
          </div>
          <label className="regular-text lighter-text font-12">Extra entries {total_bonus_entries}x</label>
        </div>
      )}
      {referral_source && (
        <>
          <label className="semibold-text font-12 mt-4">{t('REFERRAL_SOURCE')}</label>
          <div className="pt-2 border radius-4 px-3 py-4 mt-2 flex-row justify-between items-center">
            <div>
              <div className="flex-row gap-2 items-center">
                <User />
                <label className="regular-text font-14 fw-400">{referral_source?.name}</label>
              </div>
              <div className="flex-row gap-2 items-center mt-1">
                <label className="regular-text lighter-text font-12">
                  Created date {moment(referral_source?.created_on * 1000).format('MMM DD, YYYY')}
                </label>
                <label className="dot-3-lighter-text"></label>
                <label className="regular-text lighter-text font-12">Id {referral_source?.urn}</label>
                <label className="dot-3-lighter-text"></label>
                <label className="regular-text lighter-text font-12">
                  External reference {referral_source?.external_reference}
                </label>
              </div>
            </div>
            <Menu menuList={[{ name: 'Details', onClick: () => onDetails(referral_source?.id) }]} />
          </div>
        </>
      )}
      {referees?.length > 0 && (
        <>
          <label className="semibold-text font-12 mt-4">{t('REFEREES')}</label>
          <div className="flex gap-2 flex-column">
            {referees?.map(item => {
              return (
                <div className="pt-2 border radius-4 px-3 py-4 mt-2 flex-row justify-between items-center">
                  <div>
                    <div className="flex-row gap-2 items-center">
                      <User />
                      <label className="regular-text font-14 fw-400">{item?.name}</label>
                      <label className="regular-text radius-4 font-10 green-positive-text bg-green px-2 fw-500">
                        +{item?.bonus_entries} Entry
                      </label>
                    </div>
                    <div className="flex-row gap-2 items-center mt-1">
                      <label className="regular-text lighter-text font-12">
                        Created date {moment(item?.created_on * 1000).format('MMM DD, YYYY')}
                      </label>
                      <label className="dot-3-lighter-text"></label>
                      <label className="regular-text lighter-text font-12">Id {item?.urn}</label>
                      <label className="dot-3-lighter-text"></label>
                      <label className="regular-text lighter-text font-12">
                        External reference {item?.external_reference}
                      </label>
                    </div>
                  </div>
                  <Menu menuList={[{ name: 'Details', onClick: () => onDetails(item?.id) }]} />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Referrals;
