import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BillingOverviewList from '../../components/billing-overview-list';
import Loader from '../../components/common/loader';
import NoDataComponent from '../../components/common/no-data-component';
import PageHeader from '../../components/page-header';
import { exportTransactionFeeList, getTransactionFeesList } from '../../store/features/billingSlice';
import { addToast } from '../../store/features/toastSlice';
import { BillingOverviewWrapper } from '../../styles/pages/billing-overview.styled';

const BillingOverview = () => {
  const dispatch = useDispatch();
  const { transactionFeesList } = useSelector(state => state.billing);

  const [loading, setLoading] = useState(false);
  const [sortedBy, setSortedBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [startDate, setStartDate] = useState(moment().subtract(12, 'months').unix());
  const [endDate, setEndDate] = useState(moment().unix());

  const getFeesListData = (showLoading = true) => {
    setLoading(showLoading);
    dispatch(
      getTransactionFeesList({
        params: {
          sort_by: sortedBy,
          order_by: orderBy,
          start_date: startDate,
          end_date: endDate,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getFeesListData(false);
  }, [sortedBy, orderBy]);

  useEffect(() => {
    getFeesListData(true);
  }, [startDate, endDate]);

  const downloadCsvFile = data => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'billing-overview.csv');
    a.click();
  };

  const onExportClick = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(
      exportTransactionFeeList({
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
    )
      .then(response => {
        downloadCsvFile(response);
        dispatch(addToast({ error: false, text: 'File has been exported', id: 'file-export-success' }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'File has not been exported', id: 'file-export-failure' }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <BillingOverviewWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Billing', onClick: () => {} },
          { text: 'Overview', onClick: () => {} },
        ]}
        showDateRangeFilter
        showExportButton={transactionFeesList?.length > 0}
        exportButtonPermission={'TRANSACTION_FEES_EXPORT'}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onExportClick={onExportClick}
      />
      {loading ? (
        <Loader height={64} width={64} />
      ) : transactionFeesList?.length > 0 ? (
        <BillingOverviewList setSortedBy={setSortedBy} setOrderBy={setOrderBy} orderBy={orderBy} sortedBy={sortedBy} />
      ) : (
        <NoDataComponent />
      )}
    </BillingOverviewWrapper>
  );
};

export default BillingOverview;
