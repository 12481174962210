import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize, formatText } from '../../helpers/utils';
import DonutChartV2 from '../Charts/DonutV2';
const Gender = ({ expanded }) => {
  const { globalStats } = useSelector(state => state.dashboard) || [];
  const data = globalStats?.gender || [];
  const { t } = useTranslation();

  const dataSet = data
    .filter(item => item.count)
    .map(item => ({ key: item.key, value: item.count, name: capitalize(formatText(item.key)) }))
    .sort((a, b) => b.value - a.value);

  return (
    <div
      className={`border radius-4 pxy-4 flex-column flex-1 h-full ${expanded ? 'genderchart-box' : ''}`}
      style={{ height: 350, width: 415 }}>
      <label className="font-12 lighter-text regular-text">{t('ENTRANTS')}</label>
      <label className="font-20 medium-text mb-4">{t('GENDER')}</label>
      <DonutChartV2 dataSet={dataSet} Tootltiptile={'Entrants'} />
    </div>
  );
};

export default Gender;
