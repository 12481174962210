import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyImage } from '../../../assets/images/subtract.svg';

const ImageElement = ({ src, alt = 'image', height, width }) => {
  return (
    <ImageWrapper className="flex items-center justify-center">
      {src ? (
        <img className={`radius-3 ${!width && 'w-full'}`} src={src} alt={alt} height={height} width={width} />
      ) : (
        <div className="flex flex-1 items-center justify-center radius-4 empty-img-content">
          <EmptyImage className="empty-img" height={64} width={80} />
        </div>
      )}
    </ImageWrapper>
  );
};

const ImageWrapper = styled('div')`
  .empty-img-content {
    min-height: 124px;
    background: ${({ theme }) => theme.colors.backgroundColor};

    .empty-img {
      path {
        fill: ${({ theme }) => theme.colors.divider};
        opacity: 1;
      }
    }
  }
`;

export default ImageElement;
