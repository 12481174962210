import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize, formatText } from '../../../helpers/utils';
import Menu from '../../common/menu';

const AutomationLogEventDetails = () => {
  const { t } = useTranslation();
  const { automationLogDetails: automationLog } = useSelector(state => state.automations);
  return (
    <>
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text fw-500">{t('EVENT')}</label>
        <Menu />
      </div>
      <div className="pxy-6 flex-column flex-1">
        <div className="flex-column">
          <span className="regular-text grey-text font-12 mb-1">{t('NAME')}</span>
          <div className="flex items-center">
            <label className="semibold-text font-16 flex-1">
              {capitalize(formatText(automationLog?.event?.event_type, ' '))}
            </label>
          </div>
        </div>
        <div className="flex-column mt-6">
          <span className="regular-text grey-text font-12 mb-1">{t('ID')}</span>
          <div className="flex items-center">
            <label className="regular-text flex-1">{automationLog?.event?.id}</label>
          </div>
        </div>
        <div className="flex-column mt-6">
          <span className="regular-text grey-text  font-12 mb-1">{t('DATE')}</span>
          <div className="flex items-center">
            <label className="regular-text flex-1">
              {moment(automationLog?.event?.created_on * 1000).format('MM/DD/YY - hh:mm a')}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomationLogEventDetails;
