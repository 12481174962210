import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../../assets/images/burger.svg';
import { AutomationDetailsHeaderWrapper } from '../../../styles/components/automationDetails/automation-details.styled';
import Menu from '../../common/menu';

const AutomationDetailsHeader = ({ title, titleContent = null, actionContent = null }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onMenuClick = navigateTo => {
    navigate(`/platforms/automations/${id}/${navigateTo}`);
  };

  const menuList = [
    {
      name: 'Configuration',
      onClick: () => onMenuClick('configuration'),
    },
    {
      name: 'Logs',
      onClick: () => onMenuClick('logs'),
    },
  ];

  return (
    <AutomationDetailsHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 ">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text fw-500 font-20">{title}</label>
        {titleContent}
      </div>
      {actionContent}
      <Menu Icon={HamburgerIcon} menuList={menuList} tooltipContent={'Menu'} />
    </AutomationDetailsHeaderWrapper>
  );
};

export default AutomationDetailsHeader;
