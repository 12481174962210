import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { prizeDrawEntrantDetails } from '../../../store/features/prizeDrawSlice';
import PrizeDrawEntrantDetailsLeft from './prize-draw-entrant-details-left';
import PrizeDrawEntrantDetailsRight from './prize-draw-entrant-details-right';

const PrizeDrawEntrantDetails = () => {
  const { id, entrant_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (entrant_id) {
      dispatch(prizeDrawEntrantDetails(entrant_id));
    }
  }, [entrant_id]);

  return (
    <>
      <PrizeDrawEntrantDetailsLeft />
      <PrizeDrawEntrantDetailsRight />
    </>
  );
};

export default PrizeDrawEntrantDetails;
