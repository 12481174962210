import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Placeholder } from '../assets/images/subtract.svg';
import DropDown from '../components/McDropdown';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import useDebounce from '../helpers/useDebounceHook';
import { createForm, getFormType, getPrizeDrawClient, getPrizeDrawList } from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AddFormWrapper } from './modal.styled';
const RenderPrizeDrawOption = props => {
  const { option: value, handleSelect, form, index } = props;
  return (
    <div
      className={`flex items-center justify-between px-2 py-1 prizedraw-option cursor radius-2 ${
        index > 0 ? 'mt-1' : ''
      } ${value?.id === form?.prizeDraw?.id ? 'selected-option' : ''}`}
      onClick={() => handleSelect(value)}>
      <div className="flex items-center flex-1">
        {value?.image?.url ? (
          <img className="flex items-center justify-center prize-draw-img-wrapper" src={value?.image?.url} />
        ) : (
          <div className="flex items-center justify-center radius-3 empty-img-content">
            <Placeholder height={32} width={32} />
          </div>
        )}
        <label className="regular-text font-16 flex-1 ml-2 label-txt width-120 one-line">{value?.name}</label>
      </div>
      <div>
        <label className="regular-text font-16 ml-2 code-txt">{value?.code}</label>
      </div>
    </div>
  );
};

const RenderClientDrawOption = props => {
  const { option: value, handleSelect, form } = props;
  return (
    <div
      className={`flex items-center justify-between px-2 client-option cursor radius-2 ${
        value?.id === form?.prizeDraw?.id ? 'selected-option' : ''
      }`}
      onClick={() => handleSelect(value)}>
      <div className="flex items-center flex-1">
        <label className="regular-text font-16 flex-1 ml-2 label-txt">{value?.organization?.name}</label>
      </div>
      <div className="flex items-center justify-center client-code-container">
        <label className="normal-text zen-default-text font-10">{value?.organization?.code}</label>
      </div>
    </div>
  );
};

const RenderFormOption = props => {
  const { option: value, handleSelect, index } = props;
  return (
    <div className="flex-column items-start w-full">
      <label
        className={`medium-text grey-text font-14 flex option-name w-full py-2 px-2 ${index > 0 ? 'border-top' : ''}`}>
        {value.name}
      </label>
      {value?.options.map(item => (
        <div
          key={item.id}
          className="flex items-center px-2 cursor w-full mc-option-item"
          onClick={() => handleSelect(item)}
          style={{ height: `40px` }}>
          <label className="regular-text flex option-name">{item.name}</label>
        </div>
      ))}
    </div>
  );
};

const AddForm = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess = () => {} } = modal.content;
  const dispatch = useDispatch();
  const prizedrawPageRef = useRef(0);

  const { prizeDrawList, prizeDrawClients, formType, formList } = useSelector(state => state.prizeDraw);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState();
  const [error, setError] = useState({});
  const [prizeDrawText, setPrizeDrawText] = useState('');
  const debouncedPrizeDrawSearch = useDebounce(prizeDrawText, 500);
  const [clientText, setClientText] = useState('');
  const debouncedClientSearch = useDebounce(clientText, 500);
  const [labelText, setLabelText] = useState('');
  const debouncedLabelText = useDebounce(labelText, 500);
  const [lstFormType, setFormType] = useState([]);
  const getPrizeDrawOptions = (page, merge) => {
    prizedrawPageRef.current = page;
    dispatch(getPrizeDrawList({ params: { page: page, search: debouncedPrizeDrawSearch }, merge: merge }));
  };

  const getClientOptions = () => {
    dispatch(getPrizeDrawClient({ params: { search: debouncedClientSearch }, prizeDrawId: form?.prizeDraw?.id }));
  };

  const getLabelOption = () => {
    dispatch(getFormType({ param: { search: debouncedLabelText } }));
  };

  useEffect(() => {
    getPrizeDrawOptions(0, false);
  }, [debouncedPrizeDrawSearch]);

  useEffect(() => {
    if (form?.prizeDraw?.id) getClientOptions(0, false);
  }, [debouncedClientSearch, form?.prizeDraw?.id]);

  useEffect(() => {
    getLabelOption();
  }, [debouncedLabelText]);

  useEffect(() => {
    const customOptions = (formType || []).filter(item => item.category === 'CUSTOM') || [];
    const defaultOptions = (formType || []).filter(item => item.category !== 'CUSTOM') || [];
    setFormType([
      { name: 'DEFAULT', options: [...defaultOptions] },
      { name: 'CUSTOM', options: [...customOptions] },
    ]);
  }, [formType]);

  const checkFormErrors = () => {
    if (
      !form?.name?.trim() ||
      !form?.description?.trim() ||
      !form?.prizeDraw?.id ||
      !form?.client?.id ||
      !form?.label?.id
    ) {
      setError({
        name: !form?.name?.trim(),
        description: !form?.description?.trim(),
        prizeDraw: !form?.prizeDraw?.id,
        client: !form?.client?.id,
        label: !form?.label?.id,
      });
      dispatch(addToast({ error: true, text: 'Please fill all required fields' }));
      return true;
    }
    return false;
  };

  const onConfirmAction = async () => {
    if (loading || checkFormErrors()) {
      return;
    }

    setLoading(true);
    const request = {
      name: form?.name,
      description: form?.description,
      form_type: {
        id: form?.label?.id,
      },
      prizedraw: {
        id: form?.prizeDraw?.id,
      },
      image: form?.prizeDraw.image?.id
        ? {
            id: form?.prizeDraw.image?.id,
          }
        : null,
      prizedraw_client: {
        id: form?.client?.id,
      },
    };

    dispatch(createForm({ request: { ...request }, pageNumber: formList?.total_pages - 1 }))
      .then(response => {
        if (onSuccess)
          onSuccess(
            dispatch(addToast({ error: false, title: 'Add Form', text: 'form has been successfully created ' })),
          );

        setModal(initModal);
      })
      .catch(err => {
        dispatch(addToast({ error: true, text: 'form has been not create' }));
        console.log(err);
      });
  };

  const prizeDrawSelectedValue = value => {
    return (
      <div className={`flex items-center justify-between cursor w-full mr-2`} style={{ marginLeft: '-8px' }}>
        <div className="flex items-center flex-1">
          {value?.image?.url ? (
            <img className="flex items-center justify-center prize-draw-img-wrapper" src={value?.image?.url} />
          ) : (
            <div className="flex items-center justify-center empty-img-content">
              <Placeholder height={32} width={32} />
            </div>
          )}
          <label className="regular-text flex-1 ml-2 font-16 one-line width-120">{value?.name}</label>
        </div>
        <div>
          <label className="regular-text font-16 flex-1 ml-2 code-txt">{value?.code}</label>
        </div>
      </div>
    );
  };

  const clientSelectedValue = value => {
    return (
      <div className="flex items-center justify-between w-full mr-2">
        <div className="flex items-center flex-1">
          <label className="regular-text flex-1 font-16 label-txt">{value?.organization?.name}</label>
        </div>
        <div className="flex items-center justify-center client-code-container">
          <label className="normal-text zen-default-text font-10">{value?.organization?.code}</label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonPopup
        popupTitle={`Add Form`}
        confirmButtonProps={{ label: 'Add' }}
        disabled={loading}
        onCancel={() => setModal(initModal)}
        onConfirm={onConfirmAction}>
        <AddFormWrapper className="flex-column items-center w-full">
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('NAME')}</label>
            <input
              autoComplete="turnoff"
              className={`input ${error?.name && 'error-border'}`}
              onChange={e => {
                setForm({ ...form, name: e.target.value });
                setError({ ...error, name: false });
              }}
              placeholder="Form name"
              value={form?.name || ''}
            />
          </div>
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('DESCRIPTION')}</label>
            <textarea
              className={`input description-input ${error?.description && 'error-border'}`}
              onChange={e => {
                setForm({ ...form, description: e.target.value });
                setError({ ...error, description: false });
              }}
              placeholder="Enter description"
              rows={4}
              value={form?.description || ''}
            />
          </div>
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('PRIZE_DRAW')}</label>
            <DropDown
              className={`provider-selector ${error.prizeDraw && 'error-border'}`}
              options={prizeDrawList?.content}
              selected={form?.prizeDraw}
              setSelected={o => {
                setForm({ ...form, prizeDraw: o, client: null });
                setPrizeDrawText('');
                setError({ ...error, prizeDraw: false });
              }}
              placeholder={`Select`}
              isSearchable={true}
              search={prizeDrawText}
              setSearch={setPrizeDrawText}
              hasMore={!prizeDrawList?.last}
              isPaged={true}
              fetchData={() => getPrizeDrawOptions(prizedrawPageRef.current + 1, true)}
              renderSelectedValue={selectedValue => prizeDrawSelectedValue(selectedValue)}
              RenderOption={props => <RenderPrizeDrawOption {...props} form={form} />}
            />
          </div>
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('CLIENT')}</label>
            <DropDown
              className={`provider-selector ${error.client && 'error-border'}`}
              options={prizeDrawClients}
              selected={form?.client}
              setSelected={o => {
                setForm({ ...form, client: o });
                setClientText('');
                setError({ ...error, client: false });
              }}
              placeholder={`Select`}
              isSearchable={true}
              search={clientText}
              setSearch={setClientText}
              isPaged={true}
              renderSelectedValue={selectedValue => clientSelectedValue(selectedValue)}
              RenderOption={props => <RenderClientDrawOption {...props} form={form} />}
            />
          </div>
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('LABEL')}</label>
            <DropDown
              className={`label-selector ${error.label && 'error-border'}`}
              options={lstFormType}
              selected={form?.label}
              setSelected={o => {
                setForm({ ...form, label: o });
                setLabelText('');
                setError({ ...error, label: false });
              }}
              placeholder={`Select`}
              isSearchable={true}
              search={labelText}
              setSearch={setLabelText}
              RenderOption={props => <RenderFormOption {...props} form={form} />}
              menuHeight={(formType?.length < 4 ? formType?.length : 4) * 40}
            />
          </div>
        </AddFormWrapper>
      </CommonPopup>
    </div>
  );
};

export default AddForm;
