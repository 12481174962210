import styled from 'styled-components';

export const LoginWrapper = styled.div`
  .login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: ${props => props.theme.colors.color};
    background-color: ${props => props.theme.colors.backgroundColor};

    .login {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      justify-content: center;

      .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 488px;

        .logo {
          width: 152px;
          height: 80px;
        }

        .text-log {
          width: 228px;
          height: 48px;
        }

        .form {
          display: flex;
          flex-direction: column;

          .input-label {
            align-self: flex-start;
            font-family: Poppins Light;
            font-size: 12px;
            line-height: 14px;
            margin-top: 24px;
          }

          input {
            outline: 0;
            font-size: 16px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #eceef3;
            border-radius: 16px;
            padding: 0 16px;
            font-family: Poppins Regular;
            font-size: 16px;
            color: #020819;
            width: 100%;

            &:focus {
              border-color: ${({ theme }) => theme.colors.zenPurple};
            }
          }

          .error-border {
            border-color: #d93e38;

            &:focus {
              border-color: #d93e38;
            }
          }

          .show-password {
            position: absolute;
            width: 24px;
            height: 24px;
            right: 16px;
            top: 12px;
            cursor: pointer;
          }

          input[type='submit'] {
            width: 100%;
            margin-top: 40px;
            margin-bottom: 24px;
            height: 56px;
            width: 330px;
            border-radius: 16px;
            align-self: center;
            border: none;
            outline: 0;
            cursor: pointer;

            font-family: Poppins Medium;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            background-color: ${props => props.theme.colors.backgroundColor};
          }

          a {
            text-decoration: none;
            align-self: center;
          }
        }

        .input-container {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
        }

        .error-msg {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          top: 50px;

          .error-icon {
            min-width: 12px;
            min-height: 12px;
            width: 12px;
            height: 12px;
            margin: 0px;
          }

          label {
            font-family: Poppins Medium;
            font-style: normal;
            font-size: 12px;
            color: #d93e38;
            margin-left: 5px;
          }
        }

        .error-message {
          display: flex;
          align-items: center;
          background: #ff406e;
          border-radius: 8px;
          padding: 4px 8px;

          .error-icon {
            path {
              stroke: #ffffff;
            }
          }
        }
      }
    }

    .background-logo {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
`;
