import styled from 'styled-components';

export const IntegrationsDetailWrapper = styled.div`
  overflow: hidden;

  .hover-edit-hide {
    min-height: 32px;
    .edit-icon {
      display: none;
    }
  }

  .description-input {
    font-size: 14px;
  }

  .description-text {
    max-width: 100%;
    word-break: break-word;
  }

  .edit-icon-wrapper {
    min-width: 40px;
    max-width: 40px;
  }
  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      .edit-icon {
        display: flex;
      }

      .hover-edit-margin {
        margin-right: 0;
      }
    }
  }
  .content-hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      padding-left: 8px;
      .edit-icon {
        display: flex;
      }
    }
  }

  .hover-edit-show {
    box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
    border-radius: 8px;
    .edit-icon {
      display: flex;
    }

    .hover-edit-margin {
      margin-right: 0;
    }
  }
`;

export const IntegrationsDetailLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;
  transition: width 400ms linear;

  .update-detail-contaner {
    .input {
      height: 40px;
    }
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      min-width: 320px;
      max-width: 320px;
      transition: width 400ms linear;

    }

    ${up(breakpoints.xl)} {
      min-width: 400px;
      max-width: 400px;
      transition: width 400ms linear;
    }
  `}
`;

export const IntegrationsDetailRightWrapper = styled.div`
  border-radius: 16px;
  max-height: 100%;

  .action-container {
    .update-action {
      bottom: -27px;
      z-index: 2;
    }
  }

  .integration-input {
    .input {
      padding-right: 40px;
    }
    .input-show-img {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }

  .integration-input-dropdown {
    .organisations-border {
      height: 48px;

      .selected-name {
        font-size: 16px;
      }
    }
    .mc-options {
      top: 53px;
    }
  }
`;
