import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { OrganisationContext } from '../../context/organisationContext';
import {
  setIntegrationsDetail,
  updateIntegration,
  updateIntegrationState,
} from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { IntegrationsDetailLeftWrapper } from '../../styles/components/integrations-detail/integrations-detail.styled';
import Menu from '../common/menu';
import Switch from '../common/switch';

import { useNavigate } from 'react-router-dom';
import { isDataChanged } from '../../helpers/utils';
import UpdateAction from '../common/update-action';

const IntegrationsDetailLeft = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);
  const { integrationsDetail } = useSelector(state => state.integrations);

  const [updateIntegrationDetail, setUpdateIntegrationDetail] = useState({ type: '', value: '' });

  const changeIntegrationDetailsStatus = is_enabled => {
    dispatch(setIntegrationsDetail({ ...integrationsDetail, is_enabled: is_enabled }));
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integrationsDetail;
    if (is_enabled) {
      setModal({
        type: 'integration-action',
        content: {
          integration: integrationsDetail,
          top: true,
          isDisable: true,
          onSuccess: () => changeIntegrationDetailsStatus(!is_enabled),
        },
      });
    } else {
      const request = {
        enabled: !is_enabled,
      };
      changeIntegrationDetailsStatus(!is_enabled);
      dispatch(updateIntegrationState({ id: integrationsDetail?.id, request, updateList: true })).catch(error => {
        const errorData = error?.response?.data;
        changeIntegrationDetailsStatus(is_enabled);
        dispatch(
          addToast({
            error: true,
            text:
              errorData?.error_code === 'BAD_REQUEST'
                ? 'Please add all required parameters in integration'
                : 'Error while updating status',
            id: uuid(),
          }),
        );
      });
    }
  };

  const onCancelUpdate = () => {
    setUpdateIntegrationDetail({ type: '', value: '' });
  };

  const onUpdate = () => {
    if (!updateIntegrationDetail.value.trim()) {
      dispatch(addToast({ error: true, text: 'Please enter required field', id: uuid() }));
      return;
    }
    const integrationDetailData = { ...integrationsDetail };
    const updatedDetails = {
      ...integrationDetailData,
      [updateIntegrationDetail.type]: updateIntegrationDetail.value.trim(),
    };
    dispatch(setIntegrationsDetail(updatedDetails));
    dispatch(updateIntegration({ id: updatedDetails.id, request: updatedDetails })).catch(() => {
      dispatch(setIntegrationsDetail(integrationDetailData));
      dispatch(addToast({ error: true, text: 'Error while updating details', id: uuid() }));
    });
    setUpdateIntegrationDetail({ type: '', value: '' });
  };

  const onDelete = () => {
    setModal({
      type: 'integration-action',
      content: {
        integration: integrationsDetail,
        top: true,
        isDelete: true,
        onSuccess: () => navigate('/platforms/integrations'),
      },
    });
  };

  return (
    <IntegrationsDetailLeftWrapper className="border flex-column">
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text">{integrationsDetail?.connector?.name}</label>
        <Menu menuList={[{ name: t('DELETE'), onClick: () => onDelete(), permission: 'INTEGRATIONS_MANAGE' }]} />
      </div>
      <div className="py-6 pl-6 pr-4 flex-column flex-1 overflow-scroll">
        <div className="flex items-center">
          <img
            alt="log"
            src={integrationsDetail?.connector?.icon?.active}
            width={64}
            height={64}
            className="mr-2 border br-2 pxy-2"
          />
          <div className="flex-column flex-1 min-w-0">
            <span className="regular-text grey-text font-12 ml-2">{t('NAME')}</span>
            {updateIntegrationDetail.type === 'name' ? (
              <div className="update-detail-contaner relative">
                <input
                  className="input w-full"
                  value={updateIntegrationDetail.value}
                  onChange={({ target }) =>
                    setUpdateIntegrationDetail({ ...updateIntegrationDetail, value: target.value })
                  }
                  placeholder="Name"
                  autoFocus
                />
                <UpdateAction
                  onCancel={onCancelUpdate}
                  onUpdate={() =>
                    isDataChanged(
                      'INPUT',
                      updateIntegrationDetail.value || '',
                      integrationsDetail?.name || '',
                      onUpdate,
                      onCancelUpdate,
                    )
                  }
                />
              </div>
            ) : (
              <div
                className="flex items-center py-1 px-2 cursor hover-edit"
                onClick={() => setUpdateIntegrationDetail({ type: 'name', value: integrationsDetail?.name })}>
                <span className="semibold-text font-16 flex-1 one-line mr-4 hover-edit-margin">
                  {integrationsDetail?.name}
                </span>
                <EditIcon width={16} height={16} className="edit-icon" />
              </div>
            )}
          </div>
        </div>
        <div className="flex-column mt-6">
          <span className="regular-text grey-text font-12">{t('DESCRIPTION')}</span>
          {updateIntegrationDetail.type === 'description' ? (
            <div className="update-detail-contaner mt-2 relative">
              <textarea
                className="textarea w-full description-input"
                value={updateIntegrationDetail.value}
                onChange={({ target }) =>
                  setUpdateIntegrationDetail({ ...updateIntegrationDetail, value: target.value })
                }
                placeholder="Name"
                rows={4}
                autoFocus
              />
              <UpdateAction
                onCancel={onCancelUpdate}
                onUpdate={() =>
                  isDataChanged(
                    'INPUT',
                    updateIntegrationDetail.value || '',
                    integrationsDetail?.description || '',
                    onUpdate,
                    onCancelUpdate,
                  )
                }
              />
            </div>
          ) : (
            <div
              className="flex items-center py-1 cursor content-hover-edit"
              onClick={() =>
                setUpdateIntegrationDetail({ type: 'description', value: integrationsDetail?.description })
              }>
              <p className="regular-text flex-1 description-text">{integrationsDetail?.description}</p>
              <div className="edit-icon-wrapper flex items-start justify-center self-start mt-1">
                <EditIcon width={16} height={16} className="edit-icon" />
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between mt-6">
          <span className={`regular-text flex items-center`}>
            {integrationsDetail?.is_enabled ? t('ENABLED') : t('DISABLED')}
          </span>
          <Switch
            onClick={() => onChangeStatus()}
            enabled={integrationsDetail?.is_enabled}
            disabled={false}
            permission="INTEGRATIONS_MANAGE"
          />
        </div>
      </div>
    </IntegrationsDetailLeftWrapper>
  );
};

export default IntegrationsDetailLeft;
