import React from 'react';
import { BillingFeesDetailsWrapper } from '../../styles/components/billing-fees-details/billing-fees-details.styled';
import BillingFeesDetailsLeft from './billing-fees-details-left';
import BillingFeesDetailsRight from './billing-fees-details-right';

const BillingFeesDetails = () => {
  return (
    <BillingFeesDetailsWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6 overflow-hidden">
      <BillingFeesDetailsLeft />
      <BillingFeesDetailsRight />
    </BillingFeesDetailsWrapper>
  );
};

export default BillingFeesDetails;
