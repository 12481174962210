import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as UpIcon } from '../../assets/images/sorting.svg';
import { ReactComponent as Logo } from '../../assets/images/zenterprize-logo.svg';
import { getCurrencySymbol } from '../../helpers/utils';
import { BillingOverviewListWrapper } from '../../styles/components/billing-overview-list/billing-overview-list.styled';

const sortedEnum = {
  start: 'START_DATE',
  end: 'END_DATE',
  plateformFee: 'PLATFORM_FEE',
  procressorFee: 'PROCESSOR_FEE',
};

const renderSortableHeader = (
  label,
  sortKey,
  sortedBy,
  orderBy,
  handleSorting,
  showBorderRight = true,
  showLogoIcon = false,
) => {
  const handleClick = () => {
    handleSorting(sortKey);
  };

  return (
    <div className={`flex w-full items-center py-1 overflow-hidden my-2 ${showBorderRight && 'border-right'}`}>
      <label className="flex cursor medium-text font-12 lighter-text gap-6 flex-1 justify-center" onClick={handleClick}>
        <div className="flex items-center">
          {showLogoIcon && <Logo height={16} width={16} className="mr-2" />}
          {label}
        </div>
        {sortedBy === sortKey && orderBy && (
          <UpIcon
            className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
            height={16}
            width={16}
          />
        )}
      </label>
    </div>
  );
};

const BillingOverviewList = ({ setOrderBy, setSortedBy, sortedBy, orderBy }) => {
  const { transactionFeesList } = useSelector(state => state.billing);

  const handleSorting = fieldName => {
    if (!sortedBy) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    } else if (sortedBy === fieldName) {
      if (!orderBy) {
        setOrderBy('DESC');
      } else if (orderBy === 'DESC') {
        setOrderBy('ASC');
      } else if (orderBy === 'ASC') {
        setOrderBy('');
        setSortedBy('');
      }
    } else if (sortedBy !== fieldName) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    }
  };
  return (
    <BillingOverviewListWrapper className="pt-2 flex-column flex-1 overflow-scroll">
      <div className="w-full content-header content-grid">
        {renderSortableHeader('Start', sortedEnum.start, sortedBy, orderBy, handleSorting)}
        {renderSortableHeader('End', sortedEnum.end, sortedBy, orderBy, handleSorting)}
        {renderSortableHeader('Platform fee', sortedEnum.plateformFee, sortedBy, orderBy, handleSorting, true, true)}
        {renderSortableHeader('Processor fee', sortedEnum.procressorFee, sortedBy, orderBy, handleSorting, false)}
      </div>
      <div className="overflow-scroll flex-1">
        {transactionFeesList?.map(({ start_date, end_date, platform_fee, processor_fee }) => (
          <div className="w-full content-body border-bottom cursor content-grid">
            <div className="flex justify-center items-center w-full px-6 border-right">
              <label className={`regular-text font-14 ${!start_date && 'grey-text'}`}>
                {start_date ? moment(start_date * 1000).format('MMM DD, YYYY') : 'no data'}
              </label>
            </div>
            <div className="flex justify-center items-center w-full px-6 border-right">
              <label className={`regular-text font-14 ${!end_date && 'grey-text'}`}>
                {end_date ? moment(end_date * 1000).format('MMM DD, YYYY') : 'no data'}
              </label>
            </div>
            <div className="flex justify-center items-center w-full px-6 border-right">
              <label className={`regular-text font-14 ${!platform_fee.amount && 'grey-text'}`}>
                {platform_fee.amount
                  ? `${getCurrencySymbol(platform_fee.currency || 'USD')}${parseFloat(platform_fee.amount).toFixed(2)}`
                  : 'no data'}
              </label>
            </div>
            <div className="flex justify-center items-center w-full px-6">
              <label className={`regular-text font-14 ${!processor_fee.amount && 'grey-text'}`}>
                {processor_fee.amount
                  ? `${getCurrencySymbol(processor_fee.currency || 'USD')}${parseFloat(processor_fee.amount).toFixed(
                      2,
                    )}`
                  : 'no data'}
              </label>
            </div>
          </div>
        ))}
      </div>
    </BillingOverviewListWrapper>
  );
};

export default BillingOverviewList;
