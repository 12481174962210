import styled from 'styled-components';

export const FormDetailsComponentWrapper = styled.div`
  overflow: hidden;
`;

export const FormDetailsLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;
`;

export const FormDetailsRightWrapper = styled.div``;

export const FormDetailsHeaderWrapper = styled.div``;

export const FormLogWrapper = styled.div`
  .header-container,
  .item-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr) 40px;
  }

  .header-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    height: 40px;
  }

  .item-container {
    min-height: 48px;
    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% + 1px) !important;
    }
  }

  .header-border-left {
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.ZenPositive};
    }

    &:before {
      content: '';
      position: absolute;
      height: 22px;
      left: 0;
      width: 4px;
      background: ${({ theme }) => theme.colors.zenPurple};
      border-radius: 8px;
    }
  }
`;

export const FormVersionDetailsLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .version-date-container {
    padding: 8px 24px 8px 16px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export const FormVersionDetailsRightWrapper = styled.div``;

export const FormVersionDetailsHeaderWrapper = styled.div``;

export const FormVersionLogWrapper = styled.div``;
