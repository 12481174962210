import React from 'react';
import { ReactComponent as EmptyImage } from '../../../../../assets/images/subtract.svg';
import { PrizeDrawAccessItemWrapper } from '../../../../../styles/components/group-details/group-detail.styled';
import Menu from '../../../../common/menu';
import Status from '../../../../common/status';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrizeDrawAccessItem = ({ prizedraw = {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goToPrizeDrawDetail = prizedraw => {
    navigate(`/prize-draws/all/${prizedraw.id}/entrants`);
  };
  return (
    <PrizeDrawAccessItemWrapper className="w-full cursor" onClick={() => goToPrizeDrawDetail(prizedraw)}>
      <div className="flex items-center overflow-hidden px-6 border-right w-full col-gap-4">
        {prizedraw?.image?.url ? (
          <img src={prizedraw?.image?.url} alt="prize-draw-icon" className="radius-4" height={40} width={40} />
        ) : (
          <div className="flex items-center justify-center radius-4 empty-img-content">
            <EmptyImage height={32} width={32} />
          </div>
        )}
        <div className="flex-column overflow-hidden flex-1">
          <label className="regular-text one-line">{prizedraw?.name}</label>
          <label className="regular-text one-line lighter-text font-12">{prizedraw?.code}</label>
        </div>
      </div>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <label className="regular-text grey-text two-lines word-break-all font-14">{prizedraw?.description}</label>
      </div>
      <div className="flex items-center justify-end px-6">
        <Status status={prizedraw?.lifecycle} withDot={true} />
        <Menu menuClassName="ml-4" menuList={[{ name: t('DETAILS'), onClick: () => goToPrizeDrawDetail(prizedraw) }]} />
      </div>
    </PrizeDrawAccessItemWrapper>
  );
};

export default PrizeDrawAccessItem;
