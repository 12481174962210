import moment from 'moment';
import React, { memo } from 'react';
import { PrizeDrawNotesGroupWrapper } from '../../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import NoteItem from './note-item';

const NotesGroup = ({
  day = {},
  dayLogs = [],
  setEditNote = () => {},
  editNote = null,
  handleUpdateNote = () => {},
}) => {
  return (
    <PrizeDrawNotesGroupWrapper className="flex-column w-full mb-6">
      <label className="normal-text lighter-text font-16 mb-4">
        {moment.unix(day).isSame(moment(), 'day') ? 'Today' : moment.unix(day).format('MMMM DD, YYYY')}
      </label>
      <div className="flex-column row-gap-4">
        {dayLogs.map((item, index) => (
          <NoteItem
            key={item.id}
            note={item}
            isLast={index === dayLogs.length - 1 ? true : false}
            setEditNote={data => setEditNote(data)}
            editNote={editNote}
            handleUpdateNote={() => handleUpdateNote()}
          />
        ))}
      </div>
    </PrizeDrawNotesGroupWrapper>
  );
};

export default memo(NotesGroup);
