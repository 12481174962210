import moment from 'moment';
import React from 'react';
import { ActivityLogItemWrapper } from '../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import AccountAccessed from './log-components/account-accessed';
import AccountUpdated from './log-components/account-updated';
import AutomationActionFailed from './log-components/automation-action-failed';
import AutomationActionSucceeded from './log-components/automation-action-succeded';
import AutomationEnableDisableAddedDeleted from './log-components/automation-enable-disable-added-delete';
import AutomationUpdated from './log-components/automation-updated';
import BillingAddressUpdated from './log-components/billing-address-updated';
import BillingOverviewExport from './log-components/billing-overview-export';
import ClientFeeProductAddedDeleted from './log-components/client-fee-product-added-deleted';
import ClientProductFeeUpdated from './log-components/client-fee-product-updated';
import DrawConfigUpdate from './log-components/draw-config-update';
import EmailUpdated from './log-components/email-updated';
import EntrantAnonymized from './log-components/entrant-anonymized';
import EntrantCancel from './log-components/entrant-cancel';
import EntrantCreated from './log-components/entrant-created';
import EntrantDrawStatusUpdate from './log-components/entrant-draw-status-update';
import EntrantExport from './log-components/entrant-export';
import EntrantExportTemplateCreated from './log-components/entrant-export-template-created';
import EntrantExportTemplateDeleted from './log-components/entrant-export-template-deleted';
import EntrantExportTemplateUpdated from './log-components/entrant-export-template-update';
import EntrantPaymentMethodUpdate from './log-components/entrant-payment-method-update';
import EntrantPaymentStatusUpdate from './log-components/entrant-payment-status-update';
import EntrantWinStatusUpdate from './log-components/entrant-win-status-update';
import GroupAdded from './log-components/group-added';
import GroupDeleted from './log-components/group-deleted';
import GroupUpdated from './log-components/group-updated';
import IntegrationEnableDisableAddedDeleted from './log-components/integration-enable-disable-added-delete';
import IntegrationUpdated from './log-components/integration-update';
import NameUpdate from './log-components/name-updated';
import NoteCreated from './log-components/note-created';
import NoteUpdated from './log-components/note-updated';
import OrganizationAccessLog from './log-components/organization-access';
import PaymentFailed from './log-components/payment-failed';
import PaymentSuccess from './log-components/payment-success';
import PhoneUpdated from './log-components/phone-updated';
import PrizeWonLog from './log-components/prize-won-log';
import PrizedrawAdded from './log-components/prizedraw-added';
import PrizedrawClientRemoveAdded from './log-components/prizedraw-client-remove-added';
import PrizeDrawConfigUpdate from './log-components/prizedraw-config-update';
import PrizedrawFormCreated from './log-components/prizedraw-form-created';
import PrizedrawFormUpdated from './log-components/prizedraw-form-updated';
import PrizedrawFormVersionLog from './log-components/prizedraw-form-version-log';
import PrizedrawLifecycleUpdate from './log-components/prizedraw-lifecycle-update';
import PrizedrawPrizesUpdate from './log-components/prizedraw-prizes-update';
import PrizedrawUpdate from './log-components/prizedraw-update';
import ProductAdded from './log-components/product-added';
import ShippingAddressUpdated from './log-components/shipping-address-updated';
import SubmissionPDFDownload from './log-components/submission-pdf-download';
import UserActivated from './log-components/user-activated';
import UserAddDeleteActivateDeactivate from './log-components/user-add-delete-activate-deactivate';
import UserAnonymized from './log-components/user-anonymized';
import UserOTPRequest from './log-components/user-otp-request';
import UserProfileUpdate from './log-components/user-profile-update';

const ActivityLogItem = ({ log, isLast = false }) => {
  const getLogType = () => {
    switch (log?.event_type) {
      case 'ENTRANT_NOTE_CREATED':
        return <NoteCreated log={log} />;
      case 'ENTRANT_NOTE_UPDATED':
        return <NoteUpdated log={log} />;
      case 'SUBMISSION_PDF_DOWNLOADED':
        return <SubmissionPDFDownload log={log} />;
      case 'ENTRANT_SHIPPING_ADDRESS_UPDATED':
        return <ShippingAddressUpdated log={log} />;
      case 'ENTRANT_BILLING_ADDRESS_UPDATED':
        return <BillingAddressUpdated log={log} />;
      case 'AUTOMATION_ACTION_SUCCEEDED':
        return <AutomationActionSucceeded log={log} />;
      case 'AUTOMATION_ACTION_FAILED':
        return <AutomationActionFailed log={log} />;
      case 'ENTRANT_CREATED':
        return <EntrantCreated log={log} />;
      case 'ENTRANT_PAYMENT_SUCCESSFUL':
        return <PaymentSuccess log={log} />;
      case 'ENTRANT_PAYMENT_FAILED':
        return <PaymentFailed log={log} />;
      case 'ENTRANT_DRAW_STATUS_UPDATED':
        return <EntrantDrawStatusUpdate log={log} />;
      case 'ENTRANT_PAYMENT_STATUS_UPDATED':
        return <EntrantPaymentStatusUpdate log={log} />;
      case 'ENTRANT_WIN_STATUS_UPDATED':
        return <EntrantWinStatusUpdate log={log} />;
      case 'ENTRANT_WON':
        return <PrizeWonLog log={log} />;
      case 'ENTRANT_PAYMENT_METHOD_UPDATED':
        return <EntrantPaymentMethodUpdate log={log} />;
      case 'ENTRANT_NAME_UPDATED':
        return <NameUpdate log={log} />;
      case 'ENTRANT_CANCELLED':
        return <EntrantCancel log={log} />;
      case 'ENTRANT_EMAIL_UPDATED':
        return <EmailUpdated log={log} />;
      case 'ENTRANT_PHONE_UPDATED':
        return <PhoneUpdated log={log} />;
      case 'ENTRANT_ANONYMIZED':
        return <EntrantAnonymized log={log} />;
      case 'ORGANIZATION_CATEGORY_CREATED':
        return <GroupAdded log={log} />;
      case 'ORGANIZATION_CATEGORY_UPDATED':
        return <GroupUpdated log={log} />;
      case 'ORGANIZATION_ACCESSED':
      case 'ORGANIZATION_CREATED':
        return <AccountAccessed log={log} />;
      case 'ORGANIZATION_UPDATED':
        return <AccountUpdated log={log} />;
      case 'INTEGRATION_CREATED':
      case 'INTEGRATION_ENABLED':
      case 'INTEGRATION_DISABLED':
      case 'INTEGRATION_DELETED':
        return <IntegrationEnableDisableAddedDeleted log={log} />;
      case 'INTEGRATION_UPDATED':
        return <IntegrationUpdated log={log} />;
      case 'AUTOMATION_CREATED':
      case 'AUTOMATION_ENABLED':
      case 'AUTOMATION_DISABLED':
      case 'AUTOMATION_DELETED':
        return <AutomationEnableDisableAddedDeleted log={log} />;
      case 'AUTOMATION_UPDATED':
        return <AutomationUpdated log={log} />;
      case 'PRIZEDRAW_CREATED':
        return <PrizedrawAdded log={log} />;
      case 'ENTRANTS_EXPORTED':
        return <EntrantExport log={log} />;
      case 'BILLING_OVERVIEW_EXPORTED':
        return <BillingOverviewExport log={log} />;
      case 'PRIZEDRAW_UPDATE':
        return <PrizedrawUpdate log={log} />;
      case 'PRIZEDRAW_LIFECYCLE_UPDATE':
        return <PrizedrawLifecycleUpdate log={log} />;
      case 'PRIZEDRAW_FORM_CREATED':
        return <PrizedrawFormCreated log={log} />;
      case 'PRIZEDRAW_CLIENT_REMOVE':
      case 'PRIZEDRAW_CLIENT_ADD':
        return <PrizedrawClientRemoveAdded log={log} />;
      case 'PRIZEDRAW_FORM_UPDATED':
        return <PrizedrawFormUpdated log={log} />;
      case 'PRIZEDRAW_DRAW_UPDATE':
        return <DrawConfigUpdate log={log} />;
      case 'PRIZEDRAW_FORM_VERSION_STATUS_UPDATE':
      case 'PRIZEDRAW_FORM_VERSION_DUPLICATED':
        return <PrizedrawFormVersionLog log={log} />;
      case 'PRIZEDRAW_CONFIG_UPDATE':
        return <PrizeDrawConfigUpdate log={log} />;
      case 'PRIZEDRAW_ORGANIZATION_ADD':
      case 'PRIZEDRAW_ORGANIZATION_REMOVE':
        return <OrganizationAccessLog log={log} />;
      case 'CLIENT_PRODUCT_FEE_CREATED':
      case 'CLIENT_PRODUCT_FEE_DELETED':
        return <ClientFeeProductAddedDeleted log={log} />;
      case 'CLIENT_PRODUCT_FEE_UPDATED':
        return <ClientProductFeeUpdated log={log} />;
      case 'EXPORT_TEMPLATE_CREATED':
        return <EntrantExportTemplateCreated log={log} />;
      case 'EXPORT_TEMPLATE_UPDATED':
        return <EntrantExportTemplateUpdated log={log} />;
      case 'CLIENT_PRODUCT_CREATED':
        return <ProductAdded log={log} />;
      case 'USER_DELETED':
      case 'USER_INVITED':
        return <UserAddDeleteActivateDeactivate log={log} />;
      case 'USER_OTP_REQUEST':
        return <UserOTPRequest log={log} />;
      case 'PRIZEDRAW_PRIZES_UPDATE':
        return <PrizedrawPrizesUpdate log={log} />;
      case 'EXPORT_TEMPLATE_DELETED':
        return <EntrantExportTemplateDeleted log={log} />;
      case 'ORGANIZATION_CATEGORY_DELETED':
        return <GroupDeleted log={log} />;
      case 'USER_PROFILE_UPDATED':
        return <UserProfileUpdate log={log} />;
      case 'USER_ACTIVATED':
        return <UserActivated log={log} />;
      case 'USER_ANONYMIZED':
        return <UserAnonymized log={log} />;
    }
  };
  return (
    <ActivityLogItemWrapper className="flex w-full col-gap-4">
      <div className="flex-column items-center row-gap-1">
        <label className="medium-text font-12 lighter-text">{moment(log?.performed_on * 1000).format('hh:mm a')}</label>
        {!isLast && <div className="vertical-line" />}
      </div>
      <div className="flex-column flex-1 row-gap-4 w-full">{getLogType()}</div>
    </ActivityLogItemWrapper>
  );
};

export default ActivityLogItem;
