import moment from 'moment/moment';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as ClockIcon } from '../assets/images/time-default.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText } from '../helpers/utils';
import { deleteBillingFee } from '../store/features/billingSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { RemoveBillingFeeWrapper } from './modal.styled';

const RemoveBillingFee = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const { title, subTitle, productDetails, handleSuccess, productFee } = modal.content;

  const [loading, setLoading] = useState(false);

  const onDone = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(
      deleteBillingFee({
        product_id: productDetails?.id,
        fee_id: productFee?.id,
        billingType: productFee?.billing_type,
      }),
    )
      .then(() => {
        setModal(initModal);
        if (handleSuccess) {
          handleSuccess();
        }
        setLoading(false);
        dispatch(
          addToast({
            text: 'Fee deleted',
            id: uuid(),
          }),
        );
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          addToast({
            error: true,
            text: 'Error while deleting',
            id: uuid(),
          }),
        );
      });
  };

  return (
    <CommonPopup
      disabled={loading}
      popupTitle={title}
      popupSubTitle={subTitle}
      confirmButtonProps={{ label: 'Delete', className: 'negative' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}>
      <RemoveBillingFeeWrapper className="w-full radius-4 pxy-4 mt-6">
        <div className="flex mb-2">
          <div className="semibold-text font-16 mr-2">{productDetails?.product?.name}</div>
          <div className="bold-text font-16 zen-purple-text">${productFee?.fee_amount}</div>
        </div>
        <div className="medium-text font-12 mb-4">{moment(productFee?.dates?.start * 1000).format('MMM DD,YYYY')}</div>
        <div className={`flex items-center px-2 status ${formatText(productFee?.billing_type)}`}>
          <ClockIcon className="flex items-center justify-center mr-1" height={16} width={16} color="" />
          <label className="medium-text zen-default-text flex-1 one-line font-12">{`${capitalize(
            formatText(productFee?.billing_type),
          )} billing`}</label>
        </div>
      </RemoveBillingFeeWrapper>
    </CommonPopup>
  );
};

export default RemoveBillingFee;
