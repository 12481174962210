import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrizeDrawEntrantOverviewWrapper } from '../../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import PrizeDrawDetailEntrantHeader from '../prize-draw-entrant-header';
import Payment from './payment';
import PaymentMethod from './paymentMethod';
import PlateformFee from './platform-fee';
import Products from './products';
import Winning from './winning';

const Overview = () => {
  const { prizeDrawEntrantOverviewPaymentMethodsDetails } = useSelector(state => state.prizeDraw);
  const { bank, card } = prizeDrawEntrantOverviewPaymentMethodsDetails || {};
  const { t } = useTranslation();
  return (
    <PrizeDrawEntrantOverviewWrapper className="flex-column w-full h-full">
      <PrizeDrawDetailEntrantHeader title={t('OVERVIEW')} />
      <div className="flex-column flex-1 mb-6 overflow-scroll">
        <Products />
        <Winning />
        <PaymentMethod />
        <Payment />
        <PlateformFee />
      </div>
    </PrizeDrawEntrantOverviewWrapper>
  );
};
export default Overview;
