import moment from 'moment';
import React from 'react';
import { ActivityLogGroupWraper } from '../../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import ActivityLogItem from '../../../prize-draw-logs/activity-log-item';

const ActivityLogGroup = ({ day = {}, dayLogs = [] }) => {
  return (
    <ActivityLogGroupWraper className="flex-column w-full">
      <label className="normal-text lighter-text font-16 mb-4">
        {moment.unix(day).isSame(moment(), 'day') ? 'Today' : moment.unix(day).format('MMMM DD, YYYY')}
      </label>
      <div className="flex-column row-gap-4">
        {dayLogs.map((item, index) => (
          <ActivityLogItem key={item.id} log={item} isLast={index === dayLogs.length - 1 ? true : false} />
        ))}
      </div>
    </ActivityLogGroupWraper>
  );
};

export default ActivityLogGroup;
