import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const UserAddDeleteActivateDeactivate = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState({});
  const { event_data, performed_by, event_type, id } = log;
  const performedBy = getPerformedByFromLog(log);

  const { user } = event_data;
  const { t } = useTranslation();
  const getitleText = (
    <>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{data?.titleText}</span>
      <span className="semibold-text lighter-text font-12 ml-1">{performed_by?.organization?.name}</span>
      <span className="regular-text lighter-text font-12 ml-1">{t('USER_LC')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{id}</span>
    </>
  );
  const setUpdatedData = () => {
    let updatedData = data;
    switch (event_type) {
      case 'USER_DELETED': {
        updatedData = { headerText: 'User deleted', titleText: 'deleted' };
        break;
      }
      case 'USER_INVITED': {
        updatedData = { headerText: 'User added', titleText: 'added' };
        break;
      }
    }
    setData(updatedData);
  };
  useEffect(() => {
    setUpdatedData();
  }, []);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={data?.headerText}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
      />
      {showDetails && (
        <LogContentBox
          title={'Account'}
          contentText={
            <div className="flex items-center gap-6">
              <p className="regular-text lighter-text font-12">{user?.role?.name}</p>
              <p className="medium-text lighter-text font-12">BVX</p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default UserAddDeleteActivateDeactivate;
