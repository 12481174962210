import { nanoid } from '@reduxjs/toolkit';
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete-icon.svg';
import { ReactComponent as AddIcon } from '../../../../assets/images/plus.svg';
import { ReactComponent as SortingArrowsIcon } from '../../../../assets/images/sorting-arrows.svg';
import { ReactComponent as RightArrow } from '../../../../assets/images/sorting-right.svg';
import { ReactComponent as ZenterprizeIcon } from '../../../../assets/images/zenterprize-logo.svg';
import { getTemplateOptions } from '../../../../store/features/automationsSlice';
import Button from '../../../Elements/button/button';
import DropDown from '../../../McDropdown';

const RenderInput = ({ config, dependentConfig = null, errorFields, updateIntegrationParams }) => {
  const inputRef = useRef(null);
  const [cursor, setCursor] = useState(null);

  const updateInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '0px';
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = scrollHeight + 'px';
      return scrollHeight + 'px';
    }
  };

  useLayoutEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
      updateInputHeight();
    }
  }, [inputRef, cursor]);

  return config.conditional ? (
    <>
      {config.conditional.param_value === dependentConfig?.value && (
        <div className="flex integration-input relative w-full">
          <textarea
            ref={inputRef}
            autoComplete="turnoff"
            className={`input name-input-form w-full ${errorFields[config.id] && 'error-info'}`}
            onChange={e => {
              setCursor(e.target.selectionStart);
              updateIntegrationParams(config.id, e.target.value);
            }}
            placeholder={config.name}
            type="text"
            value={config.value || ''}
          />
        </div>
      )}
    </>
  ) : config.param_type === 'LIST' ? (
    <div className="flex-column">
      <DropDown
        className={`integration-input-dropdown ${!errorFields[config.id] ? 'error-info' : ''}`}
        setSelected={option => updateIntegrationParams(config.id, option.id, option)}
        options={config.choices}
        placeholder={config.name}
        selected={config.choices?.find(type => type.id === config.value)}
        withIcon={false}
      />
    </div>
  ) : (
    <div className="flex integration-input relative w-full">
      <textarea
        ref={inputRef}
        autoComplete="turnoff"
        className={`input name-input-form w-full ${errorFields[config.id] && 'error-info'}`}
        onChange={e => {
          setCursor(e.target.selectionStart);
          updateIntegrationParams(config.id, e.target.value);
        }}
        placeholder={config.name}
        type="text"
        defaultValue={config.value || ''}
      />
    </div>
  );
};

const RenderConditionalConfigs = ({
  config,
  dependentConfig = null,
  parentConfig = null,
  errorFields,
  updateIntegrationParams,
}) => {
  return (
    <>
      {(!config.conditional || (config.conditional && config.conditional.param_value === dependentConfig?.value)) && (
        <div className="flex-column action-container relative">
          <div className="flex-column mb-6">
            <label className="regular-text font-12 grey-text mb-1 pxy-1">{config.name}</label>
            <RenderInput
              key={config?.id}
              config={config}
              dependentConfig={dependentConfig}
              errorFields={errorFields}
              updateIntegrationParams={updateIntegrationParams}
            />
          </div>
          {config.depends?.map(c => (
            <RenderConditionalConfigs
              key={c.id}
              config={c}
              dependentConfig={config}
              parentConfig={dependentConfig ? parentConfig : config}
              errorFields={errorFields}
              updateIntegrationParams={updateIntegrationParams}
            />
          ))}
        </div>
      )}
    </>
  );
};

const DataMapping = ({
  configWithMapping,
  configDetailValues,
  selectedIntegration,
  selectActions,
  mappingDataValues,
  setMappingDataValues,
}) => {
  const dispatch = useDispatch();

  const { connector } = selectedIntegration || {};
  const { name: connectorName, icon } = connector || {};

  const { automationDetails } = useSelector(state => state.automations);
  const { event_type } = automationDetails || {};
  const { lexicons } = event_type || {};

  const { required_params } = configWithMapping;

  const paramsValues = useMemo(() => {
    return required_params?.reduce((acc, param) => {
      const value = configDetailValues.find(config => config.param_name === param);
      acc[param] = value?.param_value;
      return acc;
    }, {});
  }, [configDetailValues]);

  const allMappingDataParams = mappingDataValues.map(m => m.param_name);

  const [mappingFieldOptions, setMappingFieldOptions] = useState([]);

  const fetchMappingFieldOptions = () => {
    const paramsValues = required_params?.reduce((acc, param) => {
      const value = configDetailValues.find(config => config.param_name === param);
      acc[param] = value?.param_value;
      return acc;
    }, {});

    dispatch(
      getTemplateOptions({
        id: selectedIntegration?.id,
        actionType: selectActions?.action_type,
        paramId: configWithMapping.id,
        params: paramsValues,
      }),
    )
      .then(response => {
        setMappingFieldOptions(response?.data);
      })
      .catch(err => {
        console.log(err);
        setMappingFieldOptions([]);
      });
  };

  const onUpdatedData = (id, newDataValue) => {
    setMappingDataValues(
      mappingDataValues.map(data => {
        if (data.id === id) {
          return { ...data, ...newDataValue };
        }
        return data;
      }),
    );
  };

  const onRemoveData = id => {
    setMappingDataValues(mappingDataValues.filter(data => data.id !== id));
  };

  const onAddData = () => {
    setMappingDataValues([...mappingDataValues, { id: nanoid(), isForDataMatching: true }]);
  };

  useEffect(() => {
    if (required_params?.length > 0) {
      const isAllDataAvailable = required_params.every(param => {
        return configDetailValues.find(config => config.param_name === param);
      });
      if (isAllDataAvailable) {
        fetchMappingFieldOptions();
      }
    } else {
      fetchMappingFieldOptions();
    }
  }, [required_params, ...Object.values(paramsValues), selectedIntegration?.id, selectActions?.id]);

  return (
    <DataMappingWrapper className="flex-column">
      <div className="flex mb-1 pxy-1 col-gap-2 mb-3">
        <div className="flex items-center justif-center pxy-1 border radius-2 h-fit-content">
          <SortingArrowsIcon width={14} height={14} />
        </div>
        <div className="flex-column">
          <label className="regular-text font-14">Data field matching</label>
          <label className="regular-text font-12 grey-text">
            Manage your Mailchimp connection and match data fields.
          </label>
        </div>
      </div>
      <div className="flex-column border pxy-4 row-gap-6 radius-3">
        {mappingDataValues.length > 0 && (
          <div className="flex-1 col-gap-2 container-wrapper">
            <div className="flex-1 flex items-center col-gap-1">
              <ZenterprizeIcon width={16} height={16} />
              <label className="regular-text ml-1">Zenterprize data</label>
            </div>
            <div />
            <div className="flex flex-1 items-center justify-between">
              <div className="flex-1 flex items-center col-gap-1">
                <img src={icon?.active} alt={connectorName} width={16} height={16} />
                <label className="regular-text flex-1">{connectorName} data</label>
              </div>
              <label className="regular-text error-text cursor" onClick={() => setMappingDataValues([])}>
                Remove all
              </label>
            </div>
          </div>
        )}
        {mappingDataValues?.length > 0 && (
          <div className="flex-column row-gap-3">
            {mappingDataValues.map(data => (
              <div key={data?.id} className="container-wrapper items-center flex-1 col-gap-2">
                <DropDown
                  className="flex-1"
                  placeholder={'Zenterprize field'}
                  options={lexicons || []}
                  selected={lexicons?.find(option => option.lexicon === data.mapping_source)}
                  setSelected={option => {
                    const prevDisplayName = data.display_name;
                    const splitedName = prevDisplayName ? prevDisplayName.split(':') : [];
                    const newName = `${option.name}:${splitedName[1]}`;
                    onUpdatedData(data.id, {
                      ...data,
                      mapping_source: option.lexicon,
                      display_name: newName,
                    });
                  }}
                />
                <RightArrow />
                <div className="flex items-center justify-between col-gap-2">
                  <DropDown
                    className="flex-1"
                    placeholder={`${connectorName} field`}
                    options={(mappingFieldOptions || []).filter(option => !allMappingDataParams.includes(option.name))}
                    selected={mappingFieldOptions?.find(option => option.id === data.param_value)}
                    setSelected={option => {
                      const prevDisplayName = data.display_name;
                      const splitedName = prevDisplayName ? prevDisplayName.split(':') : [];
                      const newName = `${splitedName[0]}:${option.name}`;
                      onUpdatedData(data.id, {
                        ...data,
                        param_name: configWithMapping.id,
                        param_value: option.id,
                        display_name: newName,
                      });
                    }}
                  />
                  <DeleteIcon width={24} height={24} className="cursor" onClick={() => onRemoveData(data.id)} />
                </div>
              </div>
            ))}
          </div>
        )}
        <Button
          size="medium"
          className="default-secondary"
          label="Add data"
          icon={<AddIcon width={16} height={16} />}
          onClick={onAddData}
        />
      </div>
    </DataMappingWrapper>
  );
};

const AutomationLogIntegrationDetails = props => {
  const {
    automationLogIntegrationDetails,
    sendDataToIntegration,
    selectActions,
    selectedIntegration,
    editAutomationActionType,
    editAutomationActionData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getInitialConfigDetailValues = useCallback(
    (onlyMappingSource = false) => {
      if (editAutomationActionType === 'ACTION_DATA') {
        const params = editAutomationActionData?.params || [];
        return params
          ?.filter(p => (onlyMappingSource ? !!p.mapping_source : !p.mapping_source))
          .map(param => ({
            id: nanoid(),
            param_name: param.param_type,
            param_value: param.param_value,
            display_name: param.display_value,
            mapping_source: param.mapping_source,
            isForDataMatching: onlyMappingSource,
          }));
      } else {
        return [];
      }
    },
    [editAutomationActionType, editAutomationActionData],
  );

  const [errorFields, setErrorFields] = useState({});
  const [configDetailValues, setConfigDetailValues] = useState(getInitialConfigDetailValues());
  const [mappingDataValues, setMappingDataValues] = useState(getInitialConfigDetailValues(true));
  const [configDetails, setConfigDetails] = useState([]);
  const [configWithMapping, setConfigWithMapping] = useState({});

  const getConditional = config => {
    const findCondition = automationLogIntegrationDetails.filter(c => c.conditional?.param_name === config.id);
    const finalData = findCondition.map(f => ({
      ...f,
      value: getIntegrationParamValue(f.id),
      depends: getConditional(f),
    }));
    return finalData;
  };

  const getIntegrationParamValue = name => {
    return configDetailValues?.find(param => param.param_name === name)?.param_value || '';
  };

  const updateIntegrationParams = (name, value, displayName) => {
    const isAvailable = configDetailValues?.find(param => param.param_name === name);
    if (isAvailable) {
      setConfigDetailValues([
        ...configDetailValues.map(param =>
          param.param_name === name ? { ...param, param_value: value, display_name: displayName?.name } : param,
        ),
      ]);
    } else {
      setConfigDetailValues([
        ...configDetailValues,
        { param_name: name, param_value: value, display_name: displayName?.name },
      ]);
    }
    setErrorFields({ ...errorFields, [name]: false });
  };

  useEffect(() => {
    const fetchData = async () => {
      const configs = await Promise.all(
        automationLogIntegrationDetails
          ?.filter(c => !c?.is_field_mapping)
          ?.map(async curr => {
            if (!curr.conditional) {
              if (curr?.param_type === 'LIST') {
                const filteredConfigs = configDetails.filter(item => item.param_type === 'LIST');
                const matchingConfig = filteredConfigs.find(config => config.id === curr.id);
                if (matchingConfig?.choices) {
                  return {
                    ...curr,
                    choices: matchingConfig.choices,
                    value: getIntegrationParamValue(curr.id),
                    depends: await getConditional(curr),
                  };
                } else {
                  const val = await getOptions(curr);
                  return {
                    ...curr,
                    choices: val,
                    value: getIntegrationParamValue(curr.id),
                    depends: await getConditional(curr),
                  };
                }
              } else {
                const data = await getConditional(curr);
                return { ...curr, value: getIntegrationParamValue(curr.id), depends: data };
              }
            }
            return null;
          }),
      );
      const filteredConfigs = configs.filter(config => config !== null);
      const configWithMapping = automationLogIntegrationDetails?.find(c => c?.is_field_mapping);
      setConfigDetails(filteredConfigs);
      setConfigWithMapping(configWithMapping);
    };

    fetchData();
  }, [automationLogIntegrationDetails, configDetailValues]);

  const getOptions = curr => {
    return new Promise((resolve, reject) => {
      dispatch(
        getTemplateOptions({
          id: selectedIntegration?.id,
          actionType: selectActions?.action_type,
          paramId: curr?.id,
        }),
      )
        .then(response => {
          resolve(response?.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  useEffect(() => {
    sendDataToIntegration([...configDetailValues, ...mappingDataValues]);
  }, [configDetailValues, mappingDataValues]);

  return (configDetails || []).length ? (
    <Fragment>
      <div className="grid-trigger w-full">
        {configDetails.map((config, index) => (
          <RenderConditionalConfigs
            key={index}
            config={config}
            updateIntegrationParams={updateIntegrationParams}
            errorFields={errorFields}
          />
        ))}
      </div>
      {configWithMapping?.id && (
        <DataMapping
          configWithMapping={configWithMapping}
          configDetailValues={configDetailValues}
          mappingDataValues={mappingDataValues}
          setMappingDataValues={setMappingDataValues}
          selectedIntegration={selectedIntegration}
          selectActions={selectActions}
        />
      )}
    </Fragment>
  ) : (
    <div className="flex items-center justify-center w-full">
      <p className="regular-text font-16 grey-text pxy-2">{t('NO_CONFIG_PARAMS_FOUND')}</p>
    </div>
  );
};

const DataMappingWrapper = styled.div`
  .container-wrapper {
    display: grid;
    grid-template-columns: 0.9fr 16px 1fr;
  }
`;

export default AutomationLogIntegrationDetails;
