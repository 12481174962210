import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../assets/images/burger.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { PrizeDrawDetailHeaderWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Menu from '../common/menu';

const HomeDetailHeader = ({ title, titleContent = null, actionContent = null }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = useContext(OrganisationContext);
  const { t } = useTranslation();
  const onMenuClick = navigateTo => {
    navigate(`/home/${navigateTo}`);
  };
  const menuItems = [];
  const menuItemsWithPermission = [
    { key: 'ENTRANTS_VIEW', name: t('ENTRANTS'), onClick: () => onMenuClick('entrants'), priority: 1 },
    { key: 'HOMEPAGE_LOG_VIEW', name: t('LOGS'), onClick: () => onMenuClick('logs'), priority: 2 },
  ];
  const filteredMenuItems = menuItemsWithPermission.filter(permission => hasPermission(permission.key));
  const menuList = [...menuItems, ...filteredMenuItems].sort((a, b) => a.priority - b.priority);

  return (
    <PrizeDrawDetailHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 border-bottom">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text font-20">{title}</label>
        {titleContent}
      </div>
      {actionContent}
      <Menu Icon={HamburgerIcon} menuList={menuList} tooltipContent={'Menu'} />
    </PrizeDrawDetailHeaderWrapper>
  );
};

export default HomeDetailHeader;
