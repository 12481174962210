import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext';
import { getUserInitials } from '../../helpers/utils';
import { AutomationListItemWrapper } from '../../styles/components/automation-list/automation-list.styled';
import Menu from '../common/menu';
import Switch from '../common/switch';

const AutomationListItem = ({ automation = {}, getAutomationData = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermission, setModal } = useContext(OrganisationContext);

  const onChangeStatus = () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automation,
        isEnable: !automation.is_enabled,
        onSuccess: () => getAutomationData(),
        title: automation.is_enabled
          ? 'Are you sure you want to disable this automation?'
          : 'Would you like to enable this automation?',
      },
    });
  };

  const onAutomation = () => {
    navigate(`/platforms/automations/${automation.id}/configuration`);
  };

  const handleDeleteAutomation = () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automation,
        onSuccess: () => getAutomationData(),
        title: 'Are you sure you want to delete this automation?',
        isDelete: true,
      },
    });
  };

  return (
    <AutomationListItemWrapper
      className="border-bottom cursor"
      onClick={() => checkPermission(() => onAutomation(), 'AUTOMATIONS_MANAGE')}>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <label className="medium-text one-line">{automation?.name}</label>
      </div>
      <div className="border-right flex items-center px-6 overflow-hidden">
        <label className="regular-text grey-text two-lines">{automation?.description}</label>
      </div>
      <div className="border-right flex items-center justify-between px-6">
        <span
          className={`regular-text flex items-center last-width ${
            automation?.is_enabled ? 'enable-color' : 'disable-color'
          }`}>
          {automation?.is_enabled ? t('ENABLED') : t('DISABLED')}
        </span>
        <Switch onClick={() => onChangeStatus()} enabled={automation?.is_enabled} disabled={false} />
      </div>
      <div className="flex items-center justify-center">
        {getUserInitials(automation.owner || {}) && (
          <div className="flex items-center justify-center owner-container">
            <span className="bold-text zen-default-text">{getUserInitials(automation.owner || {})}</span>
          </div>
        )}
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuList={[
            { name: t('DETAILS'), onClick: () => onAutomation(), permission: 'AUTOMATIONS_MANAGE' },
            { name: t('DELETE'), onClick: () => handleDeleteAutomation(), permission: 'AUTOMATIONS_MANAGE' },
          ]}
        />
      </div>
    </AutomationListItemWrapper>
  );
};

export default AutomationListItem;
