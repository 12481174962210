import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BarGraphSelected } from '../../assets/images/barGraph-selected.svg';
import { ReactComponent as BarGraph } from '../../assets/images/barGraph.svg';
import { ReactComponent as LineChartSelected } from '../../assets/images/lineChart-selected.svg';
import { ReactComponent as LineChart } from '../../assets/images/lineChart.svg';
import { capitalize, formatText, getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';
import { getEnteredStatistic } from '../../store/features/dashboardSlice';
import { addToast } from '../../store/features/toastSlice';
import { EntrantWrapper } from '../../styles/components/dashboard/dashboard.styled';
import BarChartV2 from '../Charts/BarChartV2';
import ColumnChartV2 from '../Charts/ColumnChartV2';
import SplineChartV2 from '../Charts/SplineChartV2';
import DropDown from '../McDropdown';
import DateSelectorElement from '../widget/date-selector-element/date-selector-element';

const isDayVisible = (startDate, endDate) => {
  const dateDiffInYears = moment.unix(endDate).diff(moment.unix(startDate), 'years');
  return dateDiffInYears < 1;
};

const isWeekVisible = (startDate, endDate) => {
  const dateDiffInWeeks = moment.unix(endDate).diff(moment.unix(startDate), 'weeks');
  return dateDiffInWeeks > 2.5;
};

const isMonthVisible = (startDate, endDate) => {
  const dateDiffInMonths = moment.unix(endDate).diff(moment.unix(startDate), 'months');
  return dateDiffInMonths > 2.5;
};

const isYearVisible = (startDate, endDate) => {
  const dateDiffInYears = moment.unix(endDate).diff(moment.unix(startDate), 'years');
  return dateDiffInYears > 2.5;
};

const durationList = [
  { id: 'DAY', name: 'Day' },
  { id: 'WEEK', name: 'Week' },
  { id: 'MONTH', name: 'Month' },
  { id: 'YEAR', name: 'Year' },
];

const filterList = [
  {
    id: 'LAST_7_DAYS',
    name: 'Last 7 Days',
    startDate: moment().add(-7, 'days').add(1, 'day').startOf('day').unix(),
    durationOptions: [],
    defaultDuration: 'DAY',
  },
  {
    id: 'LAST_4_WEEKS',
    name: 'Last 4 Weeks',
    startDate: moment().add(-4, 'weeks').add(1, 'day').startOf('day').unix(),
    durationOptions: ['DAY', 'WEEK'],
    defaultDuration: 'DAY',
  },
  {
    id: 'LAST_3_MONTHS',
    name: 'Last 3 Months',
    startDate: moment().add(-3, 'months').add(1, 'day').startOf('day').unix(),
    durationOptions: ['DAY', 'WEEK', 'MONTH'],
    defaultDuration: 'DAY',
  },
  {
    id: 'LAST_12_MONTHS',
    name: 'Last 12 Months',
    startDate: moment().add(-11, 'months').add(1, 'day').startOf('month').unix(),
    durationOptions: ['WEEK', 'MONTH'],
    defaultDuration: 'MONTH',
  },
  {
    id: 'MONTH_TO_DATE',
    name: 'Month to Date',
    startDate: moment().startOf('month').unix(),
    isMonthToDate: true,
    durationOptions: [],
    defaultDuration: 'DAY',
  },
  // { id: 'ALL_TIME', name: 'All Time', startDate: 0, isAllTime: true, durationOptions: [], defaultDuration: 'MONTH' },
  {
    id: 'CUSTOM',
    name: 'Custom',
    startDate: moment().add(-4, 'weeks').add(1, 'day').startOf('day').unix(),
    isCustom: true,
    durationOptions: [],
  },
];

const defaultOption = filterList.find(item => item.id === 'LAST_7_DAYS');

const dateArrayFromStartToEnd = (startDate, endDate, type) => {
  const dateArray = [];
  let currentDate = moment.unix(startDate).startOf(type);
  const stopDate = moment.unix(endDate).endOf(type);
  while (currentDate <= stopDate) {
    dateArray.push(currentDate);
    currentDate = moment(currentDate).add(1, type);
  }
  return dateArray;
};

const formatOptions = type => {
  switch (type) {
    case 'DAY':
      return 'MMM DD, YYYY';
    case 'WEEK':
      return 'MMM DD';
    case 'MONTH':
      return 'MMM YYYY';
    case 'YEAR':
      return 'YYYY';
    default:
      return 'MMM DD, YYYY';
  }
};

const formatWeek = date => {
  const startDate = moment(date).startOf('week');
  const endDate = moment(date).endOf('week');
  return `${startDate.format('MMM DD')} - ${endDate.format('MMM DD')}`;
};

const EntrantsDetails = ({ url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { globalStats, enteredStats } = useSelector(state => state.dashboard) || [];
  const defaultToDate = useMemo(() => moment().endOf('day').unix(), []);

  const [selectedChart, setSelectedChart] = useState('LINE');
  const [selectedChartType, setSelectedChartType] = useState('DAY');
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [fromDate, setFromDate] = useState(defaultOption.startDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [dateToSet, setDateToSet] = useState('startDate');

  useEffect(() => {
    if (parseInt(fromDate) >= 0 && parseInt(toDate) >= 0) {
      // const fromDateInUtc = moment.unix(fromDate).utc().endOf('day').add(1, 'second').unix();
      // const toDateInUtc = moment.unix(toDate).utc().endOf('day').unix();
      const timezone_id = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezoneIdEncoded = encodeURIComponent(timezone_id);
      const paramString = `unit=${
        selectedChartType || ''
      }&start_date=${fromDate}&end_date=${toDate}&timezone_id=${timezoneIdEncoded}`;
      const newUrl = url ? `${url}&${paramString}` : `?${paramString}`;
      dispatch(getEnteredStatistic({ url: newUrl })).catch(() => {});
    }
  }, [selectedChartType, fromDate, toDate, url]);

  const seriesData = enteredStats?.map(item => item.count);

  const xaxisDataNew = useMemo(() => {
    return enteredStats?.map(item => {
      return selectedChartType === 'WEEK'
        ? formatWeek(moment.unix(item.key))
        : moment.unix(item.key).format(formatOptions(selectedChartType));
    });
  }, [selectedChartType, fromDate, toDate, selectedOption, enteredStats]);

  const isOnlyOneData = seriesData?.length === 1;

  const seriesDataForLine = isOnlyOneData ? [...seriesData, ...seriesData] : seriesData;
  const xaxisDataNewForLine = isOnlyOneData ? [...xaxisDataNew, ...xaxisDataNew] : xaxisDataNew;

  const seriesDataHorizontal = globalStats?.age?.map(item =>
    item.percentage !== 'NaN' ? parseFloat(item.percentage || 0).toFixed(2) : 0,
  );
  const seriesCountValue = globalStats?.age?.map(item => item.count);
  const xaxisDataHorizontal = globalStats?.age
    ? globalStats?.age.map(item => (item?.key === 'UNKNOWN' ? 'Unknown' : 'Age ' + item.key))
    : [];
  const handleChartSelection = chartType => {
    setSelectedChart(chartType);
  };

  const getFromDateBasedOnType = (chartType, date) => {
    const { startDate, isAllTime, isCustom } = selectedOption;
    let fromMoment = moment.unix(startDate);
    let fromMomentClone = fromMoment.clone();
    let fromDateTemp = date;
    if (isCustom) {
      fromMoment = moment.unix(date);
      fromMomentClone = fromMoment.clone();
    }
    if (!isAllTime) {
      if (chartType === 'DAY') {
        fromDateTemp = fromMomentClone.unix();
      } else if (chartType === 'WEEK') {
        const weekday = fromMoment.get('weekday');
        if (weekday < 3) {
          fromDateTemp = fromMomentClone.startOf('week').unix();
        } else {
          fromDateTemp = fromMomentClone.startOf('week').add(1, 'week').unix();
        }
      } else if (chartType === 'MONTH') {
        fromDateTemp = fromMomentClone.startOf('month').unix();
      } else if (chartType === 'YEAR') {
        fromDateTemp = fromMomentClone.startOf('year').unix();
      }
    }
    return fromDateTemp;
  };

  const onChangeSelectedChartType = chartType => {
    const fromDateTemp = getFromDateBasedOnType(chartType, fromDate);
    setFromDate(fromDateTemp);
    setSelectedChartType(chartType);
  };

  const renderDurationOptions = () => {
    const { durationOptions: durationOptionsInitial, isAllTime, isCustom } = selectedOption;
    let durationOptions = durationOptionsInitial;
    if (isAllTime || isCustom) {
      const fromDateInitial = isAllTime ? parseInt(enteredStats[0]?.key) : fromDate;
      durationOptions = durationList
        .filter(item => {
          if (item.id === 'DAY') return isDayVisible(fromDateInitial, toDate);
          if (item.id === 'WEEK') return isWeekVisible(fromDateInitial, toDate);
          if (item.id === 'MONTH') return isMonthVisible(fromDateInitial, toDate);
          if (item.id === 'YEAR') return isYearVisible(fromDateInitial, toDate);
          return false;
        })
        .map(item => item.id);
    }

    if (!durationOptions?.length || (isCustom && durationOptions.length <= 1)) return <div className="w-full" />;

    return (
      <div className="flex items-center justify-center w-full gap-2 m-auto px-2 py-1 bg-backgroundClr radius-3">
        {durationOptions.map(item => {
          return (
            <div
              key={item}
              className={`cursor px-2 py-1 font-12 flex items-center radius-2 regular-text ${
                selectedChartType === item ? 'bg-white' : 'lighter-text'
              }`}
              onClick={() => onChangeSelectedChartType(item)}>
              {capitalize(formatText(item))}
            </div>
          );
        })}
      </div>
    );
  };

  const handleDate = (data, changeDateToSet = true) => {
    if (dateToSet === 'startDate') {
      const fromDateTemp = getFromDateBasedOnType(selectedChartType, data);
      if (moment(fromDateTemp * 1000).isAfter(moment(toDate * 1000))) {
        dispatch(addToast({ error: true, text: 'Start date should not be greater than end date' }));
        return;
      }
      setFromDate(fromDateTemp);
      if (changeDateToSet) {
        setDateToSet('endDate');
      }
    } else {
      if (moment(fromDate * 1000).isAfter(moment(data * 1000))) {
        dispatch(addToast({ error: true, text: 'End date should not be less than start date' }));
        return;
      }
      const endDate = moment.unix(data).endOf('day').unix();
      setToDate(endDate);
      if (changeDateToSet) {
        setDateToSet('startDate');
      }
    }
  };

  return (
    <EntrantWrapper className="flex-1 border radius-4 pxy-4" style={{ width: 380 }}>
      <div className="flex justify-between">
        <div className="flex-column">
          <label className="font-12 regular-text lighter-text">{t('STATISTICS')}</label>
          <label className="font-20 medium-text">{t('ENTRANTS')}</label>
        </div>
        <div className="flex-row gap-1 cursor">
          <div onClick={() => handleChartSelection('LINE')}>
            {selectedChart === 'LINE' ? <LineChartSelected /> : <LineChart />}
          </div>
          <div onClick={() => handleChartSelection('BAR')}>
            {selectedChart === 'BAR' ? <BarGraphSelected /> : <BarGraph />}
          </div>
        </div>
      </div>
      <div className="px-2 mt-4 flex-row gap-4">
        <div className="radius-3 bg-backgroundClr flex-column justify-center items-center  px-3_5 py-2 w-full">
          <label className="w-full bold-text font-18 text-center line-27">
            {globalStats?.total_entrants ? getFormattedNumberStyle(globalStats?.total_entrants, 'decimal', 0, 0) : ''}
          </label>
          <label className="w-full regular-text font-12 text-center line-16">{t('ENTRANTS')}</label>
        </div>
        <div className="radius-3 bg-backgroundClr flex-column justify-center items-center  px-3_5 py-2 w-full">
          <label className="w-full bold-text font-18 text-center line-27">
            {globalStats?.total_prizes ? getFormattedNumber(globalStats?.total_prizes, 'USD', 0, 0) : ''}
          </label>
          <label className="w-full regular-text font-12 text-center line-16">{t('PRIZES')}</label>
        </div>
      </div>
      <div className="flex flex-1 my-4 gap-3">
        <div className="flex items-center col-gap-2 w-full">
          <DropDown
            options={filterList}
            placeholder={'Filter'}
            className="w-full"
            selected={selectedOption}
            setSelected={option => {
              setSelectedChartType(option.defaultDuration || 'DAY');
              setFromDate(option.startDate);
              setToDate(defaultToDate);
              setSelectedOption(option);
            }}
          />
          {selectedOption.isCustom && (
            <div className="flex flex-1 gap-2">
              <DateSelectorElement
                className="custom-date-selector"
                showOnlyIcon={true}
                isCustomFromChart={true}
                range={true}
                setEndDate={() => {}}
                isFromDateRange={true}
                setStartDate={handleDate}
                startDate={moment.unix(fromDate)}
                endDate={moment.unix(toDate)}
                disablePastDays={false}
                disableFutureDaysOfDate={false}
                disableFutureDayDate={moment(fromDate * 1000)
                  .add(1, 'days')
                  .unix()}
                showCurrentMonth={false}
                dateToSet={dateToSet}
                onStartDateClick={() => setDateToSet('startDate')}
                onEndDateClick={() => setDateToSet('endDate')}
                onSelectorClose={() => setDateToSet('startDate')}
                isDateRangeSelected={fromDate && toDate}
                setSelectedDate={d => handleDate(d, false)}
              />
            </div>
          )}
        </div>
        {renderDurationOptions()}
      </div>
      <div className="flex-column mt-4">
        {selectedChart === 'LINE' ? (
          <SplineChartV2 seriesData={seriesDataForLine} xaxisData={xaxisDataNewForLine} chartType={selectedChartType} />
        ) : (
          <ColumnChartV2 seriesData={seriesData} xaxisData={xaxisDataNew} />
        )}
      </div>
      <div className="bar-chart">
        <BarChartV2 seriesData={seriesDataHorizontal} xaxisData={xaxisDataHorizontal} seriesCount={seriesCountValue} />
      </div>
    </EntrantWrapper>
  );
};

export default EntrantsDetails;
