import React from 'react';
import { useTheme } from 'styled-components';
import { ReactComponent as DownArrowIcon } from '../../../../../../assets/images/arrow-down-2.svg';

const LogTitle = ({ text = <></>, showDetails = false, setShowDetails = () => {}, showDownIcon = true }) => {
  const theme = useTheme();
  return (
    <div className="flex items-center col-gap-1 px-4 note-title radius-4 py-1">
      {showDownIcon && (
        <DownArrowIcon
          height={14}
          width={14}
          className={`cursor mt-1 ${showDetails ? 'rotate-0' : 'rotate-270'}`}
          onClick={() => setShowDetails(!showDetails)}
          color={theme.colors.LighterText}
        />
      )}
      {text}
    </div>
  );
};

export default LogTitle;
