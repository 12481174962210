import React from 'react';
import AccountDetailsLeft from './account-details-left';
import AccountdetailsRight from './account-details-right';

const AccountDetails = () => {
  return (
    <>
      <AccountDetailsLeft />
      <AccountdetailsRight />
    </>
  );
};

export default AccountDetails;
