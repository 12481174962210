import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as UserIcon } from '../../assets/images/users.svg';
import Box from '../../components/common/Box';
import { SettingsWrapper } from '../../styles/pages/settings.styled';

const Settings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <SettingsWrapper className="flex-column items-start justify-start w-full px-10 py-6">
      <label className="semibold-text font-20 zen-default-text">{t('SETTINGS')}</label>
      <div className="flex items-center justify-start w-50 mt-6">
        <Box
          title={t('SECURITY')}
          icon={<UserIcon height={24} width={24} className="icon-fill" />}
          subTitle={t('CONFIGURE_YOUR_SECURITY')}
          onClick={() => navigate(`${location.pathname}/security`)}
        />
      </div>
    </SettingsWrapper>
  );
};

export default Settings;
