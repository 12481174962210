import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import DropDown from '../../../components/McDropdown';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { addClients, getOrganizationNetworks, getPrizeDrawClient } from '../../../store/features/prizeDrawSlice';
import { addToast } from '../../../store/features/toastSlice';
import { PrizeDrawClientsWrapper } from '../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Button from '../../Elements/button/button';
import InputSearch from '../../common/input-search';
import Loader from '../../common/loader';
import NoDataComponent from '../../common/no-data-component';
import NoResultComponent from '../../common/no-result-component';
import UpdateAction from '../../common/update-action';
import PrizeDrawDetailHeader from '../prize-draw-details-header';
import PrizeDrawClient from './prize-draw-client';

const Option = props => {
  const { innerProps, option: data, isSelected, handleSelect } = props;
  return (
    <div
      onClick={() => handleSelect(data)}
      className={`option-wrapper flex-column items-start justify-start w-full px-2 py-1 radius-1 cursor ${
        isSelected ? 'selected' : ''
      }`}
      {...innerProps}>
      <div className="regular-text font-16">{data?.name}</div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between w-50">
          <div className="flex items-center justify-start w-50">
            <MailIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {data?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? data?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-50 ml-2">
            <MobileIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {data?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? data?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <label
          className={`regular-text text-right ml-10 flex-1 one-line font-14 lighter-text ${
            data?.primary_location?.formatted_address ? '' : 'grey-text'
          }`}>
          {data?.primary_location?.formatted_address ? data?.primary_location?.formatted_address : 'Not provided'}
        </label>
      </div>
    </div>
  );
};

const singleValueComponent = value => {
  return (
    <div className={`flex-column items-start justify-start flex-1 w-full px-2 py-1 overflow-hidden`}>
      <div className="regular-text font-16">{value?.name}</div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between" style={{ width: '40%' }}>
          <div className="flex items-center justify-start w-50">
            <MailIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                value?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {value?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? value?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-50 ml-2">
            <MobileIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line${
                value?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {value?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? value?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <label
          className={`regular-text text-right ml-10 flex-1 one-line font-14 lighter-text ${
            value?.primary_location?.formatted_address ? '' : 'grey-text'
          }`}>
          {value?.primary_location?.formatted_address ? value?.primary_location?.formatted_address : 'Not provided'}
        </label>
      </div>
    </div>
  );
};

const PrizeDrawClients = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showAddClient, setAddClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientSearchText, setClientSearchText] = useState('');
  const debouncedClientSearch = useDebounce(clientSearchText, 500);
  const prizedrawPageRef = useRef(0);

  const dispatch = useDispatch();
  const { prizeDrawClients, prizeDrawOrganizationCategories, prizeDrawOrganizationNetworks, formTemplate } =
    useSelector(state => state.prizeDraw);
  const { hasPermission } = useContext(OrganisationContext);
  const pageRef = useRef(null);
  const debouncedSearch = useDebounce(searchText, 500);

  const [loading, setLoading] = useState(false);

  const prizedrawClientOrganizationIds = prizeDrawClients?.map(client => client?.organization?.id) || [];

  const getPrizeDrawClientsData = (page, merge) => {
    pageRef.current = page;
    if (!merge) {
      setLoading(true);
    }
    dispatch(
      getPrizeDrawClient({
        params: { page: page, search: debouncedSearch },
        merge: merge,
        prizeDrawId: id,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getClientNetworksOptions = (page, merge) => {
    prizedrawPageRef.current = page;
    dispatch(
      getOrganizationNetworks({
        param: {
          category_tag: 'CLIENT',
          search: debouncedClientSearch,
          page: page,
        },
        callFormTemplate: true,
        merge: merge,
      }),
    );
  };

  const fetchMoreClientData = () => {
    getClientNetworksOptions(prizedrawPageRef.current + 1, true);
  };

  useEffect(() => {
    getPrizeDrawClientsData(0, false);
  }, [id, debouncedSearch]);

  useEffect(() => {
    getClientNetworksOptions(0, false);
  }, [debouncedClientSearch]);

  useEffect(() => {
    if (!showAddClient) {
      setClientSearchText('');
      prizedrawPageRef.current = 0;
    }
  }, [showAddClient]);

  const getPrizeDrawList = () => {
    const output = prizeDrawClients?.map(data => (
      <PrizeDrawClient
        key={data.id}
        prizeDrawClient={data}
        setAddClient={setAddClient}
        showAddClient={showAddClient}
        refreshData={() => getPrizeDrawClientsData(0, false)}
      />
    ));
    return <div className="flex-column items-start justify-start w-full flex-1">{output}</div>;
  };

  const handleAddClient = () => {
    if (selectedClient) {
      dispatch(
        addClients({
          params: { organization: { id: selectedClient.id } },
          prizedrawid: id,
          formTemplates: formTemplate.content || [],
        }),
      ).then(data => {
        setSelectedClient(null);
        setAddClient(false);
      });
    } else {
      dispatch(addToast({ error: true, text: 'Please select client', id: uuid() }));
      return;
    }
  };

  return (
    <PrizeDrawClientsWrapper className="flex-column items-center justify-start w-full h-full overflow-hidden">
      <PrizeDrawDetailHeader
        title={t('CLIENTS')}
        actionContent={
          <div className="items-center justify-between w-full">
            <div className="flex items-center justify-end flex-1">
              {hasPermission('PRIZEDRAW_CLIENTS_MANAGE') && (
                <Button
                  fontSize="14px"
                  size="medium"
                  height="32px"
                  borderRadius="12px"
                  disabled={!hasPermission('PRIZEDRAW_CLIENTS_MANAGE')}
                  className={`default-secondary mr-4 ${showAddClient ? 'disabled' : ''}`}
                  icon={<PlusIcon height={16} width={16} />}
                  label={t('ADD_NEW')}
                  onClick={() => {
                    setAddClient(true);
                    dispatch(
                      getOrganizationNetworks({
                        param: {
                          // organization_category_id: getOrganizationCategoryIds(prizeDrawOrganizationCategories),
                          category_tag: 'CLIENT',
                        },
                        callFormTemplate: true,
                        page: 0,
                        search: debouncedClientSearch,
                      }),
                    );
                  }}
                />
              )}
              <InputSearch
                placeholder={'Search'}
                value={searchText}
                onChange={setSearchText}
                className="input-search mr-4"
              />
            </div>
          </div>
        }
      />

      <div className="w-full flex-1 h-full">
        {showAddClient && (
          <div className="flex items-center justify-start mxy-6 relative" style={{ width: 'calc(100% - 48px)' }}>
            <DropDown
              className="provider-selector w-full"
              options={prizeDrawOrganizationNetworks?.content.filter(
                item => !prizedrawClientOrganizationIds?.includes(item.id),
              )}
              optionLength={
                (prizeDrawOrganizationNetworks?.content || []).filter(
                  item => !prizedrawClientOrganizationIds?.includes(item.id),
                ).length
              }
              placeholder={<div className="font-16 placeholder">{t('SELECT_CLIENT')}</div>}
              isSearchable={true}
              isPaged={true}
              search={clientSearchText}
              setSearch={setClientSearchText}
              setSelected={setSelectedClient}
              selected={selectedClient}
              hasMore={!prizeDrawOrganizationNetworks?.last}
              fetchData={fetchMoreClientData}
              RenderOption={props => <Option {...props} />}
              optionItemHeight={54}
              renderSelectedValue={selectedClient => singleValueComponent(selectedClient)}
            />
            <UpdateAction
              onUpdate={() => handleAddClient()}
              onCancel={() => {
                setSelectedClient(null);
                setAddClient(false);
              }}
              className={`absolute update-action ${selectedClient && 'update-action-top'}`}
            />
          </div>
        )}
        <div className="w-full flex-column h-full flex-1 overflow-scroll">
          {loading ? (
            <Loader />
          ) : prizeDrawClients?.length > 0 ? (
            getPrizeDrawList()
          ) : debouncedSearch ? (
            <NoResultComponent />
          ) : (
            <NoDataComponent />
          )}
        </div>
      </div>
    </PrizeDrawClientsWrapper>
  );
};

export default PrizeDrawClients;
