import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UsersIcon } from '../../../../assets/images/sidebar/user.svg';
import { getPerformedByFromLog, lowerCase } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantCreated = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data, performed_by } = log;
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  const { form, entrant, entrant_form } = event_data || {};
  const { form_type } = entrant_form;
  const { referral_source, fundraiser } = entrant || {};

  const form_type_name = lowerCase(form_type?.name);
  let contentDetails = {};

  switch (form_type_name) {
    case 'face-to-face':
      contentDetails = { from: 'Fundraiser', fromData: fundraiser?.badge_number };
      break;
    case 'referral':
      contentDetails = { from: 'Referral source', fromData: referral_source?.urn };
      break;
    case 'amoe':
      contentDetails = { from: 'User', fromData: performedBy };
      break;
    case 'online':
      contentDetails = { from: null, fromData: null };
      break;
    default:
      contentDetails = { from: '', fromData: '' };
      break;
  }

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader icon={<UsersIcon height={16} width={16} />} title={'Entrant created'} subtitle={'Entrant'} />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{entrant?.urn}</span>
            <span className="regular-text lighter-text font-12 ml-1">entered into the Prize Draw by</span>
            <span className="semibold-text lighter-text font-12 ml-1">{form_type_name}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('ENTRY_LC')}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          {contentDetails.from && (
            <LogContentBox
              title={contentDetails.from}
              contentText={<p className="flex regular-text lighter-text font-12">{contentDetails.fromData}</p>}
            />
          )}
          <LogContentBox
            title={'Form'}
            contentText={<p className="regular-text lighter-text font-12">{form?.name}</p>}
          />
        </>
      )}
    </div>
  );
};

export default EntrantCreated;
