import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const IntegrationUpdated = ({ log = {} }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState({});
  const { event_data, performed_by } = log;
  const { previous, integration } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const getupdatedData = () => {
    let updatedData = { ...data };
    if (integration?.name !== previous?.integration?.name) {
      updatedData = {
        title: 'updated integration name',
        from: previous?.integration?.name,
        to: integration?.name,
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else if (integration?.description !== previous?.integration?.description) {
      updatedData = {
        title: 'updated integration description',
        from: previous?.integration?.description,
        to: integration?.description,
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else {
      updatedData = {
        title: 'updated integration',
        from:
          previous.integration?.integration_params[0] &&
          `${previous.integration?.integration_params[0]?.param_name} - ${previous.integration?.integration_params[0]?.param_value}`,
        to:
          integration?.integration_params[0] &&
          `${integration?.integration_params[0].param_name} - ${integration?.integration_params[0].param_value}`,
        fromLabel: 'From',
        toLabel: 'To',
      };
    }
    setData(updatedData);
  };
  useEffect(() => {
    getupdatedData();
  }, []);
  const getitleText = (
    <>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{data?.title || ''}</span>
    </>
  );
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Integration updated'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={data.fromLabel || 'From'}
            contentText={<p className="regular-text lighter-text font-12">{data?.from || ''}</p>}
          />
          <LogContentBox
            title={data?.toLabel || 'To'}
            contentText={<p className="regular-text lighter-text font-12">{data?.to || ''}</p>}
          />
          <LogContentBox
            title={'Integration'}
            contentText={<p className="regular-text lighter-text font-12">{integration?.name}</p>}
          />
        </>
      )}
    </div>
  );
};

export default IntegrationUpdated;
