import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as RightArrowIcon } from '../../assets/images/arrow-right.svg';
import { enumCountryCode, initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { emailRegEx, formatText, uppercase } from '../../helpers/utils';
import { createOrganization, getOrganizationUserRole } from '../../store/features/groupsSlice';
import { addToast } from '../../store/features/toastSlice';
import { checkRequiredField } from '../../store/features/userManagementSlice';
import CommonPopup from '../common-popup';
import { AddAccountWrapper } from '../modal.styled';
import AccountInfo from './account-info';
import Address from './address';
import UserInfo from './user-info';

const enumSteps = {
  accountInfo: 'ACCOUNT_INFO',
  address: 'ADDRESS',
  userInfo: 'USER_INFO',
};
const defaultRequiredFields = { name: true, email: true, role: true };
const AddAccount = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setModal, modal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const { groupDetails } = useSelector(state => state.groups);

  const { onSuccess } = modal?.content || {};

  const [accountData, setAccountData] = useState({});
  const [error, setError] = useState({});
  const [currentStep, setCurrentStep] = useState(enumSteps.accountInfo);
  const [loading, setLoading] = useState(false);
  const org = getItemFromLocalStorage('user')?.organizations[0];
  const [requiredFields, setRequiredFields] = useState({ ...defaultRequiredFields });
  useEffect(() => {
    dispatch(
      getOrganizationUserRole({
        params: { organization_category_id: groupDetails?.id },
        organizationID: org?.id,
      }),
    );
  }, []);

  const tabs = (
    <div className="flex items-center jusity-center col-gap-4 pt-8 pb-4">
      <label
        onClick={() => handleTabNavigation(enumSteps.accountInfo)}
        className={`semibold-text font-12 cursor ${
          currentStep === enumSteps.address || currentStep === enumSteps.userInfo ? 'grey-text' : ''
        }`}>
        {uppercase(t('ACCOUNT_INFO'))}
      </label>
      <RightArrowIcon color={currentStep === enumSteps.accountInfo ? theme.colors.RegularText : theme.colors.zenGray} />
      <label
        onClick={() => handleTabNavigation(enumSteps.address)}
        className={`semibold-text font-12 cursor ${
          currentStep === enumSteps.accountInfo || currentStep === enumSteps.userInfo ? 'grey-text' : ''
        }`}>
        {uppercase(t('ADDRESS'))}
      </label>
      <RightArrowIcon color={currentStep === enumSteps.address ? theme.colors.RegularText : theme.colors.zenGray} />
      <label
        onClick={() => handleTabNavigation(enumSteps.userInfo)}
        className={`semibold-text font-12 cursor ${
          currentStep === enumSteps.accountInfo || currentStep === enumSteps.address ? 'grey-text' : ''
        }`}>
        {uppercase(t('USER_INFO'))}
      </label>
    </div>
  );

  const getContentPage = () => {
    switch (currentStep) {
      case enumSteps.accountInfo:
        return (
          <AccountInfo
            handleChange={(field, value) => handleChange(field, value)}
            accountInfo={accountData?.accountInfo}
            error={error?.accountInfo}
          />
        );
      case enumSteps.address:
        return (
          <Address
            handleChange={(field, value) => handleChange(field, value)}
            address={accountData?.address}
            error={error?.address}
          />
        );
      case enumSteps.userInfo:
        return (
          <UserInfo
            handleChange={(field, value) => handleChange(field, value)}
            userInfo={accountData?.userInfo}
            error={error.userInfo}
            requiredFields={requiredFields}
          />
        );
    }
  };

  const handleSubmitData = async () => {
    try {
      if (loading) {
        return;
      }
      const { accountInfo, address, userInfo } = accountData;
      setLoading(true);
      let request = {
        name: accountInfo?.name,
        code: accountInfo?.abbreviation,
        category: { id: groupDetails?.id },
        parent: [
          {
            id: org?.id,
            relationship_type: 'CHILD',
          },
        ],
        unique_tax_reference: '',
        company_registration_number: '',
        location: [
          { ...address, is_primary: true, is_billing: true, is_postal: false },
          { ...address, is_primary: true, is_billing: false, is_postal: true },
        ],
        contact_details: [{ contact_type: 'EMAIL', contact_value: accountInfo?.email, is_primary: true }],
        account_owner: {
          forename: userInfo?.name?.split(' ')[0],
          surname: userInfo?.name?.split(' ')[1],
          title: '',
          email: userInfo?.email,
          phone: userInfo?.phone?.phone ? userInfo?.phone?.phone?.replaceAll('-', '').replaceAll(' ', '') : '',
          language: '',
          timezone: '',
          role: { id: userInfo?.role?.id },
          employee_number: requiredFields?.employeeid ? userInfo?.employeeId : '',
          is_owner: true,
          badge_number: requiredFields?.badgenumber ? userInfo?.badgeNumber : '',
          country_code: userInfo?.phone?.phone
            ? userInfo?.phone?.phoneCountry
              ? userInfo?.phone?.phoneCountry
              : enumCountryCode.USA
            : '',
        },
        integration: null,
        invoice_organization: null,
        payment_organization: null,
        send_invite: true,
      };
      if (accountInfo?.phone?.phone) {
        request = {
          ...request,
          contact_details: [
            ...request?.contact_details,
            {
              contact_type: 'MOBILE',
              contact_value: accountInfo?.phone?.phone?.toLowerCase().replaceAll('-', '')?.replaceAll(' ', ''),
              is_primary: true,
              country_code: accountInfo?.phone?.phone
                ? accountInfo?.phone?.phoneCountry
                  ? accountInfo.phone?.phoneCountry
                  : enumCountryCode.USA
                : '',
            },
          ],
        };
      }
      dispatch(
        createOrganization({
          request: request,
        }),
      )
        .then(() => {
          onSuccess && onSuccess();
          setModal(initModal);
        })
        .catch(err => {
          dispatch(
            addToast({
              error: true,
              text: err?.response?.data?.error_description
                ? err?.response?.data?.error_description
                : 'Something went wrong',
            }),
          );
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const checkAccountInfoErrors = () => {
    const { accountInfo } = accountData;
    if (!accountInfo?.name?.trim() || !accountInfo?.email?.trim() || !accountInfo?.abbreviation?.trim()) {
      setError({
        ...error,
        accountInfo: {
          ...error?.accountInfo,
          name: !accountInfo?.name?.trim(),
          abbreviation: !accountInfo?.abbreviation?.trim(),
          email: !accountInfo?.email?.trim(),
        },
      });
      dispatch(addToast({ error: true, text: 'Please fill name, email and abbreviation' }));
      return false;
    } else if (accountInfo?.abbreviation?.length !== 3) {
      setError({
        ...error,
        accountInfo: {
          ...error?.accountInfo,
          abbreviation: true,
        },
      });
      dispatch(addToast({ error: true, text: 'Please enter valid abbreviation' }));
      return false;
    } else if (!emailRegEx.test(accountInfo?.email)) {
      setError({
        ...error,
        accountInfo: {
          ...error?.accountInfo,
          email: true,
        },
      });
      dispatch(addToast({ error: true, text: 'Please enter valid email' }));
      return false;
    } else if (
      accountInfo?.phone?.phone &&
      accountInfo?.phone?.phone?.replaceAll('-', '').replaceAll(' ', '').length !== 10
    ) {
      setError({ ...error, accountInfo: { ...error?.accountInfo, phone: true } });
      dispatch(addToast({ error: true, text: 'Please enter valid phone number' }));
      return false;
    } else {
      return true;
    }
  };

  const checkUserInfoErrors = () => {
    const { userInfo } = accountData;
    if (
      !userInfo?.name?.trim() ||
      !userInfo?.email?.trim() ||
      !userInfo?.role?.id ||
      (requiredFields?.employeeid && !userInfo?.employeeId?.trim()) ||
      (requiredFields?.badgenumber && !userInfo?.badgeNumber?.trim())
    ) {
      setError({
        ...error,
        userInfo: {
          ...error?.userInfo,
          name: !userInfo?.name?.trim(),
          role: !userInfo?.role?.id,
          email: !userInfo?.email?.trim(),
          badgeNumber: !userInfo?.badgeNumber?.trim(),
          employeeid: !userInfo?.employeeId?.trim(),
        },
      });
      dispatch(addToast({ error: true, text: 'Please fill name, email and role' }));
      return false;
    } else if (!(userInfo?.name && userInfo?.name?.length > 2 && userInfo?.name?.split(' ').length > 1)) {
      setError({
        ...error,
        userInfo: {
          ...error?.userInfo,
          name: true,
        },
      });
      dispatch(addToast({ error: true, text: 'Please enter user full name' }));
      return false;
    } else if (!emailRegEx.test(userInfo?.email)) {
      setError({
        ...error,
        userInfo: {
          ...error?.userInfo,
          email: true,
        },
      });
      dispatch(addToast({ error: true, text: 'Please enter valid email' }));
      return false;
    } else if (
      userInfo?.phone?.phone &&
      userInfo?.phone?.phone?.replaceAll('-', '').replaceAll(' ', '').length !== 10
    ) {
      setError({ ...error, userInfo: { ...error?.userInfo, phone: true } });
      dispatch(addToast({ error: true, text: 'Please enter valid phone number' }));
      return false;
    } else {
      return true;
    }
  };

  const checkAddressInfoErrors = () => {
    const { address } = accountData;
    if (!address?.line1?.trim() || !address?.state?.trim() || !address?.city?.trim() || !address?.postcode?.trim()) {
      setError({
        ...error,
        address: {
          ...error?.address,
          line1: !address?.line1?.trim(),
          state: !address?.state?.trim(),
          city: !address?.city?.trim(),
          postcode: !address?.postcode?.trim(),
        },
      });
      dispatch(addToast({ error: true, text: 'Please fill address line 1, state, city and zip code' }));
      return false;
    } else {
      return true;
    }
  };

  const checkErrors = () => {
    switch (currentStep) {
      case enumSteps.accountInfo: {
        const isValid = checkAccountInfoErrors();
        return isValid;
      }
      case enumSteps.address: {
        const isValid = checkAddressInfoErrors();
        return isValid;
      }
      case enumSteps.userInfo: {
        const isValid = checkUserInfoErrors();
        return isValid;
      }
    }
  };

  const handleNextButton = () => {
    switch (currentStep) {
      case enumSteps.accountInfo: {
        if (checkErrors()) {
          setCurrentStep(enumSteps.address);
        }
        break;
      }
      case enumSteps.address: {
        if (checkErrors()) {
          setCurrentStep(enumSteps.userInfo);
          break;
        }
      }
      case enumSteps.userInfo: {
        if (checkErrors()) {
          handleSubmitData();
        }
        break;
      }
    }
  };

  const handleBackButton = () => {
    switch (currentStep) {
      case enumSteps.accountInfo: {
        setModal(initModal);
        break;
      }
      case enumSteps.address: {
        setCurrentStep(enumSteps.accountInfo);
        break;
      }
      case enumSteps.userInfo: {
        setCurrentStep(enumSteps.address);
        break;
      }
    }
  };

  const handleChange = (field, value) => {
    let updatedData = { ...accountData };
    let updatedError = { ...error };
    switch (currentStep) {
      case enumSteps.accountInfo: {
        if (field === 'phoneCountry' || field === 'phone') {
          updatedData = {
            ...accountData,
            accountInfo: { ...accountData?.accountInfo, phone: { ...accountData?.accountInfo?.phone, [field]: value } },
          };
        } else if (field === 'abbreviation') {
          if (value.length <= 3) {
            updatedData = {
              ...accountData,
              accountInfo: {
                ...accountData?.accountInfo,
                [field]: value,
              },
            };
          }
        } else {
          updatedData = { ...accountData, accountInfo: { ...accountData?.accountInfo, [field]: value } };
        }
        updatedError = {
          ...error,
          accountInfo: { ...error?.accountInfo, [field]: false },
        };
        break;
      }
      case enumSteps.address: {
        if (field === 'address') {
          updatedData = { ...accountData, address: { ...value } };
          updatedError = { ...updatedError, address: {} };
        } else if (field === 'postcode') {
          if (!isNaN(Number(value))) {
            updatedData = { ...accountData, address: { ...accountData?.address, [field]: value } };
            updatedError = { ...updatedError, address: { ...accountData?.Address, [field]: false } };
          }
        } else {
          updatedData = { ...accountData, address: { ...accountData?.address, [field]: value } };
          updatedError = { ...updatedError, address: { ...accountData?.Address, [field]: false } };
        }
        break;
      }
      case enumSteps.userInfo: {
        if (field === 'phoneCountry' || field === 'phone') {
          updatedData = {
            ...accountData,
            userInfo: { ...accountData?.userInfo, phone: { ...accountData?.userInfo?.phone, [field]: value } },
          };
        } else {
          if (field === 'role') {
            dispatch(checkRequiredField({ role: { ...value } })).then(data => {
              let updatedRequiredField = { ...defaultRequiredFields };
              (data || []).forEach(item => {
                updatedRequiredField = {
                  ...updatedRequiredField,
                  [formatText(item.field, '')]: item?.is_required_in_invite,
                };
              });
              updatedError = { ...updatedError, role: false, employeeId: false, phone: false, badgeNumber: false };
              setRequiredFields(updatedRequiredField);
            });
          }
          updatedData = { ...accountData, userInfo: { ...accountData?.userInfo, [field]: value } };
        }
        updatedError = {
          ...updatedError,
          userInfo: { ...error?.userInfo, [field]: false },
        };
        break;
      }
    }
    setAccountData({ ...updatedData });
    setError({ ...updatedError });
  };

  const handleTabNavigation = tab => {
    switch (tab) {
      case enumSteps.accountInfo: {
        setCurrentStep(enumSteps.accountInfo);
        break;
      }
      case enumSteps.address: {
        if (currentStep === enumSteps.accountInfo) {
          const isValid = checkAccountInfoErrors();
          if (isValid) {
            setCurrentStep(enumSteps.address);
          }
        } else if (currentStep === enumSteps.userInfo) {
          setCurrentStep(enumSteps.address);
        }
        break;
      }
      case enumSteps.userInfo: {
        const isValidAccountInfoData = checkAccountInfoErrors();
        const isValidAddressData = checkAddressInfoErrors();
        if (currentStep === enumSteps.accountInfo) {
          if (isValidAccountInfoData && isValidAddressData) {
            setCurrentStep(enumSteps.userInfo);
          } else if (!isValidAddressData) {
            dispatch(addToast({ error: true, text: 'Please check address data' }));
          }
        } else if (currentStep === enumSteps.address) {
          const isValid = checkAddressInfoErrors();
          if (isValid) {
            setCurrentStep(enumSteps.userInfo);
          }
        }
        break;
      }
    }
  };

  return (
    <CommonPopup
      popupTitle={t('CREATE_ACCOUNT')}
      disabled={loading}
      onConfirm={() => handleNextButton()}
      onCancel={() => handleBackButton()}
      cancelButtonProps={{ label: currentStep === enumSteps.accountInfo ? t('CANCEL') : t('BACK') }}
      confirmButtonProps={{ label: currentStep === enumSteps.userInfo ? t('CREATE') : t('NEXT') }}>
      <AddAccountWrapper className="flex-column items-center w-full">
        <div className="flex items-center justify-center w-full border-bottom">{tabs}</div>
        <div className="flex-column items-center mt-6 w-full">{getContentPage()}</div>
      </AddAccountWrapper>
    </CommonPopup>
  );
};

export default AddAccount;
