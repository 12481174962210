import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getCurrencySymbol, getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const ClientFeeProductAddedDeleted = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data, performed_by } = log;
  const { client_product, client_product_fee } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  const getTitleText = () => {
    return (
      <span>
        <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">
          {log?.event_type === 'CLIENT_PRODUCT_FEE_CREATED' ? 'added a new' : 'deleted'}
        </span>
        <span className="semibold-text lighter-text font-12 ml-1">
          {client_product_fee?.billing_type === 'VARIABLE' ? 'continual billing' : 'cohort billing'}
        </span>
        <span className="regular-text lighter-text font-12 ml-1">{t('FEE')}</span>
      </span>
    );
  };

  const getHeaderText = () => {
    switch (log?.event_type) {
      case 'CLIENT_PRODUCT_FEE_CREATED':
        return 'Fee added';
      case 'CLIENT_PRODUCT_FEE_DELETED':
        return 'Fee deleted';
    }
  };

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={getHeaderText()}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle text={getTitleText()} showDetails={showDetails} setShowDetails={data => setShowDetails(data)} />
      {showDetails && (
        <>
          <LogContentBox
            title={'Fee'}
            contentText={
              <p className="flex regular-text lighter-text font-12">
                {`${getCurrencySymbol(client_product?.pricing?.currency_code)} ${client_product?.pricing?.price}`}
              </p>
            }
          />
          <LogContentBox
            title={'Client'}
            contentText={<p className="regular-text lighter-text font-12">{client_product?.organization?.name}</p>}
          />
          <LogContentBox
            title={'Product'}
            contentText={<p className="regular-text lighter-text font-12">{client_product?.product?.id}</p>}
          />
        </>
      )}
    </div>
  );
};

export default ClientFeeProductAddedDeleted;
