import React from 'react';
import { HomeDetailWrapper } from '../../styles/components/home-details/home-details.styled';
import HomeDetailsLeft from './home-details-left';
import HomeDetailsRight from './home-details-right';
const HomeDetails = () => {
  return (
    <HomeDetailWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6">
      <>
        <HomeDetailsLeft />
        <HomeDetailsRight />
      </>
    </HomeDetailWrapper>
  );
};

export default HomeDetails;
