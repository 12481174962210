import React from 'react';
import styled, { useTheme } from 'styled-components';
import { capitalize, formatText } from '../../../helpers/utils';

const Status = ({
  withDot = false,
  status = '',
  withDottedBorder = false,
  dotColor = null,
  statusText = null,
  statusTextClassName = '',
  statusWrapperClassName = '',
}) => {
  const theme = useTheme();
  const { colors: themeColors } = theme || {};

  const getStatusStyle = status => {
    switch (status) {
      case 'PENDING':
      case 'SHIPPING':
        return {
          color: theme.colors.ZenOrangeText,
          dotColor: theme.colors.ZenOrange,
          background: theme.colors.ZenOrangeSecondary,
        };
      case 'DRAFT':
        return { color: themeColors.ZenDefault, background: themeColors.ZenGray2 };
      case 'ACTIVE':
      case 'WINNER':
      case 'PUBLISHED':
      case 'GROUP_TAG':
      case 'CURRENT':
        return {
          color: themeColors.ZenPositiveText,
          background: themeColors.ZenPositiveSecondary,
          dotColor: themeColors.ZenPositive,
        };
      case 'PENDING':
      case 'PAUSED':
      case 'INCOMPLETE':
      case 'INCOMPLETE_EXPIRED':
        return {
          color: themeColors.ZenOrangeText,
          background: themeColors.ZenOrangeSecondary,
          dotColor: themeColors.ZenOrangeText,
        };
      case 'RETIRED':
      case 'CANCELED':
        return { color: themeColors.ZenNegative, background: themeColors.ZenPinkSecondary_O4 };
      case 'ARCHIVED':
        return {
          color: themeColors.ZenNegative,
          background: themeColors.ZenPinkSecondary,
          dotColor: themeColors.ZenNegative,
        };
      case 'FORM_TYPE_LABEL':
      case 'CLIENT_CODE':
        return {
          color: themeColors.ZenDefault,
          background: themeColors.backgroundColor,
          dotColor: dotColor,
          borderColor: themeColors.ZenBlueSecondary,
        };
      case 'PRIZE_DRAW_FORM_LABEL':
        return {
          color: themeColors.ZenDefault,
          background: themeColors.backgroundColor,
          dotColor: dotColor,
          borderColor: themeColors.ZenBlueSecondary,
        };
      case 'IN_DRAW':
      case 'INVITED':
        return {
          background: themeColors.customStatusBackground,
          dotColor: themeColors.customStatusText,
          color: themeColors.customStatusText,
        };
      case 'OUT_OF_DRAW':
        return {
          background: themeColors.ZenPinkSecondary2,
          dotColor: themeColors.ZenPink2,
          color: themeColors.ZenPink2,
        };
      case 'ENTRANT':
        return {
          background: themeColors.ZenPurpleSecondary2,
          dotColor: themeColors.zenPurple,
          color: themeColors.zenPurple,
        };
      case 'PAST_DUE':
        return {
          color: themeColors.ZenOrangeText,
          background: themeColors.ZenOrangeSecondary,
          dotColor: themeColors.ZenOrange,
        };
      case 'UNPAID':
        return {
          color: themeColors.RegularText,
          background: themeColors.ZenGray2Secondary,
          dotColor: themeColors.RegularText,
        };
      case 'USERROLE':
        return {
          color: themeColors.ZenDefault,
          background: themeColors.ZenBlueSecondary,
          dotColor: dotColor,
          borderColor: themeColors.ZenBlueSecondary,
        };
      case 'HOME':
      case 'MOBILE':
        return {
          color: themeColors.LighterText,
          background: themeColors.ZenLightGray,
        };
      default:
        return { color: themeColors.ZenDefault, background: themeColors.ZenGray2 };
    }
  };

  return (
    <StatusWrapper
      className={`flex items-center justify-center ${withDottedBorder && 'dotted-border'} ${statusWrapperClassName}`}
      withDottedBorder={withDottedBorder}
      {...getStatusStyle(status)}>
      {withDot && <div className="dot mr-1" />}
      <span className={`medium-text font-12 status-text ${statusTextClassName}`}>
        {statusText ? statusText : capitalize(formatText(status))}
      </span>
    </StatusWrapper>
  );
};

const StatusWrapper = styled('div')`
  padding: 3px 8px;
  width: fit-content;
  border-radius: 18px;
  background: ${({ background }) => background};

  &.dotted-border {
    border: 1px dashed ${({ background, borderColor }) => borderColor || background};
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${({ color, dotColor }) => dotColor || color};
  }

  .status-text {
    color: ${({ color }) => color};
  }
`;

export default Status;
