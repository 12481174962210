import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/page-header';
import PrizeDrawDetail from '../../components/prize-draw-detail';
import { getPrizeDrawDetail } from '../../store/features/prizeDrawSlice';
import { PrizeDrawDetailContainerWrapper } from '../../styles/pages/prize-draw-detail-container.styled';

const PrizeDrawDetailContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { prizeDrawDetail, prizeDrawEntrantDetails } = useSelector(state => state.prizeDraw);
  const { accountDetails } = useSelector(state => state.groups);
  const { formDetails, formVersionDetails } = useSelector(state => state.forms);
  const isPrizeDrawEntrantDetails = location.pathname.includes('entrant-details');
  const isAccountDetails = location.pathname.includes('account-details');
  const navigate = useNavigate();

  const isFormVersionUI = location.pathname.includes('form-version');

  useEffect(() => {
    dispatch(getPrizeDrawDetail({ id: id }));
  }, [id]);

  const navigateToEntrantList = () => {
    navigate(`/prize-draws/all/${id}/entrants`);
  };

  const onFormNameClickAll = () => {
    navigate(`/prize-draws/all/${prizeDrawDetail.id}/form-details/${formDetails?.id}/form-log`);
  };

  const onFormsClick = () => {
    navigate(`/prize-draws/all/${prizeDrawDetail.id}/forms`);
  };

  const navigateBack = () => {
    if (location.pathname.includes('clients')) {
      navigate(`/prize-draws/all/${id}/clients`);
    } else if (location.pathname.includes('organizations')) {
      navigate(`/prize-draws/all/${id}/organizations`);
    } else {
      navigate(`/prize-draws/all/${id}/entrants`);
    }
  };
  return (
    <PrizeDrawDetailContainerWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Prize Draws', onClick: () => {} },
          { text: 'All Prize Draws', path: '/prize-draws/all' },
          {
            text: prizeDrawDetail?.name,
            onClick: () => navigateToEntrantList(),
          },
          ...(formDetails?.name
            ? [
                { text: 'Forms', onClick: () => onFormsClick() },
                { text: formDetails?.name, onClick: () => onFormNameClickAll() },
              ]
            : []),
          ...(isPrizeDrawEntrantDetails ? [{ text: `${prizeDrawEntrantDetails?.urn}`, onClick: () => {} }] : []),
          ...(isFormVersionUI ? [{ text: `V${formVersionDetails?.version_no}`, onClick: () => {} }] : []),
          ...(isAccountDetails
            ? [
                {
                  text: location.pathname.includes('organizations') ? 'Organizations' : 'Clients',
                  onClick: () => navigateBack(),
                },
                { text: accountDetails?.name, onClick: () => {} },
              ]
            : []),
        ]}
        showDrawCount
        targetDate={prizeDrawDetail?.next_draw_date}
      />
      <PrizeDrawDetail />
    </PrizeDrawDetailContainerWrapper>
  );
};

export default PrizeDrawDetailContainer;
