import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import DropDown from '../../components/McDropdown';
import DateSelectorElement from '../../components/widget/date-selector-element/date-selector-element';

const billingTypeOptions = [
  { name: 'Continual billing', value: 'VARIABLE' },
  { name: 'Cohort billing', value: 'FIXED' },
];

const FeeInfo = ({ feeInfo = {}, handleChange = () => {}, error = {} }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-column items-center justify-center w-full row-gap-6">
      <div className="w-full flex-column mt-6">
        <label className="regular-text lighter-text font-12 mb-1">{t('BILLING_TYPE')}</label>
        <DropDown
          className={`provider-selector ${error?.billing && 'error-border'}`}
          setSelected={option => handleChange('billing', option)}
          options={billingTypeOptions}
          placeholder="Select"
          selected={feeInfo?.billing || null}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('START')}</label>
        <DateSelectorElement
          placeholder="MM/DD/YYYY"
          selectedDate={feeInfo?.start}
          setSelectedDate={d => handleChange('start', d)}
          format={'MM/DD/YYYY'}
          showDropdownArrow={false}
          className={`relative fromdate-container ${error?.start && 'error-border'}`}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('FEE_AMOUNT')}</label>
        <MaskedInput
          mask={['$', /\d/, /\d/, '.', /\d/, /\d/]}
          value={feeInfo?.amount || null}
          className={`input w-full ${error?.amount && 'error-border'}`}
          placeholder={'$00.00'}
          guide={false}
          onChange={({ target: { value } }) => handleChange('amount', value)}
        />
      </div>
    </div>
  );
};

export default FeeInfo;
