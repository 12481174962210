import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaymentIcon } from '../../../../assets/images/logs/payment-icon.svg';
import { getCurrencySymbol } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PaymentSuccess = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log;
  const {
    entrant: { product },
    payment: { currency, amount },
  } = event_data;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<PaymentIcon height={16} width={16} />}
        title="Product payment successful"
        subtitle={log?.tenant?.id}
      />
      <LogTitle
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {event_data?.entrant?.urn}
            </span>
            <span className="regular-text lighter-text font-12 ml-1">{t('WAS_CHARGED')}</span>
            <span className="regular-text semibold-text lighter-text font-12 cursor ml-1">{`${getCurrencySymbol(
              currency,
            )}${amount}`}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('SUCCESSFULLY')}</span>
          </span>
        }
      />
      {showDetails && (
        <LogContentBox
          title="Product"
          contentText={<p className="regular-text lighter-text font-12">{product?.product_name || product?.name}</p>}
        />
      )}
    </div>
  );
};

export default PaymentSuccess;
