import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { getIntegrationConfig, updateIntegration } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import { IntegrationsDetailRightWrapper } from '../../styles/components/integrations-detail/integrations-detail.styled';
import DropDown from '../McDropdown';
import Loader from '../common/loader';
import UpdateAction from '../common/update-action';

const IntegrationsDetailRight = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { integrationsDetail, integrationConfigsDetail } = useSelector(state => state.integrations);
  const { integration_params } = integrationsDetail || {};

  const [configDetails, setConfigDetails] = useState([]);
  const [configDetailValues, setConfigDetailValues] = useState([]);
  const [updateIntegrationDetail, setUpdateIntegrationDetail] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getConditional = config => {
    const findCondition = integrationConfigsDetail.filter(c => c.conditional?.param_name === config.id);
    const finalData = findCondition.map(f => ({
      ...f,
      value: getIntegrationParamValue(f.id),
      depends: getConditional(f),
    }));
    return finalData;
  };

  useEffect(() => {
    if (id === integrationsDetail?.id) {
      setLoading(true);
      dispatch(getIntegrationConfig({ connectorId: integrationsDetail?.connector?.id })).then(response =>
        setLoading(false),
      );
    }
  }, [id, integrationsDetail?.id]);

  useEffect(() => {
    setConfigDetailValues(integration_params);
  }, [integration_params]);

  useEffect(() => {
    const configs = integrationConfigsDetail?.reduce((acc, curr) => {
      if (!curr.conditional) {
        const data = getConditional(curr);
        acc.push({ ...curr, value: getIntegrationParamValue(curr.id), depends: data });
      }
      return acc;
    }, []);
    setConfigDetails(configs);
  }, [integrationConfigsDetail, configDetailValues]);

  const getIntegrationParamValue = name => {
    return configDetailValues?.find(param => param.param_name === name)?.param_value || '';
  };

  const updateIntegrationParams = (name, value) => {
    const isAvailable = configDetailValues?.find(param => param.param_name === name);
    if (isAvailable) {
      setConfigDetailValues([
        ...configDetailValues.map(param => (param.param_name === name ? { ...param, param_value: value } : param)),
      ]);
    } else {
      setConfigDetailValues([...configDetailValues, { param_name: name, param_value: value }]);
    }
    setErrorFields({ ...errorFields, [name]: false });
  };

  const getConfigValueToDisplay = config => {
    const { param_type, value, choices } = config;
    if (param_type === 'LIST') {
      return choices?.find(c => c.id === value)?.name || '';
    }
    return value || '';
  };

  const onEditConfig = config => {
    const { config: previousConfig, previousParams } = updateIntegrationDetail;
    let detailValue = configDetailValues;
    if (previousConfig && previousConfig?.id !== config?.id) {
      detailValue = previousParams;
    }
    setConfigDetailValues(detailValue);
    setUpdateIntegrationDetail({ config, previousParams: detailValue });
  };

  const onCancelUpdate = () => {
    if (loading) {
      return;
    }
    setConfigDetailValues(updateIntegrationDetail.previousParams);
    setUpdateIntegrationDetail({});
  };

  const getSelectedConfigs = () => {
    const selectedConfigs = integrationConfigsDetail.reduce((acc, curr) => {
      if (curr.conditional) {
        const isAvailable = getIntegrationParamValue(curr.conditional.param_name) === curr.conditional.param_value;
        if (isAvailable) {
          acc.push(curr);
        }
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    return selectedConfigs;
  };

  const onUpdate = () => {
    if (loading) {
      return;
    }
    const selectedConfigs = getSelectedConfigs();
    const request = {
      ...integrationsDetail,
      integration_params:
        configDetailValues
          .filter(p => selectedConfigs.find(config => config.id === p.param_name))
          .map(param => ({ ...param, param_value: param.param_value || null })) || [],
    };
    setLoading(true);
    dispatch(updateIntegration({ id: integrationsDetail.id, request }))
      .then(() => {
        setLoading(false);
        setUpdateIntegrationDetail({});
        dispatch(
          addToast({
            error: false,
            text: 'Integration updated successfully',
            id: uuid(),
          }),
        );
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          addToast({
            error: true,
            text: 'Please Enter valid data',
            id: uuid(),
          }),
        );
      });
  };

  const renderInput = (config, dependentConfig = null, parentConfig = null) => {
    const inputConfig = config.conditional ? parentConfig : config;
    return config.conditional ? (
      <>
        {config.conditional.param_value === dependentConfig?.value && (
          <div className="flex integration-input relative w-full">
            <input
              autoComplete="turnoff"
              className={`input w-full ${errorFields[config.id] && 'error-info'} ${
                config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
              }`}
              onFocus={() =>
                (!updateIntegrationDetail?.config || updateIntegrationDetail?.config?.id !== inputConfig?.id) &&
                onEditConfig(inputConfig)
              }
              onChange={e => updateIntegrationParams(config.id, e.target.value)}
              placeholder={config.name}
              type="text"
              value={config.value || ''}
            />
            {config.param_type === 'PASSWORD' && (
              <div className="flex items-center cursor mr-16 input-show-img">
                <img
                  alt="icon"
                  onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                  src={showPassword[config.id] ? ShowPassword : HidePassword}
                />
              </div>
            )}
          </div>
        )}
      </>
    ) : config.param_type === 'LIST' ? (
      <div className="flex-column">
        <DropDown
          className={`integration-input-dropdown ${!errorFields[config.id] ? 'error-info' : ''}`}
          setSelected={option => {
            (!updateIntegrationDetail?.config || updateIntegrationDetail?.config?.id !== inputConfig?.id) &&
              onEditConfig(config?.conditional ? parentConfig : config);
            updateIntegrationParams(config.id, option.id);
          }}
          options={config.choices}
          placeholder={config.name}
          selected={config.choices?.find(type => type.id === config.value)}
          withIcon={false}
        />
      </div>
    ) : (
      <div className="flex integration-input relative w-full">
        <input
          autoComplete="turnoff"
          className={`input w-full ${errorFields[config.id] && 'error-info'} ${
            config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
          }`}
          onFocus={() =>
            (!updateIntegrationDetail?.config || updateIntegrationDetail?.config?.id !== inputConfig?.id) &&
            onEditConfig(config?.conditional ? parentConfig : config)
          }
          onChange={e => updateIntegrationParams(config.id, e.target.value)}
          placeholder={config.name}
          type="text"
          value={config.value || ''}
        />
        {config.param_type === 'PASSWORD' && (
          <div className="flex items-center cursor mr-16 input-show-img">
            <img
              alt="icon"
              onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
              src={showPassword[config.id] ? ShowPassword : HidePassword}
            />
          </div>
        )}
      </div>
    );
  };

  const renderConditionalConfigs = (config, dependentConfig = null, parentConfig = null) => {
    return (
      <>
        {(!config.conditional || (config.conditional && config.conditional.param_value === dependentConfig?.value)) && (
          <div className="flex-column action-container relative">
            <div className="flex-column mb-6">
              <label className="regular-text font-12 grey-text mb-1 pxy-1">{config.name}</label>
              {updateIntegrationDetail?.config?.id === config.id ||
              (dependentConfig && updateIntegrationDetail?.config?.id === dependentConfig?.id) ||
              !getConfigValueToDisplay(config) ? (
                renderInput(config, dependentConfig, parentConfig)
              ) : (
                <div
                  className={`flex items-center py-1 px-2 cursor hover-edit-hide ${'hover-edit'}`}
                  onClick={() => !loading && onEditConfig(config.conditional ? parentConfig : config)}>
                  <label
                    className={`width-120 one-line regular-text font-16 flex-1 ${
                      config.param_type === 'PASSWORD' && 'password-mask'
                    }`}>
                    {getConfigValueToDisplay(config)}
                  </label>
                  <EditIcon width={16} height={16} className="edit-icon" />
                </div>
              )}
            </div>
            {config.depends?.map(c => renderConditionalConfigs(c, config, dependentConfig ? parentConfig : config))}
            {updateIntegrationDetail?.config?.id === config.id && (
              <UpdateAction onCancel={onCancelUpdate} onUpdate={onUpdate} className="update-action" />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <IntegrationsDetailRightWrapper className="flex-column flex-1 border">
      <div className="flex border-bottom pl-6 pr-4 py-4">
        <span className="medium-text font-20">{t('SETTINGS')}</span>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex-column pxy-6 flex-1 overflow-scroll">
          {configDetails.map(config => renderConditionalConfigs(config))}
        </div>
      )}
    </IntegrationsDetailRightWrapper>
  );
};

export default IntegrationsDetailRight;
