import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../../components/common/loader';
import IntegrationsList from '../../components/integrations-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getIntegrationsList } from '../../store/features/integrationsSlice';
import { IntegrationsWrapper } from '../../styles/pages/integrations.styled';

const Integrations = () => {
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);
  const [search, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);

  const fetchIntegrationList = (page = 0) => {
    if (page === 0) {
      setLoading(true);
    }
    dispatch(getIntegrationsList({ params: { search: debouncedSearch, page: page } }))
      .then(response => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedPage(0);
    fetchIntegrationList(0);
  }, [debouncedSearch]);

  useEffect(() => {
    fetchIntegrationList(selectedPage);
  }, [selectedPage]);

  const onAddClick = () => {
    setModal({
      type: 'add-integration',
      content: { top: true },
    });
  };

  return (
    <IntegrationsWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Platforms', onClick: () => {} },
          { text: 'Integrations', onClick: () => {} },
        ]}
        showAddNewBtn
        showSearch
        showPagination={false}
        onAddClick={onAddClick}
        addBtnPermission="INTEGRATIONS_MANAGE"
        searchText={search}
        onSearchChange={setSearchText}
        pagination={{ selectedPage: selectedPage, totalPages: 0, setSelectedPage: setSelectedPage }}
      />
      {loading ? <Loader height={64} width={64} /> : <IntegrationsList debouncedSearch={debouncedSearch} />}
    </IntegrationsWrapper>
  );
};

export default Integrations;
