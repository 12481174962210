import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { ReactComponent as UserIcon } from '../../../../assets/images/user.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const ShippingAddressUpdated = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log;
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  const getitleText = performedBy ? (
    <>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{t('UPDATED_ENTRANT')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
        {log?.event_data?.entrant?.urn}
      </span>
      <span className="regular-text lighter-text font-12 ml-1">{t('SHIPPING_ADDRESS')}</span>
    </>
  ) : (
    <>
      <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{event_data?.entrant?.urn}</span>
      <span className="regular-text lighter-text font-12 ml-1">updated their shipping address</span>
    </>
  );
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={performedBy ? <BuildingIcon height={16} width={16} /> : <UserIcon height={16} width={16} />}
        title={'Shipping address updated'}
        subtitle={log?.tenant?.id ? log?.tenant?.id : 'Entrant'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'From'}
            contentText={
              <p className="regular-text lighter-text font-12">
                {event_data?.previous?.contact_address?.formatted_address}
              </p>
            }
          />
          <LogContentBox
            title={'To'}
            contentText={
              <p className="regular-text lighter-text font-12">{event_data?.contact_address?.formatted_address}</p>
            }
          />
        </>
      )}
    </div>
  );
};

export default ShippingAddressUpdated;
