import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
// import { ReactComponent as HamburgerIcon } from '../../../assets/images/burger.svg';
import { GroupHeaderWrapper } from '../../../styles/components/group-details/group-detail.styled';
// import Menu from '../../common/menu';

const GroupHeader = ({ title, titleContent = null, actionContent = null }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onMenuClick = navigateTo => {
    navigate(`/groups/${id}/${navigateTo}`);
  };

  // const menuList = [
  //   {
  //     name: t('ACCOUNTS'),
  //     onClick: () => onMenuClick('accounts'),
  //   },
  // ];

  return (
    <GroupHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 border-bottom">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text font-20">{title}</label>
        {titleContent}
      </div>
      {actionContent}
    </GroupHeaderWrapper>
  );
};

export default GroupHeader;
