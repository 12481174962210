import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import FeesComponent from '../../components/fees';
import PageHeader from '../../components/page-header';
import { filterChildRenderComponent, multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage, removeItemFromLocalStorage } from '../../helpers/localstorage';
import useDebounce from '../../helpers/useDebounceHook';
import { getFeesList } from '../../store/features/billingSlice';
import { FeesWrapper } from '../../styles/pages/fees.styled';

const Fees = () => {
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);
  const { feesList } = useSelector(state => state.billing);
  const clientFilter = getItemFromLocalStorage('fees-client-data');
  const [search, setSearchText] = useState('');
  const [filters, setFilters] = useState([
    clientFilter
      ? {
          id: uuid(),
          name: 'Client',
          param: 'organization_id',
          type: 'CLIENT',
          object: 'CLIENT',
          childRenderComponent: filterChildRenderComponent.infiniteScrollDropdown,
          isSearchable: false,
          isPaged: true,
          value: JSON.parse(clientFilter),
        }
      : { id: uuid() },
  ]);
  const [sortedBy, setSortedBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const createFeesFilterUrl = filters => {
    let url = ``;
    filters?.map(item => {
      if (item?.childRenderComponent === filterChildRenderComponent.minMax) {
        url = item.value
          ? url.concat(
              `&${item.minParam}=${item?.value.minValue ? item?.value.minValue : ''}&${item.maxParam}=${
                item?.value.maxValue ? item?.value.maxValue : ''
              }`,
            )
          : url;
      } else {
        url = item.value
          ? url.concat(
              `&${item.param}=${
                item.param === 'prizedraw_client_code' ? item.value.prizedraw_client_code : item.value.id
              }`,
            )
          : url;
      }
    });
    return url;
  };

  const getFeesListData = (page, showLoading = true) => {
    setLoading(showLoading);
    const url = createFeesFilterUrl(filters);
    dispatch(
      getFeesList({
        params: {
          search: debouncedSearch,
          sort_by: sortedBy,
          order_by: orderBy,
          page: page,
          size: 15,
        },
        url: url,
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedPage(0);
    getFeesListData(0, false);
  }, [sortedBy, orderBy, debouncedSearch, filters]);

  useEffect(() => {
    setSelectedPage(0);
    getFeesListData(0, true);
  }, [debouncedSearch]);

  useEffect(() => {
    if (clientFilter) {
      removeItemFromLocalStorage('fees-client-data');
    }
  }, []);

  const onPageChange = page => {
    setSelectedPage(page);
    getFeesListData(page, true);
  };

  const handleAddFees = () => {
    setModal({
      type: 'add-product-fees',
      content: {
        handleOnSuccess: () => getFeesListData(selectedPage, true),
      },
    });
  };

  return (
    <FeesWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Billing', onClick: () => {} },
          { text: 'Fees', onClick: () => {} },
        ]}
        showMultipleFilter
        multiFilter={filters}
        setMultiFilter={data => setFilters(data)}
        multiFilterFor={multipleFilterTypeEnum.billingFees}
        showSearch
        showAddNewBtn
        searchText={search}
        onSearchChange={setSearchText}
        addBtnPermission={'FEES_MANAGE'}
        showPagination={true}
        pagination={{
          selectedPage: selectedPage,
          totalPages: feesList?.total_pages,
          setSelectedPage: onPageChange,
        }}
        onAddClick={() => handleAddFees()}
      />
      <FeesComponent
        setSortedBy={setSortedBy}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        sortedBy={sortedBy}
        loading={loading}
        search={search}
      />
    </FeesWrapper>
  );
};

export default Fees;
