import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  clearAutomationActionData,
  getAutomationSaveDetails,
  updateAutomationSaveDetails,
} from '../../../../store/features/automationsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { AutomationIntegrationDetails } from '../../../../styles/components/automationDetails/automation-details.styled';
import Button from '../../../Elements/button/button';
import UpdateAction from '../../../common/update-action';
import AutomationCondition from './automation-condition';
import AutomationLogIntegrationDetails from './automation-log-integration-details';
import AutomationSchedule from './automation-schedule';

const conditionDropdownLexicons = [
  'prizedraw.id',
  'entrant_form.form_type.id',
  'client.id',
  'form.id',
  'session.form_type',
  'entrant.contact.marketing_preferences.SMS.consent',
  'entrant.contact.marketing_preferences.EMAIL.consent',
  'entrant.contact.marketing_preferences.PHONE.consent',
  'entrant.contact.marketing_preferences.POST.consent',
];

const hourOptions = [
  { id: 'MINUTE', name: 'Minutes' },
  { id: 'HOUR', name: 'Hours' },
  { id: 'DAY', name: 'Days' },
  { id: 'WEEK', name: 'Weeks' },
];

const scheduleEventTypes = [
  { id: 'appointment.start_date', name: 'Appointment date' },
  { id: 'event.created_on', name: 'Trigger date' },
];

const getDefaultSchedule = scheduleData => {
  const { offset, unit, field } = scheduleData || {};
  if (!unit) {
    return {
      inputValue: '',
      duration: null,
      beforeAfterTime: null,
      eventType: null,
    };
  }
  return {
    inputValue: Math.abs(offset),
    duration: hourOptions.find(item => item.id === unit),
    beforeAfterTime: offset < 0 ? { id: 'BEFORE', name: 'Before' } : { id: 'AFTER', name: 'After' },
    eventType: scheduleEventTypes.find(item => item.id === field),
  };
};

const AutomationLogActionIntegration = ({
  selectedIntegration,
  selectActions,
  onCancel,
  setSaveIntegration,
  stepNumber,
  editAutomationActionData = {},
  setEditAutomationActionData = () => {},
  editAutomationActionType = '',
  showSelectedIntegrationData = false,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { automationLogIntegrationDetails } = useSelector(state => state.automations);

  const [showCondition, setShowCondition] = useState(editAutomationActionData?.conditions?.length > 0);
  const [showSchedule, setShowSchedule] = useState(!!editAutomationActionData?.schedule?.unit);
  const [scheduleData, setScheduleData] = useState(getDefaultSchedule(editAutomationActionData?.schedule || null));
  const [conditionData, setConditionData] = useState(
    editAutomationActionData?.conditions?.length > 0 ? editAutomationActionData?.conditions : null,
  );
  const [integrationData, setIntegrationData] = useState(
    editAutomationActionData && editAutomationActionData?.params ? editAutomationActionData?.params : null,
  );

  const handleDataFromSchedule = data => {
    setScheduleData(data);
  };
  const handleDataFromCondition = data => {
    setConditionData(data);
  };
  const handleDataFromForm = data => {
    setIntegrationData(data);
  };

  const transformedData =
    conditionData &&
    conditionData?.length > 0 &&
    conditionData.map(item => ({
      value: conditionDropdownLexicons.includes(item.dropdownValue?.lexicon) ? item?.inputValue?.id : item.inputValue,
      field: item.dropdownValue?.lexicon,
      display_value: conditionDropdownLexicons.includes(item.dropdownValue?.lexicon)
        ? item?.inputValue?.name
        : item.inputValue,
      operator: item.operatorValue,
    }));

  const offsetSign = scheduleData && scheduleData?.beforeAfterTime?.id === 'BEFORE' ? -1 : 1;

  const transformedParamData =
    integrationData &&
    integrationData.map(item => ({
      param_type: item?.param_name,
      param_value: item.param_value,
      display_value: item.display_name ? item.display_name : item.param_value,
      mapping_source: item.mapping_source || '',
    }));

  const sendAutomationData = () => {
    let request = {
      step_number: stepNumber - 1,
      action_type: selectActions?.action_type,
      integration: {
        id: selectedIntegration?.id,
      },
      ...((transformedParamData || integrationData) && {
        params: transformedParamData,
      }),
    };
    const isAllMappingAvailable = integrationData?.every(d =>
      d.isForDataMatching ? d.param_value && d.mapping_source : true,
    );
    if (!isAllMappingAvailable) {
      dispatch(addToast({ error: true, text: 'Please fill mapping values' }));
      return;
    }
    if (showCondition) {
      if (transformedData && transformedData?.length > 0 && transformedData.find(item => !item.operator)) {
        dispatch(addToast({ error: true, text: 'Please fill operator values' }));
        return;
      }
      request = {
        ...request,
        ...(transformedData &&
          transformedData?.length > 0 && {
            conditions: transformedData,
          }),
      };
    }
    if (showSchedule) {
      request = {
        ...request,
        ...(scheduleData && {
          schedule: {
            offset: offsetSign * parseInt(scheduleData?.inputValue),
            unit: scheduleData?.duration?.id,
            field: scheduleData?.eventType?.id,
          },
        }),
      };
    }
    try {
      if (request.conditions && request.conditions.length > 0) {
        let isEmptyValue = false;
        request.conditions.forEach(condition => {
          if (condition.value === '') {
            isEmptyValue = true;
          }
        });

        if (isEmptyValue) {
          dispatch(addToast({ error: true, text: 'Please fill empty values' }));
          return;
        }
      }
      if (
        request.schedule &&
        (isNaN(request?.schedule?.offset) ||
          request?.schedule?.unit === undefined ||
          request?.schedule?.field === undefined)
      ) {
        dispatch(addToast({ error: true, text: 'Please fill empty values' }));
        return;
      }
      if ((automationLogIntegrationDetails || []).length > 0 && request.params && request.params.length === 0) {
        dispatch(addToast({ error: true, text: 'Please fill empty values' }));
        return;
      }
      if (editAutomationActionData?.id) {
        dispatch(updateAutomationSaveDetails({ id, request, actionId: editAutomationActionData?.id }))
          .then(response => {
            dispatch(clearAutomationActionData());
            if (!showSelectedIntegrationData) {
              setSaveIntegration(response?.data);
            }
            setEditAutomationActionData && setEditAutomationActionData({});
          })
          .catch(error => {});
      } else {
        dispatch(getAutomationSaveDetails({ id, request }))
          .then(response => {
            dispatch(clearAutomationActionData());
            if (!showSelectedIntegrationData) {
              setSaveIntegration(response?.data);
            }
          })
          .catch(error => {});
      }
    } catch (error) {}
  };

  const onCancelUpdate = () => {
    onCancel();
  };
  return (
    <AutomationIntegrationDetails className="flex pxy-6 w-full flex-column">
      <div className="flex w-full justify-between">
        <div className="flex flex-row items-center gap-2">
          <img src={selectedIntegration?.connector?.icon?.active} alt="" className="radius-4" height={24} width={24} />
          <label className="font-14 semibold-text">{selectedIntegration?.name}</label>
        </div>
        <div className="flex flex-row gap-4">
          {!showSchedule && (
            <Button
              width="100%"
              className={'default-secondary px-4'}
              borderRadius="8px"
              label={'Add schedule'}
              onClick={() => setShowSchedule(true)}
            />
          )}
          {!showCondition && (
            <Button
              width="100%"
              className={'default-secondary px-4'}
              borderRadius="8px"
              label={'Add condition'}
              onClick={() => setShowCondition(true)}
            />
          )}
        </div>
      </div>
      <div className="pt-6">
        <AutomationLogIntegrationDetails
          automationLogIntegrationDetails={automationLogIntegrationDetails}
          sendDataToIntegration={handleDataFromForm}
          selectActions={selectActions}
          selectedIntegration={selectedIntegration}
          editAutomationActionData={editAutomationActionData}
          editAutomationActionType={editAutomationActionType}
        />
      </div>
      <div className="flex flex-column z-index-100">
        {showCondition && (
          <AutomationCondition
            conditionData={conditionData}
            sendDataToCondition={handleDataFromCondition}
            handleShowConditon={show => setShowCondition(show)}
          />
        )}
        {showSchedule && (
          <AutomationSchedule
            scheduleData={scheduleData}
            sendDataToSchedule={handleDataFromSchedule}
            handleShowSchedule={show => setShowSchedule(show)}
          />
        )}
      </div>

      <div className="update-action-container">
        <UpdateAction onUpdate={sendAutomationData} onCancel={onCancelUpdate} />
      </div>
    </AutomationIntegrationDetails>
  );
};

export default AutomationLogActionIntegration;
