import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    formsList: [],
  },
  reducers: {
    setFormsList: (state, { payload }) => {
      state.formsList = payload;
    },
    setFormDetails: (state, { payload }) => {
      state.formDetails = payload;
    },
    setFormVersions: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.formVersions?.content, ...payload?.data?.content] };
        state.formVersions = mergeData;
      } else {
        state.formVersions = payload.data;
      }
    },
    setFormVersionDetails: (state, { payload }) => {
      state.formVersionDetails = payload;
    },
  },
});

export const getFormsList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/forms`, { params: payload.params });
    dispatch(setFormsList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/prizedraws/forms/${payload.id}`);
    dispatch(setFormDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormVersions = payload => async dispatch => {
  try {
    const { id, params, merge } = payload;
    const { data } = await api.get(`/forms/${id}/versions`, {
      params: params,
    });
    dispatch(setFormVersions({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFormVersionToRetire = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.put(`/forms/${formId}/versions/${versionId}/archive`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const duplicateFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.post(`/forms/${formId}/versions/${versionId}/duplicate`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const publishFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.put(`/forms/${formId}/versions/${versionId}/publish`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormVersionDetails = payload => async dispatch => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.get(`/forms/${formId}/versions/${versionId}`);
    dispatch(setFormVersionDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const rePublishFormVersion = payload => async () => {
  try {
    const { formId, versionId } = payload;
    const { data } = await api.put(`/forms/${formId}/versions/${versionId}/revert`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setFormsList, setFormDetails, setFormVersions, setFormVersionDetails } = formsSlice.actions;
export default formsSlice.reducer;
