import styled from 'styled-components';

export const GenderWrapper = styled.div`
  .section {
    height: 160px;
    width: 200px;

    @media (max-width: 1440px) {
      .dashboard-years-box {
        margin-left: 12px;
      }
    }
  }
  .dashboard-years-box {
    @media (max-width: 1440px) {
      width: 173px !important;
    }
  }

  .liner-gradient {
    width: 55px;
    height: 160px;
    overflow: hidden;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: linear-gradient(to bottom, #e8d1ff, #fcf9ff);
  }
`;

export const EntrantWrapper = styled.div`
  .fromdate-container {
    width: 100px;
    .date-selector {
      top: 35px;
      left: 0px;
      width: 350px;
      height: 310px;
      padding: 20px 24px;
    }
  }

  .todate-container {
    width: 100px;
    .date-selector {
      top: 35px;
      left: 0px;
      width: 350px;
      height: 310px;
      padding: 20px 24px;
    }
  }
  .bar-chart {
    margin-top: -35px;
    .apexcharts-xaxis-tick {
      display: none !important;
    }
  }

  .remove-dates {
    border: 1.5px solid ${({ theme }) => theme.colors.ZenNegative};
    border-radius: 50%;
    padding: 1.5px;

    .close-icon {
      path {
        stroke: ${({ theme }) => theme.colors.ZenNegative};
        stroke-width: 2.5px;
      }
    }
  }

  .line-16 {
    line-height: 16px;
  }

  .line-27 {
    line-height: 27px;
  }

  .custom-date-selector {
    border: none;
    padding: 4px 8px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    position: relative;
    .custom-date-selector-element {
      position: absolute;
      top: 34px;
      left: 0;
      width: fit-content;
      background-color: white;
      z-index: 3;
      box-shadow: 0px 4px 30px rgba(5, 49, 73, 0.08);
      border-radius: 16px;

      .selected {
        border-color: ${({ theme }) => theme.colors.zenPurple};
      }

      .date-selector {
        position: relative;
        top: 0;
        left: 0;
        box-shadow: none;
        border-radius: none;
      }
    }
  }
`;

export const GraphWrapper = styled.div``;
