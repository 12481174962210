import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import BillingFeesDetails from '../../components/billing-fees-details.js';
import PageHeader from '../../components/page-header';
import { getProductDetails, getProductDetailsPrizedraw } from '../../store/features/billingSlice.js';
import { BillingFeesWrapper } from '../../styles/pages/billing-fees.styled';

const BillingFees = () => {
  const dispatch = useDispatch();
  const { productDetails } = useSelector(state => state.billing);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getProductDetails({ params: { productDetailsId: id } }));
    dispatch(getProductDetailsPrizedraw({ params: { productDetailsId: id } }));
  }, []);
  return (
    <BillingFeesWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Billing', path: '' },
          { text: 'Fees', path: '/billings/fees' },
          { text: `${productDetails?.product?.name ? productDetails?.product?.name : ''}`, onClick: () => {} },
        ]}
      />
      <BillingFeesDetails />
    </BillingFeesWrapper>
  );
};

export default BillingFees;
