import parse from 'html-react-parser';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right-black.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/x.svg';
import Button from '../../components/Elements/button/button';
import { widgetNewComponentDetails } from '../../constant/InitialData';
import FormBuilderContext from '../../context/FormBuilderContext';
import { ComponentConfigs } from './component-configs';

export const WidgetConfigPanel = () => {
  const {
    selectedWidget,
    setSelectedWidget,
    onUpdateWidgetDetails,
    onDeleteWidget,
    selectedComponent,
    setSelectedComponent,
    formAttributes,
    onAddNewComponentInWidget,
  } = useContext(FormBuilderContext);

  const { t } = useTranslation();

  const { components = [] } = selectedWidget || {};

  const [loadingAddComponent, setLoadingAddComponent] = useState(false);

  const componentColor = formAttributes?.color?.default_component || '#FFFFFF';

  const updateWidgetDetails = (key, value) => {
    const updatedWidget = { ...selectedWidget, [key]: value };
    setSelectedWidget(updatedWidget);
    onUpdateWidgetDetails(updatedWidget, updatedWidget.panelId);
  };

  const onAddNewComponent = async () => {
    try {
      setLoadingAddComponent(true);
      const { components } = selectedWidget;
      const newComponents = [...components, { ...widgetNewComponentDetails, component_color: componentColor }].map(
        (c, index) => ({ ...c, priority: index }),
      );
      const updatedWidget = { ...selectedWidget, components: newComponents };
      setSelectedWidget(updatedWidget);
      await onAddNewComponentInWidget(updatedWidget, updatedWidget.panelId);
      setLoadingAddComponent(false);
    } catch (error) {
      setLoadingAddComponent(false);
    }
  };

  return (
    <Fragment>
      {selectedComponent ? (
        <ComponentConfigs component={selectedComponent} setSelectedComponent={setSelectedComponent} />
      ) : (
        <Fragment>
          <div className="flex mb-4">
            <label className="medium-text font-18 flex-1">{t('WIDGET_CONFIGURATION')}</label>
            <div className="flex h-content">
              <div className="pxy-1 radius-2 flex items-center justify-center cursor delete-widget">
                <DeleteIcon
                  height={16}
                  width={16}
                  onClick={e => {
                    e.stopPropagation();
                    onDeleteWidget(selectedWidget, selectedWidget.panelId);
                  }}
                />
              </div>
              <div className="pxy-1 radius-3 flex items-center justify-center ml-4 cursor close-widget-config">
                <CloseIcon
                  height={16}
                  width={16}
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedWidget(null);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex-1 flex-column overflow-scroll h-full">
            <label className="regular-text lighter-text font-12">{t('NAME')}</label>
            <input
              className="input radius-4 widget-input"
              value={selectedWidget?.name}
              placeholder="Widget name"
              onChange={({ target: { value } }) => updateWidgetDetails('name', value)}
            />
            <div className="mt-8">
              <label className="flex medium-text mb-6">{t('COMPONENTS_UC')}</label>
              {components.map(component => (
                <div
                  className="flex items-center px-4 py-2 radius-4 mb-4 cursor widget-component-container"
                  onClick={() => component?.id && setSelectedComponent(component, false)}>
                  <label className="regular-text font-16 flex-1 one-line">
                    {component.component_type === 'MULTICHOICE' ? parse(component.name || '') : component.name}
                  </label>
                  <ArrowRightIcon height={16} width={16} />
                </div>
              ))}
              <div className="add-new-component-btn">
                <Button
                  className={'flex items-center justify-center default-secondary'}
                  label={'Add new component'}
                  borderRadius={'12px'}
                  size={'medium'}
                  icon={<PlusIcon height={16} width={16} />}
                  width={'100%'}
                  onClick={() => onAddNewComponent()}
                  disabled={loadingAddComponent}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
