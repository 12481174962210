import React, { useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import { OrganisationContext } from '../context/organisationContext';
import ActivatePrizedraw from './activate-prizedraw';
import AddAccount from './add-account';
import AddAutomation from './add-automation';
import AddFees from './add-fees';
import AddForm from './add-form';
import AddIntegration from './add-integration';
import AddPrizedraw from './add-prizedraw';
import AddProductFees from './add-product-fees';
import AgencyActions from './agency-action';
import Anonymize from './anonymize-action';
import AutomationAction from './automation-action';
import AutomationLogCancel from './automation-cancel';
import AutomationLogAction from './automation-log-actions';
import CreateGroups from './create-groups';
import EditFormVersion from './edit-form-version';
import EntrantExport from './entant-export';
import FormVersionAction from './form-version-action';
import GroupActions from './group-action';
import IntegrationActions from './integration-actions';
import InviteUser from './invite-user';
import MarketingCompanyActions from './marketing-company-actions';
import { ModalWrapper } from './modal.styled';
import PrizeDrawEntrantAction from './prize-draw-entrant-action';
import PrizeDrawEntrantReActive from './prizedraw-entrant-reactive';
import PublishFormVersion from './publish-form-version';
import RemoveBillingFee from './remove-billing-fee';
import RemovePrizedrawClient from './remove-prizedraw-clients';
import UserActions from './user-actions';
const initModal = { type: null, content: {} };

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    switch (modal.type) {
      case 'add-integration':
        return <AddIntegration />;
      case 'integration-action':
        return <IntegrationActions />;
      case 'invite-user':
        return <InviteUser />;
      case 'user-action':
        return <UserActions />;
      case 'agency-action':
        return <AgencyActions />;
      case 'marketing-company-actions':
        return <MarketingCompanyActions />;
      case 'automation-action':
        return <AutomationAction />;
      case 'create-group':
        return <CreateGroups />;
      case 'add-account':
        return <AddAccount />;
      case 'form-version-action':
        return <FormVersionAction />;
      case 'publish-form-version':
        return <PublishFormVersion />;
      case 'add-form':
        return <AddForm />;
      case 'edit-form-version':
        return <EditFormVersion />;
      case 'add-prize-draw':
        return <AddPrizedraw />;
      case 'add-automation':
        return <AddAutomation />;
      case 'add-product-fees':
        return <AddProductFees />;
      case 'add-fees':
        return <AddFees />;
      case 'remove-prizedraw-clients':
        return <RemovePrizedrawClient />;
      case 'remove-billing-fee':
        return <RemoveBillingFee />;
      case 'prizedraw-entrant-action':
        return <PrizeDrawEntrantAction />;
      case 'anonymize':
        return <Anonymize />;
      case 'entrant-export':
        return <EntrantExport />;
      case 'activate-prizedraw':
        return <ActivatePrizedraw />;
      case 'automation-log-cancel':
        return <AutomationLogCancel />;
      case 'automation-log-action':
        return <AutomationLogAction />;
      case 'group-action':
        return <GroupActions />;
      case 'prizedraw-entrant-reactive':
        return <PrizeDrawEntrantReActive />;
      default:
        return <></>;
    }
  };

  if (!modal.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center', overflow: 'scroll' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              marginTop: modal.content?.top ? '86px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
