import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const BillingOverviewExport = ({ log = {} }) => {
  const { performed_by } = log;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Billing overview exported'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">exported billing overview</span>
          </span>
        }
        showDetails={false}
        showDownIcon={false}
      />
    </div>
  );
};

export default BillingOverviewExport;
