import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import uuid from 'react-uuid';
import { useTheme } from 'styled-components';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/multiple-users.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalize, formatText, isDataChanged } from '../../../helpers/utils';
import { getGroupTag, updateGroupDetails } from '../../../store/features/groupsSlice';
import { addToast } from '../../../store/features/toastSlice';
import { getUserRoles } from '../../../store/features/userManagementSlice';
import { GroupDetailLeftWrapper } from '../../../styles/components/group-details/group-detail.styled';
import { Tooltip } from '../..//tooltip/tooltip';
import DropDown from '../../McDropdown';
import Menu from '../../common/menu';
import Status from '../../common/status';
import UpdateAction from '../../common/update-action';

const Option = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`option-wrapper flex items-center justify-start w-full px-2 py-1 ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <div className="regular-text">{data?.label}</div>
    </div>
  );
};

const GroupDetailsLeft = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { colors: themeColors } = theme || {};
  const { t } = useTranslation();
  const { groupDetails, groupTags, accountList } = useSelector(state => state.groups);
  const { userRoles } = useSelector(state => state.usersManagement);
  const { hasPermission } = useContext(OrganisationContext);
  const { id } = useParams();

  const [editField, setEditField] = useState({});
  const [error, setError] = useState({});
  const hasManagePermission = hasPermission('ORGANIZATION_CATEGORIES_MANAGE') ? true : false;
  const [updatedUserRoles, setUpdatedUserRoles] = useState(userRoles || []);

  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getGroupTag());
  }, []);

  useEffect(() => {
    const updatedUserRoles = userRoles.map(role => {
      return { ...role, label: role.name, value: role.id };
    });
    setUpdatedUserRoles(updatedUserRoles);
  }, [userRoles]);

  const isValidData = () => {
    if (editField.type === 'NAME' && !editField.value) {
      setError({ type: 'NAME', text: 'Please enter name' });
      return false;
    } else if (editField.type === 'DESCRIPTION' && !editField.value) {
      setError({ type: 'DESCRIPTION', text: 'Please enter description' });
      return false;
    } else if (editField.type === 'ROLE' && editField.value.length === 0) {
      setError({ type: 'ROLE', text: 'Please select user role' });
      return false;
    } else {
      setError({});
      return true;
    }
  };
  const onCancelUpdateData = () => {
    setEditField({});
    setError({});
  };

  const onUpdateData = (field, value) => {
    if (!isValidData()) {
      return;
    }
    const { name, description, roles } = groupDetails;
    let request = {};
    if (field === 'roles') {
      request = {
        ...groupDetails,
        name: name,
        description: description,
        roles: value.map(role => {
          return { id: role.id };
        }),
      };
    } else if (field === 'tag') {
      request = {
        ...groupDetails,
        name: name,
        description: description,
        tag: { id: value?.id },
      };
    } else {
      request = {
        ...groupDetails,
        name: name,
        description: description,
        roles: roles.map(role => {
          return { id: role.id };
        }),
        [field]: value,
      };
    }

    dispatch(updateGroupDetails({ id: id, request: request }))
      .then(data => {
        onCancelUpdateData();
      })
      .catch(({ response }) => {
        const { data } = response;
        dispatch(
          addToast({
            error: true,
            text: data?.error_description ? data?.error_description : 'Error while updating group details',
            id: uuid(),
          }),
        );
      });
  };

  const nameField =
    editField.type === 'NAME' ? (
      <div className="relative">
        <input
          autoComplete="turnoff"
          className={'input w-full name-input'}
          onChange={e => {
            setEditField({ ...editField, value: e.target.value });
            setError({ ...error, name: false });
          }}
          value={editField?.value}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              editField.value || '',
              groupDetails.name || '',
              () => onUpdateData('name', editField.value),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-center justify-start mt-1 w-full ${hasManagePermission ? 'cursor hover-edit' : ''}`}
        onClick={() => setEditField({ type: 'NAME', value: groupDetails?.name })}>
        <label className="semibold-text font-16 one-line w-full one-line hover-margin mr-10 break-word flex-1">
          {groupDetails?.name}
        </label>
        <EditIcon width={16} height={16} className={`edit-icon mr-2 ${hasManagePermission ? '' : 'display-none'}`} />
      </div>
    );

  const descriptionField =
    editField.type === 'DESCRIPTION' ? (
      <div className="relative">
        <textarea
          className={`textarea w-full description-input`}
          onChange={e => {
            setEditField({ ...editField, value: e.target.value });
            setError({ ...error, description: false });
          }}
          placeholder="Enter description here"
          rows={4}
          value={editField.value || ''}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              editField.value || '',
              groupDetails.name || '',
              () => onUpdateData('description', editField.value),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-start justify-start mt-1 w-full ${hasManagePermission ? 'cursor hover-edit' : ''}`}
        onClick={() =>
          hasManagePermission ? setEditField({ type: 'DESCRIPTION', value: groupDetails?.description }) : {}
        }>
        <p className=" regular-text w-full break-word hover-margin mr-10 flex-1">{groupDetails?.description}</p>
        <EditIcon
          width={16}
          height={16}
          className={`edit-icon mr-2 mt-1 ${hasManagePermission ? '' : 'display-none'}`}
        />
      </div>
    );

  const userRolesField =
    editField.type === 'ROLE' ? (
      <div className="relative">
        <Select
          isMulti={true}
          options={updatedUserRoles || []}
          placeholder={'Select user role'}
          onChange={e => {
            setEditField({ ...editField, value: e });
          }}
          value={editField?.value}
          menuPlacement={'top'}
          maxMenuHeight={150}
          closeMenuOnSelect={false}
          isSearchable={true}
          styles={{
            multiValue: (baseStyles, state) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              background: themeColors.ZenLightGray,
              borderRadius: 6,
              padding: 4,
              fontFamily: 'Poppins Regular',
              color: themeColors.RegularText,
              fontSize: 14,
              maxHeight: 24,
            }),
            multiValueRemove: (baseStyles, state) => ({
              ...baseStyles,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 8,
              padding: 2,
              background: '#ffff',
              borderRadius: 4,
              height: 16,
              width: 16,
              cursor: 'pointer',
              ':hover': { background: '#ffff' },
            }),
          }}
          components={{
            Option,
            IndicatorSeparator: props => {
              <components.IndicatorSeparator {...props} className="display-none" />;
            },
            ClearIndicator: props => {
              <components.ClearIndicator {...props} className="display-none" />;
            },
            Control: props => (
              <components.Control
                {...props}
                className={`user-dropdown flex items-center justify-start radius-4 border cursor"`}
              />
            ),
            Placeholder: props => <components.Placeholder {...props} className="regular-text grey-text" />,
            Menu: props => <components.Menu {...props} className="menu-wrapper absolute radius-4 pxy-2" />,
          }}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'MULTI_DROPDOWN',
              editField.value || [],
              groupDetails?.roles || [],
              () => onUpdateData('roles', editField.value),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-center justify-start w-full wrap mt-1 relative ${
          hasManagePermission ? 'cursor pr-2 hover-edit' : ''
        }`}
        onClick={() => {
          if (hasManagePermission) {
            const lstUpdatedRole = groupDetails.roles.map(role => {
              return { ...role, label: role.name, value: role.id };
            });
            setEditField({ type: 'ROLE', value: [...lstUpdatedRole] });
          }
        }}>
        <div className="flex flex-column flex-wrap hover-margin hover-margin-bottom">
          {(groupDetails?.roles || []).map(grp => (
            <div className={`flex items-center status-container my-1 mt-1`}>
              <label className="medium-text font-12">{capitalize(formatText(grp?.name))}</label>
            </div>
          ))}
        </div>
        <EditIcon
          width={16}
          height={16}
          className={`role-edit-icon absolute ${hasManagePermission ? '' : 'display-none'}`}
        />
      </div>
    );

  const tagsField =
    editField.type === 'tag' ? (
      <div className="relative mb-6 mt-1">
        <DropDown
          className="provider-selector"
          options={groupTags}
          selected={groupTags.find(option => option?.id === editField?.value?.id)}
          setSelected={option => setEditField({ type: 'tag', value: option })}
          placeholder={'Select Tag'}
        />
        <UpdateAction
          className="update-action"
          onCancel={() => onCancelUpdateData()}
          onUpdate={() =>
            isDataChanged(
              'DROPDOWN',
              editField.value || {},
              groupDetails.tag || {},
              () => onUpdateData('tag', editField.value),
              onCancelUpdateData,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-center justify-start mt-1 w-full ${hasManagePermission ? 'cursor hover-edit' : ''}`}
        onClick={() => {
          setEditField({ type: 'tag', value: groupDetails?.tag || null });
        }}>
        <div className="flex items-center flex-1 hover-margin hover-margin-bottom">
          {groupDetails?.tag ? (
            <Status status={'GROUP_TAG'} statusText={capitalize(formatText(groupDetails?.tag?.tag, ' '))} />
          ) : (
            <div className="empty-tag" />
          )}
        </div>
        <EditIcon width={16} height={16} className={`edit-icon mr-2 ${hasManagePermission ? '' : 'display-none'}`} />
      </div>
    );

  return (
    <GroupDetailLeftWrapper className="border flex-column">
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text fw-500">{t('GROUP_DETAILS')}</label>
        <div className="flex items-center justify-start">
          <Tooltip content={<label className="regular-text font-12">{t('ACCOUNTS')}</label>} placement={'bottom-start'}>
            <div className="flex items-center justify-start">
              <UsersIcon />
              <label className="regular-text font-14 ml-1 mr-6">
                {accountList?.total_elements ? accountList?.total_elements : 0}
              </label>
            </div>
          </Tooltip>
          <Menu />
        </div>
      </div>
      <div className="py-6 pl-6 pr-4 flex-column flex-1 overflow-scroll">
        <div className="flex items-center">
          <div className="flex-column flex-1 relative min-w-0">
            <span className="regular-text grey-text font-12">{t('NAME')}</span>
            {error.type === 'NAME' && (
              <div className="error-container flex absolute">
                <div className="border-left-content " />
                <span className="regular-text font-12 pxy-1">{error.text}</span>
              </div>
            )}
            {nameField}
          </div>
        </div>
        <div className="flex-column mt-6 relative">
          <span className="regular-text grey-text font-12">{t('DESCRIPTION')}</span>
          {error.type === 'DESCRIPTION' && (
            <div className="error-container flex absolute">
              <div className="border-left-content " />
              <span className="regular-text font-12 pxy-1">{error.text}</span>
            </div>
          )}
          {descriptionField}
        </div>
        <div className="flex-column mt-6 relative">
          <span className="regular-text grey-text font-12">{t('USER_ROLES')}</span>
          {error.type === 'ROLE' && (
            <div className="error-container flex absolute">
              <div className="border-left-content " />
              <span className="regular-text font-12 pxy-1">{error.text}</span>
            </div>
          )}
          {userRolesField}
        </div>
        <div className="flex-column mt-6 relative">
          <span className="regular-text grey-text font-12">{t('TAGS')}</span>
          {error.type === 'Tags' && (
            <div className="error-container flex absolute">
              <div className="border-left-content " />
              <span className="regular-text font-12 pxy-1">{error.text}</span>
            </div>
          )}
          {tagsField}
        </div>
      </div>
    </GroupDetailLeftWrapper>
  );
};

export default GroupDetailsLeft;
