import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const automationsSlice = createSlice({
  name: 'automations',
  initialState: {
    automationsList: [],
    userList: [],
    automationConfigLoading: false,
    automationLogList: [],
    automationLogsList: [],
    automationLogDetails: null,
    automationLogAction: null,
    automationLogActionIntegration: null,
    automationLogIntegrationDetails: null,
    automationSaveDetails: null,
  },
  reducers: {
    setAutomationsList: (state, { payload }) => {
      state.automationsList = payload;
    },
    setAutomationDetails: (state, { payload }) => {
      state.automationDetails = payload;
    },
    setUserList: (state, { payload }) => {
      state.userList = payload;
    },
    setAutomationConfigLoading: (state, { payload }) => {
      state.automationConfigLoading = payload;
    },
    setAutomationLogList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.automationLogList?.content, ...payload?.data?.content],
        };
        state.automationLogList = mergeData;
      } else {
        state.automationLogList = payload.data;
      }
    },
    setAutomationLogDetails: (state, { payload }) => {
      state.automationLogDetails = payload;
    },
    setAutomationLogActions: (state, { payload }) => {
      state.automationLogAction = payload;
    },
    setAutomationLogActionIntegration: (state, { payload }) => {
      state.automationLogActionIntegration = payload;
    },
    setAutomationLogIntegrationDetails: (state, { payload }) => {
      state.automationLogIntegrationDetails = payload;
    },
    setEventTypeData: (state, { payload }) => {
      state.eventTypeData = payload;
    },
    setActionDetails: state => {
      state.automationLogActionIntegration = '';
      state.automationLogIntegrationDetails = '';
      state.automationSaveDetails = '';
    },
    setAutomationSaveData: (state, { payload }) => {
      state.automationSaveDetails = payload;
    },
  },
});

export const getAutomationsList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/automations`, { params: payload?.params });
    dispatch(setAutomationsList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationDetails = payload => async dispatch => {
  try {
    dispatch(setAutomationConfigLoading(true));
    const { data } = await api.get(`/automations/${payload.automationID}`);
    dispatch(setAutomationDetails(data));
    dispatch(setAutomationConfigLoading(false));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/users`, { params: payload.params });
    dispatch(setUserList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationDetails = payload => async dispatch => {
  try {
    const { automationID, request } = payload;
    const { data } = await api.put(`/automations/${automationID}`, request);
    dispatch(getAutomationDetails({ automationID: automationID }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationStatus = payload => async dispatch => {
  try {
    const { automationID, request } = payload;
    const { data } = await api.put(`/automations/${automationID}/state`, request);
    dispatch(getAutomationDetails({ automationID: automationID }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAutomation = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/automations`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAutomation = payload => async dispatch => {
  try {
    const { automationID } = payload;
    const { data } = await api.delete(`/automations/${automationID}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationLogList = payload => async dispatch => {
  try {
    const { params, organizationID, merge } = payload;
    const { data } = await api.get(`/automation_logs`, {
      params: params,
    });
    dispatch(setAutomationLogList({ data: data, merge: merge }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const cancelAutomationLogs = payload => async dispatch => {
  try {
    const { automationLogId } = payload;
    const { data } = await api.post(`/action_logs/${automationLogId}/cancel`);
    dispatch(getAutomationLogList(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationLogDetails = payload => async dispatch => {
  try {
    const { automationLogId } = payload;
    const { data } = await api.get(`/automation_logs/${automationLogId}`);
    dispatch(setAutomationLogDetails(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retryAutomationLog = payload => async dispatch => {
  try {
    const { automationLogId } = payload;
    const { data } = await api.post(`/action_log/${automationLogId}/retry`);
    dispatch(getAutomationLogList(payload));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEventTypes = () => async dispatch => {
  try {
    const { data } = await api.get(`/event_types`);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEventTypeById =
  ({ event_type_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/event_types/${event_type_id}`);
      return Promise.resolve({ data });
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateEventType = payload => async dispatch => {
  const { request, id } = payload;
  try {
    const { data } = await api.put(`/automations/${id}/event_type`, request);
    dispatch(getAutomationDetails({ automationID: id }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationAction = payload => async dispatch => {
  try {
    const { data } = await api.get(`/actions`);
    dispatch(setAutomationLogActions(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateActionType = payload => async dispatch => {
  try {
    const { data } = await api.get(`/actions/${payload}/integrations`);
    dispatch(setAutomationLogActionIntegration(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationConnector = payload => async dispatch => {
  try {
    const { data } = await api.get(`/connector/${payload}/config`);
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationIntegrationAction = payload => async dispatch => {
  const { actionType, id } = payload;
  try {
    const { data } = await api.get(`/integrations/${id}/action/${actionType}/config`);
    dispatch(setAutomationLogIntegrationDetails(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationEventType = payload => async dispatch => {
  try {
    const { data } = await api.get(`/automations/${payload}`);
    dispatch(setEventTypeData(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationSaveDetails = payload => async dispatch => {
  const { id, request } = payload;
  try {
    const { data } = await api.post(`/automations/${id}/actions`, request);
    dispatch(setAutomationSaveData(data));
    dispatch(getAutomationDetails({ automationID: id }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationSaveDetails = payload => async dispatch => {
  const { id, request, actionId } = payload;
  try {
    const { data } = await api.put(`/automations/${id}/actions/${actionId}`, request);
    dispatch(setAutomationSaveData(data));
    dispatch(getAutomationDetails({ automationID: id }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const clearAutomationActionData = () => async dispatch => {
  try {
    dispatch(setActionDetails());
    // return Promise.resolve({ data });
  } catch (error) {
    // return Promise.reject(error);
  }
};

export const getTemplateOptions = payload => async dispatch => {
  try {
    const { id, actionType, paramId, params = {} } = payload;
    const { data } = await api.get(`/integrations/${id}/action/${actionType}/lists/${paramId}`, { params });
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAutomationAction = payload => async dispatch => {
  const { id, automationId } = payload;
  try {
    const { data } = await api.delete(`/automations/${id}/actions/${automationId}`);
    dispatch(getAutomationDetails({ automationID: id }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};
export const {
  setAutomationsList,
  setAutomationDetails,
  setUserList,
  setAutomationConfigLoading,
  setAutomationLogList,
  setAutomationLogDetails,
  setAutomationLogActions,
  setAutomationLogActionIntegration,
  setAutomationLogIntegrationDetails,
  setEventTypeData,
  setActionDetails,
  setAutomationSaveData,
} = automationsSlice.actions;
export default automationsSlice.reducer;
