import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { useTheme } from 'styled-components';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import Loading from '../../assets/images/loading.svg';
import { filterChildRenderComponent, multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getPrizeDrawForm } from '../../store/features/prizeDrawSlice';
import { PrizeDrawFormWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import InputSearch from '../common/input-search';
import Loader from '../common/loader';
import Menu from '../common/menu';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import Status from '../common/status';
import MultipleFilter from '../multiple-filter';
import PrizeDrawDetailHeader from './prize-draw-details-header';

const PrizeDrawForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hasPermission } = useContext(OrganisationContext);
  const { t } = useTranslation();
  const { prizeDrawForm } = useSelector(state => state.prizeDraw);
  const pageRef = useRef(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([
    {
      id: uuid(),
      name: 'Status',
      param: 'status',
      type: 'STATUS',
      object: 'STATUS',
      childRenderComponent: filterChildRenderComponent.dropDown,
      allowTranslateOption: true,
      value: { id: 'ACTIVE', name: 'Active' },
    },
  ]);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { colors: themeColors } = theme || {};

  const createFormFilterUrl = filters => {
    let url = ``;
    filters?.forEach(item => {
      url = item.value
        ? url.concat(
            `&${item.param}=${
              item.param === 'prizedraw_client_code' ? item.value.prizedraw_client_code : item.value.id
            }`,
          )
        : url;
    });
    return url;
  };

  const getEntryFormData = (page, merge) => {
    pageRef.current = page;
    const lastObject = filters[filters.length - 1];
    if (lastObject && lastObject.value && !merge) {
      setLoading(true);
    }
    const url = createFormFilterUrl(filters);
    dispatch(
      getPrizeDrawForm({
        params: { prizedraw: id, page: page, search: debouncedSearch },
        merge: merge,
        url: url,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const fetchMoreData = () => {
    getEntryFormData(pageRef.current + 1, true);
  };

  useEffect(() => {
    getEntryFormData(0, false);
  }, [id, filters, debouncedSearch]);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenFilter(false);
    }
  };
  const redirectToForms = form_id => {
    if (hasPermission('FORMS_VIEW')) {
      navigate(`/prize-draws/all/${id}/form-details/${form_id}/form-log`);
    }
  };

  const openForm = form_id => {
    window.open(`https://forms.${process.env.REACT_APP_BASE_DOMAIN}/#/forms/${form_id}`, '_blank');
  };

  return (
    <PrizeDrawFormWrapper className="flex-column items-start justify-start w-full h-full">
      <PrizeDrawDetailHeader
        title={t('FORMS')}
        actionContent={
          <>
            <div className="items-center justify-between w-full mr-2">
              <div className="flex items-center justify-end flex-1">
                <div className="relative" onBlur={onBlur} tabIndex={0}>
                  <div
                    className="filter pxy-2 mr-6 flex items-center justify-center cursor"
                    onClick={() => setOpenFilter(!openFilter)}>
                    <FilterIcon className={`${(openFilter || filters.some(f => f.value)) && 'open-filter-icon'}`} />
                  </div>
                  {openFilter && (
                    <MultipleFilter
                      className="prize-draw-entrant-filter"
                      filters={filters}
                      setFilters={setFilters}
                      filterFor={multipleFilterTypeEnum.prizeDrawForms}
                    />
                  )}
                </div>
                <InputSearch
                  placeholder={'Search'}
                  value={searchText}
                  onChange={setSearchText}
                  className="input-search"
                />
              </div>
            </div>
          </>
        }
      />
      {loading ? (
        <Loader />
      ) : prizeDrawForm?.content?.length > 0 ? (
        <div className="flex-column flex-1 overflow-scroll w-full" id="scrollableDiv">
          <InfiniteScroll
            className="flex-column flex-1"
            dataLength={prizeDrawForm?.content.length}
            hasMore={!prizeDrawForm?.last}
            loader={
              <div className="item flex items-center justify-center">
                <img alt="loading" height="32px" src={Loading} />
              </div>
            }
            next={fetchMoreData}
            scrollableTarget="scrollableDiv">
            {prizeDrawForm?.content?.map(form => (
              <div
                className="item-container flex items-center justify-center w-full border-bottom py-2 cursor"
                onClick={() => redirectToForms(form?.id)}>
                <div className="flex-column items-start justify-center border-right h-full w-full px-6">
                  <label className="regular-text one-line w-full">{form?.name}</label>
                  <span className="regular-text lighter-text font-12 one-line w-full">
                    {form?.prizedraw_client?.organization?.name}
                  </span>
                </div>
                <div className="flex items-center justify-start border-right h-full px-6">
                  <span className="regular-text grey-text two-lines word-break-all w-full">{form?.description}</span>
                </div>
                <div className="flex items-center justify-center border-right h-full px-4">
                  <Status
                    withDot
                    status={'PRIZE_DRAW_FORM_LABEL'}
                    dotColor={form?.form_type?.is_custom ? themeColors?.ZenBlue : themeColors?.ZenOrange}
                    statusText={form?.form_type?.name}
                    withDottedBorder
                  />
                </div>
                <div className="flex items-center justify-between px-4">
                  <Status
                    withDot
                    status={form?.status}
                    statusText={form?.status === 'ACTIVE' ? 'Active' : 'Retired'}
                    withDottedBorder
                  />
                  <Menu
                    menuList={[
                      { name: t('DETAILS'), onClick: () => redirectToForms(form?.id), permission: 'FORMS_VIEW' },
                    ].filter(menuItem => (menuItem.permission ? hasPermission(menuItem.permission) : true))}
                    ExtraOptions={
                      form.status === 'ACTIVE' ? (
                        <div
                          className={`flex items-center regular-text justify-between ${
                            hasPermission('FORMS_VIEW') ? 'border-top mt-2' : ''
                          }`}
                          onClick={() => openForm(form?.id)}>
                          <div
                            className={`px-2 pt-2 pb-2 option-hover w-full ${
                              hasPermission('FORMS_VIEW') ? 'mt-2' : ''
                            }`}>
                            {' '}
                            {t('LAUNCH_FORM')}
                          </div>
                        </div>
                      ) : null
                    }
                  />
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : debouncedSearch || filters.some(f => f?.value) ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </PrizeDrawFormWrapper>
  );
};

export default PrizeDrawForm;
