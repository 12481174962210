import React from 'react';
import { ReactComponent as EmptyImage } from '../../../assets/images/subtract.svg';

const PrizedrawInfo = ({ prizedraw }) => {
  return (
    <div className="flex-column radius-4 pxy-4 border w-full">
      <div className="flex items-start w-full col-gap-2 prizedraw-info-header py-1">
        {prizedraw?.image?.media[0]?.url ? (
          <div className="flex items-center justify-center radius-4 h-full empty-image-wrapper">
            <img src={prizedraw?.image?.media[0]?.url} height={40} width={40} className="radius-4" />
          </div>
        ) : (
          <div className="flex items-center justify-center empty-img-content radius-4">
            <EmptyImage className="empty-img" height={32} width={32} />
          </div>
        )}
        <div className="flex-column items-start justify-start flex-1">
          <label className="semibold-text regular-text font-16"> {prizedraw?.name}</label>
          <label className="regular-text lighter-text font-12">{prizedraw?.code}</label>
        </div>
      </div>
      <label className="regular-text grey-text font-14 mt-4">{prizedraw?.description}</label>
    </div>
  );
};

export default PrizedrawInfo;
