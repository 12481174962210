import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HomeDetailRightWrapper } from '../../styles/components/home-details/home-details.styled';
import HomeActivityLogs from './home-activity-logs';
import HomeEntrants from './home-entrants';
import { OrganisationContext } from '../../context/organisationContext';

const homeRoutes = [
  {
    id: 1,
    path: '/logs',
    Component: HomeActivityLogs,
    permission: ['HOMEPAGE_LOG_VIEW'],
  },
  {
    id: 2,
    path: '/entrants',
    Component: HomeEntrants,
    permission: ['ENTRANTS_VIEW'],
  },
];

const HomeDetailsRight = () => {
  const { hasMultiplePermission } = useContext(OrganisationContext);

  const getRoute = () => {
    const currentPath = `/home`;
    const route = homeRoutes.find(path => hasMultiplePermission(path.permission));
    if (route) {
      return `${currentPath}${route.path}`;
    }
  };
  return (
    <HomeDetailRightWrapper className="flex border flex-1">
      <Routes>
        {homeRoutes
          .filter(path => hasMultiplePermission(path.permission))
          .map(path => (
            <Route
              key={path.id}
              element={<path.Component {...path.params} path={path.path} {...path.actions} />}
              path={path.path}
            />
          ))}
        {getRoute() && <Route element={<Navigate replace to={getRoute()} />} path="*" />}
      </Routes>
    </HomeDetailRightWrapper>
  );
};

export default HomeDetailsRight;
