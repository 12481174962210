import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from '../../../../assets/images/user.svg';
import { capitalize, formatText } from '../../../../helpers/utils';
import PaymentStatus from '../../../common/status/payment-status';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantPaymentStatusUpdate = ({ log = {} }) => {
  const { event_data } = log;
  const { entrant, previous } = event_data || {};
  const { t } = useTranslation();
  const entrantProduct = entrant?.product || {};
  const previousProduct = previous?.entrant?.product || {};

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader icon={<UserIcon />} title={'Payment status updated'} />
      <LogTitle
        showDownIcon={false}
        text={
          <span className="flex items-center fw-w">
            <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{entrant.urn}</span>
            <span className="regular-text lighter-text font-12 ml-1">{`payment status updated ${
              previousProduct?.product_status !== entrantProduct?.product_status ? 'from' : ''
            }`}</span>
            {previousProduct?.product_status !== entrantProduct?.product_status && (
              <PaymentStatus
                withDot={true}
                status={previousProduct?.product_status}
                statusText={capitalize(formatText(previousProduct?.product_status, ' '))}
                statusWrapperClassName={'ml-1'}
              />
            )}
            <span className="regular-text lighter-text font-12 ml-1">to</span>
            <PaymentStatus
              withDot={true}
              status={entrantProduct?.product_status}
              statusText={capitalize(formatText(entrantProduct?.product_status, ' '))}
              statusWrapperClassName={'ml-1'}
            />
          </span>
        }
      />
    </div>
  );
};

export default EntrantPaymentStatusUpdate;
