import React, { useEffect, useState } from 'react';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const AccountUpdated = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState({});
  const { event_data, performed_by } = log;
  const { previous, organization } = event_data;
  const performedBy = getPerformedByFromLog(log);

  const getupdatedData = () => {
    let updatedData = { ...data };
    if (organization?.name !== previous?.organization?.name) {
      updatedData = {
        title: 'updated account name',
        from: previous?.organization?.name,
        to: organization?.name,
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else if (organization?.code !== previous?.organization?.code) {
      updatedData = {
        title: 'updated account name abbreviation',
        from: previous?.organization?.code,
        to: organization?.code,
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else if (
      (organization?.locations || []).find(sa => sa.is_postal)?.formatted_address !==
      (previous?.organization?.locations || []).find(sa => sa.is_postal)?.formatted_address
    ) {
      updatedData = {
        title: 'updated account shipping address',
        from: (previous?.organization?.locations || [])?.find(sa => sa.is_postal)?.formatted_address,
        to: (organization?.locations || [])?.find(sa => sa.is_postal)?.formatted_address,
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else if (
      (organization?.locations || []).find(ba => ba.is_billing)?.formatted_address !==
      (previous?.organization?.locations || []).find(ba => ba.is_billing)?.formatted_address
    ) {
      updatedData = {
        title: 'updated account billing address',
        from: (previous?.organization?.locations || [])?.find(ba => ba.is_billing)?.formatted_address,
        to: (organization?.locations || [])?.find(ba => ba.is_billing)?.formatted_address,
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else if (
      (organization?.contacts || [])?.find(cont => cont?.contact_type === 'MOBILE')?.contact_value !==
      (previous?.organization?.contacts || [])?.find(cont => cont?.contact_type === 'MOBILE')?.contact_value
    ) {
      const fromMobile = (previous?.organization?.contacts || [])?.find(cont => cont?.contact_type === 'MOBILE') || {};
      const toMobile = (organization?.contacts || [])?.find(cont => cont?.contact_type === 'MOBILE') || {};
      updatedData = {
        title: 'updated account phone number',
        from: fromMobile?.contact_value ? `${fromMobile?.country_code}${fromMobile?.contact_value}` : 'no number',
        to: toMobile?.contact_value ? `${toMobile?.country_code}${toMobile?.contact_value}` : 'no number',
        fromLabel: 'From',
        toLabel: 'To',
      };
    } else if (
      (organization?.contacts || [])?.find(cont => cont?.contact_type === 'EMAIL')?.contact_value !==
      (previous?.organization?.contacts || [])?.find(cont => cont?.contact_type === 'EMAIL')?.contact_value
    ) {
      updatedData = {
        title: 'updated account email',
        from: (previous?.organization?.contacts || [])?.find(cont => cont?.contact_type === 'EMAIL')?.contact_value,
        to: (organization?.contacts || [])?.find(cont => cont?.contact_type === 'EMAIL')?.contact_value,
        fromLabel: 'From',
        toLabel: 'To',
      };
    }
    setData(updatedData);
  };
  useEffect(() => {
    getupdatedData();
  }, []);
  const getitleText = (
    <>
      <span className="regular-text lighter-text font-12">User</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{data?.title || ''}</span>
    </>
  );

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Account updated'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
        showDownIcon={!data?.hideDetails}
      />
      {showDetails && !data?.hideDetails && (
        <>
          <LogContentBox
            title={data.fromLabel || 'From'}
            contentText={<p className="regular-text lighter-text font-12">{data?.from || ''}</p>}
          />
          <LogContentBox
            title={data?.toLabel || 'To'}
            contentText={<p className="regular-text lighter-text font-12">{data?.to || ''}</p>}
          />
          {organization?.description !== previous?.organization?.description && (
            <LogContentBox
              title={'Account'}
              contentText={
                <div className="flex items-center w-full">
                  <p className="regular-text lighter-text font-12">{organization?.name || ''}</p>
                  <p className="medium-text lighter-text font-12 ml-8">{organization?.code || ''}</p>
                </div>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default AccountUpdated;
