import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import DropDown from '../../../components/McDropdown';
import { OrganisationContext } from '../../../context/organisationContext';
import { getItemFromLocalStorage } from '../../../helpers/localstorage';
import useDebounce from '../../../helpers/useDebounceHook';
import {
  AddOrganization,
  getOrganizationCategories,
  getOrganizationNetworks,
  getPrizeDrawOrganization,
} from '../../../store/features/prizeDrawSlice';
import { addToast } from '../../../store/features/toastSlice';
import { PrizeDrawOrganizationssWrapper } from '../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Button from '../../Elements/button/button';
import InputSearch from '../../common/input-search';
import Loader from '../../common/loader';
import NoDataComponent from '../../common/no-data-component';
import NoResultComponent from '../../common/no-result-component';
import UpdateAction from '../../common/update-action';
import PrizeDrawDetailHeader from '../prize-draw-details-header';
import PrizeDrawOrganizationItem from './prize-draw-organization-item';

const Option = props => {
  const { option: data, isSelected, handleSelect } = props;
  return (
    <div
      key={data.id}
      onClick={() => handleSelect(data)}
      className={`option-wrapper flex-column items-start justify-start w-full px-2 py-1 radius-1 cursor ${
        isSelected ? 'selected' : ''
      }`}
      style={{ height: '54px' }}>
      <div className="regular-text font-16">{data?.name}</div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between w-50">
          <div className="flex items-center justify-start w-50">
            <MailIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                data?.contact_details?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {data?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? data?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-50 ml-2">
            <MobileIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                data?.contact_details?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {data?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? data?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <label
          className={`regular-text text-right ml-10 flex-1 one-line font-14 lighter-text ${
            data?.primary_location?.formatted_address ? '' : 'grey-text'
          }`}>
          {data?.primary_location?.formatted_address ? data?.primary_location?.formatted_address : 'Not provided'}
        </label>
      </div>
    </div>
  );
};

const singleValueComponent = value => {
  return (
    <div className={`flex-column items-start justify-start flex-1 w-full px-2 py-1 overflow-hidden`}>
      <div className="regular-text font-16">{value?.name}</div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between" style={{ width: '40%' }}>
          <div className="flex items-center justify-start w-50">
            <MailIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line ${
                value?.contact_details?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {value?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? value?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-50 ml-2">
            <MobileIcon className="flex items-center justify-center mr-2" height={16} width={16} />
            <label
              className={`regular-text one-line font-14 lighter-text flex-1 one-line${
                value?.contact_details?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
              }`}>
              {value?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? value?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <label
          className={`regular-text text-right ml-10 flex-1 one-line font-14 lighter-text ${
            value?.primary_location?.formatted_address ? '' : 'grey-text'
          }`}>
          {value?.primary_location?.formatted_address ? value?.primary_location?.formatted_address : 'Not provided'}
        </label>
      </div>
    </div>
  );
};

const PrizeDrawOrganization = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showAddOrganization, setAddOrganization] = useState(false);
  const dispatch = useDispatch();
  const { prizeDrawOrganizations, prizeDrawOrganizationNetworks, prizeDrawOrganizationCategories } = useSelector(
    state => state.prizeDraw,
  );
  const { hasPermission } = useContext(OrganisationContext);
  const pageRef = useRef(null);
  const debouncedSearch = useDebounce(searchText, 500);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const userOrganization = getItemFromLocalStorage('user').organizations[0];
  const [loading, setLoading] = useState(false);
  const [showAddMarketingCompany, setShowAddMarketingCompany] = useState('');
  const [organizationSearchText, setOrganizatiomSearchText] = useState('');
  const debouncedOrganizationSearch = useDebounce(organizationSearchText, 500);
  const prizedrawOrganizationIds = prizeDrawOrganizations?.map(org => org?.id) || [];

  const getPrizeDrawOrganizationData = (page, merge) => {
    pageRef.current = page;
    if (!merge) {
      setLoading(true);
    }
    dispatch(
      getPrizeDrawOrganization({
        params: { page: page, search: debouncedSearch },
        merge: merge,
        prizedrawid: id,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getPrizeDrawOrganizationData(0, false);
  }, [id, debouncedSearch]);

  const getOrganizationCategoryIds = (orgCategories = []) => {
    return [...orgCategories].map(data => data.id).join(',');
  };

  useEffect(() => {
    dispatch(getOrganizationCategories());
  }, []);

  const handleAddOrganization = () => {
    if (selectedOrganization) {
      dispatch(
        AddOrganization({
          params: { organization: { id: selectedOrganization.id }, parent: { id: userOrganization.id } },
          prizedrawid: id,
        }),
      ).then(data => {
        setSelectedOrganization(null);
        setAddOrganization(false);
      });
    } else {
      dispatch(addToast({ error: true, text: 'Please select organization', id: uuid() }));
      return;
    }
  };

  const getOrganizationNetworksOptions = (page, merge) => {
    pageRef.current = page;
    dispatch(
      getOrganizationNetworks({
        param: {
          organization_category_id: getOrganizationCategoryIds(prizeDrawOrganizationCategories),
          search: debouncedOrganizationSearch,
          page: page,
        },
        callFormTemplate: true,
        merge: merge,
      }),
    );
  };

  const fetchMoreOrganizationNetworks = () => {
    getOrganizationNetworksOptions(pageRef.current + 1, true);
  };

  useEffect(() => {
    getOrganizationNetworksOptions(0, false);
  }, [debouncedOrganizationSearch]);

  useEffect(() => {
    if (!showAddOrganization) {
      setOrganizatiomSearchText('');
      pageRef.current = 0;
    }
  }, [showAddOrganization]);

  const getPrizeDrawList = () => {
    const output = prizeDrawOrganizations?.map(data => (
      <PrizeDrawOrganizationItem
        key={data.id}
        prizeDrawOrganization={data}
        setAddOrganization={setAddOrganization}
        showAddOrganization={showAddOrganization}
        showAddMarketingCompany={showAddMarketingCompany}
        setShowAddMarketingCompany={setShowAddMarketingCompany}
      />
    ));
    return <div className="flex-column items-start justify-start w-full overflow-scroll flex-1">{output}</div>;
  };

  return (
    <PrizeDrawOrganizationssWrapper className="flex-column items-center justify-start w-full h-full overflow-hidden">
      <PrizeDrawDetailHeader
        title={t('ORGANIZATIONS')}
        actionContent={
          <div className="items-center justify-between w-full">
            <div className="flex items-center justify-end flex-1">
              {hasPermission('PRIZEDRAWS_ORGANIZATIONS_MANAGE') && (
                <Button
                  fontSize="14px"
                  size="medium"
                  height="32px"
                  borderRadius="12px"
                  className={`default-secondary mr-4 ${showAddOrganization ? 'disabled' : ''}`}
                  icon={<PlusIcon height={16} width={16} />}
                  label={t('ADD_NEW')}
                  onClick={() => {
                    setAddOrganization(true);
                    setShowAddMarketingCompany('');
                    dispatch(
                      getOrganizationNetworks({
                        param: {
                          organization_category_id: getOrganizationCategoryIds(prizeDrawOrganizationCategories),
                          search: debouncedOrganizationSearch,
                          page: 0,
                        },
                      }),
                    );
                  }}
                />
              )}
              <InputSearch
                placeholder={'Search'}
                value={searchText}
                onChange={setSearchText}
                className="input-search mr-4"
              />
            </div>
          </div>
        }
      />

      <div className="w-full flex-1 h-full">
        {showAddOrganization && (
          <div
            className="add-organization-wrapper flex items-center justify-start mx-6 mt-6 mb-9 relative"
            style={{ width: 'calc(100% - 48px)' }}>
            <DropDown
              className="provider-selector w-full"
              options={(prizeDrawOrganizationNetworks?.content || [])
                .filter(item => !prizedrawOrganizationIds.includes(item.id))
                .map(item => ({
                  ...item,
                  label: item.name,
                  value: item.id,
                }))}
              optionLength={
                (prizeDrawOrganizationNetworks?.content || []).filter(
                  item => !prizedrawOrganizationIds.includes(item.id),
                ).length
              }
              placeholder={<div className="font-16 placeholder">{t('SELECT_ORGANIZATION')}</div>}
              isSearchable={true}
              isPaged={true}
              search={organizationSearchText}
              setSearch={setOrganizatiomSearchText}
              setSelected={setSelectedOrganization}
              selected={selectedOrganization}
              hasMore={!prizeDrawOrganizationNetworks?.last}
              fetchData={fetchMoreOrganizationNetworks}
              RenderOption={props => <Option {...props} />}
              optionItemHeight={54}
              renderSelectedValue={selectedClient => singleValueComponent(selectedClient)}
            />
            <UpdateAction
              onUpdate={() => handleAddOrganization()}
              onCancel={() => {
                setSelectedOrganization(null);
                setAddOrganization(false);
              }}
              className={`absolute update-action ${selectedOrganization && 'update-action-top'}`}
            />
          </div>
        )}
        <div className="w-full flex-column h-full flex-1 overflow-auto">
          {loading ? (
            <Loader />
          ) : prizeDrawOrganizations?.length > 0 ? (
            getPrizeDrawList()
          ) : debouncedSearch ? (
            <NoResultComponent />
          ) : (
            <NoDataComponent />
          )}
        </div>
      </div>
    </PrizeDrawOrganizationssWrapper>
  );
};

export default PrizeDrawOrganization;
