import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { ReactComponent as ClockIcon } from '../assets/images/time.svg';
import DropDown from '../components/McDropdown';
import DateSelectorElement from '../components/widget/date-selector-element/date-selector-element';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { createNumberMask } from '../helpers/createNumberMask';
import { capitalize, uppercase } from '../helpers/utils';
import { createFees, getProductDetailsFeesList, updateFees } from '../store/features/billingSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AddFeesWrapper } from './modal.styled';

const billingOptions = [
  { name: 'Continual billing', value: 'VARIABLE' },
  { name: 'Cohort billing', value: 'FIXED' },
];

const enumSteps = {
  details: 'DETAILS',
  confirmation: 'CONFIRMATION',
};

const AddFees = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const { productDetails } = useSelector(state => state.billing);
  const { handleSuccess, productFee, isUpdate } = modal?.content;
  const [fees, setFees] = useState({ dates: { start: moment().unix() } });
  const [currentStep, setCurrentStep] = useState(enumSteps.details);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const initalFeeDate = useRef(moment().add(-1, 'day').unix());

  useEffect(() => {
    const params = isUpdate ? { status: 'CURRENT' } : { sort_by: 'START_DATE', order_by: 'DESC' };
    dispatch(
      getProductDetailsFeesList({
        productDetailsId: productDetails?.id,
        params: { ...params },
        isPopup: true,
      }),
    ).then(response => {
      let nextDate = null;
      let feeObject = {};
      if (response?.content[0]) {
        feeObject = response?.content[0];
        nextDate = feeObject.dates.start
          ? moment(feeObject.dates.start * 1000)
              .add(1, 'day')
              .unix()
          : moment().unix();
      }
      const updatedData = isUpdate ? productFee : feeObject;
      // const feeAmount = `0${parseFloat(updatedData.fee_amount).toFixed(2)}`.slice(-5);
      const feeAmount = parseFloat(updatedData.fee_amount).toFixed(2);
      setFees({
        ...updatedData,
        fee_amount: `$${feeAmount}`,
        dates: { ...updatedData.dates, start: nextDate ? nextDate : moment().unix() },
      });
      initalFeeDate.current = feeObject.dates.start;
    });
  }, []);

  const checkErrors = () => {
    if (!fees?.billing_type?.trim() || !fees?.dates?.start || !String(fees?.fee_amount)?.replaceAll('$', '')?.trim()) {
      setErrors({
        billing_type: !fees?.billing_type?.trim(),
        start: !fees?.dates?.start,
        fee_amount: !String(fees?.fee_amount)?.replaceAll('$', '')?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill billing type, startdate and amount' }));
      return true;
    } else {
      return false;
    }
  };

  const onDone = () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      const request = {
        billing_type: fees?.billing_type,
        fee_amount: Number(String(fees?.fee_amount).replaceAll('$', '')),
        dates: { start: fees?.dates?.start },
      };
      dispatch(
        isUpdate
          ? updateFees({ request: request, product_id: productDetails?.id, fee_id: fees.id })
          : createFees({ request: request, product_id: productDetails?.id }),
      )
        .then(() => {
          if (handleSuccess) {
            handleSuccess();
          }
          setModal(initModal);
          dispatch(addToast({ error: false, text: isUpdate ? 'Fee has been edited' : 'Fee has been added' }));
        })
        .catch(err => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const detailsUI = (
    <>
      <div className="w-full flex-column mt-6">
        <label className="regular-text lighter-text font-12 mb-1">{t('BILLING_TYPE')}</label>
        <DropDown
          className={`provider-selector ${fees?.billing_type === 'VARIABLE' ? 'disabled' : ''} ${
            errors?.billing_type ? 'error-border' : ''
          }`}
          setSelected={option => {
            const showWarning = option.value !== fees?.billing_type && fees?.billing_type === 'FIXED';
            setShowWarning(showWarning ? true : false);
            setFees({ ...fees, billing_type: option?.value });
          }}
          options={billingOptions}
          placeholder="Select"
          selected={billingOptions.find(bo => bo?.value === fees?.billing_type) || null}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('START')}</label>
        <DateSelectorElement
          placeholder="MM/DD/YYYY"
          selectedDate={fees?.dates?.start}
          setSelectedDate={d => setFees({ ...fees, dates: { ...fees?.dates, start: d } })}
          format={'MM/DD/YYYY'}
          showDropdownArrow={false}
          className={`relative fromdate-container ${errors?.start ? 'error-border' : ''}`}
          disablePastDaysOfDate={true}
          disablePastDayDate={initalFeeDate.current}
          showCurrentMonth={false}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('FEE_AMOUNT')}</label>
        <MaskedInput
          mask={createNumberMask({
            prefix: '$',
            allowDecimal: true,
            includeThousandsSeparator: false,
          })}
          value={fees?.fee_amount ? fees?.fee_amount : null}
          className={`input w-full ${errors?.fee_amount ? 'error-border' : ''}`}
          placeholder={'$00.00'}
          guide={false}
          onChange={({ target: { value } }) => setFees({ ...fees, fee_amount: value })}
        />
      </div>
      {showWarning && (
        <label className="semibold-text warning-text px-2">
          WARNING you are now switching to Continual billing type. Please be certain as you cannot switch back to Cohort
          billing type.
        </label>
      )}
    </>
  );

  const confirmationUI = (
    <div className="flex-column w-full mt-6">
      {showWarning && (
        <label className="semibold-text warning-text px-2 mb-8">
          WARNING you are now switching to Continual billing type. Please be certain as you cannot switch back to Cohort
          billing type.
        </label>
      )}
      <div className="flex-column items-start justify-start confirm-box pxy-4 radius-4 w-full row-gap-2">
        <span>
          <span className="semibold-text font-16">{productDetails?.product?.name}</span>
          <span className="bold-text ml-2 zen-purple-text font-16">{`$${Number(
            String(fees?.fee_amount).replaceAll('$', ''),
          ).toFixed(2)}`}</span>
        </span>
        <label className="medium-text font-12">{moment(fees?.dates?.start * 1000).format('MMM DD, YYYY')}</label>
        <div className={`flex items-center px-2 mt-2 ${uppercase(fees?.billing_type)}`}>
          <ClockIcon className="flex items-center justify-center mr-1" height={16} width={16} color="" />
          <label className="medium-text zen-default-text  flex-1 one-line font-12">{`${capitalize(
            billingOptions?.find(bo => bo.value === fees?.billing_type)?.name,
          )}`}</label>
        </div>
      </div>
    </div>
  );

  const updateConfirmUI = (
    <div className="flex-column w-full mt-6">
      <label className="semibold-text warning-text px-2 ">
        WARNING editing this fee will result in invoices within this period to be recalculated.
      </label>
    </div>
  );

  const getContentPage = () => {
    switch (currentStep) {
      case enumSteps.details:
        return detailsUI;
      case enumSteps.confirmation:
        return isUpdate ? updateConfirmUI : confirmationUI;
    }
  };
  return (
    <CommonPopup
      popupTitle={
        currentStep === enumSteps.details
          ? isUpdate
            ? 'Edit fee'
            : 'Add Fee'
          : isUpdate
          ? 'Are you sure you want to continue?'
          : 'Are you sure you want to add this fee?'
      }
      confirmButtonProps={{
        label: currentStep === enumSteps.details || isUpdate ? 'Continue' : 'Add',
        className: currentStep === enumSteps.confirmation && isUpdate ? 'negative' : 'primary',
      }}
      cancelButtonProps={{ label: currentStep === enumSteps.details ? 'Cancel' : 'Go back!' }}
      onCancel={() => {
        currentStep === enumSteps.details ? setModal(initModal) : setCurrentStep(enumSteps.details);
      }}
      onConfirm={() => {
        if (currentStep === enumSteps.details) {
          const hasError = checkErrors();
          if (hasError) {
            return;
          }
          setCurrentStep(enumSteps.confirmation);
        } else {
          onDone();
        }
      }}
      disabled={loading}>
      <AddFeesWrapper className="flex-column items-center justify-center w-full row-gap-6">
        {getContentPage()}
      </AddFeesWrapper>
    </CommonPopup>
  );
};

export default AddFees;
