import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const ProductAdded = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data, performed_by } = log;
  const { client_product } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Product added'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">added a new product.</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'Client'}
            contentText={<p className="flex regular-text lighter-text font-12">{client_product?.organization?.name}</p>}
          />
          <LogContentBox
            title={'Integration'}
            contentText={<p className="regular-text lighter-text font-12">{client_product?.integration?.name}</p>}
          />
          <LogContentBox
            title={'Product'}
            contentText={<p className="regular-text lighter-text font-12">{client_product?.product?.id}</p>}
          />
        </>
      )}
    </div>
  );
};

export default ProductAdded;
