import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as ArchivedIcon } from '../../../assets/images/archived.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import { ReactComponent as PublishedIcon } from '../../../assets/images/published.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { getFormVersionDetails } from '../../../store/features/formsSlice';
import { FormVersionDetailsLeftWrapper } from '../../../styles/components/formDetails/form-details.styled';
import Menu from '../../common/menu';
import Status from '../../common/status';

const FormVersionDetailsLeft = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { id, form_id, versionId } = useParams();
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  const { formDetails, formVersionDetails, formVersions } = useSelector(state => state.forms);

  const { name, description, prizedraw, form_type, prizedraw_client } = formDetails || {};
  const { archived_on, created_on, published_on, version_no } = formVersionDetails || {};

  useEffect(() => {
    if (versionId) {
      dispatch(getFormVersionDetails({ formId: form_id || id, versionId: versionId }));
    }
  }, [versionId]);

  const refreshFormVersionDetails = () => {
    dispatch(getFormVersionDetails({ formId: form_id || id, versionId: versionId }));
  };

  const getVersionStatus = version => {
    const { archived_on, created_on, published_on } = version || formVersionDetails || {};
    if (created_on && published_on && archived_on) {
      return 'RETIRED';
    }
    if (created_on && published_on) {
      return 'PUBLISHED';
    }
    if (created_on) {
      return 'DRAFT';
    }
    return '';
  };

  const getPublishedVersion = () => {
    return formVersions?.content?.find(v => getVersionStatus(v) === 'PUBLISHED');
  };

  const onDuplicateFormVersion = () => {
    setModal({
      type: 'form-version-action',
      content: {
        form: formDetails,
        formVersion: formVersionDetails,
        isDuplicate: true,
        title: t('VERSION_POPUP_TITLE', { type: 'duplicate' }),
        top: true,
        onSuccess: () => refreshFormVersionDetails(),
      },
    });
  };

  const onRetireFormVersion = () => {
    setModal({
      type: 'form-version-action',
      content: {
        form: formDetails,
        formVersion: formVersionDetails,
        isRetire: true,
        title: t('VERSION_POPUP_TITLE', { type: 'retire' }),
        top: true,
        onSuccess: () => refreshFormVersionDetails(),
      },
    });
  };

  const onPublishVersion = () => {
    setModal({
      type: 'publish-form-version',
      content: {
        form: formDetails,
        formVersionToPublish: formVersionDetails,
        affectedFormVersion: getPublishedVersion(),
        title: t('PUBLISH_VERSION_POPUP_TITLE', { type: 'publish' }),
        onSuccess: () => refreshFormVersionDetails(),
      },
    });
  };

  const onRePublishVersion = () => {
    setModal({
      type: 'publish-form-version',
      content: {
        form: formDetails,
        formVersionToPublish: formVersionDetails,
        affectedFormVersion: getPublishedVersion(),
        isRePublish: true,
        title: t('PUBLISH_VERSION_POPUP_TITLE', { type: 'republish' }),
        onSuccess: () => refreshFormVersionDetails(),
      },
    });
  };

  const getVersionMenuItem = () => {
    const versionStatus = getVersionStatus();
    if (versionStatus === 'RETIRED') {
      return [{ name: t('REPUBLISH'), topSeparator: true, onClick: () => onRePublishVersion() }];
    }
    if (versionStatus === 'PUBLISHED') {
      return [{ name: t('RETIRE'), topSeparator: true, onClick: () => onRetireFormVersion() }];
    }
    if (versionStatus === 'DRAFT') {
      return [{ name: t('PUBLISH'), topSeparator: true, onClick: () => onPublishVersion() }];
    }
    return [];
  };

  return (
    <FormVersionDetailsLeftWrapper className="flex-column divider-border">
      <div className="flex items-center divider-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text fw-500">{t('VERSION_DETAILS')}</label>
        <div className="flex items-center justify-start">
          <Menu
            menuList={[
              { name: t('DUPLICATE'), onClick: () => onDuplicateFormVersion() },
              ...getVersionMenuItem(formVersionDetails),
            ]}
          />
        </div>
      </div>
      <div className="flex-column overflow-scroll">
        <div className="pxy-6 flex-column flex-1 divider-bottom">
          <div className="flex justify-between">
            <label className="medium-text lighter-text font-16 flex-1">
              Version <span className="semibold-text">V{version_no}</span>
            </label>
            <Status statusWrapperClassName="mr-6" withDot status={getVersionStatus()} />
          </div>
          <div className="flex items-center justify-between mt-4 radius-2 version-date-container">
            <div className="flex items-center">
              <CalendarIcon className="mr-2" />
              <span className="regular-text font-12">{t('CREATED')}</span>
            </div>
            <div>
              <label className={`regular-text font-12 ${!created_on && 'grey-text'}`}>
                {created_on ? moment.unix(created_on).format('MMM DD, YYYY') : 'no data'}
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between mt-2 radius-2 version-date-container">
            <div className="flex items-center">
              <PublishedIcon className="mr-2" />
              <span className="regular-text font-12">{t('PUBLISHED')}</span>
            </div>
            <div>
              <label className={`regular-text font-12 ${!published_on && 'grey-text'}`}>
                {published_on ? moment.unix(published_on).format('MMM DD, YYYY') : 'no data'}
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between mt-2 radius-2 version-date-container">
            <div className="flex items-center">
              <ArchivedIcon className="mr-2" />
              <span className="regular-text font-12">{t('RETIRED')}</span>
            </div>
            <div>
              <label className={`regular-text font-12 ${!archived_on && 'grey-text'}`}>
                {archived_on ? moment.unix(archived_on).format('MMM DD, YYYY') : 'no data'}
              </label>
            </div>
          </div>
        </div>
        <div className="py-6 pl-6 pr-4 flex-column flex-1">
          <div className="flex items-center">
            <label className="flex-1 font-16 medium-text fw-500">{t('FORM_DETAILS')}</label>
          </div>
          <div className="flex items-center mt-6">
            <div className="flex-column flex-1">
              <span className="regular-text grey-text font-12 mb-1">{t('FORM_NAME')}</span>
              <div className="flex items-center justify-start w-full mr-5">
                <label className="regular-text w-full">{name}</label>
              </div>
            </div>
          </div>
          <div className="flex-column mt-6">
            <span className="regular-text grey-text font-12 mb-1">{t('DESCRIPTION')}</span>
            <div className="flex items-center justify-start wrap mr-5">
              <p className=" regular-text w-full break-word">{description}</p>
            </div>
          </div>
          <div className="flex mt-6">
            <div className="flex-1 flex-column">
              <span className="regular-text grey-text font-12 mb-1">{t('PRIZE_DRAW')}</span>
              <div className="flex items-center justify-start wrap">
                <p className=" regular-text w-full break-work">{prizedraw?.name}</p>
              </div>
            </div>
            <div className="flex-1 flex-column">
              <span className="regular-text grey-text font-12 mb-1">{t('LABEL')}</span>
              {form_type?.name && (
                <div className="flex items-center justify-start wrap">
                  <Status
                    dotColor={form_type?.is_custom ? theme.colors.ZenBlue : theme.colors.ZenOrange}
                    withDot
                    withDottedBorder
                    status={'FORM_TYPE_LABEL'}
                    statusText={form_type?.name}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex mt-6">
            <div className="flex-1 flex-column">
              <span className="regular-text grey-text font-12 mb-1">{t('CLIENT')}</span>
              <div className="flex items-center w-full">
                <p className=" regular-text mr-2">{prizedraw_client?.organization?.name}</p>
                {prizedraw_client?.organization?.code && (
                  <div className="flex items-start h-full">
                    <Status
                      withDottedBorder
                      status={'CLIENT_CODE'}
                      statusText={prizedraw_client?.organization?.code}
                      statusTextClassName="font-10"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex mt-6">
            <div className="flex-1 flex-column">
              <span className="regular-text grey-text font-12 mb-1">{t('CLIENT_DRAW_CODE')}</span>
              <div className="flex items-center justify-between w-full ">
                <p className=" regular-text w-full ">{prizedraw_client?.prizedraw_client_code}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormVersionDetailsLeftWrapper>
  );
};

export default FormVersionDetailsLeft;
