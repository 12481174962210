import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as FilterIcon } from '../../../../../assets/images/filter.svg';
import { multipleFilterTypeEnum } from '../../../../../constant/filter-option-data';
import { getEventGroups, getEventLogs } from '../../../../../store/features/prizeDrawSlice';
import { PrizeDrawEntrantLogsWrapper } from '../../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Loader from '../../../../common/loader';
import NoDataComponent from '../../../../common/no-data-component';
import NoResultComponent from '../../../../common/no-result-component';
import MultipleFilter from '../../../../multiple-filter';
import PrizeDrawDetailEntrantHeader from '../prize-draw-entrant-header';
import ActivityLogGroup from './activity-log-group';

const PrizeDrawEntrantLogs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { entrant_id } = useParams();
  const { eventLogs } = useSelector(state => state.prizeDraw);
  const pageRef = useRef(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([{ id: uuid() }]);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState({});

  const createEntrantFilterUrl = filters => {
    let url = `?entity.ENTRANT=${entrant_id}&page=${pageRef.current}`;
    filters?.map(item => {
      url =
        item?.param && item?.id
          ? url.concat(
              `&${item.param}=${item.id}${
                item?.value?.param && item?.value?.id ? `&${item?.value?.param}=${item?.value?.id}` : ''
              }`,
            )
          : url;
    });
    return url;
  };

  const getActivityLog = (page, merge) => {
    pageRef.current = page;
    const url = createEntrantFilterUrl(filters);
    if (!merge) {
      setLoading(true);
    }
    dispatch(getEventLogs({ url: url, merge }))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const getEventGroupData = () => {
    dispatch(getEventGroups({ params: { group_type: 'ENTRANT' } })).catch(() => {});
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenFilter(false);
    }
  };

  useEffect(() => {
    getActivityLog(0, false);
  }, [filters]);

  useEffect(() => {
    getEventGroupData();
  }, []);

  useEffect(() => {
    const groupByDay =
      (eventLogs?.content || [])?.reduce((acc, curr) => {
        const ts = moment.unix(curr?.performed_on).hour(0).minute(0).second(0).millisecond(0).unix();
        if (!acc[ts]) {
          acc[ts] = [];
        }
        acc[ts].push(curr);
        return acc;
      }, {}) || {};
    setLogs(groupByDay);
  }, [eventLogs]);

  const fetchMoreData = () => {
    getActivityLog(pageRef.current + 1, true);
  };

  return (
    <PrizeDrawEntrantLogsWrapper className="flex-column w-full">
      <PrizeDrawDetailEntrantHeader
        title={t('ACTIVITY_LOGS')}
        actionContent={
          <div className="flex items-center justify-end flex-1">
            <div className="relative filter-card-open" onBlur={onBlur} tabIndex={0}>
              <div
                className="filter pxy-2 mr-6 flex items-center justify-center cursor"
                onClick={() => setOpenFilter(!openFilter)}>
                <FilterIcon className={`${(openFilter || filters.some(f => f.value)) && 'open-filter-icon'}`} />
              </div>
              {openFilter && (
                <MultipleFilter
                  className="prize-draw-entrant-filter"
                  filters={filters}
                  setFilters={setFilters}
                  filterFor={multipleFilterTypeEnum.logs}
                  needToCheckFilterVal={false}
                />
              )}
            </div>
          </div>
        }
      />
      <div className="flex-column pxy-6 overflow-scroll flex-1" id="scrollableDiv">
        {loading ? (
          <Loader />
        ) : Object.entries(logs).length ? (
          <InfiniteScroll
            className="flex-column flex-1 row-gap-6"
            dataLength={eventLogs?.content?.length || 0}
            hasMore={!eventLogs?.last}
            loader={<Loader />}
            next={fetchMoreData}
            scrollableTarget="scrollableDiv">
            {Object.entries(logs)
              .reverse()
              .map(([day, dayLogs], index) => (
                <ActivityLogGroup key={day} day={day} dayLogs={dayLogs} />
              ))}
          </InfiniteScroll>
        ) : filters.some(item => item.param) ? (
          <NoResultComponent />
        ) : (
          <NoDataComponent />
        )}
      </div>
    </PrizeDrawEntrantLogsWrapper>
  );
};

export default PrizeDrawEntrantLogs;
