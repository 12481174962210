import styled from 'styled-components';

export const AutomationLogsListWrapper = styled.div`
  overflow: scroll;
  .automatiom-log-header-wrapper {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 300px) minmax(200px, 200px) minmax(150px, 150px) 60px;
    min-height: 40px;
    max-height: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export const AutomationLogItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 300px) minmax(200px, 200px) minmax(150px, 150px) 60px;
  min-height: 40px;
  max-height: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverColor};
  }
`;
