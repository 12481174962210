import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutomationList from '../../components/automation-list';
import Loader from '../../components/common/loader';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getAutomationsList } from '../../store/features/automationsSlice';
import { AutomationsWrapper } from '../../styles/pages/automations.styled';

const Automations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { automationsList } = useSelector(state => state.automations);
  const { setModal } = useContext(OrganisationContext);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(searchText, 500);
  const [loading, setLoading] = useState(false);

  const fetchAutomationList = (page = 0) => {
    if (page === 0) {
      setLoading(true);
    }
    dispatch(getAutomationsList({ params: { search: debouncedSearch, page: page, size: 10 } }))
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAutomationList(0);
  }, [debouncedSearch]);

  useEffect(() => {
    fetchAutomationList(selectedPage);
  }, [selectedPage]);

  const handleAddAutomation = () => {
    setModal({
      type: 'add-automation',
      content: {
        onSuccess: data => navigate(`/platforms/automations/${data.id}/configuration`),
      },
    });
  };
  return (
    <AutomationsWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: t('PLATFORMS'), onClick: () => {} },
          { text: t('AUTOMATIONS'), onClick: () => {} },
        ]}
        showAddNewBtn
        addBtnPermission="AUTOMATIONS_MANAGE"
        showSearch
        showPagination
        searchText={searchText}
        onSearchChange={setSearchText}
        pagination={{
          selectedPage: selectedPage,
          totalPages: automationsList?.total_pages,
          setSelectedPage: setSelectedPage,
        }}
        onAddClick={() => handleAddAutomation()}
      />
      {loading ? (
        <Loader height={64} width={64} />
      ) : (
        <AutomationList debouncedSearch={debouncedSearch} getAutomationData={() => fetchAutomationList(selectedPage)} />
      )}
    </AutomationsWrapper>
  );
};

export default Automations;
