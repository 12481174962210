import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as UserIcon } from '../assets/images/user.svg';
import DropDown from '../components/McDropdown';
import DateSelectorElement from '../components/widget/date-selector-element/date-selector-element';
import { initModal } from '../constant/InitialData';
import { contactedClientOption, entrantCancelContactType } from '../constant/optionData';
import { OrganisationContext } from '../context/organisationContext';
import { createCancelEntrant, getPrizeDrawEntrantCancelReasons } from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { PrizedrawEntrantActionWrapper } from './modal.styled';

const PrizeDrawEntrantAction = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { prizeDrawEntrantCancelReasons } = useSelector(state => state.prizeDraw);
  const dispatch = useDispatch();
  const { entrant, title, subtitle, isCancel, confirmButtonProps, cancelButtonProps, onSuccess } = modal.content;
  const [entrantData, setEntrantData] = useState({ cancellationDate: moment().unix() });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getPrizeDrawEntrantCancelReasons());
  }, []);

  const checkErrors = () => {
    if (!entrantData?.cancelReason?.id || !entrantData?.contactType?.value || !entrantData?.contactedClient?.name) {
      setError({
        cancelReason: !entrantData?.cancelReason?.id,
        contactType: !entrantData?.contactType?.value,
        contactedClient: !entrantData?.contactedClient?.name,
      });
      dispatch(addToast({ error: true, text: 'Please select cancel reason, contact type and contacted client' }));
      return true;
    }
    return false;
  };

  const handleCancelEntrant = () => {
    try {
      setLoading(true);
      const request = {
        cancel_reason: { id: entrantData?.cancelReason?.id },
        requested_cancellation_on: entrantData.cancellationDate,
        cancelled_by: entrantData?.contactType?.value,
        contacted_client: entrantData?.contactedClient?.value,
      };
      dispatch(createCancelEntrant({ request: request, entrant_id: entrant?.id }))
        .then(() => {
          setLoading(false);
          onSuccess && onSuccess();
          setModal(initModal);
        })
        .catch(() => {
          setLoading(false);
          dispatch(addToast({ error: true, text: 'Error while cancelling subscription' }));
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onDone = () => {
    if (loading || checkErrors()) {
      return;
    }
    if (isCancel) {
      handleCancelEntrant();
    }
  };
  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{ ...confirmButtonProps }}
      cancelButtonProps={{ ...cancelButtonProps }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}
      disabled={loading}>
      <PrizedrawEntrantActionWrapper className="flex-column w-full mt-2">
        <div className="flex-column pb-6 border-bottom ">
          {subtitle && <label className="normal-text lighter-text text-center">{subtitle}</label>}
          <div className="flex entrant-details radius-4 pxy-4 col-gap-2 mt-8">
            <UserIcon height={24} width={24} color="#16192C" />
            <div className="flex-column row-gap-1">
              <label className="medium-text"> {entrant?.contact?.name}</label>
              <label className="normal-text grey-text"> {entrant?.urn}</label>
            </div>
          </div>
        </div>
        <div className="flex-column w-full row-gap-6 mt-6">
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">{t('CANCELLATION_REASON')}</label>
            <DropDown
              className={`provider-selector ${error?.cancelReason ? 'error-border' : ''}`}
              options={prizeDrawEntrantCancelReasons || []}
              selected={entrantData.cancelReason || null}
              setSelected={option => setEntrantData({ ...entrantData, cancelReason: option })}
              placeholder={`Select`}
              size="large"
            />
          </div>
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">{t('CONTACT_TYPE')}</label>
            <DropDown
              className={`provider-selector ${error?.contactType ? 'error-border' : ''}`}
              options={entrantCancelContactType || []}
              selected={entrantData?.contactType || null}
              setSelected={option => setEntrantData({ ...entrantData, contactType: option })}
              placeholder={`Select`}
              size="large"
            />
          </div>
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">{t('CONTACTED_CLIENT')}?</label>
            <DropDown
              className={`provider-selector ${error?.contactedClient ? 'error-border' : ''}`}
              options={contactedClientOption || []}
              selected={entrantData?.contactedClient || null}
              setSelected={option => setEntrantData({ ...entrantData, contactedClient: option })}
              placeholder={`Select`}
              size="large"
            />
          </div>
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">{t('CANCELLATION_DATE')}</label>
            <DateSelectorElement
              className={'relative fromdate-container'}
              selectedDate={entrantData.cancellationDate || moment.unix()}
              setSelectedDate={d => setEntrantData({ ...entrantData, cancellationDate: d })}
              format={'MM/DD/YYYY'}
            />
          </div>
        </div>
      </PrizedrawEntrantActionWrapper>
    </CommonPopup>
  );
};

export default PrizeDrawEntrantAction;
