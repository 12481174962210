import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { ReactComponent as PaymentIcon } from '../../../../assets/images/logs/payment-icon.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantAnonymized = ({ log = {} }) => {
  const { event_data } = log;
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  const getitleText = performedBy ? (
    <>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{t('ANONYMIZED_ENTRANT')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{event_data?.entrant?.urn}</span>
    </>
  ) : (
    <>
      <span className="regular-text lighter-text font-12">{t('SYSTEM_ANONYMIZED_ENTRANT')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{event_data?.entrant?.urn}</span>
    </>
  );
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={performedBy ? <BuildingIcon height={16} width={16} /> : <PaymentIcon height={16} width={16} />}
        title={'Anonymized entrant'}
        subtitle={log?.tenant?.id ? log?.tenant?.id : 'Action'}
      />
      <LogTitle text={<span>{getitleText}</span>} showDownIcon={false} />
    </div>
  );
};

export default EntrantAnonymized;
