import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrencySymbol } from '../../../../../helpers/utils';
import { getEntrantFeesList } from '../../../../../store/features/billingSlice';
import Status from '../../../../common/status';
const PlatformFee = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productfeeData, setProductFeeData] = useState(null);
  const { entrant_id } = useParams();

  useEffect(() => {
    dispatch(
      getEntrantFeesList({
        entrantId: entrant_id,
      }),
    )
      .then(response => {
        setProductFeeData(response);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return productfeeData ? (
    <div className="px-6 pt-6 flex flex-column h-full flex-1">
      <label className="medium-text font-16 lighter-text divider-bottom w-full flex pb-2">{t('PLATFORM_FEE')}</label>
      <div className="border radius-4 py-3 px-4 mt-4">
        <div>
          <div className="flex-row gap-2 items-center pb-1">
            <label className="regular-text font-14">
              {getCurrencySymbol('USD')}
              {productfeeData?.amount}
            </label>
            <Status withDot status={productfeeData?.status} />
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <label className="regular-text lighter-text font-12">
            {t('START_ON')}{' '}
            {productfeeData?.start_date ? moment(productfeeData?.start_date * 1000).format('MMM DD, YYYY') : 'no date'}
          </label>
          <label className="dot-3-lighter-text"></label>
          <label className="regular-text lighter-text font-12">
            {t('END_ON')}{' '}
            {productfeeData?.end_date ? moment(productfeeData?.end_date * 1000).format('MMM DD, YYYY') : 'no date'}
          </label>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PlatformFee;
