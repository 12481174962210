import moment from 'moment/moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Loading from '../../../assets/images/loading.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as UpIcon } from '../../../assets/images/sorting.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/time-default.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalize, formatText } from '../../../helpers/utils';
import { getProductDetailsFeesList } from '../../../store/features/billingSlice';
import { BillingFeesDetailsRightWrapper } from '../../../styles/components/billing-fees-details/billing-fees-details.styled';
import Button from '../../Elements/button/button';
import Menu from '../../common/menu';
import NoDataComponent from '../../common/no-data-component';
import Status from '../../common/status';
import { Tooltip } from '../../tooltip/tooltip';

const enumTab = {
  COUNTINUAL_BILLING: 'VARIABLE',
  COHORT_BILLING: 'FIXED',
  ALL: '',
};

const sortedEnum = {
  startDate: 'START_DATE',
  endDate: 'END_DATE',
  fee: 'FEE',
  status: 'STATUS',
};

const BillingFeesDetailsRight = () => {
  const { productDetails } = useSelector(state => state.billing);
  const { t } = useTranslation();
  const { setModal, hasPermission } = useContext(OrganisationContext);
  const [activeTab, setActiveTab] = useState(enumTab.COUNTINUAL_BILLING);
  const dispatch = useDispatch();
  const { productDetailsFeesList } = useSelector(state => state.billing);
  const { id } = useParams();
  const [sortedBy, setSortedBy] = useState('START_DATE');
  const [orderBy, setOrderBy] = useState('DESC');
  const pageRef = useRef(null);

  const fetchProductFeesList = (page, merge) => {
    pageRef.current = page;
    dispatch(
      getProductDetailsFeesList({
        params: { sort_by: sortedBy, order_by: orderBy, billing_type: activeTab, size: 20, page: page },
        productDetailsId: id,
        merge,
      }),
    );
  };

  useEffect(() => {
    fetchProductFeesList(0, false);
  }, [sortedBy, orderBy, activeTab]);

  const handleSorting = fieldName => {
    if (!sortedBy) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    } else if (sortedBy === fieldName) {
      if (!orderBy) {
        setOrderBy('DESC');
      } else if (orderBy === 'DESC') {
        setOrderBy('ASC');
      } else if (orderBy === 'ASC') {
        setOrderBy('');
        setSortedBy('');
      }
    } else if (sortedBy !== fieldName) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    }
  };

  const tabsComponent = (
    <div className="flex w-full tabs-wrapper">
      <div
        className={` flex items-center first-tab-container px-4 cursor z-in-3 ${
          activeTab === enumTab.COUNTINUAL_BILLING ? 'active-tab' : ''
        }`}
        onClick={() => setActiveTab(enumTab.COUNTINUAL_BILLING)}>
        <label className={`regular-text font-12 ${activeTab === enumTab.COUNTINUAL_BILLING ? 'medium-text' : ''}`}>
          {t('CONTINUAL_BILLING')}
        </label>
      </div>
      <div
        className={`flex items-center tab-container px-4 cursor z-in-2 ${
          activeTab === enumTab.COHORT_BILLING ? 'active-tab' : ''
        }`}
        onClick={() => setActiveTab(enumTab.COHORT_BILLING)}>
        <label className={`regular-text font-12 ${activeTab === enumTab.COHORT_BILLING ? 'medium-text' : ''}`}>
          {t('COHORT_BILLINGS')}
        </label>
      </div>
      <div
        className={`flex items-center tab-container px-4 cursor ${activeTab === enumTab.ALL ? 'active-tab' : ''}`}
        onClick={() => setActiveTab(enumTab.ALL)}>
        <label className={`regular-text font-12 ${activeTab === enumTab.ALL ? 'medium-text' : ''}`}>{t('ALL')}</label>
      </div>
    </div>
  );

  const gridHeaderComponent = (
    <div className=" w-full grid-header-container py-2">
      <div className="flex items-center px-4 bdr-right w-full">
        <label className="medium-text font-12 lighter-text">{t('BILLING_TYPE')}</label>
      </div>
      <div
        className="flex items-center px-4 bdr-right w-full cursor"
        onClick={() => handleSorting(sortedEnum.startDate)}>
        <label className="medium-text font-12 flex-1 lighter-text">{t('START')}</label>
        {sortedBy === sortedEnum.startDate && orderBy && (
          <UpIcon
            className={`flex items-center justify-center ml-1 ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
            height={16}
            width={16}
          />
        )}
      </div>
      <div className="flex items-center px-4 bdr-right w-full cursor" onClick={() => handleSorting(sortedEnum.endDate)}>
        <label className="medium-text font-12 flex-1 lighter-text">{t('END')}</label>
        {sortedBy === sortedEnum.endDate && orderBy && (
          <UpIcon
            className={`flex items-center justify-center ml-1 ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
            height={16}
            width={16}
          />
        )}
      </div>
      <div className="flex items-center px-4 bdr-right w-full cursor" onClick={() => handleSorting(sortedEnum.fee)}>
        <label className="medium-text font-12 flex-1 lighter-text">{t('FEE')}</label>
        {sortedBy === sortedEnum.fee && orderBy && (
          <UpIcon
            className={`flex items-center justify-center ml-1 ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
            height={16}
            width={16}
          />
        )}
      </div>
      <div className="flex items-center px-4 bdr-right w-full cursor" onClick={() => handleSorting(sortedEnum.status)}>
        <label className="medium-text font-12 flex-1 lighter-text">{t('STATUS')}</label>
        {sortedBy === sortedEnum.status && orderBy && (
          <UpIcon
            className={`flex items-center justify-center ml-1 ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
            height={16}
            width={16}
          />
        )}
      </div>
    </div>
  );

  const handleAddFee = () => {
    setModal({
      type: 'add-fees',
      content: {
        productDetailsId: id,
        handleSuccess: () => fetchProductFeesList(0, false),
      },
    });
  };

  const handleDelete = item => {
    setModal({
      type: 'remove-billing-fee',
      content: {
        productDetails: productDetails,
        productFee: item,
        title: 'Are you sure you want to delete this fee?',
        subTitle: 'Deleting a pending fee will auto adjust resulting in the removal of the current fee’s end date.',
        handleSuccess: () => fetchProductFeesList(),
      },
    });
  };

  const handleEdit = item => {
    setModal({
      type: 'add-fees',
      content: {
        productDetails: productDetails,
        productFee: item,
        isUpdate: true,
        handleSuccess: () => fetchProductFeesList(),
      },
    });
  };

  const fetchMoreData = () => {
    fetchProductFeesList(pageRef.current + 1, true);
  };
  return (
    <BillingFeesDetailsRightWrapper className="flex-column flex-1 border radius-4 h-full">
      <div className="flex items-center w-full px-6 py-4 border-bottom">
        <label className="medium-text font-20 flex-1">{t('FEE_LOG')}</label>
        <Button
          className={'default-secondary mr-6'}
          label={t('ADD_NEW')}
          borderRadius={'12px'}
          disabled={!hasPermission('FEES_MANAGE')}
          onClick={() => handleAddFee()}
          size={'medium'}
          width={'105px'}
          icon={<PlusIcon height={16} width={16} />}
        />
      </div>
      {tabsComponent}
      {gridHeaderComponent}
      {productDetailsFeesList?.content?.length ? (
        <div className="flex-column flex-1 overflow-scroll w-full" id="scrollableDiv">
          <InfiniteScroll
            className="flex-column flex-1"
            dataLength={productDetailsFeesList?.content?.length}
            hasMore={!productDetailsFeesList?.last}
            height={48}
            loader={
              <div className="item flex items-center justify-center">
                <img alt="loading" height="32px" src={Loading} />
              </div>
            }
            next={fetchMoreData}
            scrollableTarget="scrollableDiv">
            {(productDetailsFeesList?.content || [])?.map(item => (
              <div className=" w-full item-header-container py-2 bdr-bottom">
                <div className="flex items-center items-center px-4 item-bdr-right w-full">
                  <Tooltip
                    content={
                      <div className="flex-column items-start justify-start row-gap-1">
                        <label className="medium-text font-12">{`${capitalize(
                          formatText(item?.billing_type === 'VARIABLE' ? 'CONTINUAL' : 'COHORT'),
                        )} billing`}</label>
                        <p className="regular-text font-12 lighter-text">
                          {item?.billing_type === 'VARIABLE'
                            ? 'Billing fee will apply to all product transactions from the start date.'
                            : 'Billing fee will apply to product transactions from this prize draw entry date range.'}
                        </p>
                      </div>
                    }
                    placement="bottom-start"
                    className="w-full">
                    <div
                      className={`flex items-center px-2 cursor flex-1 ${formatText(
                        item?.billing_type === 'VARIABLE' ? 'CONTINUAL' : 'COHORT',
                      )} billing-type-wrapper`}>
                      <ClockIcon className="flex items-center justify-center mr-1" height={16} width={16} color="" />
                      <label className="medium-text zen-default-text  flex-1 one-line font-12">{`${capitalize(
                        formatText(item?.billing_type === 'VARIABLE' ? 'CONTINUAL' : 'COHORT'),
                      )} billing`}</label>
                    </div>
                  </Tooltip>
                </div>
                <div className="flex items-center px-4 item-bdr-right w-full">
                  <label
                    className={`regular-text font-14 w-full one-line ${
                      !item?.dates?.start || item.status === 'PENDING' ? 'grey-text' : ''
                    }`}>
                    {item?.dates?.start ? moment(item?.dates?.start * 1000).format('MMM DD, YYYY') : 'no data'}
                  </label>
                </div>
                <div className="flex items-center px-4 item-bdr-right w-full">
                  <label
                    className={`regular-text font-14 w-full one-line ${
                      !item?.dates?.end || item.status === 'PENDING' || item.status === 'CURRENT' ? 'grey-text' : ''
                    }`}>
                    {item?.dates?.end ? moment(item?.dates?.end * 1000).format('MMM DD, YYYY') : 'no data'}
                  </label>
                </div>
                <div className="flex items-center px-4 item-bdr-right w-full">
                  <label className="regular-text font-14  w-full one-line">{`$${(item?.fee_amount).toFixed(2)}`}</label>
                </div>
                <div className="flex items-center px-4 w-full">
                  <div className="flex flex-1">
                    <Status withDot status={item?.status} />
                  </div>
                  <Menu
                    menuList={[
                      { name: 'Edit', onClick: () => handleEdit(item), permission: 'FEES_MANAGE' },
                      { name: 'Delete', onClick: () => handleDelete(item), permission: 'FEES_MANAGE' },
                    ]}
                    isDisabled={item.status === 'RETIRED' || !hasPermission('FEES_MANAGE')}
                  />
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <NoDataComponent />
      )}
    </BillingFeesDetailsRightWrapper>
  );
};

export default BillingFeesDetailsRight;
