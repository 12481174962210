import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ReactComponent as Avatar } from '../../../../../assets/images/avatar.svg';
import { OrganisationContext } from '../../../../../context/organisationContext';
import { getUserInitials } from '../../../../../helpers/utils';
import {
  addPrizeDrawEntrantNotes,
  editPrizeDrawEntrantNotes,
  prizeDrawEntrantNotes,
} from '../../../../../store/features/prizeDrawSlice';
import { addToast } from '../../../../../store/features/toastSlice';
import { PrizeDrawEntrantNotesWrapper } from '../../../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Loader from '../../../../common/loader';
import NoDataComponent from '../../../../common/no-data-component';
import UpdateAction from '../../../../common/update-action';
import PrizeDrawDetailEntrantHeader from '../prize-draw-entrant-header';
import NotesGroup from './notes-group';

const PrizedrawEntrantsNotes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { entrant_id } = useParams();
  const { prizeDrawEntrantNotesDetails } = useSelector(state => state.prizeDraw);
  const { hasPermission } = useContext(OrganisationContext);
  const [notes, setNotes] = useState([]);
  const [addNoteData, setAddNoteData] = useState(null);
  const [editNote, setEditNote] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageRef = useRef(null);
  const { userDataProfile, userImage } = useSelector(state => state?.profile || []);

  const getPrizeDrawEntrantNote = async (page, merge) => {
    pageRef.current = page;
    if (!merge) {
      setLoading(true);
    }
    await dispatch(
      prizeDrawEntrantNotes({
        entrant_id,
        params: { page: page },
        merge,
      }),
    );
    setLoading(false);
  };

  useEffect(() => {
    getPrizeDrawEntrantNote(0, false);
  }, []);

  useEffect(() => {
    const groupByDay =
      (prizeDrawEntrantNotesDetails?.content || [])?.reduce((acc, curr) => {
        const ts = moment.unix(curr?.created_on).hour(0).minute(0).second(0).millisecond(0).unix();
        if (!acc[ts]) {
          acc[ts] = [];
        }
        acc[ts].push(curr);
        return acc;
      }, {}) || {};
    setNotes(groupByDay);
  }, [prizeDrawEntrantNotesDetails]);

  const handleAddNotes = () => {
    try {
      if (!addNoteData?.value || addNoteData?.value.trim() === '') {
        dispatch(addToast({ error: true, text: 'Please enter some text into the note before saving' }));
        return;
      }
      setLoading(true);
      dispatch(addPrizeDrawEntrantNotes({ entrant_id, request: { note: addNoteData?.value } }))
        .then(() => {
          setAddNoteData(null);
          getPrizeDrawEntrantNote(0, false);
        })
        .catch(e => setLoading(false));
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUpdateNote = () => {
    try {
      if (editNote.note.trim() === '' || !editNote?.note) {
        dispatch(addToast({ error: true, text: 'Please enter some text into the note before saving' }));
      } else {
        setLoading(true);
        dispatch(
          editPrizeDrawEntrantNotes({ entrant_id, entrant_note_id: editNote?.id, request: { note: editNote?.note } }),
        ).then(() => {
          setLoading(false);
          setEditNote(null);
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchMoreData = () => {
    getPrizeDrawEntrantNote(pageRef.current + 1, true);
  };

  const addNotesComponent = (
    <div className="flex items-start w-full mb-5 col-gap-1">
      {userImage?.url || userDataProfile?.avatar?.url ? (
        <img
          src={userImage?.url ? userImage?.url : userDataProfile?.avatar?.url}
          alt="profile"
          className="profile-icon mr-2 flex items-center justify-center radius-4"
          style={{ width: '32px', height: '32px' }}
        />
      ) : getUserInitials(userDataProfile || {}) ? (
        <div
          className="user-icon flex items-center justify-center empty-img nots-user-image radius-4"
          style={{ width: '32px', height: '32px' }}>
          <span className="font-14 bold-text grey-text">{getUserInitials(userDataProfile || {})}</span>
        </div>
      ) : (
        <Avatar height={32} width={32} />
      )}

      <div className="update-detail-contaner w-full relative">
        <textarea
          onClick={() => setAddNoteData({ ...addNoteData, type: 'ADD' })}
          className={`input w-full cursor px-4 py-2 font-14 ${
            addNoteData?.type ? ' description-input' : 'add-note-input'
          }`}
          value={addNoteData?.value || ''}
          onChange={({ target }) => setAddNoteData({ ...addNoteData, value: target.value })}
          placeholder="Add a note"
          autoComplete="turnoff"
          autoFocus={addNoteData?.type ? true : false}
        />
        {addNoteData?.type && (
          <UpdateAction
            onCancel={() => setAddNoteData({ ...addNoteData, type: '', value: '' })}
            onUpdate={() => handleAddNotes()}
          />
        )}
      </div>
    </div>
  );

  return (
    <PrizeDrawEntrantNotesWrapper className="flex-column w-full">
      <PrizeDrawDetailEntrantHeader title={t('NOTES')} />
      <div className="flex-column pxy-6 overflow-scroll flex-1">
        {hasPermission('ENTRANT_NOTES_MANAGE') && addNotesComponent}
        <div className="flex-column row-gap-6 flex-1 overflow-scroll" id="scrollableDiv">
          {loading ? (
            <Loader />
          ) : Object.entries(notes).length > 0 ? (
            <InfiniteScroll
              className="flex-column flex-1"
              dataLength={prizeDrawEntrantNotesDetails?.content?.length}
              hasMore={!prizeDrawEntrantNotesDetails?.last}
              loader={<Loader />}
              next={fetchMoreData}
              scrollableTarget="scrollableDiv">
              {Object.entries(notes)
                .reverse()
                .map(([day, dayLogs], index) => (
                  <NotesGroup
                    key={day}
                    day={day}
                    dayLogs={dayLogs}
                    setEditNote={data => setEditNote(data)}
                    editNote={editNote}
                    handleUpdateNote={() => handleUpdateNote()}
                  />
                ))}
            </InfiniteScroll>
          ) : (
            <NoDataComponent />
          )}
        </div>
      </div>
    </PrizeDrawEntrantNotesWrapper>
  );
};

export default PrizedrawEntrantsNotes;
