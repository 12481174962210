import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import MaskedInput from 'react-text-mask';
import { ReactComponent as DominoMask } from '../../../../assets/images/domino-mask.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as MailIcon } from '../../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/images/mobile.svg';
import { ReactComponent as UsersIcon } from '../../../../assets/images/users.svg';
import PhoneInput from '../../../../components/common/phone-input';
import { OrganisationContext } from '../../../../context/organisationContext';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../../../../helpers/localstorage';
import { emailRegEx, formatText, getUserInitials, isDataChanged, uppercase } from '../../../../helpers/utils';
import Address from '../../../../popup/add-account/address';
import {
  createContacts,
  createLocation,
  deleteContactDetails,
  getAccountDetails,
  onAccessAccount,
  updateContactDetails,
  updateLocation,
  updateName,
  updatePrimaryUser,
} from '../../../../store/features/groupsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { getUsers } from '../../../../store/features/userManagementSlice';
import { setUser } from '../../../../store/features/userSlice';
import { AccountsDetailLeftWrapper } from '../../../../styles/components/group-details/group-detail.styled';
import Button from '../../../Elements/button/button';
import Menu from '../../../common/menu';
import Status from '../../../common/status';
import UpdateAction from '../../../common/update-action';

const Option = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`option-wrapper flex items-center justify-start w-full px-2 py-1 ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      {getUserInitials(data || {}) && (
        <div className="user-icon flex items-center justify-center mr-2">
          <span className="bold-text grey-text-2">{getUserInitials(data || {})}</span>
        </div>
      )}
      <div className="regular-text fw-400 font-16 option-text one-line">{data?.name}</div>
    </div>
  );
};

const singleValueComponent = props => {
  const {
    selectProps: { value, menuIsOpen },
  } = props;
  return (
    <div className={`flex items-center justify-start flex-1 w-full overflow-hidden ${menuIsOpen && 'display-none'}`}>
      <div className="user-icon flex items-center justify-center mr-2">
        <span className="bold-text grey-text-2">{getUserInitials(value || {})}</span>
      </div>
      <div className="regular-text fw-400 font-16 one-line">{value?.name}</div>
    </div>
  );
};

const AccountDetailsLeft = () => {
  const { users } = useSelector(state => state.usersManagement);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = getItemFromLocalStorage('user');

  const { account_id } = useParams();
  const { accountDetails } = useSelector(state => state.groups);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [selectedUserDropDown, setSelectedUserDropDown] = useState(false);
  const [selectedUser, setSelectedUser] = useState(accountDetails?.account_owners[0]);
  const [updateGroupdrawDetail, setUpdateGroupdrawDetail] = useState({ type: '', value: '' });
  const { hasPermission } = useContext(OrganisationContext);
  const isEmailId = (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')?.id;
  const isMobNumberId = (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')?.id;

  const onCancelUpdate = () => {
    setEmailError(false);
    setPhoneError(false);
    setUpdateGroupdrawDetail({ type: '', value: '' });
  };

  const getAddressByType = type => {
    const addresses = accountDetails?.locations || [];
    const addressesByType = [...addresses].reverse().filter(a => a[type]) || [];
    return addressesByType.find(a => a.is_primary) || addressesByType[0] || addresses[0] || {};
  };

  const accountBillingAddress = getAddressByType('is_billing');
  const accountShippingAddress = getAddressByType('is_postal');

  const onCancelSelectedUser = () => {
    setSelectedUserDropDown(false);
  };

  const onUpdate = async () => {
    setEmailError(false);
    setPhoneError(false);

    const { email, mobile, mobileId, emailId, countryCode } = updateGroupdrawDetail.value;

    if (!email) {
      setEmailError(true);
      dispatch(addToast({ error: true, text: 'Required Field Missing' }));
      return;
    }
    if (!emailRegEx.test(email)) {
      setEmailError(true);
      dispatch(addToast({ error: true, text: 'Please enter a valid email' }));
      return;
    }
    if (mobile && mobile?.length > 0 && mobile?.replaceAll('-', '').replaceAll(' ', '').length !== 10) {
      setPhoneError(true);
      dispatch(addToast({ error: true, text: 'Please enter a valid phone number' }));
      return;
    }
    try {
      if (
        isDataChanged(
          'INPUT',
          updateGroupdrawDetail?.value?.mobile || '',
          (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')?.contact_value || '',
        ) ||
        isDataChanged(
          'INPUT',
          updateGroupdrawDetail?.value?.countryCode || '',
          (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')?.country_code || '',
        )
      ) {
        const mobileUpdatePromise = isMobNumberId
          ? mobile?.toLowerCase().replaceAll('-', '').replaceAll(' ', '')
            ? dispatch(
                updateContactDetails({
                  organizationId: account_id,
                  contactId: mobileId,
                  params: {
                    contact_type: 'MOBILE',
                    contact_value: mobile?.toLowerCase().replaceAll('-', '').replaceAll(' ', ''),
                    is_primary: true,
                    country_code: countryCode,
                  },
                }),
              )
            : dispatch(
                deleteContactDetails({
                  organizationId: account_id,
                  contactId: mobileId,
                }),
              )
          : dispatch(
              createContacts({
                request: {
                  contact_type: 'MOBILE',
                  contact_value: mobile?.toLowerCase().replaceAll('-', '').replaceAll(' ', ''),
                  is_primary: true,
                  country_code: countryCode ? countryCode : '+1',
                },
                organizationId: account_id,
              }),
            );
        await mobileUpdatePromise;
      }
      if (
        isDataChanged(
          'INPUT',
          updateGroupdrawDetail?.value?.email || '',
          (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')?.contact_value || '',
        )
      ) {
        const emailUpdatePromise = isEmailId
          ? dispatch(
              updateContactDetails({
                organizationId: account_id,
                contactId: emailId,
                params: {
                  contact_type: 'EMAIL',
                  contact_value: email,
                  is_primary: true,
                },
              }),
            )
          : dispatch(
              createContacts({
                request: {
                  contact_type: 'EMAIL',
                  contact_value: email,
                  is_primary: true,
                },
                organizationId: account_id,
              }),
            );
        await emailUpdatePromise;
      }
      onCancelUpdate();
      dispatch(getAccountDetails({ organizationId: account_id }));
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateName = async () => {
    setNameError(false);
    setCodeError(false);

    const { name, code } = updateGroupdrawDetail.value;

    if (!name && !code) {
      setNameError(true);
      setCodeError(true);
      dispatch(addToast({ error: true, text: 'Required Field Missing' }));
      return;
    }

    if (!name) {
      setNameError(true);
      dispatch(addToast({ error: true, text: 'Required Field Missing' }));
      return;
    }
    if (!code) {
      setCodeError(true);
      dispatch(addToast({ error: true, text: 'Required Field Missing' }));
      return;
    }
    if (code?.length !== 3) {
      setCodeError(true);
      dispatch(addToast({ error: true, text: 'Please enter valid abbreviation' }));
      return;
    }

    try {
      const nameUpdate = dispatch(
        updateName({
          organizationId: account_id,
          params: {
            name: name,
            code: code,
            unique_tax_reference: '',
            company_registration_number: '',
          },
        }),
      );
      await nameUpdate;

      onCancelUpdate();
      dispatch(getAccountDetails({ organizationId: account_id }));
    } catch (error) {
      console.log(error);
      dispatch(addToast({ error: true, text: 'Account cannot be updated', id: 'account-update-error' }));
    }
  };

  const onUpdateAddress = async () => {
    const { shippingAddress, billingAddress } = updateGroupdrawDetail.value;
    const shippingAddressErrors = checkAddressInfoErrors(shippingAddress);
    const billingAddressErrors = checkAddressInfoErrors(billingAddress);
    if (Object.values(shippingAddressErrors).some(err => err) || Object.values(billingAddressErrors).some(err => err)) {
      setUpdateGroupdrawDetail(prevState => ({
        ...prevState,
        errors: { shippingAddress: shippingAddressErrors, billingAddress: billingAddressErrors },
      }));
      dispatch(addToast({ error: true, text: 'Please fill address line 1, state, city and zip code' }));
      return;
    }

    try {
      const { shippingAddress, billingAddress } = updateGroupdrawDetail?.value || {};
      const {
        line1: shipLine1,
        line2: shipLine2,
        city: shipCity,
        state: shipState,
        postcode: shipPostCode,
        id: shipId,
        is_postal,
        is_billing: shipBilling,
      } = shippingAddress;
      const {
        line1: billLine1,
        line2: billLine2,
        city: billCity,
        state: billState,
        postcode: billPostCode,
        id: billId,
        is_billing,
        is_postal: billingPostal,
      } = billingAddress;
      const shippingFormattedAddress = [shipLine1, shipLine2, shipCity, shipState, shipPostCode]
        .filter(Boolean)
        .join(', ');
      const billingFormattedAddress = [billLine1, billLine2, billCity, billState, billPostCode]
        .filter(Boolean)
        .join(', ');

      if (isDataChanged('ADDRESS', shippingAddress || {}, accountShippingAddress || {})) {
        const shippingAddressData =
          shipId && is_postal && !shipBilling
            ? dispatch(
                updateLocation({
                  organizationId: account_id,
                  locationId: shippingAddress.id,
                  request: {
                    ...shippingAddress,
                    formatted_address: shippingFormattedAddress,
                  },
                }),
              )
            : dispatch(
                createLocation({
                  organizationId: account_id,
                  request: {
                    ...shippingAddress,
                    formatted_address: shippingFormattedAddress,
                    is_postal: true,
                    is_primary: true,
                    is_billing: false,
                  },
                }),
              );

        await shippingAddressData;
      }
      if (isDataChanged('ADDRESS', billingAddress || {}, accountBillingAddress || {})) {
        const billingAddressData =
          billId && is_billing && !billingPostal
            ? dispatch(
                updateLocation({
                  organizationId: account_id,
                  locationId: billingAddress.id,
                  request: {
                    ...billingAddress,
                    formatted_address: billingFormattedAddress,
                  },
                }),
              )
            : dispatch(
                createLocation({
                  organizationId: account_id,
                  request: {
                    ...billingAddress,
                    formatted_address: billingFormattedAddress,
                    is_billing: true,
                    is_primary: true,
                    is_postal: false,
                  },
                }),
              );

        await billingAddressData;
      }
      onCancelUpdate();
      dispatch(getAccountDetails({ organizationId: account_id }));
    } catch (error) {
      dispatch(addToast({ error: true, text: 'Addresses cannot be updated' }));
    }
  };

  const selectedUserUpdate = () => {
    dispatch(
      updatePrimaryUser({
        organizationId: account_id,
        request: [
          {
            user: {
              id: selectedUser?.id,
            },
          },
        ],
      }),
    )
      .then(response => {
        dispatch(addToast({ error: false, text: 'User updated successfully' }));
        onCancelSelectedUser();
      })
      .catch(err => {
        dispatch(addToast({ error: true, text: 'Something went wrong' }));
        onCancelSelectedUser();
      });
  };

  const onAccessAccountClick = () => {
    dispatch(onAccessAccount({ organizationId: account_id }))
      .then(data => {
        const api_tokens = user.api_tokens;
        const organizations = user.organizations;
        const updatedUser = {
          ...user,
          api_tokens: { ...data.api_tokens },
          accountDetails,
          isAccessAccount: true,
          original_api_tokens: { ...api_tokens },
          original_organizations: organizations,
          organizations: data.organizations,
          masquarage_organizations: [{ ...accountDetails }, ...accountDetails.parent_organizations],
          selectedAccount: { ...accountDetails },
        };
        setItemInLocalStorage('user', updatedUser);
        dispatch(setUser(updatedUser));
        navigate('/dashboard');
        dispatch(addToast({ error: false, text: 'Account has been accessed' }));
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Account cannot be accessed' }));
      });
  };

  useEffect(() => {
    dispatch(getAccountDetails({ organizationId: account_id }));
  }, []);

  const addressField = (address, type) => {
    const addressType = type === 'is_postal' ? 'Shipping' : 'Billing';

    return (
      <span>
        <span className="regular-text font-14">{address?.formatted_address}</span>
        <span className={`ml-2 px-2 radius-4 font-10 medium-text ${formatText(addressType)}`}>{addressType}</span>
      </span>
    );
  };

  const checkAddressInfoErrors = address => {
    if (!address?.line1?.trim() || !address?.state?.trim() || !address?.city?.trim() || !address?.postcode?.trim()) {
      return {
        line1: !address?.line1?.trim(),
        state: !address?.state?.trim(),
        city: !address?.city?.trim(),
        postcode: !address?.postcode?.trim(),
      };
    } else {
      return {};
    }
  };

  const handleAddressChange = (field, value, type) => {
    const groupDetailsValues = updateGroupdrawDetail.value;
    const groupDetailsErrors = updateGroupdrawDetail.errors;
    let updatedData = groupDetailsValues;
    let updatedError = groupDetailsErrors;

    if (field === 'address') {
      updatedData = { ...groupDetailsValues, [type]: { ...groupDetailsValues?.[type], ...value } };
      updatedError = { ...groupDetailsErrors, [type]: {} };
    } else if (field === 'postcode') {
      if (!isNaN(Number(value))) {
        updatedData = { ...groupDetailsValues, [type]: { ...groupDetailsValues?.[type], [field]: value } };
        updatedError = { ...groupDetailsErrors, [type]: { ...groupDetailsErrors?.[type], [field]: false } };
      }
    } else {
      updatedData = { ...groupDetailsValues, [type]: { ...groupDetailsValues?.[type], [field]: value } };
      updatedError = { ...groupDetailsErrors, [type]: { ...groupDetailsErrors?.[type], [field]: false } };
    }
    setUpdateGroupdrawDetail(prevState => ({
      ...prevState,
      value: updatedData,
      errors: updatedError,
    }));
  };

  const updateUser = () => {
    setSelectedUserDropDown(true);
  };

  useEffect(() => {
    if (selectedUserDropDown) {
      dispatch(getUsers({ params: { organization_id: account_id } }));
    }
  }, [selectedUserDropDown]);

  const fetchUser = e => {
    dispatch(getUsers({ params: { organization_id: account_id, search: e } }));
  };

  const addressFields =
    updateGroupdrawDetail.type === 'address' ? (
      <div className="relative">
        <label className="flex semibold-text font-14 mb-2">Shipping</label>
        <Address
          address={{ ...updateGroupdrawDetail?.value?.shippingAddress }}
          handleChange={(key, value) => handleAddressChange(key, value, 'shippingAddress')}
          customLabels={{ line1: 'ADDRESS', line2: 'ADDRESS_2' }}
          wrapperClassName="row-gap-4 address-fields"
          error={{ ...updateGroupdrawDetail?.errors?.shippingAddress }}
          labelClassName="grey-text"
        />
        <label className="flex semibold-text font-14 mb-2 mt-6">Billing</label>
        <Address
          address={{ ...updateGroupdrawDetail?.value?.billingAddress }}
          handleChange={(key, value) => handleAddressChange(key, value, 'billingAddress')}
          customLabels={{ line1: 'ADDRESS', line2: 'ADDRESS_2' }}
          wrapperClassName="address-fields"
          error={{ ...updateGroupdrawDetail?.errors?.billingAddress }}
          labelClassName="grey-text"
        />
        <div className="update-action-container absolute bottom-right">
          <UpdateAction
            onCancel={onCancelUpdate}
            onUpdate={() => {
              if (
                isDataChanged(
                  'ADDRESS',
                  updateGroupdrawDetail?.value?.billingAddress || {},
                  accountBillingAddress || {},
                ) ||
                isDataChanged(
                  'ADDRESS',
                  updateGroupdrawDetail?.value?.shippingAddress || {},
                  accountShippingAddress || {},
                )
              ) {
                onUpdateAddress();
              } else {
                onCancelUpdate();
              }
            }}
          />
        </div>
      </div>
    ) : (
      <div>
        <label className="normal-text font-12 grey-text">{t('ADDRESS')}</label>
        <div
          className="flex-row content-hover-edit cursor py-1 flex justify-between"
          onClick={() =>
            setUpdateGroupdrawDetail({
              type: 'address',
              value: {
                billingAddress: { ...accountBillingAddress },
                shippingAddress: { ...accountShippingAddress },
              },
            })
          }>
          <div className="mr-10 flex-1">
            <div className="flex-column row-gap-4">
              {addressField(accountShippingAddress, 'is_postal')}
              {addressField(accountBillingAddress, 'is_billing')}
            </div>
          </div>
          <EditIcon width={16} height={16} className={`edit-icon mr-2 mt-1`} />
        </div>
      </div>
    );

  const contactField =
    updateGroupdrawDetail.type === 'contact' ? (
      <div className="relative">
        <div className="flex-column gap-4 relative">
          <div className="flex-column gap-1">
            <label className="normal-text font-12 grey-text">{t('EMAIL')}</label>
            <input
              className={`input w-full ${emailError && 'required-error'}`}
              value={updateGroupdrawDetail?.value?.email}
              placeholder="Email"
              onChange={({ target }) => {
                setUpdateGroupdrawDetail({
                  ...updateGroupdrawDetail,
                  value: {
                    ...updateGroupdrawDetail.value,
                    email: target.value,
                  },
                });
                setEmailError(false);
              }}
            />
          </div>
          <div className="flex-column gap-1">
            <label className="normal-text font-12 grey-text">{t('PHONE_NUMBER')}</label>
            <PhoneInput
              selectedCountry={
                updateGroupdrawDetail?.value?.countryCode ? updateGroupdrawDetail?.value?.countryCode : '+1'
              }
              setSelectedCountry={country =>
                setUpdateGroupdrawDetail({
                  ...updateGroupdrawDetail,
                  value: {
                    ...updateGroupdrawDetail.value,
                    countryCode: country,
                  },
                })
              }
              className={`${phoneError && 'error-phone'}`}
              phone={updateGroupdrawDetail?.value?.mobile}
              setPhone={value => {
                setUpdateGroupdrawDetail({
                  ...updateGroupdrawDetail,
                  value: {
                    ...updateGroupdrawDetail.value,
                    mobile: value,
                  },
                });
                setPhoneError(false);
              }}
            />
          </div>
          <div className="update-action-container absolute bottom-right">
            <UpdateAction
              onCancel={onCancelUpdate}
              onUpdate={() => {
                if (
                  isDataChanged(
                    'INPUT',
                    updateGroupdrawDetail?.value?.email || '',
                    (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')
                      ?.contact_value || '',
                  ) ||
                  isDataChanged(
                    'INPUT',
                    updateGroupdrawDetail?.value?.mobile || '',
                    (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                      ?.contact_value || '',
                  ) ||
                  isDataChanged(
                    'INPUT',
                    updateGroupdrawDetail?.value?.countryCode || '',
                    (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                      ?.country_code || '',
                  )
                ) {
                  onUpdate();
                } else {
                  onCancelUpdate();
                }
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      <div
        className="flex-column row-gap-1"
        onClick={() =>
          setUpdateGroupdrawDetail({
            type: 'contact',
            value: {
              mobile: (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                ?.contact_value,
              mobileId: (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')?.id,
              countryCode: (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                ?.country_code,
              email: (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')
                ?.contact_value,
              emailId: (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')?.id,
            },
          })
        }>
        <label className="normal-text font-12 grey-text">{t('CONTACT')}</label>
        <div className={`flex py-1 justify-between content-hover-edit cursor`}>
          <div className="flex-column row-gap-2">
            <div className="flex col-gap-2 items-center">
              <MailIcon height={16} width={16} />
              <label
                className={`regular-text font-14 ${
                  !(accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')
                    ?.contact_value && 'grey-text'
                }`}>
                {(accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')?.contact_value
                  ? (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'EMAIL')?.contact_value
                  : 'not provided'}
              </label>
            </div>
            <div className="flex col-gap-2 items-center">
              <MobileIcon height={16} width={16} />
              <label
                className={`regular-text font-14 ${
                  !(accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                    ?.contact_value && 'grey-text'
                }`}>
                {(accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')?.contact_value
                  ? `${
                      (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                        ?.country_code
                    }${
                      (accountDetails?.contact_details || [])?.find(data => data.contact_type === 'MOBILE')
                        ?.contact_value
                    }`
                  : 'not provided'}
              </label>
            </div>
          </div>
          <EditIcon width={16} height={16} className={`edit-icon mr-2 mt-1`} />
        </div>
      </div>
    );

  const nameField =
    updateGroupdrawDetail.type === 'name' ? (
      <div className="flex-row items-center justify-center w-full col-gap-4 row-gap-2">
        <div className="flex-column row-gap-1" style={{ width: '65%' }}>
          <label className="regular-text grey-text font-12">{t('NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${nameError && 'required-error'} height`}
            onChange={e => {
              setUpdateGroupdrawDetail({
                ...updateGroupdrawDetail,
                value: {
                  ...updateGroupdrawDetail.value,
                  name: e.target.value,
                },
              }),
                setNameError(false);
            }}
            placeholder="Enter account name"
            value={updateGroupdrawDetail?.value?.name}
          />
        </div>
        <div className="flex-column row-gap-1 relative" style={{ width: '35%' }}>
          <label className="regular-text grey-text font-12">{t('ABBREVIATION')}</label>
          <MaskedInput
            mask={[/[A-Z,a-z]/, /[A-Z,a-z]/, /[A-Z,a-z]/]}
            value={updateGroupdrawDetail?.value?.code || null}
            className={`input w-full ${codeError && 'required-error'} height`}
            placeholder={'ABC'}
            onChange={e => {
              setUpdateGroupdrawDetail({
                ...updateGroupdrawDetail,
                value: {
                  ...updateGroupdrawDetail.value,
                  code: uppercase(e.target.value),
                },
              }),
                setCodeError(false);
            }}
            guide={false}
          />
          <div className="update-action-container absolute bottom-right">
            <UpdateAction
              onCancel={onCancelUpdate}
              onUpdate={() => {
                if (
                  isDataChanged('INPUT', updateGroupdrawDetail?.value?.name || '', accountDetails?.name || '') ||
                  isDataChanged('INPUT', updateGroupdrawDetail?.value?.code || '', accountDetails?.code || '')
                ) {
                  onUpdateName();
                } else {
                  onCancelUpdate();
                }
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      <div>
        <label className="normal-text font-12 grey-text">{t('NAME')}</label>
        <div
          className="flex items-center w-full col-gap-2 justify-between content-hover-edit cursor py-1"
          onClick={() =>
            setUpdateGroupdrawDetail({
              type: 'name',
              value: {
                name: accountDetails?.name,
                code: accountDetails?.code,
              },
            })
          }>
          <div className="flex col-gap-2 items-center">
            <label className="regular-text semibold-text font-16">{accountDetails?.name}</label>
            <Status
              status="CLIENT_CODE"
              withDottedBorder={true}
              statusText={accountDetails?.code}
              statusTextClassName={'font-10'}
              statusWrapperClassName={'account-status'}
            />
          </div>
          <EditIcon width={16} height={16} className={`edit-icon mr-2`} />
        </div>
      </div>
    );

  return (
    <AccountsDetailLeftWrapper className={`flex-column ${!selectedUserDropDown && 'overflow-hidden'}`}>
      <div className={`flex-column radius-4 border flex-1  ${!selectedUserDropDown && 'overflow-auto'}`}>
        <div className="flex items-center px-6 py-4">
          <label className="flex-1 font-20 medium-text fw-500">{t('ACCOUNT_DETAILS')}</label>
          <div className="flex items-center justify-start">
            <div className="flex items-center justify-start">
              <UsersIcon />
              <label className="medium-text font-14 ml-1 mr-6">{accountDetails?.user_count || 0}</label>
            </div>
          </div>
        </div>
        <div className={`flex-column flex-1 ${!selectedUserDropDown && 'overflow-scroll'} border-top`}>
          <div className="flex-column pxy-6 row-gap-6">
            <div className="flex-column row-gap-1">{nameField}</div>
            <div className="flex-column row-gap-1">{addressFields}</div>
            {contactField}
          </div>
          {!selectedUserDropDown ? (
            accountDetails?.account_owners?.length > 0 && (
              <div className="flex-column px-6 pb-6 pt-2 row-gap-6 border-top">
                <div className="flex-column row-gap-1">
                  <div className="flex justify-between">
                    <label className="normal-text font-12 grey-text items-center flex">{t('PRIMARY_USER')}</label>
                    <Menu
                      menuList={[
                        {
                          name: t('DETAILS'),
                          onClick: () => navigate(`account-details/${organization?.id}/prize-draw-access`),
                          permission: 'ORGANIZATIONS_VIEW',
                        },
                        hasPermission('ORGANIZATIONS_MANAGE') && {
                          name: t('UPDATE_USER'),
                          onClick: () => updateUser(),
                        },
                      ].filter(Boolean)}
                    />
                  </div>
                  {(accountDetails?.account_owners || []).map(user => (
                    <div className="flex-column primary-details-container pxy-4 radius-3 row-gap-4" key={user?.id}>
                      <div className="flex-column row-gap-2">
                        <label className="regular-text semibold-text font-16">{user?.name}</label>
                        <Status status={'USERROLE'} statusText={user?.role?.name} />
                      </div>
                      <div className="flex-column row-gap-2">
                        <div className="flex items-center col-gap-2">
                          <MailIcon height={16} width={16} />
                          <label className="regular-text">{user?.email}</label>
                        </div>
                        <div className="flex items-center col-gap-2">
                          <MobileIcon height={16} width={16} />
                          <label className="regular-text">{user?.phone}</label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          ) : (
            <div className="flex-column px-6 pb-6 pt-2 border-top relative">
              <label className="normal-text font-12 grey-text items-center flex">{t('PRIMARY_USER')}</label>
              <div className="w-full mt-2">
                <Select
                  options={users?.content?.map(user => ({ ...user, value: user.id, label: user.name })) || []}
                  placeholder={'Select User'}
                  onChange={option => setSelectedUser(option)}
                  isSearchable
                  onInputChange={e => fetchUser(e)}
                  value={selectedUser}
                  menuPlacement={'top'}
                  maxMenuHeight={150}
                  components={{
                    Option,
                    IndicatorSeparator: props => {
                      <components.IndicatorSeparator {...props} className="display-none" />;
                    },
                    Control: props => (
                      <components.Control
                        {...props}
                        className={`user-dropdown flex items-center justify-start radius-4 border cursor"`}
                      />
                    ),
                    Placeholder: props => <components.Placeholder {...props} className="regular-text grey-text" />,
                    SingleValue: props => singleValueComponent(props),
                    Input: props => (
                      <components.Input
                        {...props}
                        autoFocus={true}
                        className={`${!props.selectProps.menuIsOpen && props.hasValue && 'display-none'}`}
                      />
                    ),
                    Menu: props => <components.Menu {...props} className="menu-wrapper absolute radius-4 pxy-2" />,
                  }}
                />
                <div className="update-action-container-user">
                  <UpdateAction onCancel={onCancelSelectedUser} onUpdate={selectedUserUpdate} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Button
        className={'w-full default-secondary mt-4 mb-1 access-account-btn'}
        label="Access account"
        size="medium"
        width="100%"
        borderRadius="12px"
        icon={<DominoMask />}
        onClick={() => onAccessAccountClick()}
      />
    </AccountsDetailLeftWrapper>
  );
};

export default AccountDetailsLeft;
