import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaymentIcon } from '../../../../assets/images/logs/payment-icon.svg';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const UserAnonymized = ({ log = {} }) => {
  const {
    event_data: { user },
  } = log;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<PaymentIcon height={16} width={16} />}
        title={'User anonymized'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{user?.id}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('WAS_ANONYMIZED')}</span>
          </span>
        }
        showDownIcon={false}
      />
    </div>
  );
};

export default UserAnonymized;
