import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/mobile.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText } from '../../helpers/utils';
import { UsersListItemWrapper } from '../../styles/components/users-list/users-list.styled';
import Button from '../Elements/button/button';
import Menu from '../common/menu';
import Switch from '../common/switch';

const UserListItem = ({ user, updateUsersList }) => {
  const { hasPermission, checkPermission, setModal } = useContext(OrganisationContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToUserProfile = item => {
    navigate(`/settings/security/users/${item.id}`);
  };

  const onChangeStatus = async () => {
    const { is_active } = user;
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isChangeStatus: true,
        isActive: is_active,
        title: `Are you sure you want to ${is_active ? 'deactivate' : 'activate'} this user?`,
      },
    });
  };

  const onResendClick = async () => {
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isReinvite: true,
        title: 'Are you sure you want to re-invite this user?',
        onSuccess: updateUsersList,
      },
    });
  };

  const getExtraMenuOption = () => {
    if (user.status === 'EXPIRED') {
      return (
        <>
          <Button
            className={`primary-white w-full ${!hasPermission('USERS_MANAGE') && 'disabled'}`}
            fontSize="14px"
            label={'Resend invite'}
            borderRadius={'12px'}
            size={'medium'}
            onClick={() => checkPermission(() => onResendClick(), 'USERS_MANAGE')}
          />
        </>
      );
    } else {
      return (
        <>
          <span className={`regular-text flex items-center last-width enable-color font-14`}>{t('ACTIVE')}</span>
          <Switch
            onClick={() => checkPermission(() => onChangeStatus(), 'USERS_MANAGE')}
            enabled={user?.is_active}
            disabled={false}
          />
        </>
      );
    }
  };

  const onDeleteUser = async () => {
    const { status } = user;
    const title =
      status === 'INVITED'
        ? 'Are you sure you want to cancel this invitation?'
        : 'Are you sure you want to delete this user?';
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isDelete: status !== 'INVITED',
        isCancelInvitation: status === 'INVITED',
        title,
      },
    });
  };

  return (
    <UsersListItemWrapper className="w-full cursor" onClick={() => goToUserProfile(user)}>
      <div className="bdr-right flex-column items-start px-4 overflow-hidden justify-center w-full">
        <label className="regular-text font-16 one-line">{user?.name}</label>
        <label className="regular-text one-line lighter-text font-12">{user?.role?.name}</label>
      </div>
      <div className="bdr-right flex-column items-start px-4 overflow-hidden justify-center w-full">
        <div className="flex items-center justify-start w-full">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MailIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label className={`regular-text one-line font-14 ${!user.email ? 'grey-text' : ''}`}>
            {user?.email ? user?.email : 'Not provided'}
          </label>
        </div>
        <div className="flex items-center justify-start w-full mt-1">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MobileIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label className={`regular-text one-line font-14 ${!user.phone ? 'grey-text' : ''}`}>
            {user?.phone ? user?.phone : 'Not provided'}
          </label>
        </div>
      </div>
      <div
        className={`bdr-right flex items-center px-4 overflow-hidden justify-start w-full wrap ${
          user?.teams?.length > 0 && 'items-start'
        }`}>
        {user?.teams?.length > 0 ? (
          user?.teams?.map(data => (
            <div className="flex items-center justify-center w-auto teams-wrapper radius-2 mr-2 mb-2" key={data?.id}>
              <label className="medium-text font-12">{data?.name}</label>
            </div>
          ))
        ) : (
          <label className="regular-text one-line font-12 grey-text">User is not in any teams</label>
        )}
      </div>
      <div className="flex items-center justify-between w-full px-4">
        <div className={`flex items-center status-container py-1 px-2 ${formatText(user?.status)}`}>
          <div className="dot mr-1" />
          <span className="medium-text font-12">{capitalize(formatText(user?.status))}</span>
        </div>
        <Menu
          menuList={[
            {
              name: 'Details',
              onClick: () => goToUserProfile(user),
              permission: 'USERS_MANAGE',
            },
            hasPermission('USERS_MANAGE') && {
              name: user.status === 'INVITED' ? 'Cancel invitation' : 'Delete',
              onClick: () => onDeleteUser(),
              permission: 'USERS_MANAGE',
            },
          ]}
          ExtraOptions={
            <div className="flex items-center justify-between px-2 pt-4 pb-2 mt-2 w-full border-top">
              {getExtraMenuOption()}
            </div>
          }
        />
      </div>
    </UsersListItemWrapper>
  );
};

export default UserListItem;
