import ReactECharts from 'echarts-for-react';
import moment from 'moment/moment';
import React from 'react';
import { useTheme } from 'styled-components';

const HeatMapV2 = ({ data, paymentSuccessToggle }) => {
  const theme = useTheme();

  const customTooltipFormatter = params => {
    const value = params.value;
    const month = value[3];
    const count = value[2];
    const percentage = value[4];

    const backgroundColor = paymentSuccessToggle ? theme.colors.ZenBlueSecondary : theme.colors.ZenNegativeSecondary2;
    const color = paymentSuccessToggle ? theme.colors.ZenInfo : theme.colors.ZenNegative;

    return `
        <div class="radius-3" style="min-width:120px; text-align:center;">
          <label class="flex flex-1 items-center justify-center medium-text px-2 w-full" style="background:${backgroundColor}; color:${color}; border-radius: 12px 12px 0 0;">
          ${month}
          </label>
          <div class="pxy-2">
            <div class="flex justify-between items-center">
              <label class="regular-text font-10">Count</label>
              <label class="semibold-text font-12">${count}</label>
            </div>
            <div class="flex justify-between items-center">
              <label class="regular-text font-10">Perc.</label>
              <label class="semibold-text font-12">${percentage}%</label>
            </div>
          </div>
        </div>
    `;
  };

  const months = data?.map(d =>
    moment()
      .set('month', d.month - 1)
      .set('year', d.year)
      .format('MMM YY'),
  );

  const keys = [...data]
    ?.map(d => d.payments)
    .flat()
    .reduce((acc, curr) => {
      if (!acc.includes(curr.key)) {
        acc.push(curr.key);
      }
      return acc;
    }, []);

  const newData = data
    ?.reduce((acc, curr, currIndex) => {
      const payments = curr.payments;
      const monthFormat = moment()
        .set('month', curr.month - 1)
        .set('year', curr.year)
        .format('MMM YY');
      const test = payments.map((p, index) => [
        index,
        currIndex,
        p.count,
        monthFormat,
        parseFloat(p.percentage).toFixed(2),
        parseFloat(p.percentage).toFixed(0),
      ]);
      acc.push(test);
      return acc;
    }, [])
    .flat();

  const getOption = () => {
    return {
      textStyle: {
        fontFamily: 'Poppins Regular',
        fontSize: 14,
      },
      legend: {
        show: false,
      },
      tooltip: {
        position: 'top',
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
        borderWidth: 0,
        padding: 0,
        borderRadius: 12,
        textStyle: {
          color: '#16192C',
          fontSize: 14,
        },
        formatter: customTooltipFormatter,
      },
      grid: {
        left: '6%',
        right: '3%',
      },
      xAxis: {
        type: 'category',
        data: keys,
        position: 'top',
        axisLine: { show: false },
        axisTick: { show: false },
        splitArea: {
          show: true,
        },
        axisLabel: {
          color: theme.colors.RegularText,
          fontSize: 12,
        },
      },
      yAxis: {
        type: 'category',
        data: months,
        position: 'top',
        axisLine: { show: false },
        axisTick: { show: false },
        splitArea: {
          show: true,
        },
        axisLabel: {
          color: theme.colors.RegularText,
          fontSize: 12,
        },
      },
      visualMap: {
        show: false,
        min: 0,
        max: 100,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        inRange: {
          color: paymentSuccessToggle
            ? [
                'rgba(123, 178, 253, 0)',
                'rgba(123, 178, 253, 0.1)',
                'rgba(123, 178, 253, 0.2)',
                'rgba(123, 178, 253, 0.3)',
                'rgba(123, 178, 253, 0.4)',
                'rgba(123, 178, 253, 0.5)',
                'rgba(123, 178, 253, 0.6)',
                'rgba(123, 178, 253, 0.7)',
                'rgba(123, 178, 253, 0.8)',
                'rgba(123, 178, 253, 0.9)',
                'rgba(123, 178, 253, 1)',
              ]
            : [
                'rgba(255, 64, 110, 0)',
                'rgba(255, 64, 110, 0.1)',
                'rgba(255, 64, 110, 0.2)',
                'rgba(255, 64, 110, 0.3)',
                'rgba(255, 64, 110, 0.4)',
                'rgba(255, 64, 110, 0.5)',
                'rgba(255, 64, 110, 0.6)',
                'rgba(255, 64, 110, 0.7)',
                'rgba(255, 64, 110, 0.8)',
                'rgba(255, 64, 110, 0.9)',
                'rgba(255, 64, 110, 1)',
              ],
        },
      },
      dataZoom: [
        {
          type: 'slider',
          show: months.length > 7,
          startValue: months.length,
          endValue: months.length > 7 ? months.length - 5 : 0,
          minSpan: 10,
          realtime: false,
          orient: 'vertical',
          zoomLock: true,
          left: 0,
          brushSelect: false,
          handleSize: '0',
          width: 10,
          showDataShadow: false,
          labelFormatter: () => null,
          backgroundColor: 'rgba(236, 238, 243, 1)',
          borderColor: 'rgba(236, 238, 243, 1)',
          fillerColor: 'rgba(191, 196, 200, 1)',
        },
        {
          type: 'slider',
          startValue: 0,
          show: keys.length > 15,
          endValue: keys.length > 15 ? 15 : keys.length,
          minSpan: 15,
          realtime: false,
          orient: 'horizontal',
          zoomLock: true,
          brushSelect: false,
          handleSize: '0',
          height: 10,
          showDataShadow: false,
          labelFormatter: () => null,
          backgroundColor: 'rgba(236, 238, 243, 1)',
          borderColor: 'rgba(236, 238, 243, 1)',
          fillerColor: 'rgba(191, 196, 200, 1)',
        },
      ],
      series: [
        {
          name: 'Punch Card',
          type: 'heatmap',
          data: newData,
          itemStyle: {
            borderWidth: 2,
            borderType: 'solid',
            borderColor: '#ffffff',
            borderRadius: 4,
          },
          label: {
            normal: {
              formatter: params => {
                if (params.value[5] > 68) {
                  return `{a|${params.value[5]}%}`;
                } else {
                  return `{b|${params.value[5]}%}`;
                }
              },
              rich: {
                a: {
                  color: '#16192C',
                },
                b: {
                  color: '#16192C',
                },
              },
              show: true,
              position: 'inside',
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
            },
          },
        },
      ],
    };
  };
  return (
    <div className={data?.length <= 1 ? 'heatmap-chart-dashbord' : ''}>
      <ReactECharts option={getOption()} notMerge={true} lazyUpdate={true} opts={{ renderer: 'svg' }} />
    </div>
  );
};

export default HeatMapV2;
