import React from 'react';
import { IntegrationsDetailWrapper } from '../../styles/components/integrations-detail/integrations-detail.styled';
import IntegrationsDetailLeft from './integration-detail-left';
import IntegrationsDetailRight from './integration-detail-right';

const IntegrationsDetail = () => {
  return (
    <IntegrationsDetailWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6">
      <IntegrationsDetailLeft />
      <IntegrationsDetailRight />
    </IntegrationsDetailWrapper>
  );
};

export default IntegrationsDetail;
