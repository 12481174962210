import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/common/loader';
import GroupsList from '../../components/groups-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getGroups } from '../../store/features/groupsSlice';
import { GroupsWrapper } from '../../styles/pages/groups.styled';
const Groups = () => {
  const [search, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const dispatch = useDispatch();
  const { groups } = useSelector(state => state.groups);
  const { setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const onAddClick = () => {
    setModal({
      type: 'create-group',
      content: { top: true },
    });
  };

  const getGroupList = (page = 0) => {
    setLoading(true);
    dispatch(getGroups({ params: { search: debouncedSearch, page: page, size: 10 } }))
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getGroupList(0);
  }, [debouncedSearch]);

  useEffect(() => {
    getGroupList(selectedPage);
  }, [selectedPage]);
  return (
    <GroupsWrapper className="flex-column w-full flex-1">
      <PageHeader
        title={'Groups'}
        showAddNewBtn
        showSearch
        showPagination
        onAddClick={onAddClick}
        addBtnPermission="ORGANIZATION_CATEGORIES_MANAGE"
        searchText={search}
        onSearchChange={setSearchText}
        pagination={{ selectedPage: selectedPage, totalPages: groups?.total_pages, setSelectedPage: setSelectedPage }}
      />
      {loading ? (
        <Loader height={64} width={64} />
      ) : (
        <GroupsList debouncedSearch={debouncedSearch} getGroupList={() => getGroupList(selectedPage)} />
      )}
    </GroupsWrapper>
  );
};

export default Groups;
