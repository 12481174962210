import styled from 'styled-components';

export const PrizeDrawConfigWrapper = styled.div`
  overflow: hidden;

  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      .edit-icon {
        display: flex;
      }
    }
  }

  .timezone-dropdown {
    min-width: 325px;
  }

  .selector-dropdown {
    .mc-select {
      .organisations-border {
        height: 24px;
        width: 140px;
        border-radius: 8px;
        padding: 8px;
      }
      .mc-options {
        top: 26px;
        z-index: 11;
      }
    }
  }

  .date-time-input {
    .date-selector-element {
      height: 24px;
      border-radius: 8px;
      padding: 0 16px;
      width: 120px;
      z-index: 11;

      .date-selector {
        top: 26px;
        left: 0;
      }
    }
    .time-input {
      height: 24px;
      width: 80px;
      border-radius: 8px;
    }

    .mc-select {
      .organisations-border {
        height: 24px;
        width: 80px;
        border-radius: 8px;
        padding: 8px;
      }
      .mc-options {
        top: 26px;
        z-index: 11;
      }
    }
  }

  .weekly-input {
    .input {
      height: 24px;
      width: 50px;
      border-radius: 8px;
    }
  }

  .mc-options {
    z-index: 11;
  }

  .selected-radio {
    accent-color: ${({ theme }) => theme.colors.zenPurple};
  }
`;
