import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilderContext from '../../context/FormBuilderContext';
import { RightPanelWrapper } from './edit-form-version.styled';
import { ConfirmationPageSettings } from './form-settings/confirmation-page-settings';
import { FontSettings } from './form-settings/font-settings';
import { GeneralSettings } from './form-settings/general-settings';
import { ProgressbarSettings } from './form-settings/progress-bar-settings';
import { WaterMarkSettings } from './form-settings/watermark-settings';
import { WidgetConfigPanel } from './widget-config-panel';

const RightPanel = () => {
  const { selectedWidget } = useContext(FormBuilderContext);
  const [colorPicker, setColorPicker] = useState('');
  const { t } = useTranslation();
  return (
    <RightPanelWrapper className="border-left pxy-4 flex-column">
      {selectedWidget ? (
        <div className="flex-column flex-1 overflow-hidden h-full">
          <WidgetConfigPanel />
        </div>
      ) : (
        <div className="flex-1 overflow-scroll h-full">
          <label className="medium-text font-18 mb-4">{t('FORM_SETTINGS')}</label>
          <GeneralSettings key={'generalSettingsComponent'} setColorPicker={setColorPicker} colorPicker={colorPicker} />
          <FontSettings key={'fontSettingsComponent'} setColorPicker={setColorPicker} colorPicker={colorPicker} />
          <ProgressbarSettings key={'progressBarSettings'} />
          <WaterMarkSettings key={'waterMarkSettings'} />
          <ConfirmationPageSettings key={'confirmationPageSettings'} />
        </div>
      )}
    </RightPanelWrapper>
  );
};

export default RightPanel;
