import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrencySymbol } from '../../../../../helpers/utils';
import { prizeDrawEntrantOverviewPayment } from '../../../../../store/features/prizeDrawSlice';
const Payment = () => {
  const { prizeDrawEntrantOverDetails } = useSelector(state => state.prizeDraw);
  const { payment_count, frequency_count, payment_amount } = prizeDrawEntrantOverDetails || {};
  const { entrant_id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(prizeDrawEntrantOverviewPayment(entrant_id));
  }, []);
  return (
    <div className="px-6 pt-6 flex flex-column ">
      <label className="medium-text font-16 lighter-text divider-bottom w-full flex pb-2">{t('PAYMENTS')}</label>
      <label className="semibold-text flex pt-4 pb-2 font-12">{t('PAYMENT_AMOUNT')}</label>
      <div className="border radius-4 py-4 px-6">
        <div className="flex-row justify-between pb-4">
          <label className="regular-text lighter-text font-14">{t('GROSS_PAYMENT')}</label>
          <label className="regular-text font-14">
            {getCurrencySymbol(payment_amount?.currency)}
            {payment_amount?.gross.toFixed(2)}
          </label>
        </div>
        <div className="flex-row justify-between pb-2 divider-bottom">
          <label className="regular-text lighter-text font-14">{t('GROSS_REFUND')}</label>
          <label className="regular-text font-14 error-text">
            -{getCurrencySymbol(payment_amount?.currency)}
            {payment_amount?.refunds.toFixed(2)}
          </label>
        </div>
        <div className="flex-row justify-between pt-2 ">
          <label className="regular-text lighter-text font-14">{t('NET_PAYMENT')}</label>
          <label className="semibold-text font-14">
            {getCurrencySymbol(payment_amount?.currency)}
            {payment_amount?.net.toFixed(2)}
          </label>
        </div>
      </div>
      <label className="semibold-text flex pt-4 pb-2 font-12">{t('PAYMENT_COUNT')}</label>
      <div className="border radius-4 py-4 px-6">
        <div className="flex-row justify-between pb-4">
          <label className="regular-text lighter-text font-14">{t('GROSS_PAYMENT_COUNT')}</label>
          <label className="regular-text font-14">{payment_count?.gross}</label>
        </div>
        <div className="flex-row justify-between pb-2 divider-bottom">
          <label className="regular-text lighter-text font-14">{t('GROSS_REFUND_COUNT')}</label>
          <label className="regular-text font-14 error-text">-{payment_count?.refunds}</label>
        </div>
        <div className="flex-row justify-between pt-2 ">
          <label className="regular-text lighter-text font-14">{t('NET_PAYMENT_COUNT')}</label>
          <label className="semibold-text font-14">{payment_count?.net}</label>
        </div>
      </div>
      <label className="semibold-text flex pt-4 pb-2 font-12">{t('COUNT_BY_FREQUENCY')}</label>
      <div className="border radius-4 py-4 px-6">
        <div className="flex-row justify-between pb-4">
          <label className="regular-text lighter-text font-14">{t('GROSS_PAYMENT_COUNT')}</label>
          <label className="regular-text font-14">{frequency_count?.gross}</label>
        </div>
        <div className="flex-row justify-between pb-2 divider-bottom">
          <label className="regular-text lighter-text font-14">{t('GROSS_REFUND_COUNT')}</label>
          <label className="regular-text font-14 error-text">-{frequency_count?.refunds}</label>
        </div>
        <div className="flex-row justify-between pt-2 ">
          <label className="regular-text lighter-text font-14">{t('NET_PAYMENT_COUNT')}</label>
          <label className="semibold-text font-14">{frequency_count?.net}</label>
        </div>
      </div>
    </div>
  );
};

export default Payment;
