import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as ExportIcon } from '../../assets/images/download.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as UpIcon } from '../../assets/images/sorting.svg';
import MultipleFilter from '../../components/multiple-filter';
import { Tooltip } from '../../components/tooltip/tooltip';
import { filterChildRenderComponent, multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { paymentDeclineCode } from '../../constant/paymentDeclineCode';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import useDebounce from '../../helpers/useDebounceHook';
import { capitalize, capitalizeFirstLetterOfWords, formatText, getPaymentFrequencyText } from '../../helpers/utils';
import { getPrizeDrawEntrant } from '../../store/features/prizeDrawSlice';
import { PrizeDrawEntrantsWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Button from '../Elements/button/button';
import InputSearch from '../common/input-search';
import Loader from '../common/loader';
import Menu from '../common/menu';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import Status from '../common/status';
import PaymentStatus from '../common/status/payment-status';
import PrizeDrawDetailHeader from './prize-draw-details-header';

const PrizeDrawEntrants = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const navigate = useNavigate();

  const { prizeDrawEntrant, exportColumnList, templateList } = useSelector(state => state.prizeDraw);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([{ id: uuid() }]);
  const [searchText, setSearchText] = useState('');
  const [sortedBy, setSortedBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [entryFormData, setEntryFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { hasPermission, setModal } = useContext(OrganisationContext);
  const org = getItemFromLocalStorage('user').organizations[0];

  const sortedEnum = {
    entrants: 'entrant_name',
    paymentStatus: 'payment_status',
    drawStatus: 'draw_status',
    createdDate: 'created_date',
    paymentFeq: 'payment_frequency',
    winStatus: 'win_status',
    prizeDraw: 'prizedraw',
  };

  const debouncedSearch = useDebounce(searchText, 500);

  const createEntrantFilterUrl = filters => {
    let url = ``;
    filters?.map(item => {
      if (item?.childRenderComponent === filterChildRenderComponent.dateRange) {
        url = item.value
          ? url.concat(
              `&${item.minParam}=${item?.value.fromDate ? item?.value.fromDate : ''}&${item.maxParam}=${
                item?.value.toDate ? item?.value.toDate : ''
              }`,
            )
          : url;
      } else if (item?.childRenderComponent === filterChildRenderComponent.minMax) {
        url = item.value
          ? url.concat(
              `&${item.minParam}=${item?.value.minValue ? item?.value.minValue : ''}&${item.maxParam}=${
                item?.value.maxValue ? item?.value.maxValue : ''
              }`,
            )
          : url;
      } else if (item?.childRenderComponent === filterChildRenderComponent.inputDropDown) {
        url = item.value
          ? url.concat(
              `&${item.countParam}=${item?.value.inputValue ? item?.value.inputValue : ''}&${item.param}=${
                item.value.id ? item.value.id : ''
              }`,
            )
          : url;
      } else if (item?.childRenderComponent === filterChildRenderComponent.infiniteScrollMultiDropdown) {
        if (item.value2) {
          url += `${item.param}=${item.value.id}&${item.subParam ? item.subParam : item.param}=${item.value2.id}`;
        } else if (item.value) {
          url += `${item.param}=${item.value.id}`;
        }
      } else {
        url = item.value
          ? url.concat(
              `&${item.param}=${
                item.param === 'prizedraw_client_code' ? item.value.prizedraw_client_code : item.value.id
              }`,
            )
          : url;
      }
    });
    return url;
  };

  const getPrizeDrawEntrantData = (url, page, merge) => {
    pageRef.current = page;
    // if (!merge) {
    //   setLoading(true);
    // }
    const lastObject = filters[filters.length - 1];
    if (lastObject && lastObject.value) {
      setLoading(true);
    }
    dispatch(
      getPrizeDrawEntrant({
        params: {
          prizedraw_id: id,
          search: debouncedSearch,
          sort_by: sortedBy,
          order_by: orderBy,
          page: page,
          size: 20,
        },
        url: url,
        merge,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    const url = createEntrantFilterUrl(filters);
    getPrizeDrawEntrantData(url, 0, false);
  }, [id, debouncedSearch, sortedBy, orderBy, filters]);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenFilter(false);
    }
  };

  const handleCancelEntrant = entrantDetails => {
    setModal({
      type: 'prizedraw-entrant-action',
      content: {
        entrant: entrantDetails,
        title: 'Are you sure you want to cancel this entrant’s subscription?',
        subtitle: 'This will stop further charges and will change the entrant to canceled status',
        isCancel: true,
        confirmButtonProps: { label: 'Yes, cancel', className: 'negative' },
        cancelButtonProps: { label: 'Go back!', className: 'primary-white' },
        onSuccess: () => {
          const url = createEntrantFilterUrl(filters);
          getPrizeDrawEntrantData(url, 0, false);
        },
      },
    });
  };

  const handleReActiveEntrant = entrantDetails => {
    setModal({
      type: 'prizedraw-entrant-reactive',
      content: {
        entrant: entrantDetails,
        title: 'Are you sure you want reactivate this entrant?',
        subtitle: 'The entrant will change to active status and will start further charges.',
        confirmButtonProps: { label: 'Activate', className: 'primary' },
        cancelButtonProps: { label: 'Cancel', className: 'primary-white' },
        onSuccess: () => {
          const url = createEntrantFilterUrl(filters);
          getPrizeDrawEntrantData(url, 0, false);
        },
      },
    });
  };

  const getMenuList = entrantDetails => {
    const menu = [
      { name: 'Details', onClick: () => redirectToDetails(entrantDetails?.id), permission: 'ENTRANTS_DETAILS_VIEW' },
    ];
    const status = formatText(entrantDetails?.product?.product_status);
    if (!entrantDetails?.is_anonymized) {
      if (status !== 'canceled') {
        menu.push({
          name: 'Cancel',
          onClick: () => handleCancelEntrant(entrantDetails),
          permission: 'ENTRANTS_MANAGE',
        });
      } else {
        menu.push({
          name: 'Reactivate',
          onClick: () => handleReActiveEntrant(entrantDetails),
          permission: 'ENTRANTS_MANAGE',
        });
      }
    }
    return menu.filter(permission => (permission.permission ? hasPermission(permission.permission) : true));
  };

  const getDeclineCodeTooltip = (paymenCode = 'authentication_required') => {
    const paymenCodeData = paymentDeclineCode[paymenCode];
    return (
      <div className="flex-column items-start justify-start payment-status-tooltip">
        <label className="normal-text font-12">{capitalize(formatText(paymenCode, ' '))}</label>
        <label className="lighter-text font-12">{paymenCodeData}</label>
      </div>
    );
  };

  const handleSorting = fieldName => {
    if (!sortedBy) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    } else if (sortedBy === fieldName) {
      if (!orderBy) {
        setOrderBy('DESC');
      } else if (orderBy === 'DESC') {
        setOrderBy('ASC');
      } else if (orderBy === 'ASC') {
        setOrderBy('');
        setSortedBy('');
      }
    } else if (sortedBy !== fieldName) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    }
  };

  const getCurrencySymbol = currency => {
    switch (currency) {
      case 'USD':
        return '$';
    }
  };

  const fetchMoreData = () => {
    const url = createEntrantFilterUrl(filters);
    getPrizeDrawEntrantData(url, pageRef.current + 1, true);
  };

  const redirectToDetails = entrant_id => {
    if (hasPermission('ENTRANTS_DETAILS_VIEW')) {
      navigate(`/prize-draws/all/${id}/entrant-details/${entrant_id}/overview`);
    }
  };

  const handleExportEntrantList = () => {
    setModal({
      type: 'entrant-export',
      content: { filters: filters },
    });
  };
  const getFilterEnum = () => {
    const {
      category: { tag },
    } = org;
    switch (tag?.tag) {
      case 'AGENCY':
        return multipleFilterTypeEnum.entrants;
      case 'SAAS_OPERATOR':
        return multipleFilterTypeEnum?.entarntSAASOperator;
      case 'CLIENT':
        return multipleFilterTypeEnum.entrantClient;
      case 'MARKETING_COMPANY':
        return multipleFilterTypeEnum.entrantMarketingCompany;
      default:
        return multipleFilterTypeEnum.entrants;
    }
  };
  useEffect(() => {
    if (prizeDrawEntrant?.content) {
      setLoading(false);
    }
  }, [prizeDrawEntrant?.content]);
  return (
    <PrizeDrawEntrantsWrapper className="flex-column items-center justify-start w-full h-full">
      <PrizeDrawDetailHeader
        title={t('ENTRANTS')}
        titleContent={
          <div className="flex items-center justify-center count-box px-2 ml-4">
            <span className="semibold-text lighter-text mr-1 font-10">{prizeDrawEntrant?.total_elements} </span>
            <span className="regular-text lighter-text font-10">{t('RESULTS')}</span>
          </div>
        }
        actionContent={
          <div className="flex items-center justify-end flex-1">
            {hasPermission('ENTRANT_EXPORT') && (
              <Button
                className={'primary-grey mr-6'}
                label={t('EXPORT')}
                borderRadius={'12px'}
                size={'medium'}
                icon={<ExportIcon height={16} width={16} />}
                onClick={() => handleExportEntrantList()}
              />
            )}
            <div className="relative" onBlur={onBlur} tabIndex={0}>
              <div
                className="filter pxy-2 mr-6 flex items-center justify-center cursor"
                onClick={() => setOpenFilter(!openFilter)}>
                <FilterIcon className={`${(openFilter || filters.some(f => f.value)) && 'open-filter-icon'}`} />
              </div>
              {openFilter && (
                <MultipleFilter
                  className="prize-draw-entrant-filter"
                  filters={filters}
                  setFilters={setFilters}
                  filterFor={getFilterEnum()}
                />
              )}
            </div>
            <InputSearch
              placeholder={'Search'}
              value={searchText}
              onChange={setSearchText}
              className="input-search mr-6"
            />
          </div>
        }
      />
      {loading ? (
        <Loader />
      ) : prizeDrawEntrant?.content?.length > 0 ? (
        <>
          {/* content */}

          <div className="w-full content-header">
            <div
              className="flex w-full  justify-start items-center px-2 py-3 border-right overflow-hidden"
              onClick={() => handleSorting(sortedEnum.entrants)}>
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('ENTRANT_NAME')}</label>
              {sortedBy === sortedEnum.entrants && orderBy && (
                <UpIcon
                  className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                  height={16}
                  width={16}
                />
              )}
            </div>
            <div
              className="flex w-full justify-start items-center px-2 py-3 border-right overflow-hidden"
              onClick={() => handleSorting(sortedEnum.paymentStatus)}>
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('PAYMENT_STATUS')}</label>
              {sortedBy === sortedEnum.paymentStatus && orderBy && (
                <UpIcon
                  className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                  height={16}
                  width={16}
                />
              )}
            </div>
            <div
              className="flex w-full justify-start items-center px-2 py-3 border-right cursor overflow-hidden"
              onClick={() => handleSorting(sortedEnum.drawStatus)}>
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('DRAW_STATUS')}</label>
              {sortedBy === sortedEnum.drawStatus && orderBy && (
                <UpIcon
                  className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                  height={16}
                  width={16}
                />
              )}
            </div>
            <div
              className="flex w-full justify-start items-center px-2 py-3 border-right cursor overflow-hidden"
              onClick={() => handleSorting(sortedEnum.createdDate)}>
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('CREATED_DATE')}</label>
              {sortedBy === sortedEnum.createdDate && orderBy && (
                <UpIcon
                  className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                  height={16}
                  width={16}
                />
              )}
            </div>
            <div className="flex w-full justify-start items-center px-2 py-3 border-right overflow-hidden">
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('DONATION_AMOUNT')}</label>
            </div>
            <div
              className="flex w-full justify-start items-center px-2 py-3 border-right cursor overflow-hidden"
              onClick={() => handleSorting(sortedEnum.paymentFeq)}>
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('DONATION_FREQUENCY')}</label>
              {sortedBy === sortedEnum.paymentFeq && orderBy && (
                <UpIcon
                  className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                  height={16}
                  width={16}
                />
              )}
            </div>
            <div
              className="flex w-full justify-start items-center px-2 py-3 border-right cursor overflow-hidden"
              onClick={() => handleSorting(sortedEnum.winStatus)}>
              <label className="medium-text font-12 lighter-text mr-1 flex-1">{t('WIN_STATUS')}</label>
              {sortedBy === sortedEnum.winStatus && orderBy && (
                <UpIcon
                  className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                  height={16}
                  width={16}
                />
              )}
            </div>
          </div>
          <div className="flex-column flex-1 overflow-scroll w-full" id="scrollableDiv">
            <InfiniteScroll
              className="flex-column flex-1"
              dataLength={prizeDrawEntrant?.content?.length}
              hasMore={!prizeDrawEntrant?.last}
              height={60}
              loader={
                <div className="item flex items-center justify-center">
                  <img alt="loading" height="32px" src={Loading} />
                </div>
              }
              next={fetchMoreData}
              scrollableTarget="scrollableDiv">
              {prizeDrawEntrant?.content?.map(data => {
                return (
                  <div
                    className={`w-full content-body border-bottom cursor`}
                    key={data.id}
                    onClick={() => redirectToDetails(data.id)}>
                    <div className="flex w-full  justify-start items-center px-4 py-3 border-right ">
                      <div className="flex-column items-start justify-start w-full">
                        <label className="regular-text font-12 ">
                          {data?.is_anonymized ? (
                            <div className="font-12 grey-secondary-text">{t('ANONYMIZE')}</div>
                          ) : hasPermission('PII_VIEW') ? (
                            data?.contact?.name
                          ) : (
                            <div className="font-12 grey-secondary-text">{t('ENTRANT')}</div>
                          )}
                        </label>
                        <label className="normal-text font-12 lighter-text fw-400">{data?.urn}</label>
                      </div>
                    </div>
                    <div className="flex w-full justify-start items-center px-4 py-3 border-right ">
                      {data?.product?.product_status && (
                        <Tooltip
                          className="overflow-hidden"
                          content={getDeclineCodeTooltip()}
                          shouldOpen={
                            formatText(data?.product?.product_status) === 'pastdue' ||
                            formatText(data?.product?.product_status) === 'unpaid'
                              ? true
                              : false
                          }>
                          <PaymentStatus
                            statusWrapperClassName="w-full overflow-hidden"
                            statusTextClassName="one-line"
                            withDot
                            status={data?.product?.product_status}
                            statusText={data?.product?.product_status === 'PAST_DUE' ? 'Past Due' : ''}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div className="flex w-full justify-start items-center px-4 py-3 border-right">
                      <Tooltip
                        className="overflow-hidden"
                        content={
                          <span className="lighter-text font-12 px-2">
                            {data?.draw?.out_of_draw_date &&
                              moment(data?.draw?.out_of_draw_date * 1000).format('MMM DD, YYYY')}
                          </span>
                        }
                        shouldOpen={formatText(data?.draw?.status, ' ') === 'outofdraw' ? true : false}>
                        <Status
                          withDot={true}
                          statusTextClassName="one-line"
                          status={data?.draw?.status}
                          statusText={capitalizeFirstLetterOfWords(formatText(data?.draw?.status, ' '))}
                          statusWrapperClassName={'overflow-hidden w-full'}
                        />
                      </Tooltip>
                    </div>
                    <div className="flex w-full justify-start items-center px-4 py-3 border-right ">
                      <label className="normal-text font-12 lighter-text fw-400 one-line">
                        {data?.created_date && moment(data?.created_date * 1000).format('MMM DD, YYYY')}
                      </label>
                    </div>
                    <div className="flex w-full justify-start items-center px-4 py-3 border-right ">
                      <label className="normal-text font-12 lighter-text fw-400 one-line">
                        {data?.product ? `${getCurrencySymbol(data?.product?.currency)} ${data?.product?.amount}` : ''}
                      </label>
                    </div>
                    <div className="flex w-full justify-start items-center px-4 py-3 border-right ">
                      <label className="normal-text font-12 lighter-text fw-400 one-line">
                        {data?.product
                          ? `${data?.product?.frequency_count} ${getPaymentFrequencyText(data?.product?.frequency)}`
                          : ''}
                      </label>
                    </div>
                    <div className="flex w-full justify-between items-center px-4 py-3">
                      <Tooltip
                        content={
                          <>
                            <div className="flex w-full justify-start items-center">
                              <label className="normal-text flex-column font-12 lighter-text fw-400 one-line">
                                {data?.prizedraw?.prizes?.map(prize => (
                                  <span>
                                    <span className="regular-text font-12 lighter-text fw-400 mr-4">
                                      {prize?.win_date && moment(prize?.win_date * 1000).format('MM/DD/YYYY')}
                                    </span>
                                    <span className="regular-text font-12">
                                      {`${getCurrencySymbol(prize?.win?.currency)}${
                                        prize?.win?.prize_amount ? `${Number(prize?.win?.prize_amount).toFixed(2)}` : ''
                                      }`}
                                    </span>
                                  </span>
                                ))}
                              </label>
                            </div>
                          </>
                        }
                        shouldOpen={data?.prizedraw?.prizes?.length ? true : false}>
                        <div
                          className={`flex items-center status-container py-1 px-2 cursor ${
                            data?.prizedraw?.prizes?.length ? 'winner' : 'entrant'
                          }`}>
                          <div className="dot mr-1" />
                          <span className="medium-text font-12">
                            {data?.prizedraw?.prizes?.length ? 'Winner' : 'Entrant'}
                          </span>
                        </div>
                      </Tooltip>
                      <Menu menuList={getMenuList(data)} />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </>
      ) : debouncedSearch || filters.some(item => item.param) ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </PrizeDrawEntrantsWrapper>
  );
};

export default PrizeDrawEntrants;
