import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as MailIcon } from '../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../assets/images/mobile.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { getItemFromLocalStorage } from '../helpers/localstorage';
import { getDetailsProfileUser } from '../store/features/profileSlice';
import { addToast } from '../store/features/toastSlice';
import { deleteUser, inviteUser, updateUsersList, updateUsersStatus } from '../store/features/userManagementSlice';
import CommonPopup from './common-popup';
import { UserActionsWrapper } from './modal.styled.js';

const UserActions = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const navigate = useNavigate();
  const localUser = getItemFromLocalStorage('user');
  const localUserOrganization = localUser.organizations?.[0] || {};

  const [loading, setLoading] = useState(false);
  const {
    user,
    isDelete,
    isReinvite,
    title,
    isChangeStatus,
    isActive,
    isCancelInvitation,
    onSuccess = () => {},
    isUserDetailPage,
  } = modal.content;
  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (isReinvite) {
        dispatch(inviteUser({ organization_id: localUserOrganization.id, user: user }))
          .then(() => {
            setLoading(false);
            setModal(initModal);
            onSuccess();
          })
          .catch(() => {
            setLoading(false);
            dispatch(
              addToast({
                error: true,
                text: 'Error while re invite user',
                id: uuid(),
              }),
            );
          });
      } else if (isDelete || isCancelInvitation) {
        dispatch(deleteUser({ ...user }))
          .then(() => {
            setLoading(false);
            setModal(initModal);
            if (isUserDetailPage) {
              navigate('/settings/security/users');
            }
          })
          .catch(err => {
            setLoading(false);
            dispatch(
              addToast({
                error: true,
                text: isCancelInvitation ? 'Error while cancelling invitation' : 'Error while delete user',
                id: uuid(),
              }),
            );
          });
      } else if (isChangeStatus) {
        const { is_active, id } = user;
        const params = {
          is_active: !isActive,
        };
        if (!isUserDetailPage) {
          dispatch(updateUsersList({ id: id }));
        }
        dispatch(updateUsersStatus({ id: id, params }))
          .then(() => {
            if (isUserDetailPage) {
              dispatch(getDetailsProfileUser({ id: id })).catch(() => {});
            }
            setLoading(false);
            setModal(initModal);
          })
          .catch(error => {
            dispatch(updateUsersList({ id: id }));
            dispatch(
              addToast({
                error: true,
                text: 'Error while updating status',
                id: uuid(),
              }),
            );
          });
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to ${isDelete ? 'delete' : 'disable'} user`,
          id: uuid(),
        }),
      );
    }
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{
        label: isCancelInvitation
          ? 'Yes'
          : isReinvite
          ? 'Re-invite'
          : isDelete
          ? 'Delete'
          : isChangeStatus
          ? isActive
            ? 'Deactivate'
            : 'Activate'
          : 'Confirm',
        className: isDelete ? 'negative' : 'primary',
      }}
      cancelButtonProps={{ label: isCancelInvitation ? 'No' : 'Cancel' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <UserActionsWrapper className="flex-column items-center w-full">
        <div className="mt-6 pxy-4 flex-column w-full user-details-container">
          <label className="bold-text font-18">{user.name}</label>
          <label className="regular-text font-14 grey-text mt-1">{user?.role?.name}</label>
          <div className="flex-column items-start justify-start w-full mt-5">
            <div className="flex items-center justify-start w-full">
              <div className="flex items-center justify-center icon-wrapper mr-2">
                <MailIcon className="flex items-center justify-center " height={16} width={16} />
              </div>
              <label className={`regular-text font-14 ${!user?.email ? 'grey-text' : ''}`}>
                {user?.email ? user?.email : 'Not provided'}
              </label>
            </div>
            <div className="flex items-center justify-start w-full mt-2">
              <div className="flex items-center justify-center icon-wrapper mr-2">
                <MobileIcon className="flex items-center justify-center " height={16} width={16} />
              </div>
              <label className={`regular-text font-14 ${!user?.phone ? 'grey-text' : ''}`}>
                {user?.phone ? user?.phone : 'Not provided'}
              </label>
            </div>
          </div>
        </div>
      </UserActionsWrapper>
    </CommonPopup>
  );
};

export default UserActions;
