import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ConditonIcon } from '../../../assets/images/condition.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/menu/arrow-down-black.svg';
import { ReactComponent as RetryIcon } from '../../../assets/images/retry.svg';
import { ReactComponent as RightArrow } from '../../../assets/images/sorting-right.svg';
import { ReactComponent as SwapVerticalIcon } from '../../../assets/images/swap-vertical-circle.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/time.svg';
import { ReactComponent as AlertIcon } from '../../../assets/images/vector.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalize, formatText, isJson, operatorOptions } from '../../../helpers/utils';
import Status from '../../common/status/automation-log-status';

const AutomationLogAction = ({ automationAction, index }) => {
  const { setModal } = useContext(OrganisationContext);
  const [expand, setExpand] = useState(true);
  const { t } = useTranslation();
  const lstErrors = isJson(automationAction?.status_message)
    ? JSON.parse(automationAction?.status_message).errors || []
    : typeof automationAction?.status_message === 'string'
    ? [{ field: automationAction?.status_message, text: automationAction?.status_message }]
    : [];

  const handleRetryAction = () => {
    setModal({
      type: 'automation-log-action',
      content: {
        automationAction: automationAction,
        isRetry: true,
        title: 'Are you sure you want to retry this action?',
        actionTitle: `Action ${index + 1}`,
      },
    });
  };

  const showSchedule =
    automationAction?.action?.schedule?.offset &&
    automationAction?.action?.schedule?.unit &&
    automationAction?.action?.schedule?.field;

  const expandUI = (
    <div className="automation-log-action-wrapper flex-column border items-start justify-start w-full radius-4">
      {/* Header */}
      <div className="flex-column items-start justify-start w-full pr-6 pt-4 pb-6 border-bottom">
        <div className="flex items-center header-border-left w-full ">
          <div className="flex items-center pl-5 flex-1">
            <div className="dot-2-green" />
            <label className="medium-text font-16 ml-2">{`Action ${index + 1}`}</label>
            {automationAction?.status === 'FAILURE' && (
              <div className="flex items-center justify-start ml-4 cursor" onClick={() => handleRetryAction()}>
                <RetryIcon className="flex item-center justify-center" height={16} width={16} />
                <label className="medium-text ml-1 retry-text font-12">{t('RETRY')}</label>
              </div>
            )}
          </div>
          <div className="flex items-center justify-start">
            <div
              className={`flex items-center justify-center radius-1 status-container w-fit-content ${formatText(
                automationAction?.status,
              )}`}>
              <label className="medium-text font-12 one-line">
                <Status
                  dotColor={automationAction?.status}
                  withDot
                  status={automationAction?.status}
                  statusText={capitalize(formatText(automationAction?.status))}
                />
              </label>
            </div>
            <ArrowDownIcon
              className={`flex items-center justify-center ml-6 cursor ${expand ? 'rotate-180' : ''}`}
              onClick={() => setExpand(!expand)}
            />
          </div>
        </div>
        <div className="flex-column items-start justify-start w-full">
          {automationAction?.status === 'FAILURE' &&
            lstErrors.map((err, index) => (
              <div
                className={`flex items-center justify-start ml-6 schedule-conditon-wrapper w-full radius-2 pxy-2 action-container ${
                  index === 0 ? '' : 'mt-1'
                }`}
                key={err.field}>
                <AlertIcon className="flex justify-center items-center" height={16} width={16} />
                <label className="regular-text ml-2 flex-1">{err.message}</label>
              </div>
            ))}
        </div>
        <div className="flex items-start justify-start self-start ml-6 sub-header-border radius-2 mt-4 px-4 py-3">
          <div className="flex items-center justify-center icon-wrapper">
            <MailIcon className="flex items-center justify-center" height={24} width={24} />
          </div>

          <div className="flex-column items-start justify-start flex-1 ml-2">
            <label className="medium-text font-14">{automationAction?.action?.action_type?.name}</label>
            <label className="regular-text font-12 grey-text">
              {automationAction?.action?.action_type?.description}
            </label>
          </div>
        </div>
      </div>

      {/* content */}
      <div className="flex-column items-start justify-start w-full pxy-6">
        <div className="flex items-center justify-start w-full">
          <div className="flex items-center justify-center border icon-wrapper">
            <img
              src={`${automationAction?.action?.integration?.connector?.icon?.active}`}
              style={{ width: 18, height: 18 }}
            />
          </div>
          <label className="ml-2 medium-text font-14">{automationAction?.action?.integration?.name}</label>
        </div>
        {automationAction?.action?.params.length > 0 && (
          <Fragment>
            <div className="flex-column items-start justify-start w-full mt-4">
              {automationAction?.action?.params
                ?.filter(p => !p.mapping_source)
                .map((param, index) => (
                  <div className={`flex items-center justify-start w-full ${index === 0 ? '' : 'mt-2'}`} key={param.id}>
                    <label className="regular-text lighter-text font-14">
                      {capitalize(formatText(param.param_type, ' '))}: &nbsp;
                    </label>
                    <label className="regular-text font-14"> {param.display_value || param.param_value}</label>
                  </div>
                ))}
            </div>
            {automationAction?.action?.params?.filter(p => p.mapping_source)?.length > 0 && (
              <div className="flex-column bg-backgroundClr mapping-data-wrapper mt-4 radius-2 px-3 py-2">
                {automationAction?.action?.params
                  ?.filter(p => p.mapping_source)
                  ?.map((param, index) => {
                    const { display_value, id } = param || {};
                    const splitedName = display_value?.split(':') || [];
                    return (
                      <div key={id} className="data-wrapper items-center col-gap-2 row-gap-1">
                        {index === 0 ? <SwapVerticalIcon width={16} height={16} /> : <div />}
                        <div className="flex items-center col-gap-2">
                          <label className="regular-text font-14">
                            {capitalize(formatText(splitedName?.[0], ' '))}
                          </label>
                          <RightArrow />
                          <label className="regular-text font-14">
                            {capitalize(formatText(splitedName?.[1], ' '))}
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </Fragment>
        )}
        {automationAction?.action?.schedule?.offset &&
          automationAction?.action?.schedule?.unit &&
          automationAction?.action?.schedule?.field && (
            <div className="flex item-center justify-start mt-6 schedule-conditon-wrapper py-2 px-4 radius-2">
              <ClockIcon className="flex items-center justify-center" height={18} width={18} />
              <label className="regular-text ml-1">{`${Math.abs(automationAction?.action?.schedule?.offset)}
           ${formatText(automationAction?.action?.schedule?.unit, ' ')}
           ${automationAction?.action?.schedule?.offset < 0 ? 'before' : 'after'}
           ${automationAction?.action?.schedule?.field.toLowerCase().replaceAll('.', ' ')}`}</label>
            </div>
          )}
        <div className="flex-column items-start justify-start mt-2">
          {(automationAction?.action?.conditions || []).map((condition, index) => {
            return (
              <div className="flex item-center justify-start mt-6 schedule-conditon-wrapper py-2 px-4 radius-2 my-1">
                <label className="flex regular-text font-14">
                  {index == 0 ? 'When' : 'And'} {condition?.field}{' '}
                  {operatorOptions.find(opr => opr.value === condition?.operator).name}
                </label>
                <label className="flex medium-text font-14 ml-1">{condition?.value}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const collapsedUI = (
    <div className="automation-log-action-wrapper flex-column border items-start justify-start w-full radius-4">
      {/* Header */}
      <div className="flex-column items-start justify-start w-full pr-6 pt-4 pb-6">
        <div className="flex items-center header-border-left w-full ">
          <div className="flex items-center pl-5 flex-1">
            <div className="dot-2-green" />
            <label className="medium-text font-16 ml-2">{`Action ${index + 1}`}</label>
            {automationAction?.status === 'FAILURE' && (
              <div className="flex items-center justify-start ml-4 cursor" onClick={() => handleRetryAction()}>
                <RetryIcon className="flex item-center justify-center" height={16} width={16} />
                <label className="medium-text ml-1 retry-text font-12">{t('RETRY')}</label>
              </div>
            )}
          </div>
          <div className="flex items-center justify-start ">
            <div
              className={`flex items-center justify-center radius-1 status-container w-fit-content ${formatText(
                automationAction?.status,
              )}`}>
              <label className="medium-text font-12 one-line">
                <Status
                  dotColor={automationAction?.status}
                  withDot
                  status={automationAction?.status}
                  statusText={capitalize(formatText(automationAction?.status))}
                />
              </label>
            </div>
            <ArrowDownIcon
              className={`flex items-center justify-center ml-6 cursor ${expand ? 'rotate-180' : ''}`}
              onClick={() => setExpand(!expand)}
            />
          </div>
        </div>
        <div className="flex-column items-start justify-start w-full">
          {automationAction?.status === 'FAILURE' &&
            lstErrors.map((err, index) => (
              <div
                className={`flex items-center justify-start ml-6 schedule-conditon-wrapper w-full radius-2 pxy-2 action-container ${
                  index === 0 ? '' : 'mt-1'
                }`}
                key={err.field}>
                <AlertIcon className="flex justify-center items-center" height={16} width={16} />
                <label className="regular-text ml-2 one-line flex-1">{err.message}</label>
              </div>
            ))}
        </div>
        <div className="flex items-center justify-start ml-6 sub-header-border radius-2 mt-4 px-4 py-3">
          <div className="flex items-start justify-start w-50">
            <MailIcon className="flex items-center justify-center" height={24} width={24} />
            <div className="flex-column items-start justify-start flex-1 ml-2">
              <label className="medium-text font-14">{automationAction?.action?.action_type?.name}</label>
              <label className="regular-text font-12 grey-text">
                {automationAction?.action?.action_type?.description}
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between w-50 ">
            <div className="flex items-center justify-start flex-1">
              <div className="flex items-center justify-center border icon-wrapper">
                <img
                  src={`${automationAction?.action?.integration?.connector?.icon?.active}`}
                  style={{ width: 18, height: 18 }}
                />
              </div>
              <label className="ml-2 medium-text font-14">{automationAction?.action?.integration?.name}</label>
            </div>
            <div className="flex items-center justify-start">
              {showSchedule && <ClockIcon className="flex items-center justify-center" />}
              <div className="flex items-center justify-center ml-4">
                <ConditonIcon className="flex items-center justify-center" color={'#053149'} />
                <label className="regular-text ml-2">{(automationAction?.action?.conditions || [])?.length}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return expand ? expandUI : collapsedUI;
};

export default AutomationLogAction;
