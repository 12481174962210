import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { ColorPicker } from '../common/color-picker';
export const GeneralSettings = ({ colorPicker, setColorPicker }) => {
  const { formAttributes, setFormAttributes, onPageColorChange, onComponentColorChange } =
    useContext(FormBuilderContext);
  const { background, color, panel_width, field_accent_color } = formAttributes || {};
  const { button, page, default_component } = color;
  const { t } = useTranslation();
  return (
    <div className="pb-8 pt-6 border-bottom flex-column">
      <label className="medium-text">{t('GENERAL_UC')}</label>
      <div className="flex items-center mt-4">
        <label className="flex flex-1 regular-text mb-1">{t('WIDTH')}</label>
        <input
          placeholder="Width"
          className="input general-settings-input flex-1 w-full"
          type="number"
          value={panel_width}
          onChange={({ target: { value } }) => setFormAttributes({ ...formAttributes, panel_width: value })}
        />
      </div>
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Background color'}
        settingKey={'color'}
        selectedColor={background.color}
        onChangeColor={(settingKey, color) =>
          setFormAttributes({ ...formAttributes, background: { ...formAttributes.background, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Page color'}
        settingKey={'page'}
        selectedColor={page}
        onChangeColor={(settingKey, color) =>
          onPageColorChange({ ...formAttributes, color: { ...formAttributes.color, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Default component color'}
        settingKey={'default_component'}
        selectedColor={default_component}
        onChangeColor={(settingKey, color) =>
          onComponentColorChange({ ...formAttributes, color: { ...formAttributes.color, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Button color'}
        settingKey={'button'}
        selectedColor={button}
        onChangeColor={(settingKey, color) =>
          setFormAttributes({ ...formAttributes, color: { ...formAttributes.color, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Field accent color'}
        settingKey={'field_accent_color'}
        selectedColor={field_accent_color}
        onChangeColor={(settingKey, color) => setFormAttributes({ ...formAttributes, [settingKey]: color })}
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
    </div>
  );
};
