import styled from 'styled-components';

export const GroupsListWrapper = styled.div`
  overflow: scroll;
  border-top: 1px solid ${({ theme }) => theme.colors.ZenGray2};
`;

export const GroupsListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 230px) minmax(350px, 400px) minmax(250px, 1fr) minmax(100px, 150px);
  min-height: 72px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  padding: 8px 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.hoverColor};
  }

  .tooltip-content {
    padding: 3px;
  }

  .role-wrapper {
    background: ${({ theme }) => theme.colors.ZenBlueSecondary};
    padding: 2px 8px;
    width: fit-content;

    label {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
  }
  .height-none {
    height: 0px;
    padding: 0px;
    margin: 0px;
  }

  .display-none-class {
    height: 0;
    margin: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: transparent !important;
    border: unset !important;
  }
`;
