import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import GroupDetails from '../../components/groups-details';
import PageHeader from '../../components/page-header';
import { getGroupDetail } from '../../store/features/groupsSlice';
import { GroupsDetailContainerWrapper } from '../../styles/pages/group-details-container.styled';

const GroupDetailsContainer = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { groupDetails, accountDetails } = useSelector(state => state.groups);
  const isAccountDetails = location.pathname.includes('account-details');

  useEffect(() => {
    dispatch(getGroupDetail({ id: id }));
  }, [id]);

  return (
    <GroupsDetailContainerWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Groups', path: '/groups' },
          { text: groupDetails?.name, path: `/groups/${id}/accounts` },
          ...(isAccountDetails && accountDetails?.name ? [{ text: `${accountDetails?.name}`, onClick: () => {} }] : []),
        ]}
      />
      <GroupDetails />
    </GroupsDetailContainerWrapper>
  );
};

export default GroupDetailsContainer;
