import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../components/McDropdown';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import useDebounce from '../helpers/useDebounceHook';
import { getUserInitials } from '../helpers/utils';
import { createAutomation } from '../store/features/automationsSlice';
import { addToast } from '../store/features/toastSlice';
import { getUsers } from '../store/features/userManagementSlice';
import CommonPopup from './common-popup';
import { AddAutomationWrapper } from './modal.styled';

const ownerSelectedValue = value => {
  return (
    <div className={`flex items-center w-full`}>
      <div className="user-icon flex items-center justify-center mr-2">
        <span className="bold-text grey-text-2">{getUserInitials(value || {})}</span>
      </div>
      <div className="regular-text fw-400 font-16">{value?.name}</div>
    </div>
  );
};

const RenderOwnerOption = props => {
  const { option: value, handleSelect, index } = props;
  return (
    <div
      className={`flex items-center w-full px-2 radius-2 mt-2 owner-option cursor`}
      onClick={() => handleSelect(value)}>
      <div className="user-icon flex items-center justify-center mr-2 border">
        <span className="bold-text grey-text-2">{getUserInitials(value || {})}</span>
      </div>
      <div className="regular-text fw-400 font-16">{value?.name}</div>
    </div>
  );
};

function AddAutomation() {
  const { setModal, modal } = useContext(OrganisationContext);
  const { users } = useSelector(state => state.usersManagement);
  const pageRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [automation, setAutomation] = useState({});
  const [error, setError] = useState({});
  const { onSuccess } = modal.content;
  const [searchText, setSearchText] = useState('');
  const debouncedSearchSearch = useDebounce(searchText, 500);

  const getUsersList = (page, merge) => {
    pageRef.current = page;
    dispatch(getUsers({ params: { page: page, search: debouncedSearchSearch }, merge: merge }));
  };

  useEffect(() => {
    getUsersList(0, false);
  }, []);

  useEffect(() => {
    getUsersList(0, false);
  }, [debouncedSearchSearch]);

  const fetchMoreData = () => {
    getUsersList(pageRef.current + 1, true);
  };

  const checkAutomationErrors = () => {
    if (!automation?.name?.trim() || !automation?.description?.trim() || !automation?.owner?.id) {
      setError({
        name: !automation?.name?.trim(),
        owner: !automation?.owner?.id,
        description: !automation?.description?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill automation name, description and owner' }));
      return true;
    }
    return false;
  };

  const onDone = () => {
    if (loading || checkAutomationErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      name: automation.name,
      description: automation.description,
      owner: { id: automation.owner.id },
    };
    dispatch(createAutomation({ request }))
      .then(data => {
        setModal(initModal);
        setLoading(false);
        onSuccess && onSuccess(data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <CommonPopup
      popupTitle="Add automation"
      confirmButtonProps={{ label: 'Add' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}
      disabled={loading}>
      <AddAutomationWrapper className="flex-column items-center w-full">
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{t('NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error?.name && 'error-border'}`}
            onChange={e => {
              setAutomation({ ...automation, name: e.target.value });
              setError({ ...error, name: false });
            }}
            placeholder="Automation name"
            value={automation?.name || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{t('DESCRIPTION')}</label>
          <textarea
            className={`textarea description-input ${error?.description && 'error-border'}`}
            onChange={e => {
              setAutomation({ ...automation, description: e.target.value });
              setError({ ...error, description: false });
            }}
            placeholder="Enter description"
            rows={4}
            value={automation?.description || ''}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text lighter-text mb-1 font-12">{t('OWNER')}</label>
          <DropDown
            className={`provider-selector ${error?.owner && 'error-border'}`}
            options={users?.content || []}
            selected={automation?.owner || null}
            setSelected={option => setAutomation({ ...automation, owner: option })}
            placeholder={`Select`}
            size="large"
            fetchData={() => fetchMoreData()}
            hasMore={!users?.last}
            isPaged={true}
            renderSelectedValue={selectedValue => ownerSelectedValue(selectedValue)}
            RenderOption={props => <RenderOwnerOption {...props} />}
            isSearchable
            search={searchText}
            setSearch={setSearchText}
          />
        </div>
      </AddAutomationWrapper>
    </CommonPopup>
  );
}

export default AddAutomation;
