import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as AmericanExpress } from '../assets/images/card/american-express.svg';
import { ReactComponent as BankAccount } from '../assets/images/card/bank-account.svg';
import { ReactComponent as Discover } from '../assets/images/card/discover.svg';
import { ReactComponent as Master } from '../assets/images/card/master.svg';
import { ReactComponent as Visa } from '../assets/images/card/visa.svg';
import { ReactComponent as UserIcon } from '../assets/images/user.svg';
import DropDown from '../components/McDropdown';
import DateSelectorElement from '../components/widget/date-selector-element/date-selector-element';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, getCurrencySymbol } from '../helpers/utils';
import {
  createEntrantSubscription,
  getFormProductDetails,
  getProductDetails,
  prizeDrawEntrantEntryForm,
  prizeDrawEntrantOverviewPaymentMethods,
  prizeDrawEntrantOverviewProducts,
} from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { PrizedrawEntrantReActiveWrapper } from './modal.styled';

const RenderProductListOption = props => {
  const { option, handleSelect, index, selected } = props;
  const { pricing, external_reference, id } = option || {};
  return (
    <div
      className={`flex items-center justify-between px-2 py-1 product-list-option cursor radius-2 ${
        index > 0 ? 'mt-1' : ''
      } ${id === selected?.id ? 'selected-option' : ''}`}
      onClick={() => handleSelect(option)}>
      <div className="flex-column flex-1">
        <label className="regular-text font-16">{option?.name}</label>
        <div className="flex mt-1">
          <label className="regular-text grey-text font-12 mr-1">{external_reference}</label>
          <label className="regular-text grey-text font-12">
            {getCurrencySymbol(pricing?.currency_code)}
            {pricing?.price} {pricing?.recurring_period === 'YEAR' ? 'Yearly' : 'Monthly'}
          </label>
        </div>
      </div>
    </div>
  );
};

const RenderPaymentMethodOption = props => {
  const { option, handleSelect, index, selected } = props;
  const { id, type, card, bank, brand, last_4_digits } = option || {};

  const getCardImage = brand => {
    switch (brand) {
      case 'visa':
        return <Visa height={28} width={60} />;
      case 'amex':
        return <AmericanExpress height={28} />;
      case 'discover':
        return <Discover height={28} />;
      case 'mastercard':
        return <Master height={28} />;
      default:
        return <BankAccount height={28} />;
    }
  };

  return (
    <div
      className={`flex items-center justify-between payment-method-option cursor radius-2 ${index > 0 ? 'mt-1' : ''} ${
        id === selected?.id ? 'selected-option' : ''
      }`}
      onClick={() => handleSelect(option)}>
      <div className="flex flex-1">
        <div className="flex items-center justify-center">{getCardImage(brand)}</div>
        {type === 'CARD' ? (
          <div className="flex-column flex-1 ml-2">
            <div className="flex">
              <label className="regular-text font-16">{capitalize(brand)}</label>
              <label className="regular-text font-16 ml-1">{last_4_digits}</label>
            </div>
            <div className="flex flex-1">
              <label className="regular-text lighter-text font-12">
                Expires{' '}
                {moment()
                  .set('month', card.expiration_month - 1)
                  .set('year', card.expiration_year)
                  .format('MMM YYYY')}
              </label>
            </div>
          </div>
        ) : (
          <div className="flex-column flex-1 ml-2">
            <label className="regular-text font-16">{bank?.name}</label>
            <label className="regular-text lighter-text font-12">US Bank Account</label>
          </div>
        )}
      </div>
    </div>
  );
};

const renderSelectedPaymentMethod = option => {
  const { id, type, card, bank, brand, last_4_digits } = option || {};

  const getCardImage = brand => {
    switch (brand) {
      case 'visa':
        return <Visa height={28} />;
      case 'amex':
        return <AmericanExpress height={28} />;
      case 'discover':
        return <Discover height={28} />;
      case 'mastercard':
        return <Master height={28} />;
      default:
        return <BankAccount height={28} />;
    }
  };
  return (
    <div className="flex flex-1">
      <div className="flex items-center justify-center">{getCardImage(brand)}</div>
      {type === 'CARD' ? (
        <div className="flex items-center pr-2 flex-1">
          <label className="regular-text font-16">{capitalize(brand)}</label>
          <label className="regular-text font-16 flex-1 ml-1">{last_4_digits}</label>
          <label className="regular-text lighter-text font-12">
            Exp{' '}
            {moment()
              .set('month', card.expiration_month - 1)
              .set('year', card.expiration_year)
              .format('MM/YY')}
          </label>
        </div>
      ) : (
        <div className="flex flex-1 pr-2">
          <label className="regular-text font-16 flex-1">{bank?.name}</label>
          <label className="regular-text lighter-text font-12">{t('US_BANK_ACCOUNT')}</label>
        </div>
      )}
    </div>
  );
};

const PrizeDrawEntrantReActive = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const { entrant, title, subtitle, confirmButtonProps, cancelButtonProps, onSuccess } = modal.content;
  const [entrantData, setEntrantData] = useState({ billingCycle: moment().unix() });
  const [productList, setProductList] = useState([]);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const fetchData = async () => {
    try {
      setLoading(true);
      let productDetails = {};
      let formProducts = [];
      const productData = await dispatch(prizeDrawEntrantOverviewProducts(entrant?.id));
      const paymentMethods = await dispatch(prizeDrawEntrantOverviewPaymentMethods(entrant?.id));
      const entryForm = await dispatch(prizeDrawEntrantEntryForm(entrant?.id));
      const firstPaymentMethod = paymentMethods?.[0];
      if (productData?.length > 0 && firstPaymentMethod?.integration?.id) {
        productDetails = await dispatch(
          getProductDetails({
            product_id: productData[0]?.product?.external_reference,
            params: { integration_id: firstPaymentMethod?.integration?.id },
          }),
        );
      }

      if (entryForm?.form?.id) {
        formProducts = await dispatch(
          getFormProductDetails({
            form_id: entryForm?.form?.id,
          }),
        );
      }
      setPaymentMethodList([...paymentMethods]);
      checkDuplicates(productDetails, formProducts.content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Something went wrong',
        }),
      );
    }
  };

  const checkDuplicates = (productDetails, formProducts) => {
    const externalReferencesData = [productDetails.external_reference];
    const externalReferencesData1 = formProducts.map(item => item.external_reference);

    const duplicates = externalReferencesData.filter(reference => externalReferencesData1.includes(reference));

    const uniqueValues = formProducts.filter((item, index) => {
      return (
        !duplicates.includes(item.external_reference) ||
        formProducts.findIndex(i => i.external_reference === item.external_reference) === index
      );
    });

    setProductList(uniqueValues);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkErrors = () => {
    if (!entrantData?.product?.external_reference || !entrantData?.paymentMethod?.id) {
      setError({
        product: !entrantData?.product?.external_reference,
        paymentMethod: !entrantData?.paymentMethod?.id,
      });
      dispatch(addToast({ error: true, text: 'Please select product and payment method' }));
      return true;
    }
    return false;
  };

  const onDone = () => {
    if (loading || checkErrors()) {
      return;
    }
    const { product, billingCycle, paymentMethod } = entrantData;
    const request = {
      product: {
        id: product.external_reference,
      },
      payment_method: {
        id: paymentMethod.external_reference,
      },
      vendor: null,
      price: {
        id: product?.pricing?.external_reference,
      },
      integration: {
        id: paymentMethod?.integration?.id,
      },
      billing: {
        start_date: billingCycle || moment().unix(),
      },
    };
    setLoading(true);
    dispatch(createEntrantSubscription({ entrant_id: entrant?.id, request }))
      .then(() => {
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(() => {
        dispatch(
          addToast({
            error: true,
            text: 'Something went wrong while reactivating subscription',
          }),
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{ ...confirmButtonProps }}
      cancelButtonProps={{ ...cancelButtonProps }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}
      disabled={loading}>
      <PrizedrawEntrantReActiveWrapper className="flex-column w-full mt-2">
        <div className="flex-column pb-6 border-bottom">
          {subtitle && <label className="regular-text grey-text text-center">{subtitle}</label>}
          <div className="flex entrant-details radius-4 pxy-4 col-gap-2 mt-8">
            <UserIcon height={24} width={24} color="#16192C" />
            <div className="flex-column row-gap-1">
              <label className="semibold-text font-18"> {entrant?.contact?.name}</label>
              <label className="regular-text grey-text"> {entrant?.urn}</label>
            </div>
          </div>
        </div>
        <div className="flex-column w-full row-gap-6 mt-6">
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">{t('PRODUCT')}</label>
            <DropDown
              optionItemHeight={53}
              className={`product-selector ${error?.product ? 'error-border' : ''}`}
              options={productList || []}
              showLoader={loading}
              selected={entrantData.product || null}
              setSelected={option => setEntrantData({ ...entrantData, product: option })}
              placeholder={`Select`}
              size="large"
              RenderOption={props => <RenderProductListOption {...props} selected={entrantData.product || {}} />}
            />
          </div>
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">Start billing cycle on</label>
            <DateSelectorElement
              className={'relative fromdate-container'}
              selectedDate={entrantData?.billingCycle || moment().unix()}
              setSelectedDate={date => setEntrantData({ ...entrantData, billingCycle: date })}
              format={'MM/DD/YYYY'}
            />
          </div>
          <div className="flex-column w-full row-gap-1">
            <label className="normal-text font-12 lighter-text">{t('PAYMENT_METHOD')}</label>
            <DropDown
              optionItemHeight={44}
              className={`payment-method-selector ${error?.paymentMethod ? 'error-border' : ''}`}
              options={paymentMethodList || []}
              selected={entrantData?.paymentMethod || null}
              setSelected={option => setEntrantData({ ...entrantData, paymentMethod: option })}
              placeholder={`Select`}
              size="large"
              renderSelectedValue={selectedValue => renderSelectedPaymentMethod(selectedValue)}
              RenderOption={props => <RenderPaymentMethodOption {...props} selected={entrantData.product || {}} />}
            />
          </div>
        </div>
      </PrizedrawEntrantReActiveWrapper>
    </CommonPopup>
  );
};

export default PrizeDrawEntrantReActive;
