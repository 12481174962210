import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const OrganizationAccessLog = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_type, tenant, event_data } = log || {};
  const { prizedraw_organization, prizedraw } = event_data;
  const IS_SAAS_OPERATOR = prizedraw_organization?.parent?.category?.name === 'Saas Operators' ? true : false;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  const getHeaderText = () => {
    if (event_type === 'PRIZEDRAW_ORGANIZATION_ADD') {
      return IS_SAAS_OPERATOR ? 'Agency added' : 'Marketing Company added';
    } else if (event_type === 'PRIZEDRAW_ORGANIZATION_REMOVE') {
      return IS_SAAS_OPERATOR ? 'Agency removed' : 'Marketing Company removed';
    }
  };
  const getTitleText = () => {
    if (event_type === 'PRIZEDRAW_ORGANIZATION_ADD') {
      return IS_SAAS_OPERATOR
        ? 'added agency organization to Prize Draw'
        : 'added marketing company organization to agency in Prize Draw';
    } else if (event_type === 'PRIZEDRAW_ORGANIZATION_REMOVE') {
      return IS_SAAS_OPERATOR
        ? 'removed agency organization from Prize Draw'
        : 'added marketing company organization to agency in Prize Draw';
    }
  };
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={getHeaderText()}
        subtitle={tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{getTitleText()}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'Agency'}
            contentText={<p className="regular-text lighter-text font-12">{prizedraw_organization?.parent?.name}</p>}
          />
          {!IS_SAAS_OPERATOR && (
            <LogContentBox
              title={'Marketing Company'}
              contentText={<p className="regular-text lighter-text font-12">{prizedraw_organization?.name}</p>}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OrganizationAccessLog;
