import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AutomationDetailsComponent from '../../components/automation-log-details';
import PageHeader from '../../components/page-header';
import { getAutomationLogDetails } from '../../store/features/automationsSlice';
import { AutomationLogDetailsWrapper } from '../../styles/pages/automation-log-details';
const AutomationLogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getAutomationLogDetails({
        automationLogId: id,
      }),
    );
  });
  return (
    <AutomationLogDetailsWrapper className="flex-1 flex flex-column w-full h-full">
      <PageHeader
        names={[
          { text: 'Platforms', onClick: () => {} },
          { text: 'Automations Log', path: '/platforms/automation-log' },
          { text: id, onClick: () => {} },
        ]}
      />
      <AutomationDetailsComponent />
    </AutomationLogDetailsWrapper>
  );
};

export default AutomationLogDetails;
