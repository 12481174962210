import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MailIcon } from '../../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/images/mobile.svg';
import { ReactComponent as UsersIcon } from '../../../../assets/images/users.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import { removeAccountData } from '../../../../store/features/groupsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { GroupAccountItemWrapper } from '../../../../styles/components/group-details/group-detail.styled';
import Menu from '../../../common/menu';
import { Tooltip } from '../../../tooltip/tooltip';

const AccountItem = ({ account, groupDetails, getAccountListData }) => {
  const { t } = useTranslation();
  const { checkPermission } = useContext(OrganisationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const ConfirmRemoveAccount = async () => {
    if (loading) return;
    setLoading(true);
    dispatch(removeAccountData({ organization_id: account?.id }))
      .then(() => {
        setLoading(false);
        dispatch(
          addToast({
            error: false,
            text: 'Remove Organization',
          }),
        );
        getAccountListData(0, false);
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          addToast({
            error: true,
            text: 'Error while Remove Organization',
          }),
        );
      });
  };

  return (
    <GroupAccountItemWrapper
      className="w-full list-container border-bottom py-2 cursor"
      onClick={() =>
        checkPermission(() => navigate(`account-details/${account.id}/prize-draw-access`), 'ORGANIZATIONS_VIEW')
      }>
      <div className="border-right flex-column items-start px-6 overflow-hidden justify-center w-full">
        <label className="regular-text font-14 one-line w-full">{account?.name}</label>
        <div className="flex items-center justify-center category-container mt-2">
          <label className="medium-text one-line lighter-text font-10">{groupDetails?.tag?.name}</label>
        </div>
      </div>
      <div className="border-right flex-column items-start px-6 justify-center w-full">
        <div className="flex items-center justify-start w-full">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MailIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label
            className={`regular-text one-line font-14 ml-2 flex-1 ${
              account?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
            }`}>
            {account?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value
              ? account?.contact_details?.find(data => data?.contact_type === 'EMAIL')?.contact_value
              : 'Not provided'}
          </label>
        </div>
        <div className="flex items-center justify-start w-full mt-1">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MobileIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label
            className={`regular-text one-line font-14 ml-2 flex-1 ${
              account?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value ? '' : 'grey-text'
            }`}>
            {account?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value
              ? account?.contact_details?.find(data => data?.contact_type === 'MOBILE')?.contact_value
              : 'Not provided'}
          </label>
        </div>
      </div>
      <div className="border-right flex items-center px-6 justify-start w-full">
        <label className="regular-text font-14 text-overflow-2">{account?.primary_location?.formatted_address}</label>
      </div>
      <div className="flex items-center px-6 justify-between w-full">
        <Tooltip content={<label className="regular-text font-12">Users</label>} placement={'bottom-start'}>
          <div className="flex items-center justify-start flex-1">
            <UsersIcon />
            <label className="regular-text font-14 ml-2">{account?.users || 0}</label>
          </div>
        </Tooltip>
        <div className="flex items-center justify-start mr-2">
          {(account?.parent_organization || [])?.length > 0 && (
            <Tooltip
              content={
                <div className="flex-column items-start justify-start abbrivation-tooltip-container ">
                  <div className="flex items-center justify-start w-auto">
                    <label className="medium-text font-12 flex-1 one-line">
                      {account?.parent_organization[0]?.name}
                    </label>
                    <div className="flex items-center justify-center abbrivation-container ml-2 px-2 py-0">
                      <label className="regular-text zen-default-text font-10">
                        {account?.parent_organization[0]?.code}
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center justify-start w-full mt-1">
                    <label className="regular-text lighter-text font-12 break-word wrap">
                      {`Created this account in ${account?.category?.name} group`}
                    </label>
                  </div>
                </div>
              }
              placement={'bottom-start'}>
              <div className="flex items-center justify-center px-2 py-0 abbrivation-container cursor">
                <label className="medium-text font-10 zen-default-text">{account?.parent_organization[0]?.code}</label>
              </div>
            </Tooltip>
          )}
          <Menu
            menuList={[
              {
                name: t('DETAILS'),
                onClick: () => navigate(`account-details/${account.id}/prize-draw-access`),
                permission: 'ORGANIZATIONS_VIEW',
              },
              ...(account?.users === 0
                ? [
                    {
                      name: t('REMOVE'),
                      onClick: () => {
                        ConfirmRemoveAccount();
                      },
                    },
                  ]
                : []),
            ]}
          />
        </div>
      </div>
    </GroupAccountItemWrapper>
  );
};

export default AccountItem;
