import React from 'react';

import FeesDetails from './fees-details';
const Fees = ({ setOrderBy, setSortedBy, sortedBy, orderBy, loading = false, search = '' }) => {
  return (
    <div className="pt-2 flex-1 flex overflow-scroll">
      <FeesDetails
        setSortedBy={setSortedBy}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        sortedBy={sortedBy}
        loading={loading}
        search={search}
      />
    </div>
  );
};
export default Fees;
