import ReactECharts from 'echarts-for-react';
import React from 'react';

const BarChartV2 = ({ seriesData, xaxisData, seriesCount }) => {
  const customColors = ['#F8B133', '#7BB2FD', '#E8D1FF', '#8927EF', '#EF7ABD', '#29CC97', '#2B2B7C', '#D0D5DD'];
  const reversedSeriesData = seriesData && [...seriesData]?.reverse();
  const customTooltipFormatter = params => {
    const ageValue = params[0].axisValueLabel;
    const value = seriesCount[params[0].dataIndex];
    const SubtitleTooltip = params[0].seriesName;
    return `
    <div  style="min-width:120px; text-align:center; border-radius: 12px; ">
          <p class="medium-text py-1 font-12" style="background-color: #E8D1FF; color: #8927EF ;  border-radius: 12px 12px 0 0;">
          ${ageValue}
          </p>
          <div class="px-2 py-1  flex-column">
            <label class="flex justify-center items-center flex-1 semibold-text font-12">${value}</label>
            <label class="flex justify-center items-center flex-1 semibold-text font-12">${SubtitleTooltip}</label>

          </div>
        </div>`;
  };

  const options = {
    tooltip: {
      trigger: 'axis',
      formatter: customTooltipFormatter,
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
    },
    grid: [
      {
        left: 5,
        top: 10,
        width: '99%',
        containLabel: true,
      },
    ],
    xAxis: {
      type: 'value',
      show: false,
    },

    yAxis: [
      {
        type: 'category',
        data: xaxisData,
        show: true,
        position: 'left',
        inverse: true,
        axisLine: {
          show: false,
        },
        z: 3,
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#6B7280',
          inside: false,
          show: true,
          margin: 15,
        },
        nameLocation: 'start',
      },
      {
        type: 'category',
        data: reversedSeriesData,
        position: 'right',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          inside: false,
          show: true,
          fontSize: '12px',
          fontFamily: 'Poppins',
          color: '#16192C',
          margin: 15,
          fontWeight: '600',
          formatter: function (params) {
            const value = parseFloat(params);
            return value.toFixed(0) + '%';
          },
        },
      },
    ],

    series: [
      {
        name: 'Entrants',
        type: 'bar',
        data: seriesData,
        barWidth: '70%',
        showBackground: true,
        backgroundStyle: {
          color: '#F5F9FC',
          borderRadius: 4,
        },
        label: {
          show: false,
        },
        itemStyle: {
          color: function (params) {
            return customColors[params.dataIndex];
          },
          borderRadius: 4,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      <ReactECharts option={options} type="bar" height={210} width={'100%'} />
    </div>
  );
};

export default BarChartV2;
