import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import uuid from 'react-uuid';
import { ReactComponent as PlusIcon } from '../assets/images/plus.svg';
import { ReactComponent as EmptyImage } from '../assets/images/subtract.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/trash-red.svg';
import Loader from '../components/common/loader';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { uppercase } from '../helpers/utils';
import { createPrizeDraw } from '../store/features/prizeDrawSlice';
import { addToast } from '../store/features/toastSlice';
import { uploadMedia } from '../store/services/media';
import CommonPopup from './common-popup';
import { AddPrizedrawWrapper } from './modal.styled';

const AddPrizedraw = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { handleOnSuccess } = modal.content;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef();

  const [loading, setLoading] = useState(false);
  const [prizedraw, setPrizeDraw] = useState({});
  const [error, setError] = useState({});
  const [isProfileUploading, setIsProfileUploading] = useState(false);
  const [prizeDrawData, setPrizeDrawData] = useState();
  const navigate = useNavigate();

  const checkPrizeDrawErrors = () => {
    if (!prizedraw?.name?.trim() || !prizedraw?.description?.trim() || !prizedraw?.code?.trim()) {
      setError({
        name: !prizedraw?.name?.trim(),
        description: !prizedraw?.description?.trim(),
        code: !prizedraw?.code?.trim(),
      });
      dispatch(addToast({ error: true, text: 'Please fill prizedraw name, description and code' }));
      return true;
    } else if (prizedraw?.code.trim().length !== 3) {
      setError({
        ...error,
        code: true,
      });
      dispatch(addToast({ error: true, text: 'Please enter valid prizedraw code' }));
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkPrizeDrawErrors()) {
      return;
    }
    setLoading(true);
    let imageData = {};
    let request = {
      name: prizedraw?.name,
      description: prizedraw?.description,
      code: `${prizedraw?.code}`,
      image: { id: '' },
    };
    if (prizedraw?.img) {
      try {
        imageData = await uploadMedia(prizedraw.img);
        request = { ...request, image: { id: imageData?.media_external_id } };
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Error while uploading image', id: uuid() }));
        return;
      }
    }
    dispatch(createPrizeDraw({ request }))
      .then(data => {
        setPrizeDrawData(data);
        setLoading(false);
        handleOnSuccess && handleOnSuccess(data);
        setModal(initModal);
      })
      .catch(() => {
        setLoading(false);
        dispatch(addToast({ error: true, text: 'Something went wrong' }));
      });
  };

  const emptyImageContent = (
    <div className="img-container border flex items-center justify-center radius-4 empty-img-content cursor">
      <input
        className="display-none"
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={e => {
          setPrizeDraw({ ...prizedraw, img: e.target.files[0], url: window.URL.createObjectURL(e.target.files[0]) });
        }}
      />
      <EmptyImage className="empty-img" />
      <div className="add-img-btn radius-2 flex items-center justify-center" onClick={() => inputRef.current.click()}>
        <PlusIcon />
      </div>
    </div>
  );

  const imageContent = (
    <div className="flex items-center justify-center radius-4 cursor img-data-wrapper relative">
      <div
        className="delete-btn-wrapper absolute flex items-center justify-center radius-2"
        onClick={() => setPrizeDraw({ ...prizedraw, img: null, url: null })}>
        <DeleteIcon width={14} height={14} />
      </div>
      <img
        className="flex items-center justify-center radius-2"
        src={prizedraw?.url}
        style={{ objectFit: 'cover', height: '64px', width: '64px' }}
      />
    </div>
  );

  return (
    <CommonPopup
      popupTitle="Add Prize Draw"
      confirmButtonProps={{ label: 'Add' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onDone}
      disabled={loading}>
      {loading ? (
        <Loader />
      ) : (
        <AddPrizedrawWrapper className="flex-column items-center w-full">
          <div className="flex items-start w-full col-gap-4 mt-6">
            {prizedraw?.img ? imageContent : emptyImageContent}
            <div className="flex-column flex-1" style={{ width: 'calc(100% - 80px)' }}>
              <label className="regular-text lighter-text mb-1 font-12">{t('NAME')}</label>
              <input
                autoComplete="turnoff"
                className={`input ${error?.name && 'error-border'}`}
                onChange={e => {
                  setPrizeDraw({ ...prizedraw, name: e.target.value });
                  setError({ ...error, name: false });
                }}
                placeholder="Prize draw name"
                value={prizedraw?.name || ''}
              />
            </div>
          </div>
          <div className="w-full flex-column mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('DESCRIPTION')}</label>
            <textarea
              className={`textarea ${error?.description && 'error-border'}`}
              onChange={e => {
                setPrizeDraw({ ...prizedraw, description: e.target.value });
                setError({ ...error, description: false });
              }}
              placeholder="Enter description"
              rows={4}
              value={prizedraw?.description || ''}
            />
          </div>
          <div className="flex-column w-full mt-6">
            <label className="regular-text lighter-text mb-1 font-12">{t('PRIZE_DRAW_CODE')}</label>
            <div className={`flex items-center relative `}>
              <MaskedInput
                mask={[/[A-Z, a-z]/, /[A-Z, a-z]/, /[A-Z,a-z]/]}
                value={prizedraw?.code || null}
                className={`input w-full ${error?.code && 'error-border'}`}
                placeholder={'ABC-001'}
                onChange={({ target: { value } }) => {
                  setPrizeDraw({ ...prizedraw, code: uppercase(value) });
                  setError({ ...error, code: false });
                }}
                guide={false}
              />
              {prizedraw?.code ? <label className="absolute normal-text font-16 numeric-id">-001</label> : <></>}
            </div>
          </div>
        </AddPrizedrawWrapper>
      )}
    </CommonPopup>
  );
};

export default AddPrizedraw;
