import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as RightArrowIcon } from '../../assets/images/arrow-right.svg';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { uppercase } from '../../helpers/utils';
import { createProductFees, createdProducts, getProductList } from '../../store/features/billingSlice';
import { getIntegrationsList } from '../../store/features/integrationsSlice';
import { getOrganizationNetworks } from '../../store/features/prizeDrawSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';
import { AddProductFeesWrapper } from '../modal.styled';
import FeeInfo from './fee-info';
import Product from './product';

const enumSteps = {
  product: 'PRODUCT',
  feeInfo: 'FEE INFO',
};

const AddProductFees = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setModal, modal } = useContext(OrganisationContext);
  const { handleOnSuccess } = modal.content;
  const [loading, setLoading] = useState(false);
  const [fees, setfees] = useState({ feeInfo: { start: moment().unix() } });
  const [error, setError] = useState({});
  const [currentStep, setCurrentStep] = useState(enumSteps.product);
  const clientPage = useRef(0);
  const productPage = useRef(0);

  const checkProductDetailsErrors = () => {
    if (
      !fees?.productDetails?.client?.id ||
      !fees?.productDetails?.integration?.id ||
      !fees?.productDetails?.product?.external_reference ||
      !fees?.productDetails?.pricing?.external_reference
    ) {
      setError({
        ...error,
        productDetails: {
          client: fees?.productDetails?.client?.id ? false : true,
          integration: fees?.productDetails?.integration?.id ? false : true,
          product: fees?.productDetails?.product?.external_reference ? false : true,
          pricing: fees?.productDetails?.pricing?.external_reference ? false : true,
        },
      });
      const errMessage =
        !fees?.productDetails?.client?.id &&
        !fees?.productDetails?.integration?.id &&
        !fees?.productDetails?.product?.external_reference &&
        fees?.productDetails?.pricing?.external_reference
          ? 'Please select client, integration and product'
          : fees?.productDetails?.client?.id &&
            !fees?.productDetails?.integration?.id &&
            !fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select integration, product and pricing'
          : !fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            !fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select client, product and pricing'
          : !fees?.productDetails?.client?.id &&
            !fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select client, integration and pricing'
          : !fees?.productDetails?.client?.id &&
            !fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            fees?.productDetails?.pricing?.external_reference
          ? 'Please select client and integration'
          : !fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            !fees?.productDetails?.product?.external_reference &&
            fees?.productDetails?.pricing?.external_reference
          ? 'Please select client and product'
          : !fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select client and pricing'
          : fees?.productDetails?.client?.id &&
            !fees?.productDetails?.integration?.id &&
            !fees?.productDetails?.product?.external_reference &&
            fees?.productDetails?.pricing?.external_reference
          ? 'Please select integration and product'
          : fees?.productDetails?.client?.id &&
            !fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select integration and pricing'
          : fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            !fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select product and pricing'
          : !fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            fees?.productDetails?.pricing?.external_reference
          ? 'Please select client'
          : fees?.productDetails?.client?.id &&
            !fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            fees?.productDetails?.pricing?.external_reference
          ? 'Please select integration'
          : fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            !fees?.productDetails?.product?.external_reference &&
            fees?.productDetails?.pricing?.external_reference
          ? 'Please select product'
          : fees?.productDetails?.client?.id &&
            fees?.productDetails?.integration?.id &&
            fees?.productDetails?.product?.external_reference &&
            !fees?.productDetails?.pricing?.external_reference
          ? 'Please select pricing'
          : 'Please input the required fields.';
      dispatch(addToast({ error: true, text: errMessage }));
      return false;
    } else {
      return true;
    }
  };

  const checkFeeInforErrors = () => {
    const { feeInfo } = fees;
    if (!feeInfo.billing?.value || !feeInfo.start || !feeInfo?.amount?.trim()) {
      setError({
        ...error,
        feeInfo: {
          billing: !feeInfo?.billing?.value,
          start: !feeInfo?.start,
          amount: !feeInfo?.amount?.trim(),
        },
      });
      dispatch(addToast({ error: true, text: 'please input the required fields.' }));
      return false;
    } else {
      return true;
    }
  };

  const checkErrors = () => {
    switch (currentStep) {
      case enumSteps.product: {
        const isValid = checkProductDetailsErrors();
        return isValid;
      }
      case enumSteps.feeInfo: {
        const isValid = checkFeeInforErrors();
        return isValid;
      }
    }
  };

  const handleTabNavigation = tab => {
    switch (tab) {
      case enumSteps.product: {
        setCurrentStep(enumSteps.product);
        break;
      }
      case enumSteps.feeInfo: {
        const isValid = checkProductDetailsErrors();
        if (isValid) {
          setCurrentStep(enumSteps.feeInfo);
        }
        break;
      }
    }
  };

  const tabs = (
    <div className="flex items-center jusity-center col-gap-4 pt-8 pb-4">
      <label
        onClick={() => handleTabNavigation(enumSteps.product)}
        className={`semibold-text font-12 cursor ${currentStep === enumSteps.feeInfo ? 'grey-text' : ''}`}>
        {uppercase('Product')}
      </label>
      <RightArrowIcon color={currentStep === enumSteps.product ? theme.colors.RegularText : theme.colors.zenGray} />
      <label
        onClick={() => handleTabNavigation(enumSteps.feeInfo)}
        className={`semibold-text font-12 cursor ${currentStep === enumSteps.product ? 'grey-text' : ''}`}>
        {uppercase('fee info')}
      </label>
    </div>
  );

  const handleSubmitData = async () => {
    const {
      productDetails: { client, integration, product, pricing },
      feeInfo,
    } = fees;
    if (loading) {
      return;
    }
    setLoading(true);
    const request = {
      organization: { id: client?.id },
      integration: { id: integration?.id },
      client_product: {
        product: {
          id: product?.external_reference,
          name: product?.name,
          description: product?.description,
        },
        pricing: {
          id: pricing?.external_reference,
          price: pricing?.price,
          currency_code: pricing?.currency_code,
          recurring_period: uppercase(pricing?.recurring_period),
        },
        image: product?.image?.id
          ? {
              id: product?.image?.id,
              url: product?.image?.url,
            }
          : null,
      },
    };
    const feesRequest = {
      billing_type: feeInfo?.billing?.value ? feeInfo?.billing?.value : null,
      fee_amount: feeInfo?.amount ? String(feeInfo?.amount).replaceAll('$', '') : null,
      dates: { start: feeInfo?.start ? feeInfo?.start : moment().unix() },
    };
    dispatch(createdProducts({ request: request }))
      .then(async data => {
        dispatch(createProductFees({ request: feesRequest, productId: data?.id }))
          .then(() => {
            handleOnSuccess && handleOnSuccess();
            setModal(initModal);
            setLoading(false);
          })
          .catch(err => {
            dispatch(addToast({ error: true, text: err?.response?.data?.error_description || 'Something Went Wrong' }));
            setLoading(false);
          });
      })
      .catch(err => {
        dispatch(addToast({ error: true, text: err?.response?.data?.error_description || 'Something Went Wrong' }));
        setLoading(false);
      });
  };

  const handleBackButton = () => {
    switch (currentStep) {
      case enumSteps.product: {
        setModal(initModal);
        break;
      }
      case enumSteps.feeInfo: {
        setCurrentStep(enumSteps.product);
        break;
      }
    }
  };

  const handleNextButton = () => {
    switch (currentStep) {
      case enumSteps.product: {
        if (checkErrors()) {
          setCurrentStep(enumSteps.feeInfo);
        }
        break;
      }
      case enumSteps.feeInfo: {
        if (checkErrors()) {
          handleSubmitData();
        }
        break;
      }
    }
  };

  const handleChange = (field, value) => {
    let updatedData = { ...fees };
    let updatedError = { ...error };
    switch (currentStep) {
      case enumSteps.product: {
        if (field === 'client') {
          updatedData = {
            ...updatedData,
            productDetails: { ...updatedData?.productDetails, integration: null, product: null, [field]: value },
          };
        } else if (field === 'integration') {
          updatedData = {
            ...updatedData,
            productDetails: { ...updatedData?.productDetails, product: null, [field]: value },
          };
        } else {
          updatedData = {
            ...updatedData,
            productDetails: { ...updatedData?.productDetails, [field]: value },
          };
        }
        updatedError = {
          ...error,
          productDetails: { ...error?.productDetails, [field]: false },
        };
        break;
      }
      case enumSteps.feeInfo: {
        updatedData = { ...updatedData, feeInfo: { ...updatedData?.feeInfo, [field]: value } };
        updatedError = {
          ...error,
          feeInfo: { ...error?.feeInfo, [field]: false },
        };
        break;
      }
    }
    setfees({ ...updatedData });
    setError(updatedError);
  };

  const getClientList = (page = 0, merge = false) => {
    dispatch(
      getOrganizationNetworks({
        param: {
          page: page,
          category_tag: 'CLIENT',
        },
        merge: merge,
      }),
    );
  };

  const fetchProducts = (page = 0, merge = false) => {
    dispatch(getProductList({ params: { integration_id: fees?.productDetails?.integration?.id, page: page }, merge }));
  };

  useEffect(() => {
    getClientList(0, false);
  }, []);

  useEffect(() => {
    if (fees?.productDetails?.client)
      dispatch(
        getIntegrationsList({ params: { organization_id: fees?.productDetails?.client?.id, action: 'PRODUCT_LIST' } }),
      );
  }, [fees?.productDetails?.client]);

  useEffect(() => {
    if (fees?.productDetails?.integration) {
      fetchProducts(0, false);
    }
  }, [fees?.productDetails?.integration]);

  useEffect(() => {
    setfees({ ...fees, productDetails: { ...fees.productDetails, pricing: null } });
  }, [fees?.productDetails?.product]);

  const getContentPage = () => {
    switch (currentStep) {
      case enumSteps.product:
        return (
          <Product
            error={error?.productDetails}
            productDetails={fees?.productDetails}
            fetchClientList={() => {
              getClientList(clientPage.current + 1, true);
              clientPage.current = clientPage.current + 1;
            }}
            handleChange={(field, value) => handleChange(field, value)}
            fetchProductList={() => {
              fetchProducts(productPage.current + 1, true);
              productPage.current = productPage.current + 1;
            }}
          />
        );
      case enumSteps.feeInfo:
        return (
          <FeeInfo
            error={error?.feeInfo}
            feeInfo={fees?.feeInfo}
            handleChange={(field, value) => handleChange(field, value)}
          />
        );
    }
  };

  return (
    <CommonPopup
      popupTitle={'Add product fee'}
      disabled={loading}
      onConfirm={() => handleNextButton()}
      onCancel={() => handleBackButton()}
      cancelButtonProps={{ label: currentStep === enumSteps.product ? t('CANCEL') : t('BACK') }}
      confirmButtonProps={{ label: currentStep === enumSteps.feeInfo ? t('ADD') : t('NEXT') }}>
      <AddProductFeesWrapper className="w-full flex-column">
        <div className="flex items-center justify-center border-bottom">{tabs}</div>
        <div className="flex-column items-center w-full">{getContentPage()}</div>
      </AddProductFeesWrapper>
    </CommonPopup>
  );
};

export default AddProductFees;
