import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const SubmissionPDFDownload = ({ log = {} }) => {
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader icon={<BuildingIcon />} title={'Form PDF downloaded'} />
      <LogTitle
        showDownIcon={false}
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('DOWNLOADED_ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {log?.event_data?.entrant?.urn}
            </span>
            <span className="regular-text lighter-text font-12 ml-1">{t('ENTRY_FORM_PDF')}</span>
          </span>
        }
      />
    </div>
  );
};

export default SubmissionPDFDownload;
