import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { ReactComponent as EditorLinkIcon } from '../../../assets/images/editor-link.svg';

const CustomToolbar = ({ showLink = false, onLink }) => {
  return (
    <div id="toolbar">
      {showLink && (
        <button className="ql-insertLink link-insert" data-tooltip-id="link-selector" onClick={onLink}>
          <EditorLinkIcon />
        </button>
      )}
    </div>
  );
};

const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {},
  },
};

const Editor = ({ value, setValue, placeholder = '', showLink = false, onLink, disableInput = false }) => {
  const quillRef = useRef(null);

  return (
    <EditorWrapper className="mb-8 border border radius-3">
      <ReactQuill
        ref={quillRef}
        className="regular-text font-16"
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        placeholder={placeholder}
        onKeyDown={e => disableInput && e.preventDefault()}
      />
      <CustomToolbar showLink={showLink} onLink={() => onLink(quillRef.current)} />
    </EditorWrapper>
  );
};

export const EditorWrapper = styled.div`
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.ZenPurpleSecondary};
  }

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.zenPurple};
  }

  .ql-snow {
    border: unset;

    button {
      width: 26px;
      border-radius: 6px;

      :hover {
        background-color: ${({ theme }) => theme.colors.ZenPurpleSecondary2};
        color: ${({ theme }) => theme.colors.zenPurple};
      }
    }

    a {
      color: ${({ theme }) => theme.colors.zenPurple};
    }

    .ql-tooltip {
      left: 0 !important;
      border-radius: 8px;
      padding: 8px;
      border: unset;
      box-shadow: 0px 4px 10px 0px rgba(9, 19, 53, 0.1);
      font-family: 'Poppins Regular';
      font-size: 12px;

      &::before {
        content: '';
      }

      .ql-preview {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.RegularText};
      }

      input {
        height: 32px;
        border: 1px solid rgba(181, 185, 193, 0.3);
        border-radius: 4px;
        color: ${({ theme }) => theme.colors.RegularText};
        font-family: Poppins Regular;
        font-size: 14px;
        outline: none;
        width: 200px;
      }
    }
  }

  .quill {
    .ql-editor {
      font-family: 'Poppins Regular';
      font-size: 16px;
    }

    .ql-blank::before {
      color: ${({ theme }) => theme.colors.RegularText};
      opacity: 0.3;
      font-style: normal;
    }
  }

  .ql-toolbar {
    border: unset;
    border-radius: 0 0 16px 16px;
    padding: 4px 8px;
    display: flex;
  }
`;

export default Editor;
