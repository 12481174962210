import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizedrawAdded = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Prize Draw added'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('ADDED_PRIZE_DRAW')}</span>
            <span className="regular-text zen-purple-text cursor  font-12 ml-1">{event_data?.prizedraw?.code}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <LogContentBox
          title={'Prize Draw'}
          contentText={<p className="flex regular-text lighter-text font-12">{event_data?.prizedraw?.name}</p>}
        />
      )}
    </div>
  );
};

export default PrizedrawAdded;
