import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../assets/images/burger.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { PrizeDrawDetailHeaderWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Menu from '../common/menu';

const PrizeDrawDetailHeader = ({ title, titleContent = null, actionContent = null }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = useContext(OrganisationContext);
  const { t } = useTranslation();

  const onMenuClick = navigateTo => {
    navigate(`/prize-draws/all/${id}/${navigateTo}`);
  };

  const menuItemsWithPermission = [
    { key: 'ENTRANTS_VIEW', name: t('ENTRANTS'), onClick: () => onMenuClick('entrants'), priority: 1 },
    { key: 'FORMS_VIEW_LIST', name: t('FORMS'), onClick: () => onMenuClick('forms'), priority: 2 },
    { key: 'PRIZEDRAWS_LOG_VIEW', name: t('RECENT_ACTIVITY'), onClick: () => {}, priority: 3 },
    {
      key: 'PRIZEDRAWS_CONFIG_VIEW',
      name: t('CONFIGURATION'),
      onClick: () => onMenuClick('draw-settings'),
      priority: 4,
    },
    {
      key: 'PRIZEDRAWS_DRAWCONFIG_VIEW',
      name: t('DRAW_CONFIGURATION'),
      onClick: () => onMenuClick('draw-configuration'),
      priority: 5,
    },
    {
      key: 'PRIZEDRAWS_ORGANIZATIONS_VIEW',
      name: t('ORGANIZATIONS'),
      onClick: () => onMenuClick('organizations'),
      priority: 6,
    },
    { key: 'PRIZEDRAW_CLIENTS_VIEW', name: t('CLIENTS'), onClick: () => onMenuClick('clients'), priority: 7 },
    { key: 'PRIZEDRAW_LOG_VIEW', name: t('LOGS'), onClick: () => onMenuClick('logs'), priority: 8 },
  ];

  const filteredMenuItems = menuItemsWithPermission.filter(permission => hasPermission(permission.key));

  const menuList = [...filteredMenuItems].sort((a, b) => a.priority - b.priority);

  return (
    <PrizeDrawDetailHeaderWrapper className="items-center justify-between w-full pl-6 pr-4 py-4 border-bottom">
      <div className="flex items-center justify-start mr-2">
        <label className="medium-text font-20">{title}</label>
        {titleContent}
      </div>
      {actionContent}
      <Menu Icon={HamburgerIcon} menuList={menuList} tooltipContent={'Menu'} />
    </PrizeDrawDetailHeaderWrapper>
  );
};

export default PrizeDrawDetailHeader;
