import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DropDown from '../../components/McDropdown';
import { getCurrencySymbol, getRecurringPeriod } from '../../helpers/utils';

const RenderPrizeDrawOption = props => {
  const { option: value, handleSelect, productDetails, index } = props;
  return (
    <div
      className={`flex items-center justify-between px-2 py-1 prizedraw-option cursor radius-2 ${
        index > 0 ? 'mt-1' : ''
      } ${value?.id === productDetails?.integration?.id ? 'selected-option' : ''}`}
      onClick={() => handleSelect(value)}>
      <div className="flex items-center flex-1">
        <div className="flex items-center justify-center radius-2 integration-img-wrapper">
          <img className="flex items-center justify-center integration-img" src={value?.connector?.icon?.active} />
        </div>
        <label className="regular-text font-16 flex-1 ml-2 label-txt">{value?.name}</label>
      </div>
    </div>
  );
};

const RenderPrizedrawProduct = props => {
  const { option, handleSelect, productDetails, index } = props;
  return (
    <div
      className={`flex-column pxy-2 row-gap-1 radius-2 prizedraw-product-option cursor ${index > 0 ? 'mt-1' : ''} ${
        option?.external_reference === productDetails?.product?.external_reference ? 'selected-product-option' : ''
      }`}
      onClick={() => handleSelect(option)}>
      <label className="regular-text product-option-text">{option?.name}</label>
      <span className="sub-option-text regular-text font-12 grey-text">
        <span>{option?.external_reference}</span>
        {option?.pricing.length ? (
          <span className="ml-2">{`${getCurrencySymbol(option?.pricing[0]?.currency_code)}${
            option?.pricing[0]?.price
          } ${getRecurringPeriod(option?.pricing[0]?.recurring_period)}`}</span>
        ) : (
          <></>
        )}
      </span>
    </div>
  );
};

const Product = ({
  productDetails = {},
  handleChange = () => {},
  error = {},
  fetchClientList = () => {},
  fetchProductList = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { prizeDrawOrganizationNetworks } = useSelector(state => state.prizeDraw);
  const { integrationsList } = useSelector(state => state.integrations);
  const { productsList } = useSelector(state => state.billing);

  useEffect(() => {
    if (productsList?.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [productsList?.length > 0]);

  const integrationSelectedValue = value => {
    return (
      <div className={`flex items-center justify-between cursor w-full`}>
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-center radius-2 integration-img-wrapper">
            <img className="flex items-center justify-center integration-img" src={value?.connector?.icon?.active} />
          </div>
          <label className="regular-text flex-1 ml-2 font-16">{value?.name}</label>
        </div>
      </div>
    );
  };

  const getProductPricingList = () => {
    const pricingLists = productDetails?.product?.pricing || [];
    const formatedPricingList = pricingLists.map(data => ({
      ...data,
      name: `${getCurrencySymbol(data?.currency_code)} ${data?.price} ${getRecurringPeriod(data?.recurring_period)}`,
    }));
    return formatedPricingList;
  };

  return (
    <div className="flex-column items-center justify-center w-full row-gap-6">
      <div className="w-full flex-column mt-6">
        <label className="regular-text lighter-text font-12 mb-1">{t('CLIENT')}</label>
        <DropDown
          className={`provider-selector ${error?.client && 'error-border'}`}
          setSelected={option => handleChange('client', option)}
          options={prizeDrawOrganizationNetworks?.content}
          placeholder="Select"
          selected={productDetails.client || null}
          hasMore={!prizeDrawOrganizationNetworks?.last}
          isPaged={true}
          fetchData={() => fetchClientList()}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('INTEGRATION')}</label>
        <DropDown
          className={`provider-selector ${error?.integration && 'error-border'}`}
          setSelected={option => handleChange('integration', option)}
          options={integrationsList}
          placeholder="Select"
          selected={productDetails?.integration || null}
          renderSelectedValue={selectedValue => integrationSelectedValue(selectedValue)}
          RenderOption={props => <RenderPrizeDrawOption {...props} productDetails={productDetails} />}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('PRODUCT')}</label>
        <DropDown
          className={`provider-selector ${error?.product && 'error-border'}`}
          setSelected={option => handleChange('product', option)}
          options={productsList?.content || []}
          placeholder="Select"
          selected={productDetails?.product || null}
          size="large"
          showLoader={loading}
          RenderOption={props => <RenderPrizedrawProduct {...props} productDetails={productDetails} />}
          hasMore={!productsList?.last}
          isPaged={true}
          fetchData={() => fetchProductList()}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text lighter-text font-12 mb-1">{t('PRICING')}</label>
        <DropDown
          className={`provider-selector ${error?.pricing && 'error-border'}`}
          setSelected={option => handleChange('pricing', option)}
          options={getProductPricingList()}
          placeholder="Select"
          selected={productDetails?.pricing || null}
        />
      </div>
    </div>
  );
};

export default Product;
