import styled from 'styled-components';

export const AutomationLogDetailsComponentWrapper = styled.div``;

export const AutomationLogDetailLeftWrapper = styled.div`
  .left-box-wrapper {
    height: fit-content;
    min-width: 350px;
    max-width: 350px;
    border-radius: 16px;
    max-height: 100%;
    overflow: hidden;
  }
`;

export const AutomationLogDetailsRightWrapper = styled.div`
  border-radius: 16px;
  max-height: 100%;

  .vertical-line {
    height: 24px;
    border-color: ${({ theme }) => theme.colors.ZenGray2};
  }
  .automation-log-action-wrapper {
    .header-border-left {
      border-left: 4px solid ${({ theme }) => theme.colors.ZenOrange};
    }

    .action-container {
      width: calc(100% - 24px);
    }

    .icon-wrapper {
      background: ${({ theme }) => theme.colors.background};
      border-radius: 6px;
      padding: 3px;
    }

    .retry-text {
      color: ${({ theme }) => theme.colors.zenPurple};
    }

    .sub-header-border {
      border: 1px solid ${({ theme }) => theme.colors.ZenGray2};
      width: calc(100% - 24px);
    }

    .schedule-conditon-wrapper {
      background: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  .mapping-data-wrapper {
    .data-wrapper {
      display: grid;
      grid-template-columns: 16px 1fr;
    }
  }
`;
