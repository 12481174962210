import React from 'react';
import { useSelector } from 'react-redux';
import { GroupsListWrapper } from '../../styles/components/groups-list/groups-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import GroupListItem from './group-list-item';

const GroupsList = ({ debouncedSearch, getGroupList = () => {} }) => {
  const { groups } = useSelector(state => state.groups);
  return (
    <GroupsListWrapper className="flex-column flex-1">
      {groups.content?.length > 0 ? (
        <div className="flex-column">
          {groups?.content?.map(group => (
            <GroupListItem key={group?.id} group={group} getGroupList={getGroupList} />
          ))}
        </div>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </GroupsListWrapper>
  );
};

export default GroupsList;
