import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as TrashRedIcon } from '../../assets/images/trash-red.svg';
import { stateOptions } from '../../constant/optionData';
import { OrganisationContext } from '../../context/organisationContext';
import { isDataChanged } from '../../helpers/utils';
import {
  getPrizeDrawSettings,
  setPrizeDrawSettings,
  updateExcludedStatesSettings,
  updatePrizeDrawSettings,
  updatePrizesSettings,
} from '../../store/features/prizeDrawSlice';
import { PrizeDrawSettingsWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import DropDown from '../McDropdown';
import Switch from '../common/switch';
import UpdateAction from '../common/update-action';
import PrizeDrawDetailHeader from './prize-draw-details-header';

const PrizeDrawSettings = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { prizeDrawSettings } = useSelector(state => state.prizeDraw);
  const [updateConfig, setUpdateConfig] = useState({});
  const [error, setError] = useState({});
  const { hasPermission } = useContext(OrganisationContext);

  useEffect(() => {
    dispatch(getPrizeDrawSettings({ prizedrawId: id }));
  }, [id]);

  const onCancelUpdateConfig = () => {
    setUpdateConfig({});
    setError({});
  };

  useEffect(() => {
    setError({});
  }, [updateConfig?.type]);

  const callUpdatePrizeDrawSettings = (request, previousSettings) => {
    dispatch(setPrizeDrawSettings({ ...prizeDrawSettings, ...request }));
    dispatch(updatePrizeDrawSettings({ prizedrawId: id, request }));
  };

  const checkSettingsErrors = (field, value, shouldBeGreaterThan = null, shouldBeLessThan = null) => {
    if (field === 'min_age' && (!value || (value && shouldBeLessThan && value > shouldBeLessThan))) {
      setError({
        type: 'MIN_AGE_TO_ENTER',
        text: !value ? 'Minimum age should not be empty' : 'Value has to be at least the minimum F2F allowed age',
      });
      return false;
    }
    if (field === 'F2F_ALLOWED_AGE_RANGE' && shouldBeGreaterThan && value < shouldBeGreaterThan) {
      setError({ type: 'F2F_ALLOWED_AGE_RANGE', text: 'Value has to be at least the minimum age to enter' });
      return false;
    }

    if (field === 'max_entries' && ((shouldBeGreaterThan && parseInt(value) <= shouldBeGreaterThan) || !value)) {
      setError({
        type: 'max_entries',
        text: !value ? 'Value has to be at least 1' : 'Value has to be greater than bonus entries per referral',
      });
      return false;
    }
    if (
      field === 'referral_bonus_entries' &&
      ((value && shouldBeLessThan && parseInt(value) >= shouldBeLessThan) || !value)
    ) {
      setError({
        type: 'referral_bonus_entries',
        text: !value ? 'Value has to be at least 1' : 'Value has to be less than Maximum permitted entries',
      });
      return false;
    }
    if (field === 'PRIZES' && !value?.some(v => v.amount && v.entries)) {
      setError({
        type: 'PRIZES',
        text: 'Minimum one prize should be available',
      });
      return false;
    }
    setError({});
    return true;
  };

  const checkF2FAgeSettingsError = (f2f_min_age, f2f_max_age, lastUpdated, shouldBeGreaterThan) => {
    if (shouldBeGreaterThan && parseInt(f2f_min_age) < parseInt(shouldBeGreaterThan)) {
      setError({
        type: 'F2F_ALLOWED_AGE_RANGE',
        field: 'f2f_min_age',
        text: 'Value has to be at least the minimum age to enter',
      });
      return false;
    }
    if (parseInt(f2f_min_age) >= parseInt(f2f_max_age)) {
      setError({
        type: 'F2F_ALLOWED_AGE_RANGE',
        field: lastUpdated || 'f2f_min_age',
        text:
          lastUpdated === 'f2f_max_age'
            ? 'Value has to be greater than the minimum F2F allowed age'
            : 'Value has to be less than the maximum F2F allowed age',
      });
      return false;
    }
    setError({});
    return true;
  };

  const onUpdatePrizeDrawSettings = (field, value) => {
    const { min_age, max_entries, allow_prorated_charges, referral_bonus_entries, f2f_min_age, f2f_max_age } =
      prizeDrawSettings;
    const previousSettings = { ...prizeDrawSettings };
    if (
      checkSettingsErrors(
        field,
        value,
        referral_bonus_entries,
        field === 'referral_bonus_entries' ? max_entries : f2f_min_age,
      )
    ) {
      const request = {
        min_age,
        max_entries,
        allow_prorated_charges,
        referral_bonus_entries,
        f2f_min_age,
        f2f_max_age,
        [field]: value,
      };
      callUpdatePrizeDrawSettings(request, previousSettings);
      setUpdateConfig({});
    }
  };

  const onUpdateAgeRange = () => {
    const { min_age, max_entries, allow_prorated_charges, referral_bonus_entries } = prizeDrawSettings;
    const previousSettings = { ...prizeDrawSettings };
    if (
      checkF2FAgeSettingsError(updateConfig?.f2f_min_age, updateConfig?.f2f_max_age, updateConfig?.lastUpdated, min_age)
    ) {
      const request = {
        min_age,
        max_entries,
        allow_prorated_charges,
        referral_bonus_entries,
        f2f_min_age: updateConfig?.f2f_min_age,
        f2f_max_age: updateConfig?.f2f_max_age,
      };
      callUpdatePrizeDrawSettings(request, previousSettings);
      setUpdateConfig({});
    }
  };

  const onUpdatePrizes = () => {
    const { prizes } = updateConfig;
    const request = prizes?.map(p => ({
      currency: 'USD',
      amount: p.amount || 0,
      entries: parseInt(p.entries || 0, 10),
    }));
    if (isDataChanged('PRIZES', request || [], prizeDrawSettings.prizes || [])) {
      if (checkSettingsErrors('PRIZES', request)) {
        dispatch(setPrizeDrawSettings({ ...prizeDrawSettings, prizes: request }));
        dispatch(updatePrizesSettings({ prizedrawId: id, request }));
        setUpdateConfig({});
      }
    } else {
      setUpdateConfig({});
    }
  };

  const onUpdateStatesExcluded = () => {
    const { excluded_states } = updateConfig;
    const uniqueStates = [...new Set(excluded_states.map(s => s.excluded?.abbreviation))].filter(x => x);
    if (isDataChanged('MULTI_VALUE', uniqueStates || [], prizeDrawSettings.excluded_states || [])) {
      dispatch(setPrizeDrawSettings({ ...prizeDrawSettings, excluded_states: uniqueStates }));
      dispatch(updateExcludedStatesSettings({ prizedrawId: id, request: uniqueStates }));
      setUpdateConfig({});
    } else {
      setUpdateConfig({});
    }
  };

  const minAgeInput = () => {
    return (
      <div className="flex items-center col-gap-1">
        <input
          className={`input font-14 mr-1 age-input ${error.type === 'MIN_AGE_TO_ENTER' && 'error-border'}`}
          value={updateConfig?.min_age || null}
          placeholder="1"
          type="number"
          inputMode="numeric"
          onChange={e => {
            setUpdateConfig({ ...updateConfig, min_age: e.target.value });
            setError({});
          }}
        />
        <span className="regular-text mx-1">{`years old`}</span>
      </div>
    );
  };

  const ageRangeInput = () => {
    return (
      <div className="flex items-center col-gap-1">
        <div className="flex items-center relative">
          <input
            className={`input font-14 mr-1 age-input ${updateConfig?.f2f_min_age && 'age-input-value'} ${
              error.type === 'F2F_ALLOWED_AGE_RANGE' && error.field === 'f2f_min_age' && 'error-border'
            }`}
            value={updateConfig?.f2f_min_age || null}
            placeholder="00"
            type="number"
            inputMode="numeric"
            onChange={e => {
              setUpdateConfig({ ...updateConfig, f2f_min_age: e.target.value, lastUpdated: 'f2f_min_age' });
              setError({});
            }}
          />
          {updateConfig?.f2f_min_age && <span className="regular-text grey-text absolute age-input-type">min</span>}
        </div>
        <span className="regular-text mx-1">{`-`}</span>
        <div className="flex items-center relative">
          <input
            className={`input font-14 mr-1 age-input ${updateConfig?.f2f_max_age && 'age-input-value'} ${
              error.type === 'F2F_ALLOWED_AGE_RANGE' && error.field === 'f2f_max_age' && 'error-border'
            }`}
            value={updateConfig?.f2f_max_age || null}
            placeholder="00"
            type="number"
            inputMode="numeric"
            onChange={e => {
              setUpdateConfig({ ...updateConfig, f2f_max_age: e.target.value, lastUpdated: 'f2f_max_age' });
              setError({});
            }}
          />
          {updateConfig?.f2f_max_age && <span className="regular-text grey-text absolute age-input-type">max</span>}
        </div>
        <span className="regular-text mx-1">{`years old`}</span>
      </div>
    );
  };

  const bonusReferralInput = (field, inputInfo) => {
    return (
      <div className="flex items-center col-gap-1">
        <input
          className={`input font-14 mr-1 age-input ${
            (error.type === 'max_entries' || error.type === 'referral_bonus_entries') && 'error-border'
          }`}
          value={updateConfig?.[field] || null}
          type="number"
          inputMode="numeric"
          placeholder="1"
          onChange={e => setUpdateConfig({ ...updateConfig, [field]: e.target.value })}
        />
        <span className="regular-text mx-1">{inputInfo}</span>
      </div>
    );
  };

  const prizesInput = () => {
    const { prizes } = updateConfig;

    return (
      <div className="flex-column col-gap-1">
        {prizes?.map(p => (
          <div className="flex items-center col-gap-1 mb-1 mt-1" key={p.id}>
            <span className="regular-text lighter-text mx-1">{`Prize amount:`}</span>
            <div className="flex items-center relative">
              <span className="regular-text absolute currency-icon">$</span>
              <input
                className={`input font-14 mr-1 pl-6 age-input prize-input ${
                  error.type === 'PRIZES' && !p.amount && 'error-border'
                }`}
                type="number"
                inputMode="numeric"
                value={p.amount || null}
                placeholder="00.00"
                onChange={e =>
                  setUpdateConfig({
                    ...updateConfig,
                    prizes: [...prizes.map(pr => (pr.id === p.id ? { ...p, amount: e.target.value } : pr))],
                  })
                }
              />
            </div>
            <span className="regular-text lighter-text mx-1">{`Number of entrants:`}</span>
            <input
              className={`input font-14 mr-1 age-input entry-input ${
                error.type === 'PRIZES' && !p.entries && 'error-border'
              }`}
              value={p.entries || null}
              type="number"
              inputMode="numeric"
              placeholder="0"
              onChange={e =>
                setUpdateConfig({
                  ...updateConfig,
                  prizes: [...prizes.map(pr => (pr.id === p.id ? { ...p, entries: e.target.value } : pr))],
                })
              }
            />
            {prizes?.length > 1 && (
              <div
                className="flex items-center justify-center ml-3 delete-icon cursor"
                onClick={() => {
                  setUpdateConfig({
                    ...updateConfig,
                    prizes: [...prizes.filter(pr => pr.id !== p.id)],
                  });
                }}>
                <TrashRedIcon height={16} width={16} />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const statesExcludeInput = () => {
    const { excluded_states } = updateConfig;
    return (
      <div className="flex-column col-gap-1">
        {excluded_states?.map(s => (
          <div className="flex items-center selector-dropdown state-dropdown mb-1 mt-1" key={s.id}>
            <DropDown
              options={stateOptions.filter(o =>
                s?.searchText ? o.name?.toLocaleLowerCase()?.includes(s?.searchText?.toLocaleLowerCase()) : true,
              )}
              selected={s?.excluded || null}
              removeSearchOnBlur
              setSelected={o => {
                setUpdateConfig(config => ({
                  ...config,
                  excluded_states: [
                    ...config.excluded_states.map(se => (se.id === s.id ? { ...se, excluded: o, searchText: '' } : se)),
                  ],
                }));
              }}
              placeholder="Select"
              isSearchable={true}
              setSearch={o => {
                setUpdateConfig(config => ({
                  ...updateConfig,
                  excluded_states: [
                    ...config.excluded_states.map(se => (se.id === s.id ? { ...se, searchText: o } : se)),
                  ],
                }));
              }}
              search={s?.searchText}
            />
            <div
              className="flex items-center justify-center delete-icon ml-4 cursor"
              onClick={() => {
                setUpdateConfig({
                  ...updateConfig,
                  excluded_states: [...excluded_states.filter(se => se.id !== s.id)],
                });
              }}>
              <TrashRedIcon height={16} width={16} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getTotalPrizeAmount = prizes => {
    const prizeAmount =
      prizes?.map(p => p.amount)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr), 0) || '00.00';
    return parseFloat(prizeAmount).toFixed(2);
  };

  const getTotalEntrants = prizes => {
    const totalEntries =
      prizes?.map(p => p.entries)?.reduce((acc, curr) => parseInt(acc, 10) + parseInt(curr, 10), 0) || '0';
    return parseInt(totalEntries, 10);
  };

  const renderPrizes = prizes => {
    return prizes.map((prize, index) => (
      <div className="flex mb-1 justify-end" key={index}>
        <div className="flex prize-entry-container mr-1">
          <span className="regular-text lighter-text mr-1">{t('PRIZE_AMOUNT')}</span>
          <span className="regular-text">{prize.amount ? `$${prize.amount} ${prize.currency}` : '-'}</span>
        </div>
        <div className="flex prize-entry-container">
          <span className="regular-text lighter-text mr-1">{t('NUMBER_OF_ENTRANTS')}</span>
          <span className="regular-text">{prize.entries ? `${prize.entries}` : '-'}</span>
        </div>
      </div>
    ));
  };

  const renderStatesExcludes = excluded_states => {
    return excluded_states.map(state => (
      <div className="flex mb-1 justify-end" key={state}>
        <div className="flex prize-entry-container mr-1">
          <span className="regular-text">{stateOptions.find(s => s.abbreviation === state)?.name || ''}</span>
        </div>
      </div>
    ));
  };

  return (
    <PrizeDrawSettingsWrapper className="flex-column items-start justify-start w-full h-full">
      <PrizeDrawDetailHeader
        title={t('CONFIGURATION')}
        titleContent={
          <>
            {/* <div className="ml-4 py-1 px-2 header-detail-container flex items-center justify-center">
              <label className="regular-text font-10 lighter-text w-full one-line">
                <span className="medium-text lighter-text font-10 mr-1">
                  ${getTotalPrizeAmount(prizeDrawSettings?.prizes || [])}
                </span>
                Prizes
              </label>
            </div>
            <div className="ml-2  py-1 px-2 header-detail-container flex items-center justify-center">
              <label className="regular-text font-10 lighter-text w-full one-line">
                <span className="medium-text lighter-text font-10 mr-1">
                  {getTotalEntrants(prizeDrawSettings?.prizes || [])}
                </span>{' '}
                Entrants
              </label>
            </div> */}
          </>
        }
      />
      <div className="flex-column pxy-6 w-full h-full flex-1 overflow-scroll">
        <div className="flex-column pb-6">
          <div className="border-bottom medium-text lighter-text font-16 pb-2 mb-2">{t('ENTRY_RESTRICTIONS')}</div>
          <div className="flex items-center justify-between py-2 relative">
            <div className="flex-column nowrap mr-4">
              <span className="normal-text lighter-text">{t('MIN_AGE_TO_ENTER')}</span>
            </div>
            <div>
              {error.type === 'MIN_AGE_TO_ENTER' && (
                <div className="error-container flex absolute">
                  <div className="border-left-content " />
                  <span className="regular-text font-12 pxy-1">{error.text}</span>
                </div>
              )}
              {updateConfig.type === 'MIN_AGE_TO_ENTER' && hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? (
                <div className="update-detail-contaner relative">
                  {minAgeInput()}
                  <UpdateAction
                    onCancel={() => onCancelUpdateConfig()}
                    onUpdate={() =>
                      isDataChanged(
                        'INPUT',
                        updateConfig?.min_age || '',
                        prizeDrawSettings?.min_age || '',
                        () => onUpdatePrizeDrawSettings('min_age', updateConfig?.min_age),
                        onCancelUpdateConfig,
                      )
                    }
                  />
                </div>
              ) : (
                <div
                  className={`flex items-center py-1 px-2 cursor ${
                    hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? 'hover-edit' : ''
                  } `}
                  onClick={() =>
                    setUpdateConfig({ type: 'MIN_AGE_TO_ENTER', min_age: prizeDrawSettings?.min_age || '' })
                  }>
                  {prizeDrawSettings?.min_age ? (
                    <span className="regular-text font-14 flex-1 mr-2">{prizeDrawSettings?.min_age} years old</span>
                  ) : (
                    <span className="normal-text font-16 flex-1 grey-text mr-2">{t('NO_DATA')}</span>
                  )}
                  {hasPermission('PRIZEDRAWS_CONFIG_MANAGE') && (
                    <EditIcon width={16} height={16} className="edit-icon" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between py-2 relative">
            <div className="flex-column nowrap mr-4">
              <span className="normal-text lighter-text">{t('F2F_ALLOWED_AGE_RANGE')}</span>
            </div>
            <div>
              {error.type === 'F2F_ALLOWED_AGE_RANGE' && (
                <div className="error-container flex absolute">
                  <div className="border-left-content " />
                  <span className="regular-text font-12 pxy-1">{error.text}</span>
                </div>
              )}
              {updateConfig.type === 'F2F_ALLOWED_AGE_RANGE' && hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? (
                <div className="update-detail-contaner relative">
                  {ageRangeInput()}
                  <UpdateAction
                    onCancel={() => onCancelUpdateConfig()}
                    onUpdate={() => {
                      if (
                        isDataChanged('INPUT', updateConfig?.f2f_min_age || '', prizeDrawSettings?.f2f_min_age || '') ||
                        isDataChanged('INPUT', updateConfig?.f2f_max_age || '', prizeDrawSettings?.f2f_max_age || '')
                      ) {
                        onUpdateAgeRange();
                      } else {
                        onCancelUpdateConfig();
                      }
                    }}
                  />
                </div>
              ) : (
                <div
                  className={`flex items-center py-1 px-2 cursor ${
                    hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? 'hover-edit' : ''
                  } `}
                  onClick={() =>
                    setUpdateConfig({
                      type: 'F2F_ALLOWED_AGE_RANGE',
                      f2f_min_age: prizeDrawSettings?.f2f_min_age,
                      f2f_max_age: prizeDrawSettings?.f2f_max_age,
                    })
                  }>
                  {prizeDrawSettings?.f2f_min_age || prizeDrawSettings?.f2f_max_age ? (
                    <span className="regular-text font-14 flex-1 mr-2">
                      {prizeDrawSettings?.f2f_min_age || '-'} to {prizeDrawSettings?.f2f_max_age || '-'} years old
                    </span>
                  ) : (
                    <span className="normal-text font-16 flex-1 grey-text mr-2">{t('NO_DATA')}</span>
                  )}
                  {hasPermission('PRIZEDRAWS_CONFIG_MANAGE') && (
                    <EditIcon width={16} height={16} className="edit-icon" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between py-2">
            <div className="flex-column nowrap mr-4">
              <span className="normal-text lighter-text">{t('ALLOW_PRORATED_CHARGES')}</span>
            </div>
            <div className="flex items-center">
              <span className="regular-text font-14 flex-1 mr-2">
                {prizeDrawSettings?.allow_prorated_charges ? t('ENABLED') : t('DISABLED')}
              </span>

              <Switch
                enabled={prizeDrawSettings?.allow_prorated_charges}
                onClick={() =>
                  onUpdatePrizeDrawSettings('allow_prorated_charges', !prizeDrawSettings?.allow_prorated_charges)
                }
                permission="PRIZEDRAWS_CONFIG_MANAGE"
                disabled={false}
              />
            </div>
          </div>
        </div>
        <div className="flex-column pb-6">
          <div className="border-bottom medium-text lighter-text font-16 py-2">{t('REFERRAL_RULES')}</div>
          <div className="flex items-center justify-between py-2 relative">
            <div className="flex-column nowrap mr-4">
              <span className="normal-text lighter-text">{t('BONUS_ENTRIES_PER_REFERRAL')}</span>
            </div>
            <div>
              {error.type === 'referral_bonus_entries' && (
                <div className="error-container flex absolute">
                  <div className="border-left-content " />
                  <span className="regular-text font-12 pxy-1">{error.text}</span>
                </div>
              )}
              {updateConfig.type === 'BONUS_ENTRIES_PER_REFERRAL' && hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? (
                <div className="update-detail-contaner relative">
                  {bonusReferralInput('referral_bonus_entries', 'entry per referral')}
                  <UpdateAction
                    onCancel={() => onCancelUpdateConfig()}
                    onUpdate={() =>
                      isDataChanged(
                        'INPUT',
                        updateConfig?.referral_bonus_entries || '',
                        prizeDrawSettings?.referral_bonus_entries || '',
                        () => onUpdatePrizeDrawSettings('referral_bonus_entries', updateConfig?.referral_bonus_entries),
                        onCancelUpdateConfig,
                      )
                    }
                  />
                </div>
              ) : (
                <div
                  className={`flex items-center py-1 px-2 cursor ${
                    hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? 'hover-edit' : ''
                  } `}
                  onClick={() =>
                    setUpdateConfig({
                      type: 'BONUS_ENTRIES_PER_REFERRAL',
                      referral_bonus_entries: prizeDrawSettings?.referral_bonus_entries,
                    })
                  }>
                  {prizeDrawSettings?.referral_bonus_entries ? (
                    <span className="regular-text font-14 flex-1 mr-2">
                      {prizeDrawSettings?.referral_bonus_entries} entry per referral
                    </span>
                  ) : (
                    <span className="normal-text font-16 flex-1 grey-text mr-2">{t('NO_DATA')}</span>
                  )}
                  {hasPermission('PRIZEDRAWS_CONFIG_MANAGE') && (
                    <EditIcon width={16} height={16} className="edit-icon" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between py-2 relative">
            <div className="flex-column nowrap mr-4">
              <span className="normal-text lighter-text">{t('MAXIMUM_PERMITTED_ENTRIES')}</span>
            </div>
            <div>
              {error.type === 'max_entries' && (
                <div className="error-container flex absolute">
                  <div className="border-left-content " />
                  <span className="regular-text font-12 pxy-1">{error.text}</span>
                </div>
              )}
              {updateConfig.type === 'MAXIMUM_PERMITTED_ENTRIES' && hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? (
                <div className="update-detail-contaner relative">
                  {bonusReferralInput('max_entries', 'maximum entries')}
                  <UpdateAction
                    onCancel={() => onCancelUpdateConfig()}
                    onUpdate={() =>
                      isDataChanged(
                        'INPUT',
                        updateConfig?.max_entries || '',
                        prizeDrawSettings?.max_entries || '',
                        () => onUpdatePrizeDrawSettings('max_entries', updateConfig?.max_entries),
                        onCancelUpdateConfig,
                      )
                    }
                  />
                </div>
              ) : (
                <div
                  className={`flex items-center py-1 px-2 cursor ${
                    hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? 'hover-edit' : ''
                  } `}
                  onClick={() =>
                    setUpdateConfig({ type: 'MAXIMUM_PERMITTED_ENTRIES', max_entries: prizeDrawSettings?.max_entries })
                  }>
                  {prizeDrawSettings?.max_entries ? (
                    <span className="regular-text font-14 flex-1 mr-2">
                      {prizeDrawSettings?.max_entries} maximum entries
                    </span>
                  ) : (
                    <span className="normal-text font-16 flex-1 grey-text mr-2">{t('NO_DATA')}</span>
                  )}
                  {hasPermission('PRIZEDRAWS_CONFIG_MANAGE') && (
                    <EditIcon width={16} height={16} className="edit-icon" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex-column">
          <div className="border-bottom medium-text lighter-text font-16 py-2">{t('WINNING_RULES')}</div>
          <div className="flex items-center justify-between py-2 relative">
            <div className="flex-column nowrap mr-4 h-full">
              <span className="normal-text lighter-text">{t('PRIZES')}</span>
            </div>
            <div>
              {error.type === 'PRIZES' && (
                <div className="error-container flex absolute">
                  <div className="border-left-content " />
                  <span className="regular-text font-12 pxy-1">{error.text}</span>
                </div>
              )}
              {updateConfig.type === 'PRIZES' && hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? (
                <div className="update-detail-contaner relative">
                  {prizesInput()}
                  <div className="flex justify-between w-full absolute">
                    <div
                      className="flex icon cursor"
                      onClick={() =>
                        setUpdateConfig({ ...updateConfig, prizes: [...updateConfig.prizes, { id: uuid() }] })
                      }>
                      <PlusIcon height={16} width={16} />
                    </div>
                    <UpdateAction onCancel={() => onCancelUpdateConfig()} onUpdate={() => onUpdatePrizes()} />
                  </div>
                </div>
              ) : (
                <div
                  className={`flex items-start py-1 px-2 cursor ${
                    hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? 'hover-edit' : ''
                  } `}
                  onClick={() =>
                    setUpdateConfig({
                      type: 'PRIZES',
                      prizes:
                        prizeDrawSettings?.prizes?.length > 0
                          ? prizeDrawSettings?.prizes?.map(p => ({ ...p, id: uuid() }))
                          : [{ id: uuid() }],
                    })
                  }>
                  {prizeDrawSettings?.prizes?.length > 0 ? (
                    <div className="flex-column mr-2">{renderPrizes(prizeDrawSettings?.prizes)}</div>
                  ) : (
                    <span className="regular-text font-14 flex-1 mr-2 grey-text">{t('NO_DATA')}</span>
                  )}
                  {hasPermission('PRIZEDRAWS_CONFIG_MANAGE') && (
                    <EditIcon width={16} height={16} className="edit-icon mt-1" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between py-2">
            <div className="flex-column nowrap mr-4 h-full">
              <span className="normal-text lighter-text">{t('STATES_EXCLUDED')}</span>
            </div>
            <div>
              {updateConfig.type === 'STATES_EXCLUDED' && hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? (
                <div className="update-detail-contaner relative">
                  {statesExcludeInput()}
                  <div className="flex justify-between w-full absolute">
                    <div
                      className="flex items-center justify-center icon cursor"
                      onClick={() =>
                        setUpdateConfig({
                          ...updateConfig,
                          excluded_states: [...updateConfig.excluded_states, { id: uuid() }],
                        })
                      }>
                      <PlusIcon height={16} width={16} />
                    </div>
                    <UpdateAction onCancel={() => onCancelUpdateConfig()} onUpdate={() => onUpdateStatesExcluded()} />
                  </div>
                </div>
              ) : (
                <div
                  className={`flex justify-center items-start py-1 px-2 cursor ${
                    hasPermission('PRIZEDRAWS_CONFIG_MANAGE') ? 'hover-edit' : ''
                  } `}
                  onClick={() =>
                    setUpdateConfig({
                      type: 'STATES_EXCLUDED',
                      excluded_states:
                        prizeDrawSettings?.excluded_states?.length > 0
                          ? prizeDrawSettings?.excluded_states?.map(es => ({
                              excluded: stateOptions.find(s => s.abbreviation === es),
                              id: uuid(),
                            }))
                          : [{ id: uuid() }],
                    })
                  }>
                  {prizeDrawSettings?.excluded_states?.length > 0 ? (
                    <div className="flex-column mr-2">{renderStatesExcludes(prizeDrawSettings?.excluded_states)}</div>
                  ) : (
                    <span className="normal-text font-16 flex-1 grey-text mr-2">{t('NO_DATA')}</span>
                  )}
                  {hasPermission('PRIZEDRAWS_CONFIG_MANAGE') && (
                    <EditIcon width={16} height={16} className="edit-icon mt-1" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PrizeDrawSettingsWrapper>
  );
};

export default PrizeDrawSettings;
