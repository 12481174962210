import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FormDetailsComponent from '../../pages/form-details';
import { PrizeDrawDetailWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import AccountDetails from '../groups-details/account-details';
import PrizeDrawDetailLeft from './prize-draw-detail-left';
import PrizeDrawDetailRight from './prize-draw-detail-right';
import PrizeDrawEntrantDetails from './prize-draw-entrant-details';

const PrizeDrawDetail = () => {
  return (
    <PrizeDrawDetailWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6">
      <Routes>
        <Route element={<PrizeDrawEntrantDetails />} path="/entrant-details/:entrant_id/*" />
        <Route element={<FormDetailsComponent />} path="/form-details/:form_id/*" />
        <Route path="/clients/account-details/:account_id/*" element={<AccountDetails />} />
        <Route path="/organizations/account-details/:account_id/*" element={<AccountDetails />} />
        <Route
          element={
            <>
              <PrizeDrawDetailLeft />
              <PrizeDrawDetailRight />
            </>
          }
          path="/*"
        />
      </Routes>
    </PrizeDrawDetailWrapper>
  );
};

export default PrizeDrawDetail;
