import moment from 'moment/moment';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText } from '../../helpers/utils';
import { AutomationLogItemWrapper } from '../../styles/components/automation-logs-list/automation-logs-list.styled';
import Menu from '../common/menu';
import Status from '../common/status/automation-log-status';

const AutomationLogItem = ({ automationLog }) => {
  const navigate = useNavigate();
  const { setModal, hasPermission } = useContext(OrganisationContext);
  const onAutomationLog = () => {
    navigate(`/platforms/automation-log/${automationLog?.id}`);
  };

  const onCancel = () => {
    setModal({
      type: 'automation-log-cancel',
      content: { automationLog },
    });
  };
  return (
    <AutomationLogItemWrapper
      className="divider-bottom items-center justify-center w-full py-0"
      onClick={() => onAutomationLog()}>
      <div className="divider-right flex items-center justify-center w-full px-6">
        <label className="regular-text ont-14 one-line">{automationLog?.id}</label>
      </div>
      <div className="divider-right flex items-center justify-center w-full px-6 overflow-hidden">
        <label className="regular-text font-14 one-line">{automationLog?.automation?.name}</label>
      </div>
      <div className="divider-right flex items-center justify-center w-full px-6 overflow-hidden">
        <label className="regular-text font-14 one-line">
          {moment(automationLog?.created_on * 1000).format('MM/DD/YY - hh:mm a')}
        </label>
      </div>
      <div className="flex items-center justify-between w-full px-6">
        <div className="flex items-center justify-center flex-1">
          <Status
            dotColor={automationLog?.status}
            withDot
            status={automationLog?.status}
            statusText={capitalize(formatText(automationLog?.status))}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuList={[
            hasPermission('AUTOMATION_LOGS_VIEW') && {
              name: 'Details',
              onClick: () => onAutomationLog(),
              permission: 'AUTOMATION_LOGS_VIEW',
            },
            automationLog?.status === 'PENDING' && { name: 'Cancel', onClick: () => onCancel() },
          ].filter(Boolean)}
        />
      </div>
    </AutomationLogItemWrapper>
  );
};

export default AutomationLogItem;
