import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as TrashIcon } from '../../assets/images/trash-red.svg';
import {
  billingFeesFilterOptions,
  billingTypeFilter,
  dashboardAgencyFilterOptions,
  dashboardClientFilterOptions,
  dashboardMarketingCompanyFilterOptions,
  dashboardSassFilterOptions,
  declineCodeFilter,
  drawStatusFilter,
  entrantsFilterOptions,
  entrantsFilterOptionsForClient,
  entrantsFilterOptionsForSAASOperator,
  filterChildRenderComponent,
  formListFilterOptions,
  frequencyFilter,
  inputPaymentFrequencyFilter,
  multipleFilterTypeEnum,
  paymentMethodFilter,
  paymentTypeFilter,
  prizeDrawFormStatusFilter,
  prizeDrawFormsFilterOptions,
  productPaymentStatusFilter,
  statusFilter,
  winStatusFilter,
} from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import useDebounce from '../../helpers/useDebounceHook';
import { capitalize, formatText } from '../../helpers/utils';
import {
  getClientDrawCode,
  getFormType,
  getMarketingCompanyUsers,
  getMultiOrganization,
  getOrganizationNetworks,
  getOrganizationNetworksAgency,
  getOrganizationNetworksClient,
  getOrganizationNetworksforSubOptions,
  getOrganizations,
  getPrizeDrawForm,
  getPrizeDrawList,
  getSubEventGroups,
  getUsers,
} from '../../store/features/prizeDrawSlice';
import { addToast } from '../../store/features/toastSlice';
import DropDown from '../McDropdown';
import InputDropDown from '../common/inputDropDown';
import MinMaxInput from '../common/min-max-input';
import MinMaxIntValueInput from '../common/min-max-intvalue-input';
import DateSelectorElement from '../widget/date-selector-element/date-selector-element';
const FilterItem = ({ filter = {}, setFilter, index = 0, filterFor, removeFilter, getOptions, options }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    prizeDrawForm,
    users,
    organizations,
    prizeDrawList,
    formType,
    clientDrawCode,
    prizeDrawOrganizationNetworks,
    eventGroups,
    subEventGroups,
    prizeDrawOrganizationNetworksClient,
    prizeDrawOrganizationNetworksAgency,
    prizeDrawOrganizationNetworksForSubChild,
    marketingCompanyUsers,
  } = useSelector(state => state.prizeDraw);
  const pageRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchText2, setSearchText2] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);
  const debouncedSearch2 = useDebounce(searchText2, 500);
  const org = getItemFromLocalStorage('user').organizations[0];
  const organizationTag = org?.category?.tag?.tag || '';
  const { hasPermission } = useContext(OrganisationContext);
  const { t } = useTranslation();

  const getFilterOption = () => {
    switch (filterFor) {
      case multipleFilterTypeEnum.entrants:
      case multipleFilterTypeEnum.entrantMarketingCompany:
        return entrantsFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.formsList:
        return formListFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.prizeDrawForms:
        return prizeDrawFormsFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.billingFees:
        return billingFeesFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.SaasOperators:
        return dashboardSassFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.Client:
        return dashboardClientFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.Agency:
        return dashboardAgencyFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.MarketingCompany:
        return dashboardMarketingCompanyFilterOptions.sort((a, b) => a.name?.localeCompare(b.name));
      case multipleFilterTypeEnum.logs: {
        const updatedOptions = (eventGroups || []).map(data => ({
          ...data,
          childRenderComponent: filterChildRenderComponent.dropDown,
          object: 'EVENT_GROUP',
          type: 'EVENT_GROUP',
          param: 'event_group_id',
        }));
        return updatedOptions.sort((a, b) => a.name?.localeCompare(b.name));
      }
      case multipleFilterTypeEnum.entarntSAASOperator:
        return [...entrantsFilterOptions, ...entrantsFilterOptionsForSAASOperator].sort((a, b) =>
          a.name?.localeCompare(b.name),
        );
      case multipleFilterTypeEnum.entrantClient:
        return entrantsFilterOptionsForClient.sort((a, b) => a.name?.localeCompare(b.name));
    }
  };
  const getSelectedParentOption = () => {
    switch (filterFor) {
      case multipleFilterTypeEnum.entrants:
      case multipleFilterTypeEnum.entrantMarketingCompany:
        return entrantsFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.formsList:
        return formListFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.prizeDrawForms:
        return prizeDrawFormsFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.billingFees:
        return billingFeesFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.SaasOperators:
        return dashboardSassFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.Client:
        return dashboardClientFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.MarketingCompany:
        return dashboardMarketingCompanyFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.Agency:
        return dashboardAgencyFilterOptions.find(opt => opt?.object === filter?.object);
      case multipleFilterTypeEnum.logs:
        return (eventGroups || []).find(opt => opt?.id === filter?.id);
      case multipleFilterTypeEnum.entarntSAASOperator:
        return [...entrantsFilterOptions, ...entrantsFilterOptionsForSAASOperator].find(
          opt => opt?.object === filter?.object,
        );
      case multipleFilterTypeEnum.entrantClient:
        return entrantsFilterOptionsForClient.find(opt => opt?.object === filter?.object);
    }
  };
  const getEntryForm = (page, merge) => {
    pageRef.current = page;
    let params = { page: page };
    if (id) params = { ...params, prizedraw: id };
    dispatch(getPrizeDrawForm({ params: { ...params }, merge: merge })).catch(() => {});
  };
  const getUsersData = (page, merge) => {
    pageRef.current = page;
    const param = { page: page, search: debouncedSearch, role_tag: 'FUNDRAISER' };
    dispatch(getUsers({ params: { ...param }, merge: merge })).catch(() => {});
  };
  const getUsersDataMarketing = (page, merge) => {
    pageRef.current = page;
    const param = { page: page, search: debouncedSearch2, organization_id: filter?.value?.id, role_tag: 'FUNDRAISER' };
    dispatch(getUsers({ params: { ...param }, merge: merge })).catch(() => {});
  };
  const getUsersDataMarketingCompany = (page, merge) => {
    pageRef.current = page;
    const param = { page: page, search: debouncedSearch2, organization_id: filter?.value?.id, role_tag: 'FUNDRAISER' };
    dispatch(getMarketingCompanyUsers({ params: { ...param }, merge: merge })).catch(() => {});
  };
  const getOrganization = (page, merge) => {
    pageRef.current = page;
    dispatch(getOrganizations({ params: { page: page, relationship: filter.object }, merge: merge })).catch(() => {});
  };
  const getMultiOrganizations = (page, merge) => {
    pageRef.current = page;
    dispatch(getMultiOrganization({ params: { page: page, search: debouncedSearch }, merge: merge })).catch(() => {});
  };
  const getPrizeDrawOptions = (page, merge) => {
    pageRef.current = page;
    dispatch(getPrizeDrawList({ params: { page: page, search: debouncedSearch }, merge: merge })).catch(() => {});
  };
  const getFormTypeOption = (page, merge) => {
    dispatch(getFormType({ params: { page: page }, merge: merge })).catch(() => {});
  };
  const getClientDrawCodeOption = (page, merge) => {
    dispatch(getClientDrawCode({ params: { page: page }, merge: merge })).catch(() => {});
  };
  const getOrganizationCategoriesOption = (page, merge) => {
    pageRef.current = page;
    dispatch(
      getOrganizationNetworksClient({
        param: { page: page, search: debouncedSearch, category_tag: 'CLIENT' },
        merge: merge,
        organization_id: org?.id,
      }),
    ).catch(() => {});
  };
  const getOrganizationAgencyOption = (page, merge) => {
    pageRef.current = page;
    dispatch(
      getOrganizationNetworksAgency({
        param: { page: page, search: debouncedSearch, category_tag: 'AGENCY' },
        merge: merge,
        organization_id: org?.id,
      }),
    ).catch(() => {});
  };
  const getMarketingOrganization = (page, merge) => {
    pageRef.current = page;
    const orgId =
      filter?.object === 'MARKETING_COMPANY' || filter?.object === 'MARKETING_COMPANY_DASHBOARD'
        ? getItemFromLocalStorage('user').organizations[0]?.id
        : filter?.value?.id;

    dispatch(
      getOrganizationNetworks({
        param: { page: page, search: debouncedSearch, category_tag: 'MARKETING_COMPANY' },
        merge: merge,
        organization_id: orgId,
      }),
    ).catch(() => {});
  };
  const getMarketingOrganizationForSubChild = (page, merge) => {
    pageRef.current = page;
    dispatch(
      getOrganizationNetworksforSubOptions({
        param: { page: page, search: debouncedSearch, category_tag: 'MARKETING_COMPANY' },
        merge: merge,
        organization_id: filter?.value?.id,
      }),
    ).catch(() => {});
  };
  const getChildEventGroups = () => {
    dispatch(getSubEventGroups({ id: filter.id }))
      .catch(() => {})
      .catch(() => {});
  };
  useEffect(() => {
    if (filter?.value?.id) {
      if (
        filter.object === 'MARKETING_COMPANY_DASHBOARD' ||
        (organizationTag === 'AGENCY' && filter.object === 'MARKETING_COMPANY')
      )
        getUsersDataMarketing(0, false);
      else if (filterFor === multipleFilterTypeEnum?.entarntSAASOperator) {
        if (filter.object === 'AGENCY') getMarketingOrganizationForSubChild(0, false);
        else if (filter.object === 'MARKETING_COMPANY') getUsersDataMarketingCompany(0, false);
      } else if (filter.object === 'AGENCY') getMarketingOrganization(0, false);
    }
  }, [filter?.value, debouncedSearch2]);
  useEffect(() => {
    if (filter.object) {
      if (filter.object === 'ENTRY_FORM') getEntryForm(0, false);
      else if (filter.object === 'FUNDRAISER' || filter?.object === 'FUNDRAISER_BADGE') getUsersData(0, false);
      else if (
        (filterFor === multipleFilterTypeEnum.entarntSAASOperator || organizationTag === 'AGENCY') &&
        filter.object === 'MARKETING_COMPANY'
      )
        getMarketingOrganization(0, false);
      else if (filter.object === 'MARKETING_COMPANY') getMultiOrganizations(0, false);
      else if (filter.object === 'PRIZEDRAW') getPrizeDrawOptions(0, false);
      else if (filter.object === 'FORM_TYPE') getFormTypeOption(0, false);
      else if (filter.object === 'CLIENT_DRAW_CODE') getClientDrawCodeOption(0, false);
      else if (filter.object === 'CLIENT') getOrganizationCategoriesOption(0, false);
      else if (filter.object === 'AGENCY') getOrganizationAgencyOption(0, false);
      else if (filter.object === 'MARKETING_COMPANY_DASHBOARD') getMarketingOrganization(0, false);
      else if (filter.object === 'EVENT_GROUP') getChildEventGroups();
    }
  }, [filter.object, filter.id, debouncedSearch, dispatch]);
  const getFundraiserOptions = () => {
    return hasPermission('USERS_PII_VIEW')
      ? users?.content
      : (users?.content || []).map(item => {
          return {
            ...item,
            name: item?.name ? item?.name : item?.badge_number ? item?.badge_number : '****',
          };
        });
  };
  const getFundraiserBadgeOptions = () => {
    return hasPermission('USERS_PII_VIEW')
      ? (users?.content || [])
          .filter(data => data.badge_number)
          .map(item => ({
            ...item,
            name: item?.badge_number ? item?.badge_number : '****',
          }))
      : (users?.content || [])
          .filter(data => data.badge_number || data.employee_number)
          .map(item => ({
            ...item,
            name: item?.badge_number ? item?.badge_number : item?.employee_number ? item?.employee_number : '****',
          }));
  };
  const getChildOptions = () => {
    switch (filterFor) {
      case multipleFilterTypeEnum.entrants:
      case multipleFilterTypeEnum.entrantClient: {
        if (filter.object === 'PAYMENT_STATUS') return productPaymentStatusFilter;
        else if (filter.object === 'DRAW_STATUS') return drawStatusFilter;
        else if (filter.object === 'PRODUCT_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'WIN_STATUS') return winStatusFilter;
        else if (filter.object === 'PAYMENT_METHOD') return paymentMethodFilter;
        else if (filter.object === 'PAYMENT_TYPE') return paymentTypeFilter;
        else if (filter.object === 'DECLINE_CODE') return declineCodeFilter;
        else if (filter.object === 'ENTRY_FORM') return prizeDrawForm?.content;
        else if (filter.object === 'FUNDRAISER') {
          return getFundraiserOptions();
        } else if (filter.object === 'FUNDRAISER_BADGE') {
          return getFundraiserBadgeOptions();
        } else if (organizationTag === 'AGENCY' && filter.object === 'MARKETING_COMPANY')
          return prizeDrawOrganizationNetworks?.content;
        else if (filter.object === 'MARKETING_COMPANY') return organizations?.content;
        break;
      }
      case multipleFilterTypeEnum.formsList: {
        if (filter.object === 'PRIZEDRAW') return prizeDrawList?.content;
        else if (filter.object === 'FORM_TYPE') return formType;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;
        else if (filter.object === 'CLIENT') return prizeDrawOrganizationNetworksClient?.content;
        else if (filter.object === 'STATUS') return statusFilter;
        break;
      }
      case multipleFilterTypeEnum.prizeDrawForms: {
        if (filter.object === 'FORM_TYPE') return formType;
        else if (filter.object === 'CLIENT') return prizeDrawOrganizationNetworksClient?.content;
        else if (filter.object === 'STATUS') return prizeDrawFormStatusFilter;
        break;
      }
      case multipleFilterTypeEnum.billingFees: {
        if (filter.object === 'PRIZEDRAW') return prizeDrawList?.content;
        else if (filter.object === 'FREQUENCY') return frequencyFilter;
        else if (filter.object === 'CLIENT') return prizeDrawOrganizationNetworksClient?.content;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;
        else if (filter.object === 'BILLING_TYPE') return billingTypeFilter;
      }
      case multipleFilterTypeEnum.SaasOperators: {
        if (filter.object === 'MARKETING_COMPANY_DASHBOARD') return prizeDrawOrganizationNetworks?.content;
        else if (filter.object === 'CLIENT') return prizeDrawOrganizationNetworksClient?.content;
        else if (filter.object === 'AGENCY') return prizeDrawOrganizationNetworksAgency?.content;
        else if (filter.object === 'DRAW_STATUS') return drawStatusFilter;
        else if (filter.object === 'WIN_STATUS') return winStatusFilter;
        else if (filter.object === 'PAYMENT_STATUS') return productPaymentStatusFilter;
        else if (filter.object === 'PRODUCT_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'DONATION_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'PRIZEDRAW') return prizeDrawList?.content;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;
      }
      case multipleFilterTypeEnum.Client: {
        if (filter.object === 'PAYMENT_STATUS') return productPaymentStatusFilter;
        else if (filter.object === 'DRAW_STATUS') return drawStatusFilter;
        else if (filter.object === 'WIN_STATUS') return winStatusFilter;
        else if (filter.object === 'PRODUCT_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'DONATION_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'PAYMENT_METHOD') return paymentMethodFilter;
        else if (filter.object === 'PAYMENT_TYPE') return paymentTypeFilter;
        else if (filter.object === 'DECLINE_CODE') return declineCodeFilter;
        else if (filter.object === 'ENTRY_FORM') return prizeDrawForm?.content;
        else if (filter.object === 'FUNDRAISER') {
          return getFundraiserOptions();
        } else if (filter.object === 'FUNDRAISER_BADGE') {
          return getFundraiserBadgeOptions();
        } else if (filter.object === 'PRIZEDRAW') return prizeDrawList?.content;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;
      }
      case multipleFilterTypeEnum.MarketingCompany: {
        if (filter.object === 'PAYMENT_STATUS') return productPaymentStatusFilter;
        else if (filter.object === 'DRAW_STATUS') return drawStatusFilter;
        else if (filter.object === 'WIN_STATUS') return winStatusFilter;
        else if (filter.object === 'PRODUCT_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'DONATION_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'PAYMENT_METHOD') return paymentMethodFilter;
        else if (filter.object === 'PAYMENT_TYPE') return paymentTypeFilter;
        else if (filter.object === 'DECLINE_CODE') return declineCodeFilter;
        else if (filter.object === 'ENTRY_FORM') return prizeDrawForm?.content;
        else if (filter.object === 'FUNDRAISER') {
          return getFundraiserOptions();
        } else if (filter.object === 'FUNDRAISER_BADGE') {
          return getFundraiserBadgeOptions();
        } else if (filter.object === 'PRIZEDRAW') return prizeDrawList?.content;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;
      }
      case multipleFilterTypeEnum.Agency: {
        if (filter.object === 'PAYMENT_STATUS') return productPaymentStatusFilter;
        else if (filter.object === 'DRAW_STATUS') return drawStatusFilter;
        else if (filter.object === 'WIN_STATUS') return winStatusFilter;
        else if (filter.object === 'PRODUCT_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'DONATION_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'PAYMENT_METHOD') return paymentMethodFilter;
        else if (filter.object === 'PAYMENT_TYPE') return paymentTypeFilter;
        else if (filter.object === 'DECLINE_CODE') return declineCodeFilter;
        else if (filter.object === 'ENTRY_FORM') return prizeDrawForm?.content;
        else if (filter.object === 'FUNDRAISER') {
          return getFundraiserOptions();
        } else if (filter.object === 'FUNDRAISER_BADGE') {
          return getFundraiserBadgeOptions();
        } else if (filter.object === 'MARKETING_COMPANY_DASHBOARD') return prizeDrawOrganizationNetworks?.content;
        else if (filter.object === 'PRIZEDRAW') return prizeDrawList?.content;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;
      }
      case multipleFilterTypeEnum.logs: {
        if (filter.object === 'EVENT_GROUP') {
          const subEventGroupById = subEventGroups?.[filter.id] || { filters: [] };
          const updatedList = subEventGroupById?.filters.map(data => ({
            ...data?.event_type,
            id: data?.event_type?.type,
            param: 'event_type',
          }));
          return updatedList;
        }
      }
      case multipleFilterTypeEnum.entarntSAASOperator: {
        if (filter?.object === 'CLIENT') return prizeDrawOrganizationNetworksClient?.content;
        else if (filter?.object === 'AGENCY') return prizeDrawOrganizationNetworksAgency?.content;
        else if (filter?.object === 'MARKETING_COMPANY') return prizeDrawOrganizationNetworks?.content;
      }
      case multipleFilterTypeEnum.entrantMarketingCompany: {
        if (filter.object === 'PAYMENT_STATUS') return productPaymentStatusFilter;
        else if (filter.object === 'DRAW_STATUS') return drawStatusFilter;
        else if (filter.object === 'PRODUCT_FREQUENCY') return inputPaymentFrequencyFilter;
        else if (filter.object === 'WIN_STATUS') return winStatusFilter;
        else if (filter.object === 'PAYMENT_METHOD') return paymentMethodFilter;
        else if (filter.object === 'PAYMENT_TYPE') return paymentTypeFilter;
        else if (filter.object === 'DECLINE_CODE') return declineCodeFilter;
        else if (filter.object === 'ENTRY_FORM') return prizeDrawForm?.content;
        else if (filter.object === 'FUNDRAISER') {
          return getFundraiserOptions();
        } else if (filter.object === 'FUNDRAISER_BADGE') {
          return getFundraiserBadgeOptions();
        } else if (organizationTag === 'AGENCY' && filter.object === 'MARKETING_COMPANY')
          return prizeDrawOrganizationNetworks?.content;
        else if (filter.object === 'MARKETING_COMPANY') return organizations?.content;
        else if (filter.object === 'CLIENT_DRAW_CODE') return clientDrawCode;

        break;
      }
    }
  };
  const getGrandChildOptions = () => {
    switch (filterFor) {
      case multipleFilterTypeEnum.entrants: {
        if (filter.object === 'MARKETING_COMPANY') return users?.content;
        break;
      }
      case multipleFilterTypeEnum.Agency: {
        if (filter.object === 'MARKETING_COMPANY_DASHBOARD') return users?.content;
        break;
      }
      case multipleFilterTypeEnum.SaasOperators: {
        if (filter.object === 'MARKETING_COMPANY_DASHBOARD') return users?.content;
        if (filter.object === 'AGENCY') return prizeDrawOrganizationNetworks?.content;
        break;
      }
      case multipleFilterTypeEnum.entarntSAASOperator: {
        if (filter.object === 'AGENCY')
          return prizeDrawOrganizationNetworksForSubChild?.[filter?.value?.id]?.content && filter?.value?.id
            ? prizeDrawOrganizationNetworksForSubChild?.[filter?.value?.id]?.content
            : [];
        else if (filter.object === 'MARKETING_COMPANY') {
          return marketingCompanyUsers && marketingCompanyUsers[filter?.value?.id]?.content && filter?.value?.id
            ? marketingCompanyUsers[filter?.value?.id]?.content
            : [];
        }
        break;
      }
    }
  };

  const getGrandChildOptionsPlaceholderText = () => {
    if (
      (filterFor === multipleFilterTypeEnum.entrants || filterFor === multipleFilterTypeEnum.entarntSAASOperator) &&
      filter.object === 'MARKETING_COMPANY'
    ) {
      return 'Select fundraiser';
    } else if (
      (filterFor === multipleFilterTypeEnum.Agency || filterFor === multipleFilterTypeEnum.SaasOperators) &&
      filter.object === 'MARKETING_COMPANY_DASHBOARD'
    ) {
      return 'Select fundraiser';
    } else if (
      (filterFor === multipleFilterTypeEnum.entarntSAASOperator ||
        filterFor === multipleFilterTypeEnum.SaasOperators) &&
      filter?.object === 'AGENCY'
    ) {
      return 'Select marketing company';
    } else {
      return `Select ${filter.name.toLowerCase()}`;
    }
  };
  const getChildrenRenderComponent = () => {
    switch (filter?.childRenderComponent) {
      case filterChildRenderComponent.dropDown:
        return (
          <DropDown
            options={getChildOptions()}
            selected={filter.value}
            setSelected={o => setFilter({ ...filter, value: o })}
            placeholder={
              filter?.object === 'MARKETING_COMPANY' && organizationTag === 'AGENCY'
                ? 'Select agency'
                : capitalize(formatText(`Select ${t(`${filter.name}`)}`))
            }
            allowTranslateOption={filter.allowTranslateOption ? true : false}
          />
        );
      case filterChildRenderComponent.dateRange:
        return (
          <div className={'flex-row flex w-full gap-6'}>
            <div className={'flex-column w-full'}>
              <label className="regular-text mb-1 font-12 lighter-text fw-400">From</label>
              <DateSelectorElement
                placeholder="MM/DD/YY"
                selectedDate={filter?.value?.fromDate}
                setSelectedDate={d => {
                  if (
                    filter?.value.toDate &&
                    moment(d * 1000)
                      .clone()
                      .startOf('day')
                      .isAfter(moment(filter?.value?.toDate * 1000).clone())
                  )
                    dispatch(
                      addToast({
                        error: true,
                        text: 'From date should less than To date',
                        id: uuid(),
                      }),
                    );
                  else
                    setFilter({
                      ...filter,
                      value: {
                        ...filter.value,
                        fromDate: moment(d * 1000)
                          .clone()
                          .startOf('day')
                          .unix(),
                      },
                    });
                }}
                format={'MM/DD/YY'}
                showDropdownArrow={false}
                className={'relative fromdate-container'}
              />
            </div>

            <div className={'flex-column w-full'}>
              <label className="regular-text font-12 lighter-text fw-400 mb-1">To</label>
              <DateSelectorElement
                placeholder="MM/DD/YY"
                selectedDate={filter?.value?.toDate}
                setSelectedDate={d => {
                  if (
                    filter?.value.fromDate &&
                    moment(d * 1000)
                      .clone()
                      .endOf('day')
                      .isBefore(moment(filter?.value?.fromDate * 1000).clone())
                  )
                    dispatch(
                      addToast({
                        error: true,
                        text: 'To date should greater than From date',
                        id: uuid(),
                      }),
                    );
                  else
                    setFilter({
                      ...filter,
                      value: {
                        ...filter.value,
                        toDate: moment(d * 1000)
                          .clone()
                          .endOf('day')
                          .unix(),
                      },
                    });
                }}
                format={'MM/DD/YY'}
                showDropdownArrow={false}
                className="relative todate-container"
              />
            </div>
          </div>
        );
      case filterChildRenderComponent.minMax:
        return (
          <div className={'flex-row flex w-full'}>
            {filter?.type === 'PAYMENT_AMOUNT' ? (
              <MinMaxInput
                minValue={filter?.value?.minValue ? filter?.value?.minValue : ''}
                maxValue={filter?.value?.maxValue ? filter?.value?.maxValue : ''}
                minPlaceholder="00.00"
                maxPlaceholder="00.00"
                typefilter={filter?.type}
                setMaxValue={value => setFilter({ ...filter, value: { ...filter.value, maxValue: value } })}
                setMinValue={value => setFilter({ ...filter, value: { ...filter.value, minValue: value } })}
              />
            ) : filter?.type === 'ENTRIES' ? (
              <MinMaxIntValueInput
                minValue={filter?.value?.minValue ? filter?.value?.minValue : ''}
                maxValue={filter?.value?.maxValue ? filter?.value?.maxValue : ''}
                minPlaceholder="0"
                maxPlaceholder="0"
                typefilter={filter?.type}
                setMaxValue={value => setFilter({ ...filter, value: { ...filter.value, maxValue: value } })}
                setMinValue={value => setFilter({ ...filter, value: { ...filter.value, minValue: value } })}
              />
            ) : (
              <MinMaxInput
                minValue={filter?.value?.minValue ? filter?.value?.minValue : ''}
                maxValue={filter?.value?.maxValue ? filter?.value?.maxValue : ''}
                minPlaceholder="0"
                maxPlaceholder="0"
                typefilter={filter?.type}
                setMaxValue={value => setFilter({ ...filter, value: { ...filter.value, maxValue: value } })}
                setMinValue={value => setFilter({ ...filter, value: { ...filter.value, minValue: value } })}
              />
            )}
          </div>
        );
      case filterChildRenderComponent.infiniteScrollDropdown:
        return (
          <DropDown
            options={getChildOptions()}
            selected={filter.value}
            setSelected={o => setFilter({ ...filter, value: o })}
            placeholder={
              filter?.object === 'MARKETING_COMPANY' && organizationTag === 'AGENCY'
                ? 'Select agency'
                : capitalize(formatText(`Select ${t(`${filter.name}`)}`))
            }
            isSearchable={filter.isSearchable}
            search={searchText}
            setSearch={setSearchText}
            hasMore={getLastPageFlag()}
            isPaged={filter.isPaged}
            fetchData={() => fetchData(pageRef.current + 1, true)}
            allowTranslateOption={filter.allowTranslateOption ? true : false}
          />
        );
      case filterChildRenderComponent.inputDropDown:
        return (
          <InputDropDown
            options={getChildOptions()}
            selected={filter.value}
            inputValue={filter?.value?.inputValue}
            setInputValue={value =>
              setFilter({
                ...filter,
                value: {
                  ...filter.value,
                  inputValue: value,
                  id: filter?.value?.id ? filter?.value?.id : 'MONTH',
                  name: filter?.value?.name ? filter?.value?.name : 'Monthly',
                },
              })
            }
            setSelected={o => {
              let updatedFilter = { ...filter, value: { ...filter.value, ...o, inputValue: '' } };
              if (o?.id?.trim() === 'QUARTERLY') {
                updatedFilter = { ...updatedFilter, value: { ...updatedFilter?.value, id: 'MONTH', inputValue: 3 } };
              } else if (o?.id?.trim() === 'BI_ANNUALLY') {
                updatedFilter = { ...updatedFilter, value: { ...updatedFilter?.value, id: 'MONTH', inputValue: 6 } };
              } else if (o?.id?.trim() === 'MONTH') {
                updatedFilter = { ...updatedFilter, value: { ...updatedFilter?.value, id: 'MONTH', inputValue: 1 } };
              }
              setFilter(updatedFilter);
            }}
          />
        );

      case filterChildRenderComponent.infiniteScrollMultiDropdown:
        return (
          <div className="flex-column gap-4">
            <DropDown
              options={getChildOptions()}
              selected={filter.value}
              setSelected={o => setFilter({ ...filter, value: o, value2: null })}
              placeholder={
                filter?.object === 'MARKETING_COMPANY' && organizationTag === 'AGENCY'
                  ? 'Select agency'
                  : capitalize(formatText(`Select ${t(`${filter.name}`)}`))
              }
              isSearchable={filter.isSearchable}
              search={searchText}
              setSearch={setSearchText}
              hasMore={getLastPageFlag()}
              isPaged={filter.isPaged}
              fetchData={() => fetchData(pageRef.current + 1, true)}
              allowTranslateOption={filter.allowTranslateOption ? true : false}
            />
            {filter.value && (
              <DropDown
                options={getGrandChildOptions()}
                selected={filter.value2}
                setSelected={o => setFilter({ ...filter, value2: o })}
                placeholder={getGrandChildOptionsPlaceholderText()}
                isSearchable={filter.isSearchable}
                search={searchText2}
                setSearch={setSearchText2}
                hasMore={getLastPageFlag()}
                isPaged={filter.isPaged}
                fetchData={() => fetchData(pageRef.current + 1, true)}
              />
            )}
          </div>
        );
    }
  };
  const getLastPageFlag = () => {
    if (filter.object) {
      if (filter.object === 'ENTRY_FORM') return !prizeDrawForm?.last;
      else if (filter.object === 'FUNDRAISER' || filter?.object === 'FUNDRAISER_BADGE') return !users?.last;
      else if (filterFor === multipleFilterTypeEnum.entarntSAASOperator && filter.object === 'AGENCY')
        return !prizeDrawOrganizationNetworksAgency?.last;
      else if (filterFor === multipleFilterTypeEnum.entarntSAASOperator && filter.object === 'CLIENT')
        return !prizeDrawOrganizationNetworksClient?.last;
      else if (filterFor === multipleFilterTypeEnum.entarntSAASOperator && filter.object === 'MARKETING_COMPANY')
        return !prizeDrawOrganizationNetworks?.last;
      else if (filter.object === 'MARKETING_COMPANY') return !organizations?.last;
      else if (filter.object === 'CLIENT') return !prizeDrawOrganizationNetworksClient?.last;
      else if (filter.object === 'AGENCY') return !prizeDrawOrganizationNetworksAgency?.last;
      else if (filter.object === 'MARKETING_COMPANY_DASHBOARD') return !prizeDrawOrganizationNetworks?.last;
      else if (filter.object === 'PRIZEDRAW') return !prizeDrawList?.last;
    }
  };
  const fetchData = (page, merge) => {
    if (filter.object) {
      if (filter.object === 'ENTRY_FORM') getEntryForm(page, merge);
      else if (filter.object === 'FUNDRAISER' || filter?.object === 'FUNDRAISER_BADGE') getUsersData(page, merge);
      else if (
        (filterFor === multipleFilterTypeEnum.entarntSAASOperator || organizationTag === 'AGENCY') &&
        filter.object === 'MARKETING_COMPANY'
      )
        getMarketingOrganization(page, merge);
      else if (filter.object === 'MARKETING_COMPANY') getMultiOrganizations(page, merge);
      else if (filter.object === 'PRIZEDRAW') getPrizeDrawOptions(page, merge);
      else if (filter.object === 'CLIENT') getOrganizationCategoriesOption(page, merge);
      else if (filter.object === 'AGENCY') getOrganizationAgencyOption(page, merge);
      else if (filter.object === 'MARKETING_COMPANY_DASHBOARD' && filterFor === multipleFilterTypeEnum.SaasOperators)
        getMarketingOrganization(page, merge);
      else if (filter.object === 'MARKETING_COMPANY_DASHBOARD') getOrganization(page, merge);
    }
  };
  return (
    <div className="flex-column pxy-4 border-top">
      <div className="flex w-full items-center justify-between mb-4">
        <label className="normal-text font-14 flex-1">{`FILTER ${index + 1}`}</label>
        <TrashIcon className="flex items-center justify-center cursor" onClick={() => removeFilter(filter)} />
      </div>
      <DropDown
        options={getFilterOption()}
        selected={getSelectedParentOption()}
        setSelected={o => setFilter({ ...o, value: '', ids: uuid() })}
        placeholder="Select"
        allowTranslateOption={true}
      />
      {filter.object && (
        <div className="flex-column  mt-4">
          <div className="flex-column">{getChildrenRenderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default FilterItem;
