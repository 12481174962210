import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { getUserInitials } from '../helpers/utils';
import { deleteAutomation, updateAutomationStatus } from '../store/features/automationsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { AutomationActionWrapper } from './modal.styled';

const AutomationAction = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { modal, setModal } = useContext(OrganisationContext);

  const { automation, isEnable, title, isDelete, onSuccess } = modal.content;

  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    setLoading(true);
    if (isDelete) {
      dispatch(deleteAutomation({ automationID: automation.id }))
        .then(data => {
          setModal(initModal);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(({ response }) => {
          const { data } = response;
          dispatch(
            addToast({
              error: true,
              text: data?.error_description ? data?.error_description : 'Error while delete automation',
              id: uuid(),
            }),
          );
          setLoading(false);
        });
    } else {
      dispatch(updateAutomationStatus({ automationID: automation.id, request: { enabled: !automation.is_enabled } }))
        .then(data => {
          setModal(initModal);
          setLoading(false);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(({ response }) => {
          const { data } = response;
          dispatch(
            addToast({
              error: true,
              text: data?.error_description ? data?.error_description : 'Error while updating status',
              id: uuid(),
            }),
          );
          setLoading(false);
        });
    }
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{
        label: isDelete ? 'Delete' : isEnable ? 'Enable' : 'Disable',
        className: isDelete ? 'negative' : 'primary',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={handleDone}>
      <AutomationActionWrapper className="flex-column items-center w-full">
        <div className="automation-details-container mt-6 pxy-4 w-full">
          <div className="flex justify-between items-center w-full">
            <label className="normal-text font-16">{automation?.name}</label>
            {getUserInitials(automation.owner || {}) && (
              <div className="user-icon flex items-center justify-center mr-2">
                <span className="bold-text grey-text-2">{getUserInitials(automation.owner || {})}</span>
              </div>
            )}
          </div>
          <div className="flex items-center justify-start w-full">
            <label className="normal-text fw-400 grey-text">{automation?.description}</label>
          </div>
        </div>
      </AutomationActionWrapper>
    </CommonPopup>
  );
};

export default AutomationAction;
