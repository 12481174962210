import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaymentIcon } from '../../../../assets/images/logs/payment-icon.svg';
import { getCurrencySymbol } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizeWonLog = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log;
  const {
    prize: { win },
  } = event_data;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader icon={<PaymentIcon height={16} width={16} />} title={'Prize won'} subtitle={'Action'} />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {log?.event_data?.entrant?.urn}
            </span>
            <span className="regular-text lighter-text font-12 ml-1">won the Prize Draw 🎉</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <LogContentBox
          title={'Amount'}
          contentText={
            <p className="regular-text lighter-text font-12">{`${getCurrencySymbol(win.currency)}${
              win?.prize_amount
            }`}</p>
          }
        />
      )}
    </div>
  );
};

export default PrizeWonLog;
