import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as FiAward } from '../../../../../assets/images/FiAward.svg';
import { ReactComponent as Trophy } from '../../../../../assets/images/trophy-light.svg';
import { getCurrencySymbol } from '../../../../../helpers/utils';
import { prizeDrawEntrantOverviewWinning } from '../../../../../store/features/prizeDrawSlice';

const Winning = () => {
  const { prizeDrawEntrantOverviewWinningDetails } = useSelector(state => state.prizeDraw);
  const { amount, count, currency, winnings } = prizeDrawEntrantOverviewWinningDetails || {};
  const { entrant_id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(prizeDrawEntrantOverviewWinning(entrant_id));
  }, []);

  return (
    <>
      {winnings && (
        <div className="px-6 pt-6 flex flex-column">
          <label className="medium-text font-16 lighter-text divider-bottom w-full flex-1 flex pb-2">
            {t('WINNINGS')}
          </label>
          <div className="border radius-4 mt-4 px-4 py-3">
            <label className="regular-text font-14 flex items-center mb-1">
              {`${getCurrencySymbol(currency || 'USD')}${amount} total`}
              <FiAward className="ml-1" />
            </label>
            <div className="flex flex-row gap-2 items-center">
              {/* <label className="regular-text lighter-text font-12">{t('ENTERED_ON')}</label> */}
              {/* <label className="dot-3-lighter-text"></label> */}
              <label className="regular-text lighter-text font-12">
                {t('WON')}
                {` ${count}x`}
              </label>
            </div>
          </div>
          <div className="winner-list">
            {winnings.map((item, index) => (
              <div key={index} className="flex border mt-4 radius-4 px-4 py-2 justify-between">
                <label className="regular-text flex gap-1 items-center font-14">
                  <Trophy /> {`${getCurrencySymbol(currency)}${item.amount.toFixed(2)}`}
                </label>
                <label className="regular-text lighter-text flex items-center font-12">
                  Won on {moment(item.date * 1000).format('MMM DD, YY')}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Winning;
