import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText } from '../helpers/utils';
import {
  deleteIntegration,
  getIntegrationAutomations,
  setIntegrationAutomations,
  updateIntegrationState,
} from '../store/features/integrationsSlice';
import CommonPopup from './common-popup';
import { DeleteIntegrationWrapper } from './modal.styled.js';

const IntegrationActions = () => {
  const dispatch = useDispatch();
  const { integrationAutomations } = useSelector(state => state.integrations);
  const { modal, setModal } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(false);

  const { integration, isDelete, isDisable, onSuccess } = modal.content;

  const fetchIntegrationAutomations = integrationId => {
    dispatch(getIntegrationAutomations({ id: integrationId }));
  };

  useEffect(() => {
    if (integration.id) {
      fetchIntegrationAutomations(integration.id);
    }

    return () => {
      dispatch(setIntegrationAutomations([]));
    };
  }, [integration?.id]);

  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (isDelete) {
        dispatch(deleteIntegration({ id: integration?.id })).then(data => {
          setLoading(false);
          setModal(initModal);
          onSuccess && onSuccess();
        });
      } else {
        dispatch(
          updateIntegrationState({
            id: integration?.id,
            request: {
              enabled: false,
            },
            updateList: true,
          }),
        ).then(() => {
          setLoading(false);
          setModal(initModal);
          onSuccess && onSuccess();
        });
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to ${isDelete ? 'delete' : 'disable'} Integration`,
          id: uuid(),
        }),
      );
    }
  };

  const getOwnerLogo = (automation, index) => {
    const { owner } = automation;
    if (!owner.name) return '';
    const nameArray = owner.name?.split(' ');
    return (
      <div className={`owner-logo ml-3 pxy-2 border owner-background-${index % 3}`}>
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };

  return (
    <CommonPopup
      popupTitle={`Are you sure you want to ${isDelete ? 'delete' : isDisable ? 'disable' : ''} this Integration?`}
      confirmButtonProps={{ label: isDelete ? 'Delete' : 'Disable', className: isDelete ? 'negative' : 'primary' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <DeleteIntegrationWrapper className="flex-column items-center w-full">
        <div className="mt-6 pxy-4 flex-column w-full integration-details-container">
          <label className="normal-text font-16">{integration.name}</label>
          <div className="flex items-center mt-2 integration-type-container">
            <div className="flex integration-icon">
              <img alt="image" src={`${integration.connector.icon?.active}`} width={24} height={24} />
            </div>
            <label className="regular-text ml-2">{capitalize(formatText(integration.connector?.name))}</label>
          </div>
        </div>
        {integrationAutomations?.length > 0 && (
          <div className="mt-6">
            <div className="flex items-center justify-center text-center">
              <label className="regular-text grey-text">
                This will affect these automations and they will be disabled as a result:
              </label>
            </div>
            <div className="automation-detail mt-4 overflow-scroll">
              {integrationAutomations.map((automation, index) => (
                <div className="pxy-4 mb-4 automation-detail-container">
                  <div className="flex items-center justify-between integration-item-top">
                    <div className="flex-column mr-1 flex-1 integration-item-left">
                      <label className="normal-text hide-name font-16">{automation.name}</label>
                      <span className="regular-text grey-text">{automation.description}</span>
                    </div>
                    <div className="flex items-center integration-item-right" tabIndex={0}>
                      {getOwnerLogo(automation, index)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </DeleteIntegrationWrapper>
    </CommonPopup>
  );
};

export default IntegrationActions;
