import ReactECharts from 'echarts-for-react';
import React, { useMemo } from 'react';

const SplineChartV2 = ({ xaxisData, seriesData }) => {
  const customTooltipFormatter = params => {
    const ageValue = params[0].axisValueLabel;
    const value = params[0].data;
    const SubtitleTooltip = params[0].seriesName;
    return `
    <div  style="min-width:120px; text-align:center; border-radius: 12px; ">
          <p class="medium-text py-1 font-12" style="background-color: #E8D1FF; color: #8927EF ;  border-radius: 12px 12px 0 0;">
          ${ageValue}
          </p>
          <div class="px-2 py-1  flex-column">
            <label class="flex justify-center items-center flex-1 semibold-text font-12">${value}</label>
            <label class="flex justify-center items-center flex-1 semibold-text font-12">${SubtitleTooltip}</label>

          </div>
        </div>`;
  };

  const getOption = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
        formatter: customTooltipFormatter,
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
        borderRadius: 12,
        borderWidth: 0,
        padding: 0,
      },

      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xaxisData,
        axisLabel: {
          color: '#6B7280',
          fontSize: 12,
          show: true,
          fontWeight: 500,
          interval: xaxisData?.length - 2,
          showMinLabel: true,
          showMaxLabel: true,
          alignMinLabel: 'left',
          alignMaxLabel: 'right',
          width: 300,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      grid: [
        {
          left: 0,
          top: 30,
          bottom: 50,
          width: '100%',
          containLabel: true,
        },
      ],
      yAxis: {
        type: 'value',
        minInterval: 1,
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            width: 2,
            color: 'rgba(0, 0, 0, 0.13)',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#6B7280',
          fontWeight: 500,
          fontSize: 12,
          margin: 15,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: 'Entrants',
          type: 'line',
          smooth: true,
          itemStyle: {
            opacity: 0,
            color: '#8927EF',
          },
          lineStyle: {
            opacity: 1,
            width: 4,
            color: '#8927EF',
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
              color: '#8927EF',
              borderWidth: 3,
            },
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(137, 39, 239, 0.8)' },
                { offset: 1, color: 'rgba(137, 39, 239, 0)' },
              ],
              globalCoord: false,
            },
          },
          data: seriesData,
        },
      ],
    };
  });

  return <ReactECharts option={getOption} opts={{ renderer: 'svg' }} />;
};

export default SplineChartV2;
