import React from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import PhoneInput from '../../components/common/phone-input';
import { uppercase } from '../../helpers/utils';

const AccountInfo = ({ handleChange = () => {}, accountInfo = {}, error = {} }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-column items-center justify-center w-full row-gap-6">
      <div className="flex-row items-center justify-center w-full col-gap-6 row-gap-2">
        <div className="flex-column row-gap-2" style={{ width: '65%' }}>
          <label className="regular-text lighter-text font-12">{t('NAME')}</label>
          <input
            autoComplete="turnoff"
            className={`input ${error?.name && 'error-border'}`}
            onChange={e => handleChange('name', e.target.value)}
            placeholder="Enter account name"
            value={accountInfo?.name}
          />
        </div>
        <div className="flex-column row-gap-2" style={{ width: '35%' }}>
          <label className="regular-text lighter-text font-12">{t('ABBREVIATION')}</label>
          <MaskedInput
            mask={[/[A-Z,a-z]/, /[A-Z,a-z]/, /[A-Z,a-z]/]}
            value={accountInfo?.abbreviation || null}
            className={`input w-full ${error?.abbreviation && 'error-border'}`}
            placeholder={'ABC'}
            onChange={({ target: { value } }) => {
              handleChange('abbreviation', uppercase(value));
            }}
            guide={false}
          />
        </div>
      </div>
      <div className="flex-column w-full row-gap-2">
        <label className="regular-text lighter-text font-12">{t('EMAIL')}</label>
        <input
          autoComplete="turnoff"
          className={`input ${error?.email && 'error-border'}`}
          onChange={e => handleChange('email', e.target.value)}
          placeholder="Enter account email"
          value={accountInfo?.email}
        />
      </div>
      <div className="flex-column w-full row-gap-2">
        <label className="regular-text lighter-text font-12">{t('PHONE_OPTIONAL')}</label>
        <PhoneInput
          selectedCountry={accountInfo?.phone?.phoneCountry}
          setSelectedCountry={country => handleChange('phoneCountry', country)}
          phone={accountInfo?.phone?.phone}
          setPhone={phone => {
            handleChange('phone', phone);
          }}
          className={error?.phone ? 'phone-input-error' : ''}
        />
      </div>
    </div>
  );
};

export default AccountInfo;
