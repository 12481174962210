import AllPrizeDraws from '../all-prize-draws';
import AutomationDetails from '../automation-details';
import AutomationLogDetails from '../automation-log-details';
import AutomationLog from '../automation-logs';
import Automations from '../automations';
import BillingFees from '../billing-fees';
import BillingOverview from '../billing-overview';
import Dashboard from '../dashboard';
import Fees from '../fees';
import FormDetails from '../form-details';
import Forms from '../forms';
import Groups from '../groups';
import GroupDetailsContainer from '../groups-details-container';
import Home from '../home';
import Integrations from '../integrations';
import IntegrationsDetailContainer from '../integrations-detail-container';
import PrizeDrawDetailContainer from '../prize-draw-detail';
import Profile from '../profile/profile';
import Settings from '../settings';
import Security from '../settings/security';
import Users from '../settings/security/users';
export const ROUTE_PATHS = [
  {
    id: 22,
    path: '/home/*',
    Component: Home,
    permission: ['HOMEPAGE_VIEW', 'ENTRANTS_VIEW', 'HOMEPAGE_LOG_VIEW'],
  },
  {
    id: 1,
    path: '/dashboard',
    Component: Dashboard,
    permission: ['ENTRANT_STATS_VIEW'],
  },
  {
    id: 2,
    path: '/prize-draws/all',
    Component: AllPrizeDraws,
    permission: ['PRIZEDRAWS_VIEW', 'PRIZEDRAWS_MANAGE'],
  },
  {
    id: 3,
    path: '/prize-draws/forms',
    Component: Forms,
    permission: ['FORMS_VIEW_LIST'],
  },
  {
    id: 4,
    path: '/billings/overview',
    Component: BillingOverview,
    permission: ['TRANSACTION_FEES_VIEW'],
  },
  {
    id: 6,
    path: '/groups',
    Component: Groups,
    permission: ['ORGANIZATION_CATEGORIES_VIEW', 'ORGANIZATION_CATEGORIES_MANAGE'],
  },
  {
    id: 7,
    path: '/profile',
    Component: Profile,
  },
  {
    id: 8,
    path: '/prize-draws/all/:id/*',
    Component: PrizeDrawDetailContainer,
    permission: ['PRIZEDRAWS_VIEW', 'PRIZEDRAWS_MANAGE'],
  },
  {
    id: 9,
    path: '/platforms/integrations',
    Component: Integrations,
    permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
  },
  {
    id: 10,
    path: '/platforms/automations',
    Component: Automations,
    permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
  },
  {
    id: 11,
    path: '/platforms/integrations/:id',
    Component: IntegrationsDetailContainer,
    permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
  },
  {
    id: 12,
    path: '/settings',
    Component: Settings,
  },
  {
    id: 13,
    path: '/settings/security',
    Component: Security,
  },
  {
    id: 14,
    path: '/settings/security/users',
    Component: Users,
    permission: ['USERS_MANAGE', 'USERS_VIEW'],
  },
  {
    id: 15,
    path: '/groups/:id/*',
    Component: GroupDetailsContainer,
  },
  {
    id: 16,
    path: '/platforms/automations/:id/*',
    Component: AutomationDetails,
    permission: ['AUTOMATIONS_MANAGE'],
  },
  {
    id: 17,
    path: '/prize-draws/forms/:id/*',
    Component: FormDetails,
    permission: ['FORMS_VIEW'],
  },
  {
    id: 18,
    path: '/billings/fees',
    Component: Fees,
    permission: ['FEES_VIEW', 'FEES_MANAGE'],
  },
  { id: 19, path: '/billings/fees/:id', Component: BillingFees },
  {
    id: 20,
    path: '/platforms/automation-log',
    Component: AutomationLog,
    permission: ['AUTOMATION_LOGS_VIEW'],
  },
  {
    id: 21,
    path: '/platforms/automation-log/:id',
    Component: AutomationLogDetails,
    permission: ['AUTOMATION_LOGS_VIEW'],
  },
  {
    id: 22,
    path: '/settings/security/users/:id',
    Component: Profile,
    permission: ['USERS_MANAGE', 'USERS_VIEW'],
  },
];
