import React from 'react';
import { AutomationLogDetailsComponentWrapper } from '../../styles/components/automation-log-details/automation-log-details.styled';
import AutomationDetailsLeft from './automation-log-details-left';
import AutomationDetailsRight from './automation-log-details-right';
const AutomationLogDetails = () => {
  return (
    <AutomationLogDetailsComponentWrapper className="flex col-gap-6 flex-1 px-10 pt-2 pb-6 overflow-scroll">
      <AutomationDetailsLeft />
      <AutomationDetailsRight />
    </AutomationLogDetailsComponentWrapper>
  );
};

export default AutomationLogDetails;
