import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from '../../../../assets/images/user.svg';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const UserOTPRequest = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log;
  const { user } = event_data;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<UserIcon height={16} width={16} />}
        title={'User OTP request'}
        subtitle={log?.tenant?.id ? log?.tenant?.id : 'Entrant'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{`${user?.forename} ${user?.surname}`}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('REQUEST_FOR_OTP')}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'phone'}
            contentText={<p className="regular-text lighter-text font-12">{user?.profile.phone}</p>}
          />
          <LogContentBox
            title={'email'}
            contentText={<p className="regular-text lighter-text font-12">{user?.profile.email}</p>}
          />
        </>
      )}
    </div>
  );
};

export default UserOTPRequest;
