import React from 'react';
import { useSelector } from 'react-redux';
import { FormListWrapper } from '../../styles/components/form-list/form-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import FormListItem from './form-list-item';

const FormList = ({ debouncedSearch, filters = [] }) => {
  const { formList } = useSelector(state => state.prizeDraw);
  return (
    <FormListWrapper className="flex-column flex-1 overflow-scroll">
      {formList?.content?.length > 0 ? (
        formList?.content?.map(item => <FormListItem form={item} key={item.id} />)
      ) : debouncedSearch || filters?.some(f => f.value) ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </FormListWrapper>
  );
};

export default FormList;
