import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrencySymbol, getPaymentFrequencyText } from '../../../helpers/utils';
import { BillingFeesDetailsLeftWrapper } from '../../../styles/components/billing-fees-details/billing-fees-details.styled';
import PrizedrawInfo from './prizedraw-info';

const BillingFeesDetailsLeft = () => {
  const { productDetails, productDetailsPrizedraw } = useSelector(state => state.billing);
  const { t } = useTranslation();
  return (
    <BillingFeesDetailsLeftWrapper className="flex-column items-start justify-start row-gap-4 overflow-scroll">
      <div className="flex-column w-full border radius-4">
        <div className="flex items-center px-6 py-4 border-bottom">
          <label className="medium-text font-20 fw-500">{t('PRODUCT_DETAILS')}</label>
        </div>
        <div className="flex-column items-start justify-start w-full pxy-6">
          <div className="flex-column items-center justify-start row-gap-1">
            <label className="normal-text grey-text font-12 w-full">{t('PRODUCT_NAME')}</label>
            <label className="semibold-text font-16 w-full">{productDetails?.product?.name}</label>
          </div>
          <div className="flex-column items-center justify-start row-gap-1 mt-6">
            <label className="normal-text grey-text font-12 w-full">{t('ID')}</label>
            <label className="normal-text w-full">{productDetails?.product?.id}</label>
          </div>
          <div className="flex-column items-center justify-start row-gap-1 mt-6">
            <label className="normal-text grey-text font-12 w-full">{t('CLIENT')}</label>
            <label className="normal-text w-full">{productDetails?.organization?.name}</label>
          </div>
          <div className="flex w-full mt-5">
            <div className="flex-column items-start justify-start w-50 ">
              <label className="normal-text grey-text font-12 w-full">{t('AMOUNT')}</label>
              <label className="normal-text w-full">{`${getCurrencySymbol(productDetails?.pricing?.currency_code)}${
                productDetails?.pricing?.price
              }`}</label>
            </div>
            <div className="flex-column items-start justify-start w-50 ">
              <label className="normal-text grey-text font-12 w-full">{t('FREQUENCY')}</label>
              <label className="normal-text w-full">
                {getPaymentFrequencyText(productDetails?.pricing?.recurring_period)}
              </label>
            </div>
          </div>
        </div>
      </div>
      {(productDetailsPrizedraw?.content || [])?.map(item => (
        <PrizedrawInfo key={item?.id} prizedraw={item} />
      ))}
    </BillingFeesDetailsLeftWrapper>
  );
};

export default BillingFeesDetailsLeft;
