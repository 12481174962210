import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AccountDetailsRightWrapper } from '../../../../styles/components/group-details/group-detail.styled';
import PrizeDrawAccess from './prize-draw-access';

const AccountDetailsRight = () => {
  return (
    <AccountDetailsRightWrapper className="flex-column border flex-1 radius-4">
      <Routes>
        <Route path="/prize-draw-access" element={<PrizeDrawAccess />} />
      </Routes>
    </AccountDetailsRightWrapper>
  );
};

export default AccountDetailsRight;
