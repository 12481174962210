import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import Loader from '../../components/common/loader';
import FormList from '../../components/form-list';
import PageHeader from '../../components/page-header';
import { multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getFormList } from '../../store/features/prizeDrawSlice';
import { FormWrapper } from '../../styles/pages/forms.styled';

const Forms = () => {
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);
  const { formList } = useSelector(state => state.prizeDraw);
  const [search, setSearchText] = useState('');
  const [filters, setFilters] = useState([{ id: uuid() }]);
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);

  const createFormFilterUrl = filters => {
    let url = ``;
    filters.forEach(item => {
      url = item.value
        ? url.concat(
            `&${item.param}=${
              item.param === 'prizedraw_client_code' ? item.value.prizedraw_client_code : item.value.id
            }`,
          )
        : url;
    });
    return url;
  };

  const getForms = currentPage => {
    const url = createFormFilterUrl(filters);
    const lastObject = filters[filters.length - 1];
    if (lastObject && lastObject.value) {
      setLoading(true);
    }
    dispatch(getFormList({ params: { search: debouncedSearch, page: currentPage }, url: url }))
      .then(response => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getForms(0);
  }, [debouncedSearch]);

  useEffect(() => {
    getForms(selectedPage);
  }, [selectedPage, filters]);

  const handleAddForms = () => {
    setModal({ type: 'add-form', content: {}, onSuccess: () => getForms(selectedPage) });
  };

  return (
    <FormWrapper className="flex-column flex-1">
      <PageHeader
        names={[
          { text: 'Prize Draws', onClick: () => {} },
          { text: 'Forms', onClick: () => {} },
        ]}
        showMultipleFilter
        showSearch
        showPagination
        showAddNewBtn
        multiFilter={filters}
        setMultiFilter={data => setFilters(data)}
        searchText={search}
        onSearchChange={setSearchText}
        pagination={{
          selectedPage: selectedPage,
          totalPages: formList?.total_pages || 0,
          setSelectedPage: setSelectedPage,
        }}
        multiFilterFor={multipleFilterTypeEnum.formsList}
        addBtnPermission="FORMS_MANAGE"
        onAddClick={() => handleAddForms()}
      />
      {loading ? <Loader height={64} width={64} /> : <FormList debouncedSearch={debouncedSearch} filters={filters} />}
    </FormWrapper>
  );
};

export default Forms;
