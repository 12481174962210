import styled from 'styled-components';

export const PrizeDrawListWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  overflow: scroll;
`;

export const PrizeDrawItemWrapper = styled.div`
  display: grid;
  grid-template-columns:
    minmax(170px, 1fr) minmax(170px, 2fr) minmax(100px, 120px) minmax(100px, 120px) minmax(100px, 120px)
    minmax(40px, 40px);
  min-height: 64px;
  max-height: 64px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  padding: 8px 16px;

  .empty-img-content {
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.hoverColor};
  }

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  }

  .prize-draw-lifecycle {
    padding: 3px 8px;
    width: fit-content;
    border-radius: 20px;

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
    }
  }

  .tooltip-content {
    padding: 6px 15px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px ${({ theme }) => theme.colors.shadowColor};

    &:before {
      display: none;
    }
  }

  // NOTE: Lifecycle class
  .active {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenPositiveText};
    }

    .dot {
      background: ${({ theme }) => theme.colors.ZenPositive};
    }
  }

  .inactive {
    background: ${({ theme }) => theme.colors.ZenGray2};

    span {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }

    .dot {
      background: ${({ theme }) => theme.colors.ZenDefault};
    }
  }
`;
