const themeHelper = {
  down: breakpoint => `@media screen and (max-width: ${breakpoint})`,
  up: breakpoint => `@media screen and (min-width: ${breakpoint})`,
  breakpoints: {
    lg: '1024px',
    xl: '1920px',
  },
};

export const light = {
  ...themeHelper,
  colors: {
    primary: '#4851f4',
    backgroundColor: '#F5F9FC',
    nav: '#f8f8f8',
    border: '#deebf1',
    text: '#202224',
    sidebarBackground: '#FCFEFF',
    hoverColor: '#f5f9fc80',
    shadowColor: 'rgba(5, 49, 73, 0.08)',
    modalBackground: 'rgba(22, 25, 44, 0.8)',
    divider: '#E2E7EF',
    backgroundColor_05: 'rgba(245, 249, 252, 0.5)',
    accountAccessHover: '#FDFDFE',

    ZenDefault: '#2B2B7C',
    zenPurple: '#8927EF',
    zenPink: '#EF7ABD',
    zenGray: '#A0A0A0',

    ZenDefaultSecondary: '#DBDBFF',
    ZenDefaultSecondary_O3: 'rgb(219, 219, 255, 0.3)',
    ZenPurpleSecondary: '#E8D1FF',
    ZenPinkSecondary: '#FFD8EE',
    ZenPinkSecondary_O4: 'rgba(255, 216, 238, 0.4)',
    ZenGraySecondary: '#D0D5DD',
    ZenGraySecondary_05: 'rgba(208, 213, 221, 0.5)',
    ZenPurpleSecondary2: '#F4EBFF',
    ZenPurple3: '#FBF6FF',

    RegularText: '#16192C',
    LighterText: '#6B7280',
    ZenGray2: '#ECEEF3',
    ZenLightGray: '#F2F4F7',
    Gray2: '#73868C',
    LightGrey: '#D2E5ED',

    ZenPositiveText: '#1E7021',
    ZenPositive: '#29CC97',
    ZenPositiveSecondary: '#DAFFF3',
    ZenPositive_05: 'rgba(218, 255, 243, 0.5)',
    ZenPositiveDark: '#22A57A',

    ZenOrangeText: '#F27C0E',
    ZenOrange: '#F8B133',
    ZenOrangeSecondary: '#FFFAE1',
    ZenOrange_05: 'rgba(255, 250, 225, 0.5)',

    ZenNegative: '#FF406E',
    ZenNegativeSecondary: '#FFD5DF',
    ZenNegativeSecondary2: '#FFD4DF',
    ZenNegativeSecondary_O3: 'rgba(255, 212, 223, 0.3)',

    ZenNegativeSecondary_O4: 'rgba(255, 212, 223, 0.3)',

    ZenBlue: '#7BB2FD',
    ZenInfo: '#3F8FFC',

    ZenRed: '#FF4040',
    ZenRedSecondary: '#FFE7E7',
    ZenPinkSecondary2: '#FFECF7',
    ZenPink2: '#FF40A7',
    ZenPurpleSecondary2: '#F2E4FF',

    ZenGray2Secondary: '#EBF0F6',

    customStatusBackground: '#DBECFF',
    customStatusText: '#43548E',

    additionalRed: '#FFF0F0',
    additionalGreen: '#E6FAF3',
    additionalYellow: '#FAF4E7',
    ZenBlueSecondary: '#DEECFF',
    ZenDefaultBackground2: '#E3E3FF',

    LightBlueBackground: '#DDF9FF',
    LightBlue: '#00677D',
    ZenPinkBackground: '#FFE9F7',
    ZenPink: '#E50097',
    Gray3: '#F4F7F9',
    Gray4: '#E3EEF3',

    ZenBlue2: '#DEECFF99',
    ZenTransparent: 'transparent',

    white: '#fff',
  },
};

export const dark = {
  ...themeHelper,
  colors: {
    primary: '#4851f4',
    backgroundColor: '#222',
    nav: '#27282b',
    border: '#303236',
    text: '#f8f8f8',
    sidebarBackground: '#FCFEFF',
    accountAccessHover: '#FDFDFE',
    divider: '#E2E7EF',

    ZenDefault: '#2B2B7C',
    zenPurple: '#8927EF',
    zenPink: '#EF7ABD',
    zenGray: '#A0A0A0',

    ZenDefaultSecondary: '#DBDBFF',
    ZenPurpleSecondary: '#E8D1FF',
    ZenPinkSecondary: '#FFD8EE',
    ZenPinkSecondary_O4: 'rgba(255, 216, 238, 0.4)',
    ZenGraySecondary: '#D0D5DD',
    ZenGraySecondary_05: 'rgba(208, 213, 221, 0.5)',
    ZenPurpleSecondary2: '#F4EBFF',
    ZenPurple3: '#FBF6FF',
    RegularText: '#16192C',
    LighterText: '#6B7280',
    ZenGray2: '#ECEEF3',
    ZenLightGray: '#F2F4F7',

    ZenPositiveText: '#1E7021',
    ZenPositive: '#29CC97',
    ZenPositiveSecondary: '#DAFFF3',
    ZenPositive_05: 'rgba(218, 255, 243, 0.5)',

    ZenOrangeText: '#F27C0E',
    ZenOrange: '#F8B133',
    ZenOrangeSecondary: '#FFFAE1',
    ZenOrange_05: 'rgba(255, 250, 225, 0.5)',

    ZenNegative: '#FF406E',
    ZenNegativeSecondary: '#FFD5DF',
    ZenNegativeSecondary2: '#FFD4DF',

    ZenBlue: '#7BB2FD',
    ZenInfo: '#3F8FFC',

    customStatusBackground: '#DBECFF',
    customStatusText: '#43548E',

    additionalRed: '#FFF0F0',
    additionalGreen: '#E6FAF3',
    additionalYellow: '#FAF4E7',
    ZenBlueSecondary: '#DEECFF',
    ZenTransparent: 'transparent',
  },
};
