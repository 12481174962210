import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as DropdownIcon } from '../../../assets/images/dropdown.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/multiple-users.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { getItemFromLocalStorage } from '../../../helpers/localstorage';
import { AddOrganization } from '../../../store/features/prizeDrawSlice';
import { addToast } from '../../../store/features/toastSlice';
import api from '../../../store/services';
import { PrizeDrawOrganizationItemWrapper } from '../../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Button from '../../Elements/button/button';
import ReactSelectDropdownComponent from '../../Elements/react-select-dropdown';
import Menu from '../../common/menu';
import UpdateAction from '../../common/update-action';

const PrizeDrawOrganizationItem = ({
  prizeDrawOrganization,
  setAddOrganization,
  showAddOrganization,
  showAddMarketingCompany,
  setShowAddMarketingCompany,
}) => {
  const [showSubItem, setShowSubItem] = useState(false);
  const [showAddMarketing, setShowAddMarketing] = useState(false);
  const [organizationCategories, setOrganizationCategories] = useState([]);
  const [organizationsNetwork, setOrganizationNetworks] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const organisation = getItemFromLocalStorage('user')?.organizations[0];
  const { hasPermission, setModal, checkPermission } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getOrganizationCategoryIds = () => {
    return [...organizationCategories].map(data => data.id).join(',');
  };

  const fetchOrganizationNetworks = async () => {
    try {
      const { data } = await api.get(`/organizations/${prizeDrawOrganization?.id}/network`, {
        params: {
          organization_category_id: getOrganizationCategoryIds(),
        },
      });
      const updatedData = {
        ...data,
        content: (data.content || []).map(item => ({ ...item, label: item.name, value: item.id })),
      };
      setOrganizationNetworks(updatedData);
    } catch (error) {}
  };

  const handleAddMarketingCompany = async () => {
    setShowAddMarketing(true);
    setShowSubItem(true);
    setShowAddMarketingCompany(prizeDrawOrganization?.id);
    setAddOrganization(false);
    await fetchOrganizationNetworks();
  };

  const resetData = () => {
    setShowSubItem(false);
    setShowAddMarketingCompany(prizeDrawOrganization?.id);
    setSelectedOrganization(null);
    setOrganizationNetworks([]);
  };

  const handleAddOrganization = () => {
    if (selectedOrganization) {
      dispatch(
        AddOrganization({
          params: { organization: { id: selectedOrganization.id }, parent: { id: prizeDrawOrganization.id } },
          prizedrawid: id,
        }),
      ).then(() => {
        setShowAddMarketingCompany('');
        setSelectedOrganization(null);
        setOrganizationNetworks([]);
      });
    } else {
      dispatch(addToast({ error: true, text: 'Please select organization', id: uuid() }));
      return;
    }
  };

  const handleRemoveAgency = () => {
    setModal({
      type: 'agency-action',
      content: {
        agency: prizeDrawOrganization,
        prizedrawid: id,
        top: true,
        isDelete: true,
        title: 'Are you sure you want to remove this agency?',
      },
    });
  };

  const handleRemoveMarketingCompany = data => {
    setModal({
      type: 'marketing-company-actions',
      content: {
        marketingCompany: data,
        agency: prizeDrawOrganization,
        prizedrawid: id,
        top: true,
        isDelete: true,
        title: 'Are you sure you want to remove this marketing company?',
      },
    });
  };

  const getOrganizationCategories = async () => {
    if (prizeDrawOrganization?.id) {
      try {
        const { data } = await api.get(
          `/prizedraws/organizations/${prizeDrawOrganization?.id}/organization_categories`,
        );
        setOrganizationCategories(data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    getOrganizationCategories();
  }, []);

  const saasOrganizationTypeItem = (
    <>
      <div
        className="w-full list-container border-bottom cursor"
        onClick={() =>
          checkPermission(
            () => navigate(`account-details/${prizeDrawOrganization?.id}/prize-draw-access`),
            'ORGANIZATIONS_VIEW',
          )
        }>
        <div className="border-right flex-column items-start px-6 overflow-hidden justify-center w-full">
          <label className="regular-text font-14 one-line">{prizeDrawOrganization?.name}</label>
          <div className="flex items-center justify-center status-container mt-2">
            <label className="medium-text one-line lighter-text font-10">{prizeDrawOrganization?.category?.name}</label>
          </div>
        </div>
        <div className="border-right flex-column items-start px-6 justify-center w-full">
          <div className="flex items-center justify-start w-full">
            <div className="flex items-center justify-center icon-wrapper radius-2">
              <MailIcon className="flex items-center justify-center " height={16} width={16} />
            </div>
            <label
              className={`regular-text one-line font-14 ml-2 ${
                prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                  ? ''
                  : 'grey-text'
              }`}>
              {prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                ? prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
                : 'Not provided'}
            </label>
          </div>
          <div className="flex items-center justify-start w-full mt-1">
            <div className="flex items-center justify-center icon-wrapper radius-2">
              <MobileIcon className="flex items-center justify-center " height={16} width={16} />
            </div>
            <label
              className={`regular-text one-line font-14 ml-2 ${
                prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                  ? ''
                  : 'grey-text'
              }`}>
              {prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                ? prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
                : 'Not provided'}
            </label>
          </div>
        </div>
        <div className="border-right flex items-center px-6 justify-start w-full">
          <label className="regular-text font-14 text-overflow-2">
            {prizeDrawOrganization?.primary_location?.formatted_address}
          </label>
        </div>
        <div className="flex items-center px-6 justify-between w-full">
          <div className="flex items-center justify-start flex-1">
            <UsersIcon />
            <label className="regular-text font-14 ml-2">
              {prizeDrawOrganization?.child_organizations?.length
                ? prizeDrawOrganization?.child_organizations?.length
                : 0}
            </label>
            <DropdownIcon
              className={`flex items-center justify-center ml-3 cursor ${showSubItem ? 'rotate-180' : ''}`}
              onClick={e => {
                e.stopPropagation();
                showSubItem ? resetData() : setShowSubItem(!showSubItem);
              }}
            />
          </div>
          {hasPermission('PRIZEDRAWS_ORGANIZATIONS_MANAGE') && (
            <Menu
              menuList={[
                {
                  name: t('DETAILS'),
                  onClick: () => navigate(`account-details/${prizeDrawOrganization?.id}/prize-draw-access`),
                  permission: 'ORGANIZATIONS_VIEW',
                },
                {
                  name: t('REMOVE'),
                  onClick: () => handleRemoveAgency(),
                  permission: 'PRIZEDRAWS_ORGANIZATIONS_MANAGE',
                },
              ]}
              ExtraOptions={
                <div className="flex items-center justify-between px-2 pt-4 pb-2 mt-2 w-full border-top">
                  <Button
                    className={`default-secondary w-full ${hasPermission('') && 'disabled'}`}
                    fontSize="14px"
                    label={
                      organizationCategories.length === 1
                        ? `Add ${organizationCategories[0].name}`
                        : 'Add new organization'
                    }
                    borderRadius={'12px'}
                    size={'medium'}
                    onClick={() => handleAddMarketingCompany()}
                    icon={<PlusIcon height={16} width={16} />}
                  />
                </div>
              }
            />
          )}
        </div>
      </div>
      {showSubItem &&
        prizeDrawOrganization?.child_organizations?.map(data => (
          <div
            className="list-container-3-col sub-item-container w-full border-bottom cursor"
            key={data.id}
            onClick={() =>
              checkPermission(() => navigate(`account-details/${data?.id}/prize-draw-access`), 'ORGANIZATIONS_VIEW')
            }>
            <div className="border-right flex-column items-start px-6 overflow-hidden justify-center w-full">
              <label className="regular-text font-14 one-line">{data?.name}</label>
              <div className="flex items-center justify-center subitem-status-container mt-2">
                <label className="medium-text one-line lighter-text font-10">{data?.category?.name}</label>
              </div>
            </div>
            <div className="border-right flex-column items-start px-6 justify-center w-full">
              <div className="flex items-center justify-start w-full">
                <div className="flex items-center justify-center icon-wrapper radius-2">
                  <MailIcon className="flex items-center justify-center " height={16} width={16} />
                </div>
                <label
                  className={`regular-text one-line font-14 ml-2 ${
                    data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value ? '' : 'grey-text'
                  }`}>
                  {data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value
                    ? data?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value
                    : 'Not provided'}
                </label>
              </div>
              <div className="flex items-center justify-start w-full mt-1">
                <div className="flex items-center justify-center icon-wrapper radius-2">
                  <MobileIcon className="flex items-center justify-center " height={16} width={16} />
                </div>
                <label
                  className={`regular-text one-line font-14 ml-2 ${
                    data?.contacts?.find(item => item?.contact_type === 'MOBILE')?.contact_value ? '' : 'grey-text'
                  }`}>
                  {data?.contacts?.find(item => item?.contact_type === 'MOBILE')?.contact_value
                    ? data?.contacts?.find(item => item?.contact_type === 'MOBILE')?.contact_value
                    : 'Not provided'}
                </label>
              </div>
            </div>
            <div className="flex items-center px-6 justify-between w-full">
              <label className="regular-text font-14 flex-1 text-overflow-2">
                {data?.primary_location?.formatted_address}
              </label>
              <Menu
                menuClassName="w-auto h-full flex items-center"
                menuList={[
                  {
                    name: t('DETAILS'),
                    onClick: () => navigate(`account-details/${data?.id}/prize-draw-access`),
                    permission: 'ORGANIZATIONS_VIEW',
                  },
                  {
                    name: t('REMOVE'),
                    onClick: () => handleRemoveMarketingCompany(data),
                    permission: 'PRIZEDRAWS_ORGANIZATIONS_MANAGE',
                  },
                ]}
              />
            </div>
          </div>
        ))}

      {showAddMarketingCompany === prizeDrawOrganization?.id && !showAddOrganization && showAddMarketing && (
        <div className="flex-column items-start justify-start sub-item-container py-4 px-6 w-full border-bottom relative">
          <label className="regular-text grey-text font-12 mb-1">{t('MARKETING_COMPANY')}</label>
          <ReactSelectDropdownComponent
            isSearchable={true}
            options={organizationsNetwork?.content?.filter(
              org => !prizeDrawOrganization?.child_organizations?.some(childOrg => childOrg.id === org.id),
            )}
            selectedValue={selectedOrganization}
            onChange={selectedOption => setSelectedOrganization(selectedOption)}
          />
          <UpdateAction
            className={`absolute update-action ${selectedOrganization && 'update-action-top'}`}
            onCancel={() => {
              setSelectedOrganization(null);
              setOrganizationNetworks([]);
              setShowAddMarketingCompany('');
              setShowAddMarketing(false);
            }}
            onUpdate={() => handleAddOrganization()}
          />
        </div>
      )}
    </>
  );

  const normalOrgannizationTypeItem = (
    <div
      className="list-container-3-col w-full border-bottom cursor"
      onClick={() =>
        checkPermission(
          () => navigate(`account-details/${prizeDrawOrganization?.id}/prize-draw-access`),
          'ORGANIZATIONS_VIEW',
        )
      }>
      <div className="border-right flex-column items-start px-6 overflow-hidden justify-center w-full">
        <label className="regular-text font-14 one-line">{prizeDrawOrganization?.name}</label>
        <div className="flex items-center justify-center status-container mt-2">
          <label className="medium-text one-line lighter-text font-10"> {prizeDrawOrganization?.category?.name}</label>
        </div>
      </div>
      <div className="border-right flex-column items-start px-6 justify-center w-full">
        <div className="flex items-center justify-start w-full">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MailIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label
            className={`regular-text one-line font-14 ml-2 ${
              prizeDrawOrganization?.contacts?.find(item => item?.contact_type === 'EMAIL')?.contact_value
                ? ''
                : 'grey-text'
            }`}>
            {prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
              ? prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'EMAIL')?.contact_value
              : 'Not provided'}
          </label>
        </div>
        <div className="flex items-center justify-start w-full mt-1">
          <div className="flex items-center justify-center icon-wrapper radius-2">
            <MobileIcon className="flex items-center justify-center " height={16} width={16} />
          </div>
          <label
            className={`regular-text one-line font-14 ml-2 ${
              prizeDrawOrganization?.contacts?.find(item => item?.contact_type === 'MOBILE')?.contact_value
                ? ''
                : 'grey-text'
            }`}>
            {prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
              ? prizeDrawOrganization?.contacts?.find(data => data?.contact_type === 'MOBILE')?.contact_value
              : 'Not provided'}
          </label>
        </div>
      </div>
      <div className="flex items-center px-6 justify-between w-full">
        <label className="regular-text font-14 flex-1 text-overflow-2">
          {prizeDrawOrganization?.primary_location?.formatted_address}
        </label>
        <Menu
          menuClassName="h-full flex items-center"
          menuList={[
            {
              name: t('DETAILS'),
              onClick: () => navigate(`account-details/${prizeDrawOrganization?.id}/prize-draw-access`),
              permission: 'ORGANIZATIONS_VIEW',
            },
            { name: t('REMOVE'), onClick: () => handleRemoveAgency(), permission: 'PRIZEDRAWS_ORGANIZATIONS_MANAGE' },
          ]}
        />
      </div>
    </div>
  );
  return (
    <PrizeDrawOrganizationItemWrapper className={`flex-column items-start flex-start w-full`}>
      {organisation.organization_type === 'SAAS_OPERATOR' ? saasOrganizationTypeItem : normalOrgannizationTypeItem}
    </PrizeDrawOrganizationItemWrapper>
  );
};

export default PrizeDrawOrganizationItem;
