import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { camelCase, capitalize, formatText, getPerformedByFromLog } from '../../../../helpers/utils';
import Status from '../../../common/status';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizedrawFormVersionLog = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const {
    event_data: {
      form,
      form_version,
      client,
      prizedraw,
      previous: { form_version: prevVersion },
    },
    event_type,
    performed_by,
  } = log;
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  const getHeaderText = () => {
    if (event_type === 'PRIZEDRAW_FORM_VERSION_DUPLICATED') {
      return 'Version duplicated';
    } else if (prevVersion?.status === 'PUBLISHED' && form_version?.status === 'RETIRED') {
      return 'Version retired';
    } else if (prevVersion?.status === 'DRAFT' && form_version?.status === 'PUBLISHED') {
      return 'Version published';
    } else if (prevVersion?.status === 'RETIRED' && form_version?.status === 'PUBLISHED') {
      return 'Version republished';
    }
  };

  const getLogTitleText = () => {
    if (prevVersion?.status === 'PUBLISHED' && form_version?.status === 'RETIRED') {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('RETIRED_FORM_VERSION')}</span>
          <span className="semibold-text lighter-text font-12 ml-1">V{form_version.version_no}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('IN_PRIZE_DRAW')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
        </span>
      );
    } else if (prevVersion?.status === 'DRAFT' && form_version?.status === 'PUBLISHED') {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">published form version</span>
          <span className="semibold-text lighter-text font-12 ml-1">V{form_version.version_no}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('IN_PRIZE_DRAW')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
        </span>
      );
    } else if (prevVersion?.status === 'RETIRED' && form_version?.status === 'PUBLISHED') {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">republished form version</span>
          <span className="semibold-text lighter-text font-12 ml-1">V{form_version.version_no}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('IN_PRIZE_DRAW')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
        </span>
      );
    } else if (event_type === 'PRIZEDRAW_FORM_VERSION_DUPLICATED') {
      return (
        <span>
          <span className="regular-text lighter-text font-12">{t('USER')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
          <span className="regular-text lighter-text font-12 ml-1">duplicated form version</span>
          <span className="semibold-text lighter-text font-12 ml-1">V{form_version.version_no}</span>
          <span className="regular-text lighter-text font-12 ml-1">{t('IN_PRIZE_DRAW')}</span>
          <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
        </span>
      );
    }
  };

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={getHeaderText()}
        subtitle={performed_by?.organization?.name}
      />
      <LogTitle text={getLogTitleText()} showDetails={showDetails} setShowDetails={data => setShowDetails(data)} />
      {showDetails && (
        <>
          {event_type !== 'PRIZEDRAW_FORM_VERSION_DUPLICATED' && (
            <LogTitle
              showDownIcon={false}
              text={
                <span className="flex items-center">
                  <span className="semibold-text lighter-text cursor font-12">V{form_version.version_no}</span>
                  <span className="regular-text lighter-text font-12 cursor ml-1">{t('STATUS_UPDATED_FROM')}</span>
                  <Status
                    withDot={true}
                    status={prevVersion?.status}
                    statusText={capitalize(camelCase(formatText(prevVersion?.status, ' ')))}
                    statusWrapperClassName={'ml-1'}
                  />
                  <span className="regular-text lighter-text font-12 ml-1">to</span>
                  <Status
                    withDot={true}
                    status={form_version?.status}
                    statusText={capitalize(camelCase(formatText(form_version?.status, ' ')))}
                    statusWrapperClassName={'ml-1'}
                  />
                </span>
              }
            />
          )}
          <LogContentBox
            title={'Form'}
            contentText={<p className="regular-text lighter-text font-12">{form?.name}</p>}
          />
          <LogContentBox
            title={'Client'}
            contentText={
              <div className="flex items-center w-full">
                <p className="regular-text lighter-text font-12">{client?.name}</p>
                <p className="medium-text lighter-text font-12 ml-8">{client?.code}</p>
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

export default PrizedrawFormVersionLog;
