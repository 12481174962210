import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from '../../../../assets/images/user.svg';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantPaymentMethodUpdate = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader icon={<UserIcon height={16} width={16} />} title={'Payment method updated'} subtitle={'Entrant'} />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {log?.event_data?.entrant?.urn}
            </span>
            <span className="regular-text lighter-text font-12 ml-1">updated their payment method</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'From'}
            contentText={<p className="regular-text lighter-text font-12">{t('DEBIT_CARD')}</p>}
          />
          <LogContentBox
            title={'To'}
            contentText={<p className="regular-text lighter-text font-12">{t('US_BANK_ACCOUNT')}</p>}
          />
        </>
      )}
    </div>
  );
};

export default EntrantPaymentMethodUpdate;
