import moment from 'moment';
import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as AvatarImg } from '../../../../../assets/images/avatar.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/images/edit.svg';
import { OrganisationContext } from '../../../../../context/organisationContext';
import { getInitialsSingleChar, isDataChanged } from '../../../../../helpers/utils';
import { addToast } from '../../../../../store/features/toastSlice';
import UpdateAction from '../../../../common/update-action';

const NoteItem = ({ note, isLast = false, setEditNote = () => {}, editNote = null, handleUpdateNote = () => {} }) => {
  const { hasPermission } = useContext(OrganisationContext);
  const dispatch = useDispatch();

  return (
    <div className="flex w-full col-gap-3 notes-item">
      <div className="flex-column items-center row-gap-1">
        <label className="medium-text font-12 lighter-text">{moment(note?.created_on * 1000).format('hh:mm a')}</label>
        {!isLast && <div className="vertical-line" />}
      </div>
      <div className="flex-column row-gap-1 flex-1">
        <div className="flex col-gap-2">
          <div className="flex col-gap-1">
            {note?.owner?.user?.avatar_url ? (
              <img
                src={note?.owner?.user?.avatar_url}
                alt="profile"
                className="profile-icon mr-2 flex items-center justify-center radius-4"
                style={{ width: '20px', height: '20px' }}
              />
            ) : getInitialsSingleChar(note?.owner?.user || {}) ? (
              <div
                className="user-icon flex items-center justify-center empty-img nots-user-image radius-4"
                style={{ width: '20px', height: '20px' }}>
                <span className="font-14 bold-text grey-text">{getInitialsSingleChar(note?.owner?.user || {})}</span>
              </div>
            ) : (
              <AvatarImg height={20} width={20} />
            )}

            <label className="medium-text regular-text font-12">{note?.owner?.user?.name}</label>
          </div>
          <div className="flex">
            <label className="regular-text font-12 lighter-text">{note?.owner?.organization?.name}</label>
          </div>
        </div>
        {editNote?.id === note.id ? (
          <div className="update-detail-contaner relative">
            <textarea
              className="textarea w-full note-item-textbox"
              value={editNote?.note}
              onChange={({ target }) => setEditNote({ ...editNote, note: target.value })}
              placeholder="Add Notes"
              rows={2}
              autoFocus
            />
            <div className="overflow-scroll h-full">
              <UpdateAction
                onCancel={() => setEditNote(null)}
                onUpdate={() => {
                  if (editNote.note.trim() === '' || !editNote?.note) {
                    dispatch(addToast({ error: true, text: 'Please enter some text into the note before saving' }));
                    return;
                  }
                  isDataChanged(
                    'INPUT',
                    editNote?.note.trim() || '',
                    editNote?.prevNote.trim() || '',
                    handleUpdateNote,
                    () => setEditNote(null),
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={`pxy-4 flex items-start justify-start ${
              hasPermission('ENTRANT_NOTES_MANAGE') ? 'notes-item-text' : 'not-edit-notes-item-text'
            } radius-4 cursor mr-1`}
            onClick={() => setEditNote({ ...note, prevNote: note?.note })}>
            <label className="regular-text font-12 lighter-text flex-1 break-word">{note?.note}</label>
            <EditIcon width={16} height={16} className="ml-2 edit-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(NoteItem);
