import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getCurrencySymbol, getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const ClientProductFeeUpdated = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [data, setData] = useState({});
  const { event_data, performed_by } = log;
  const { client_product_fee, previous, client_product } = event_data;
  const performedBy = getPerformedByFromLog(log);
  const { t } = useTranslation();

  const getTitleText = () => {
    return (
      <span>
        <span className="regular-text lighter-text font-12 ml-1">{t('USER')}</span>
        <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
        <span className="regular-text lighter-text font-12 ml-1">{t('UPDATED')}</span>
        {client_product_fee?.billing_type !== previous?.client_product_fee?.billing_type && (
          <span className="semibold-text lighter-text font-12 ml-1">
            {client_product_fee?.billing_type === 'VARIABLE' ? 'continual billing' : 'cohort billing'}
          </span>
        )}
        <span className="regular-text lighter-text font-12 ml-1">{data?.title}</span>
      </span>
    );
  };

  const setDetails = () => {
    let updateData = {};
    if (client_product_fee?.dates?.start !== previous?.client_product_fee?.dates?.start) {
      updateData = {
        title: 'start date',
        from: previous?.client_product_fee?.dates?.start
          ? moment(previous?.client_product_fee?.dates?.start * 1000).format('MMM DD, YYYY')
          : '',
        to: client_product_fee?.dates?.start
          ? moment(client_product_fee?.dates?.start * 1000).format('MMM DD, YYYY')
          : '',
      };
    } else if (client_product_fee?.dates?.end !== previous?.client_product_fee?.dates?.end) {
      updateData = {
        title: 'end date',
        from: previous?.client_product_fee?.dates?.end
          ? moment(previous?.client_product_fee?.dates?.end * 1000).format('MMM DD, YYYY')
          : '',
        to: client_product_fee?.dates?.end ? moment(client_product_fee?.dates?.end * 1000).format('MMM DD, YYYY') : '',
      };
    } else if (client_product_fee?.billing_type !== previous?.client_product_fee?.billing_type) {
      updateData = {
        title: 'billing type',
        from: previous?.client_product_fee?.billing_type,
        to: client_product_fee?.billing_type,
      };
    }
    if (client_product_fee?.fee_amount !== previous?.client_product_fee?.fee_amount) {
      updateData = {
        title: 'fee amount',
        from: previous?.client_product_fee?.fee_amount,
        to: client_product_fee?.fee_amount,
      };
    }
    setData(updateData);
  };

  useEffect(() => {
    setDetails();
  }, []);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Fee updated'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle text={getTitleText()} showDetails={showDetails} setShowDetails={data => setShowDetails(data)} />
      {showDetails && (
        <>
          {client_product_fee?.fee_amount !== previous?.client_product_fee?.fee_amount ? (
            ''
          ) : (
            <LogContentBox
              title={'Fee'}
              contentText={
                <p className="flex regular-text lighter-text font-12">
                  {`${getCurrencySymbol(client_product_fee?.currency_code)}${client_product_fee?.fee_amount}`}
                </p>
              }
            />
          )}
          <LogContentBox
            title={'From'}
            contentText={<p className="flex regular-text lighter-text font-12">{data?.from}</p>}
          />
          <LogContentBox title={'To'} contentText={<p className="regular-text lighter-text font-12">{data?.to}</p>} />
          <LogContentBox
            title={'Client'}
            contentText={<p className="regular-text lighter-text font-12">{client_product?.organization?.name}</p>}
          />
          <LogContentBox
            title={'Product'}
            contentText={<p className="regular-text lighter-text font-12">{client_product?.product?.id || ''}</p>}
          />
        </>
      )}
    </div>
  );
};

export default ClientProductFeeUpdated;
