import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getCurrencySymbol, getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const PrizedrawPrizesUpdate = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const {
    event_data: {
      previous: { prizedraw: prevPrizedraw },
      prizedraw,
    },
  } = log || {};
  const performedBy = getPerformedByFromLog(log);
  const { config: prevConfig } = prevPrizedraw || {};
  const { config } = prizedraw || {};
  const { t } = useTranslation();

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Configuration updated'}
        subtitle={log?.tenant?.id || 'Zenterprize'}
      />
      <LogTitle
        text={
          <span>
            <span className="regular-text lighter-text font-12">{t('USER')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('UPDATED_PRIZE_DRAW')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{prizedraw?.code}</span>
            <span className="regular-text lighter-text font-12 ml-1">{t('PRIZE')}</span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'From'}
            contentText={
              <div className="flex-column w-full">
                {(prevConfig?.prizes || []).map(data => (
                  <p className="regular-text lighter-text font-12">{`Prize amount ${getCurrencySymbol(data?.currency)}${
                    data?.amount
                  }, Number of entrants: ${data?.entries}`}</p>
                ))}
              </div>
            }
          />
          <LogContentBox
            title={'To'}
            contentText={
              <div className="flex-column w-full">
                {(config?.prizes || []).map(data => (
                  <p className="regular-text lighter-text font-12">{`Prize amount ${getCurrencySymbol(data?.currency)}${
                    data?.amount
                  }, Number of entrants: ${data?.entries}`}</p>
                ))}
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

export default PrizedrawPrizesUpdate;
