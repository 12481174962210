import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserIcon } from '../../../../assets/images/user.svg';
import Status from '../../../common/status';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const EntrantWinStatusUpdate = ({ log = {} }) => {
  const { event_data } = log;
  const { previous, entrant } = event_data;
  const { t } = useTranslation();
  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader icon={<UserIcon />} title={'Win status updated'} />
      <LogTitle
        showDownIcon={false}
        text={
          <span className="flex items-center fw-w">
            <span className="regular-text lighter-text font-12">{t('ENTRANT')}</span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{entrant?.urn}</span>
            <span className="regular-text lighter-text font-12 ml-1">win status updated from</span>
            <Status withDot={true} status={previous?.entrant?.win_status} statusWrapperClassName={'ml-1'} />
            <span className="regular-text lighter-text font-12 ml-1">to</span>
            <Status withDot={true} status={entrant?.win_status} statusWrapperClassName={'ml-1'} />
          </span>
        }
      />
    </div>
  );
};

export default EntrantWinStatusUpdate;
