import { createSlice } from '@reduxjs/toolkit';
import api from '../../store/services';
export const billingSlice = createSlice({
  name: 'billing',
  initialState: {},
  reducers: {
    setFeesList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.feesList?.content, ...payload?.data?.content],
        };
        state.feesList = mergeData;
      } else {
        state.feesList = payload.data;
      }
    },
    setProductsList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.productsList?.content, ...payload?.data?.content],
        };
        state.productsList = mergeData;
      } else {
        state.productsList = payload.data;
      }
    },
    setProductDetails: (state, { payload }) => {
      state.productDetails = payload;
    },
    setProductDetailsPrizedraw: (state, { payload }) => {
      state.productDetailsPrizedraw = payload;
    },
    setProductDetailsFeesList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.productDetailsFeesList?.content, ...payload?.data?.content],
        };
        state.productDetailsFeesList = mergeData;
      } else {
        state.productDetailsFeesList = payload.data;
      }
    },
    setTransactionFeesList: (state, { payload }) => {
      state.transactionFeesList = payload.data;
    },
  },
});

export const getFeesList = payload => async dispatch => {
  try {
    const { search, sort_by, order_by, page, size } = payload?.params;
    const url = `/prizedraws/client_products?page=${page}&size=${size}&search=${search}&sort_by=${sort_by}&order_by=${
      order_by ? order_by : ''
    }${payload?.url && `&${payload?.url}`}`;
    const { data } = await api.get(url);
    dispatch(setFeesList({ data: data, merge: payload.merge }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getProductDetails = payload => async dispatch => {
  try {
    const { params } = payload;
    const { data } = await api.get(`/client_products/${params?.productDetailsId}`);
    dispatch(setProductDetails(data));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getProductDetailsPrizedraw = payload => async dispatch => {
  try {
    const { params } = payload;
    const { data } = await api.get(`/client_products/${params?.productDetailsId}/prizedraws`);
    dispatch(setProductDetailsPrizedraw(data));
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getProductDetailsFeesList = payload => async dispatch => {
  try {
    const { params, productDetailsId, merge, isPopup } = payload;
    const { data } = await api.get(`/client_products/${productDetailsId}/fees`, { params });
    if (!isPopup) {
      dispatch(setProductDetailsFeesList({ data: data, merge }));
    }
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createFees = payload => async dispatch => {
  try {
    const { request, product_id } = payload;
    const { data } = await api.post(`/client_products/${product_id}/fees`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteBillingFee = payload => async dispatch => {
  try {
    const { product_id, fee_id } = payload;
    const { data } = await api.delete(`/client_products/${product_id}/fees/${fee_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFees = payload => async dispatch => {
  try {
    const { request, product_id, fee_id } = payload;
    const { data } = await api.put(`/client_products/${product_id}/fees/${fee_id}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createdProducts = payload => async dispatch => {
  try {
    const { data } = await api.post(`/client_products`, { ...payload?.request });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createProductFees = payload => async dispatch => {
  try {
    const { productId, request } = payload;
    const { data } = await api.post(`/client_products/${productId}/fees`, { ...request });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProductList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/products`, { params: payload?.params });
    dispatch(setProductsList({ data: data, merge: payload.merge }));
  } catch (error) {
    return Promise.reject(err);
  }
};

export const getTransactionFeesList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/transaction_fees`, { params: payload?.params });
    dispatch(setTransactionFeesList({ data: data }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const exportTransactionFeeList = payload => async () => {
  try {
    const { data } = await api.get(`/transaction_fees/export`, { params: payload?.params });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const getEntrantFeesList = payload => async dispatch => {
  try {
    const { params, entrantId } = payload;
    const { data } = await api.get(`/entrants/${entrantId}/fees`, { params });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const {
  setFeesList,
  setProductDetails,
  setProductDetailsPrizedraw,
  setProductDetailsFeesList,
  setTransactionFeesList,
  setProductsList,
} = billingSlice.actions;
export default billingSlice.reducer;
