import React from 'react';
import { useSelector } from 'react-redux';
import { AutomationListWrapper } from '../../styles/components/automation-list/automation-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import AutomationListItem from './automation-list-item';

const AutomationList = ({ debouncedSearch = '', getAutomationData = () => {} }) => {
  const { automationsList } = useSelector(state => state.automations);
  const { content: automations } = automationsList || {};
  return (
    <AutomationListWrapper className="flex-column flex-1 overflow-scroll border-top">
      {(automations || []).length > 0 ? (
        (automations || [])?.map(automation => (
          <AutomationListItem automation={automation} key={automation?.id} getAutomationData={getAutomationData} />
        ))
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </AutomationListWrapper>
  );
};

export default AutomationList;
