import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingIcon } from '../../../../assets/images/logs/buildings.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const GroupAdded = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data, performed_by } = log;
  const { t } = useTranslation();
  const performedBy = getPerformedByFromLog(log);

  const getitleText = (
    <>
      <span className="regular-text lighter-text font-12">{t('USER')}</span>
      <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">{performedBy}</span>
      <span className="regular-text lighter-text font-12 ml-1">{t('ADDED_A_GROUP')}</span>
    </>
  );

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<BuildingIcon height={16} width={16} />}
        title={'Group added'}
        subtitle={performed_by?.organization?.name ? performed_by?.organization?.name : 'Action'}
      />
      <LogTitle
        text={<span>{getitleText}</span>}
        setShowDetails={data => setShowDetails(data)}
        showDetails={showDetails}
      />
      {showDetails && (
        <LogContentBox
          title={'Group'}
          contentText={<p className="regular-text lighter-text font-12">{event_data?.organization_category?.name}</p>}
        />
      )}
    </div>
  );
};

export default GroupAdded;
