import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../assets/images/loading.svg';
import useDebounce from '../../../../helpers/useDebounceHook';
import { capitalize, formatText } from '../../../../helpers/utils';
import { getAutomationLogList as automationList } from '../../../../store/features/automationsSlice';
import { LogsWrapper } from '../../../../styles/components/automationDetails/automation-details.styled';
import InputSearch from '../../../common/input-search';
import Loader from '../../../common/loader';
import Menu from '../../../common/menu';
import NoDataComponent from '../../../common/no-data-component';
import NoResultComponent from '../../../common/no-result-component';
import Status from '../../../common/status/automation-log-status';
import AutomationDetailsHeader from '../automation-details-header';
const Logs = () => {
  const navigate = useNavigate();
  const { automationLogList } = useSelector(state => state.automations);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const debouncedSearch = useDebounce(searchText, 500);
  const pageRef = useRef(null);
  const { t } = useTranslation();

  const onAutomationLog = id => {
    navigate(`/platforms/automation-log/${id}`);
  };

  const AutomationLogHeader = [
    {
      name: t('ID'),
    },
    {
      name: t('DATE'),
    },
    {
      name: t('STATUS'),
      ignoreRightBorder: true,
    },
  ];

  const fetchMoreData = () => {
    getAutomationLogList(pageRef.current + 1, true, debouncedSearch);
  };

  const getAutomationLogList = (page, merge) => {
    pageRef.current = page;
    if (!merge && page === 0) {
      setLoading(true);
    }
    dispatch(
      automationList({
        params: { automation_id: id, page: page, search: debouncedSearch, size: 20 },
        merge: merge,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getAutomationLogList(0, false);
  }, [id, debouncedSearch]);
  return (
    <LogsWrapper className="flex-column items-start justify-start w-full h-full">
      <AutomationDetailsHeader
        actionContent={
          <div className="items-center justify-between w-full mr-2">
            <div className="flex items-center justify-end flex-1">
              <InputSearch
                placeholder={'Search'}
                value={searchText}
                onChange={setSearchText}
                className="input-search"
              />
            </div>
          </div>
        }
        title={<div className="one-line">{t('AUTOMATION_LOG')}</div>}
      />
      {loading ? (
        <Loader height={32} width={32} />
      ) : automationLogList?.content?.length > 0 ? (
        <>
          <div className="py-2 divider-bottom w-full header-container">
            {AutomationLogHeader.map(({ name, value, ignoreRightBorder }) => (
              <div
                key={value}
                className={`flex items-center justify-center header-item-container ${
                  !ignoreRightBorder && 'divider-right'
                }`}
                onClick={() => handleSorting(sort_by)}>
                <span className="medium-text lighter-text font-12">{name}</span>
              </div>
            ))}
          </div>
          <div className="flex-column flex-1 overflow-scroll w-full" id="scrollableDiv">
            <InfiniteScroll
              className="flex-column flex-1"
              dataLength={automationLogList?.content.length}
              hasMore={!automationLogList?.last}
              loader={
                <div className="item flex items-center justify-center">
                  <img alt="loading" height="32px" src={Loading} />
                </div>
              }
              next={fetchMoreData}
              scrollableTarget="scrollableDiv">
              {automationLogList?.content?.map(item => (
                <div
                  className="flex items-center justify-start w-full divider-bottom item-container cursor automation-log-hover"
                  onClick={() => onAutomationLog(item?.id)}>
                  <div className={`flex items-center justify-center divider-right`}>
                    <span className="regular-text one-line">{item?.id}</span>
                  </div>
                  <div className={`flex items-center justify-center divider-right`}>
                    <span className={`regular-text`}>
                      {moment(item?.created_on * 1000).format('MM/DD/YY - hh:mm a')}
                    </span>
                  </div>
                  <div className={`flex items-center justify-center`}>
                    <span className={`regular-text`}>
                      <Status
                        withDot
                        withDottedBorder
                        status={item?.status}
                        statusText={capitalize(formatText(item?.status))}
                      />
                    </span>
                  </div>
                  <div>
                    <Menu
                      menuList={[
                        { name: t('DETAILS'), onClick: () => onAutomationLog(item?.id) },
                        {
                          name: t('CANCEL'),
                          onClick: () => {},
                        },
                      ]}
                    />
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </LogsWrapper>
  );
};

export default Logs;
