import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDebounce from '../../../../../helpers/useDebounceHook';
import { getOrganizationPrizedraw } from '../../../../../store/features/groupsSlice';
import { PrizeDrawAccessWarpper } from '../../../../../styles/components/group-details/group-detail.styled';
import InputSearch from '../../../../common/input-search';
import Loader from '../../../../common/loader';
import NoDataComponent from '../../../../common/no-data-component';
import NoResultComponent from '../../../../common/no-result-component';
import AccountDetailsHeader from '../../account-details-header';
import PrizeDrawAccessItem from './prize-draw-access-item';

const PrizeDrawAccess = () => {
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const { organizationPrizedraw } = useSelector(state => state.groups);
  const dispatch = useDispatch();
  const { account_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const getOrganizationPrizedrawList = (page, merge) => {
    pageRef.current = page;
    if (!merge && page === 0) {
      setLoading(true);
    }
    dispatch(
      getOrganizationPrizedraw({
        params: { page: page, search: debouncedSearch, size: 20 },
        organizationId: account_id,
        merge: merge,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getOrganizationPrizedrawList(0, false);
  }, [debouncedSearch]);

  const fetchMoreData = () => {
    getOrganizationPrizedrawList(pageRef.current + 1, true);
  };

  return (
    <PrizeDrawAccessWarpper className="flex-column w-full flex-1 overflow-scroll">
      <AccountDetailsHeader
        title={'Prize Draw access'}
        titleContent={
          <div className="flex items-center">
            <div className="flex items-center result-container radius-4 px-2">
              <div className="flex col-gap-1">
                <label className="semibold-text lighter-text font-10">
                  {organizationPrizedraw?.content.length || 0}
                </label>
                <label className="regular-text lighter-text font-10">{t('RESULTS')}</label>
              </div>
            </div>
          </div>
        }
        actionContent={
          <div className="flex flex-1 items-center justify-end mr-4">
            <InputSearch placeholder={'Search'} value={searchText} onChange={setSearchText} className="input-search" />
          </div>
        }
      />
      <div className="flex-column flex-1 overflow-scroll w-full" id="scrollableDiv">
        {loading ? (
          <Loader height={32} width={32} />
        ) : organizationPrizedraw?.content.length > 0 ? (
          <InfiniteScroll
            className="flex-column flex-1"
            dataLength={(organizationPrizedraw.content || [])?.length}
            hasMore={!organizationPrizedraw?.last}
            loader={<Loader height={32} width={32} />}
            next={fetchMoreData}
            scrollableTarget="scrollableDiv">
            {organizationPrizedraw.content.map(data => (
              <PrizeDrawAccessItem prizedraw={data} />
            ))}
          </InfiniteScroll>
        ) : debouncedSearch ? (
          <NoResultComponent />
        ) : (
          <NoDataComponent />
        )}
      </div>
    </PrizeDrawAccessWarpper>
  );
};

export default PrizeDrawAccess;
