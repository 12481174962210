import React, { useState } from 'react';
import { ReactComponent as AutomationLogIcon } from '../../../../assets/images/automation-log.svg';
import { getPerformedByFromLog } from '../../../../helpers/utils';
import LogContentBox from './common-ui/log-content-box';
import LogHeader from './common-ui/log-header';
import LogTitle from './common-ui/log-title';

const AutomationActionFailed = ({ log = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  const { event_data } = log || {};

  const { automation, integration, entrant, action_execution } = event_data || {};
  const { connector, name: integrationName } = integration || {};
  const { action_type } = action_execution || {};

  const actionTypeName = action_type === 'SMS' ? 'Text' : 'Email';
  const performedBy = getPerformedByFromLog(log);

  return (
    <div className="flex-column row-gap-1 note-created-wrapper">
      <LogHeader
        icon={<AutomationLogIcon height={16} width={16} />}
        title={`${actionTypeName} sent unsuccessfully`}
        subtitle={'Communication'}
      />
      <LogTitle
        text={
          <span>
            <span className="semibold-text lighter-text font-12">{automation?.name}</span>
            <span className="regular-text lighter-text font-12 ml-1">
              sent unsuccessfully to {entrant ? 'entrant' : 'user'}
            </span>
            <span className="regular-text lighter-text font-12 zen-purple-text cursor ml-1">
              {entrant ? entrant?.urn : performedBy}
            </span>
          </span>
        }
        showDetails={showDetails}
        setShowDetails={data => setShowDetails(data)}
      />
      {showDetails && (
        <>
          <LogContentBox
            title={'Id'}
            contentText={<p className="flex regular-text lighter-text font-12">{automation?.id}</p>}
          />
          <LogContentBox
            title={'Provider'}
            contentText={<p className="flex regular-text lighter-text font-12">{connector?.name}</p>}
          />
          <LogContentBox
            title={'Integration'}
            contentText={<p className="regular-text lighter-text font-12">{integrationName}</p>}
          />
        </>
      )}
    </div>
  );
};

export default AutomationActionFailed;
