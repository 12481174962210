import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import uuid from 'react-uuid';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as Loading } from '../../assets/images/loading.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as EmptyImage } from '../../assets/images/subtract.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, isDataChanged, uppercase } from '../../helpers/utils';
import { getPrizeDrawDetail, updatePrizeDraw } from '../../store/features/prizeDrawSlice';
import { addToast } from '../../store/features/toastSlice';
import { uploadMedia } from '../../store/services/media';
import { PrizeDrawDetailLeftWrapper } from '../../styles/components/prize-draw-detail/prize-draw-detail.styled';
import Menu from '../common/menu';
import UpdateAction from '../common/update-action';

const PrizeDrawDetailLeft = () => {
  const { setModal, hasPermission } = useContext(OrganisationContext);
  const { prizeDrawDetail } = useSelector(state => state.prizeDraw);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updatePrizedrawDetail, setUpdatePrizedrawDetail] = useState({ type: '', value: '' });
  const inputRef = useRef();
  const [imageData, setImageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isProfileUploading, setIsProfileUploading] = useState(false);

  const fetchPrizeDrawDetails = () => {
    setLoading(true);
    dispatch(getPrizeDrawDetail({ id: prizeDrawDetail?.id })).then(() => {
      setLoading(false);
    });
  };

  const onCancelUpdate = () => {
    setUpdatePrizedrawDetail({ type: '', value: '' });
  };

  const onUpdate = async (field = '', imgData = '') => {
    if (loading) {
      return;
    }
    let imageData = {};
    if (updatePrizedrawDetail.type === 'code' && updatePrizedrawDetail.value.trim().length !== 3) {
      dispatch(addToast({ error: true, text: 'Please enter valid code', id: uuid() }));
      return;
    } else if (field !== 'img' && !updatePrizedrawDetail.value.trim()) {
      dispatch(addToast({ error: true, text: 'Please enter required field', id: uuid() }));
      return;
    }
    let updatedDetails = {
      name: prizeDrawDetail?.name,
      description: prizeDrawDetail?.description,
      code: prizeDrawDetail?.code,
      image: { id: prizeDrawDetail?.image?.media_external_id ? prizeDrawDetail?.image?.media_external_id : '' },
    };
    if (field === 'img' && imgData) {
      try {
        setIsProfileUploading(true);
        imageData = await uploadMedia(imgData);
        updatedDetails = { ...updatedDetails, image: { id: imageData?.media_external_id } };
        setIsProfileUploading(false);
      } catch (error) {
        setIsProfileUploading(false);
        dispatch(addToast({ error: true, text: 'Error while uploading image', id: uuid() }));
        return;
      }
    } else if (field === 'img' && !imgData) {
      updatedDetails = { ...updatedDetails, image: { id: '' } };
    } else if (updatePrizedrawDetail.type === 'code') {
      updatedDetails = {
        ...updatedDetails,
        code: `${updatePrizedrawDetail?.value}-${updatePrizedrawDetail?.numericId}`,
      };
    } else {
      updatedDetails = {
        ...updatedDetails,
        [updatePrizedrawDetail.type]: updatePrizedrawDetail.value.trim(),
      };
    }
    setLoading(true);
    dispatch(updatePrizeDraw({ request: updatedDetails, prizeDrawId: prizeDrawDetail?.id }))
      .then(() => {
        setLoading(false);
        dispatch(getPrizeDrawDetail({ id: prizeDrawDetail?.id }));
        if (field === 'img' && !imgData) {
          setImageData({});
        }
        setUpdatePrizedrawDetail({ type: '', value: '' });
      })
      .catch(() => {
        setLoading(false);
        dispatch(addToast({ error: true, text: 'Error while updating details', id: uuid() }));
      });
  };

  useEffect(() => {
    setImageData({ url: prizeDrawDetail?.image?.url, thumbnail_url: prizeDrawDetail?.image?.thumbnail_url });
  }, [prizeDrawDetail?.image?.url]);

  const handleSetPrizedrawCode = () => {
    const spliitedValues = prizeDrawDetail?.code.split('-');
    setUpdatePrizedrawDetail({
      type: 'code',
      value: uppercase(prizeDrawDetail?.code?.slice(0, 3)),
      numericId: spliitedValues[spliitedValues.length - 1],
    });
  };

  const emptyImageContent = (
    <div className="img-container border flex items-center justify-center radius-4 empty-img-content-details cursor mr-4">
      <input
        className="display-none"
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={e => {
          setImageData({ ...imageData, img: e.target.files[0], url: window.URL.createObjectURL(e.target.files[0]) });
          onUpdate('img', e.target.files[0]);
        }}
      />
      <EmptyImage className="empty-img" />
      <div className="add-img-btn radius-2 flex items-center justify-center" onClick={() => inputRef.current.click()}>
        <PlusIcon />
      </div>
    </div>
  );

  const imageContent = (
    <div className="flex items-center justify-center radius-4 cursor img-data-wrapper relative mr-4">
      <div
        className="delete-btn-wrapper absolute flex items-center justify-center radius-2"
        onClick={() => onUpdate('img', '')}>
        <DeleteIcon width={14} height={14} />
      </div>
      <img
        className="flex items-center justify-center radius-2"
        src={imageData?.thumbnail_url || imageData?.url}
        style={{ objectFit: 'cover', height: '64px', width: '64px' }}
      />
    </div>
  );

  const nameField =
    updatePrizedrawDetail.type === 'name' && hasPermission('PRIZEDRAWS_MANAGE') ? (
      <div className="update-detail-contaner relative">
        <input
          className="input w-full edit-input"
          value={updatePrizedrawDetail.value}
          onChange={({ target }) => setUpdatePrizedrawDetail({ ...updatePrizedrawDetail, value: target.value })}
          placeholder="Name"
          autoFocus
        />
        <UpdateAction
          onCancel={onCancelUpdate}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              updatePrizedrawDetail.value || '',
              prizeDrawDetail.name || '',
              onUpdate,
              onCancelUpdate,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-center py-1 cursor ${
          hasPermission('PRIZEDRAWS_MANAGE') ? 'content-hover-edit' : ''
        } w-full`}
        onClick={() => setUpdatePrizedrawDetail({ type: 'name', value: prizeDrawDetail?.name })}>
        <span className="semibold-text font-16 flex-1 one-line hover-margin  mr-10 w-full">
          {prizeDrawDetail?.name}
        </span>
        {hasPermission('PRIZEDRAWS_MANAGE') && <EditIcon width={16} height={16} className="edit-icon mr-2" />}
      </div>
    );

  const descriptionField =
    updatePrizedrawDetail.type === 'description' && hasPermission('PRIZEDRAWS_MANAGE') ? (
      <div className="update-detail-contaner relative">
        <textarea
          className="textarea w-full description-input"
          value={updatePrizedrawDetail.value}
          onChange={({ target }) => setUpdatePrizedrawDetail({ ...updatePrizedrawDetail, value: target.value })}
          placeholder="Description"
          rows={3}
          autoFocus
        />
        <UpdateAction
          onCancel={onCancelUpdate}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              updatePrizedrawDetail.value || '',
              prizeDrawDetail.description || '',
              onUpdate,
              onCancelUpdate,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-start justify-start w-full py-1 cursor ${
          hasPermission('PRIZEDRAWS_MANAGE') ? 'content-hover-edit' : ''
        }`}
        onClick={() => setUpdatePrizedrawDetail({ type: 'description', value: prizeDrawDetail?.description })}>
        <p className="regular-text w-full break-word hover-margin mr-10 flex-1 description-text">
          {prizeDrawDetail?.description}
        </p>
        {hasPermission('PRIZEDRAWS_MANAGE') && <EditIcon width={16} height={16} className={`edit-icon mr-2 mt-1 `} />}
      </div>
    );

  const codeField =
    updatePrizedrawDetail.type === 'code' && hasPermission('PRIZEDRAWS_MANAGE') ? (
      <div className="update-detail-contaner relative">
        <div className={`flex items-center relative`}>
          <MaskedInput
            mask={[/[A-Z, a-z]/, /[A-Z, a-z]/, /[A-Z,a-z]/]}
            className={`input w-full code-input flex-1 radius-2 px-3`}
            placeholder={'ABC-001'}
            value={updatePrizedrawDetail.value}
            onChange={({ target }) =>
              setUpdatePrizedrawDetail({ ...updatePrizedrawDetail, value: uppercase(target.value) })
            }
            guide={false}
            autoFocus
          />
          {updatePrizedrawDetail.value ? (
            <label className="absolute normal-text font-16 numeric-id">-{updatePrizedrawDetail?.numericId}</label>
          ) : (
            <></>
          )}
        </div>
        <UpdateAction
          onCancel={onCancelUpdate}
          onUpdate={() =>
            isDataChanged(
              'INPUT',
              updatePrizedrawDetail.value || '',
              uppercase(prizeDrawDetail?.code?.slice(0, 3)),
              onUpdate,
              onCancelUpdate,
            )
          }
        />
      </div>
    ) : (
      <div
        className={`flex items-center py-1 cursor ${
          hasPermission('PRIZEDRAWS_MANAGE') ? 'content-hover-edit' : ''
        } w-full`}
        onClick={() => handleSetPrizedrawCode()}>
        <p className="regular-text flex-1 description-text hover-margin">{prizeDrawDetail?.code}</p>
        <div className="edit-icon-wrapper flex items-start justify-center self-start mt-1 mr-2">
          {hasPermission('PRIZEDRAWS_MANAGE') && <EditIcon width={16} height={16} className="edit-icon" />}
        </div>
      </div>
    );

  const handleActivatePrizedraw = () => {
    const { lifecycle } = prizeDrawDetail;
    setModal({
      type: 'activate-prizedraw',
      content: {
        prizeDrawDetail: prizeDrawDetail,
        isDeActivated: lifecycle === 'ACTIVE' ? true : false,
        isActivate: lifecycle === 'INACTIVE' ? true : false,
        confirmTitle:
          lifecycle === 'INACTIVE'
            ? 'Would you like to activate this prize draw?'
            : 'Would you like to deactivate this prize draw?',
        handleSuccess: () => fetchPrizeDrawDetails(),
      },
    });
  };

  const getMenuList = () => {
    const menuList = [];
    if (prizeDrawDetail?.lifecycle === 'INACTIVE') {
      menuList.push({
        name: 'Activate',
        onClick: () => handleActivatePrizedraw(),
        permission: 'PRIZEDRAW_STATUS_MANAGE',
      });
    } else if (prizeDrawDetail?.lifecycle === 'ACTIVE') {
      menuList.push({
        name: 'Deactivate',
        onClick: () => handleActivatePrizedraw(),
        permission: 'PRIZEDRAW_STATUS_MANAGE',
      });
    }
    return menuList;
  };
  useEffect(() => {
    if (prizeDrawDetail?.id) {
      setLoader(false);
    }
  }, [prizeDrawDetail?.id]);
  return (
    <PrizeDrawDetailLeftWrapper className="border flex-column">
      <div className="flex items-center border-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text">{t('PRIZE_DRAW_DETAILS')}</label>
        {hasPermission('PRIZEDRAW_STATUS_MANAGE') && hasPermission('PRIZEDRAWS_MANAGE') && (
          <Menu menuList={getMenuList()} />
        )}
      </div>
      {loader ? (
        <div className="flex items-center pxy-6 flex-column flex-1 overflow-scroll">
          <Loading />
        </div>
      ) : (
        <div className="pxy-6 flex-column flex-1 overflow-scroll">
          <div className="flex items-center">
            {isProfileUploading ? (
              <div className="loader-image flex items-center justify-center">
                <Loading />
              </div>
            ) : imageData?.url ? (
              imageContent
            ) : (
              emptyImageContent
            )}
            <div className="flex-column flex-1" style={{ maxWidth: 'calc(100% - 80px)' }}>
              <span className="regular-text grey-text font-12">{t('NAME')}</span>
              {nameField}
            </div>
          </div>
          <div className="flex-column mt-6">
            <span className="regular-text grey-text font-12">{t('DESCRIPTION')}</span>
            {descriptionField}
          </div>
          <div className={`flex mt-6 ${updatePrizedrawDetail?.type === 'code' ? 'mb-6' : ''} pr-8`}>
            <div className="flex-1 flex-column mr-4">
              <span className="regular-text grey-text font-12 mb-1">{t('PRIZE_DRAW_CODE')}</span>
              {codeField}
            </div>
            <div className="flex-1 flex-column">
              <span className="regular-text grey-text font-12">{t('STATUS')}</span>
              <div
                className={`flex items-center status-container py-1 px-2 mt-2 ${formatText(
                  prizeDrawDetail?.lifecycle,
                )}`}>
                <div className="dot mr-1" />
                <span className="medium-text font-12">{capitalize(formatText(prizeDrawDetail?.lifecycle))}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </PrizeDrawDetailLeftWrapper>
  );
};

export default PrizeDrawDetailLeft;
