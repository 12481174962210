import React from 'react';

const LogContentBox = ({ title = '', contentText = <></> }) => {
  return (
    <div className="flex-column px-4 py-2 radius-4 border">
      <label className="regular-text grey-text font-12">{`${title}:`}</label>
      {contentText}
    </div>
  );
};

export default LogContentBox;
