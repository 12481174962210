import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as Delete } from '../../../../assets/images/delete-icon.svg';
import DropDown from '../../../McDropdown';

const beforeAfter = [
  { id: 'BEFORE', name: 'Before' },
  { id: 'AFTER', name: 'After' },
];

const hourOptions = [
  { id: 'MINUTE', name: 'Minutes' },
  { id: 'HOUR', name: 'Hours' },
  { id: 'DAY', name: 'Days' },
  { id: 'WEEK', name: 'Weeks' },
];

const initialFormData = {
  inputValue: '',
  duration: null,
  beforeAfterTime: null,
  eventType: null,
};

const AutomationSchedule = ({ scheduleData, sendDataToSchedule, handleShowSchedule = () => {} }) => {
  const { t } = useTranslation();
  const { eventTypeData } = useSelector(state => state.automations);

  const [formData, setFormData] = useState(scheduleData);

  const dateOptions = eventTypeData?.event_type?.name?.includes('APPOINTMENT')
    ? [
        { id: 'appointment.start_date', name: 'Appointment date' },
        { id: 'event.created_on', name: 'Trigger date' },
      ]
    : [{ id: 'event.created_on', name: 'Trigger date' }];

  const clearData = () => {
    setFormData(initialFormData);
    handleShowSchedule && handleShowSchedule(false);
  };

  const handleInputChange = (field, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  useEffect(() => {
    sendDataToSchedule(formData);
  }, [formData]);
  return (
    <div className="flex flex-column pt-8 z-index-2">
      <div className="flex-row justify-between">
        <label className="regular-text font-14 grey-text">{t('SCHEDULE')}</label>
      </div>
      <div className="pt-4 flex-row gap-4">
        <input
          autoComplete="turnoff"
          className={'input name-input'}
          onChange={e => handleInputChange('inputValue', e.target.value)}
          value={formData.inputValue}
          placeholder="0"
        />
        <DropDown
          className="provider-selector w-full relative"
          options={hourOptions}
          selected={formData.duration}
          setSelected={val => handleInputChange('duration', val)}
          placeholder="hour"
        />
        <DropDown
          className="provider-selector w-full"
          options={beforeAfter}
          selected={formData.beforeAfterTime}
          setSelected={val => handleInputChange('beforeAfterTime', val)}
          placeholder="before"
        />
        <DropDown
          className="provider-selector w-full"
          options={dateOptions}
          selected={formData.eventType}
          setSelected={val => handleInputChange('eventType', val)}
          placeholder="select"
        />
        <Delete width={100} height={32} onClick={clearData} className="cursor" />
      </div>
    </div>
  );
};

export default AutomationSchedule;
