import styled from 'styled-components';

export const FormListWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.ZenGray2};
`;

export const FormListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 230px) minmax(250px, 2fr) minmax(250px, 280px) minmax(160px, 180px) minmax(
      180px,
      180px
    );
  min-height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ZenGray2};
  padding: 8px 16px;

  .option-hover {
    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
      color: ${({ theme }) => theme.colors.zenPurple};
      border-radius: 8px;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.hoverColor};
  }
  .empty-img-content {
    background: ${({ theme }) => theme.colors.zenPurple};
    opacity: 0.3;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    object-fit: cover;
  }
`;
